import React, { Component } from 'react';
import { connect } from "react-redux";
import { dashboardActions, growActions } from "../../_actions";

import Header from "./Components/Header";
// import Start from "./Components/Start";
import Home from "./Components/Home";


class GrowMonth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType1: true,
      showMusic: true,
      viewType2: false,
      width: 0, height: 0
    };
    // this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }
  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(growActions.getGrowPlusBucket_User());
    this.props.dispatch(dashboardActions.getGrowProfile());
    this.props.dispatch(growActions.getGrowPlusTaskStatus_User());
    // this.props.dispatch(dashboardActions.getGrowParameterList(data));

  }


  leading = (id) => {
    this.props.history.push(`/app/grow-week-plan/${id}`);
  }


  // leading = (item) => {
  //   if (item.isStart === 0) {
  //     this.setState({ newid: item.id })
  //     let data = { bucketId: item.id }
  //     this.props.dispatch(growActions.getGrowPlusMonth_User(data, this.props))
  //     let monthname = { item }
  //     this.props.history.push('/app/grow-week-plan/' + item.id)
  //   }
  //   else {
  //     let monthname = { item }
  //     this.props.history.push('/app/grow-week-plan/' + item.id)
  //   }
  // }


  handleRedirect = () => {
    this.props.history.push(`/app/manageprofile`)
  };


  render() {
    let { dashboard, grow } = this.props;
    let { growProfile } = dashboard;
    let { getGrowPlusBucket_UserData,getGrowPlusTaskStatus_UserData } = grow;
    let { viewType1, viewType2, } = this.state;
    // console.log("getGrowPlusTaskStatus_UserDatagetGrowPlusTaskStatsus_UserDatagetGrowPlusTaskStatus_UserData ", getGrowPlusTaskStatus_UserData);

    return (
      <div className="h-screen overflow-hidden">
        <Header
          growProfile={growProfile}
          handleRedirect={this.handleRedirect}
        />

        <Home
          getGrowPlusBucket_UserData={getGrowPlusBucket_UserData}
          growProfile={growProfile}
          onLeadingClick={this.leading}
          getGrowPlusTaskStatus_UserData={getGrowPlusTaskStatus_UserData}
        />

        {/* <Start /> */}

      </div>
    )
  }
}

function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, users, grow, dashboard } = state;
  return {
    loggingIn,
    questions,
    users,
    grow,
    dashboard,
  };
}
export default connect(mapStateToProps)(GrowMonth)