import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {

    let { DemographicValues, DemographicValuesObj, XAxis, AllAnsText, AnswerObjOverall,


      currFilterValue, currFilterName, getDistributionIndexData, getIndexDriverData, GetParameterLabel } = this.props;

    //console.log('ssssssDemographicValues: ',DemographicValues)     
    //console.log('ssssssssDemographicValuesObj: ',DemographicValuesObj)     


    AllAnsText.sort((a, b) => {
      if (a.slice(0, -2) && b.slice(0, -2)) {
        return (a.slice(5, 6) - b.slice(5, 6))
      }
    });


    function GetPerc(ValuesObj, item, type) {
      if (item === "OVERALL") {
        let total = 0;
        if (AllAnsText && AllAnsText.length > 0) {
          AllAnsText.forEach(element => {
            total += ValuesObj[element];
          });
        }
        let score = (((ValuesObj[type]) * 100) / total)
        return score ? score.toFixed(2) : 0
      }
      else {
        let total = 0;
        if (AllAnsText && AllAnsText.length > 0) {
          AllAnsText.forEach(element => {
            total += ValuesObj[item][element];
          });
        }
        let score = (((ValuesObj[item][type]) * 100) / total)
        // return score?score.toFixed(2):0

        let count = ValuesObj[item]["count"];
        return count > 5 ? (score ? score.toFixed(2) : 0) : "NaN"
      }

    }

    DemographicValues.sort();

    return (
      <>

        <div className='space-y-4 bg-white'>
          {/* <div className='flex flex-row items-center justify-between w-full gap-4'>
            <div className='flex items-center text-[#212121] space-x-2'>
              <p className='p-3 bg-[#ededed] font-semibold'>Question 111</p>
            </div>
          </div>
          <div className='space-y-2 '>
            <h2 className='text-xl font-medium text-[#212121]'>question name</h2>
            <p className='text-base text-[#212121]/80 flex space-x-2 items-center'>
              <p className='px-2 py-1 text-sm bg-[#ededed] font-semibold text-[#212121]'>Email</p>
            </p>
          </div> */}

          <div className='bg-[#f9f9f9] rounded'>

            <div class="space-y-4 text-sm text-slate-800">
              <table class="w-full border-collapse">
                <thead class="text-sm  capitalize bg-[#2196F3]/20">
                  <tr class="border-b-2 border-white">
                    <th class="text-[#212121] font-medium p-4 py-3 text-sm text-left border-r-2 border-white">{XAxis}</th>
                    {AllAnsText && AllAnsText.length > 0 ? AllAnsText.map((item) =>
                      <th class="text-[#212121] font-medium p-4 py-3 text-sm text-center border-r-2 border-white">{item + " Star" + "(%)"}</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody class="divide-y-2 divide-white ">
                  <tr class="even:bg-gray-100 odd:bg-gray-50">
                    <td class="p-2 px-4 border-r-2 border-white"><p class="font-medium ">{"OVERALL"}</p></td>
                    {AllAnsText && AllAnsText.length > 0 ? AllAnsText.map((type) =>
                      <td class="p-2 px-4 text-center border-r-2 border-white">{GetPerc(AnswerObjOverall, "OVERALL", type)}</td>
                    ) : null}
                  </tr>

                  {DemographicValues && DemographicValues.length > 0 ?
                    DemographicValues.map((item, index) =>
                      (item) ?
                        <tr class="even:bg-gray-100 odd:bg-gray-50">
                          <td class="p-2 px-4 border-r-2 border-white"><p class="font-medium">{item}</p></td>
                          {AllAnsText && AllAnsText.length > 0 ? AllAnsText.map((type) =>
                            <td class="p-2 px-4 text-center border-r-2 border-white">{GetPerc(DemographicValuesObj, item, type)}</td>
                          ) : null}
                        </tr>
                        : null
                    ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>








      </>

    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HeatMap);
