import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class QuestionScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { AnswerObjOverall, DemographicValues, DemographicValuesObj } = this.props;

    console.log("AnswerObjOverall---------12211----->", AnswerObjOverall);
    console.log("DemographicValues---------12211----->", DemographicValues);
    console.log("DemographicValuesObj---------12211----->", DemographicValuesObj);

    // function ColorFn(key){
    //   if(templateId===11){
    //     return {color:key==="Yes"?'#00cc66':'#ff5050'}
    //   }
    //   else{
    //     return {}
    //   }
    // }

    let DataList = [];

    let totalCount = 0;

    let Promoters = 0;
    let Passsives = 0;
    let Distractors = 0;

    if (AnswerObjOverall) {
      Object.keys(AnswerObjOverall).forEach(key => {
        let score = parseInt(AnswerObjOverall[key]);

        totalCount += score;

        if (parseInt(key) >= 9 && parseInt(key) <= 10) {
          Promoters += score;
        }
        if (parseInt(key) >= 7 && parseInt(key) <= 8) {
          Passsives += score;
        }
        if (parseInt(key) >= 1 && parseInt(key) <= 6) {
          Distractors += score;
        }

      });
    }


    let PromotersPer = (Promoters * 100 / totalCount);
    let PasssivesPer = (Passsives * 100 / totalCount);
    let DistractorsPer = (Distractors * 100 / totalCount);

    let npsScore = Math.round((PromotersPer) - (DistractorsPer));

    let PromotersColorRange = PromotersPer / 100;
    let PasssivesColorRange = (PasssivesPer / 100) + PromotersColorRange;
    let DistractorsColorRange = (DistractorsPer / 100) + PasssivesColorRange;








    //console.log('npsScore:',npsScore)
    //////console.log("DemographicValues-------------->",DemographicValues)
    //////console.log("DemographicValuesObj-------------->",DemographicValuesObj)


    // Distractors: 8
    // Passsives: 42
    // Promoters: 13
    // count: 63

    function npsScoreCal(data) {
      let NPSScore = "NaN";
      if (DemographicValuesObj) {
        let objData = DemographicValuesObj[data];
        let Count = objData["count"];
        let Promoters = objData["Promoters"];
        let Distractors = objData["Distractors"];
        NPSScore = ((Promoters * 100) / Count) - ((Distractors * 100) / Count);
      }
      return NPSScore;
    }

    let ListTemp = []
    if (DemographicValues && DemographicValues.length > 0) {
      DemographicValues.forEach((item) => {
        let scoreData = npsScoreCal(item);
        ListTemp.push({
          "name": item,
          "value": scoreData
        });
      });
    }


    ListTemp.sort((a, b) => a.value - b.value);


    let List = []
    let NameList = []
    if (ListTemp && ListTemp.length > 0) {
      let sum = 0;
      ListTemp.forEach((item) => {
        NameList.push(item.name);
        let scoreData = item.value;
        List.push({
          value: Math.round(scoreData),
          itemStyle: {
            color: scoreData < 0 ? "#e82525" : "#21bf1f"
          }
        });
      });
    }



    //////console.log("List-------------->",List);

    let NewScoreList = List && List.length > 0 ? Array.from({ length: List.length }, (_, i) => npsScore) : []

    return (
      <>
        <div style={{ minHeight: "500px" }}>

          {List && List.length > 0 ?
            <ReactEcharts
              style={{
                height: List.length < 10 ? '500px' : List.length * 20 + 'px'
                , marginTop: "-55px", width: '100%'
              }}
              option={{
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow'
                  }
                },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                xAxis: {
                  type: 'value',
                  boundaryGap: [0, 0.05],
                  label: {
                    show: true,
                    color: 'white'
                  }
                },
                yAxis: {
                  type: 'category',
                  data: NameList,
                  boundaryGap: [0, 0.1],

                },
                series: [
                  {
                    name: 'Score',
                    type: 'bar',
                    barWidth: '9px',
                    data: List,
                    color: '#32c77a'
                  },
                  {
                    name: 'Overall',
                    type: 'bar',
                    barWidth: '9px',
                    data: NewScoreList

                  }
                ]
              }}
            />
            : null}
          <div className="font-bold px-2 py-2 text-blue-500  text-center ">Please select demographic to view NPS Score Vs Overall</div>

        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  //   //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionScore);
