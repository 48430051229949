import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import ReactTooltip from "react-tooltip";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSurveyName: "",
      currentSurveyId: "",
      showDropdown: false,
      gameType: "",
      sidebaTab: 1
      // showSidebar:false
    };
  }

  componentDidMount() { }

  onDropdown = () => {
    if (this.state.showDropdown) {
      this.setState({ showDropdown: false });
    } else {
      this.setState({ showDropdown: true });
    }
  };

  logout = () => {
    this.props.dispatch(userActions.logout());
  };

  IndexHoverOptions = (surveyId, indexType) => {
    let { onOptionType } = this.props;

    return (
      <span>
        <i
          className="fa fa-scribd"
          onClick={() => onOptionType(surveyId, indexType, 1)}
        />
        <i
          className="fa fa-quora"
          onClick={() => onOptionType(surveyId, indexType, 2)}
        />
        <i
          className="fa fa-align-left"
          onClick={() => onOptionType(surveyId, indexType, 3)}
        />
        {/* <i className="fa fa-facebook" onClick={()=>onOptionType(surveyId,indexType,4)}/>  */}
      </span>
    );
  };

  handleIndexType = (surveyId, indexType, isDisable, event) => {
    this.setState({ gameType: event });
    let { onOptionType } = this.props;
    if (!isDisable) {
      onOptionType(surveyId, indexType, 1);
    }
    // this.setState({showSidebar:false});

    let { handleSidebar } = this.props;
    handleSidebar(false);

    // setTimeout(()=>{
    //   this.setState({showSidebar:false});
    // },5000)
  };

  sidebarToggle = () => {
    // this.setState({showSidebar:!this.state.showSidebar});

    let { handleSidebar, showSidebar } = this.props;
    handleSidebar(!showSidebar);

    // setTimeout(()=>{
    //   this.setState({showSidebar:false});
    // },6000)

    // setTimeout(()=>{
    //   this.setState({showSidebar:false});
    // },7000)
  };

  render() {
    let { // onHappinessIndex, onEngagementIndex, onHopeIndex, onStressIndex, onOptionType,currentSurveyName, showDropdown,
      showSidebar, SurveyList, getIndexData, indexType, CurrentSurveyId, showMobileSidebar, isLifecycle, } = this.props;

    let surveyId = "";
    if (SurveyList && SurveyList.length > 0) {
      if (CurrentSurveyId) {
        surveyId = CurrentSurveyId;
      } else {
        surveyId = SurveyList[0].id;
      }
    }

    let indexLabelsList = [];
    let IndexList = [];

    function getIndexName(key) {
      let name = "";
      if (key === 1) {
        name = "Happiness";
      } else if (key === 2) {
        name = "Engagement";
      } else if (key === 3) {
        name = "Hope";
      } else if (key === 4) {
        name = "Stress";
      } else if (key === 5) {
        name = "Manager";
      } else if (key === 6) {
        name = "Leadership";
      } else if (key === 7) {
        name = "Safety";
      } else if (key === 9) {
        name = "Diversity";
      } else {
        name = "NaN";
      }
      return name;
    }

    if (getIndexData) {
      IndexList = Object.keys(getIndexData).map((key) => [
        Number(key),
        getIndexData[key],
      ]);

      IndexList.forEach((data) => {
        if (getIndexName(data[0]) !== "NaN" && data && data[0] && data[1]) {
          indexLabelsList.push({
            sidebaTab: data[0],
            score: data[1].toFixed(2),
            name: getIndexName(data[0]),
          });
        }
      });
    }

    function getIconsByTemplateNo(sidebaTab) {
      return sidebaTab === 11 ? (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M21 4.5C21.0002 3.99542 20.8096 3.50943 20.4665 3.13945C20.1234 2.76947 19.6532 2.54284 19.15 2.505L19 2.5H5C4.49542 2.49984 4.00943 2.69041 3.63945 3.0335C3.26947 3.37659 3.04284 3.84685 3.005 4.35L3 4.5V20.5C2.99984 21.0046 3.19041 21.4906 3.5335 21.8605C3.87659 22.2305 4.34685 22.4572 4.85 22.495L5 22.5H10C10.5046 22.5002 10.9906 22.3096 11.3605 21.9665C11.7305 21.6234 11.9572 21.1532 11.995 20.65L12 20.5V16.5H14C14.5046 16.5002 14.9906 16.3096 15.3605 15.9665C15.7305 15.6234 15.9572 15.1532 15.995 14.65L16 14.5V10.5H19C19.5046 10.5002 19.9906 10.3096 20.3605 9.9665C20.7305 9.62341 20.9572 9.15315 20.995 8.65L21 8.5V4.5ZM19 4.5V8.5L5 8.5L5 4.5H19ZM14 10.5V14.5L5 14.5V10.5L14 10.5ZM10 16.5V20.5H5V16.5H10Z" fill="currentColor" />
        </svg>
      ) : sidebaTab === 12 ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
          <path d="M12 10.5C14.2091 10.5 16 8.70914 16 6.5C16 4.29086 14.2091 2.5 12 2.5C9.79086 2.5 8 4.29086 8 6.5C8 8.70914 9.79086 10.5 12 10.5Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M21 22.5C21 17.5295 16.9705 13.5 12 13.5C7.0295 13.5 3 17.5295 3 22.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12 22.5L14 20L12 13.5L10 20L12 22.5Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ) : sidebaTab === 13 ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
          <path d="M15 3.5C15.5304 3.5 16.0391 3.71071 16.4142 4.08579C16.7893 4.46086 17 4.96957 17 5.5V9.5H19C19.5304 9.5 20.0391 9.71071 20.4142 10.0858C20.7893 10.4609 21 10.9696 21 11.5V19.5C21.2652 19.5 21.5196 19.6054 21.7071 19.7929C21.8946 19.9804 22 20.2348 22 20.5C22 20.7652 21.8946 21.0196 21.7071 21.2071C21.5196 21.3946 21.2652 21.5 21 21.5H3C2.73478 21.5 2.48043 21.3946 2.29289 21.2071C2.10536 21.0196 2 20.7652 2 20.5C2 20.2348 2.10536 19.9804 2.29289 19.7929C2.48043 19.6054 2.73478 19.5 3 19.5V9.5C3 8.96957 3.21071 8.46086 3.58579 8.08579C3.96086 7.71071 4.46957 7.5 5 7.5H7V5.5C7 4.96957 7.21071 4.46086 7.58579 4.08579C7.96086 3.71071 8.46957 3.5 9 3.5H15ZM7 9.5H5V19.5H7V9.5ZM19 11.5H17V19.5H19V11.5ZM15 5.5H9V19.5H15V5.5ZM13 15.5V17.5H11V15.5H13ZM13 11.5V13.5H11V11.5H13ZM13 7.5V9.5H11V7.5H13Z" fill="currentColor" />
        </svg>
      ) : sidebaTab === 4 ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5ZM13 4.562V5.5C12.9997 5.75488 12.9021 6.00003 12.7272 6.18537C12.5522 6.3707 12.313 6.48223 12.0586 6.49717C11.8042 6.51211 11.5536 6.42933 11.3582 6.26574C11.1627 6.10214 11.0371 5.8701 11.007 5.617L11 5.5V4.562C9.28177 4.77898 7.68025 5.54734 6.43578 6.75179C5.19131 7.95623 4.37102 9.53178 4.098 11.242L4.062 11.5H5C5.25488 11.5003 5.50003 11.5979 5.68537 11.7728C5.8707 11.9478 5.98223 12.187 5.99717 12.4414C6.01211 12.6958 5.92933 12.9464 5.76574 13.1418C5.60214 13.3373 5.3701 13.4629 5.117 13.493L5 13.5H4.062C4.30363 15.4112 5.22674 17.1711 6.66175 18.4564C8.09677 19.7417 9.94734 20.466 11.8735 20.4964C13.7998 20.5268 15.6723 19.8612 17.1471 18.6219C18.622 17.3825 19.6002 15.6527 19.902 13.75L19.938 13.5H19C18.7451 13.4997 18.5 13.4021 18.3146 13.2272C18.1293 13.0522 18.0178 12.813 18.0028 12.5586C17.9879 12.3042 18.0707 12.0536 18.2343 11.8582C18.3979 11.6627 18.6299 11.5371 18.883 11.507L19 11.5H19.938C19.7198 9.75622 18.932 8.13326 17.697 6.883L15.273 11.642L15.118 11.936L14.808 12.546C14.438 13.266 14.036 14 13.485 14.551C12.513 15.522 10.897 15.64 9.879 14.621C8.86 13.603 8.978 11.987 9.949 11.015C10.421 10.543 11.027 10.18 11.645 9.853L12.564 9.382L13.413 8.938L17.616 6.803C16.366 5.56819 14.7434 4.78045 13 4.562ZM13.162 11.338L12.952 11.45L12.736 11.563C12.334 11.772 11.914 11.989 11.564 12.261L11.363 12.431L11.29 12.515C11.097 12.775 11.155 13.069 11.293 13.207C11.431 13.345 11.725 13.403 11.985 13.21L12.071 13.136L12.239 12.936C12.456 12.656 12.639 12.331 12.81 12.006L12.937 11.764C13.0117 11.6173 13.0867 11.4753 13.162 11.338Z" fill="currentColor" />
        </svg>
      ) : sidebaTab === 5 ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
          <path d="M9 8.75H10.5V13.25H9V8.75ZM5.25 10.25H6.75V13.25H5.25V10.25ZM1.5 5.75H3V13.25H1.5V5.75Z" fill="currentColor" />
          <path d="M22.5004 21.9395L16.8364 16.2755C18.0741 14.7963 18.7517 12.9287 18.7504 11C18.7504 6.45125 15.0491 2.75 10.5004 2.75C8.69814 2.75 6.98664 3.32 5.54964 4.4L6.44964 5.6C7.61619 4.71963 9.03893 4.24547 10.5004 4.25C14.2226 4.25 17.2504 7.27775 17.2504 11C17.2504 14.7222 14.2226 17.75 10.5004 17.75C9.45177 17.7541 8.41683 17.5119 7.4789 17.043C6.54098 16.574 5.72627 15.8914 5.10039 15.05L3.90039 15.9515C4.66575 16.9793 5.66161 17.8132 6.8079 18.3861C7.95419 18.959 9.21892 19.2549 10.5004 19.25C12.5051 19.25 14.3449 18.53 15.7759 17.336L21.4399 23L22.5004 21.9395Z" fill="currentColor" />
        </svg>
      ) : (
        "dashboard"
      );
    }

    let otherLabelList = [
      {
        name: "ICE Index",
        sidebaTab: 11,
        show: true,
      },
      {
        name: "Manager Index",
        sidebaTab: 12,
        show: !isLifecycle,
      },
      {
        name: "Organization Core",
        sidebaTab: 13,
        show: true,
      },
      {
        name: "eNPS",
        sidebaTab: 4,
        show: !isLifecycle,
      },
      {
        name: "Text Analysis",
        sidebaTab: 5,
        show: !isLifecycle,
      },
    ];

    return (
      <>
        <div
          className={
            showSidebar
              ? "cursor-pointer transition-width w-64 lg:block scollar-xs  lg:relative fixed top-0 left-0  h-screen lg:z-20 z-20 overflow-y-auto overflow-hidden  duration-200 transform bg-white border-r customscroll4 " +
              (showMobileSidebar ? "" : "hidden")
              : "cursor-pointer transition-width  w-16 lg:block scollar-xs lg:relative fixed top-0 left-0  h-screen lg:z-20 z-20 overflow-y-auto overflow-hidden  duration-200 transform bg-white border-r customscroll4 " +
              (showMobileSidebar ? "" : "hidden")
          }
          style={{ height: "calc(100% - 0rem)" }}
        >
          {!showMobileSidebar ? (
            <div className="sticky top-0 flex items-center bg-white ">
              <div className="flex items-center justify-center w-16 px-4 py-3 text-gray-500">
                <span
                  onClick={() => this.sidebarToggle()}
                  className="p-1 bg-gray-100 rounded material-symbols-outlined"
                >
                  {showSidebar ? "chevron_left" : "chevron_right"}
                </span>
              </div>
              <span className="text-sm text-gray-500 ">Insight</span>
            </div>
          ) : (
            <div className="py-1" />
          )}

          <div className="mb-4 whitespace-nowrap ">
            {/* 1 */}
            <div data-tip="Overview" onClick={() => this.handleIndexType(surveyId, 0, indexType === 0)} className={indexType === 0 ? "flex items-center hover:bg-[#2196F3]/10 text-gray-500 cursor-pointer border-l-4 border-[#2196F3] bg-[#2196F3]/10" : "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer border-l-4 border-[#fff]"}>
              <div className={indexType === 0 ? "flex items-center px-4 py-4 w-16  text-[#2196F3] h-12 justify-center" : "flex items-center px-4 py-4 w-16 h-12 justify-center"}>
                <span className="p-2 material-symbols-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-layout-dashboard" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.8" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>  <path d="M4 4h6v8h-6z"></path>  <path d="M4 16h6v4h-6z"></path>  <path d="M14 12h6v8h-6z"></path>  <path d="M14 4h6v4h-6z"></path></svg>
                </span>
              </div>
              <span className={indexType === 0 ? " text-sm text-[#2196F3]" : " text-sm  "}>  Overview</span>
            </div>

            {otherLabelList && otherLabelList.length > 0
              ? otherLabelList.map((item, index) =>
                item.show ? (
                  <div
                    title={item && item.name ? item.name : ""}
                    key={index}
                    onClick={() =>
                      this.handleIndexType(
                        surveyId,
                        item.sidebaTab,
                        indexType === item.sidebaTab
                      )
                    }
                    className={
                      indexType === item.sidebaTab
                        ? "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer border-l-4 border-[#2196F3] bg-[#2196F3]/10"
                        : "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer border-l-4 border-[#fff]"
                    }
                  >
                    <div className={
                      indexType === item.sidebaTab
                        ? "flex items-center px-4 py-4 w-16  text-[#2196F3] h-12 justify-center"
                        : "flex items-center px-4 py-4 w-16  text-[#3D405B]  h-12 justify-center"
                    }>
                      <span className={
                        indexType === item.sidebaTab
                          ? "text-[#2196F3] p-2 material-symbols-outlined"
                          : "text-[#3D405B] p-2 material-symbols-outlined"
                      }>
                        {getIconsByTemplateNo(item.sidebaTab)}
                      </span>
                    </div>
                    <span
                      className={
                        indexType === item.sidebaTab
                          ? " text-sm text-[#2196F3]"
                          : " text-sm text-[#3D405B] "
                      }
                    >
                      {item && item.name ? item.name : ""}
                    </span>
                  </div>
                ) : null
              )
              : null}

            <ReactTooltip
              place="right"
              className="extraClass1"
              type="dark"
              effect="solid"
            />
            {/* 2 */}
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn,
  };
}
export default connect(mapStateToProps)(Sidebar);
