import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accessModule } from '../../_helpers';

//api call
import { dashboardActions, surveyActions, userActions, generalActions } from '../../_actions';

//Layout
import Sidebar from './Sidebar/Sidebar';



//libraries
import LoadingOverlay from 'react-loading-overlay';

import Overview from './Overview/Overview';

import Header from '../CommonComponents/DashboardHeader/Header';
import LeadershipReport from './LeadershipReport/LeadershipReport';
import { spliceDecimal } from "../FunctionLibrary/FuntionList";


import { CONST } from '../../_config';

import moment from 'moment';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexType: 0,
      CurrentSurveyName: '',
      CurrentSurveyId: this.props.match.params.id,
      optionType: 0,
      showFilterDropdownName: false,
      showFilterDropdownValue: false,
      CurrentIndexName: '',
      filterSelectIndex: 0,
      loaderActive: false,
      filterOn: false,
      FilterList2: [],
      showFilter: true,

      loadReport: false,
      isFilter: false,

      showMobileSidebar: false,

      showOverview: true,
      showIndex: false,
      showManagerList: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showCustomScoreCard: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showReport: false,
      showOtherTypes: false,
      showPeopleToMeet: false,

      StackObject: [],
      StackObjectTotal: [],
      ReturnFinalFilterList: [],
      selectedFilterList: [],
      selectedFilterValue: 'none',
      HeatMapSelectedFilter: 'Department',

      DynaObj: [],

      Mindextype: '',

      customeScoreData: "",

      ManagerDynaObj: [],

      QuesDynaObj: {},

      QuesMngrHappinessReportObject: {},
      QuesMngrEngagementReportObject: {},
      QuesMngrStressReportObject: {},
      QuesMngrManagerReportObject: {},

      DriverMngrHappinessReportObject: {},
      DriverMngrEngagementReportObject: {},
      DriverMngrStressReportObject: {},
      DriverMngrManagerReportObject: {},

      MQuesIndextype: '',
      rwaIndexType: 1,
      ManagerListDasboardDataObj: [],
      ManagerListQuestionDataObj: [],

      showSidebar: false,
      currentQuestionId: '',
      currentQuestionTemplate: '',
      showAllQuestions: true,

      showReportDMGModal: false,

      ReportDMGindexType: "",
      ReportDMGcheck: "",
      ReportDMGfiltersListNew: "",
      ReportxAxis: "",
      isReportOverall: false,

      ManagerReportFConfig: {
        "managerIdType": CONST.MANAGER_ID_TYPE //Int or String
      },
      showReportModal: false,
      showMngrReportModal: false,
      showHiringReportModal: false,
      showTeamReportModal: false

    }
  }


  componentDidMount() {



    this.props.dispatch(dashboardActions.getClientProfile());
    this.props.dispatch(dashboardActions.getCompanyNameOnly());

    this.props.dispatch(dashboardActions.getLeadershipParaCategory({}));
    let data5 = {
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getRespondantDetails(data5));
    this.props.dispatch(dashboardActions.getLeadershipTextDetails({}));

    
    // this.props.dispatch(dashboardActions.getEmpFilter());
    // this.props.dispatch(surveyActions.getIndexByFilterDMG());


    // let data2 = {
    //   "keyWord": "",
    //   "pageNo": 1,
    //   "size": 100
    // }

    // this.props.dispatch(surveyActions.getLanguageLabel(data2));
    // this.props.dispatch(surveyActions.getLanguage(data2));


    this.props.dispatch(dashboardActions.getUserAccess({}));

    let data10 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10000
    }
    this.props.dispatch(dashboardActions.getParameterLibraryList(data10));



  }




  callOverviewApi = () => {


    this.setState({
      showOverview: true,
      showIndex: false,
      showManagerList: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showReport: false,
      showOtherTypes: false,
      showCustomScoreCard: false,
      showPeopleToMeet: false
    })

  }


  LoadOverviewInitFn = () => {
    let data1 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }

    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": []
    }


    let data5 = {
      "surveyId": this.state.CurrentSurveyId
    }



    this.props.dispatch(dashboardActions.getCompanyNameOnly());

    this.props.dispatch(surveyActions.getIndexByFilterDMG());

    this.props.dispatch(dashboardActions.getRespondantDetails(data5));

    this.props.dispatch(surveyActions.getSurveyById(data5));

    this.props.dispatch(dashboardActions.getClientProfile());


    this.props.dispatch(dashboardActions.getEmpFilter());




    // HAPPINESS_INDEX: 1,
    // ENGAGEMENT_INDEX: 2,
    // HOPE_INDEX: 3,
    // STRESS_INDEX: 4,
    // MANAGER_INDEX: 5,
    // LEADER_INDEX: 6,
    // SENIORLEADERSHIP_INDEX: 7,
    // ORGANIZATIONCORE_INDEX: 8,



    //   let data5 = {
    //     "surveyId": "5fa04d316ede0f0336066cce",
    //     "xAxis": "",
    //     "filters": null
    // }
    //   this.props.dispatch(dashboardActions.getOrgCoreIndex(data5));




    this.props.dispatch(generalActions.getQuestionAvgScore({ "surveyId": this.state.CurrentSurveyId, qtemplateList: [1] }));

    this.props.dispatch(generalActions.getQuestionOthersResult({
      "surveyId": this.state.CurrentSurveyId,
      "qtemplateList": [1, 2, 6, 11, 16]
    }));

    this.props.dispatch(surveyActions.getIndexByFilterDMG());







    let data10 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 1000
    }


    this.props.dispatch(surveyActions.getParameterList(data10));





  }







  handleLogout = () => {
    this.props.dispatch(userActions.logoutSession());
  }

  handleSidebar = (check) => {
    this.setState({ showSidebar: check })
  }
  handleMobileSidebar = (check) => {
    this.setState({ showMobileSidebar: check })
    this.setState({ showSidebar: check })
  }


  handleSummaryCard = (name) => {
    let { CurrentSurveyId } = this.state;

    let { indexType } = this.state;
    let data = {
      "surveyId": CurrentSurveyId,
      "xAxis": name
    }
    this.props.dispatch(dashboardActions.getDemographicIndex(data));

    if (indexType === 1) {
      this.props.dispatch(dashboardActions.getHappinessIndexV2Demographic(data));
    }
    else if (indexType === 2) {
      this.props.dispatch(dashboardActions.getEngagementIndexV2Demographic(data));
    }
    else if (indexType === 4) {
      this.props.dispatch(dashboardActions.getStressIndexV2Demographic(data));
    }
    else if (indexType === 5) {
      this.props.dispatch(dashboardActions.getDemographicIndexManager(data));
    }
    else if (indexType === 6) {
      this.props.dispatch(dashboardActions.getDemographicIndexLeader(data));
    }
  }

  handleSummaryFilter = (name, value) => {
    let { CurrentSurveyId } = this.state;
    let data = {
      "surveyId": CurrentSurveyId,
      "filters": [
        { "level": name, "value": value }
      ]
    }
    this.props.dispatch(dashboardActions.getIndexByFilter(data));
  }

  handleQuestionFilter = (name, value) => {
    let { indexType, CurrentSurveyId } = this.state;
    let data = {
      "surveyId": CurrentSurveyId,
      "template": indexType,
      "filters": [
        { "level": name, "value": value }
      ]
    }
    // //////console.log('data: ',data)
    this.props.dispatch(dashboardActions.getQuestionFilter(data));
  }

  handleDistributionFilter = (name) => {
    let { indexType } = this.state;
    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": name
    }
    if (indexType === 1) {
      this.props.dispatch(dashboardActions.getDemographicIndexHappiness(data));
    }
    else if (indexType === 2) {
      this.props.dispatch(dashboardActions.getDemographicIndexEngagement(data));
    }
    else if (indexType === 4) {
      this.props.dispatch(dashboardActions.getDemographicIndexStress(data));
    }
    else if (indexType === 5) {
      this.props.dispatch(dashboardActions.getDemographicIndexManager(data));
    }
    else if (indexType === 6) {
      this.props.dispatch(dashboardActions.getDemographicIndexLeader(data));
    }
    else if (indexType === 8) {
      this.props.dispatch(dashboardActions.getDemographicIndexOrg(data));
    }
  }


  onCustomScoreCard = (item) => {

    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showReport: false,
      showOtherTypes: false,
      showAllQuestions: false,
      showCustomScoreCard: true,
      currentQuestionTemplate: '',

      customeScoreData: item,
      showPeopleToMeet: false

    })
  }

  onAllQuestions = (item) => {


    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showReport: false,
      showOtherTypes: true,
      showAllQuestions: true,
      showCustomScoreCard: false,
      currentQuestionTemplate: item.templateId,
      showPeopleToMeet: false

    })


  }




  onOverview = (surveyId) => {
    this.setState({
      showOverview: true,
      showIndex: false,
      showManagerList: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showCustomScoreCard: false,
      showReport: false,
      showOtherTypes: false,
      showPeopleToMeet: false


    })
    // this.setState({CurrentSurveyId:surveyId})

  }




  // onLoadIndex=(indexType,check, filtersListNew,isFilterTwice,name, isClose)=>{

  //   let X2Axis = "";
  //   let Filters = [];
  //   let isAllCheck = false;
  //   let isOverall = false
  //   if(filtersListNew && filtersListNew.length>0){
  //     let getIndex = filtersListNew.findIndex(prev=>prev.value === "All");
  //     if(getIndex !== -1){
  //       X2Axis = filtersListNew[getIndex].level;
  //       filtersListNew.forEach((item)=>{
  //         if(item.value !== "All"){
  //           Filters.push(item);
  //         }
  //         else{
  //           isAllCheck = true
  //         }
  //       });
  //     }
  //     else{
  //       X2Axis = filtersListNew[filtersListNew.length-1].level;
  //       Filters = filtersListNew;
  //     }

  //     this.setState({isReportOverall:false});
  //   }
  //   else{
  //     this.setState({isReportOverall:true});
  //     isOverall = true;
  //   }

  //   // let data7 = {
  //   //   "surveyId": this.state.CurrentSurveyId,
  //   //   "xAxis": isFilterTwice?name:X2Axis,
  //   //   "filters":Filters
  //   // }





  //   let filter = Filters;

  //   let isFilter = false;
  //   let xAxis = "";
  //   if(filter && filter.length>0){
  //     isFilter=true;
  //     xAxis = isFilterTwice?name:X2Axis;
  //     // xAxis = filter && filter[0] && filter[0]["level"]?filter[0]["level"]:""
  //     this.setState({isFilter:true});
  //   }
  //   else{
  //     this.setState({isFilter:false});
  //   }

  //   let data1 = {
  //     "surveyId":this.state.CurrentSurveyId
  //   }

  //   this.props.dispatch(dashboardActions.getRespondantDetails({...data1,"filters":filter}));

  //   if(!isClose){


  //     if(!isOverall){
  //       this.setState({
  //         ReportDMGindexType:indexType,
  //         ReportDMGcheck:check, 
  //         ReportDMGfiltersListNew:Filters,
  //         ReportxAxis:xAxis
  //       });
  //       this.setState({showReportDMGModal:true});

  //     }
  //     else{
  //       this.setState({loadReport:check});
  //       this.onLoadIndexConfirm(indexType,check, filtersListNew);
  //       this.setState({showReportDMGModal:false});

  //     }

  //   }
  //   else{

  //       let data1x1 = {
  //         "surveyId":this.state.CurrentSurveyId,
  //         "filters":[]
  //       }
  //       this.props.dispatch(dashboardActions.getIndexFilter(data1x1));


  //       this.setState({
  //         ReportDMGindexType:"",
  //         ReportDMGcheck:"", 
  //         ReportDMGfiltersListNew:"",
  //         ReportxAxis:"",
  //         showReportDMGModal:false,
  //         loadReport:false,
  //         isFilter:false
  //       });
  //   }



  // }


  onLoadIndex = (indexType, check, filtersListNew, isFilterTwice, name, isClose) => {

    let X2Axis = "";
    let Filters = [];
    let isAllCheck = false;
    let isOverall = false
    if (filtersListNew && filtersListNew.length > 0) {
      let getIndex = filtersListNew.findIndex(prev => prev.valueList === "All");
      if (getIndex !== -1) {
        X2Axis = filtersListNew[getIndex].level;
        filtersListNew.forEach((item) => {
          if (item.valueList && item.valueList.length > 0) {
            if (item.valueList[0] !== "All") {
              Filters.push(item);
            }
            else {
              isAllCheck = true
            }
          }
          else {
            isAllCheck = true
          }

        });
      }
      else {
        X2Axis = filtersListNew[filtersListNew.length - 1].level;
        Filters = filtersListNew;
      }

      this.setState({ isReportOverall: false });
    }
    else {
      this.setState({ isReportOverall: true });
      isOverall = true;
    }

    // let data7 = {
    //   "surveyId": this.state.CurrentSurveyId,
    //   "xAxis": isFilterTwice?name:X2Axis,
    //   "filters":Filters
    // }


    let MultiValueFilters = [];
    let AllLevelsList = [];
    let FilLevelsList = [];

    if (Filters && Filters.length > 0) {
      Filters.forEach((inner) => {
        if (inner && inner.valueList && inner.valueList.length > 0) {
          inner.valueList.forEach((item) => {
            if (item !== "All") {
              MultiValueFilters.push({
                "level": inner.level,
                "value": item
              })
              FilLevelsList.push(inner.level);

            }
            else {
              AllLevelsList.push(inner.level);

            }

          })
        }

      });
    }

    let NewXaxis = ""
    if (AllLevelsList && AllLevelsList.length > 0) {
      NewXaxis = AllLevelsList[AllLevelsList.length - 1] ? AllLevelsList[AllLevelsList.length - 1] : "";
    }
    else {
      if (FilLevelsList && FilLevelsList.length > 0) {
        NewXaxis = FilLevelsList[FilLevelsList.length - 1] ? FilLevelsList[FilLevelsList.length - 1] : "";
      }
      else {
        NewXaxis = X2Axis
      }
    }

    ////////console.log("AllLevelsList---confirm2--->",AllLevelsList)
    ////////console.log("FilLevelsList---confirm2--->",FilLevelsList)
    ////////console.log("NewXaxis---confirm2--->",NewXaxis)
    ////////console.log("MultiValueFilters---X2Axis--->",X2Axis)



    ////////console.log("MultiValueFilters---confirm2--->",MultiValueFilters)


    let filter = MultiValueFilters;

    let isFilter = false;
    let xAxis = "";
    if (filter && filter.length > 0) {
      isFilter = true;
      xAxis = isFilterTwice ? name : X2Axis;
      // xAxis = filter && filter[0] && filter[0]["level"]?filter[0]["level"]:""
      this.setState({ isFilter: true });
    }
    else {
      this.setState({ isFilter: false });
    }

    let data1 = {
      "surveyId": this.state.CurrentSurveyId
    }

    this.props.dispatch(dashboardActions.getRespondantDetails({ ...data1, "filters": filter }));

    if (!isClose) {

      ////////console.log("filtersListNew---confirm1--->",filtersListNew)
      ////////console.log("filtersListNew---confirm2--->",filter)







      if (!isOverall) {
        this.setState({
          ReportDMGindexType: indexType,
          ReportDMGcheck: check,
          ReportDMGfiltersListNew: filter,
          ReportxAxis: NewXaxis
        });
        this.setState({ showReportDMGModal: true });

      }
      else {

        this.setState({ loadReport: check });
        this.onLoadIndexConfirm(indexType, check, filtersListNew);
        this.setState({ showReportDMGModal: false });

      }

    }
    else {

      let data1x1 = {
        "surveyId": this.state.CurrentSurveyId,
        "filters": []
      }
      this.props.dispatch(dashboardActions.getIndexFilter(data1x1));


      this.setState({
        ReportDMGindexType: "",
        ReportDMGcheck: "",
        ReportDMGfiltersListNew: "",
        ReportxAxis: "",
        showReportDMGModal: false,
        loadReport: false,
        isFilter: false
      });
    }



  }

  onLoadDMGReportConfirmation = (value) => {
    if (value) {
      this.setState({ loadReport: true });
      let {
        ReportDMGindexType,
        ReportDMGcheck,
        ReportDMGfiltersListNew,
        ReportxAxis
      } = this.state;
      this.onLoadIndexConfirm(ReportDMGindexType, ReportDMGcheck, ReportDMGfiltersListNew, ReportxAxis);
      this.setState({ showReportDMGModal: false });
    }
    else {
      this.setState({ loadReport: false });
      this.setState({ showReportDMGModal: false });
    }

  }

  onLoadIndexConfirm = (indexType, check, filtersListNew, xAxis) => {
    this.setState({ loadReport: check });

    let filter = filtersListNew;

    // let isFilter = false;
    // let xAxis = "";
    if (filter && filter.length > 0) {
      // isFilter=true;
      // xAxis = filter && filter[0] && filter[0]["level"]?filter[0]["level"]:""
      this.setState({ isFilter: true });
    }
    else {
      this.setState({ isFilter: false });
    }

    let { isReportOverall } = this.state;


    let isFilter = !isReportOverall;


    // if(isFilter){
    //   this.props.dispatch(dashboardActions.getRespondantDetails({...data1,"filters":filter}));
    // }
    // else{
    //   this.props.dispatch(dashboardActions.getRespondantDetails({...data1,"filters":filter}));

    // }
    let data1x1 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": filter
    }
    // "xAxis": xAxis

    ////////console.log("------data1x1-------",data1x1)
    // this.props.dispatch(dashboardActions.getDemographicIndexMultiFilter(data1x1));
    this.props.dispatch(dashboardActions.getIndexFilter(data1x1));





    if (true) {


      let data1 = {
        "surveyId": this.state.CurrentSurveyId
      }
      this.props.dispatch(dashboardActions.getRespondantDetails({ ...data1, "filters": filter }));
      this.props.dispatch(dashboardActions.getOverallIndex(data1));



      if (isFilter) {
        let data1x1 = {
          "surveyId": this.state.CurrentSurveyId,
          "filters": filter
        }
        // "xAxis": xAxis

        ////////console.log("------data1x1-------",data1x1)
        // this.props.dispatch(dashboardActions.getDemographicIndexMultiFilter(data1x1));
        this.props.dispatch(dashboardActions.getIndexFilter(data1x1));


      }
      else {
        let data1x2 = {
          "surveyId": this.state.CurrentSurveyId
        }
        this.props.dispatch(dashboardActions.getIndex(data1x2));
      }





      let { dashboard } = this.props;
      let { getIndexData } = dashboard;

      ////////console.log("repoirt --->getIndexData: ",getIndexData)

      let IndexValidList = getIndexData ? Object.keys(getIndexData) : [];

      if (IndexValidList && IndexValidList.length > 0) {
        IndexValidList.forEach((item) => {
          if (item && getIndexData && getIndexData[item]) {
            ////////console.log("repoirt --->item: ",item)

            if (parseInt(item) === 1) {
              this.props.dispatch(dashboardActions.getHappinessIndexV2(data1));
              this.props.dispatch(dashboardActions.getHappinessIndexDriver(data1));

              if (isFilter) {
                this.props.dispatch(dashboardActions.getHappinessIndexV2Filter({
                  ...data1,
                  "filters": filter
                }));
                this.props.dispatch(dashboardActions.getHappinessIndexDriverFilter({
                  ...data1,
                  "filters": filter
                }));
              }
              else {
                // this.props.dispatch(dashboardActions.getHappinessIndexV2(data1));
                // this.props.dispatch(dashboardActions.getHappinessIndexDriver(data1));
              }

              //Overall
              this.props.dispatch(dashboardActions.getHappinessDMGQuestion({
                ...data1,
                "template": parseInt(item),
                "filters": []
              }));

              //Filter
              this.props.dispatch(dashboardActions.getHappinessQuestion({
                ...data1,
                "template": parseInt(item),
                "filters": filter
              }));

            }
            else if (parseInt(item) === 2) {
              this.props.dispatch(dashboardActions.getEngagementIndexV2(data1));
              this.props.dispatch(dashboardActions.getEngagementIndexDriver(data1));

              if (isFilter) {
                ////////console.log("--filter---","YES")
                this.props.dispatch(dashboardActions.getEngagementIndexV2Filter({
                  ...data1,
                  "filters": filter
                }));

                this.props.dispatch(dashboardActions.getEngagementIndexDriverFilter({
                  ...data1,
                  "filters": filter
                }));
              }
              else {
                // this.props.dispatch(dashboardActions.getEngagementIndexV2(data1));
                // this.props.dispatch(dashboardActions.getEngagementIndexDriver(data1));
              }
              // this.props.dispatch(dashboardActions.getEngagementIndexV2(data1));
              //Overall
              this.props.dispatch(dashboardActions.getEngagementDMGQuestion({
                ...data1,
                "template": parseInt(item),
                "filters": []
              }));
              //Filter
              this.props.dispatch(dashboardActions.getEngagementQuestion({
                ...data1,
                "template": parseInt(item),
                "filters": filter
              }));
            }
            else if (parseInt(item) === 4) {
              this.props.dispatch(dashboardActions.getStressIndexV2(data1));
              this.props.dispatch(dashboardActions.getStressIndexDriver(data1));
              if (isFilter) {
                this.props.dispatch(dashboardActions.getStressIndexV2Filter({
                  ...data1,
                  "filters": filter
                }));
                this.props.dispatch(dashboardActions.getStressIndexDriverFilter({
                  ...data1,
                  "filters": filter
                }));
              }
              else {
                // this.props.dispatch(dashboardActions.getStressIndexV2(data1));
                // this.props.dispatch(dashboardActions.getStressIndexDriver(data1));
              }
              // this.props.dispatch(dashboardActions.getStressIndexV2(data1));
              //Overall
              this.props.dispatch(dashboardActions.getStressDMGQuestion({
                ...data1,
                "template": parseInt(item),
                "filters": []
              }));
              //Filter
              this.props.dispatch(dashboardActions.getStressQuestion({
                ...data1,
                "template": parseInt(item),
                "filters": filter
              }));
            }
            else if (parseInt(item) === 5) {
              // this.props.dispatch(dashboardActions.getManagerIndexV2(data1));
              // this.props.dispatch(dashboardActions.getManagerIndexDriver(data1));
              //Overall
              this.props.dispatch(dashboardActions.getManagerDMGQuestion({
                ...data1,
                "template": parseInt(item),
                "filters": []
              }));
              //Filter
              this.props.dispatch(dashboardActions.getManagerQuestion({
                ...data1,
                "template": parseInt(item),
                "filters": filter
              }));
              if (isFilter) {
                this.props.dispatch(dashboardActions.getDemographicIndexManager({
                  ...data1,
                  "filters": filter,
                  "xAxis": xAxis
                }));
              }
              else {
                this.props.dispatch(dashboardActions.getManagerIndexDriver(data1));
              }
            }
            else if (parseInt(item) === 6) {
              // this.props.dispatch(dashboardActions.getLeaderIndexV2(data1));
              // this.props.dispatch(dashboardActions.getLeaderIndexDriver(data1));
              //Overall
              this.props.dispatch(dashboardActions.getLeaderDMGQuestion({
                ...data1,
                "template": parseInt(item),
                "filters": []
              }));
              //Filter
              this.props.dispatch(dashboardActions.getLeaderQuestion({
                ...data1,
                "template": parseInt(item),
                "filters": filter
              }));
              if (isFilter) {
                this.props.dispatch(dashboardActions.getDemographicIndexLeader({
                  ...data1,
                  "filters": filter,
                  "xAxis": xAxis
                }));
              }
              else {
                this.props.dispatch(dashboardActions.getLeaderIndexDriver(data1));
              }
            }



          }
        });

      }



      // Page9
      //overall org
      let data9x = {
        "surveyId": this.state.CurrentSurveyId,
        "template": 8,
        "filters": []
      }
      this.props.dispatch(dashboardActions.getQuestionOverall(data9x));
      //Filter org
      let data9 = {
        "surveyId": this.state.CurrentSurveyId,
        "template": 8,
        "filters": filter
      }
      this.props.dispatch(dashboardActions.getQuestionFilter(data9));


      let allowAPI = true;

      if (true) {

        //Page1


        if (allowAPI) {

          // getHappinessIndexV2,
          // getEngagementIndexV2,
          // getHopeIndexV2,
          // getStressIndexV2,
          // getManagerIndexV2,
          // getLeaderIndexV2,


          // this.props.dispatch(dashboardActions.getAllBenchMark());

          // getHappinessIndexDriver,
          // getEngagementIndexDriver,
          // getStressIndexDriver,
          // getManagerIndexDriver,
          // getLeaderIndexDriver,

        }


        //Page2 
        let data2 = {
          "surveyId": this.state.CurrentSurveyId,
          "template": indexType,
          "filters": filter
        }

        if (allowAPI) {
          this.props.dispatch(dashboardActions.getQuestion(data2));

          this.props.dispatch(dashboardActions.getOrgIndexV2(data1));

          //Page3
          if (isFilter) {
            this.props.dispatch(dashboardActions.getDemographicIndexOrg({
              ...data1,
              "filters": filter,
              "xAxis": xAxis
            }));
            this.props.dispatch(dashboardActions.getOrgIndexV2Filter({
              ...data1,
              "filters": filter
            }));
          }
          else {
            this.props.dispatch(dashboardActions.getOrgIndexV2(data1));
          }


          this.props.dispatch(surveyActions.getSurveyById(data1));
        }


        //Page4
        let data3 = {
          "surveyId": this.state.CurrentSurveyId,
          "index": indexType,
          "filters": filter,
          "addValueTemplate": []
        }

        let { getIndexData } = this.props;

        if (getIndexData && getIndexData["5"]) {
          data3["addValueTemplate"].push(5)
        }

        if (getIndexData && getIndexData["6"]) {
          data3["addValueTemplate"].push(6)
        }

        if (allowAPI) {
          this.props.dispatch(dashboardActions.getEngagementRWA(data3));
        }

        //Page5
        let data4 = {
          "surveyId": this.state.CurrentSurveyId,
          "limit": 10,
          "top": true,
          "filters": filter
        }

        if (allowAPI) {
          this.props.dispatch(dashboardActions.getQuestionFavorability(data4));
        }


        //Page6
        let data5 = {
          "surveyId": this.state.CurrentSurveyId,
          "limit": 10,
          "top": false,
          "filters": filter
        }

        if (allowAPI) {
          this.props.dispatch(dashboardActions.getQuestionFavorability2(data5));
        }



        let { dashboard } = this.props;
        let { EmpFilterData } = dashboard;




        if (!isFilter) {

          // Page7


          let data6 = []

          let data7 = []

          if (EmpFilterData && EmpFilterData.length > 0) {
            EmpFilterData.forEach((filter1) => {
              if (filter1.isShowReport) {



                data6.push({
                  "surveyId": this.state.CurrentSurveyId,
                  "xAxis": filter1 && filter1.name ? filter1.name : ""
                });

                data7.push({
                  "surveyId": this.state.CurrentSurveyId,
                  "xAxis": filter1 && filter1.name ? filter1.name : ""
                });




              }

            });
          }


          if (allowAPI) {
            this.props.dispatch(dashboardActions.getDemographicIndexFilterReport(data6));
          }



          // Page8
          this.props.dispatch(dashboardActions.getDemographicOrgReport(data7));















        }
        else {

          // Page7


          let data6 = []

          let data7 = []

          // if(EmpFilterData && EmpFilterData.length>0){
          //   EmpFilterData.forEach((filter1)=>{
          //     if(filter1.isShowReport){



          data6.push({
            "surveyId": this.state.CurrentSurveyId,
            "xAxis": xAxis,
            "filters": filter
          });

          data7.push({
            "surveyId": this.state.CurrentSurveyId,
            "xAxis": xAxis,
            "filters": filter
          });




          //   }

          //   });
          // }


          if (allowAPI) {
            this.props.dispatch(dashboardActions.getDemographicIndexFilterReport(data6));
          }



          // Page8
          this.props.dispatch(dashboardActions.getDemographicOrgReport(data7));



        }







        // NPS------------------------------------------------------------------------

        let dataNPSOverall = {
          "surveyId": this.state.CurrentSurveyId,
          "qtemplateList": [6],
          "filters": []
        }

        this.props.dispatch(generalActions.getQuestion2OthersResultFilter(dataNPSOverall));


        let dataNPS = {
          "surveyId": this.state.CurrentSurveyId,
          "qtemplateList": [6],
          "filters": filter
        }

        this.props.dispatch(generalActions.getQuestionOthersResult(dataNPS));

        let dataNPSFilter = []
        ////////console.log("EmpFilterData",EmpFilterData)

        if (EmpFilterData && EmpFilterData.length > 0) {
          EmpFilterData.forEach((filter1) => {
            if (filter1.isInEngComp) {
              ////////console.log("dataNPSFilter",filter1)
              dataNPSFilter.push({
                "surveyId": this.state.CurrentSurveyId,
                "qtemplateList": [6],
                "xAxis": filter1 && filter1.name ? filter1.name : "",
                "filters": filter
              })

            }

          });
        }

        if (dataNPSFilter && dataNPSFilter.length > 0) {
          this.props.dispatch(generalActions.getQuestionOthersMultiResultFilter(dataNPSFilter));
        }

        // NPS---end---------------------------------------------------------------------










        // Engagement Comparison
        let data10 = [];
        let data10x = [];

        if (EmpFilterData && EmpFilterData.length > 0) {
          EmpFilterData.forEach((filter1) => {
            if (filter1.isInEngComp) {

              data10.push({
                "surveyId": this.state.CurrentSurveyId,
                "xAxis": filter1 && filter1.name ? filter1.name : "",
                "filters": filter
              });

              data10x.push({
                "surveyId": this.state.CurrentSurveyId,
                "xAxis": filter1 && filter1.name ? filter1.name : "",
                "filters": []
              });

            }

          });
        }

        //Filter
        setTimeout(() => {
          this.props.dispatch(dashboardActions.getDemographicIndexMultiFilterEngComp(data10));
        }, 400)

        //Overall
        setTimeout(() => {
          this.props.dispatch(dashboardActions.getDemographicIndexEngCompOverall(data10x));
        }, 400)

        // this.props.dispatch(dashboardActions.getTextQuestion(data1, 111,filter));




      }









    }







  }


  onLoadManagerReport = (managerDetails) => {
    let { dashboard } = this.props;
    let { getIndexData } = dashboard;
    let { ManagerReportFConfig } = this.state;

    let IndexList = getIndexData ? Object.keys(getIndexData) : []

    let mngrId = managerDetails && managerDetails["emp_id"] ? managerDetails["emp_id"] : "";
    let mngrDMGValue = managerDetails && managerDetails["Department"] ? managerDetails["Department"] : "";

    if (mngrId) {

      let data1 = {
        "surveyId": this.state.CurrentSurveyId
      }

      this.props.dispatch(surveyActions.getSurveyById(data1));


      let managerIDNumber = mngrId;

      if (ManagerReportFConfig && ManagerReportFConfig.managerIdType) {
        if (ManagerReportFConfig.managerIdType === "Int") {
          managerIDNumber = parseInt(mngrId)
        }
        else if (ManagerReportFConfig.managerIdType === "String") {
          managerIDNumber = (mngrId).toString()
        }
      }



      if (IndexList.some(prev => (prev.toString()) === "1") && getIndexData && getIndexData["1"]) {

        this.props.dispatch(dashboardActions.getDemographicIndexHappiness({
          ...data1, "xAxis": "ManagerPID",
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber.toString()
            }
          ]
        }));

        this.props.dispatch(dashboardActions.getHappinessIndexByFilter({
          ...data1,
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber
            }
          ]
        }));



        //Happiness Manager
        this.props.dispatch(dashboardActions.getHappinessQuestion({
          ...data1,
          "template": 1,
          "filters": []
        }));
        this.props.dispatch(dashboardActions.getHappinessManagerQuestion({
          ...data1,
          "template": 1,
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber
            }
          ]
        }));
        if (mngrDMGValue) {
          this.props.dispatch(dashboardActions.getHappinessDMGQuestion({
            ...data1,
            "template": 1,
            "filters": [
              {
                "level": "Department",
                "value": mngrDMGValue.toString()
              }
            ]
          }));
        }
      }






      if (IndexList.some(prev => (prev.toString()) === "2") && getIndexData && getIndexData["2"]) {

        this.props.dispatch(dashboardActions.getDemographicIndexEngagement({
          ...data1, "xAxis": "ManagerPID",
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber.toString()
            }
          ]
        }));

        this.props.dispatch(dashboardActions.getEngagementIndexByFilter({
          ...data1,
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber
            }
          ]
        }));




        //Engagement Manager
        this.props.dispatch(dashboardActions.getEngagementQuestion({
          ...data1,
          "template": 2,
          "filters": []
        }));
        this.props.dispatch(dashboardActions.getEngagementManagerQuestion({
          ...data1,
          "template": 2,
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber
            }
          ]
        }));
        if (mngrDMGValue) {
          this.props.dispatch(dashboardActions.getEngagementDMGQuestion({
            ...data1,
            "template": 2,
            "filters": [
              {
                "level": "Department",
                "value": mngrDMGValue.toString()
              }
            ]
          }));
        }
      }


      if (IndexList.some(prev => (prev.toString()) === "4") && getIndexData && getIndexData["4"]) {

        this.props.dispatch(dashboardActions.getDemographicIndexStress({
          ...data1, "xAxis": "ManagerPID",
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber.toString()
            }
          ]
        }));

        this.props.dispatch(dashboardActions.getStressIndexByFilter({
          ...data1,
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber
            }
          ]
        }));




        //Stress Manager
        this.props.dispatch(dashboardActions.getStressQuestion({
          ...data1,
          "template": 4,
          "filters": []
        }));
        this.props.dispatch(dashboardActions.getStressManagerQuestion({
          ...data1,
          "template": 4,
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber
            }
          ]
        }));
        if (mngrDMGValue) {
          this.props.dispatch(dashboardActions.getStressDMGQuestion({
            ...data1,
            "template": 4,
            "filters": [
              {
                "level": "Department",
                "value": mngrDMGValue.toString()
              }
            ]
          }));
        }
      }





      if (IndexList.some(prev => (prev.toString()) === "5") && getIndexData && getIndexData["5"]) {

        this.props.dispatch(dashboardActions.getDemographicIndexManager({
          ...data1, "xAxis": "ManagerPID",
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber.toString()
            }
          ]
        }));

        this.props.dispatch(dashboardActions.getManagerIndexByFilter({
          ...data1,
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber
            }
          ]
        }));




        //Manager Manager
        this.props.dispatch(dashboardActions.getManagerQuestion({
          ...data1,
          "template": 5,
          "filters": []
        }));
        this.props.dispatch(dashboardActions.getManagerManagerQuestion({
          ...data1,
          "template": 5,
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber
            }
          ]
        }));
        if (mngrDMGValue) {
          this.props.dispatch(dashboardActions.getManagerDMGQuestion({
            ...data1,
            "template": 5,
            "filters": [
              {
                "level": "Department",
                "value": mngrDMGValue.toString()
              }
            ]
          }));
        }


      }

      if (IndexList.some(prev => (prev.toString()) === "6") && getIndexData && getIndexData["6"]) {

        this.props.dispatch(dashboardActions.getDemographicIndexLeader({
          ...data1, "xAxis": "ManagerPID",
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber.toString()
            }
          ]
        }));
        this.props.dispatch(dashboardActions.getLeaderIndexByFilter({
          ...data1,
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber
            }
          ]
        }));


        //Leader Manager
        this.props.dispatch(dashboardActions.getLeaderQuestion({
          ...data1,
          "template": 6,
          "filters": []
        }));
        this.props.dispatch(dashboardActions.getLeaderManagerQuestion({
          ...data1,
          "template": 6,
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber
            }
          ]
        }));
        if (mngrDMGValue) {
          this.props.dispatch(dashboardActions.getLeaderDMGQuestion({
            ...data1,
            "template": 6,
            "filters": [
              {
                "level": "Department",
                "value": mngrDMGValue.toString()
              }
            ]
          }));
        }



      }


    }


  }




  onTreeMap = (EMPFieldFirstName) => {
    this.props.dispatch(dashboardActions.EmptyTreeMap());



    let data = {
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getIndex(data));

    let { dashboard } = this.props;
    let { getIndexData } = dashboard;


    let IndexValidList = getIndexData ? Object.keys(getIndexData) : [];
    if (IndexValidList && IndexValidList.length > 0) {
      IndexValidList.forEach((item) => {
        if (item && getIndexData && getIndexData[item]) {
          let template = parseInt(item);
          if (template === 1) {
            this.props.dispatch(dashboardActions.getHappinessIndexDriver(data));
          }
          else if (template === 2) {
            this.props.dispatch(dashboardActions.getEngagementIndexDriver(data));
          }
          else if (template === 4) {
            this.props.dispatch(dashboardActions.getStressIndexDriver(data));
          }
          else if (template === 5) {
            this.props.dispatch(dashboardActions.getManagerIndexDriver(data));
          }
          else if (template === 6) {
            this.props.dispatch(dashboardActions.getLeaderIndexDriver(data));
          }
        }
      })



    }


    let data2 = {
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 6, [], EMPFieldFirstName));


  }


  handleThemeStrenght = (name, filtersListNew) => {
    let X2Axis = "";
    let Filters = [];
    if (filtersListNew && filtersListNew.length > 0) {
      let getIndex = filtersListNew.findIndex(prev => prev.valueList === "All");
      if (getIndex !== -1) {
        X2Axis = filtersListNew[getIndex].level;
        filtersListNew.forEach((item) => {
          if (item.valueList && item.valueList.length > 0) {
            if (item.valueList[0] !== "All") {
              Filters.push(item);
            }
          }
        });
      }
      else {
        X2Axis = filtersListNew[filtersListNew.length - 1].level;
        Filters = filtersListNew;
      }

      this.setState({ isReportOverall: false });
    }



    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "parameterBucketName": name,
      "filters": Filters
    }
    this.props.dispatch(dashboardActions.getAssessmentByTheme(data));
  }

  onAssessmentByEmpID = (emp_id) => {
    if (emp_id) {
      this.props.dispatch(dashboardActions.getAssessmentReportByEmpID({
        "surveyId": this.state.CurrentSurveyId,
        "emp_id": (emp_id).toString()
      }));

      this.props.dispatch(dashboardActions.getSurveyTakenDateAdmin({
        "surveyId": this.state.CurrentSurveyId,
        "emp_id": (emp_id).toString()
      }));
    }
  }

  onDownloadAssessmentType2ByEmpID = (emp_id) => {
    this.props.dispatch(dashboardActions.getType2AssessmentReportByEmpID({
      "surveyId": this.state.CurrentSurveyId,
      "emp_id": (emp_id).toString()
    }));
  }



  // onAssessmentByManagerID = (emp_id) => {
  //   if (emp_id) {
  //     this.props.dispatch(dashboardActions.getAssessmentReportByEmpID({
  //       "surveyId": this.state.CurrentSurveyId,
  //       "emp_id": emp_id
  //     }));
  //   }
  // }

  onAssessmentByManagerPID = (emp_id) => {
    if (emp_id) {

      this.props.dispatch(dashboardActions.getAssessmentEmpListByMngr({
        "surveyId": this.state.CurrentSurveyId,
        "mngr_id": emp_id.toString()
      }));

    }

  }

  onReportModal = () => {
    let reqData = {
      "surveyId": this.state.CurrentSurveyId,
      "isAssessment":true
    }
    this.props.dispatch(dashboardActions.getAnsweredEmployees(reqData));
    this.setState({ showTeamReportModal: true })
  }

  onLoadReportFn = (emp_id) => {
    let reqData = {
      "surveyId": this.state.CurrentSurveyId,
      "emp_id": emp_id
    }
    this.props.dispatch(dashboardActions.getLeadershipReportScore(reqData));
  }



  onTeamAssessmentByEmpID = (mngr_id) => {

    let data50 = {
      "surveyId": this.state.CurrentSurveyId,
      "isAll": true,
      "filters": [{
        "level": "emp_id",
        "value": mngr_id
      }]
    }

    this.props.dispatch(dashboardActions.getAssessmentDimensionsV2(data50));

    let data51 = {
      "surveyId": this.state.CurrentSurveyId,
      "isAll": true,
      "filters": [{
        "level": "ManagerPID",
        "value": mngr_id
      }]
    }

    this.props.dispatch(dashboardActions.getAssessmentDimensions(data51));

    let data52 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": [{
        "level": "ManagerPID",
        "value": mngr_id
      }]
    }

    this.props.dispatch(dashboardActions.getAllAnsweredAssessmentEmpData(data52));

    this.props.dispatch(dashboardActions.getSurveyTakenDateAdmin({
      "surveyId": this.state.CurrentSurveyId,
      "emp_id": (mngr_id).toString()
    }));


  }



  closeReportModal = () => {
    this.setState({ showReportModal: false })
  }
  closeMngrReportModal = () => {
    this.setState({ showMngrReportModal: false })
  }

  closeHiringReportModal = () => {
    this.setState({ showHiringReportModal: false })
  }

  closeTeamReportModal = () => {
    this.setState({ showTeamReportModal: false })
  }


  onOverviewFromHeader = () => {
    this.props.history.goBack()
  }

  getAssessmentDimensionsTop5 = (isTop5) => {
    let data51 = {
      "surveyId": this.state.CurrentSurveyId,
      "isAll": isTop5 ? false : true
    }
    this.props.dispatch(dashboardActions.getAssessmentDimensions(data51));
  }



  handlAssessmentDashboard = (FilterValues, isAll) => {

    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        Filters = FilterValues
      }

    }

    let data51 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": Filters,
      "isAll": isAll ? true : false
    }
    this.props.dispatch(dashboardActions.getAssessmentDimensions(data51));


  }



  loadAllAssessment = () => {

    let data5 = {
      "surveyId": this.state.CurrentSurveyId
    }

    this.props.dispatch(dashboardActions.getAllAnsweredAssessmentEmpData(data5));

  }


  render() {

    let { dashboard, questions, survey, general } = this.props;
    let { questionsList } = questions;

    let { getQuestionAvgScoreData, getQuestion2OthersResultFilterData, getQuestionOthersResultData, getQuestionOthersResultFilterData, getQuestionOthersMultiResultFilterData } = general;



    ////////console.log("----------------------------------------------------------------------------------------------------------")
    ////////console.log("many result: ",getQuestionOthersMultiResultFilterData)
    ////////console.log("----------------------------------------------------------------------------------------------------------")


    let { showTeamReportModal, showHiringReportModal, showMngrReportModal, showReportModal, showPeopleToMeet, customeScoreData, showCustomScoreCard, showReportDMGModal, ManagerReportFConfig, loadReport, isFilter, currentQuestionId, currentQuestionTemplate } = this.state;


    let { getSurveyData, getIndexByFilterDMGData, getTranslateElementAllData, getLanguageData } = survey;



    let {
      getLeadershipParaCategoryData,
      getLeadershipReportScoreData,
      getLeadershipTextDetailsData,


      getAssessmentTop5DescByNamesData,
      loadingAllAssessment,
      getAllAnsweredAssessmentEmpDataList,
      cronbatchAlphaVarianceData,
      getSurveyTakenDateAdminData,
      getAssessmentEmpListByMngrData,
      getAssessmentOnlyDetailsData,
      getAssessmentManagerReportData,
      getAssessmentManagerListData,
      getRespondantDetailsData,
      getAssessmentDimensionsData,
      getAssessmentDimensionsV2Data,
      getAssessmentReportByEmpIDData,
      getAnsweredEmployeesData,

      getAssessmentByThemeData,
      getAssessmentDashboardData,
      getMetaRespondantDetailsData,
      getClusterSaveKeyWord,
      getClusterSentencesData,
      getClusterKeywordsData,
      getTextClusterSentimentCountData,
      textclusterData,
      getTextClusterData,
      getUserAccessData,

      AnswerFeedbackTextData,
      AnswerFeedbackTextKeyWord,

      getQuestionManagerPIDWiseData,
      getIndexScoreManagerPIDWiseData,
      getIndexDriverManagerPIDWiseData,

      clusterAnalysisError,
      textDistError,
      TreeMapError,
      neighborGraphError,
      wordCloudError,
      topicModellingError,
      getCorrelationDimenionVsDimensionError,
      crossTabError,



      getQuestionCustomTemplateData,
      getQuestionMapData,
      getCompanyNameOnlyData,
      getIDPLastDateData,
      getAllManagerGoalPlanData,
      clusterAnalysisData,
      clusterAnalysisCount,

      getOverallScoreBySurveyIdData,

      getAllManagerScoresData,

      getMngrReportDownloadStatusData,
      getMngrReportDownloadStatusTotal,

      getTopicListData,

      clusterAnalysisMSG,

      topicModellingData,

      getClusterEmpInfoListData,

      getParameterLibraryListData,

      ClientProfileData, SurveyList, HappinessIndexMNGRData,
      EngagementIndexMNGRData, HopeIndexMNGRData, StressIndexMNGRData,

      getDemographicIndexMultiFilterEngCompData,
      getQuestionCommentsData,

      getOrgIndexV2FilterData,

      TreeMapData,
      textDistTreeMapData,
      EmpFilterData,
      getEngagementRWAData,
      getDemographicIndexFilterReportData,

      textDistData,
      getDemographicIndexMultiFilterData,

      ManagerListData, getDemographicIndexData,

      wordCloudMultiTextData,
      wordMultiTextKeyWord,

      loading,

      crossTabAnalysisData,
      getQuestionFavorability2Data,

      getManagerListDashboardManagerIndexData,


      getDemographicOrgReportData,

      getDemographicIndexEngCompOverallData,


      HappinessIndexNewData,
      EngagementIndexNewData,
      HopeIndexNewData,
      StressIndexNewData,
      ManagerIndexNewData,




      getQuestionFilterData,

      OrgCoreIndexData,

      getIndexFilterData,
      getManagerListDomainFilterData,

      getQuestionData,

      getDemographicIndexHappinessData,
      getDemographicIndexEngagementData,
      getDemographicIndexHopeData,
      getDemographicIndexStressData,
      getDemographicIndexManagerData,
      getDemographicIndexLeaderData,

      getQuestionManagerOfHappinessData,
      getQuestionManagerOfEngagementData,
      getQuestionManagerOfStressData,
      getQuestionManagerOfManagerData,


      // getHappinessIndexV2Data,
      // getEngagementIndexV2Data,
      // getStressIndexV2Data,
      // getManagerIndexV2Data,

      getDemographicIndexScatterData,


      getHappinessCorrelationData,
      getEngagementCorrelationData,
      getStressCorrelationData,
      getManagerCorrelationData,
      getLeaderCorrelationData,


      getRWAnalysisData,


      getTextQuestionData,

      wordCloudData,
      AdvanceWordCloudData,

      wordTextKeyWord,

      cooccurmatrixData,


      getQuestionFavorabilityData,



      getCommentData,

      crossTabData,
      crossTabDMGData,

      getQuestionOverallData,

      getCorrelationDimenionVsDimensionData,
      getCorrelationDimensionVsDimensionFilterData,
      getOrgIndexV2Data,
      getAllBenchMarkData,




      getHappinessQuestionData,
      getHappinessManagerQuestionData,
      getHappinessDMGQuestionData,

      getEngagementQuestionData,
      getEngagementManagerQuestionData,
      getEngagementDMGQuestionData,

      getHopeQuestionData,

      getStressQuestionData,
      getStressManagerQuestionData,
      getStressDMGQuestionData,


      getManagerQuestionData,
      getManagerManagerQuestionData,
      getManagerDMGQuestionData,


      getLeaderQuestionData,
      getLeaderManagerQuestionData,
      getLeaderDMGQuestionData,

      getHappinessIndexV2Data,
      getEngagementIndexV2Data,
      getHopeIndexV2Data,
      getStressIndexV2Data,
      getManagerIndexV2Data,
      getLeaderIndexV2Data,

      getHappinessIndexDriverData,
      getEngagementIndexDriverData,
      getHopeIndexDriverData,
      getStressIndexDriverData,
      getManagerIndexDriverData,
      getLeaderIndexDriverData,


      getHappinessIndexV2DemographicData,
      getEngagementIndexV2DemographicData,
      getHopeIndexV2DemographicData,
      getStressIndexV2DemographicData,
      getDemographicIndexOrgData,


      getHappinessIndexByFilterData,
      getEngagementIndexByFilterData,
      getStressIndexByFilterData,
      getManagerIndexByFilterData,
      getLeaderIndexByFilterData,
      getOverallIndexData,


      getHappinessIndexV2FilterData,
      getEngagementIndexV2FilterData,
      getHopeIndexV2FilterData,
      getStressIndexV2FilterData,

      getHappinessIndexDriverFilterData,
      getEngagementIndexDriverFilterData,
      getHopeIndexDriverFilterData,
      getStressIndexDriverFilterData,



      getDemographicIndexError,
      crossTabAnalysisError,
      wordCloudTextError,
      textDistTreeError,
      getQuestionFavorabilityError,
      getClusterEmpInfoError,


      getOrgQuestionData,
      getOrgDMGQuestionData,
      getOrgManagerQuestionData,
      getOrgCoreDriverSentimentData,
      getFeedbackTextQuestionData


    } = dashboard;

    let ErrorOtherObj = {
      "getDemographicIndexError": getDemographicIndexError,
      "getCorrelationDimenionVsDimensionError": getCorrelationDimenionVsDimensionError,
      "crossTabAnalysisError": crossTabAnalysisError,

      "wordCloudTextError": wordCloudTextError,
      "neighborGraphError": neighborGraphError,
      "TreeMapError": TreeMapError,
      "textDistError": textDistError,
      "textDistTreeError": textDistTreeError,
      "topicModellingError": topicModellingError,
      "getQuestionFavorabilityError": getQuestionFavorabilityError,
      "clusterAnalysisError": clusterAnalysisError,
      "getClusterEmpInfoError": getClusterEmpInfoError


    }







    let { showManagerGoalPlan, Mindextype, MQuesIndextype, ManagerListDasboardDataObj, ManagerListQuestionDataObj, SelectedAPDimension, SelectedAPAction, SelectedAPTask } = this.state;


    //ROLE MANAGEMENT ----------------------------------------------
    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }



    function getFilterViewFn(getIndexDMGData, getUserAcc) {
      let FilterDMGDataObj = {}
      if (getIndexDMGData) {
        let list = Object.keys(getIndexDMGData);
        if (list && list.length > 0) {
          list.forEach((key) => {
            if (getUserAcc && getUserAcc[key] && getUserAcc[key].length > 0) {
              FilterDMGDataObj[key] = getUserAcc[key]
            } else {
              FilterDMGDataObj[key] = getIndexDMGData[key]
            }
          })
        }

      }
      return FilterDMGDataObj

    }
    getIndexByFilterDMGData = getFilterViewFn(getIndexByFilterDMGData, getUserAccessData)

    console.log("getParameterLibraryListData----NEW1--->", getParameterLibraryListData)


    function GetParameterLabel(name) {
      let AllParameterObj = {};
      let NewParameterList = dashboard && dashboard.getParameterLibraryListData && dashboard.getParameterLibraryListData.length > 0 ? dashboard.getParameterLibraryListData : []
      if (NewParameterList && NewParameterList.length > 0) {
        NewParameterList.forEach((item) => {
          if (item && item.name) {
            AllParameterObj[item.name] = item && item.label ? item.label : item.name;
          }
        });
      }
      console.log("AllParameterObj----NEW2--->", AllParameterObj)

      return (AllParameterObj && AllParameterObj[name] ? AllParameterObj[name] : name);
    }


    let SHOW_ELEMENT = false;
    let isCrole = false;
    let clientData = this.props.dashboard.ClientProfileData;
    if (clientData && clientData.role) {

      if (clientData.role === "ADMIN") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if (clientData.role === "MNGR") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "CROLE") {
        isCrole = true
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "EMP") {
        SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    function accessAllow(item) {
      return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))
    }

    //END -------------------------------------------------------




    let ManagerIndexQuestionData = '';

    if (MQuesIndextype === 1) {
      ManagerIndexQuestionData = getQuestionManagerOfHappinessData;
    }
    else if (MQuesIndextype === 2) {
      ManagerIndexQuestionData = getQuestionManagerOfEngagementData;
    }
    else if (MQuesIndextype === 4) {
      ManagerIndexQuestionData = getQuestionManagerOfStressData;
    }
    else if (MQuesIndextype === 5) {
      ManagerIndexQuestionData = getQuestionManagerOfManagerData;
    }




    let ManagerIndexDriverData = [];

    if (Mindextype === 1) {
      ManagerIndexDriverData = getDemographicIndexHappinessData;
    }
    else if (Mindextype === 2) {
      ManagerIndexDriverData = getDemographicIndexEngagementData;
    }
    else if (Mindextype === 4) {
      ManagerIndexDriverData = getDemographicIndexStressData;
    }
    else if (Mindextype === 5) {
      ManagerIndexDriverData = getDemographicIndexManagerData;
    }




    let { showAllQuestions, showOtherTypes, showReport, showSidebar, CurrentSurveyId, indexType, showOverview, showIndex, showManagerList, showAdvanceComparision, showTextAnalysis, showStrengthAndWeaknesses, showManagerActionPlan, DynaObj, ManagerDynaObj, QuesDynaObj } = this.state;
    let { getAllParameterListData, getAllManagerActionPlanData, getManagerActionPlanData, getIndexData, getDemographicIndexForManagerData, wordCloudTextData, neighborGraphData } = dashboard;

    // //////console.log('showOverview: ',showOverview)
    // //////console.log('showIndex: ',showIndex)
    // //////console.log('showManagerList: ',showManagerList)
    // //////console.log('sshowAdvanceComparision: ',showAdvanceComparision)
    let ManagerNameIdList = getDemographicIndexForManagerData;

    if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "MNGR") {
      this.props.history.push(`/app/manageractionplan`)
    } else if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "EMP") {
      this.props.history.push(`/app/employeeactionplan`)
    }


    console.log('------------( getFeedbackTextQuestionData )----->', getFeedbackTextQuestionData)


    function checkIndex(key) {
      let check = false
      if (key === 1) { check = true }
      else if (key === 2) { check = true }
      else if (key === 3) { check = true }
      else if (key === 4) { check = true }
      else if (key === 5) { check = true }
      else if (key === 6) { check = true }
      else if (key === 7) { check = true }
      else if (key === 9) { check = true }
      else { check = false }

      return check;
    }

    let IndexList = []
    let newFilterObj = {}
    if (getIndexData) {



      IndexList = Object.keys(getIndexData).map((key) => [Number(key), getIndexData[key]]);

      if (getIndexFilterData) {
        if (IndexList && IndexList.length > 0) {
          IndexList.forEach((data) => {
            if (checkIndex(data[0])) {
              newFilterObj[data[0]] = getIndexFilterData[data[0]];
            }
          });
        };
      }
    }

    let IndexDimension = {
      "Happiness": ['Time', 'Being', 'Relationship', 'WorkLife', 'Achivement', 'Meeningfull'],
      "Engagement": ['Pride', 'Presence', 'Performance'],
      "Stress": ['Recognition', 'WorkSatisfaction', 'Inclusion', 'Collaboration', 'Empowerment'],
      "Manager": ['Connect', 'ACT', 'Respect', 'Empower'],
      "Leader": ['Vision', 'Impact', 'Execute', 'Wisdom']
    }


    function CountRestrict(count, isOverall, section) {
      let isCountLessThanFive = false;

      // ////////console.log("count---",count)

      if (count !== "NaN") {
        if (isOverall) {
          isCountLessThanFive = false;
        }
        else {
          if (section === "rwa") {
            if (parseInt(count) < 30) {
              isCountLessThanFive = true;
            }
          }
          else {
            if (parseInt(count) < 5) {
              isCountLessThanFive = true;
            }
          }

        }
      }
      // ////////console.log("isCountLessThanFive---",isCountLessThanFive)

      return isCountLessThanFive
    }



    // ////////console.log("getSurveyData------------>",getSurveyData)


    function FormattFun(inputData, isSummary) {

      let LIST = []
      let prevTemList = []
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {

          let templateId = ques.total[0]._id.qtemplate;

          let temp = {
            name: ques && ques.question && ques.question[0] && ques.question[0].name ? ques.question[0].name : "",
            answerList: [],
            templateId: templateId
          }
          if (ques && ques.total && ques.total.length > 0) {
            ques.total.forEach((item) => {
              let ans = {
                count: item.count,
                answerText: item._id.answerText,
                heading: item && item._id && item._id.heading ? item._id.heading : "",
                parentId: item && item._id && item._id.parentId ? item._id.parentId : "",
                template: item._id.qtemplate,
                QuesId: item._id.questionId
              }
              temp["answerList"].push(ans);
            });
          }


          if (isSummary) {
            let getIndex = prevTemList.findIndex(prev => prev === templateId);
            if (getIndex === -1) {
              LIST.push(temp);
              prevTemList.push(templateId)
            }
          }
          else {
            LIST.push(temp);
          }


        });
      }
      return LIST
    }
    let AllQuesList = FormattFun(getQuestionOthersResultData, false).filter(x => x.templateId === currentQuestionTemplate);


    ////////console.log("----AllQuesList----,",AllQuesList)
    ////////console.log("----getQuestionOthersResultData----,",getQuestionOthersResultData)


    //////console.log('currentQuestionId, currentQuestionTemplate :',currentQuestionId, currentQuestionTemplate );






    let surveyLogo = "";

    let surveyId = getSurveyData && getSurveyData.id ? getSurveyData.id : "";

    if (surveyId === "618b673c9503f41bad61667f") {
      surveyLogo = 'img/logo/kotaklifelogo.png';
    }
    else if (surveyId === "61541d6cf0f59b75a9b50d44") {
      surveyLogo = 'img/logo/tcillogo.jpeg';
    }
    else if (surveyId === "61b1850ada0e4ed403288e52") {
      surveyLogo = 'img/logo/tcellogo.jpg';
    }
    else if (surveyId === "61c06c871a708fbb5cf1c9a3") {
      surveyLogo = 'img/logo/bppllogo.png';
    }
    else if (surveyId === "5fa04d316ede0f0336066cce") {
      surveyLogo = 'img/logo/happypluslogo.png';
    }
    else if (surveyId === "621dc1de0f73b3a8e532f1b5") {
      surveyLogo = 'img/logo/axissecuritylogo.png';
    }



    function getEMPFilterLabel(name) {

      let label = name;
      if (EmpFilterData && EmpFilterData.length > 0) {
        let getIndex = EmpFilterData.findIndex(prev => prev.name === name);
        if (getIndex !== 1) {
          label = EmpFilterData && EmpFilterData[getIndex] && EmpFilterData[getIndex].label ? EmpFilterData[getIndex].label : name
        }

      }
      return label
    }



    // getHappinessIndexV2DemographicData
    // getEngagementIndexV2DemographicData
    // getHopeIndexV2DemographicData
    // getStressIndexV2DemographicData

    function ObjectFieldAveragingFn(getTempIndexV2DemographicData, type) {
      let count = 0
      let keyList = Object.keys(getTempIndexV2DemographicData && getTempIndexV2DemographicData[0] ? getTempIndexV2DemographicData[0] : {});
      let obj = {}
      if (keyList && keyList.length > 0) {
        keyList.forEach((key) => {
          obj[key] = 0;
        });
      }
      if (getTempIndexV2DemographicData && getTempIndexV2DemographicData.length > 0) {
        getTempIndexV2DemographicData.forEach((item) => {
          if (keyList && keyList.length > 0) {
            keyList.forEach((key) => {
              obj[key] += item[key];
              count += item.count;
            });
          }
        });
        if (keyList && keyList.length > 0) {
          keyList.forEach((key) => {
            obj[key] /= getTempIndexV2DemographicData.length;
          });
        }
      }
      if (type === "count") {
        obj["count"] = count;
      }
      return obj
    }



    function getSurveyDetails(name) {
      return getSurveyData && getSurveyData[name] ? getSurveyData[name] : "NaN";
    }

    let isLifecycle = false
    if (getSurveyData && getSurveyData.isRiskSurveyType === "DEFAULTDAYS") {
      isLifecycle = true
    }



    ////////console.log("<><><><< isLifecycle- <><><><<--->",isLifecycle)






    //Access Compoenent specific


    function allowSpecificFn(name) {
      if (name === "tce" && (CurrentSurveyId === "61b1850ada0e4ed403288e52")) {
        return true
      }
      else {
        return false
      }
    }



    let createdAt = showTeamReportModal ?

      moment(Date.now()).utcOffset("+05:30").format("DD MMMM, YYYY")
      : getSurveyTakenDateAdminData && getSurveyTakenDateAdminData.createdAt ? moment(parseInt(getSurveyTakenDateAdminData.createdAt)).utcOffset("+05:30").format("DD MMMM, YYYY") : "";



    let cronBachAlpha = {
      score: 0
    };
    if (
      cronbatchAlphaVarianceData &&
      cronbatchAlphaVarianceData.score &&
      cronbatchAlphaVarianceData.score > 0
    ) {
      cronBachAlpha["score"] = spliceDecimal(cronbatchAlphaVarianceData.score, 2);
    }




    return (
      <>
        <LoadingOverlay
          active={loading}
          // active={false}

          spinner
          text='Loading Data...'
          className="loader"
        >


          <div className='h-screen overflow-hidden bg-gray-50'>

            <Header
              ClientProfileData={ClientProfileData}
              handleLogout={this.handleLogout}
              ChooseSurvey={this.ChooseSurvey}
              CurrentSurveyId={CurrentSurveyId}
              props={this.props}
              onOverviewFromHeader={this.onOverviewFromHeader}
              getSurveyData={getSurveyData}
              companyName={getCompanyNameOnlyData && getCompanyNameOnlyData.name ? getCompanyNameOnlyData.name : ""}
            />



            {/* header end */}
            {/*sub nav bar 1 */}
            <div className="px-4  md:flex  justify-between items-center bg-white
      border-b py-2.5">
              <div className="flex justify-between w-full space-x-2 text-gray-700">

                <div className='flex space-x-2'>
                  <span className="material-symbols-outlined -mt-0.5">description</span>
                  <h2 className="text-sm font-medium truncate"> {getSurveyDetails("name")} </h2>
                </div>

                <div onClick={() => this.handleMobileSidebar(!this.state.showMobileSidebar)}>
                  <span className="cursor-pointer material-symbols-outlined lg:hidden">menu_open</span>
                </div>

              </div>

              {/* <div className="inline-flex items-center">
          <button className="px-4 py-2 text-xs font-medium uppercase border rounded-full border-sky-500 text-sky-500 ">VIEW CAMPAIGN</button>
        </div> */}

            </div>
            {/* end */}
            <div className="flex w-full" style={{ height: 'calc(100% - 6.5rem)' }}>
              {/*this is side bar  */}


              <Sidebar
                SurveyList={SurveyList}
                onOverview={this.onOverview}
                onOptionType={this.onOptionType}
                onManagerList={this.onManagerList}
                onOrganizationCore={this.onOrganizationCore}
                indexType={indexType}
                getIndexData={newFilterObj}
                CurrentSurveyId={CurrentSurveyId}
                showSidebar={showSidebar}
                handleSidebar={this.handleSidebar}
                showMobileSidebar={this.state.showMobileSidebar}
                isLifecycle={isLifecycle}
                callOverviewApi={this.callOverviewApi}
              />




              {showOverview ?
                <Overview

                  getIndexData={newFilterObj}
                  SurveyList={SurveyList}
                  CurrentSurveyId={CurrentSurveyId}
                  onOptionType={this.onOptionType}

                  getHappinessIndexV2Data={getHappinessIndexV2Data}
                  getEngagementIndexV2Data={getEngagementIndexV2Data}
                  getStressIndexV2Data={getStressIndexV2Data}
                  getManagerIndexV2Data={getManagerIndexV2Data}
                  getRespondantDetailsData={getRespondantDetailsData}

                  getCommentData={getCommentData}

                  getQuestionOthersResultData={getQuestionOthersResultData}

                  onAllQuestions={this.onAllQuestions}

                  getQuestionAvgScoreData={getQuestionAvgScoreData}

                  onCustomScoreCard={this.onCustomScoreCard}

                  loading={loading}





                  getAssessmentDashboardData={getAssessmentDashboardData}
                  getAssessmentByThemeData={getAssessmentByThemeData}
                  handleThemeStrenght={this.handleThemeStrenght}

                  getIndexByFilterDMGData={getIndexByFilterDMGData}


                  handlAssessmentDashboard={this.handlAssessmentDashboard}

                  onReportModal={this.onReportModal}

                  getAssessmentDimensionsData={getAssessmentDimensionsData}

                  getAssessmentDimensionsTop5={this.getAssessmentDimensionsTop5}


                  getEMPFilterLabel={getEMPFilterLabel}
                  EmpFilterData={EmpFilterData}
                  getIndexFilterData={getIndexFilterData}


                  cronBachAlpha={cronBachAlpha}

                  getAllAnsweredAssessmentEmpDataList={getAllAnsweredAssessmentEmpDataList}

                  loadAllAssessment={this.loadAllAssessment}

                  loadingAllAssessment={loadingAllAssessment}

                />
                : null}


            </div>
          </div>



          {showTeamReportModal ?
            <LeadershipReport
              getLeadershipReportScoreData={getLeadershipReportScoreData}
              getLeadershipParaCategoryData={getLeadershipParaCategoryData}
              GetParameterLabel={GetParameterLabel}
              onLoadReportFn={this.onLoadReportFn}
              getLeadershipTextDetailsData={getLeadershipTextDetailsData}

              
              closeReportModal={this.closeTeamReportModal}
              getAssessmentDimensionsData={getAssessmentDimensionsData}
              getAssessmentDimensionsV2Data={getAssessmentDimensionsV2Data}
              getAllAnsweredAssessmentEmpDataList={getAllAnsweredAssessmentEmpDataList}
              getAssessmentTop5DescByNamesData={getAssessmentTop5DescByNamesData}

              getAnsweredEmployeesData={getAnsweredEmployeesData}
              getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
              onTeamAssessmentByEmpID={this.onTeamAssessmentByEmpID}
              onDownloadAssessmentType2ByEmpID={this.onDownloadAssessmentType2ByEmpID}
              getLanguageData={getLanguageData}
              getTranslateElementAllData={getTranslateElementAllData}
              createdAt={createdAt}
            />
            : null}





        </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard, questions, survey, general } = state;
  return {
    loggingIn,
    users,
    dashboard,
    questions,
    survey,
    general
  };
}
export default connect(mapStateToProps)(Dashboard);
