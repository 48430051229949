import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";
import { graphic } from 'echarts';

class QuestionStack extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { colorTheme, question, getQuestionFilterData, currFilterValue, currFilterName } = this.props;


    // //console.log('getQuestionFilterData:--ss-x> ',getQuestionFilterData)


    function hexToRgbA(hex, opacity) {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
      throw hex;
    }



    let filterQuestion = {}
    if (question && getQuestionFilterData && getQuestionFilterData.length > 0) {
      getQuestionFilterData.forEach((quesFilter) => {
        if (quesFilter.name === question.name) {
          filterQuestion = quesFilter;
        }
      })
    }


    let total = 0
    let names = []
    let weightage1 = [];
    let weightage2 = [];
    let weightage3 = [];
    let weightage4 = [];
    let weightage5 = [];
    let weightage6 = [];

    // let ColorList = ["#757575","#e60000","#ff3333","#ff8080","#99e699","#70db70","#33cc33"];
    let ColorList = ["#757575", "#ff6600", "#ff944d", "#ffb380", "#9fbfdf", "#6699cc", "#336699"];

    if (colorTheme === 2) {
      ColorList = ["#757575", "#ff6600", "#ff944d", "#ffb380", "#9fbfdf", "#6699cc", "#336699"];
    }
    else if (colorTheme === 1) {
      ColorList = ["#757575", "#e60000", "#ff3333", "#ff8080", "#99e699", "#70db70", "#33cc33"];
    }

    if (question) {
      total = question["Strongly Agree"] + question["Agree"] + question["Slightly Agree"] + question["Slightly Disagree"] + question["Disagree"] + question["Strongly Disagree"];
      names.push({
        value: "Overall",
        textStyle: { fontWeight: 'bold' }
      })
      weightage1.push({
        value: (parseFloat(question["Strongly Disagree"]) * 100 / total).toFixed(2),
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: hexToRgbA(ColorList[1], "0.50")
            },
            {
              offset: 1,
              color: ColorList[1]
            }])
        }
      });
      weightage2.push({
        value: (parseFloat(question["Disagree"]) * 100 / total).toFixed(2),
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: hexToRgbA(ColorList[2], "0.50")
            },
            {
              offset: 1,
              color: ColorList[2]
            }])
        }

      });
      weightage3.push({
        value: (parseFloat(question["Slightly Disagree"]) * 100 / total).toFixed(2),
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: hexToRgbA(ColorList[3], "0.50")
            },
            {
              offset: 1,
              color: ColorList[3]
            }])
        }

      });
      weightage4.push({
        value: (parseFloat(question["Slightly Agree"]) * 100 / total).toFixed(2),
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: hexToRgbA(ColorList[4], "0.50")
            },
            {
              offset: 1,
              color: ColorList[4]
            }])
        }

      });
      weightage5.push({
        value: (parseFloat(question["Agree"]) * 100 / total).toFixed(2),
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: hexToRgbA(ColorList[5], "0.50")
            },
            {
              offset: 1,
              color: ColorList[5]
            }])
        }

      });
      weightage6.push({
        value: (parseFloat(question["Strongly Agree"]) * 100 / total).toFixed(2),
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: hexToRgbA(ColorList[6], "0.50")
            },
            {
              offset: 1,
              color: ColorList[6]
            }])
        }

      });
    }




    return (

      <div className='w-full'>
        {true ?
          <ReactEcharts
            className=' '
            style={{ height: '36px',  width: '100%' }}
            option={{
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow',
                }
              },
              xAxis: {
                type: 'value',
                axisLine: 'left',
                splitLine: {
                  lineStyle: {
                    color: '#fff'
                  }
                },
                axisLabel: {
                  show: false
                },
                max: 100

              },
              yAxis: {
                type: 'category',
                data: names,
                position: 'left',
                inverse: true,
                axisLine: {
                  lineStyle: {
                    color: '#fff'
                  }
                },
                axisLabel: {
                  show: false
                }
              },
              axisLabel: {
                color: "#000",
                interval: 0,
                formatter: function (value) {
                  if (value.length > 20) {
                    return value.substring(0, 20) + ".. :";
                  } else {
                    return value + ":";
                  }
                }
              },
              series: [
                {
                  name: 'Strongly Disagree',
                  type: 'bar',
                  stack: '总量',
                  barWidth: '30px',
                  label: {
                    show: true,
                    color: "#000",
                    position: 'insideRight',
                    formatter: function (d) {
                      if (parseInt(d.value) < 7) {
                        return '';
                      }
                      else {
                        return d.value;
                      }
                    }
                  },
                  data: weightage1,
                  inverse: true
                },
                {
                  name: 'Disagree',
                  type: 'bar',
                  stack: '总量',
                  barWidth: '30px',
                  label: {
                    show: true,
                    color: "#000",
                    position: 'insideRight',
                    formatter: function (d) {
                      if (parseInt(d.value) < 7) {
                        return '';
                      }
                      else {
                        return d.value;
                      }
                    }
                  },
                  data: weightage2,
                  inverse: true
                },
                {
                  name: 'Slightly Disagree',
                  type: 'bar',
                  stack: '总量',
                  barWidth: '20px',
                  label: {
                    show: true,
                    color: "#000",
                    position: 'insideRight',
                    formatter: function (d) {
                      if (parseInt(d.value) < 7) {
                        return '';
                      }
                      else {
                        return d.value;
                      }
                    }
                  },
                  data: weightage3,
                  inverse: true
                },
                {
                  name: 'Slightly Agree',
                  type: 'bar',
                  stack: '总量',
                  barWidth: '20px',
                  label: {
                    show: true,
                    color: "#000",
                    position: 'insideRight',
                    formatter: function (d) {
                      if (parseInt(d.value) < 7) {
                        return '';
                      }
                      else {
                        return d.value;
                      }
                    }
                  },
                  data: weightage4,
                  inverse: true
                },
                {
                  name: 'Agree',
                  type: 'bar',
                  stack: '总量',
                  barWidth: '20px',
                  label: {
                    show: true,
                    color: "#000",
                    position: 'insideRight',
                    formatter: function (d) {
                      if (parseInt(d.value) < 7) {
                        return '';
                      }
                      else {
                        return d.value;
                      }
                    }
                  },
                  data: weightage5,
                  inverse: true
                },
                {
                  name: 'Strongly Agree',
                  type: 'bar',
                  stack: '总量',
                  barWidth: '20px',
                  label: {
                    show: true,
                    color: "#000",
                    position: 'insideRight',
                    formatter: function (d) {
                      if (parseInt(d.value) < 7) {
                        return '';
                      }
                      else {
                        return d.value;
                      }
                    }
                  },
                  data: weightage6,
                  inverse: true
                }
              ],
              grid: {
                left: '1%',
                right: '1%',
                bottom: '0%',
                top: '0%',
                // containLabel: true
              },
            }}
          />
          : null}

      </div>


    );
  }
}
function mapStateToProps(state) {
  //   //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionStack);
