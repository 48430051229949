import React from 'react';
import ReactEcharts from "echarts-for-react";
import { graphic } from 'echarts';


export default function BarCorelation(props) {

  let { GetParameterLabel, getCorrelationData, CountRestrict, List, XAxis1 } = props;


  console.log('XAxis1:-------->>> ', XAxis1)
  console.log('List:-------->>> ', List)


  return (
    <>
      {List && List.length > 0 ?
        <div className="pt-1 ">
          <ReactEcharts
            option={{
              tooltip: {
                trigger: 'item',
                formatter: function (a) {
                  //console.log('params: ', a)
                  return '<b>' + XAxis1[a.dataIndex] + '</b></br>' + 'Value: ' + ((a.value) ? a.value.toFixed(2) : 0).toString()
                }
              },
              xAxis: {
                type: 'category',
                data: XAxis1,
                axisLabel: {
                  show: true,
                  textStyle: {
                    fontSize: 12,
                    fontWeight: 'bold'
                  },
                }
              },
              yAxis: {
                type: 'value',
              },
              series: [{
                data: List,
                type: 'bar',
                showBackground: true,
                barWidth: '30px',
                color: new graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#62cff4'
                  },
                  {
                    offset: 1,
                    color: '#2c67f2'
                  }]),
              }]
              ,
              grid: {
                left: '0%',
                right: '0%',
                bottom: '3%',
                top: '2%',
                containLabel: true
              },

            }}

          />

          <div className="text-center"><b>{"X-Axis:"}</b>{" Drivers,"} <b>{"Y-Axis:"}</b>{" Correlation"}</div>
        </div>
        : null}

      <div className="text-sm text-[#3d405B]/70">* We are not showing data where employee count is less than 5</div>

    </>
  );
}
