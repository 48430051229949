import React, { Component } from 'react';
import { connect } from 'react-redux';
import BoxPlot from './Charts/BoxPlot';
import Commentary from '../../../Components/Commentary/Commentary';
import Filter from '../../../../../CommonComponents/Filters/IndexFilter/Filter';

class DriverComparision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',


      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: ''
    }
  }
  componentDidMount() {

  }


  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }


  handleMultifilterIndexScore2 = (finalFilter) => {
    let { handleMultiFilterQuantile } = this.props;
    this.setState({ FilterValues: finalFilter });
    handleMultiFilterQuantile(finalFilter);

  }

  render() {
    let { getIndexQuantileError, selectedFilterValue, loading,CountRestrict,getIndexQuantileCount, getEMPFilterLabel,
      indexType, optionType, getCommentData,ErrorIndexObj,

      getQuestionData, getQuestionFilterData, handleQuantileFilter, EmpFilterData, getIndexFilterData, getIndexQuantileData, getIndexByFilterDMGData

    } = this.props;

    let { currentSelectValue, FilterValues } = this.state;


    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;
    //  let DemographicDataScore = [];
    //  let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }


      //  DemographicDataScore=getDemographicIndexMultiFilterData;
      //  DemographicDataDistribution=getIndexDistributionData;

    }

    let { GetParameterLabel, XAxis, name } = this.props;
    let IndexQuantile = CountRestrict(getIndexQuantileCount)?"NaN":getIndexQuantileData;

    return (
      <>
            <div className="md:items-center justify-between flex md:flex-row flex-col gap-4">
            <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
            />

            {/* <span className="text-xs font-medium text-blue-500 hidden">Need Help?</span> */}
            <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

            </div>


        {true ?
          <>

            {/* <div className="xl:flex lg:flex md:flex justify-start items-center"> */}

              {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        <Filter 
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        /> */}



            {/* </div> */}

            {/* {FilterValues && FilterValues.length > 0 ?
              <div className=" px-4 xl:flex lg:flex md:flex justify-left items-center bg-white border-b py-3 h-10 ">
                {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
                  <p className="text-sm mx-2 my-4  flex"><p className="text-blue-500 mr-1">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
                ) : null}</div>
              : null} */}



            <div onClick={() => this.closeFilter(false)} className=" " >
              {/* <div className="flex w-full" >


                <div className="bg-white rounded w-full shadow-lg px-4 m-4">
                  <div className="text-lg font-semibold py-4">{name} Behaviour-Range</div> */}

                 <div className="w-full" >

                  <div className="grid md:grid-cols-1 grid-cols-1 gap-4 ">

                  <div className=" bg-white hover:shadow-lg duration-150 transition  border p-6 rounded-lg   cursor-pointer">

                      <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">{name} Behaviour-Range</h1>
    



                      {ErrorIndexObj && ErrorIndexObj["getIndexQuantileError"]?
                    <>
                      <div className='text-gray-400 font-medium text-center my-24 text-2xl'>{ErrorIndexObj["getIndexQuantileError"]}</div>
                    </>
                    :
                    <>
                  {IndexQuantile?
                  <BoxPlot getIndexQuantileData={IndexQuantile} GetParameterLabel={GetParameterLabel} XAxis={XAxis} name={name} />
                  :
                  <div className='text-center text-gray-500 p-2 text-lg'>{getIndexQuantileError}</div>}
                   </>}

                  
                </div>
              </div>
            </div>

            </div>
          </>
          :
          <>
            {!loading ?
              <div className="text-center text-3xl text-gray-400 pt-40">We have not calculated this section!</div>
              : null}
          </>
        }
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DriverComparision);
