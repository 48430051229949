import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";
import { graphic } from 'echarts';
class QuestionScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let {  question } = this.props;
    
    ////console.log("question----->",question);

    function hexToRgbA(hex, opacity) {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
      throw hex;
    }

    
    function getScoreValueX(relation, questionX){
      let scoreMean = 0;
      if(relation !== "Self"){
        let otherSum = 0;
        let otherCount = 0;

          if(question && question.relationList && question.relationList.length>0){
            question.relationList.forEach((relation)=>{
              if(relation !== "Self"){
                otherCount+=Number(questionX[relation+"_count"])

    
                otherSum += Number(questionX[relation+"_sum"]);
              }
            })
          }
          scoreMean = Number((otherSum/otherCount).toFixed(2))
      }
      else{
        scoreMean = Number(questionX["Self_sum"] / questionX["Self_count"]);
        
      }
      return [Number((scoreMean).toFixed(2))]

    }


    let RelationList = [];


  let relationXList = [
    {
      "name":"Self score",
      "value":"Self"
    },{
      "name":"Other Respondents Average score",
      "value":"Others"
    }
  ];
  if(relationXList && relationXList.length>0){

    relationXList.forEach((item2)=>{


        if(item2 === "Self"){
          RelationList.push({
            name: item2.name,
            type: 'bar',
            barWidth:'25px',
            data: getScoreValueX(item2.value, question),
            label:{
              show:true,
              position: 'right',
              textStyle: {
                fontWeight: 'bold'
              },
            }
          })
        }else {
          RelationList.push({
            name: item2.name,
            type: 'bar',
            barWidth:'25px',
            data: getScoreValueX(item2.value, question),
            label:{
              show:true,
              position: 'right',
              textStyle: {
                fontWeight: 'bold'
              },
            }
          })
        }




      })

    }

    // let dimension = question && question.parameterDimensionName?question.parameterDimensionName: "";
    let DimensionList = [""];

    return (
      <>
        <div style={{zIndex:'10'}}>

        <ReactEcharts
              style={{height:'120px',width:'100%'}}
              option={{
                // color: ["#F6736A", "#f9d350"],
                color: [
                  new graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: hexToRgbA('#F6736A','0.5')
                    },
                    {
                      offset: 1,
                      color: '#F6736A'
                    }]),
                    new graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: hexToRgbA('#f9d350','0.5')
                      },
                      {
                        offset: 1,
                        color: '#f9d350'
                      }])
                    ],
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow'
                  }
                },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                xAxis: {
                  type: 'value',
                  boundaryGap: [0, 0.01],
                  max:6,
                  splitLine: {
                    lineStyle: {
                        color: '#fff'
                    }
                  },
                  axisLine: {
                    lineStyle: {
                      color:'#e6e6e6'
                    }
                  },
                  axisLabel:{
                      show:false
                  }

                },
                yAxis: {
                  label:{
                    show:false
                  },
                  axisLine: {
                    lineStyle: {
                      color:'#e6e6e6'
                    }
                  },
                  type: 'category',
                  data: DimensionList
                },
                series: RelationList
              }}
          />

        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
//   ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionScore);
