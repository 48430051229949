import React from "react";
import ReactEcharts from 'echarts-for-react';

function HitachiReport() {

    const MultiPieCircle = {
        series: [
            {
                type: 'pie',
                radius: ['65%', '80%'], // Outer circle
                startAngle: 180, // Start angle of the progress (0 degrees is the top)
                // endAngle: 45, // End angle to create a full circle effect
                data: [
                    { value: 60, itemStyle: { color: '#66BB6A', borderRadius: '50%', } },
                    { value: 40, itemStyle: { color: '#66BB6A29', } },

                ],
                label: {
                    show: false, // Hide the label by default 
                    position: 'center',
                    formatter: '50%',
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: '#212121', // Text color
                    fontFamily: 'Poppins, sans-serif', // Title font family
                },
                labelLine: {
                    show: false,
                },
                emphasis: {
                    label: {
                        show: true, // Show the label on hover
                    },
                },
            },
            {
                type: 'pie',
                radius: ['50%', '63%'], // Middle circle
                startAngle: 180, // Start angle of the progress (0 degrees is the top)
                data: [
                    { value: 55, itemStyle: { color: '#FFA726', borderRadius: '50%' } },
                    { value: 45, itemStyle: { color: '#FFA72629' } },
                ],
                label: {
                    show: false, // Hide the label by default
                    position: 'center',
                    formatter: '55%',
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: '#212121', // Text color
                    fontFamily: 'Poppins, sans-serif', // Title font family
                },
                labelLine: {
                    show: false,
                },
                emphasis: {
                    label: {
                        show: true, // Show the label on hover
                    },
                },
            },
            {
                type: 'pie',
                radius: ['35%', '48%'], // Inner circle
                startAngle: 180, // Start angle of the progress (0 degrees is the top)
                data: [
                    { value: 50, itemStyle: { color: '#EF5350', borderRadius: '50%', } },
                    { value: 50, itemStyle: { color: '#F4433629' } },
                ],
                label: {
                    show: false, // Hide the label by default
                    position: 'center',
                    formatter: '60%',
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: '#212121', // Text color
                    fontFamily: 'Poppins, sans-serif', // Title font family
                },
                labelLine: {
                    show: false,
                },
                emphasis: {
                    label: {
                        show: true, // Show the label on hover
                    },
                },
            },
        ],
    };
    // Function to get the chart options
    const getOption = () => {
        return {
            // title: {
            //     text: 'Stacked Horizontal Bar Chart',
            // },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow', // Shadow is for bar chart tooltip
                },
            },
            // legend: {
            //     data: ['Value 1', 'Value 2', 'Value 3'],
            // },

            xAxis: {
                type: 'value',
                axisLabel: {
                    show: false, // Hide X-axis labels (numbers)
                },
                axisLine: {
                    show: false, // Hide the X-axis line
                },
                splitLine: {
                    show: false, // Hide the grid lines on the X-axis
                },
            },
            yAxis: {
                type: 'category',
                data: ['Overall Satisfaction', 'Pride', 'Trust', 'Confidence', 'Delivery on Promise', 'Promote/Passion'],
                axisLabel: {
                    color: '#212121', // Y-axis labels text color 
                    fontWeight: '600', // Make Y-axis labels bold
                    fontSize: 12, // Set font size for Y-axis labels
                    fontFamily: 'Poppins, sans-serif', // Title font family
                },

                axisLine: {
                    show: false, // Hide the X-axis line
                },
                splitLine: {
                    show: false, // Hide the grid lines on the X-axis
                },
            },
            series: [
                {
                    name: 'Extremely Dissatisfied',
                    type: 'bar',
                    stack: 'total', // Stack bars on top of each other
                    label: {
                        show: true,
                        color: '#000', // Series labels text color
                    },
                    data: [11, 17, 22, 25, 10, 15],
                    color: '#EF5350',

                },
                {
                    name: 'Very Dissatisfied',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: true,
                        color: '#000', // Series labels text color

                    },
                    data: [17, 17, 22, 25, 10, 15],
                    color: '#EF5350BF',
                },
                {
                    name: 'Somewhat Dissatisfied',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: true,
                        color: '#000', // Series labels text color

                    },
                    data: [22, 17, 22, 25, 10, 15],

                    color: '#EF535080',
                },
                {
                    name: 'Somewhat Satisfied',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: true,
                        color: '#000', // Series labels text color

                    },
                    data: [25, 17, 22, 25, 10, 15],

                    color: '#66BB6A80',
                },
                {
                    name: 'Very Satisfied',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: true,
                        color: '#000', // Series labels text color

                    },
                    data: [10, 17, 22, 25, 10, 15],

                    color: '#66BB6ABF',
                },
                {
                    name: 'Extremely Satisfied',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: true,
                        color: '#000', // Series labels text color

                    },
                    data: [15, 17, 22, 25, 10, 15],

                    color: '#66BB6A',
                },
            ],

            grid: {
                left: '6px',
                right: '10px',
                bottom: '0px',
                top: '4px',
                containLabel: true,
            },


        };
    };
    const option = {
        series: [
            {
                type: 'pie',
                radius: ['60%', '90%'], // Adjust thickness of the circular progress bar
                startAngle: 225, // Start angle of the progress (0 degrees is the top)
                endAngle: 45, // End angle to create a full circle effect
                data: [
                    {
                        value: '75',
                        name: 'Progress',
                        itemStyle: {
                            color: '#7E57C2', // Progress color
                            borderRadius: '50%', // Simulate border-radius (but this won't directly apply)
                        },
                    },
                    {
                        value: '100',
                        name: 'Remaining',
                        itemStyle: {
                            color: '#7E57C214', // Background color
                        },
                    },
                ],
                label: {
                    show: true,
                    position: 'center',
                    formatter: `{d}%`, // Display percentage value in the center
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: '#212121', // Text color
                    fontFamily: 'Poppins, sans-serif', // Title font family
                },
                labelLine: {
                    show: false, // Hide the line connecting the label
                },
                emphasis: {
                    scale: false, // Disable scaling on hover
                },
            },
        ],
    };

    const optionCircleSmall = {
        series: [
            {
                type: 'pie',
                radius: ['60%', '80%'], // Adjust thickness of the circular progress bar
                startAngle: 225, // Start angle of the progress (0 degrees is the top)
                endAngle: 45, // End angle to create a full circle effect
                data: [
                    {
                        value: '75',
                        name: 'Progress',
                        itemStyle: {
                            color: '#7E57C2', // Progress color
                            borderRadius: '50%', // Simulate border-radius (but this won't directly apply)
                        },
                    },
                    {
                        value: '100',
                        name: 'Remaining',
                        itemStyle: {
                            color: '#7E57C214', // Background color
                        },
                    },
                ],
                label: {
                    show: true,
                    position: 'center',
                    formatter: `{d}%`, // Display percentage value in the center
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#212121', // Text color
                    fontFamily: 'Poppins, sans-serif', // Title font family
                },
                labelLine: {
                    show: false, // Hide the line connecting the label
                },
                emphasis: {
                    scale: false, // Disable scaling on hover
                },
            },
        ],
    };

    const optionPie = {
        title: {
            show: false,
        },
        tooltip: {
            trigger: 'item',
        },
        legend: {
            show: false,
            orient: 'horizontal',
            bottom: 'center',
        },
        series: [
            {
                name: 'Sales',
                type: 'pie',
                radius: '100%',
                data: [
                    {
                        value: 10,
                        name: 'self',
                        itemStyle: {
                            color: '#42A5F5', // Custom color for Product A
                        },
                        label: {
                            show: false,
                            formatter: '10', // Displays the name and value
                            itemStyle: {
                                color: '#fff', // Custom color for Product A
                            },
                        },
                    },
                    {
                        value: 6,
                        name: 'Cross-functional',
                        itemStyle: {
                            color: '#7E57C2', // Custom color for Product A
                        },
                        label: {
                            show: false,
                            formatter: '6', // Displays the name and value
                            itemStyle: {
                                color: '#fff', // Custom color for Product A
                            },
                        },
                    },
                ],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                },
            },
        ],
        grid: {
            left: '6px',
            right: '10px',
            bottom: '0px',
            top: '4px',
            containLabel: true,
        },

    };

    const elements = Array.from({ length: 12 });
    const elements10 = Array.from({ length: 5 });
    const elements2 = Array.from({ length: 12 });
    const elementsProto2 = Array.from({ length: 3 });
    const elementsPie = Array.from({ length: 3 });


    const elements4 = Array.from({ length: 4 });
    const tableSix = Array.from({ length: 6 });

    return (
        <div className="mx-auto overflow-hidden overflow-y-auto bg-white rounded-md 2xl:w-3/6 xl:w-4/6 lg:w-5/6 customscroll5">
            {/* page cover 1 */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>

                <div className="flex flex-col space-y-8 w-full ">
                    <img className="w-48 mx-auto" src="./img/hitachi-logo-1-1.svg" />
                    <h1 className="text-5xl font-semibold capitalize   text-[#E50026]  text-center">
                        Pulse
                    </h1>
                    <p className="text-center text-[#212121]/80 italic font-medium">Cash Operations  | 07 August, 2024</p>
                    <img className="w-full -mx-6" src='./img/pulse-cover-img.webp' />
                    <p className="text-center text-[#212121] font-medium">This report is confidential and should not be distributed without permission
                    </p>
                    <p className="text-sm text-[#212121]/80 text-center">Copyright © 2024 HappyPlus, Inc. All rights reserved.</p>
                </div>

            </section>
            {/* page prototype 2 */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-6 w-full ">
                    <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                        Respondent summary
                    </h1>
                    <div className="grid grid-cols-3 divide-x-2">
                        {elementsPie.map((_, index) => (
                            <div className="space-y-4 ">
                                <p class="text-xl font-semibold text-[#212121] text-center">Invited</p>
                                <div className="flex justify-center">
                                    <ReactEcharts option={optionPie} style={{ height: '200px', width: '100%' }} className="" />
                                </div>
                                <div className="flex justify-center space-x-4">
                                    <div className="flex items-center ">
                                        <div
                                            className="p-1.5 rounded-sm bg-[#7E57C2]"
                                        />
                                        <p className="ml-1.5">Self</p>
                                    </div>
                                    <div className="flex items-center ">
                                        <div
                                            className="p-1.5 rounded-sm bg-[#42A5F5]"
                                        />
                                        <p className="ml-1.5">Cross-functional</p>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>
                    <p class="text-xl font-semibold text-[#212121]">Sub-department Wise</p>
                    <table className="w-full bg-[#F1F5F9] divide-y-2 divide-white">
                        <thead className="divide-x-2 divide-white bg-[#EAEFF4] ">
                            <th className="p-3 text-base font-semibold text-[#212121] text-right w-[30%]"> </th>
                            <th colSpan={3} className="p-3 text-base font-semibold text-[#212121] w-[35%]">Self</th>
                            <th colSpan={3} className="p-3 text-base font-semibold text-[#212121] w-[35%]">Cross-functional</th>
                        </thead>
                        <tbody className="divide-y-2 divide-white">
                            <tr className="text-sm font-medium divide-x-2 divide-white bg-[#EAEFF4] ">
                                <td className="p-3 text-left">Sub-department</td>
                                <td className="p-3 text-center">Invited</td>
                                <td className="p-3 text-center">Completed</td>
                                <td className="p-3 text-center">Completion
                                    Rate %</td>
                                <td className="p-3 text-center">Invited</td>
                                <td className="p-3 text-center">Completed</td>
                                <td className="p-3 text-center">Completion
                                    Rate %</td>
                            </tr>

                            {elementsProto2.map((_, index) => (
                                <tr className="text-sm font-normal divide-x-2 divide-white ">
                                    <td className="p-3  font-normal text-left">Cash operation A</td>
                                    <td className="p-3 text-center">7</td>
                                    <td className="p-3 text-center">3</td>
                                    <td className="p-3 text-center">30%
                                    </td>
                                    <td className="p-3 text-center">4</td>
                                    <td className="p-3 text-center">1</td>
                                    <td className="p-3 text-center">25%
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>


            {/* page 4    ICE Index Summary*/}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                        ICE Index Summary
                    </h1>

                    <div className='space-x-4  flex items-start'>
                        <div className=" w-60 flex-shrink-0 space-y-6">
                            <p className='text-xl font-semibold text-[#212121] '>ICE Index Score</p>
                            <ReactEcharts option={option} style={{ height: '200px', width: '200px' }} />
                        </div>

                        <div className="w-full space-y-6 border-l pl-4">
                            <p className='text-xl font-semibold text-[#212121] '>ICE Index Themes Score</p>

                            <div className="space-y-2 ">
                                {tableSix.map((_, index) => (
                                    <div className="flex items-center space-x-2 text-sm">
                                        <span className="text-[#212121]/70 w-40 flex-shrink-0 font-medium text-right">Delivery on Promise </span>
                                        <div className="bg-[#7e58c0]/10 w-full h-8 overflow-hidden flex items-center space-x-2">
                                            <div
                                                className="bg-[#7e58c0] w-3/6 h-8"
                                            />
                                            <span className="text-xs font-medium">75%</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <table className="w-full bg-[#F1F5F9] divide-y-2 divide-white">
                        <thead className="bg-[#EAEFF4] ">
                            <th rowSpan={2} className="p-2 px-4 text-base font-semibold text-[#212121] text-left border-r-2 border-white w-36 ">Sub-department</th>
                            <th rowSpan={2} className="p-2 text-base font-semibold text-[#212121] border-r-2 border-white  w-24">ICE Index
                                Score</th>
                            <th rowSpan={1} colSpan={6} className="p-2 py-3 text-base font-semibold text-[#212121]  border-b-2 border-white">Themes Score</th>
                            <tr className="divide-x-2 divide-white ">
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20 ">Overall
                                    Satisfaction</th>
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20">Pride </th>
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20">Trust </th>
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20">Confidence </th>
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20">Delivery on Promise </th>
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20">Promote/ Passion  </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y-2 divide-white">
                            {elementsPie.map((_, index) => (
                                <tr className="text-sm font-normal divide-x-2 divide-white ">
                                    <td className="p-2 px-4  font-normal text-left">Cash operation A</td>
                                    <td className="p-2 text-center">
                                        <div className="flex justify-center">
                                            <ReactEcharts option={optionCircleSmall} style={{ height: '100px', width: '100px' }} />
                                        </div>
                                    </td>
                                    <td className="p-2 text-center">100%</td>
                                    <td className="p-2 text-center">100%</td>
                                    <td className="p-2 text-center">100%</td>
                                    <td className="p-2 text-center">100%</td>
                                    <td className="p-2 text-center">100%</td>
                                    <td className="p-2 text-center">100%</td>


                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </section>

            {/* page 5 ICE Index Themes Distribution */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                        ICE Index Themes Distribution
                    </h1>

                    <div className="space-y-4 ">

                        <div className="flex items-center  justify-center bg-[#F1F5F9] p-2 px-4 ">
                            <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]/70   ">
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350]"

                                    />
                                    <p className="ml-1.5">Extremely Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350BF]"
                                    />
                                    <p className="ml-1.5">Very Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF535080]"
                                    />
                                    <p className="ml-1.5">Somewhat Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A80]"
                                    />
                                    <p className="ml-1.5">Somewhat Satisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6ABF]"
                                    />
                                    <p className="ml-1.5">Very Satisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A]"
                                    />
                                    <p className="ml-1.5">Extremely Satisfied</p>
                                </div>
                            </div>

                        </div>

                        {elements4.map((_, index) => (
                            <div className="space-y-4 ">
                                <p className="text-lg font-semibold text-[#212121] bg-[#F1F5F9] p-2 px-4"> Cash Operation A</p>
                                <div className="grid grid-cols-1 gap-1">
                                    {tableSix.map((_, index) => (
                                        <div className="flex items-center space-x-4">
                                            <span className="text-sm w-40 flex-shrink-0 text-right">Overall Satisfaction</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[10%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#EF5350BF] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[30%] bg-[#EF535080] h-6 flex items-center justify-center font-medium">30%</div>
                                                <div className="w-[10%] bg-[#66BB6A80] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[10%] bg-[#66BB6ABF] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">20%</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}


                    </div>
                </div>

            </section>


            {/* page 7 Top 3 Themes */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                        Top 3 Themes
                    </h1>
                    <div className="space-y-4">
                        <div className="flex items-center  justify-center bg-[#F1F5F9] p-2 px-4 ">
                            <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]/70   ">
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350]"

                                    />
                                    <p className="ml-1.5">Extremely Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350BF]"
                                    />
                                    <p className="ml-1.5">Very Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF535080]"
                                    />
                                    <p className="ml-1.5">Somewhat Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A80]"
                                    />
                                    <p className="ml-1.5">Somewhat Satisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6ABF]"
                                    />
                                    <p className="ml-1.5">Very Satisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A]"
                                    />
                                    <p className="ml-1.5">Extremely Satisfied</p>
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]/70 border-l-2 pl-4  ">
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#42A5F5]"
                                    />
                                    <p className="ml-1.5">Less than 6 months</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#9575CD]"
                                    />
                                    <p className="ml-1.5">6 months to 1 year</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#FFA726]"
                                    />
                                    <p className="ml-1.5">1 to 3 years</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A]"
                                    />
                                    <p className="ml-1.5">3 to 5 years</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350]"
                                    />
                                    <p className="ml-1.5">6 to 10 years</p>
                                </div>
                            </div>
                        </div>
                        {elementsPie.map((_, index) => (
                            <div className="space-y-4 ">
                                <p className="text-lg font-semibold text-[#212121] bg-[#F1F5F9] p-2 px-4">{index + 1}.  Department Satisfaction</p>
                                <div className="grid grid-cols-2 gap-6">
                                    <div className="space-y-2 ">
                                        <p className="font-semibold text-base">Department</p>
                                        <div>
                                            <span className="text-sm">Self</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[10%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#EF5350BF] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[30%] bg-[#EF535080] h-6 flex items-center justify-center font-medium">30%</div>
                                                <div className="w-[10%] bg-[#66BB6A80] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[10%] bg-[#66BB6ABF] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">20%</div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="text-sm">Cross-functional</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[10%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#EF5350BF] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[30%] bg-[#EF535080] h-6 flex items-center justify-center font-medium">30%</div>
                                                <div className="w-[10%] bg-[#66BB6A80] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[10%] bg-[#66BB6ABF] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">20%</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="space-y-2 ">
                                        <p className="font-semibold text-base">Tenure</p>
                                        <div>
                                            <span className="text-sm">Self</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[25%] bg-[#42A5F5] h-6 flex items-center justify-center font-medium">25%</div>
                                                <div className="w-[20%] bg-[#9575CD] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[15%] bg-[#FFA726] h-6 flex items-center justify-center font-medium">15%</div>
                                                <div className="w-[10%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[30%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">30%</div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="text-sm">Cross-functional</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[25%] bg-[#42A5F5] h-6 flex items-center justify-center font-medium">25%</div>
                                                <div className="w-[20%] bg-[#9575CD] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[15%] bg-[#FFA726] h-6 flex items-center justify-center font-medium">15%</div>
                                                <div className="w-[10%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[30%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">30%</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>
                </div>
            </section>

            {/* page 8 Bottom 3 Themes */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                        Bottom 3 Themes
                    </h1>
                    <div className="space-y-4">
                        <div className="flex items-center  justify-center bg-[#F1F5F9] p-2 px-4 ">
                            <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]/70   ">
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350]"

                                    />
                                    <p className="ml-1.5">Extremely Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350BF]"
                                    />
                                    <p className="ml-1.5">Very Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF535080]"
                                    />
                                    <p className="ml-1.5">Somewhat Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A80]"
                                    />
                                    <p className="ml-1.5">Somewhat Satisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6ABF]"
                                    />
                                    <p className="ml-1.5">Very Satisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A]"
                                    />
                                    <p className="ml-1.5">Extremely Satisfied</p>
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]/70 border-l-2 pl-4  ">
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#42A5F5]"
                                    />
                                    <p className="ml-1.5">Less than 6 months</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#9575CD]"
                                    />
                                    <p className="ml-1.5">6 months to 1 year</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#FFA726]"
                                    />
                                    <p className="ml-1.5">1 to 3 years</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A]"
                                    />
                                    <p className="ml-1.5">3 to 5 years</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350]"
                                    />
                                    <p className="ml-1.5">6 to 10 years</p>
                                </div>
                            </div>
                        </div>
                        {elementsPie.map((_, index) => (
                            <div className="space-y-4 ">
                                <p className="text-lg font-semibold text-[#212121] bg-[#F1F5F9] p-2 px-4">{index + 1}.  Department Satisfaction</p>
                                <div className="grid grid-cols-2 gap-6">
                                    <div className="space-y-2 ">
                                        <p className="font-semibold text-base">Department</p>
                                        <div>
                                            <span className="text-sm">Self</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[10%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#EF5350BF] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[30%] bg-[#EF535080] h-6 flex items-center justify-center font-medium">30%</div>
                                                <div className="w-[10%] bg-[#66BB6A80] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[10%] bg-[#66BB6ABF] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">20%</div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="text-sm">Cross-functional</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[10%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#EF5350BF] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[30%] bg-[#EF535080] h-6 flex items-center justify-center font-medium">30%</div>
                                                <div className="w-[10%] bg-[#66BB6A80] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[10%] bg-[#66BB6ABF] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">20%</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="space-y-2 ">
                                        <p className="font-semibold text-base">Tenure</p>
                                        <div>
                                            <span className="text-sm">Self</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[25%] bg-[#42A5F5] h-6 flex items-center justify-center font-medium">25%</div>
                                                <div className="w-[20%] bg-[#9575CD] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[15%] bg-[#FFA726] h-6 flex items-center justify-center font-medium">15%</div>
                                                <div className="w-[10%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[30%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">30%</div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="text-sm">Cross-functional</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[25%] bg-[#42A5F5] h-6 flex items-center justify-center font-medium">25%</div>
                                                <div className="w-[20%] bg-[#9575CD] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[15%] bg-[#FFA726] h-6 flex items-center justify-center font-medium">15%</div>
                                                <div className="w-[10%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[30%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">30%</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>
                </div>
            </section>

            {/* page 9 Bottom 3 Themes */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                        Top 3 Themes <span className="text-lg">(Cash Operation A)</span>
                    </h1>
                    <div className="space-y-4">
                        <div className="flex items-center  justify-center bg-[#F1F5F9] p-2 px-4 ">
                            <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]/70   ">
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350]"

                                    />
                                    <p className="ml-1.5">Extremely Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350BF]"
                                    />
                                    <p className="ml-1.5">Very Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF535080]"
                                    />
                                    <p className="ml-1.5">Somewhat Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A80]"
                                    />
                                    <p className="ml-1.5">Somewhat Satisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6ABF]"
                                    />
                                    <p className="ml-1.5">Very Satisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A]"
                                    />
                                    <p className="ml-1.5">Extremely Satisfied</p>
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]/70 border-l-2 pl-4  ">
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#42A5F5]"
                                    />
                                    <p className="ml-1.5">Less than 6 months</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#9575CD]"
                                    />
                                    <p className="ml-1.5">6 months to 1 year</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#FFA726]"
                                    />
                                    <p className="ml-1.5">1 to 3 years</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A]"
                                    />
                                    <p className="ml-1.5">3 to 5 years</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350]"
                                    />
                                    <p className="ml-1.5">6 to 10 years</p>
                                </div>
                            </div>
                        </div>
                        {elementsPie.map((_, index) => (
                            <div className="space-y-4 ">
                                <p className="text-lg font-semibold text-[#212121] bg-[#F1F5F9] p-2 px-4">{index + 1}.  Department Satisfaction</p>
                                <div className="grid grid-cols-2 gap-6">
                                    <div className="space-y-2 ">
                                        <p className="font-semibold text-base">Department</p>
                                        <div>
                                            <span className="text-sm">Self</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[10%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#EF5350BF] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[30%] bg-[#EF535080] h-6 flex items-center justify-center font-medium">30%</div>
                                                <div className="w-[10%] bg-[#66BB6A80] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[10%] bg-[#66BB6ABF] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">20%</div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="text-sm">Cross-functional</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[10%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#EF5350BF] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[30%] bg-[#EF535080] h-6 flex items-center justify-center font-medium">30%</div>
                                                <div className="w-[10%] bg-[#66BB6A80] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[10%] bg-[#66BB6ABF] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">20%</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="space-y-2 ">
                                        <p className="font-semibold text-base">Tenure</p>
                                        <div>
                                            <span className="text-sm">Self</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[25%] bg-[#42A5F5] h-6 flex items-center justify-center font-medium">25%</div>
                                                <div className="w-[20%] bg-[#9575CD] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[15%] bg-[#FFA726] h-6 flex items-center justify-center font-medium">15%</div>
                                                <div className="w-[10%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[30%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">30%</div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="text-sm">Cross-functional</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[25%] bg-[#42A5F5] h-6 flex items-center justify-center font-medium">25%</div>
                                                <div className="w-[20%] bg-[#9575CD] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[15%] bg-[#FFA726] h-6 flex items-center justify-center font-medium">15%</div>
                                                <div className="w-[10%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[30%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">30%</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>
                </div>
            </section>
            {/* page 10 Bottom 3 Themes */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                        Bottom 3 Themes <span className="text-lg">(Cash Operation A)</span>
                    </h1>
                    <div className="space-y-4">
                        <div className="flex items-center  justify-center bg-[#F1F5F9] p-2 px-4 ">
                            <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]/70   ">
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350]"

                                    />
                                    <p className="ml-1.5">Extremely Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350BF]"
                                    />
                                    <p className="ml-1.5">Very Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF535080]"
                                    />
                                    <p className="ml-1.5">Somewhat Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A80]"
                                    />
                                    <p className="ml-1.5">Somewhat Satisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6ABF]"
                                    />
                                    <p className="ml-1.5">Very Satisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A]"
                                    />
                                    <p className="ml-1.5">Extremely Satisfied</p>
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]/70 border-l-2 pl-4  ">
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#42A5F5]"
                                    />
                                    <p className="ml-1.5">Less than 6 months</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#9575CD]"
                                    />
                                    <p className="ml-1.5">6 months to 1 year</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#FFA726]"
                                    />
                                    <p className="ml-1.5">1 to 3 years</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A]"
                                    />
                                    <p className="ml-1.5">3 to 5 years</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350]"
                                    />
                                    <p className="ml-1.5">6 to 10 years</p>
                                </div>
                            </div>
                        </div>
                        {elementsPie.map((_, index) => (
                            <div className="space-y-4 ">
                                <p className="text-lg font-semibold text-[#212121] bg-[#F1F5F9] p-2 px-4">{index + 1}.  Department Satisfaction</p>
                                <div className="grid grid-cols-2 gap-6">
                                    <div className="space-y-2 ">
                                        <p className="font-semibold text-base">Department</p>
                                        <div>
                                            <span className="text-sm">Self</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[10%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#EF5350BF] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[30%] bg-[#EF535080] h-6 flex items-center justify-center font-medium">30%</div>
                                                <div className="w-[10%] bg-[#66BB6A80] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[10%] bg-[#66BB6ABF] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">20%</div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="text-sm">Cross-functional</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[10%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#EF5350BF] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[30%] bg-[#EF535080] h-6 flex items-center justify-center font-medium">30%</div>
                                                <div className="w-[10%] bg-[#66BB6A80] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[10%] bg-[#66BB6ABF] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">20%</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="space-y-2 ">
                                        <p className="font-semibold text-base">Tenure</p>
                                        <div>
                                            <span className="text-sm">Self</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[25%] bg-[#42A5F5] h-6 flex items-center justify-center font-medium">25%</div>
                                                <div className="w-[20%] bg-[#9575CD] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[15%] bg-[#FFA726] h-6 flex items-center justify-center font-medium">15%</div>
                                                <div className="w-[10%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[30%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">30%</div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="text-sm">Cross-functional</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[25%] bg-[#42A5F5] h-6 flex items-center justify-center font-medium">25%</div>
                                                <div className="w-[20%] bg-[#9575CD] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[15%] bg-[#FFA726] h-6 flex items-center justify-center font-medium">15%</div>
                                                <div className="w-[10%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[30%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">30%</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>
                </div>
            </section>

            {/* page 11 eNPS */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium    bg-[#212121]/90 text-white p-4">
                        eNPS
                    </h1>

                    <div className='space-x-10  flex items-center'>
                        <div className=" w-60 flex-shrink-0 space-y-6">
                            {/* <p className='text-xl font-semibold text-[#212121] '>ICE Index Score</p> */}
                            <ReactEcharts option={MultiPieCircle} style={{ height: '270px', width: '270px' }} />
                        </div>

                        <div className="w-full space-y-6">
                            <p className='text-xl font-bold text-[#212121] '>eNPS Score: 10%</p>
                            <p className='text-xl font-semibold text-[#212121] '>eNPS = % of Promoters - % of Detractors</p>
                            <div className="flex justify-start space-x-6 ">
                                <div className="flex flex-col items-center gap-2">
                                    <p className="font-medium">Detractors</p>
                                    <div className="text-white flex items-center divide-x divide-white ">
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">1</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">2</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">3</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">4</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">5</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">6</span>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center gap-2">
                                    <p className="font-medium">Passive</p>
                                    <div className="text-white flex items-center divide-x divide-white ">
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#FFA726]">7</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#FFA726]">8</span>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center gap-2">
                                    <p className="font-medium">Promoters</p>
                                    <div className="text-white flex items-center divide-x divide-white ">
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#66BB6A]">9</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#66BB6A]">10</span>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="">
                        <table className="w-full">
                            <thead className="border-b-[8px] border-white">
                                <th className="w-[30%] px-2 my-1"></th>
                                <th className="bg-[#F1F5F9] w-[70%] h-8 px-2  " colSpan={2}>
                                    <div className="text-sm font-medium flex justify-between">
                                        <span>-100</span>
                                        <span>0</span>
                                        <span>100</span>
                                    </div>
                                </th>
                            </thead>
                            <tbody>
                                {tableSix.map((_, index) => (
                                    <tr>
                                        <td className="text-sm text-right p-2 py-1">
                                            Business Finance, Finance & Accounts and Legal

                                        </td>
                                        <td className="py-1">
                                            <div className="bg-[#EF5350]/10 w-full h-8 overflow-hidden flex items-center space-x-2 justify-end">
                                                <span className="text-xs font-medium">75%</span>
                                                <div className="bg-[#EF5350] w-3/6 h-8"></div>

                                            </div>

                                        </td>
                                        <td className="py-1">
                                            <div className="bg-[#66BB6A]/10 w-full h-8 overflow-hidden flex items-center space-x-2">
                                                <div className="bg-[#66BB6A] w-3/6 h-8"></div>
                                                <span className="text-xs font-medium">75%</span>
                                            </div>

                                        </td>

                                    </tr>
                                ))}
                            </tbody>

                        </table>

                    </div>
                </div>

            </section>

            {/* page 12 eNPS Cash Operation A */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium    bg-[#212121]/90 text-white p-4">
                        eNPS <span className="text-lg">(Cash Operation A)</span>
                    </h1>

                    <div className='space-x-10  flex items-center'>
                        <div className=" w-60 flex-shrink-0 space-y-6">
                            <ReactEcharts option={MultiPieCircle} style={{ height: '270px', width: '270px' }} />
                        </div>

                        <div className="w-full space-y-6  ">
                            <p className='text-xl font-bold text-[#212121] '>eNPS Score: 10%</p>
                            <p className='text-xl font-semibold text-[#212121] '>eNPS = % of Promoters - % of Detractors</p>
                            <div className="flex justify-start space-x-6 ">
                                <div className="flex flex-col items-center gap-2">
                                    <p className="font-medium">Detractors</p>
                                    <div className="text-white flex items-center divide-x divide-white ">
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">1</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">2</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">3</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">4</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">5</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">6</span>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center gap-2">
                                    <p className="font-medium">Passive</p>
                                    <div className="text-white flex items-center divide-x divide-white ">
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#FFA726]">7</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#FFA726]">8</span>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center gap-2">
                                    <p className="font-medium">Promoters</p>
                                    <div className="text-white flex items-center divide-x divide-white ">
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#66BB6A]">9</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#66BB6A]">10</span>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="">
                        <table className="w-full">
                            <thead className="border-b-[8px] border-white">
                                <th className="w-[30%] px-2 my-1"></th>
                                <th className="bg-[#F1F5F9] w-[70%] h-8 px-2  " colSpan={2}>
                                    <div className="text-sm font-medium flex justify-between">
                                        <span>-100</span>
                                        <span>0</span>
                                        <span>100</span>
                                    </div>
                                </th>
                            </thead>
                            <tbody>
                                {tableSix.map((_, index) => (
                                    <tr>
                                        <td className="text-sm text-right p-2 py-1">
                                            Business Finance, Finance & Accounts and Legal

                                        </td>
                                        <td className="py-1">
                                            <div className="bg-[#EF5350]/10 w-full h-8 overflow-hidden flex items-center space-x-2 justify-end">
                                                <span className="text-xs font-medium">75%</span>
                                                <div className="bg-[#EF5350] w-3/6 h-8"></div>

                                            </div>

                                        </td>
                                        <td className="py-1">
                                            <div className="bg-[#66BB6A]/10 w-full h-8 overflow-hidden flex items-center space-x-2">
                                                <div className="bg-[#66BB6A] w-3/6 h-8"></div>
                                                <span className="text-xs font-medium">75%</span>
                                            </div>

                                        </td>

                                    </tr>
                                ))}
                            </tbody>

                        </table>

                    </div>
                </div>

            </section>

            {/* page 13  Themes Summary */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium    bg-[#212121]/90 text-white p-4">
                        Themes Summary
                    </h1>

                    <div className="pt-8">
                        <table className="w-full">
                            <thead className="text-sm">
                                <th className="w-[25%]"></th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14 "> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Team Ownership/ Commitment </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Team Efficiency </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Team Proactiveness </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Team Collaboration </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                            </thead>
                            <tbody className="divide-y divide-white ">
                                {elements.map((_, index) => (
                                    <tr key={index} className="text-center text-sm font-medium text-[#212121] divide-x divide-white">
                                        <td className="text-sm text-right p-2 font-normal">
                                            Business Finance, Finance & Accounts & Legal
                                        </td>
                                        <td className="bg-[#EF5350] py-2 h-14">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>

                                    </tr>
                                ))}



                            </tbody>

                        </table>

                    </div>
                </div>

            </section>
            {/* page 14  Themes Summary Cash Operation A */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium    bg-[#212121]/90 text-white p-4">
                        Themes Summary <span className="text-lg">(Cash Operation A)</span>
                    </h1>

                    <div className="pt-8">
                        <table className="w-full">
                            <thead className="text-sm">
                                <th className="w-[25%]"></th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14 "> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Team Ownership/ Commitment </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Team Efficiency </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Team Proactiveness </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Team Collaboration </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                            </thead>
                            <tbody className="divide-y divide-white">
                                {elements.map((_, index) => (
                                    <tr key={index} className="text-center text-sm font-medium text-[#212121] divide-x divide-white">
                                        <td className="text-sm text-right p-2 font-normal">
                                            Business Finance, Finance & Accounts & Legal
                                        </td>
                                        <td className="bg-[#EF5350] py-2 h-14">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>

                                    </tr>
                                ))}



                            </tbody>

                        </table>

                    </div>
                </div>

            </section>

            {/* page 15 Manager score */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                        Manager  Score
                    </h1>

                    <div className='space-x-4  flex items-start'>
                        <div className=" w-60 flex-shrink-0 space-y-6">
                            <p className='text-xl font-semibold text-[#212121] '>Manager  Score</p>
                            <ReactEcharts option={option} style={{ height: '200px', width: '200px' }} />
                        </div>

                        <div className="w-full space-y-6 border-l pl-4">
                            <p className='text-xl font-semibold text-[#212121] '>Manager  Themes Score</p>

                            <div className="space-y-2 ">
                                {elements10.map((_, index) => (
                                    <div className="flex items-center space-x-2 text-sm">
                                        <span className="text-[#212121]/70 w-40 flex-shrink-0 font-medium text-right">Delivery on Promise </span>
                                        <div className="bg-[#7e58c0]/10 w-full h-8 overflow-hidden flex items-center space-x-2">
                                            <div
                                                className="bg-[#7e58c0] w-3/6 h-8"
                                            />
                                            <span className="text-xs font-medium">75%</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <p className="text-xl font-semibold text-[#212121] pt-4">Manager Themes Score Sub-department wise</p>

                    <table className="w-full bg-[#F1F5F9] divide-y-2 divide-white">
                        <thead className="bg-[#EAEFF4] ">
                            <th rowSpan={2} className="p-2 px-4 text-base font-semibold text-[#212121] text-left border-r-2 border-white w-36 ">Sub-department</th>
                            <th rowSpan={2} className="p-2 text-base font-semibold text-[#212121] border-r-2 border-white  w-24">Manager
                                Score</th>
                            <th rowSpan={1} colSpan={5} className="p-2 py-3 text-base font-semibold text-[#212121]  border-b-2 border-white">Themes Score</th>
                            <tr className="divide-x-2 divide-white ">
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20 ">Role Model
                                </th>
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20">Empower </th>
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20">Feedback & Development
                                </th>
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20">Information Communication
                                </th>
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20">Listen & Nurtures </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y-2 divide-white">
                            {elementsPie.map((_, index) => (
                                <tr className="text-sm font-normal divide-x-2 divide-white ">
                                    <td className="p-2 px-4  font-normal text-left">Cash operation A</td>
                                    <td className="p-2 text-center">
                                        <div className="flex justify-center">
                                            <ReactEcharts option={optionCircleSmall} style={{ height: '100px', width: '100px' }} />
                                        </div>
                                    </td>
                                    <td className="p-2 text-center">100%</td>
                                    <td className="p-2 text-center">100%</td>
                                    <td className="p-2 text-center">100%</td>
                                    <td className="p-2 text-center">100%</td>
                                    <td className="p-2 text-center">100%</td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </section>

            {/* page 16    Open Ended Feedback */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash operation | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium    bg-[#212121]/90 text-white p-4">
                        Open Ended Feedback
                    </h1>
                    <p className="text-sm text-[#212121] italic">This section provides verbatim comments from all respondents. You will gain the most value if you pay attention to the frequently occurring topics and suggestions. Try to view the information objectively and reconcile it with the information in the previous rating sections.</p>
                    <div className="space-y-8 ">

                        <div className="space-y-4  ">
                            <p className="text-lg font-semibold text-[#212121]">1. Do you have any additional comments or suggestions for improving the services provided by the department?</p>

                            <div className="space-y-2 ">
                                {elements10.map((_, index) => (
                                    <div className="w-full flex  space-x-4 items-end">
                                        <div className="text-[#3F51B5]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24" fill="none">
                                                <path d="M20.4842 3.51579C18.3132 1.34211 15.3132 0 12 0C5.37368 0 0 5.37368 0 12C0 15.3132 1.34211 18.3132 3.51579 20.4842C5.68947 22.6553 8.68684 24 12 24C15.3132 24 18.3132 22.6579 20.4842 20.4842C22.6553 18.3105 24 15.3132 24 12C24 8.68684 22.6579 5.68684 20.4842 3.51579ZM11.9974 3.69474C14.2921 3.69211 16.15 5.55263 16.15 7.84737C16.1474 10.1026 14.2605 11.9921 12.0053 11.9921C9.71053 11.9947 7.85263 10.1342 7.85263 7.83947C7.85263 5.58684 9.74211 3.69737 11.9974 3.69474ZM18.8947 18.8947C18.0632 19.7263 17.0842 20.4079 16 20.8947C14.7789 21.4447 13.4263 21.75 12 21.75C10.5737 21.75 9.22105 21.4447 8 20.8947C6.91579 20.4053 5.93421 19.7237 5.10526 18.8947C4.68684 18.4763 4.30526 18.0184 3.96579 17.5289C4.57105 15.5105 6.45 14.0289 8.66053 14.0289H15.3395C17.55 14.0289 19.4289 15.5105 20.0342 17.5289C19.6947 18.0184 19.3132 18.4737 18.8947 18.8947Z" fill="currentColor" />
                                            </svg>
                                        </div>
                                        <div className="bg-[#3F51B51A] w-full rounded-t-xl rounded-r-xl px-4 py-3 text-sm ">
                                            <p>Standardization of finishing materials & design of projects.</p>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                        <div className="space-y-4  ">
                            <p className="text-lg font-semibold text-[#212121]">2. What is something this department can improve upon?</p>

                            <div className="space-y-2 ">
                                {elements10.map((_, index) => (
                                    <div className="w-full flex  space-x-4 items-end">
                                        <div className="text-[#3F51B5]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24" fill="none">
                                                <path d="M20.4842 3.51579C18.3132 1.34211 15.3132 0 12 0C5.37368 0 0 5.37368 0 12C0 15.3132 1.34211 18.3132 3.51579 20.4842C5.68947 22.6553 8.68684 24 12 24C15.3132 24 18.3132 22.6579 20.4842 20.4842C22.6553 18.3105 24 15.3132 24 12C24 8.68684 22.6579 5.68684 20.4842 3.51579ZM11.9974 3.69474C14.2921 3.69211 16.15 5.55263 16.15 7.84737C16.1474 10.1026 14.2605 11.9921 12.0053 11.9921C9.71053 11.9947 7.85263 10.1342 7.85263 7.83947C7.85263 5.58684 9.74211 3.69737 11.9974 3.69474ZM18.8947 18.8947C18.0632 19.7263 17.0842 20.4079 16 20.8947C14.7789 21.4447 13.4263 21.75 12 21.75C10.5737 21.75 9.22105 21.4447 8 20.8947C6.91579 20.4053 5.93421 19.7237 5.10526 18.8947C4.68684 18.4763 4.30526 18.0184 3.96579 17.5289C4.57105 15.5105 6.45 14.0289 8.66053 14.0289H15.3395C17.55 14.0289 19.4289 15.5105 20.0342 17.5289C19.6947 18.0184 19.3132 18.4737 18.8947 18.8947Z" fill="currentColor" />
                                            </svg>
                                        </div>
                                        <div className="bg-[#3F51B51A] w-full rounded-t-xl rounded-r-xl px-4 py-3 text-sm ">
                                            <p>Standardization of finishing materials & design of projects.</p>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>

            </section>

            {/* page 17    Open Ended Feedback */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash operation | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium    bg-[#212121]/90 text-white p-4">
                        Open Ended Feedback <span className="text-lg">(Cash Operation A)</span>
                    </h1>
                    <p className="text-sm text-[#212121] italic">This section provides verbatim comments from all respondents. You will gain the most value if you pay attention to the frequently occurring topics and suggestions. Try to view the information objectively and reconcile it with the information in the previous rating sections.</p>
                    <div className="space-y-8 ">

                        <div className="space-y-4  ">
                            <p className="text-lg font-semibold text-[#212121]">1. Do you have any additional comments or suggestions for improving the services provided by the department?</p>

                            <div className="space-y-2 ">
                                {elements10.map((_, index) => (
                                    <div className="w-full flex  space-x-4 items-end">
                                        <div className="text-[#3F51B5]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24" fill="none">
                                                <path d="M20.4842 3.51579C18.3132 1.34211 15.3132 0 12 0C5.37368 0 0 5.37368 0 12C0 15.3132 1.34211 18.3132 3.51579 20.4842C5.68947 22.6553 8.68684 24 12 24C15.3132 24 18.3132 22.6579 20.4842 20.4842C22.6553 18.3105 24 15.3132 24 12C24 8.68684 22.6579 5.68684 20.4842 3.51579ZM11.9974 3.69474C14.2921 3.69211 16.15 5.55263 16.15 7.84737C16.1474 10.1026 14.2605 11.9921 12.0053 11.9921C9.71053 11.9947 7.85263 10.1342 7.85263 7.83947C7.85263 5.58684 9.74211 3.69737 11.9974 3.69474ZM18.8947 18.8947C18.0632 19.7263 17.0842 20.4079 16 20.8947C14.7789 21.4447 13.4263 21.75 12 21.75C10.5737 21.75 9.22105 21.4447 8 20.8947C6.91579 20.4053 5.93421 19.7237 5.10526 18.8947C4.68684 18.4763 4.30526 18.0184 3.96579 17.5289C4.57105 15.5105 6.45 14.0289 8.66053 14.0289H15.3395C17.55 14.0289 19.4289 15.5105 20.0342 17.5289C19.6947 18.0184 19.3132 18.4737 18.8947 18.8947Z" fill="currentColor" />
                                            </svg>
                                        </div>
                                        <div className="bg-[#3F51B51A] w-full rounded-t-xl rounded-r-xl px-4 py-3 text-sm ">
                                            <p>Standardization of finishing materials & design of projects.</p>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                        <div className="space-y-4  ">
                            <p className="text-lg font-semibold text-[#212121]">2. What is something this department can improve upon?</p>

                            <div className="space-y-2 ">
                                {elements10.map((_, index) => (
                                    <div className="w-full flex  space-x-4 items-end">
                                        <div className="text-[#3F51B5]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24" fill="none">
                                                <path d="M20.4842 3.51579C18.3132 1.34211 15.3132 0 12 0C5.37368 0 0 5.37368 0 12C0 15.3132 1.34211 18.3132 3.51579 20.4842C5.68947 22.6553 8.68684 24 12 24C15.3132 24 18.3132 22.6579 20.4842 20.4842C22.6553 18.3105 24 15.3132 24 12C24 8.68684 22.6579 5.68684 20.4842 3.51579ZM11.9974 3.69474C14.2921 3.69211 16.15 5.55263 16.15 7.84737C16.1474 10.1026 14.2605 11.9921 12.0053 11.9921C9.71053 11.9947 7.85263 10.1342 7.85263 7.83947C7.85263 5.58684 9.74211 3.69737 11.9974 3.69474ZM18.8947 18.8947C18.0632 19.7263 17.0842 20.4079 16 20.8947C14.7789 21.4447 13.4263 21.75 12 21.75C10.5737 21.75 9.22105 21.4447 8 20.8947C6.91579 20.4053 5.93421 19.7237 5.10526 18.8947C4.68684 18.4763 4.30526 18.0184 3.96579 17.5289C4.57105 15.5105 6.45 14.0289 8.66053 14.0289H15.3395C17.55 14.0289 19.4289 15.5105 20.0342 17.5289C19.6947 18.0184 19.3132 18.4737 18.8947 18.8947Z" fill="currentColor" />
                                            </svg>
                                        </div>
                                        <div className="bg-[#3F51B51A] w-full rounded-t-xl rounded-r-xl px-4 py-3 text-sm ">
                                            <p>Standardization of finishing materials & design of projects.</p>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>

            </section>




            {/*htiachi  Overall */}
            {/* page 3 */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Overall | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-6 w-full ">
                    <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                        Respondent summary
                    </h1>
                    <div className="grid grid-cols-3 gap-4">
                        <div className="flex items-center p-4  cursor-pointer bg-[#7E57C2]/10 space-x-4">
                            <div className="p-2 bg-[#673AB7] text-[#fff] ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-mail-check"
                                    width={50}
                                    height={50}
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M11 19h-6a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v6" />
                                    <path d="M3 7l9 6l9 -6" />
                                    <path d="M15 19l2 2l4 -4" />
                                </svg>
                            </div>
                            <div className="flex items-center w-full ">
                                <div className="space-y-1">
                                    <p className="block text-base text-[#212112] w-full capitalize font-medium ">
                                        Invited
                                    </p>
                                    <h1 className="text-2xl font-medium w-full text-[#212121]">100</h1>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center p-4  cursor-pointer bg-[#4CAF50]/10 space-x-4">
                            <div className="p-2 bg-[#4CAF50] text-[#fff] ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-rosette-discount-check"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1" /><path d="M9 12l2 2l4 -4" /></svg>
                            </div>
                            <div className="flex items-center w-full ">
                                <div className="space-y-1">
                                    <p className="block text-base text-[#212112] w-full capitalize font-medium ">
                                        Completed
                                    </p>
                                    <h1 className="text-2xl font-medium w-full text-[#212121]">55</h1>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center p-4  cursor-pointer bg-[#4CAF50]/10 space-x-4">
                            <div className="p-2 bg-[#7CB342] text-[#fff] ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-circle-percentage"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M9 15.075l6 -6" /><path d="M9 9.105v.015" /><path d="M15 15.12v.015" /></svg>
                            </div>
                            <div className="flex items-center w-full ">
                                <div className="space-y-1">
                                    <p className="block text-base text-[#212112] w-full capitalize font-medium ">
                                        Completion Rate %
                                    </p>
                                    <h1 className="text-2xl font-medium w-full text-[#212121]">55%</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className="w-full bg-[#F1F5F9] divide-y-2 divide-white">
                        <thead className="divide-x-2 divide-white bg-[#EAEFF4]">
                            <th className="p-2  px-4 text-sm font-semibold text-[#212121] text-left w-[30%]">Department</th>
                            <th className="p-2 text-sm font-semibold text-[#212121] w-[15%]">Invited</th>
                            <th className="p-2 text-sm font-semibold text-[#212121] w-[15%]">Completed</th>
                            <th className="p-2 text-sm font-semibold text-[#212121] w-[30%]">Completion Rate %</th>
                        </thead>
                        <tbody className="divide-y-2 divide-white">
                            {elements2.map((_, index) => (
                                <tr className="text-sm font-normal divide-x-2 divide-white ">
                                    <td className="p-2 px-4  font-normal text-left">Product & Digital Transformation</td>
                                    <td className="p-2 text-center">10</td>
                                    <td className="p-2 text-center">5</td>
                                    <td className="p-2 w-fit">
                                        <div
                                            className="flex items-center justify-center space-x-2  "
                                        >
                                            <div className="bg-[#E5E7EB] w-48 h-5 overflow-hidden">
                                                <div
                                                    className="bg-[#7CB342] w-[50%] h-5"
                                                />
                                            </div>
                                            <span>50%</span>
                                        </div>



                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>

            </section>

            {/* page 6 */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Overall | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                        ICE Index Summary
                    </h1>

                    <div className='space-x-4  flex items-start'>
                        <div className=" w-60 flex-shrink-0 space-y-6">
                            <p className='text-xl font-semibold text-[#212121] '>ICE Index Score</p>
                            <ReactEcharts option={option} style={{ height: '200px', width: '200px' }} />
                        </div>

                        <div className="w-full space-y-6 border-l pl-4">
                            <p className='text-xl font-semibold text-[#212121] '>ICE Index Themes Score</p>

                            <div className="space-y-2 ">
                                {tableSix.map((_, index) => (
                                    <div className="flex items-center space-x-2 text-sm">
                                        <span className="text-[#212121]/70 w-40 flex-shrink-0 font-medium text-right">Delivery on Promise </span>
                                        <div className="bg-[#7e58c0]/10 w-full h-8 overflow-hidden flex items-center space-x-2">
                                            <div
                                                className="bg-[#7e58c0] w-3/6 h-8"
                                            />
                                            <span className="text-xs font-medium">75%</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="space-y-4 ">
                        <div className="space-y-6 ">
                            <p className='text-xl font-semibold text-[#212121]'>ICE Index Themes Distribution</p>

                            <div className="flex items-center justify-center  ">
                                <div className="flex flex-wrap justify-start gap-4 text-xs text-[#212121]/70   ">
                                    <div className="flex items-center ">
                                        <div
                                            className="p-1.5 rounded-sm bg-[#EF5350]"

                                        />
                                        <p className="ml-1.5">Extremely Dissatisfied</p>
                                    </div>
                                    <div className="flex items-center ">
                                        <div
                                            className="p-1.5 rounded-sm bg-[#EF5350BF]"
                                        />
                                        <p className="ml-1.5">Very Dissatisfied</p>
                                    </div>
                                    <div className="flex items-center ">
                                        <div
                                            className="p-1.5 rounded-sm bg-[#EF535080]"
                                        />
                                        <p className="ml-1.5">Somewhat Dissatisfied</p>
                                    </div>
                                    <div className="flex items-center ">
                                        <div
                                            className="p-1.5 rounded-sm bg-[#66BB6A80]"
                                        />
                                        <p className="ml-1.5">Somewhat Satisfied</p>
                                    </div>
                                    <div className="flex items-center ">
                                        <div
                                            className="p-1.5 rounded-sm bg-[#66BB6ABF]"
                                        />
                                        <p className="ml-1.5">Very Satisfied</p>
                                    </div>
                                    <div className="flex items-center ">
                                        <div
                                            className="p-1.5 rounded-sm bg-[#66BB6A]"
                                        />
                                        <p className="ml-1.5">Extremely Satisfied</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ReactEcharts option={getOption()} style={{ height: '300px', width: '100%' }} />


                    </div>
                </div>

            </section>

            {/* Leadership Report pages  */}
            {/*Leadership Report  Overall */}
            {/* page 1 */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Leadership Report</p>
                    <p className='font-medium italic text-base'>07 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-6 w-full ">
                    <h1 className="text-2xl font-bold capitalize  bg-[#212121]/90 text-white p-4">
                        Dimension Summary
                    </h1>

                    <table className="w-full bg-[#F1F5F9]  ">
                        <thead className="  bg-[#EAEFF4]">
                            <th className="p-2  px-4 text-sm font-semibold text-[#212121] text-left border-r-2 border-b-2 border-white ">Dimension</th>
                            <th className="p-2 text-sm font-semibold text-[#212121] border-r-2 border-b-2 border-white text-left ">Sub-Dimension</th>
                            <th className="p-2 text-sm font-semibold text-[#212121] text-right    border-b-2 border-white">Heading 1</th>
                            <th className="p-2 text-sm font-semibold text-[#212121]    border-b-2 border-white"> </th>
                            <th className="p-2 text-sm font-semibold text-[#212121] text-left border-r-2 border-b-2 border-white ">Heading 2</th>

                        </thead>
                        <tbody className=" ">
                            {elements4.map((_, index) => (
                                <>
                                    <tr className="text-sm  ">
                                        <td className="p-2 px-4  border-r-2 border-b-2 border-white text-center text-base uppercase font-semibold " rowSpan={5}>Leading
                                            Self</td>
                                    </tr>
                                    {elements4.map((_, index) => (
                                        <tr className="text-xs  ">
                                            <td className="p-2 text-left border-r-2 border-b-2 border-white">Self Awareness</td>
                                            <td className="p-2 text-right  border-b-2 border-white">External Benchmarks</td>
                                            <td className="p-2 px-0 w-fit  border-b-2 border-white">
                                                <div
                                                    className="flex items-center justify-center space-x-0  "
                                                >
                                                    <span className="pr-2 font-semibold">80%</span>
                                                    <div className="bg-[#DFE5E8] w-32 h-6 overflow-hidden flex justify-end">
                                                        <div
                                                            className="bg-[#4DB6AC] w-[50%] h-6"
                                                        />
                                                    </div>
                                                    <div className="bg-[#DFE5E8] w-32 h-6 overflow-hidden flex justify-start">
                                                        <div
                                                            className="bg-[#EF5350] w-[50%] h-6"
                                                        />
                                                    </div>
                                                    <span className="pl-2 font-semibold">80%</span>
                                                </div>
                                            </td>
                                            <td className="p-2 text-left border-r-2 border-b-2 border-white ">Internal Benchmarks</td>
                                        </tr>
                                    ))}
                                </>

                            ))}

                        </tbody>
                    </table>

                </div>

            </section>

            {/*Hitachi Demographic Wise*/}
            {/* page 1 */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations | 07 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-6 w-full ">
                    <h1 className="text-2xl font-bold capitalize  bg-[#212121]/90 text-white p-4">
                        Respondent summary
                    </h1>

                    <div className="grid grid-cols-2 divide-x  ">
                        {/* 1 compare */}
                        <div className="space-y-4 pr-6">
                            <div className="text-lg font-bold text-[#212121] bg-[#F1F5F9] p-2 px-4 flex justify-between items-center">
                                <p>  Administration</p>
                                <div className="flex items-center justify-between space-x-2">
                                    <span className="font-bold">342</span>
                                    <span className="text-sm font-normal">Respondents</span>
                                </div>
                            </div>

                            <div className="  ">
                                <p className="text-lg font-semibold" >Tenure</p>

                                <div className="grid grid-cols-5 gap-4 py-4 border-b">
                                    <div className="space-y-2  ">
                                        <div className="flex items-center justify-between px-2 text-sm">
                                            <span>8</span>
                                            <span>8%</span>
                                        </div>
                                        <div className="w-full h-28 bg-[#E8F4FD] flex items-end">
                                            <div className="bg-[#42A5F5] h-[20%] w-full"></div>
                                        </div>
                                        <p className="text-center text-[#212121]/70 text-sm leading-snug font-medium">Below 6
                                            Months</p>
                                    </div>
                                    <div className="space-y-2  ">
                                        <div className="flex items-center justify-between px-2 text-sm">
                                            <span>8</span>
                                            <span>8%</span>
                                        </div>
                                        <div className="w-full h-28 bg-[#E8F4FD] flex items-end">
                                            <div className="bg-[#7E57C2] h-[40%] w-full"></div>
                                        </div>
                                        <p className="text-center text-[#212121]/70 text-sm leading-snug font-medium">6 Months to 3
                                            yrs</p>
                                    </div>
                                    <div className="space-y-2  ">
                                        <div className="flex items-center justify-between px-2 text-sm">
                                            <span>8</span>
                                            <span>8%</span>
                                        </div>
                                        <div className="w-full h-28 bg-[#E8F4FD] flex items-end">
                                            <div className="bg-[#FFC107] h-[30%] w-full"></div>
                                        </div>
                                        <p className="text-center text-[#212121]/70 text-sm leading-snug font-medium">3 to
                                            5 yrs</p>
                                    </div>
                                    <div className="space-y-2  ">
                                        <div className="flex items-center justify-between px-2 text-sm">
                                            <span>8</span>
                                            <span>8%</span>
                                        </div>
                                        <div className="w-full h-28 bg-[#E8F4FD] flex items-end">
                                            <div className="bg-[#66BB6A] h-[60%] w-full"></div>
                                        </div>
                                        <p className="text-center text-[#212121]/70 text-sm leading-snug font-medium">5 to 10 yrs</p>
                                    </div>
                                    <div className="space-y-2  ">
                                        <div className="flex items-center justify-between px-2 text-sm">
                                            <span>8</span>
                                            <span>8%</span>
                                        </div>
                                        <div className="w-full h-28 bg-[#E8F4FD] flex items-end">
                                            <div className="bg-[#5C6BC0] h-[20%] w-full"></div>
                                        </div>
                                        <p className="text-center text-[#212121]/70 text-sm leading-snug font-medium">Above 10 Yrs</p>
                                    </div>

                                </div>
                            </div>

                            <div className="">
                                <p className="text-lg font-semibold" >Gender</p>

                                <div className="grid grid-cols-1 gap-4 py-4 border-b">
                                    <div className=" flex items-center space-x-2 ">
                                        <div className="flex-shrink-0  text-[#42A5F5] flex items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="currentColor" class="icon icon-tabler icons-tabler-filled icon-tabler-man"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M15 8c1.628 0 3.2 .787 4.707 2.293a1 1 0 0 1 -1.414 1.414c-.848 -.848 -1.662 -1.369 -2.444 -1.587l-.849 5.944v4.936a1 1 0 0 1 -2 0v-4h-2v4a1 1 0 0 1 -2 0v-4.929l-.85 -5.951c-.781 .218 -1.595 .739 -2.443 1.587a1 1 0 1 1 -1.414 -1.414c1.506 -1.506 3.08 -2.293 4.707 -2.293z" /><path d="M12 1a3 3 0 1 1 -3 3l.005 -.176a3 3 0 0 1 2.995 -2.824" /></svg>
                                        </div>

                                        <div className="w-full h-7 bg-[#E8F4FD] flex items-start">
                                            <div className="bg-[#42A5F5] w-[20%] h-7"></div>
                                        </div>
                                        <div className="flex flex-shrink-0 items-center justify-between px-2 text-base space-x-4 font-medium">
                                            <span>92</span>
                                            <span>92%</span>
                                        </div>

                                    </div>
                                    <div className=" flex items-center space-x-2 ">
                                        <div className="flex-shrink-0  text-[#7E57C2] flex items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="currentColor" class="icon icon-tabler icons-tabler-filled icon-tabler-woman"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 8c1.91 0 3.79 .752 5.625 2.219a1 1 0 1 1 -1.25 1.562c-1.019 -.815 -2.016 -1.345 -2.997 -1.6l1.584 5.544a1 1 0 0 1 -.962 1.275h-1v4a1 1 0 0 1 -2 0v-4h-2v4a1 1 0 0 1 -2 0v-4h-1a1 1 0 0 1 -.962 -1.275l1.584 -5.545c-.98 .256 -1.978 .786 -2.997 1.601a1 1 0 1 1 -1.25 -1.562c1.733 -1.386 3.506 -2.133 5.307 -2.212l.335 -.007z" /><path d="M12 1a3 3 0 1 1 -3 3l.005 -.176a3 3 0 0 1 2.995 -2.824" /></svg>
                                        </div>

                                        <div className="w-full h-7 bg-[#E8F4FD] flex items-start">
                                            <div className="bg-[#7E57C2] w-[20%] h-7"></div>
                                        </div>
                                        <div className="flex flex-shrink-0 items-center justify-between px-2 text-base space-x-4 font-medium">
                                            <span>88</span>
                                            <span>88%</span>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <p className="text-lg font-semibold" >Zone</p>

                                <div className="flex items-center space-x-6 justify-center py-4 border-b">

                                    <ReactEcharts option={optionPie} style={{ height: '170px', width: '170px' }} className="" />

                                    <table className="w-full">
                                        <tbody>
                                            {elements10.map((_, index) => (
                                                <tr className="bg-[#F1F5F9]">
                                                    <td className="text-[#212121] w-40 flex-shrink-0 font-medium text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                                        <div className="flex itmes-center space-x-2   ">
                                                            <div className="w-4 h-4 bg-[#42A5F5]" />
                                                            <span> East</span>
                                                        </div>
                                                    </td>
                                                    <td className="font-medium w-10 text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                                        25
                                                    </td>
                                                    <td className="font-medium w-10 text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                                        25%
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>



                                </div>
                            </div>

                            <div className=" ">
                                <p className="text-lg font-semibold" >Grade</p>

                                <div className="py-4 border-b w-full">

                                    <table className="w-full">
                                        <tbody>
                                            {elements10.map((_, index) => (
                                                <tr className=" ">
                                                    <td className="text-[#212121] w-40 flex-shrink-0 font-medium text-right text-sm p-2 py-1  ">
                                                        Senior Manager
                                                    </td>
                                                    <td className="w-[50%]  p-2 py-1  ">
                                                        <div className="bg-[#E8F4FD] w-full h-6 overflow-hidden flex items-center space-x-2">
                                                            <div
                                                                className="w-3/6 h-6"
                                                                style={{ width: "75%", backgroundColor: "#42A5F5" }}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className="font-medium w-10 text-left text-sm p-2 py-1  ">
                                                        20
                                                    </td>
                                                    <td className="font-medium w-10 text-left text-sm p-2 py-1  ">
                                                        10%
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>


                            </div>

                            <div className="  ">
                                <p className="text-lg font-semibold" >Employee Type</p>

                                <div className="flex items-center space-x-6 justify-center py-4 ">

                                    <ReactEcharts option={optionPie} style={{ height: '170px', width: '170px' }} className="" />

                                    <table className="w-full">
                                        <tbody>

                                            <tr className="bg-[#F1F5F9]">
                                                <td className="text-[#212121] w-40 flex-shrink-0 font-medium text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                                    <div className="flex itmes-center space-x-2   ">
                                                        <div className="w-4 h-4 bg-[#42A5F5]" />
                                                        <span> Field</span>
                                                    </div>
                                                </td>
                                                <td className="font-medium w-10 text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                                    25
                                                </td>
                                                <td className="font-medium w-10 text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                                    25%
                                                </td>
                                            </tr>
                                            <tr className="bg-[#F1F5F9]">
                                                <td className="text-[#212121] w-40 flex-shrink-0 font-medium text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                                    <div className="flex itmes-center space-x-2   ">
                                                        <div className="w-4 h-4 bg-[#7E57C2]" />
                                                        <span> Office</span>
                                                    </div>
                                                </td>
                                                <td className="font-medium w-10 text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                                    25
                                                </td>
                                                <td className="font-medium w-10 text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                                    25%
                                                </td>
                                            </tr>


                                        </tbody>
                                    </table>



                                </div>
                            </div>


                        </div>

                        {/* 2 compare */}
                        <div className="space-y-4 pl-6 ">
                            <div className="text-lg font-bold text-[#212121] bg-[#F1F5F9] p-2 px-4 flex justify-between items-center">
                                <p>  Human Recourse</p>
                                <div className="flex items-center justify-between space-x-2">
                                    <span className="font-bold">342</span>
                                    <span className="text-sm font-normal">Respondents</span>
                                </div>
                            </div>

                            <div className="  ">
                                <p className="text-lg font-semibold" >Tenure</p>

                                <div className="grid grid-cols-5 gap-4 py-4 border-b">
                                    <div className="space-y-2  ">
                                        <div className="flex items-center justify-between px-2 text-sm">
                                            <span>8</span>
                                            <span>8%</span>
                                        </div>
                                        <div className="w-full h-28 bg-[#E8F4FD] flex items-end">
                                            <div className="bg-[#42A5F5] h-[20%] w-full"></div>
                                        </div>
                                        <p className="text-center text-[#212121]/70 text-sm leading-snug font-medium">Below 6
                                            Months</p>
                                    </div>
                                    <div className="space-y-2  ">
                                        <div className="flex items-center justify-between px-2 text-sm">
                                            <span>8</span>
                                            <span>8%</span>
                                        </div>
                                        <div className="w-full h-28 bg-[#E8F4FD] flex items-end">
                                            <div className="bg-[#7E57C2] h-[40%] w-full"></div>
                                        </div>
                                        <p className="text-center text-[#212121]/70 text-sm leading-snug font-medium">6 Months to 3
                                            yrs</p>
                                    </div>
                                    <div className="space-y-2  ">
                                        <div className="flex items-center justify-between px-2 text-sm">
                                            <span>8</span>
                                            <span>8%</span>
                                        </div>
                                        <div className="w-full h-28 bg-[#E8F4FD] flex items-end">
                                            <div className="bg-[#FFC107] h-[30%] w-full"></div>
                                        </div>
                                        <p className="text-center text-[#212121]/70 text-sm leading-snug font-medium">3 to
                                            5 yrs</p>
                                    </div>
                                    <div className="space-y-2  ">
                                        <div className="flex items-center justify-between px-2 text-sm">
                                            <span>8</span>
                                            <span>8%</span>
                                        </div>
                                        <div className="w-full h-28 bg-[#E8F4FD] flex items-end">
                                            <div className="bg-[#66BB6A] h-[60%] w-full"></div>
                                        </div>
                                        <p className="text-center text-[#212121]/70 text-sm leading-snug font-medium">5 to 10 yrs</p>
                                    </div>
                                    <div className="space-y-2  ">
                                        <div className="flex items-center justify-between px-2 text-sm">
                                            <span>8</span>
                                            <span>8%</span>
                                        </div>
                                        <div className="w-full h-28 bg-[#E8F4FD] flex items-end">
                                            <div className="bg-[#5C6BC0] h-[20%] w-full"></div>
                                        </div>
                                        <p className="text-center text-[#212121]/70 text-sm leading-snug font-medium">Above 10 Yrs</p>
                                    </div>

                                </div>
                            </div>

                            <div className="">
                                <p className="text-lg font-semibold" >Gender</p>

                                <div className="grid grid-cols-1 gap-4 py-4 border-b">
                                    <div className=" flex items-center space-x-2 ">
                                        <div className="flex-shrink-0  text-[#42A5F5] flex items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="currentColor" class="icon icon-tabler icons-tabler-filled icon-tabler-man"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M15 8c1.628 0 3.2 .787 4.707 2.293a1 1 0 0 1 -1.414 1.414c-.848 -.848 -1.662 -1.369 -2.444 -1.587l-.849 5.944v4.936a1 1 0 0 1 -2 0v-4h-2v4a1 1 0 0 1 -2 0v-4.929l-.85 -5.951c-.781 .218 -1.595 .739 -2.443 1.587a1 1 0 1 1 -1.414 -1.414c1.506 -1.506 3.08 -2.293 4.707 -2.293z" /><path d="M12 1a3 3 0 1 1 -3 3l.005 -.176a3 3 0 0 1 2.995 -2.824" /></svg>
                                        </div>

                                        <div className="w-full h-7 bg-[#E8F4FD] flex items-start">
                                            <div className="bg-[#42A5F5] w-[20%] h-7"></div>
                                        </div>
                                        <div className="flex flex-shrink-0 items-center justify-between px-2 text-base space-x-4 font-medium">
                                            <span>92</span>
                                            <span>92%</span>
                                        </div>

                                    </div>
                                    <div className=" flex items-center space-x-2 ">
                                        <div className="flex-shrink-0  text-[#7E57C2] flex items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="currentColor" class="icon icon-tabler icons-tabler-filled icon-tabler-woman"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 8c1.91 0 3.79 .752 5.625 2.219a1 1 0 1 1 -1.25 1.562c-1.019 -.815 -2.016 -1.345 -2.997 -1.6l1.584 5.544a1 1 0 0 1 -.962 1.275h-1v4a1 1 0 0 1 -2 0v-4h-2v4a1 1 0 0 1 -2 0v-4h-1a1 1 0 0 1 -.962 -1.275l1.584 -5.545c-.98 .256 -1.978 .786 -2.997 1.601a1 1 0 1 1 -1.25 -1.562c1.733 -1.386 3.506 -2.133 5.307 -2.212l.335 -.007z" /><path d="M12 1a3 3 0 1 1 -3 3l.005 -.176a3 3 0 0 1 2.995 -2.824" /></svg>
                                        </div>

                                        <div className="w-full h-7 bg-[#E8F4FD] flex items-start">
                                            <div className="bg-[#7E57C2] w-[20%] h-7"></div>
                                        </div>
                                        <div className="flex flex-shrink-0 items-center justify-between px-2 text-base space-x-4 font-medium">
                                            <span>88</span>
                                            <span>88%</span>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <p className="text-lg font-semibold" >Zone</p>

                                <div className="flex items-center space-x-6 justify-center py-4 border-b">

                                    <ReactEcharts option={optionPie} style={{ height: '170px', width: '170px' }} className="" />

                                    <table className="w-full">
                                        <tbody>
                                            {elements10.map((_, index) => (
                                                <tr className="bg-[#F1F5F9]">
                                                    <td className="text-[#212121] w-40 flex-shrink-0 font-medium text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                                        <div className="flex itmes-center space-x-2   ">
                                                            <div className="w-4 h-4 bg-[#42A5F5]" />
                                                            <span> East</span>
                                                        </div>
                                                    </td>
                                                    <td className="font-medium w-10 text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                                        25
                                                    </td>
                                                    <td className="font-medium w-10 text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                                        25%
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>



                                </div>
                            </div>

                            <div className=" ">
                                <p className="text-lg font-semibold" >Grade</p>

                                <div className="py-4 border-b w-full">

                                    <table className="w-full">
                                        <tbody>
                                            {elements10.map((_, index) => (
                                                <tr className=" ">
                                                    <td className="text-[#212121] w-40 flex-shrink-0 font-medium text-right text-sm p-2 py-1  ">
                                                        Senior Manager
                                                    </td>
                                                    <td className="w-[50%]  p-2 py-1  ">
                                                        <div className="bg-[#E8F4FD] w-full h-6 overflow-hidden flex items-center space-x-2">
                                                            <div
                                                                className="w-3/6 h-6"
                                                                style={{ width: "75%", backgroundColor: "#42A5F5" }}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className="font-medium w-10 text-left text-sm p-2 py-1  ">
                                                        20
                                                    </td>
                                                    <td className="font-medium w-10 text-left text-sm p-2 py-1  ">
                                                        10%
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>


                            </div>

                            <div className="  ">
                                <p className="text-lg font-semibold" >Employee Type</p>

                                <div className="flex items-center space-x-6 justify-center py-4 ">

                                    <ReactEcharts option={optionPie} style={{ height: '170px', width: '170px' }} className="" />

                                    <table className="w-full">
                                        <tbody>

                                            <tr className="bg-[#F1F5F9]">
                                                <td className="text-[#212121] w-40 flex-shrink-0 font-medium text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                                    <div className="flex itmes-center space-x-2   ">
                                                        <div className="w-4 h-4 bg-[#42A5F5]" />
                                                        <span> Field</span>
                                                    </div>
                                                </td>
                                                <td className="font-medium w-10 text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                                    25
                                                </td>
                                                <td className="font-medium w-10 text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                                    25%
                                                </td>
                                            </tr>
                                            <tr className="bg-[#F1F5F9]">
                                                <td className="text-[#212121] w-40 flex-shrink-0 font-medium text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                                    <div className="flex itmes-center space-x-2   ">
                                                        <div className="w-4 h-4 bg-[#7E57C2]" />
                                                        <span> Office</span>
                                                    </div>
                                                </td>
                                                <td className="font-medium w-10 text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                                    25
                                                </td>
                                                <td className="font-medium w-10 text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                                    25%
                                                </td>
                                            </tr>


                                        </tbody>
                                    </table>



                                </div>
                            </div>


                        </div>

                    </div>



                </div>

            </section>

            {/* Leadership Report pages  */}
            {/* page 2 */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Leadership Report</p>
                    <p className='font-medium italic text-base'>Sample | 10 Oct, 2024</p>
                </div>

                <div className="space-y-6">
                    <h1 className="text-2xl font-bold capitalize text-[#212121]">
                        Introduction
                    </h1>
                    <p className="text-[#212121]">
                        Your talents are the natural ways in which you think, feel, and behave. They define how you approach tasks and interact with the world around you. Understanding and embracing your talents is crucial for achieving success and fulfillment in life. By knowing your talents, you can experience flow at work, perform at your best, and stand out from others.
                        <br />
                        <br />
                        This report helps you understand and embrace your unique strengths, empowering you to leverage them effectively. By reflecting on your talents, you gain insights into your thoughts, actions, and relationships. This awareness enables you to proactively enhance your skills and pursue your goals. Additionally, it offers valuable insights into your cognitive processes, interpersonal dynamics, behaviors, and leadership style, setting the stage for success.
                    </p>
                    <div className="w-full space-y-1 page-break">
                        <h1 className="text-xl font-semibold text-[#212121] uppercase">
                            How to go through this report
                        </h1>
                    </div>
                    <div className="space-y-0.5 text-base">
                        <div className="flex p-3 bg-[#F5F5F5]">
                            <img
                                src="/img/icon/dot.png"
                                className="w-1.5 h-1.5 mt-2 bg-gray-900 rounded-full"
                            />
                            <p className="mx-2">
                                Fully engage with the report, reflecting on every word to grasp your unique strengths. Recall instances where your talents were in play and note their impact on your performance.
                            </p>
                        </div>
                        <div className="flex p-3 bg-[#E2E2E2]">
                            <img
                                src="/img/icon/dot.png"
                                className="w-1.5 h-1.5 mt-2 bg-gray-900 rounded-full"
                            />
                            <p className="mx-2">
                                Prioritize your Dominant themes, as they heavily influence your behavior and personal brand. Understanding their origin helps you cultivate an authentic perception of yourself.
                            </p>
                        </div>
                        <div className="flex p-3 bg-[#F5F5F5]">
                            <img
                                src="/img/icon/dot.png"
                                className="w-1.5 h-1.5 mt-2 bg-gray-900 rounded-full"
                            />
                            <p className="mx-2">
                                {" "}
                                Pay attention to your non-dominant talent themes to identify areas where partnering with others may be beneficial and recognize your areas of lesser natural aptitude.
                            </p>
                        </div>
                        <div className="flex p-3 bg-[#E2E2E2]">
                            <img
                                src="/img/icon/dot.png"
                                className="w-1.5 h-1.5 mt-2 bg-gray-900 rounded-full"
                            />
                            <p className="mx-2">
                                Proactively plan to leverage your inherent talents in your next task to observe how they enhance your performance.
                            </p>
                        </div>
                        <div className="flex p-3 bg-[#F5F5F5]">
                            <img
                                src="/img/icon/dot.png"
                                className="w-1.5 h-1.5 mt-2 bg-gray-900 rounded-full"
                            />
                            <p className="mx-2">
                                For further insights and clarity, feel free to reach out to us at contact@happyplus.in. We can schedule a coaching call to address any questions or concerns you may have.
                            </p>
                        </div>
                    </div>
                    <main className="page-break">
                        <div className="w-full space-y-1 page-break">
                            <h1 className="text-xl font-semibold text-[#212121] uppercase">
                                How to use this report
                            </h1>
                        </div>
                    </main>
                    <div className="space-y-0.5 text-base">
                        <div className="flex p-3 bg-[#F5F5F5]">
                            <img
                                src="/img/icon/dot.png"
                                className="w-1.5 h-1.5 mt-2 bg-gray-900 rounded-full"
                            />
                            <p className="mx-2">
                                Prioritize your Dominant talents by keeping them at the forefront of your mind before embarking on any task to optimize their utilization.
                            </p>
                        </div>
                        <div className="flex p-3 bg-[#E2E2E2]">
                            <img
                                src="/img/icon/dot.png"
                                className="w-1.5 h-1.5 mt-2 bg-gray-900 rounded-full"
                            />
                            <p className="mx-2">
                                Share the report with your manager to enhance their understanding of what drives you and how they can better support your success.
                            </p>
                        </div>
                        <div className="flex p-3 bg-[#F5F5F5]">
                            <img
                                src="/img/icon/dot.png"
                                className="w-1.5 h-1.5 mt-2 bg-gray-900 rounded-full"
                            />
                            <p className="mx-2">
                                Engage your friends and close ones by sharing the report and inviting them to reflect on it with you for encouraging mutual reflection and growth.
                            </p>
                        </div>
                        <div className="flex p-3 bg-[#E2E2E2]">
                            <img
                                src="/img/icon/dot.png"
                                className="w-1.5 h-1.5 mt-2 bg-gray-900 rounded-full"
                            />
                            <p className="mx-2">
                                Extend the report to your colleagues, allowing them to leverage your unique talents for collective success.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* page 3 */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Leadership Report</p>
                    <p className='font-medium italic text-base'>Sample | 10 Oct, 2024</p>
                </div>

                <div className="flex flex-col space-y-6 w-full ">
                    <h1 className="text-2xl font-bold capitalize text-[#212121]">
                        Leadership 5 Domains Definition
                    </h1>

                    <div className="space-y-2">
                        <div className="flex items-center p-4 bg-[#FFF5D9] space-x-6  ">
                            <div className="flex items-center space-x-4  w-60 flex-shrink-0">
                                <div className="w-4 h-4 bg-[#FFC107]"></div>
                                <p className="text-lg font-semibold uppercase">  Leading Self</p>
                            </div>
                            <div>
                                <p>These talents shape your thinking and perception of the world, allowing you to deliberate on ideas, possibilities, and future actions. They encompass intellectual abilities, which drive human progress. By contemplating these themes, we explore our past, envision the future, and plan in the present. Thinking talents serve as the strategic pathway to our future.</p>
                            </div>
                        </div>
                        <div className="flex items-center p-4 bg-[#E4F3E4] space-x-6  ">
                            <div className="flex items-center space-x-4  w-60 flex-shrink-0">
                                <div className="w-4 h-4 bg-[#4CAF50]"></div>
                                <p className="text-lg font-semibold uppercase">  Leading Others</p>
                            </div>
                            <div>
                                <p>As social creatures, humans thrive through connections with others. Sharing successes, hardships, and experiences with fellow beings is essential for our well-being and progress. These bonds elevate us, enabling collective achievement of goals, overcoming challenges, and shaping history together.</p>
                            </div>
                        </div>
                        <div className="flex items-center p-4 bg-[#DEF0FD] space-x-6  ">
                            <div className="flex items-center space-x-4  w-60 flex-shrink-0">
                                <div className="w-4 h-4 bg-[#2196F3]"></div>
                                <p className="text-lg font-semibold uppercase">  Leading Team</p>
                            </div>
                            <div>
                                <p>Action talents drive progress by turning ideas into reality. While ideas are important, true advancement occurs through action. Acting talents empower us to translate thoughts into tangible outcomes, driving visible progress and consistent achievement of tasks and goals.</p>
                            </div>
                        </div>
                        <div className="flex items-center p-4 bg-[#E8E2F5] space-x-6  ">
                            <div className="flex items-center space-x-4  w-60 flex-shrink-0">
                                <div className="w-4 h-4 bg-[#673AB7]"></div>
                                <p className="text-lg font-semibold uppercase">  Leading Results</p>
                            </div>
                            <div>
                                <p>Leading talents define our ability to lead others toward a shared vision. Leadership isn't just about holding a position; it's a mindset that involves inspiring, aligning, and motivating others to work towards a desired future. Leaders shape a better tomorrow by turning their vision into reality through collaboration and empowerment of others.</p>
                            </div>
                        </div>
                        <div className="flex items-center p-4 bg-[#E2E4F3] space-x-6  ">
                            <div className="flex items-center space-x-4  w-60 flex-shrink-0">
                                <div className="w-4 h-4 bg-[#3F51B5]"></div>
                                <p className="text-lg font-semibold uppercase">  Leading Community</p>
                            </div>
                            <div>
                                <p>Leading talents define our ability to lead others toward a shared vision. Leadership isn't just about holding a position; it's a mindset that involves inspiring, aligning, and motivating others to work towards a desired future. Leaders shape a better tomorrow by turning their vision into reality through collaboration and empowerment of others.</p>
                            </div>
                        </div>
                    </div>

                </div>

            </section>

            {/* page 4 */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Leadership Report</p>
                    <p className='font-medium italic text-base'>Sample | 10 Oct, 2024</p>
                </div>

                <div className="flex flex-col space-y-6 w-full ">
                    <h1 className="text-2xl font-bold capitalize text-[#212121]">
                        Leadership 25 Sub-Domains Definition
                    </h1>

                    <div className="flex flex-wrap justify-start gap-4 text-sm text-[#3C3C3C]   ">
                        <div className="flex items-center ">
                            <div className="p-1.5 rounded-sm bg-[#FFC107]" />
                            <p className="ml-1.5">Leading Self</p>
                        </div>
                        <div className="flex items-center ">
                            <div className="p-1.5 rounded-sm bg-[#4CAF50]" />
                            <p className="ml-1.5">Leading Others</p>
                        </div>
                        <div className="flex items-center ">
                            <div className="p-1.5 rounded-sm bg-[#2196F3]" />
                            <p className="ml-1.5">Leading Team</p>
                        </div>
                        <div className="flex items-center ">
                            <div className="p-1.5 rounded-sm bg-[#673AB7]" />
                            <p className="ml-1.5">Leading Results</p>
                        </div>
                        <div className="flex items-center ">
                            <div className="p-1.5 rounded-sm bg-[#3F51B5]" />
                            <p className="ml-1.5">Leading Community</p>
                        </div>

                    </div>


                    <table className="w-full bg-[#F1F5F9] divide-y-2 divide-white">
                        <thead className=" bg-[#F1F5F9]">
                            <tr>
                                <th className="p-2  px-4 text-sm font-semibold text-[#212121] text-left w-[20%] border-r-2 border-b-2 border-white">
                                    Sub-Domains
                                </th>
                                <th className="p-2 text-sm font-semibold text-[#212121] w-[80%] text-left border-r-2 border-b-2 border-white">
                                    Definition
                                </th>
                            </tr>
                        </thead>
                        <tbody className=" ">
                            {elements10.map((_, index) => (
                                <tr className="text-sm  bg-[#FFF8E1] ">
                                    <td className="p-2 px-4 text-left border-r-2 border-b-2 border-white capitalize font-semibold text-sm text-[#3C3C3C] ">
                                        Conflict resolution
                                    </td>
                                    <td className="p-2 text-left text-xs border-r-2 border-b-2 border-white">People with this theme have a superior ability to solve any problem in life effortlessly, that too in multiple ways.</td>
                                </tr>
                            ))}
                            {elements10.map((_, index) => (
                                <tr className="text-sm  bg-[#E8F5E9]">
                                    <td className="p-2 px-4 text-left border-r-2 border-b-2 border-white capitalize font-semibold text-sm text-[#3C3C3C] ">
                                        Conflict resolution
                                    </td>
                                    <td className="p-2 text-left text-xs border-r-2 border-b-2 border-white">People with this theme have a superior ability to solve any problem in life effortlessly, that too in multiple ways.</td>
                                </tr>
                            ))}
                            {elements10.map((_, index) => (
                                <tr className="text-sm  bg-[#EDE7F6]">
                                    <td className="p-2 px-4 text-left border-r-2 border-b-2 border-white capitalize font-semibold text-sm text-[#3C3C3C] ">
                                        Conflict resolution
                                    </td>
                                    <td className="p-2 text-left text-xs border-r-2 border-b-2 border-white">People with this theme have a superior ability to solve any problem in life effortlessly, that too in multiple ways.</td>
                                </tr>
                            ))}
                            {elements10.map((_, index) => (
                                <tr className="text-sm  bg-[#E8EAF6]">
                                    <td className="p-2 px-4 text-left border-r-2 border-b-2 border-white capitalize font-semibold text-sm text-[#3C3C3C] ">
                                        Conflict resolution
                                    </td>
                                    <td className="p-2 text-left text-xs border-r-2 border-b-2 border-white">People with this theme have a superior ability to solve any problem in life effortlessly, that too in multiple ways.</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>


                </div>

            </section>


            {/* page 5 */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Leadership Report</p>
                    <p className='font-medium italic text-base'>Sample | 10 Oct, 2024</p>
                </div>

                <div className="flex flex-col space-y-6 w-full ">
                    <h1 className="text-2xl font-bold capitalize text-[#212121]">
                        Leadership Summary
                    </h1>

                    <table className="w-full bg-[#F1F5F9]  ">
                        <thead className="  bg-[#F1F5F9]">
                            <th className="p-2  px-4 text-sm font-semibold text-[#212121] text-left border-r-2 border-b-2 border-white ">Domains</th>
                            <th className="p-2 text-sm font-semibold text-[#212121] border-r-2 border-b-2 border-white text-left ">Sub-Domains</th>
                            <th className="p-2 text-sm font-semibold text-[#212121] text-right    border-b-2 border-white">Qualities</th>
                            <th className="p-2 text-sm font-semibold text-[#212121]    border-b-2 border-white"> </th>
                            <th className="p-2 text-sm font-semibold text-[#212121] text-left border-r-2 border-b-2 border-white ">Qualities</th>

                        </thead>
                        <tbody className=" ">
                            {elements4.map((_, index) => (
                                <>
                                    <tr className="text-sm bg-[#FFC107] ">
                                        <td className="p-2 px-4  border-r-2 border-b-2 border-white text-center text-base uppercase font-semibold " rowSpan={5}>Leading
                                            Self</td>
                                    </tr>
                                    {elements4.map((_, index) => (
                                        <tr className="text-xs  ">
                                            <td className="p-2 text-left border-r-2 border-b-2 border-white bg-[#FFF8E1]">3. Self Awareness</td>
                                            <td className="p-2 text-right  border-b-2 border-white">External Benchmarks</td>
                                            <td className="p-2 px-0 w-fit  border-b-2 border-white">
                                                <div
                                                    className="flex items-center justify-center space-x-0  "
                                                >
                                                    <span className="pr-2 font-semibold">80%</span>
                                                    <div className="bg-[#DFE5E8] w-32 h-6 overflow-hidden flex justify-end">
                                                        <div
                                                            className="bg-[#009688] w-[50%] h-6"
                                                        />
                                                    </div>
                                                    <div className="bg-[#DFE5E8] w-32 h-6 overflow-hidden flex justify-start">
                                                        <div
                                                            className="bg-[#9C27B0] w-[50%] h-6"
                                                        />
                                                    </div>
                                                    <span className="pl-2 font-semibold">80%</span>
                                                </div>
                                            </td>
                                            <td className="p-2 text-left border-r-2 border-b-2 border-white ">Internal Benchmarks</td>
                                        </tr>
                                    ))}
                                </>

                            ))}

                        </tbody>
                    </table>

                </div>

            </section>

            {/* page 6 */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Leadership Report</p>
                    <p className='font-medium italic text-base'>Sample | 10 Oct, 2024</p>
                </div>

                <div className="flex flex-col space-y-6 w-full ">
                    <div className="space-y-3">
                        <div className='bg-[#FFB300] px-6 py-2 text-white uppercase font-semibold flex w-fit rounded-tr-lg   '>
                            Leading Self
                        </div>
                        <h1 className="text-2xl font-bold capitalize text-[#212121]">
                            1. Thinking
                        </h1>
                        <div className="bg-[#FFB30029] w-full h-5 overflow-hidden flex justify-start">
                            <div className="bg-[#FFB300] w-[50%] h-5" />
                        </div>

                    </div>

                    <div className="w-full  ">

                        <h2 className="p-2 text-base font-semibold text-[#212121] text-left uppercase">Qualities</h2>
                        <div className="text-sm w-full flex items-center    ">

                            <div className="p-2 text-left  flex itmes-center space-x-2 flex-shrink-0 ">
                                <span>
                                    intellectual
                                </span>

                                <span className="pr-2 font-semibold">80%</span>
                            </div>

                            <div
                                className="flex items-center justify-center space-x-0  w-full"
                            >

                                <div className="bg-[#DFE5E8] w-[50%] h-5 overflow-hidden flex justify-end">
                                    <div
                                        className="bg-[#009688] w-[50%] h-5"
                                    />
                                </div>
                                <div className="bg-[#DFE5E8] w-[50%] h-5 overflow-hidden flex justify-start">
                                    <div
                                        className="bg-[#9C27B0] w-[50%] h-5"
                                    />
                                </div>

                            </div>

                            <div className="p-2 text-left  flex itmes-center space-x-2 flex-shrink-0 ">  <span className="pl-2 font-semibold">80%</span>
                                <span>Practical</span></div>
                        </div>



                    </div>

                    <div className="w-full space-y-1 page-break">
                        <h1 className="text-xl font-semibold text-[#212121] uppercase">
                            SELF SNAPSHOT
                        </h1>
                        <p className="text-[#212121]">
                            Ideas are fascinating. In fact, it is the ideas which make the world tick. Everything which is available in front of us is someone's idea. Driven by these thoughts, you always want to do things in different ways and look at solving problems in a way in which it was never done.
                        </p>
                    </div>
                    <div className="w-full space-y-4 page-break">
                        <h1 className="text-xl font-semibold text-[#212121] uppercase">
                            Self Development
                        </h1>
                        <ul className="text-[#212121] list-disc space-y-4 pl-6">
                            <li>
                                Ideas are something which excites you. Look for different types of ideas around you and think about them. You can learn a lot of things by just observing things around you.
                            </li>
                            <li>
                                Only having ideas and not executing them can have an adverse impact on your reputation. Always partner with planners, and starters to ensure your ideas are also implemented and people can see the impact these ideas have created.
                            </li>
                            <li>
                                You should look for a role which allows you to generate newer ideas constantly. A stint in media, advertising, journalism, creative writing, R&D, research etc. will allow your creative juices to flow.
                            </li>
                            <li>
                                You can also look for a role where you can solve problems using your innovative mind. You are gifted in terms of understanding connections and how things work.
                            </li>
                            <li>
                                When you look at a problem, you can also understand the underlying connections of issues which are causing it. Proactively use it to solve problems.
                            </li>
                        </ul>
                    </div>

                    <div className="w-full space-y-4 page-break">
                        <h1 className="text-xl font-semibold text-[#212121] uppercase">
                            Key Takeaway
                        </h1>
                        <ul className="text-[#212121] list-disc space-y-4 pl-6">
                            <li>
                                Ideas are something which excites you. Look for different types of ideas around you and think about them. You can learn a lot of things by just observing things around you.
                            </li>
                            <li>
                                Only having ideas and not executing them can have an adverse impact on your reputation. Always partner with planners, and starters to ensure your ideas are also implemented and people can see the impact these ideas have created.
                            </li>
                            <li>
                                You should look for a role which allows you to generate newer ideas constantly. A stint in media, advertising, journalism, creative writing, R&D, research etc. will allow your creative juices to flow.
                            </li>
                            <li>
                                You can also look for a role where you can solve problems using your innovative mind. You are gifted in terms of understanding connections and how things work.
                            </li>
                            <li>
                                When you look at a problem, you can also understand the underlying connections of issues which are causing it. Proactively use it to solve problems.
                            </li>
                        </ul>
                    </div>

                </div>

            </section>


        </div>
    );
}
export default HitachiReport;