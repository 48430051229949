import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactWordcloud from 'react-wordcloud';
import 'tippy.js/dist/tippy.css';
import ReactEcharts from "echarts-for-react";

class WordChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedText:''
    }
  }



  componentDidMount() {
  }


    handleKeyword=(data)=>{
      let { handleKeywordCall } = this.props;
      handleKeywordCall(data.text)
      this.setState({SelectedText:data.text+' ('+data.value+')'});

    }

    handleKeywordTooltip=(data)=>{
      this.setState({SelectedText:data.text+' ('+data.value+')'});
    }


    render(){

      let { wordCloudData ,  textDistTreeMapData, FilterList, handleSentencesList}= this.props;
      // let { SelectedText }= this.state;




      let DmgLevel = FilterList && FilterList.length>0?FilterList[0].level:"Demographic"






      let words = []

      // let CurrentWord = '';

      if(wordCloudData && wordCloudData.list &&  wordCloudData.list.length>0){
        wordCloudData.list.forEach((item)=>{
          words.push({
            text: item[0],
            value: item[1]
          })
        })
      }

      // const callbacks = {
      //   // getWordColor: word => word.value > 50 ? "blue" : "red",
      //   onWordClick: (data)=>this.handleKeyword(data),
        
      //   //onWordMouseOver: //////console.log(""),
      //   // getWordTooltip: (data)=>{},
      //   getWordTooltip: function(a){
      //     return (a.text+': '+a.value)
      //   },
      // }



      // const options = {
      //   rotations: 2,
      //   rotationAngles: [0, -90],
      //   padding:5,
      //   fontWeight:600,
      //   fontSizes:[20,50]
        
      // };



      // const formatUtil = echarts.format;
      function getLevelOption() {
        return [
          {
            itemStyle: {
              borderColor: '#e6e6e6',
              borderWidth: 0,
              gapWidth: 1
            },
            upperLabel: {
              show: false
            }
          },
          {
            itemStyle: {
              borderColor: '#e6e6e6',
              borderWidth: 2,
              gapWidth: 1
            },
            emphasis: {
              itemStyle: {
                borderColor: '#e6e6e6'
              }
            }
          },
          {
            colorSaturation: [0.35, 0.5],
            itemStyle: {
              borderWidth: 5,
              gapWidth: 1,
              borderColorSaturation: 0.6
            }
          }
        ];
      }






      // 1: Array(15)
      // 0: Array(2)
      // 0: (2) ['culture', 'aster']
      // 1: 2
      // length: 2
      // [[Prototype]]: Array(0)
      // 1: Array(2)
      // 0: (2) ['current', 'culture']
      // 1: 2
      // length: 2
      // length: 2



      let DataList = []
      if(textDistTreeMapData && textDistTreeMapData.length>0){
        textDistTreeMapData.forEach((item)=>{

            let total = 0;
            let ChildList = []
            if(item && item[1] && item[1].length>0){
              item[1].forEach((innerItem)=>{
                let Lenghtx = innerItem[0];
                let SentenceNo = innerItem && innerItem[1] &&innerItem[1].length>0?innerItem[1].length:0;

                total+=Lenghtx;

                ChildList.push({
                  value: SentenceNo, 
                  name: Lenghtx, 
                  path: item[0]
                })

              });
            }
          

            let temp = {
              name: item[0],
              path: item[0],
              value: total,
              children:ChildList
            }

            DataList.push(temp);

          
        });
      }


      ////////console.log("//////---.DataList...--",DataList)
      //////////console.log("/////....textDistTreeMapData--->",textDistTreeMapData)



      let diskData =  DataList

      // let diskData = [
      //   {
      //     children:[
      //       {value: 744, name: 'CardDAVPlugin.sourcebundle', path: 'AddressBook Plug-Ins/CardDAVPlugin.sourcebundle'},
      //       {value: 28, name: 'DirectoryServices.sourcebundle', path: 'AddressBook Plug-Ins/DirectoryServices.sourcebundle'},
      //       {value: 680, name: 'Exchange.sourcebundle', path: 'AddressBook Plug-Ins/Exchange.sourcebundle'},
      //       {value: 432, name: 'LDAP.sourcebundle', path: 'AddressBook Plug-Ins/LDAP.sourcebundle'},
      //       {value: 20, name: 'LocalSource.sourcebundle', path: 'AddressBook Plug-Ins/LocalSource.sourcebundle'}
      //     ],
      //     name: "AddressBook Plug-Ins",
      //     path: "AddressBook Plug-Ins",
      //     value: 1904
      //   }
      // ]



      // return [
      //   '<div className="tooltip-title">' +
      //     formatUtil.encodeHTML(treePath.join('/')) +
      //     '</div>',
      //   'Disk Usage: ' + formatUtil.addCommas(value) + ' KB'
      // ].join('');

       let { handleKeywordCall } = this.props;
        const onChartClick = (params) => {
          let tempTextList = params && params.name? params.name.split(',') : []
          ////console.log('Chart params', params);

          



          let DmgValue = params && params.data && params.data.path?params.data.path:"";
          let DmgLen = params && params.data && params.data.name?params.data.name:"";


          if(textDistTreeMapData && textDistTreeMapData.length>0){
            let getINdex = textDistTreeMapData.findIndex(prev=>prev[0] === DmgValue);
            ////////console.log("getINdex--->",getINdex)

            if(getINdex!==-1){
              let eleData = textDistTreeMapData[getINdex][1];

              ////////console.log("eleData--->",eleData)
              ////////console.log("DmgLen--->",DmgLen)

              if(eleData && eleData.length>0){
                let getINdex2 = eleData.findIndex(prev=>prev[0] === DmgLen);

                ////////console.log("getINdex2--->",getINdex2)


                if(getINdex2!==-1){
                  let Sentences = eleData && eleData[getINdex2] && eleData[getINdex2][1] && eleData[getINdex2][1].length>0?eleData[getINdex2][1]:[];

                  handleSentencesList(Sentences)

    
                }
              }

            }
          }



          // if(DmgLevel && DmgLevel!=="Demographic"){
          //   let filters = [{
          //     "level":DmgLevel,
          //     "value":DmgValue
          //   }]
          //   handleKeywordCall(tempTextList, true, filters)
          // }



        };


        const onEvents = {
          click: onChartClick,
        };


        return(
            <>
                    {/* <div className="flex justify-between items-center">
                    <h1 className="text-sm text-gray-900 font-semibold">Text Length Distribution Tree Map
                    <span className="text-blue-500">{" ( This rectangular map shows demographic wise comparative analysis of top sentences by length i.e top 15 lengthy sentences. ) "}</span>

                    </h1>
                    </div> */}


                    <div className="bg-white p-4 border space-y-4">
                          <h1 className="text-sm text-gray-500 "><b className="text-sm text-gray-800 font-medium">Text Length Distribution Tree Map
                          <span className="text-blue-500">{" (This rectangular map shows demographic wise comparative analysis of top sentences by length i.e top 15 lengthy sentences) "}</span>
                          
                          </b> 
                          </h1>


                    <ReactEcharts
                    onEvents={onEvents}
                    style={{height:"400px"}}
                    option={{
                      tooltip: {
                        formatter: function (info) {
                          var name = info.data.name;
                          var path = info.data.path;
                          var value = info.data.value;

                          return `
                            <h2><b>${DmgLevel}:</b> ${path}</h2>
                            <h2><b>Length of sentences:</b> ${name}</h2>
                            <h2><b>No. of Sentences:</b> ${value}</h2>
                          `;
                        }
                      },
                      series: [
                        {
                          name: 'Tree Map',
                          type: 'treemap',
                          visibleMin: 300,
                          label: {
                            show: true,
                            formatter: '{b}'
                          },
                          upperLabel: {
                            show: true,
                            height: 30
                          },
                          itemStyle: {
                            borderColor: '#fff'
                          },
                          levels: getLevelOption(),
                          data: diskData,

                          
                        },
                        
                      ]
                      
                    }} 

                  />


                    {/* <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div> */}

            </div>
            </>
        );
    }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(WordChart);
