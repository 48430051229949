import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class StackChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  openChartList = () => {
    if (this.state.showChartList) {
      this.setState({ showChartList: false })
    }
    else {
      this.setState({ showChartList: true })
    }
  }

  handleChartType = (item) => {
    this.setState({ chartTypeName: item })
  }

  render() {
    let { xAxis, handleSelectEDA, getDemographicIndexData, currFilterName, getIndexScore, indexType, } = this.props;


    let nameList = []
    let scoreList = []

    // if(getIndexScore){
    //   nameList.push({
    //     value:"Overall Score",
    //     textStyle: {fontWeight:'bold'}
    //   });
    //   scoreList.push({
    //     value:getIndexScore[indexType].toFixed(2),
    //     itemStyle: {color: '#00cc66'}
    //   })

    // }



    let tempSortData = []
    if (getDemographicIndexData && getDemographicIndexData.length > 0) {
      getDemographicIndexData.forEach((data) => {
        tempSortData.push(data)
      })
    }
    // tempSortData.sort((a,b)=>(a && a.name?a.name:"").charCodeAt(0) - (b && b.name?b.name:"").charCodeAt(0));


    let newSortData = []
    if ((xAxis.trim()) === "Age") {
      // newSortData = tempSortData.sort((a,b)=>(a && a.name?a.name:"").charCodeAt(0) - (b && b.name?b.name:"").charCodeAt(0));




      let ageLabelList = [
        "25 Yrs & Below",
        "Between 25 to 30 yrs",
        "Between 31 to 34 yrs",
        "Between 35 to 44 yrs",
        "Between 45 to 55 yrs",
        "Above 55 Yrs"
      ]

      let count = 0;
      let check = 0;
      if (tempSortData && tempSortData.length > 0) {
        tempSortData.forEach((tempData) => {
          count++
          if (ageLabelList.includes(tempData.name)) {
            check++
          }
        })
      }

      if (count === check) {

        if (ageLabelList && ageLabelList.length > 0) {
          ageLabelList.forEach((agelabel) => {

            if (tempSortData && tempSortData.length > 0) {
              tempSortData.forEach((tempData) => {
                if (tempData.name === agelabel) {
                  newSortData.push(tempData);
                }

              })
            }

          })
        }

      }
      else {
        // newSortData = tempSortData.sort((a,b)=>(a && a.name?a.name.charCodeAt(0):"") - (b && b.name?b.name.charCodeAt(0):""));
        newSortData = tempSortData;

      }





    }
    else {
      // newSortData = tempSortData.sort((a,b)=>(a && a.name?a.name.charCodeAt(0):"") - (b && b.name?b.name.charCodeAt(0):""));
      newSortData = tempSortData;

    }

    if (newSortData && newSortData.length > 0) {
      newSortData.forEach((data) => {
        let dataScore = data[indexType].toFixed(2)
        nameList.push({
          value: data.name,
          textStyle: { fontWeight: 'bold' }
        });
        scoreList.push({
          value: dataScore,
          itemStyle: { color: '#8f34eb' }
        })

      })
    }




    return (
       
        <div className=" w-full " >
          {scoreList && scoreList.length > 0 ? 
              <ReactEcharts className='w-full'
                style={{
                  height: (nameList.length < 4) ? '200px' : (nameList.length < 5) ? '300px' : (nameList.length > 20) ? (nameList.length * 60).toString() + 'px' : (nameList.length * 50).toString() + 'px',
                  width: '100%'

                }}
                option={{
                  tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                      type: 'shadow'
                    },
                    formatter: function (a, b, c, d) {
                      return (a && a[0] && a[0].name) ? a[0].name + ' = ' + a[0].value + '%' : ''
                    }


                  },
                  legend: {
                    data: []
                  },
                 
                  xAxis: {
                    type: 'value',
                    splitLine: {
                      lineStyle: {
                        color: '#f2f2f2'
                      }
                    },
                    axisLine: {
                      lineStyle: {
                        color: '#e6e6e6'
                      }
                    },
                    max: 100
                  },
                  yAxis: {
                    type: 'category',
                    data: nameList.reverse(),
                    axisLine: {
                      lineStyle: {
                        color: '#e6e6e6'
                      }
                    }
                  },
                  axisLabel: {
                    color: "#000",
                    interval: 0,
                    formatter: function (value) {
                      if (value.length > 15) {
                        return value.substring(0, 15) + "..";
                      } else {
                        return value;
                      }
                    }
                  },
                  series: [
                    {
                      name: 'Score',
                      type: 'bar',
                      barWidth: '20px',
                      stack: '总量',
                      label: {
                        show: true,
                        position: 'insideRight'
                      },
                      data: scoreList.reverse()

                    },

                  ],
                  grid: {
                    left: '10px',
                    right: '10px',
                    bottom: '10px',
                    top: '10px',
                    containLabel: true
                  },
                }}
              /> 
            : null}
        </div>
 
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(StackChart);
