import React, { Component } from "react";
import { connect } from "react-redux";
import { accessModule } from "../../../_helpers";

import { dashboardActions } from "../../../_actions";
import Header from "../../SurveyMgmt/Header/Header";

import { userActions } from "../../../_actions";
import Sidebar from "../Sidebar/SidebarOverview";

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CrrRoute: "survey",
      CurrentLanguage: "en",
      CurrentLanguageName: "English",
      showSidebar: false,
      showMobileSidebar: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(dashboardActions.getCompanyNameOnly());

    this.props.dispatch(dashboardActions.getClientProfile());
  }
  sidebarToggle = () => {
    this.setState({ showSidebar: !this.state.showSidebar });
  };
  routeLink = (link) => {
    if (link === "happinessdashbaord") {
      this.props.history.push("/app/happinessdashbaord");
    } else {
      this.props.history.push("/app/" + link);
      this.setState({ CrrRoute: link });
    }
  };

  handleLanguage = (item) => {
    this.setState({
      CurrentLanguage: item.shortName,
      CurrentLanguageName: item.name,
      showLanguageModal: false,
    });
  };

  openLanguageModal = (check) => {
    this.setState({ showLanguageModal: !check });
    setTimeout(() => {
      this.setState({ showLanguageModal: false });
    }, 7000);
  };

  handleLogout = () => {
    console.log("logout");
    this.props.dispatch(userActions.logout());
  };

  onClickOverview = (card) => {
    let value = card.value;
    if (value === 0) {
      this.props.history.push(`/app/survey`);
    } else if (value === 1) {
      this.props.history.push(`/app/exitsurvey`);
    } else if (value === 2) {
      this.props.history.push(`/app/risksurvey`);
    } else if (value === 3) {
      this.props.history.push(`/app/happinessdashboard`);
    } else if (value === 4) {
      this.props.history.push(`/app/feedbacksurvey`);
    } else if (value === 6) {
      this.props.history.push(`/app/customersurvey`);
    } else if (value === 7) {
      this.props.history.push(`/app/assessment`);
    } else if (value === 72) {
      this.props.history.push(`/app/leadershipassessment`);
    } else if (value === 8) {
      this.props.history.push(`/app/performance`);
    }
  };

  handleSidebar = (check) => {
    this.setState({ showSidebar: check });
  };
  handleMobileSidebar = (check) => {
    this.setState({ showMobileSidebar: check });
    this.setState({ showSidebar: check });
  };

  render() {
    let { survey, dashboard } = this.props;
    let { getLanguageLabelData, getLanguageData } = survey;
    let { getCompanyNameOnlyData } = dashboard;
    let {
      showLanguageModal,
      CurrentLanguageName,
      CurrentLanguage,
      CrrRoute,
      showSidebar,
      showMobileSidebar,
    } = this.state;

    let moduleAccess = {
      isAdmin: false,
      access: {},
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }

    //ROLE MANAGEMENT ----------------------------------------------

    let SHOW_ELEMENT = false;
    let CLIENT_ID = "";
    let CLIENT_ROLE = "";

    let clientData = this.props.dashboard.ClientProfileData;

    if (clientData && clientData.id) {
      CLIENT_ID = clientData.id;
    }

    if (clientData && clientData.role) {
      CLIENT_ROLE = clientData.role;

      if (CLIENT_ROLE === "ADMIN") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      } else if (CLIENT_ROLE === "MNGR") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      } else if (CLIENT_ROLE === "CROLE") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      } else if (CLIENT_ROLE === "EMP") {
        SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }

    function accessAllow(item) {
      return moduleAccess.isAdmin
        ? true
        : moduleAccess && moduleAccess.access && moduleAccess.access[item]
          ? true
          : false;
    }

    //END -------------------------------------------------------
    let { ClientProfileData } = this.props.dashboard;
    if (
      ClientProfileData &&
      ClientProfileData.role &&
      ClientProfileData.role === "MNGR"
    ) {
      this.props.history.push(`/app/manageractionplan`);
    } else if (
      ClientProfileData &&
      ClientProfileData.role &&
      ClientProfileData.role === "EMP"
    ) {
      this.props.history.push(`/app/employeeactionplan`);
    }

    let LanguageObj = {};
    if (getLanguageLabelData && getLanguageLabelData[CurrentLanguage]) {
      LanguageObj = getLanguageLabelData[CurrentLanguage];
    }

    let CardItemList = [
      {
        id: 1,
        name: "Employee Experience",
        desc: "Establish an insightful end-to-end employee listening strategy for better understanding the people in your organization and improving the employee experience.",
        value: 0,
        color: "#FF4081",
        image: "/img/modules/employee_engagement.svg",
        shortName: "employeeExperience",
      },

      {
        id: 8,
        name: "Performance",
        desc: "Establish an insightful end-to-end assessment for better understanding the people in your organization.",
        value: 8,
        image: "/img/modules/assessment.svg",
        shortName: "performance",
      },
      {
        id: 4,
        name: "360 Feeback",
        desc: "The Plus 360 assessment platform for easier, faster and more affordable employee reviews and performance assessments.",
        value: 4,
        image: "/img/modules/360_feedback_survey.svg",
        shortName: "360Feedback",
      },
      {
        id: 6,
        name: "Customer Insight",
        desc: "Establish an insightful end-to-end customer listening strategy for better understanding the people in your organization and improving the customer experience.",
        value: 6,
        image: "/img/modules/customer_insight.svg",
        shortName: "customerExperience",
      },
      {
        id: 7,
        name: "Assessment",
        desc: "Establish an insightful end-to-end assessment for better understanding the people in your organization.",
        value: 7,
        image: "/img/modules/assessment.svg",
        shortName: "assessment",
      },
      {
        id: 72,
        name: "Leadership Assessment",
        desc: "Establish an insightful end-to-end assessment for better understanding the people in your organization.",
        value: 72,
        image: "/img/modules/happiness_insights.svg",
        shortName: "leadershipassessment",
      },
      {
        id: 2,
        name: "Sense Solution",
        desc: "SENSE Solution helps to sense different behaviors, individuals changing need and unearthed the dissatisfaction trigger point.",
        value: 2,
        image: "/img/modules/sense_solution.svg",
        shortName: "sense",
      },
      {
        id: 3,
        name: "Happiness Insights",
        desc: "Prioritize well-being within your organization culture through science, techniques and researched based solutions",
        value: 3,
        image: "/img/modules/happiness_insights.svg",
        shortName: "happinessInsight",
      },
    ];

    let List = [];
    if (CardItemList && CardItemList.length > 0) {
      CardItemList.forEach((item) => {
        if (accessAllow(item.shortName)) {
          List.push(item);
        }
      });
    }

    return (
      <>
        <div className="h-screen overflow-hidden bg-gray-50">
          <Header
            ClientProfileData={ClientProfileData}
            handleLogout={this.handleLogout}
            props={this.props}
            companyName={getCompanyNameOnlyData && getCompanyNameOnlyData.name ? getCompanyNameOnlyData.name : ""}
            sidebarToggle={this.sidebarToggle}
          />

          <div className="flex w-full" style={{ height: "calc(100% - 2rem)" }}>
            <Sidebar
              onOverview={this.onOverview}
              showSidebar={showSidebar}
              handleSidebar={this.handleSidebar}
              accessAllow={accessAllow}
              routeLink={this.routeLink}
              showMobileSidebar={this.state.showMobileSidebar}
              sidebarToggle={this.sidebarToggle}
            />

            <main className="flex-1 md:p-6 p-4 w-full bg-[#f9fafb]  overflow-hidden overflow-y-auto h-[calc(100vh-4rem)] ">


              <div className="mx-auto space-y-6">
                <div className="p-6 bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] shadow-md rounded-lg space-y-6 flex md:flex-row flex-col justify-between items-center lg:space-x-20">
                  <div className="w-full space-y-6 md:w-4/6">
                    <h1 className="text-2xl font-medium text-white lg:text-3xl ">
                      Build Continuous Employee Listening Strategy
                    </h1>
                    <div className="space-y-4 text-sm md:text-base text-white/80">
                      <p>
                        Tune in to hear employee’s voices and connect with
                        their emotions to increase employee engagement,
                        happiness, productivity and inclusivity. Enhance
                        employee experience with constructive response and
                        thoughtful actions.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-center w-full md:w-2/6 md:justify-end">
                    <img
                      src="/img/employee_listening_strategy.svg"
                      className="w-full lg:w-64"
                    />
                  </div>
                </div>

                <h1 className="block w-full py-4 text-2xl font-medium text-left md:text-4xl text-slate-900 md:text-center md:font-normal">
                  Experience The One-Stop Listening Platform
                </h1>
                <div className="grid grid-cols-1 gap-6 xl:grid-cols-3 lg:grid-cols-2 ">
                  {List && List.length > 0
                    ? List.map((card) => (
                      <div
                        key={card.id}
                        className="flex md:flex-row flex-col md:p-8 p-6 gap-6 overflow-hidden bg-white border rounded-md"
                      >
                        <div>
                          <img
                            alt="card"
                            src={card["image"]}
                            className="w-12 h-12"
                          />
                        </div>
                        <div className="flex flex-col items-start justify-between w-full h-full space-y-6">
                          <div className="space-y-4">
                            <h1 className="text-lg capitalize font-semibold line-clamp-1 text-[#3D405B]">
                              {card["name"]}
                            </h1>
                            <p className="text-gray-600 line-clamp-4 ">
                              {card["desc"]}
                            </p>
                          </div>
                          <button
                            onClick={() => this.onClickOverview(card)}
                            className=" text-[#2196f3] uppercase text-sm font-medium"
                          >
                            Let's begin
                          </button>
                        </div>
                      </div>
                    ))
                    : null}
                </div>
              </div>

            </main>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard } = state;
  return {
    loggingIn,
    survey,
    dashboard,
  };
}
export default connect(mapStateToProps)(Overview);
