import React, { Component } from 'react';
import { connect } from 'react-redux';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // showFilter:false,
      // showValue:'',
      currFilterList: [],
      finalFilter: [],
      searchName: ''

    }
  }
  componentDidMount() {

  }


  handleParameterQuestion = () => {

  }

  handleSelectFilter = (name) => {
    // this.setState({showFilter:false});

    this.props.closeFilter(false);

    let { currFilterList, finalFilter } = this.state;
    let { handleMultifilterIndexScore } = this.props;
    let getIndex = currFilterList.findIndex(prev => prev === name)
    if (getIndex === -1) {
      currFilterList.push(name);
      finalFilter.push({
        "level": name,
        "value": "All"
      });

      this.setState({ currFilterList, finalFilter }, () => {
        handleMultifilterIndexScore(finalFilter, name);
      });
    }
    else {
      currFilterList.splice(getIndex, 1);
      let getIndex2 = finalFilter.findIndex(prev => prev.level === name);
      finalFilter.splice(getIndex2, 1);

      this.setState({ currFilterList, finalFilter }, () => {
        if (finalFilter && finalFilter.length > 0) {
          handleMultifilterIndexScore(finalFilter, finalFilter[finalFilter.length - 1].level);
        }
        else {
          handleMultifilterIndexScore([]);
          // this.setState({showFilter:false})
          this.props.closeFilter(false);

        }
      });
    }

  }

  handleOpenFilter = (check) => {
    // this.setState({showValue:''});
    this.props.closeFilter(!check, '');

  }


  handleOpenValue = (name) => {
    if (this.props.showValue === name) {
      // this.setState({showValue:''});
      this.props.closeFilter(false, '');

    }
    else {
      // this.setState({showValue:name});
      this.props.closeFilter(false, name);

    }
  }


  handleSelectValue = (value, name) => {
    let { finalFilter } = this.state;

    let getIndex = finalFilter.findIndex(prev => prev.level === name);

    if (getIndex === -1) {

      finalFilter.push({
        "level": name,
        "value": value
      });

    }
    else {

      let getIndex2 = finalFilter.findIndex(prev => prev.value === value);
      if (getIndex2 === -1) {
        finalFilter[getIndex].value = value;
      }
      else if (value === "All") {
        finalFilter[getIndex].value = "All";
      }

    }
    let { handleMultifilterIndexScore } = this.props;
    this.props.closeFilter(false, '');
    this.setState({ finalFilter }, () => {
      handleMultifilterIndexScore(finalFilter, name);
    })
  }


  handleFilterClear = () => {
    let { handleMultifilterIndexScore } = this.props;
    this.setState({ finalFilter: [], currFilterList: [] }, () => {
      handleMultifilterIndexScore([]);
    })
  }

  handleSelectAllFilter = () => {

  }

  handleSearch = (e) => {
    this.setState({ searchName: e.target.value });
  }

  render() {

    const ref = React.createRef();
    let { EmpFilterData2, getIndexFilterData, showValue, showFilter, getEMPFilterLabel } = this.props;
    let { searchName, currFilterList, finalFilter } = this.state;

    let DemographicValueList = {};
    if (getIndexFilterData) {
      DemographicValueList = getIndexFilterData;
      // DemographicValueList.sort();
    }



    console.log('EmpFilterData2: ', EmpFilterData2)
    console.log('currFilterList: ', currFilterList)
    console.log('DemographicValueList: ', DemographicValueList)
    console.log('finalFilter: ', finalFilter)



    function selectedValue(item) {
      let Value = '';
      let getIndex = finalFilter.findIndex(prev => prev.level === item);
      if (getIndex !== -1) {
        if (finalFilter && finalFilter[getIndex] && finalFilter[getIndex].value) {
          Value = finalFilter[getIndex].value;
        }
      }
      return Value;
    }


    function SortList(list) {
      let temp = [];
      if (list && list.length > 0) {
        temp = list.sort()
      }
      return temp;
    }



    function getEmpValue(item) {
      return (selectedValue(item) ? selectedValue(item) === "All" ? item + " (" + selectedValue(item) + ")" : selectedValue(item) : item + ' (All)')
    }

    function getFilterCount() {
      return currFilterList && currFilterList.length > 0 ? currFilterList.length : 0
    }

    if (EmpFilterData2 && EmpFilterData2.length > 0) {
      EmpFilterData2.sort((a, b) => (a.name.charCodeAt(0) - b.name.charCodeAt(0)))
    }
    return (
      <>
        <div className="flex flex-wrap gap-2">
          {/*  selelect box */}
          <div className="cursor-pointer bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] flex justify-between px-4 items-center relative py-2 rounded-md w-40 ">
            <span onClick={() => this.handleOpenFilter(showFilter)} className="text-white material-icons ">filter_list</span>
            <span onClick={() => this.handleOpenFilter(showFilter)} className="pr-2 text-sm text-white" >
              Filters
            </span>
            <span onClick={() => this.handleOpenFilter(showFilter)} className=" bg-white  rounded-full text-[#2196F3] text-xs w-6 h-6 flex items-center justify-center font-bold">{getFilterCount()}</span>
            <span onClick={() => this.handleOpenFilter(showFilter)} className="text-white material-icons ">arrow_drop_down</span>

            {showFilter ?
              <div className="absolute left-0 z-10 w-48 p-4 font-normal bg-white rounded-lg shadow-lg top-12 font-xs" id="chatbot" style={{ overflowY: 'auto', height: '300px' }}>
                {EmpFilterData2 && EmpFilterData2.length > 0 ?
                  EmpFilterData2.map((dmg, index) =>
                    <div key={index} className="flex items-center py-2 m-1" onClick={() => this.handleSelectFilter(dmg.name)} >
                      <input type="checkbox" checked={currFilterList.some(prev => prev === dmg.name) ? true : false}
                        onChange={() => { }} /><label className="ml-2 text-sm cursor-pointer text-[#3D405B] ">{dmg.label}</label>
                    </div>
                  ) : null}
              </div>
              : null}


          </div>



          {currFilterList && currFilterList.length > 0 ?
            currFilterList.map((item) =>

              <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">

                <span onClick={() => this.handleOpenValue(item)} class=" text-sm text-[#3D405B]/70 font-medium ">{getEmpValue(item)}</span>
                <span onClick={() => this.handleOpenValue(item)} className="material-icons ">arrow_drop_down</span>


                {showValue === item ?
                  <>
                    <div className="absolute left-0 z-10 w-56 p-4 overflow-y-auto font-normal bg-white rounded-lg shadow-lg top-12 font-xs h-60 scollar-xs-blue" id="chatbot" style={{ overflowY: 'auto', height: '400px' }}>
                      <input type="text" name="searchName" value={searchName} onChange={this.handleSearch} placeholder="Search" className="text-xs w-full border p-1.5 rounded-sm focus:outline-none text-gray-500 mb-2 focus:ring-blue-500 focus:ring-1" />
                      {/* <div className="flex items-center py-2 m-1"><input type="checkbox"  checked={finalFilter.some(prev=>(prev.level === item)&&(prev.value === "All"))?true:false} onClick={()=>this.handleSelectValue('All',item)}  /><label className="ml-2">Select all</label> </div> */}
                      <div className="flex items-center py-2 m-1"><input type="checkbox" onClick={() => this.handleSelectValue("All", item)} onChange={() => { }} />
                        <label onClick={() => this.handleSelectValue("All", item)} className="ml-2 text-xs">{"All"}</label>
                      </div>
                      {SortList(DemographicValueList[item]) && DemographicValueList && DemographicValueList[item] && DemographicValueList[item].length > 0 ?
                        SortList(DemographicValueList[item]).map((value, index) =>
                          value && value.match(new RegExp(`${searchName}`, 'gi')) ?
                            <div className="flex items-center py-2 m-1" onClick={() => this.handleSelectValue(value, item)}><input type="checkbox" checked={finalFilter.some(prev => prev.value === value) ? true : false}
                              onChange={() => { }} /><label className="ml-2 text-xs">{value}</label> </div>
                            : null)
                        : null}
                    </div>
                  </>
                  : null}



              </div>


            ) : null}

        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Filter);
