import React, { Component } from 'react';
import { connect } from 'react-redux';

import QuadrantData from './Components/QuadrantData/QuadrantData';
import BestFitData from './Components/BestFitData/BestFitData';
import Corelation from './Components/Corelation/Corelation';
import CrossTab from './Components/CrossTab/CrossTab';
import ClusterAnalysis from './Components/ClusterAnalysis/ClusterAnalysis';
import CrossTabNPSvsLadder from './Components/CrossTabNPSvsLadder/CrossTabNPSvsLadder';


class AdvanceComparision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionType: 1,
      DimensionList: [
        {
          index: 1,
          name: 'Happiness',
          cutOff: 0
        },
        {
          index: 2,
          name: 'Engagement',
          cutOff: 0

        },
        {
          index: 4,
          name: 'Stress',
          cutOff: 0
        },
        {
          index: 5,
          name: 'Manager',
          cutOff: 0

        },
        {
          index: 6,
          name: 'Leader',
          cutOff: 0

        }
      ],
    }
  }
  componentDidMount() {

  }

  onOptionType = (val, validateList) => {
    let { EmpFilterData, handleMultiFilterAdvancScatter, IndexDimension, handleCorelation, onCrossTab, handleQuadrantFilter, onClusterAnalysis } = this.props;


    this.setState({ optionType: val })
    ////console.log('handleCorelation: ',val)
    if (val === 1) {
      handleQuadrantFilter(EmpFilterData && EmpFilterData ? EmpFilterData[0].name : "");

    }
    else if (val === 2) {
      handleMultiFilterAdvancScatter([], true, EmpFilterData && EmpFilterData ? EmpFilterData[0].name : "");

    }
    else if (val === 3) {

      let validParameterList = []
      if (validateList && validateList.length > 0) {
        validateList.forEach((indexObj) => {
          if (IndexDimension && IndexDimension[indexObj.name] && IndexDimension[indexObj.name].length > 0) {
            IndexDimension[indexObj.name].forEach((elem) => {
              validParameterList.push(elem);
            });
          }
        });
      }

      handleCorelation(validParameterList);

    }
    else if (val === 4 || val === 6) {
      onCrossTab()
    }
    else if (val === 5) {
      onClusterAnalysis()
    }
  }

  onSwitchOptionScreen = (validateList) => {
    let {
      clusterAnalysisData, clusterAnalysisCount,
      indexType, EmpFilterData, getIndexFilterData, getCommentData, loading, questionsList,

      getAllBenchMarkData, getIndexScore, handleSummaryFilter, handleQuadrantFilter, handleSummaryCard, getDemographicIndexData, getIndexData, getIndexDistributionData,
      handleCorrelationFilter,


      handleCorelation,

      getDemographicIndexScatterData,


      getHappinessCorrelationData,
      getEngagementCorrelationData,
      getStressCorrelationData,
      getManagerCorrelationData,
      getLeaderCorrelationData,

      handleMultiFilterAdvanc4Quadrant,
      handleMultiFilterAdvancScatter,

      crossTabAnalysisData,



      handleMultiFilterCorrelation,
      handleCrossTabApi,
      crossTabData,

      IndexDimension,

      GetParameterLabel,

      getCorrelationData,
      getCorrelationFilterData,
      getSurveyData,
      CountRestrict,
      getIndexByFilterDMGData,
      getClusterEmpData,
      getClusterEmpInfoListData,
      getEMPFilterLabel,

      onClusterAnalysis,

      clusterAnalysisMSG,
      getCorrelationDimenionVsDimensionError,
      crossTabError,


      ErrorOtherObj,
      validateIndex

    } = this.props;

    let { optionType } = this.state;

    switch (optionType) {

      case 1: return <QuadrantData
        ErrorOtherObj={ErrorOtherObj}
        getIndexByFilterDMGData={getIndexByFilterDMGData}
        CountRestrict={CountRestrict} validateList={validateList} loading={loading} handleMultiFilterAdvanc4Quadrant={handleMultiFilterAdvanc4Quadrant}

        getEMPFilterLabel={getEMPFilterLabel}

        optionType={optionType} getCommentData={getCommentData}
        getDemographicIndexData={getDemographicIndexData} handleQuadrantFilter={handleQuadrantFilter}
        indexType={indexType} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getAllBenchMarkData={getAllBenchMarkData} getIndexScore={getIndexScore} getIndexDistributionData={getIndexDistributionData} handleSummaryFilter={handleSummaryFilter} handleSummaryCard={handleSummaryCard} getIndexData={getIndexData} />


      case 2: return <BestFitData getIndexByFilterDMGData={getIndexByFilterDMGData}
        CountRestrict={CountRestrict} validateList={validateList} loading={loading} handleMultiFilterAdvancScatter={handleMultiFilterAdvancScatter}
        ErrorOtherObj={ErrorOtherObj}


        optionType={optionType} getCommentData={getCommentData}

        getDemographicIndexScatterData={getDemographicIndexScatterData}

        getEMPFilterLabel={getEMPFilterLabel}


        getDemographicIndexData={getDemographicIndexData} handleQuadrantFilter={handleQuadrantFilter}

        indexType={indexType} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getAllBenchMarkData={getAllBenchMarkData} getIndexScore={getIndexScore} getIndexDistributionData={getIndexDistributionData} handleSummaryFilter={handleSummaryFilter} handleSummaryCard={handleSummaryCard} getIndexData={getIndexData} />

      case 3: return <Corelation
        getCorrelationDimenionVsDimensionError={getCorrelationDimenionVsDimensionError}
        getIndexByFilterDMGData={getIndexByFilterDMGData}
        CountRestrict={CountRestrict} GetParameterLabel={GetParameterLabel} IndexDimension={IndexDimension} validateList={validateList} loading={loading} handleMultiFilterCorrelation={handleMultiFilterCorrelation}
        optionType={optionType} getCommentData={getCommentData}
        getSurveyData={getSurveyData}

        getHappinessCorrelationData={getHappinessCorrelationData}
        getEngagementCorrelationData={getEngagementCorrelationData}
        getStressCorrelationData={getStressCorrelationData}
        getManagerCorrelationData={getManagerCorrelationData}
        getLeaderCorrelationData={getLeaderCorrelationData}

        handleCorrelationFilter={handleCorrelationFilter}


        getDemographicIndexData={getDemographicIndexData} handleQuadrantFilter={handleQuadrantFilter}


        handleCorelation={handleCorelation}


        getCorrelationData={getCorrelationData}
        getCorrelationFilterData={getCorrelationFilterData}

        getEMPFilterLabel={getEMPFilterLabel}


        indexType={indexType} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getAllBenchMarkData={getAllBenchMarkData} getIndexScore={getIndexScore} getIndexDistributionData={getIndexDistributionData} handleSummaryFilter={handleSummaryFilter} handleSummaryCard={handleSummaryCard} getIndexData={getIndexData} />

      case 4: return <CrossTab getIndexByFilterDMGData={getIndexByFilterDMGData}
        indexType={indexType}
        optionType={optionType}
        getCommentData={getCommentData}
        questionsList={questionsList}
        handleCrossTabApi={handleCrossTabApi}
        crossTabData={crossTabData}
        EmpFilterData={EmpFilterData}
        getIndexFilterData={getIndexFilterData}
        crossTabAnalysisData={crossTabAnalysisData}

        getEMPFilterLabel={getEMPFilterLabel}
        crossTabError={crossTabError}
      />

      case 5: return <ClusterAnalysis getIndexByFilterDMGData={getIndexByFilterDMGData}
        CountRestrict={CountRestrict} validateList={validateList} loading={loading} handleMultiFilterAdvanc4Quadrant={handleMultiFilterAdvanc4Quadrant}

        optionType={optionType} getCommentData={getCommentData}
        getDemographicIndexData={getDemographicIndexData} handleQuadrantFilter={handleQuadrantFilter}
        indexType={indexType} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getAllBenchMarkData={getAllBenchMarkData} getIndexScore={getIndexScore} getIndexDistributionData={getIndexDistributionData} handleSummaryFilter={handleSummaryFilter} handleSummaryCard={handleSummaryCard} getIndexData={getIndexData}




        clusterAnalysisData={clusterAnalysisData}
        clusterAnalysisCount={clusterAnalysisCount}
        getClusterEmpData={getClusterEmpData}
        getClusterEmpInfoListData={getClusterEmpInfoListData}

        getEMPFilterLabel={getEMPFilterLabel}

        onClusterAnalysis={onClusterAnalysis}

        clusterAnalysisMSG={clusterAnalysisMSG}

      />

      case 6: return <CrossTabNPSvsLadder getIndexByFilterDMGData={getIndexByFilterDMGData}
        indexType={indexType}
        optionType={optionType}
        getCommentData={getCommentData}
        questionsList={questionsList}
        handleCrossTabApi={handleCrossTabApi}
        crossTabData={crossTabData}
        EmpFilterData={EmpFilterData}
        getIndexFilterData={getIndexFilterData}
        crossTabAnalysisData={crossTabAnalysisData}

        getEMPFilterLabel={getEMPFilterLabel}
        crossTabError={crossTabError}
      />


      default: return <div>Default Option</div>
    }
  }

  render() {
    let { CurrentSurveyId, indexType, getDemographicIndexData } = this.props;
    let { optionType } = this.state;




    let { DimensionList } = this.state;

    let { validateIndex } = this.props;

    let validateList = [];
    let validObj = Object.keys(validateIndex).map((key) => [key, validateIndex[key]])
    ////console.log('validObj: ',validObj);
    if (validObj && validObj.length > 0) {
      validObj.forEach((item) => {
        if (item[1]) {
          let getIndex = DimensionList.findIndex(prev => prev.index === parseInt(item[0]));
          if (getIndex !== -1 ? DimensionList[getIndex].name : "") {
            validateList.push({
              "name": getIndex !== -1 ? DimensionList[getIndex].name : "",
              "template": parseInt(item[0])
            });
          }

        }
      })
    }
    console.log('validateIndex: ',validateIndex);

    console.log('validObj: ',validObj);





    return (
      <>
        {/* <main className="flex-1 w-full overflow-hidden overflow-y-auto h-[calc(100vh-4rem)] bg-[#f9fafb]">
        
        <nav className="flex px-6 overflow-x-auto bg-white scroll justify-left overscroll-x-contain">
          <div className="flex cursor-pointer justify-left">
            <a onClick={()=>this.onOptionType(1)} className={(optionType===1)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8"} href>
            4 Quadrant Matrix
            </a>
             <a onClick={()=>this.onOptionType(2)} className={(optionType===2)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8"} href>
            Scatter Plot 
            </a>
            <a onClick={()=>this.onOptionType(3,validateList)} className={(optionType===3)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8"} href>
            Correlation
            </a>
            <a onClick={()=>this.onOptionType(4)} className={(optionType===4)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8"} href>
            CrossTab
            </a>
            <a onClick={()=>this.onOptionType(5)} className={(optionType===5)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8"} href>
            Cluster Analysis
            </a>
          </div>
        </nav>

          {this.onSwitchOptionScreen(validateList)}
      </main>  */}


      <div className="md:p-6 p-4 space-y-4 w-full flex-1 overflow-y-auto bg-[#f9fafb]">
          <div className="flex justify-between text-gray-800 items-center">
          <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B] pb-2   ">{"Advance Insight"} </h1>  
          
          {/* <span className="material-symbols-outlined cursor-pointer lg:hidden flex">menu_open</span> */}
          </div>
          {/*sub nav bar 1 */}
          <div className="items-center justify-between border-b md:flex">
            <div className="flex space-x-2 overflow-hidden overflow-x-auto text-xs font-medium text-gray-600 whitespace-nowrap tab-scroll">
              <span onClick={() => this.onOptionType(1)} className={optionType === 1 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}> 4 Quadrant Matrix </span>
              <span onClick={() => this.onOptionType(2)} className={optionType === 2 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}> Scatter Plot </span>
              <span onClick={() => this.onOptionType(3, validateList)} className={optionType === 3 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}> Correlation </span>
              <span onClick={() => this.onOptionType(4)} className={optionType === 4 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}> CrossTab </span>
              <span onClick={() => this.onOptionType(6)} className={optionType === 6 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}> NPS VS Happiness Ladder </span>
              {/* <span onClick={()=>this.onOptionType(5)} className={optionType===5?"cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}> Cluster Analysis </span> */}
            </div>
          </div>

          {this.onSwitchOptionScreen(validateList)}

        </div>


      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(AdvanceComparision);
