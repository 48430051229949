import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Filter from '../../CommonComponents/Filters/IndexFilter/Filter'
import GaugeScore from '../Overview/Components/QuestionTypeCards/Charts/GaugeScore'
// import ReactEcharts from "echarts-for-react";

class ICEIndexDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currFilterName: 'none',
      showLeaderBoard: false,
      isMultifilter: false,
      optionType: 1,
      showOverview: true,
      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: '',
      ListName: 'Demographic',
      showEDAModal: false,
      crrDMGValue: ""
    }
  }

  componentDidMount() {

  }

  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleDashICEFilter, indexNumber } = this.props;
    this.setState({ FilterValues: finalFilter, ListName: name });
    handleDashICEFilter(finalFilter, true, name, this.state.optionType, indexNumber);
  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  onOptionType = (value) => {
    let { handleDashICEFilter, indexNumber } = this.props;
    let { FilterValues, ListName } = this.state;
    this.setState({ optionType: value }, () => {
      handleDashICEFilter(FilterValues, true, ListName, value, indexNumber);
    });
  };


  render() {

    let { optionType, FilterValues, ListName } = this.state;
    let { GetParameterLabel, indexNumber, getIceCrossDistData, getIceIndexDMGWiseData, getEMPFilterLabel, getIndexFilterData, getIceDMGDimensionScoreData, EmpFilterData, getIceIndexData, getIceDmgQuestionWiseDistData } = this.props;

    // console.log('getIceDMGDimensionScoreData-------------------------11111111111111', getIceDMGDimensionScoreData);

    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let overviewObj = {
      "iceIndexScore": getIceIndexData && getIceIndexData[indexNumber] ? getIceIndexData[indexNumber].toFixed(2) : "",
      "iceIndexScore_SELF": getIceIndexData && getIceIndexData[indexNumber + "_SELF"] ? getIceIndexData[indexNumber + "_SELF"].toFixed(2) : "",
      "iceIndexScore_CROSS": getIceIndexData && getIceIndexData[indexNumber + "_CROSS"] ? getIceIndexData[indexNumber + "_CROSS"].toFixed(2) : ""
    }

    let OptionList = [
      {
        "name": "Summary",
        "template": 1
      },
      {
        "name": "Themes Summary",
        "template": 2
      },
      {
        "name": "Detailed Distribution",
        "template": 3
      },
    ]

    function spliceDecimal(no) {
      if (no) {
        let str = no.toString();
        let first = str.split('.')[0];
        let second = str.split('.')[1];
        let second2 = second ? second.substring(0, 2) : ""
        let final = first + '.' + second2;
        if (second2) {
          return parseFloat(final).toFixed(2)
        } else {
          return no
        }
      } else {
        return 0
      }
    }

    const getScore = (ele, field) => {
      return ele && ele[indexNumber.toString() + field] ? spliceDecimal(ele[indexNumber.toString() + field]) : "0"
    }


    const cleanedParameters = [
      "Feedback and  Developmen",
      "EmpowerICE",
      "Listen and Nurtures",
      "CommunicationICE",
      "Role Model",
      "Information"
    ];



    const calcFn = (list, name, check) => {
      if (list && list.length > 0) {
        console.log("list----->", list)

        let newList = []
        list.forEach((innerList) => {
          if (innerList && innerList.answers && innerList.answers.length > 0) {
            innerList.answers.forEach((eleE) => {
              newList.push(eleE)
            })
          }
        })
        console.log("newList----->", newList)

        const totalSum = newList.reduce((sum, inner) => sum + (inner.count || 0), 0);
        const scoreSum = newList.reduce((sum, inner) =>
          sum + (inner.answerText === name ? (inner.count || 0) : 0), 0);

        const perc = totalSum ? ((scoreSum * 100) / totalSum).toFixed(0) : "0";
        console.log("perc----->", perc)
        console.log("totalSum----->", totalSum)
        console.log("scoreSum----->", scoreSum)

        return parseFloat(perc) < 5 && !check ? "" : perc;
      }
      return "0";
    }

    const getDistScore = (data, type, name, check) => {
      if (type === "OVERALL") {
        if (data && data.feedbacks && data.feedbacks.length > 0) {
          let list = data.feedbacks;
          return calcFn(list, name, check);
        }
      }
      else if (type === "SELF") {
        if (data && data.feedbacks && data.feedbacks.length > 0) {
          let list = data.feedbacks.filter(item => item.feedback.includes('Self'));
          return calcFn(list, name, check);
        }
      } else if (type === "CROSS") {
        if (data && data.feedbacks && data.feedbacks.length > 0) {
          let list = data.feedbacks.filter(item => !item.feedback.includes('Self'));
          return calcFn(list, name, check);
        }
      }

      return "0";
    }



    function calcParaFavFn(list) {
      let total = 0;
      let relevantCount = 0;

      for (const item of list) {
        for (const answer of item.answers) {
          total += answer.count;
          if (["Strongly Agree", "Agree", "Extremely Satisfied", "Very Satisfied"].includes(answer.answerText)) {
            relevantCount += answer.count;
          }
        }
      }

      return total > 0 ? ((relevantCount * 100) / total).toFixed(0) : "0";
    }

    const getFavScore = (data, type) => {
      if (type === "OVERALL") {
        if (data && data.feedbacks && data.feedbacks.length > 0) {
          let list = data.feedbacks;
          return calcParaFavFn(list);
        }
      }
      else if (type === "SELF") {
        if (data && data.feedbacks && data.feedbacks.length > 0) {
          let list = data.feedbacks.filter(item => item.feedback.includes('Self'));
          return calcParaFavFn(list);
        }
      } else if (type === "CROSS") {
        if (data && data.feedbacks && data.feedbacks.length > 0) {
          let list = data.feedbacks.filter(item => !item.feedback.includes('Self'));
          return calcParaFavFn(list);
        }
      }

      return "0";
    }

    const getDMGScore = (ele, para, type) => {
      let getIndex = ele.parameterDimensionNames.findIndex(prev => prev.parameterDimensionName === para);
      if (getIndex !== -1) {
        let feedback_list = ele.parameterDimensionNames[getIndex]["feedbacks"];
        if (type === "OVERALL") {
          if (feedback_list && feedback_list.length > 0) {
            let list = feedback_list;
            return calcParaFavFn(list);
          }
        }
        else if (type === "SELF") {
          if (feedback_list && feedback_list.length > 0) {
            let list = feedback_list.filter(item => item.feedback.includes('Self'));
            return calcParaFavFn(list);
          }
        } else if (type === "CROSS") {
          if (feedback_list && feedback_list.length > 0) {
            let list = feedback_list.filter(item => !item.feedback.includes('Self'));
            return calcParaFavFn(list);
          }
        }
      }
      return "0"
    }

    const distList = [
      {
        name: 'Strongly Disagree',
        color: '#EF5350'
      },
      {
        name: 'Disagree',
        color: '#EF5350BF'
      },
      {
        name: 'Slightly Disagree',
        color: '#EF535080'
      },
      {
        name: 'Slightly Agree',
        color: '#66BB6A80'
      },
      {
        name: 'Agree',
        color: '#66BB6ABF'
      },
      {
        name: 'Strongly Agree',
        color: '#66BB6A'
      },
    ]

    return (
      <>
        <main className="flex-1 w-full p-4 overflow-hidden overflow-y-auto bg-[#f9fafb] md:p-6 space-y-6 ">

          {/* <div className=''>
            <Filter
              showFilter={this.state.showFilter}
              showValue={this.state.showValue}
              closeFilter={this.closeFilter}
              EmpFilterData2={EmpFilterData2}
              getIndexFilterData={getIndexFilterData}
              handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
              getEMPFilterLabel={getEMPFilterLabel}
            />
          </div> */}

          <div className="items-center justify-between border-b md:flex">
            <div className="flex space-x-2 overflow-hidden overflow-x-auto text-sm font-medium text-gray-600 whitespace-nowrap tab-scroll">
              {OptionList && OptionList.length > 0 ?
                OptionList.map((item) =>
                  <span onClick={() => this.onOptionType(item.template)}
                    className={optionType === item.template ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}>
                    {item && item.name ? item.name : ""}
                  </span>
                ) : null}
            </div>
          </div>

          {optionType === 1 ?
            <>
              {!(FilterValues && FilterValues.length > 0) ?

                <div className="md:p-4 p-4 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                  <div className="flex flex-wrap gap-2 items-center justify-between">
                    <h1 className="font-medium xl:text-xl text-lg text-[#212121] whitespace-nowrap">Manager Index</h1>
                  </div>
                  <div className='flex items-center justify-center'>
                    <div>
                      <div className='2xl:!w-[450px] 2xl:!h-[270px] md:!w-[400px] md:!h-[250px] !w-[300px] !h-[200px] -mt-4'>
                        <GaugeScore score={overviewObj["iceIndexScore"]} />
                      </div>
                      <div className="gauge-outer">
                        <div className="gauge-inner" style={{ transform: 'rotate(calc(1deg * (-45 + ' + 0 + ' * 29.95)))' }}></div>
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div className="md:p-4 p-4 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                  <h1 className="font-medium xl:text-xl text-lg text-[#212121] whitespace-nowrap">Manager Index Score</h1>

                  <div className="w-full mb-4 overflow-hidden overflow-x-auto customscroll2 ">
                    <table className="w-full border whitespace-nowrap ">
                      <thead>
                        <tr className="bg-[#F9FAFB] border-b text-sm  text-[#212121]">
                          <th className="py-3 px-2 border-r font-semibold  md:w-[30%] w-32 text-left">{ListName}</th>
                          <th className="py-3 px-2 border-r font-semibold md:w-[70%] w-60">Overall Score</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getIceIndexDMGWiseData && getIceIndexDMGWiseData.length > 0 &&
                          getIceIndexDMGWiseData.sort((a, b) => (a.name > b.name ? 1 : -1)).map((ele) =>
                            <tr className="bg-white mb-10 lg:mb-0 text-sm text-[#212121]">
                              <td className="p-3 border border-b text-left">{ele && ele.name}</td>
                              <td className="p-3 border border-b text-center">
                                <div className='w-full space-y-0.5'>
                                  <div className="h-8 bg-[#F1F5F9] w-full">
                                    <div className="h-8 bg-[#5C6BC0] w-[70%] flex justify-end items-center " style={{ width: getScore(ele, "") + "%" }}>
                                      <span className="text-white text-sm px-2 font-medium">{getScore(ele, "") + "%"}</span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>

                  {/* <div className="flex md:flex-row flex-col gap-4 justify-center items-center   text-[#3D405B] text-sm font-medium  ">
                    <p className="">Pages</p>
                    <div className="flex border divide-x rounded-md ">
                      <button className="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                        Previous
                      </button>
                      <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] ">
                        1
                      </button>
                      <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                        2
                      </button>
                      <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                        3
                      </button>
                      <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                        4
                      </button>
                      <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                        5
                      </button>
                      <button className="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                        Next
                      </button>
                    </div>
                    <p>1 of 26</p>
                  </div> */}

                </div>
              }
            </>
            : null}


          {optionType === 2 ?
            <>
              {!(FilterValues && FilterValues.length > 0) ?
                <div className="md:p-4 p-4 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">

                  <div className='flex items-center justify-between'>
                    <h1 className="font-medium xl:text-xl text-lg text-[#212121] whitespace-nowrap">Manager Themes Score</h1>
                    <div className='flex items-center space-x-6 text-sm'>

                    </div>
                  </div>

                  <div className='w-full space-y-4'>
                    {getIceCrossDistData && getIceCrossDistData.length > 0 && getIceCrossDistData.sort((a, b) => (a.parameterDimensionName > b.parameterDimensionName ? 1 : -1)).map((ele) =>
                      <div className="flex lg:flex-row flex-col lg:items-center lg:gap-6 gap-3 ">
                        <p className="lg:w-80 w-full lg:text-right text-left text-[#212121]/70 whitespace-nowrap md:text-base text-sm">{ele && ele.parameterDimensionName?GetParameterLabel(ele.parameterDimensionName):""}</p>
                        <div className='w-full space-y-0.5'>
                          <div className="h-8 bg-[#F1F5F9] w-full">
                            <div className="h-8 bg-[#5C6BC0] w-[70%] flex justify-end items-center " style={{ width: getFavScore(ele, "OVERALL") + "%" }}>
                              <span className="text-white text-sm px-2 font-medium">{getFavScore(ele, "OVERALL") + "%"}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                  </div>
                </div>
                :
                <div className="md:p-4 p-4 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                  <h1 className="font-medium xl:text-xl text-lg text-[#212121] whitespace-nowrap">Manager Themes Score</h1>

                  <div className="w-full mb-4 overflow-hidden overflow-x-auto customscroll2 ">
                    <table className="w-full border whitespace-nowrap ">
                      <thead>
                        <tr className="bg-[#F9FAFB] border-b text-sm  text-[#212121]">
                          <th colSpan={1} className="py-3 px-2 border-r font-semibold  md:w-[20%] w-32 text-left text-sm">Name</th>

                          {cleanedParameters && cleanedParameters.length > 0 &&
                            cleanedParameters.map((ele) =>
                              <th className="py-3 px-2 border-r font-semibold md:w-[20%] w-60 text-sm">{GetParameterLabel(ele)}</th>
                            )}

                          {/* <th className="py-3 px-2 border-r font-semibold md:w-[20%] w-60">Overall Satisfaction</th>
                          <th className="py-3 px-4 border-r font-semibold md:w-[20%] w-40">Pride</th>
                          <th className="py-3 px-4 border-r font-semibold md:w-[20%] w-40">Trust</th>
                          <th className="py-3 px-4 border-r font-semibold md:w-[20%] w-40">Confidence</th>
                          <th className="py-3 px-4 border-r font-semibold md:w-[20%] w-40">Delivery on Promise</th>
                          <th className="py-3 px-4 border-r font-semibold md:w-[20%] w-40">Promote / Passion</th> */}
                        </tr>
                      </thead>
                      {getIceDMGDimensionScoreData && getIceDMGDimensionScoreData.length > 0 &&
                        getIceDMGDimensionScoreData.sort((a, b) => (a.xAxis > b.xAxis ? 1 : -1)).map((ele) =>
                          <tbody>
                            <tr className="bg-white text-sm text-[#3D405B] ">
                              <td rowSpan={3} className="p-3 border border-b  w-60 bg-[#F9FAFB]">{ele && ele.xAxis}</td>
                              {cleanedParameters && cleanedParameters.length > 0 &&
                                cleanedParameters.map((para) =>
                                  <td className="p-3 border border-b text-center">{getDMGScore(ele, para, "OVERALL")}</td>
                                )}
                            </tr>
                          </tbody>
                        )}
                    </table>
                  </div>



                  {/* <div className="flex md:flex-row flex-col gap-4 justify-center items-center   text-[#3D405B] text-sm font-medium  ">
                    <p className="">Pages</p>
                    <div className="flex border divide-x rounded-md ">
                      <button className="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                        Previous
                      </button>
                      <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] ">
                        1
                      </button>
                      <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                        2
                      </button>
                      <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                        3
                      </button>
                      <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                        4
                      </button>
                      <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                        5
                      </button>
                      <button className="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                        Next
                      </button>
                    </div>
                    <p>1 of 26</p>
                  </div> */}


                </div>
              }
            </>
            : null}

          {optionType === 3 ? (
            <div className="md:p-4 p-4 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
              <h1 className="font-medium xl:text-xl text-lg text-[#212121]">
                Manager Themes Details Distribution
              </h1>

              <div className="w-full mb-4 overflow-hidden overflow-x-auto customscroll2">
                <table className="w-full border">
                  <thead>
                    <tr className="bg-[#F9FAFB] border-b text-sm text-[#212121]">
                      <th className="py-3 px-2 border-r font-semibold md:w-[20%]   text-left">
                        Dimension
                      </th>
                      <th className="py-3 px-2 border-r font-semibold md:w-[25%] text-left">
                        Question
                      </th>
                      <th className="py-3 px-4 border-r font-semibold md:w-[55%] ">
                        Distribution (%)
                      </th>
                    </tr>
                  </thead>
                  {getIceDmgQuestionWiseDistData && getIceDmgQuestionWiseDistData.length > 0 &&
                    getIceDmgQuestionWiseDistData.sort((a, b) => (a.parameterDimensionName > b.parameterDimensionName ? 1 : -1)).map((element, index) =>
                      <tbody key={index}>
                        <tr className="bg-white text-[#3D405B] font-medium text-sm">
                          <td className="p-3 border border-b">{element && element.parameterDimensionName ? GetParameterLabel(element.parameterDimensionName) : ""}</td>
                          <td className="p-3 border border-b ">
                            <div className='w-80'>
                              {element && element.question ? element.question : ""}
                            </div>
                          </td>
                          <td className="p-3 border border-b text-center">
                            <div className="md:w-full w-96 flex flex-col gap-4 text-sm relative group">
                              <div className="md:w-full w-96 flex flex-col gap-4 text-sm relative group">
                                <div className='flex items-center cursor-pointer'>
                                  <div className="bg-[#EF5350] h-8 flex items-center justify-center font-medium" style={{ width: getDistScore(element, 'CROSS', 'Strongly Disagree', true) + "%" }}>{getDistScore(element, 'CROSS', 'Strongly Disagree')}</div>
                                  <div className="bg-[#EF5350BF] h-8 flex items-center justify-center font-medium" style={{ width: getDistScore(element, 'CROSS', 'Disagree', true) + "%" }}>{getDistScore(element, 'CROSS', 'Disagree')}</div>
                                  <div className="bg-[#EF535080] h-8 flex items-center justify-center font-medium" style={{ width: getDistScore(element, 'CROSS', 'Slightly Disagree', true) + "%" }}>{getDistScore(element, 'CROSS', 'Slightly Disagree')}</div>
                                  <div className="bg-[#66BB6A80] h-8 flex items-center justify-center font-medium" style={{ width: getDistScore(element, 'CROSS', 'Slightly Agree', true) + "%" }}>{getDistScore(element, 'CROSS', 'Slightly Agree')}</div>
                                  <div className="bg-[#66BB6ABF] h-8 flex items-center justify-center font-medium" style={{ width: getDistScore(element, 'CROSS', 'Agree', true) + "%" }}>{getDistScore(element, 'CROSS', 'Agree')}</div>
                                  <div className="bg-[#66BB6A] h-8 flex items-center justify-center font-medium" style={{ width: getDistScore(element, 'CROSS', 'Strongly Agree', true) + "%" }}>{getDistScore(element, 'CROSS', 'Strongly Agree')}</div>
                                </div>

                                <div className='bg-white rounded-lg p-4 border shadow-xl w-fit whitespace-nowrap absolute -top-16 group-hover:block hidden z-40'>
                                  {distList && distList.length > 0 &&
                                    distList.map((ele, index) =>
                                      <div className='flex items-center space-x-6 space-y-1'>
                                        <div className='flex items-center space-x-2 w-32'>
                                          <div className='w-3 h-3 bg-gray-300 rounded-full' style={{ background: ele.color }} />
                                          <span className='text-xs'>{ele.name}</span>
                                        </div>
                                        <span className='text-xs'>{getDistScore(element, 'CROSS', ele.name) ? getDistScore(element, 'CROSS', ele.name) + "%" : "0%"}</span>
                                      </div>
                                    )}
                                </div>

                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    )}
                </table>
              </div>
            </div>
          ) : null}


        </main>
      </>
    );

  }
}




function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(ICEIndexDetails);