import React, { Component } from "react";
import { connect } from "react-redux";
import DemographicFilter from "./Filters/DemographicFilter";
import QuestionFilter from "./Filters/QuestionFilter";
import TreeMapChart from "./Content/TreeMapChart";
import WordDetails from "./Content/WordDetails";
import Comments from "./Content/Comments";
import Commentary from "../../../Index/Components/Commentary/Commentary";
import WordsBarChart from "./Content/Charts/WordsBarChart";
import IndexComparison from "./Content/IndexComparison";

import VerticalBarChart from "./Content/Charts/VerticalBarChart";
import Filter from "../../../../CommonComponents/Filters/IndexFilter/Filter";

class TextAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuesId: "",
      DemographicFilterName: "",
      DemographicFilterValue: "",
      FilterList: [],
      showFilter: false,
      showValue: "",

      showViewBy: false,

      keyWordList: [],
    };
  }

  componentDidMount() {}

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };

  closeFilterQues = (check1) => {
    this.setState({ showViewBy: check1 });
  };

  handleChangeQuesIdSave = (Id) => {
    this.setState({ currentQuesId: Id });
  };

  FilterSave = (name, value) => {
    this.setState({
      DemographicFilterName: name,
      DemographicFilterValue: value,
    });
  };

  handleKeywordCall = (keyWord, isMultiKeyword, FilterList) => {
    let { currentQuesId } = this.state;
    let { getTextQuestionData, callWordCloudText } = this.props;

    let initQuesId = "";
    if (currentQuesId) {
      initQuesId = currentQuesId;
    } else {
      initQuesId =
        getTextQuestionData && getTextQuestionData.length > 0
          ? getTextQuestionData[0].id
          : "";
    }

    this.setState({ keyWordList: keyWord });

    // console.log("--->--->--->--->--->--->--->FilterList--->--->--->--->--->--->--->",FilterList)
    // this.setState({ FilterList: FilterList });

    callWordCloudText(
      initQuesId,
      keyWord,
      this.state.FilterList,
      "",
      isMultiKeyword,
      "TreeMap"
    );
  };

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { getTextQuestionData } = this.props;
    let { currentQuesId, graphType } = this.state;
    let initQuesId = "";
    if (currentQuesId) {
      initQuesId = currentQuesId;
    } else {
      initQuesId =
        getTextQuestionData && getTextQuestionData.length > 0
          ? getTextQuestionData[0].id
          : "";
    }

    let { handleWordCloudDemographicFilter } = this.props;

    let showValue =
      finalFilter && finalFilter[0] && finalFilter[0]["value"]
        ? finalFilter[0]["value"]
        : "";
    this.setState({ FilterList: finalFilter, showValue: showValue });

    handleWordCloudDemographicFilter(
      initQuesId,
      finalFilter,
      graphType,
      "TreeMap"
    );
  };

  render() {
    let {
      TreeMapError,
      ErrorOtherObj,
      getDemographicIndexHappinessDataTreeMap,
      getDemographicIndexEngagementDataTreeMap,
      getDemographicIndexStressDataTreeMap,
      getDemographicIndexManagerDataTreeMap,
      getDemographicIndexLeaderDataTreeMap,
      getDemographicIndexMultiFilterDataTreeMap,

      getIndexData,
      CountRestrict,

      getHappinessIndexDriverData,
      getEngagementIndexDriverData,
      getStressIndexDriverData,
      getManagerIndexDriverData,
      getLeaderIndexDriverData,

      getIndexByFilterDMGData,
      TreeMapData,
      loading,
      callQuestionWordCloud,
      wordTextKeyWord,
      wordCloudTextData,
      getTextQuestionData,
      handleChangeQuestion,
      wordCloudData,
      EmpFilterData,
    } = this.props;

    let { FilterList, keyWordList } = this.state;

    // let initQuesId = '';

    // if(currentQuesId){
    //   initQuesId = currentQuesId;
    // }
    // else{
    //   initQuesId = getTextQuestionData && getTextQuestionData.length>0?getTextQuestionData[0].id:'';
    // }

    let count =
      wordCloudData && wordCloudData.count ? wordCloudData.count : "NaN";
    let CloudData = CountRestrict(count) ? [] : wordCloudData;
    let CloudTextData = CountRestrict(count) ? [] : wordCloudTextData;
    let wordTextKeyWordData = CountRestrict(count) ? "" : wordTextKeyWord;

    let maxCount = 0;
    if (wordCloudData && wordCloudData.list && wordCloudData.list.length > 0) {
      wordCloudData.list.forEach((item) => {
        if (item[1] > maxCount) {
          maxCount = item[1];
        }
      });
    }

    console.log(
      "/// TreeMapData-TreeMapData--->",
      TreeMapData && TreeMapData.length > 0
    );
    console.log("TreeMapData---->",TreeMapData)

    console.log(
      "/// wordCloudTextData-wordCloudTextData--->",
      wordCloudTextData && wordCloudTextData.length > 0
    );

    let TreeMapDataFinal = [];
    // if(TreeMapData && TreeMapData.length>0){
    //   TreeMapDataFinal = TreeMapData.filter(prev=>prev[0]===this.state.showValue)
    // }
    if (TreeMapData && TreeMapData.length > 0) {
      TreeMapDataFinal = TreeMapData;
    }
    console.log("/// this.state.TreeMapDataFinal--->", TreeMapDataFinal);

    let { indexType, optionType, getCommentData } = this.props;

    return (
      <>
        <div onClick={() => this.closeFilter(false)}>
          <div className="flex flex-wrap items-start justify-between w-full gap-4">
            <QuestionFilter
              showViewBy={this.state.showViewBy}
              closeFilterQues={this.closeFilterQues}
              callQuestionWordCloud={callQuestionWordCloud}
              getTextQuestionData={getTextQuestionData}
              handleChangeQuestion={handleChangeQuestion}
              handleChangeQuesIdSave={this.handleChangeQuesIdSave}
            />
          </div>
        </div>

        <div className=" md:flex  justify-between items-center lg:space-y-0 space-y-4">
          <Filter //DemographicFilter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}
            EmpFilterData2={EmpFilterData}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
          />
          

          <Commentary
            indexType={indexType}
            optionType={optionType}
            getCommentData={getCommentData}
          />
        </div>

        {/* {FilterList && FilterList.length>0?
              <div className="items-center h-10 px-4 py-3 bg-white border-b xl:flex lg:flex md:flex justify-left">
                {FilterList && FilterList.length>0?FilterList.map((element)=>
                <p className="flex mx-2 my-4 font-semibold text-md"><p className="mr-1 text-blue-500">{element.level+": "}</p><p className="text-gray-500 ">{element.value}</p></p>
                ):null}</div>
            :null} */}

        {getTextQuestionData && getTextQuestionData.length > 0 ? (
          <div>
            <div
              onClick={() => {
                this.closeFilter(false);
                this.closeFilterQues(false);
              }}
              className=""
            >
              <div className={"grid xl:grid-cols-1 grid-cols-1 gap-4 my-4"}>
                {/* <div className="p-4 space-y-4 bg-white border">
                      <div className="flex items-center justify-between">
                      <h1 className="text-sm font-semibold text-gray-900">Tree Map
                      <span className="text-blue-500">{" ( This rectangular map shows demographic wise comparative analysis of trending Co-occurance words . ) "}</span>

                      </h1>
                      </div> */}

                {(FilterList &&
                  FilterList[0] &&
                  FilterList[0]["value"] !== "All") ||
                true ? (
                  <div className="">
                    {TreeMapDataFinal && TreeMapDataFinal.length > 0 ? (
                      TreeMapDataFinal.map((item, index) =>
                        true ? (
                          <div className=" bg-white hover:shadow-lg duration-150 transition  border  rounded-lg   cursor-pointer">
                            <h1 class="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap px-6 py-4 border-b ">
                            Co-Occurrence Graph
                            </h1>

                            <VerticalBarChart
                              dmgValue={item[0]}
                              valueList={item[1]}
                              handleKeywordCall={this.handleKeywordCall}
                              FilterList={FilterList}
                              loading={loading}
                              EmpFilterData={EmpFilterData}
                              CountRestrict={CountRestrict}
                              secondCardActive={
                                wordCloudTextData &&
                                wordCloudTextData.length > 0 &&
                                TreeMapData &&
                                TreeMapData.length > 0
                              }
                            />
                          </div>
                        ) : null
                      )
                    ) : (
                      <div className="pt-40 text-3xl text-center text-gray-400">
                        We have not calculated this section!
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="my-24 text-3xl font-medium text-center text-gray-400">
                    {"Please Select Demographic to View Data"}
                  </div>
                )}

   
              </div>

              {wordCloudTextData &&
              wordCloudTextData.length > 0 &&
              TreeMapDataFinal &&
              TreeMapDataFinal.length > 0 ? (
                <div className="grid grid-cols-1 gap-4 my-4 xl:grid-cols-2">
                  <IndexComparison
                    getDemographicIndexHappinessDataTreeMap={
                      getDemographicIndexHappinessDataTreeMap
                    }
                    getDemographicIndexEngagementDataTreeMap={
                      getDemographicIndexEngagementDataTreeMap
                    }
                    getDemographicIndexStressDataTreeMap={
                      getDemographicIndexStressDataTreeMap
                    }
                    getDemographicIndexManagerDataTreeMap={
                      getDemographicIndexManagerDataTreeMap
                    }
                    getDemographicIndexLeaderDataTreeMap={
                      getDemographicIndexLeaderDataTreeMap
                    }
                    getDemographicIndexMultiFilterDataTreeMap={
                      getDemographicIndexMultiFilterDataTreeMap
                    }
                    getIndexData={getIndexData}
                    getHappinessIndexDriverData={getHappinessIndexDriverData}
                    getEngagementIndexDriverData={getEngagementIndexDriverData}
                    getStressIndexDriverData={getStressIndexDriverData}
                    getManagerIndexDriverData={getManagerIndexDriverData}
                    getLeaderIndexDriverData={getLeaderIndexDriverData}
                    FilterList={FilterList}
                  />

                  <WordDetails
                    keyWordList={keyWordList}
                    FilterList={FilterList}
                    loading={loading}
                    wordCloudTextData={CloudTextData}
                    wordTextKeyWord={wordTextKeyWordData}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <>
            {!loading ? (
              <div className="pt-40 text-3xl text-center text-gray-400">
                We have not calculated this section!
              </div>
            ) : null}
          </>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn,
  };
}
export default connect(mapStateToProps)(TextAnalysis);
