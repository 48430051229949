import React from "react";
import { GoDotFill } from "react-icons/go";

function Content(props) {

  let { task } = props;

  return (
    <div className='mt-10 md:w-4/5 w-full mx-auto'>
      <p className='bg-[#009688] p-4 text-white text-base'>
        {task && task.desc ? task.desc : ""}
      </p>

      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          {task && task.list && task.list.length > 0 &&
            task.list.map((ele) =>
              <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="flex items-center space-x-2 text-[#212121] text-base font-medium">
                  <GoDotFill className='w-3 h-3' />
                  <span className='text-lg'>{ele && ele.title ? ele.title : ""}: </span>
                </dt>
                <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2 ">{ele && ele.desc ? ele.desc : ""}</dd>
              </div>
            )}
        </dl>
      </div>
    </div>
  );

}
export default Content;