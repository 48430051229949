import React, { Component } from "react";
import { connect } from "react-redux";
import DemographicFilter from "./Filters/DemographicFilter";
import QuestionFilter from "./Filters/QuestionFilter";
import CooccurGraph from "./Content/CooccurGraph";
import NeighbourGraph from "./Content/NeighbourGraph";
import Commentary from "../../../Index/Components/Commentary/Commentary";
import DemographicMultiFilter from '../Filters/DemographicMultiFilter';

class Network extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuesId: "",
      DemographicFilterName: "",
      DemographicFilterValue: "",
      graphType: 2,
      FilterList: [],
      showFilter: false,
      showValue: "",
      showViewBy: false,

      showViewByDMG: false,
      crrDMGSelected: ""
    };
  }

  componentDidMount() { }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };
  closeFilterQues = (check1) => {
    this.setState({ showViewBy: check1 });
  };
  handleChangeQuesIdSave = (Id) => {
    this.setState({ currentQuesId: Id });
  };

  FilterSave = (name, value) => {
    this.setState({
      DemographicFilterName: name,
      DemographicFilterValue: value,
    });
  };

  handleKeywordCall = (keyWord, isMultiKeyword) => {
    let { currentQuesId, DemographicFilterName, DemographicFilterValue } =
      this.state;
    let { getTextQuestionData, callWordCloudText, } = this.props;

    let initQuesId = "";
    if (currentQuesId) {
      initQuesId = currentQuesId;
    } else {
      initQuesId =
        getTextQuestionData && getTextQuestionData.length > 0
          ? getTextQuestionData[0].id
          : "";
    }

    callWordCloudText(
      initQuesId,
      keyWord,
      DemographicFilterName,
      DemographicFilterValue,
      isMultiKeyword
    );
  };

  ongraphType = (type) => {
    let { callQuestionNetworkCloud, getTextQuestionData } = this.props;
    let { FilterList, currentQuesId } = this.state;
    this.setState({ graphType: type });

    let initQuesId = "";
    if (currentQuesId) {
      initQuesId = currentQuesId;
    } else {
      initQuesId =
        getTextQuestionData && getTextQuestionData.length > 0
          ? getTextQuestionData[0].id
          : "";
    }

    callQuestionNetworkCloud(initQuesId, type, FilterList);

    // if(type===2){
    //   handleNeighborGraph(FilterList);
    // }
    // else{
    //   callQuestionNetworkCloud(initQuesId,type, FilterList);
    // }
  };

  handleNetworkDemographicFilter = (currentQuesId, selectedFilterName, val) => {
    let { handleNetworkDemographicFilter } = this.props;
    handleNetworkDemographicFilter(
      currentQuesId,
      selectedFilterName,
      val,
      this.state.graphType
    );
  };

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { getTextQuestionData } = this.props;
    let { currentQuesId, graphType, crrDMGSelected } = this.state;
    let initQuesId = "";
    if (currentQuesId) {
      initQuesId = currentQuesId;
    } else {
      initQuesId =
        getTextQuestionData && getTextQuestionData.length > 0
          ? getTextQuestionData[0].id
          : "";
    }

    let { handleNetworkDemographicFilter } = this.props;
    this.setState({ FilterList: finalFilter });
    handleNetworkDemographicFilter(initQuesId, finalFilter, graphType, crrDMGSelected);
  };

  callQuestionNetworkCloudQuesChange = (initQuesId) => {
    let { handleNetworkDemographicFilter } = this.props;
    let { FilterList, crrDMGSelected, graphType } = this.state;
    // callQuestionNetworkCloud(initQuesId, "", FilterList, crrDMGSelected);
    handleNetworkDemographicFilter(initQuesId, FilterList, graphType, crrDMGSelected);

  };


  closeFilterDMG = (value) => {
    this.setState({ showViewByDMG: value });
  }
  handleCrossDMGChange = (ele) => {
    this.setState({ crrDMGSelected: ele }, () => {
      let { getTextQuestionData, handleNetworkDemographicFilter } = this.props;
      let { currentQuesId, FilterList, graphType } = this.state;
      let initQuesId = '';
      if (currentQuesId) {
        initQuesId = currentQuesId;
      }
      else {
        initQuesId = getTextQuestionData && getTextQuestionData.length > 0 ? getTextQuestionData[0].id : '';
      }

      handleNetworkDemographicFilter(initQuesId, FilterList, graphType, ele);

    });
  }


  render() {
    let {
      isDMGText, getAllFeedbackWordsData,
      GetParameterLabel,
      ErrorOtherObj,
      neighborGraphError,
      getIndexByFilterDMGData,
      wordTextKeyWordOld,
      wordCloudTextDataOld,
      loading,
      neighborGraphData,
      callQuestionNetworkCloud,
      cooccurmatrixData,
      wordTextKeyWord,
      wordCloudTextData,
      getTextQuestionData,
      handleChangeQuestion,
      EmpFilterData,

      addKeywordsAPI

    } = this.props;

    let { graphType, FilterList } = this.state;

    // let initQuesId = '';

    // if(currentQuesId){
    //   initQuesId = currentQuesId;
    // }
    // else{
    //   initQuesId = getTextQuestionData && getTextQuestionData.length>0?getTextQuestionData[0].id:'';
    // }

    let { CountRestrict } = this.props;

    // let count1 = cooccurmatrixData && cooccurmatrixData.count?cooccurmatrixData.count:"NaN";
    // let cooccurmatrixDataFinal = CountRestrict(count1)?{}:cooccurmatrixData;

    let count2 =
      neighborGraphData && neighborGraphData.count
        ? neighborGraphData.count
        : "NaN";
    let neighborGraphDataFinal = CountRestrict(count2) ? {} : neighborGraphData;

    let neighborGraphTextData = wordCloudTextDataOld;
    let neighborGraphKeyword = wordTextKeyWordOld;

    let neighborGraphTextData2 = wordCloudTextData;
    let neighborGraphKeyword2 = wordTextKeyWord;

    let { indexType, optionType, getCommentData, getTextKeywordsData } = this.props;

    return (
      <>
        {/* <div className="items-center justify-between h-10 px-4 bg-white border-b xl:flex lg:flex md:flex">
                  <div className="flex overflow-hidden overflow-x-auto text-xs font-medium text-gray-600 cursor-pointer whitespace-nowrap">
                    <span onClick={()=>this.ongraphType(1)} className={(graphType===1)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Co-Occurrence Graph</span>
                    <span onClick={()=>this.ongraphType(2)} className={(graphType===2)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Neighbour Graph</span>
                  </div>
                </div> */}

        <div onClick={() => this.closeFilter(false)}>
          <div className="flex flex-wrap items-start justify-between w-full gap-4">
            <QuestionFilter
              showViewBy={this.state.showViewBy}
              closeFilterQues={this.closeFilterQues}
              graphType={graphType}
              FilterList={FilterList}
              callQuestionNetworkCloud={this.callQuestionNetworkCloudQuesChange}
              getTextQuestionData={getTextQuestionData}
              handleChangeQuestion={handleChangeQuestion}
              handleChangeQuesIdSave={this.handleChangeQuesIdSave}
            />
          </div>
        </div>

        <div className="items-center justify-between space-y-4 md:flex lg:space-y-0">
          <DemographicFilter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}
            EmpFilterData2={EmpFilterData}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
          />
          <div className='flex space-x-2 '>


            {isDMGText &&
              <DemographicMultiFilter
                showViewBy={this.state.showViewByDMG}
                closeFilter={this.closeFilterDMG}
                handleItemChange={this.handleCrossDMGChange}
                getTextQuestionData={
                  getAllFeedbackWordsData && getAllFeedbackWordsData.length > 0 ?
                    getAllFeedbackWordsData.map((ele) => ({ id: ele, label: ele, name: ele })) : []
                }
              />}

            <Commentary
              indexType={indexType}
              optionType={optionType}
              getCommentData={getCommentData}
            />
          </div>

        </div>

        {ErrorOtherObj && ErrorOtherObj["neighborGraphError"] ? (
          <>
            <div className="my-24 text-2xl font-medium text-center text-gray-400">
              {ErrorOtherObj["neighborGraphError"]}
            </div>
          </>
        ) : (
          <>
            {getTextQuestionData && getTextQuestionData.length > 0 ? (
              <div
                onClick={() => {
                  this.closeFilter(false);
                  this.closeFilterQues(false);
                }}
                className=""
              >
                <NeighbourGraph
                  addKeywordsAPI={addKeywordsAPI}
                  getTextKeywordsData={getTextKeywordsData}
                  neighborGraphData={neighborGraphDataFinal}
                  neighborGraphError={neighborGraphError}
                  handleKeywordCall={this.handleKeywordCall}
                  neighborGraphTextData={neighborGraphTextData}
                  neighborGraphKeyword={neighborGraphKeyword}
                  neighborGraphTextData2={neighborGraphTextData2}
                  neighborGraphKeyword2={neighborGraphKeyword2}
                  GetParameterLabel={GetParameterLabel}
                  EmpFilterData2={EmpFilterData}
                />
              </div>
            ) : (
              <>
                {!loading ? (
                  <div className="pt-40 text-3xl text-center text-[#3D405B]/70">
                    We have not calculated this section!
                  </div>
                ) : null}
              </>
            )}
          </>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn,
  };
}
export default connect(mapStateToProps)(Network);
