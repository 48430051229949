export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    PMS_LOGIN_OTP_REQUEST: 'PMS_LOGIN_OTP_REQUEST',
    PMS_LOGIN_OTP_SUCCESS: 'PMS_LOGIN_OTP_SUCCESS',
    PMS_LOGIN_OTP_FAILURE: 'PMS_LOGIN_OTP_FAILURE',

    PMS_LOGIN_REQUEST: 'PMS_LOGIN_REQUEST',
    PMS_LOGIN_SUCCESS: 'PMS_LOGIN_SUCCESS',
    PMS_LOGIN_FAILURE: 'PMS_LOGIN_FAILURE',

    LOGIN_OTP_REQUEST: 'USERS_LOGIN_OTP_REQUEST',
    LOGIN_OTP_SUCCESS: 'USERS_LOGIN_OTP_SUCCESS',
    LOGIN_OTP_FAILURE: 'USERS_LOGIN_OTP_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    LOGIN_SUPER_ADMIN_REQUEST: 'USERS_LOGIN_SUPER_ADMIN_REQUEST',
    LOGIN_SUPER_ADMIN_SUCCESS: 'USERS_LOGIN_SUPER_ADMIN_SUCCESS',
    LOGIN_SUPER_ADMIN_FAILURE: 'USERS_LOGIN_SUPER_ADMIN_FAILURE',


    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',

    LOGO_FILE_UPLOAD_STATUS_REQUEST: 'LOGO_FILE_UPLOAD_STATUS_REQUEST',
    LOGO_FILE_UPLOAD_STATUS_SUCCESS: 'LOGO_FILE_UPLOAD_STATUS_SUCCESS',
    LOGO_FILE_UPLOAD_STATUS_FAILURE: 'LOGO_FILE_UPLOAD_STATUS_FAILURE',

    LOGO_FILE_UPLOAD_2_STATUS_REQUEST: 'LOGO_FILE_UPLOAD_2_STATUS_REQUEST',
    LOGO_FILE_UPLOAD_2_STATUS_SUCCESS: 'LOGO_FILE_UPLOAD_2_STATUS_SUCCESS',
    LOGO_FILE_UPLOAD_2_STATUS_FAILURE: 'LOGO_FILE_UPLOAD_2_STATUS_FAILURE',

    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',


    UPLOAD_PDF_STATUS_REQUEST: 'UPLOAD_PDF_STATUS_REQUEST',
    UPLOAD_PDF_STATUS_SUCCESS: 'UPLOAD_PDF_STATUS_SUCCESS',
    UPLOAD_PDF_STATUS_FAILURE: 'UPLOAD_PDF_STATUS_FAILURE',


    
    GROW_LOGIN_REQUEST: 'GROW_LOGIN_REQUEST',
    GROW_LOGIN_SUCCESS: 'GROW_LOGIN_SUCCESS',
    GROW_LOGIN_FAILURE: 'GROW_LOGIN_FAILURE',
    
    GROW_LOGIN_OTP_REQUEST: 'GROW_LOGIN_OTP_REQUEST',
    GROW_LOGIN_OTP_SUCCESS: 'GROW_LOGIN_OTP_SUCCESS',
    GROW_LOGIN_OTP_FAILURE: 'GROW_LOGIN_OTP_FAILURE',

    GROW_LOGOUT_REQUEST: 'GROW_LOGOUT_REQUEST',
    GROW_LOGOUT_SUCCESS: 'GROW_LOGOUT_SUCCESS',
    GROW_LOGOUT_FAILURE: 'GROW_LOGOUT_FAILURE',
};