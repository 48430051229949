import React, { Component } from "react";
import { connect } from "react-redux";

function IntroPage(props) {

  let { userName, createdAt } = props;

  return (

    <div className="flex flex-col space-y-8 w-full ">
      <img className="w-48 mx-auto" src="./img/hitachi-logo-1-1.svg" />
      <h1 className="text-5xl font-bold capitalize   text-[#E50026]  text-center">
        Pulse
      </h1>
      <div className="text-center text-[#212121]/80  font-medium flex items-center space-x-2 justify-center" ><span>Overall </span> <span>|</span> <span>{createdAt}</span></div>
      <img className="w-full" src='./img/pulse-cover-img.webp' />
      <p className="text-center text-[#212121] font-medium">This report is confidential and should not be distributed without permission
      </p>
      {/* <p className="text-sm text-[#212121]/80 text-center">Copyright © 2024 HappyPlus, Inc. All rights reserved.</p> */}
    </div>

  );
}
export default IntroPage;
