import React, { Component } from 'react';
import { connect } from 'react-redux';
import { surveyActions, dashboardActions } from '../../../../_actions';
import { CSVLink } from "react-csv";

import FilterDMG from '../SurveyReport/FilterDMG/Filter';
import ResponseCards from '../SurveyReport/Components/ResponseCards';
import ResponseMetaTable from '../SurveyReport/Components/ResponseMetaTable';

import MultiBarStaticChart from './Charts/MultiBarStaticChart';


class SurveyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {

      FilterValues: [],
      showFilter: false,
      showValue: '',

      litsXAxis: 'Gender',



      selectFilterType: 1,
      surveyReportEmail: '',
      openModalComfirmSend: false,
      SaveEmployeeId: '',
      DemographValue: '',



      filterList: [
        {
          id: true,
          type: 'Show All'
        },
        {
          id: false,
          type: 'Show Filter'
        }
      ],

      isMailList: [
        {
          id: true,
          type: 'Delivered'
        },
        {
          id: false,
          type: 'Not Delivered'
        }
      ],

      isClickedList:
        [
          {
            id: true,
            type: 'Clicked',
          },
          {
            id: false,
            type: 'Not Clicked'
          }
        ],

      isCompleteList: [
        {
          id: true,
          type: 'Completed'
        },
        {
          id: false,
          type: 'Not Completed'
        }
      ],

      filterformData: {
        "showAll": true,
        "isMailSent": true,
        "isClicked": true,
        "isComplete": true
      },

      currFilterName: "Demographic"

    }
  }
  componentDidMount() {
    let { EmpFilterData, getIndexFilterData } = this.props;
    let { selectedFilterName, showViewBy } = this.state;

    let CurrentFilterName = this.state.litsXAxis;
    if (EmpFilterData && EmpFilterData.length > 0) {
      CurrentFilterName = EmpFilterData[0].name
    }

    let data = {
      "surveyId": this.props.CurrentSurveyId,
      "xAxis": CurrentFilterName
    }
    // this.props.dispatch(dashboardActions.responseAnalysisBySurveyId(data));
    // this.props.dispatch(dashboardActions.getResponseAnalytics(data));
    this.props.dispatch(dashboardActions.getMeta360ResponseAnalyticsFilter(data));

    let data2 = {
      "surveyId": this.props.CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getMeta360RespondantDetails(data2));

    this.props.dispatch(surveyActions.getIndexByFilterDMG({
      filters: []
    }));

  }


  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }


  handleCreateSurveyPopup = () => {

  }

  handleSetFilter = (value) => {

    let { filterformData } = this.state;

    if (value === 'true' || value === true) {
      this.setState({ selectFilterType: 1 });
      filterformData['showAll'] = true;
    }
    else {
      this.setState({ selectFilterType: 2 });
      filterformData['showAll'] = false;
    }

    this.setState({ filterformData });

  }


  handleIsMailSent = (value) => {
    let { filterformData } = this.state;
    if (value === 'true' || value === true) {
      filterformData['isMailSent'] = true;
    }
    else {
      filterformData['isMailSent'] = false;

    }
    this.setState({ filterformData });

  }

  handleIsClicked = (value) => {
    let { filterformData } = this.state;
    if (value === 'true' || value === true) {
      filterformData['isClicked'] = true;
    }
    else {
      filterformData['isClicked'] = false;

    }
    this.setState({ filterformData });

  }

  handleIsComplete = (value) => {
    let { filterformData } = this.state;
    if (value === 'true' || value === true) {
      filterformData['isComplete'] = true;
    }
    else {
      filterformData['isComplete'] = false;

    }
    this.setState({ filterformData });

  }

  handleEmailInput = (val) => {
    this.setState({ surveyReportEmail: val });
  }

  HandleFilterType = (value) => {
    this.setState({ selectFilterType: value });
  }

  RefreshAPI = () => {
    let data = {
      surveyId: this.props.CurrentSurveyId
    }
    this.props.dispatch(surveyActions.responseAnalysisBySurveyId(data));
    this.props.dispatch(surveyActions.getCampaignEmployeesByIDSurveyWise(data));

  }


  forceMail = (id) => {
    this.setState({
      openModalComfirmSend: true,
      SaveEmployeeId: id
    })
    // this.props.dispatch(surveyActions.resendCampain({ id: id }));
  }


  handleSendSingleMail = () => {
    let { SaveEmployeeId } = this.state;
    this.props.dispatch(surveyActions.resendCampain({ id: SaveEmployeeId }));
    this.setState({ SaveEmployeeId: '' });
  }

  resendCampain = (id) => {
    this.setState({
      openModalComfirmSend: true,
      SaveEmployeeId: id
    })
    //  this.props.dispatch(surveyActions.resendCampain({ id: id }));
  }

  handleConfirmMailClose = () => {
    this.setState({ openModalComfirmSend: false, SaveEmployeeId: '' });
  }


  handleQuestionFilterName = (Name, Value) => {
    this.setState({ DemographValue: '' });

    let data = {
      "surveyId": this.props.CurrentSurveyId,
      "xAxis": Name
    }
    this.props.dispatch(dashboardActions.getResponseAnalytics(data));
  }

  handleQuestionFilterValue = (Name, Value) => {
    console.log('Value:', Value)
    if (Value === 'All') {
      this.setState({ DemographValue: '' });
    }
    else {
      this.setState({ DemographValue: Value });
    }
  }

  handleQuestionFilterValueSave = () => {

  }

  handleQuestionFilterNameSave = () => {

  }


  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleMultifilterBehaviour } = this.props;
    this.setState({ FilterValues: finalFilter, currFilterName: name });
    ////console.log("finalFilter, true, name====>",finalFilter, true, name)
    // handleMultifilterBehaviour(finalFilter, true, name);





    let FilterValues = finalFilter;

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues;
      }

    }



    let data = {
      "surveyId": this.props.CurrentSurveyId,
      "xAxis": XAxis,
      "filters": Filters
    }

    this.setState({ litsXAxis: XAxis })
    this.props.dispatch(dashboardActions.getMeta360ResponseAnalyticsFilter(data));


  }


  render() {
    let { survey, dashboard, closeResponseAnalytics, handleGoBack,



      getIndexFilterData,
      currFilterName,
      getRespondantDetailsData,
      styleType,
      loading

    } = this.props;
    // let {  getResponseAnalyticsData } = survey;
    let { getResponseAnalyticsData, getMetaRespondantDetailsData, getMetaResponseAnalyticsFilterData } = dashboard;
    let { getIndexByFilterDMGData } = survey;

    let { openModalComfirmSend, DemographValue, FilterValues } = this.state;


    let DemographicResponseNewData = getResponseAnalyticsData;

    function ResponseRate(item) {
      return (item.completed * 100) / item.totalInvite
    }


    if (DemographicResponseNewData && DemographicResponseNewData.length > 0) {
      DemographicResponseNewData.sort((a, b) => ResponseRate(b) - ResponseRate(a));
    }

    let EmpFilterData = [];
    let filterLevels = this.props.EmpFilterData;
    if (filterLevels && filterLevels.length > 0) {
      filterLevels.forEach((item) => {
        if (item.name !== "ManagerPID") { EmpFilterData.push(item); }
      })
    }


    console.log('EmpFilterData====?', EmpFilterData)



    console.log('==============>getIndexByFilterDMGData==============>', getIndexByFilterDMGData);

    console.log('==============>DemographicResponseNewData==============>', DemographicResponseNewData);


    let DemographicValueList = []
    if (DemographicResponseNewData && DemographicResponseNewData.length > 0) {
      DemographicResponseNewData.forEach((value, index) => {
        DemographicValueList.push(value.name);
      });
    }

    console.log('==============>DemographicResponseNewData==============>', DemographicResponseNewData);


    function spliceDecimal(no) {
      if (no) {
        let str = no.toString();
        let first = str.split('.')[0];
        let second = str.split('.')[1];
        let second2 = second ? second.substring(0, 2) : ""
        let final = first + '.' + second2;
        if (second2) {
          return parseFloat(final).toFixed(2)
        } else {
          return no
        }
      } else {
        return 0
      }
    }


    function getResponseOverall(data, type) {
      let value = 0;
      let mailSent = data["mailSent"] ? data["mailSent"] : 0
      let clicked = data["clicked"] ? data["clicked"] : 0
      let completed = data["completed"] ? data["completed"] : 0

      if (type === "mailSent") {
        value = mailSent
      } else if (type === "clicked") {
        value = clicked
      } else if (type === "notclicked") {
        value = mailSent - clicked
      } else if (type === "completed") {
        value = completed
      } else if (type === "notcompleted") {
        value = mailSent - completed
      } else if (type === "responserate") {
        let perc = spliceDecimal((completed * 100) / mailSent);
        console.log("perc", perc)
        value = perc
      }
      if (type === "responserate") {
        console.log("value--->", value)
      }
      return value

    }





    function getResponseValue(data, type) {
      let value = 0;
      let mailSent = data["mailSent"] ? data["mailSent"] : 0
      let clicked = data["clicked"] ? data["clicked"] : 0
      let completed = data["completed"] ? data["completed"] : 0

      if (type === "mailSent") {
        value = mailSent
      } else if (type === "clicked") {
        value = clicked
      } else if (type === "notclicked") {
        value = mailSent - clicked
      } else if (type === "completed") {
        value = completed
      } else if (type === "notcompleted") {
        value = mailSent - completed
      } else if (type === "responserate") {
        let perc = spliceDecimal((Number(completed) * 100) / Number(mailSent));
        console.log("perc", perc)
        value = perc
      }
      if (type === "responserate") {
        console.log("value--->", value)
      }
      return value

    }




    let CurrentFilterName = this.state.litsXAxis;
    if (EmpFilterData && EmpFilterData.length > 0) {
      CurrentFilterName = EmpFilterData[0].name
    }



    let temp1 = {}

    temp1[CurrentFilterName] = "Overall";
    temp1["Sent"] = getResponseOverall(getMetaRespondantDetailsData && getMetaRespondantDetailsData[0] ? getMetaRespondantDetailsData[0] : {}, "mailSent");
    temp1["Clicked"] = getResponseOverall(getMetaRespondantDetailsData && getMetaRespondantDetailsData[0] ? getMetaRespondantDetailsData[0] : {}, "clicked");
    temp1["Notclicked"] = getResponseOverall(getMetaRespondantDetailsData && getMetaRespondantDetailsData[0] ? getMetaRespondantDetailsData[0] : {}, "notclicked");
    temp1["Completed"] = getResponseOverall(getMetaRespondantDetailsData && getMetaRespondantDetailsData[0] ? getMetaRespondantDetailsData[0] : {}, "completed");
    temp1["Notcompleted"] = getResponseOverall(getMetaRespondantDetailsData && getMetaRespondantDetailsData[0] ? getMetaRespondantDetailsData[0] : {}, "notcompleted");
    temp1["ResponseRate"] = getResponseValue(getMetaRespondantDetailsData && getMetaRespondantDetailsData[0] ? getMetaRespondantDetailsData[0] : {}, "responserate").toString() + "%";

    let DownloadExcelResponse = [temp1];
    if (getMetaResponseAnalyticsFilterData && getMetaResponseAnalyticsFilterData.length > 0) {
      getMetaResponseAnalyticsFilterData.forEach((element) => {

        let temp = {}


        temp[CurrentFilterName] = element && element.name ? element.name : "NA";
        temp["Sent"] = getResponseValue(element.data, "mailSent");
        temp["Clicked"] = getResponseValue(element.data, "clicked");
        temp["Notclicked"] = getResponseValue(element.data, "notclicked");
        temp["Completed"] = getResponseValue(element.data, "completed");
        temp["Notcompleted"] = getResponseValue(element.data, "notcompleted");
        temp["ResponseRate"] = getResponseValue(element.data, "responserate").toString() + "%";


        DownloadExcelResponse.push(temp)
      })
    }



    return (
      <>
        <div className="">
          <div className="space-y-4" style={{}}>
            {styleType !== 2 ?
              <div className='flex items-center space-x-4'>
                <span onClick={handleGoBack} class="cursor-pointer material-symbols-outlined bg-blue-100 p-2 rounded-full text-blue-500 ">arrow_back</span>
                <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">Response Demographic Wise</h1>
              </div>
              : null}


            <div className="grid gap-6 lg:grid-cols-3 md:grid-cols-3 ">
              <ResponseCards getRespondantDetailsData={getMetaRespondantDetailsData} />
            </div>
            <div className="capitalize bg-white border rounded-md">

              <div className='flex items-center justify-between pt-4 mx-3'>
                <div className='flex items-center space-x-2'>
                  <div className="flex items-center px-2 text-sm md:text-xl">
                    {"Response Demographic Wise"} </div></div>


                <div className='flex flex-wrap gap-2 itmes-center '>

                  <FilterDMG
                    showFilter={this.state.showFilter}
                    showValue={this.state.showValue}
                    EmpFilterData2={EmpFilterData}
                    DemographicValueList2={getIndexByFilterDMGData}
                    closeFilter={this.closeFilter}
                    handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
                  />


                  {/* <div className='mx-2'>   
                          {DownloadExcelResponse && DownloadExcelResponse.length > 0 ?
                          <CSVLink data={DownloadExcelResponse} filename={"Survey360MetaResponseAnalytics.csv"}>
                            <span className="flex items-center px-4 py-2 text-sm font-medium text-white capitalize bg-blue-500 rounded-md hover:bg-blue-600">
                              <span className="mr-2 material-symbols-outlined">file_download</span> Download response</span>
                          </CSVLink>
                          : '...'}
                        </div>  */}

                  <div className='mx-2'>
                    {DownloadExcelResponse && DownloadExcelResponse.length > 0 ?
                      <CSVLink data={DownloadExcelResponse} filename={"SurveyMetaResponseAnalytics.csv"}>
                        <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                          <svg xmlns="http://www.w3.org/2000/svg" className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                          > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <path d="M14 3v4a1 1 0 0 0 1 1h4" /> <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /> <path d="M10 12l4 4m0 -4l-4 4" />
                          </svg>
                          <span>Download Response</span>
                        </button>
                      </CSVLink>
                      : '...'}
                  </div>

                </div>


              </div>


              {getMetaResponseAnalyticsFilterData && getMetaResponseAnalyticsFilterData.length > 0 ?

                <ResponseMetaTable
                  getResponseOverall={getResponseOverall}
                  getResponseValue={getResponseValue}
                  litsXAxis={this.state.litsXAxis}
                  getMetaRespondantDetailsData={getMetaRespondantDetailsData}
                  getMetaResponseAnalyticsFilterData={getMetaResponseAnalyticsFilterData}
                />
                : <>
                  <div className="w-full mb-4 overflow-hidden overflow-x-auto bg-white whitespace-nowrap">
                    {loading ? <div>
                      <div className='py-12 text-lg text-center text-gray-500'>Loading...</div>
                    </div> :
                      <div className='py-12 text-lg text-center text-gray-500'>Data not available</div>
                    }
                  </div>
                </>}
            </div>


          </div>

        </div>
      </>
    );
  }
}


function mapStateToProps(state) {
  ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard } = state;
  return {
    loggingIn,
    survey,
    dashboard
  };
}
export default connect(mapStateToProps)(SurveyReport);
