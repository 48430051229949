import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// import { CSVLink } from "react-csv";  
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import EmployeeDropdown from '../Filter/EmployeeDropdown';
import LTHeadEmployeeDropdown from '../Filter/LTHeadEmployeeDropdown';
import Filter from './Filter/Filter';
import PageTemplate from './PageTemplate';
// import ReactEcharts from 'echarts-for-react';
import IntroPage from './Components/IntroPage';
import ICEIndexSummary from './Components/ICEIndexSummary';
import ResponsePage from './Components/ResponsePage';
import ResponsePageV2 from './Components/ResponsePageV2';
import IndexThemesDistribution from './Components/IndexThemesDistribution';
import OrgThemesSelfCross from './Components/OrgThemesSelfCross';
import NPSDistributionSubDepartment from './Components/NPSDistributionSubDepartment';
import FeedbackResponseSubDepartment from './Components/FeedbackResponseSubDepartment';
import MNGRIndexSummary from './Components/MNGRIndexSummary';
import FeedbackResponseTopicDesc from './Components/FeedbackResponseTopicDesc';
import NPSDistributionOverall from './Components/NPSDistributionOverall';


// const pdfExportComponent = React.useRef(null);
class SubHitachiReport extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',
      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: '',
      crrSelectedEmp: {},
      crrSelectedType: {
        "emp_id": "LT Head",
        "name": "LT Head"
      },
      showReportNow: false
    }
  }

  componentDidMount() {

  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { get360Overview, handle360GetQuestionFilter } = this.props;
    this.setState({ FilterValues: finalFilter, crrSelectedEmp: {} });
    get360Overview(finalFilter, "", "", false);
    handle360GetQuestionFilter(finalFilter, "", "", false);
  }

  handleSelectEmployee = (value) => {
    this.setState({ crrSelectedEmp: value }, () => {
      let emp_id = "";
      if (value && value.emp_id) {
        emp_id = value.emp_id;
      }
      let { onLoadSubReport } = this.props;
      onLoadSubReport(emp_id, 1);
    })


    this.setState({ showReportNow: false });
    setTimeout(() => {
      this.setState({ showReportNow: true });
    }, 200000);


  }


  handleSelectLTHead = (value) => {
    this.setState({ crrSelectedType: value }, () => {
      let emp_id = "";
      if (value && value.emp_id) {
        emp_id = value.emp_id;
      }
      let { onLoadSubReport } = this.props;
      onLoadSubReport(emp_id, 2);
    })


  }



  exportPDFWithComponent = () => {
    if (this.pdfExportComponent.current) {
      this.pdfExportComponent.current.save();
    }
  };

  render() {


    let { getLTHeadIceScoreV2Data, getLTHeadIceParaScoreV2Data, getLTHeadIceSubWiseIceScoreV2Data,
      getSubWiseFeedParaScoreData,
      getSubWiseStrengthImprovementData,
      getSubWiseStrengthImprovementV2Data,
      getLTHeadIceDmgResponsesData,
      getLTHeadIceDmgWiseICENPSData,
      crrSubReportType,
      getIceNPSRowWiseLTHeadData,

      getLTHeadIceScoreV3Data,
      getLTHeadIceParaScoreV3Data,

      getLTHeadIceScoreV4Data,
      getLTHeadIceParaScoreV4Data,

      getLTHeadEmpListData, getSubWiseParaScoreData, getSubWiseParaFeedDistData, getSubWiseFeedAnsCountData, getSubWiseParaFeedDistV2Data, getLTHeadIceScoreData, getLTHeadIceParaScoreData, getLTHeadIceSubWiseIceScoreData,


      getMetaResponseAnalyticsFilterData, getIceIndexData, getIceDistributionData, getIceFeedbackListData,
      getIceCrossDistData, getIceMixHeatMapData, getIceNPSRowWiseData, getIceDistributionV2Data, getIceMixHeatMapV2Data,
      closeReportModal, isCheckSurvey, ParticipationObj } = this.props;



    let { showReportNow } = this.state;

    let { getTemp360EmpStrengthData, surveyLogo, getSurveyData, GetParameterDesc, get360RelationResponseData, getFeedbackAndQuestionNameData, getTextAndQuestionNameData, get360DimensionRelationScoreData, getEMPFilterLabel, CountRestrict, GetParameterLabel, loading, indexType, EmpFilterData, getQuestionData,
      getIndexByFilterDMGData, getIndexFilterData, get360Overview, getRespondantDetailsData, get360DimensionQuestionsData, optionType, getQuestionFilterData, getCommentData, handle360GetQuestionFilter, getAnsweredEmployeesData } = this.props;
    let { FilterValues } = this.state;

    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;

    if (FilterValues && FilterValues.length > 0) {
      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }
    }

    // let colorList = ["#E57373","#BA68C8","#BA68C8","#9575CD","#7986CB","#64B5F6","#4FC3F7","#4DD0E1","#4DB6AC","#81C784","#DCE775","#FFB74D","#FF8A65","#90A4AE","#F06292"]
    let colorList = [
      "#3F51B5", "#1E88E5", "#9C27B0", "#00ACC1", "#EC3472",
      "#FFB74D", "#FF8A65", "#90A4AE", "#996633",
      "#FFD54F", "#E57373", "#81C784", "#64B5F6", "#DCE775",
      "#BA68C8", "#9575CD", "#7986CB", "#4FC3F7", "#4DD0E1"
    ]

    let colorListA = []
    if (getQuestionData && getQuestionData.length > 0) {
      let relation1List = getQuestionData && getQuestionData[0] && getQuestionData[0]["relationList"] ? getQuestionData[0]["relationList"] : [];
      if (relation1List && relation1List.length > 0) {
        relation1List.forEach((element, index) => {
          colorListA.push({
            "relation": element,
            "color": colorList[index] ? colorList[index] : "#FFD54F"
          })
        })
      }
    }

    console.log("{{{{{{{colorListA", colorListA)

    // let colorRelList = []
    // if(RelationList && RelationList.length>0){
    //   RelationList.forEach((item)=>{
    //     colorRelList.push({
    //       "name":item.name,
    //       "color":color
    //     })
    //   })
    // }


    function getColorByRelation(name) {
      let defaultColor = "#FFD54F";
      let getIndex = colorListA && colorListA.length > 0 ? colorListA.findIndex(prev => prev.relation === name) : -1;
      if (getIndex !== -1) {
        defaultColor = colorListA && colorListA[getIndex] && colorListA[getIndex]["color"] ? colorListA[getIndex]["color"] : "#FFD54F"
      }
      return defaultColor
    }

    let SurveyLogo = surveyLogo ? surveyLogo : "/img/logo/happypluslogo.png";

    function sortRelation(list) {
      if (list && list.length > 0) {
        let List1 = list.filter(prev => prev.reviewer_relation === "Self")
        let List2 = list.filter(prev => prev.reviewer_relation !== "Self")
        let List3 = []
        if (List2 && List2.length > 0) {
          let sortingArr = ["Team Member", "Peer", "Skip 1", "Direct Manager"]
          if (sortingArr && sortingArr.length > 0) {
            sortingArr.forEach((ele) => {
              let GetINdex = List2.findIndex(prev => prev.reviewer_relation.trim() === ele.trim());
              if (GetINdex !== -1) {
                List3.push(List2[GetINdex])
              }
            })
          }
        }
        let List4 = List2.filter(x => !List3.includes(x))
        let list5 = List3.concat(List4)
        let listMerged = List1.concat(list5)
        // console.log("List2--->", List2)
        // console.log("List3--->", List3)
        // console.log("List4--->", List4)
        // console.log("list5--->", list5)
        // console.log("listMerged--->", listMerged)
        return listMerged
      } else {
        return []
      }
    }

    let get360RelationResponseD1 = []
    if (get360RelationResponseData && get360RelationResponseData.length > 0) {
      get360RelationResponseData.forEach((element) => {
        if (element && element["completeCount"] > 0) {
          get360RelationResponseD1.push(element)
        }
      })
    }

    let relListSorted = get360RelationResponseD1 && get360RelationResponseD1.length > 0 ?
      sortRelation(get360RelationResponseD1).map((prev) => (prev.reviewer_relation)) : [];

    let surveyId = getSurveyData && getSurveyData.id ? getSurveyData.id.toString() : "";
    let surveyIdMatch = "63d754f650a5f4d496779086";

    let get360DimensionRelationScoreList1 = []
    if (get360DimensionRelationScoreData && get360DimensionRelationScoreData.length > 0) {
      get360DimensionRelationScoreList1 = get360DimensionRelationScoreData.map((element) => (element))
      get360DimensionRelationScoreList1.sort((a, b) => ((a && a.item && a.item._id ? a.item._id : "").charCodeAt(0) - (b && b.item && b.item._id ? b.item._id : "").charCodeAt(0)))
    }

    let DimensionNameList = [];
    if (get360DimensionRelationScoreList1 && get360DimensionRelationScoreList1.length > 0) {
      get360DimensionRelationScoreList1.forEach((item) => {
        let dimension1 = item && item._id ? item._id : "";
        DimensionNameList.push(GetParameterLabel(dimension1));
      })
    }

    let userName = (this.state.crrSelectedEmp && this.state.crrSelectedEmp["name"] ? this.state.crrSelectedEmp["name"] : "");
    let createdAt = moment(Date.now()).format("DD MMMM, YYYY");

    function getScoreValueX(relation, questionX) {
      let scoreMean = 0;
      if (relation !== "Self") {
        let otherSum = 0;
        let otherCount = 0;

        if (questionX && questionX.relationList && questionX.relationList.length > 0) {
          questionX.relationList.forEach((relation) => {
            if (relation !== "Self") {
              otherCount += Number(questionX[relation + "_count"])


              otherSum += Number(questionX[relation + "_sum"]);
            }
          })
        }
        scoreMean = Number((otherSum / otherCount).toFixed(2))
      }
      else {
        scoreMean = Number(questionX["Self_sum"] / questionX["Self_count"]);
      }
      return [Number((scoreMean).toFixed(2))]

    }

    function isShowHiddenStrength(getQX, isStr) {
      let questionTableList = []
      if (getQX && getQX.length > 0) {
        getQX.forEach((element) => {
          let temp = {
            "name": element.name,
            "dimension": element.parameterDimensionName,
            "Self": getScoreValueX("Self", element),
            "Others": getScoreValueX("Others", element)
          }
          questionTableList.push(temp)
        })
      }


      let listXY = []
      if (questionTableList && questionTableList.length > 0) {
        questionTableList.forEach((element) => {
          if (isStr) {
            if (element.Self < element.Others) {
              listXY.push(element)
            }
          } else {
            if (element.Self > element.Others) {
              listXY.push(element)
            }
          }
        })
      }
      if (listXY && listXY.length > 0) {
        return false
      } else {
        return true
      }
    }


    let tableOfContent = [
      {
        "sno": "1",
        "name": "Introduction",
        "children": []
      },
      {
        "sno": "2",
        "name": "Respondent Summary",
        "children": []
      },
      {
        "sno": "3",
        "name": "Competency Summary",
        "children": []
      },
      {
        "sno": "4",
        "name": "Competency Summary (Bar Chart)",
        "children": []
      },
      {
        "sno": "5",
        "name": "Competency Wise Evaluation Summary",
        "children": [
          {
            "sno": "1",
            "name": "Strengths",
            "hide": 0
          },
          {
            "sno": "2",
            "name": "Areas of Development",
            "hide": 0
          },
          {
            "sno": "3",
            "name": "Hidden Strengths",
            "hide": isShowHiddenStrength(getQuestionData, true) ? 1 : 0
          },
          {
            "sno": "4",
            "name": "Blind Spots",
            "hide": 0
          }
        ]
      },
      {
        "sno": "6",
        "name": "Open Ended Feedback",
        "children": []
      },
      {
        "sno": "7",
        "name": "Detailed Feedback",
        "children": []
      },
      {
        "sno": "8",
        "name": "Guiding Principles",
        "children": []
      },
      {
        "sno": "9",
        "name": "Development Action Plan",
        "children": []
      }
    ]

    let tableOfContent_v2 = []
    if (tableOfContent && tableOfContent.length > 0) {
      tableOfContent.forEach((ele) => {
        let temp = {
          "sno": ele.sno,
          "name": ele.name,
          "children": ele && ele.children && ele.children.length > 0 ? ele.children.filter(prev => prev.hide !== 1) : []
        };
        tableOfContent_v2.push(temp)
      })
    }


    let { crrSelectedType } = this.state;


    return (

      <div className="fixed top-0 bottom-0 left-0 right-0 z-40 w-full h-full bg-black bg-opacity-60" >
        <div className="flex items-start justify-center h-screen p-4 overflow-hidden">

          <div className="mx-auto overflow-hidden overflow-y-auto bg-white rounded-md 2xl:w-3/6 xl:w-4/6 lg:w-5/6 customscroll5" style={{ height: 'calc(100vh - 4rem)' }} >

            <div className="sticky top-0 z-20 flex flex-col items-center justify-between p-4 px-6 bg-white border-b md:flex-row"  >
              <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B] line-clamp-1">
                Pulse {(crrSelectedType && crrSelectedType.name ? crrSelectedType.name : "LT Head")} Report
              </h1>

              <div className='flex items-center space-x-4'>
                {/* <div onClick={() => this.exportPDFWithComponent()} className="flex items-center p-2 px-4 text-sm text-blue-500 bg-blue-100 rounded-full cursor-pointer " >
                  <span class="material-symbols-outlined mr-2 " >
                    file_downloa
                  </span> Download Report
                </div> */}


                {showReportNow || true? (
                  <button onClick={() => this.exportPDFWithComponent()} className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2"><svg xmlns="http://www.w3.org/2000/svg" class="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M14 3v4a1 1 0 0 0 1 1h4"></path><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path><path d="M10 12l4 4m0 -4l-4 4"></path></svg><span> Download Report</span></button>
                ) : (
                  <div className="px-2 py-1 text-blue-500">
                    ....loading report
                  </div>
                )}



                <div onClick={() => closeReportModal(false)} className="inline-flex items-center justify-center w-10 h-10 text-gray-500 bg-gray-100 rounded-full cursor-pointer hover:text-gray-800" >
                  <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                  </svg>
                </div>
              </div>

            </div>

            <div className=''>
              <div className="flex-wrap items-center justify-between w-full gap-4 px-6 py-4 space-y-4 md:flex md:space-y-0">
                {/* <Filter
                  showFilter={this.state.showFilter}
                  showValue={this.state.showValue}
                  closeFilter={this.closeFilter}

                  EmpFilterData2={EmpFilterData2}
                  getIndexFilterData={getIndexByFilterDMGData}
                  handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
                  getEMPFilterLabel={getEMPFilterLabel}
                /> */}

                <div />

                <div className='flex space-x-3'>
                  <LTHeadEmployeeDropdown
                    getAnsweredEmployeesData={
                      [
                        {
                          emp_id: "LT Head",
                          name: "LT Head"
                        },
                        {
                          emp_id: "Unit Head",
                          name: "Unit Head"
                        },
                        {
                          emp_id: "Unit",
                          name: "Unit"
                        }
                      ]
                    }
                    crrSelectedEmp={this.state.crrSelectedType}
                    handleSelectEmployee={this.handleSelectLTHead}
                  />
                  <EmployeeDropdown
                    getAnsweredEmployeesData={getLTHeadEmpListData}
                    crrSelectedEmp={this.state.crrSelectedEmp}
                    handleSelectEmployee={this.handleSelectEmployee}
                  />
                </div>
              </div>

              <PDFExport ref={this.pdfExportComponent}
                pageTemplate={(pageObj) =>
                  <PageTemplate
                    pageNum={pageObj.pageNum}
                    surveyLogo={surveyLogo}
                  />}
                scale={0.7}
                forcePageBreak=".page-break"
                paperSize="A4"
                margin={0}
                fileName={`Pulse ` + crrSubReportType + ` Report ${userName} - ${createdAt}`}
                author="Happyplus Team"
                landscape={false}
              >


                {!userName ?
                  <>
                    <div className='py-24 text-2xl font-bold text-center text-gray-500'>{"Please Select " + (crrSelectedType && crrSelectedType.name ? crrSelectedType.name : "LT Head") + " Users."}</div>
                  </>
                  :
                  <>

                    <section className='w-full flex pt-8 flex-col justify-between'>
                      <IntroPage
                        createdAt={createdAt}
                        userName={userName}
                      />
                    </section>

                    <ResponsePage
                      ParticipationObj={ParticipationObj}
                      list={getLTHeadIceDmgResponsesData}
                      createdAt={createdAt}
                      userName={userName}
                    />

                    <ResponsePageV2
                      ParticipationObj={ParticipationObj}
                      list={getLTHeadIceDmgResponsesData}
                      dataList={getLTHeadIceDmgWiseICENPSData}
                      createdAt={createdAt}
                      userName={userName}
                    />


                    <ICEIndexSummary
                      ParticipationObj={ParticipationObj}
                      list={getMetaResponseAnalyticsFilterData}
                      createdAt={createdAt}
                      userName={userName}
                      getLTHeadIceScoreData={getLTHeadIceScoreData}
                      getLTHeadIceParaScoreData={getLTHeadIceParaScoreData}
                      getLTHeadIceSubWiseIceScoreData={getLTHeadIceSubWiseIceScoreData}
                      GetParameterLabel={GetParameterLabel}
                      getIceIndexData={getIceIndexData}
                      IndexNumber={"11"}
                      getIceDistributionData={getIceDistributionData}
                      crrSubReportType={crrSubReportType}
                      getLTHeadIceScoreV3Data={getLTHeadIceScoreV3Data}
                      getLTHeadIceParaScoreV3Data={getLTHeadIceParaScoreV3Data}
                    />

                    <IndexThemesDistribution
                      getSubWiseParaFeedDistData={getSubWiseParaFeedDistData}
                      createdAt={createdAt}
                      userName={userName}
                      GetParameterLabel={GetParameterLabel}
                    />

                    <OrgThemesSelfCross
                      getSubWiseFeedAnsCountData={getSubWiseFeedAnsCountData}
                      createdAt={createdAt}
                      userName={userName}
                      GetParameterLabel={GetParameterLabel}
                      mode={"Top"}
                    />

                    <OrgThemesSelfCross
                      getSubWiseFeedAnsCountData={getSubWiseFeedAnsCountData}
                      createdAt={createdAt}
                      userName={userName}
                      GetParameterLabel={GetParameterLabel}
                      mode={"Bottom"}
                    />

                    <NPSDistributionOverall
                      getSubWiseFeedParaScoreData={getSubWiseFeedParaScoreData}
                      createdAt={createdAt}
                      userName={userName}
                      title={"eNPS"}
                      GetParameterLabel={GetParameterLabel}
                      getIceNPSRowWiseData={getIceNPSRowWiseData}
                      crrSubReportType={crrSubReportType}
                      getIceNPSRowWiseLTHeadData={getIceNPSRowWiseLTHeadData}
                    />

                    <NPSDistributionSubDepartment
                      getSubWiseFeedParaScoreData={getSubWiseFeedParaScoreData}
                      createdAt={createdAt}
                      userName={userName}
                      title={"eNPS"}
                      GetParameterLabel={GetParameterLabel}
                      crrSubReportType={crrSubReportType}
                      getIceNPSRowWiseData={getIceNPSRowWiseData}
                      getIceNPSRowWiseLTHeadData={getIceNPSRowWiseLTHeadData}
                    />

                    {/* <NPSDistributionSubDepartment
                      getSubWiseFeedParaScoreData={getSubWiseFeedParaScoreData}
                      createdAt={createdAt}
                      userName={userName}
                      title={"eNPS (Self)"}
                      isSelf={true}
                    />

                    <NPSDistributionSubDepartment
                      getSubWiseFeedParaScoreData={getSubWiseFeedParaScoreData}
                      createdAt={createdAt}
                      userName={userName}
                      title={"eNPS (Excluding Self)"}
                      isSelf={false}
                    /> */}
                    {/* 
                    <ICEIndexSummary
                      ParticipationObj={ParticipationObj}
                      list={getMetaResponseAnalyticsFilterData}
                      createdAt={createdAt}
                      userName={userName}
                      getLTHeadIceScoreData={getLTHeadIceScoreData}
                      getLTHeadIceParaScoreData={getLTHeadIceParaScoreData}
                      getLTHeadIceSubWiseIceScoreData={getLTHeadIceSubWiseIceScoreData}
                    /> */}

                    <MNGRIndexSummary
                      ParticipationObj={ParticipationObj}
                      list={getMetaResponseAnalyticsFilterData}
                      createdAt={createdAt}
                      userName={userName}
                      getLTHeadIceScoreData={getLTHeadIceScoreV2Data}
                      getLTHeadIceParaScoreData={getLTHeadIceParaScoreV2Data}
                      getLTHeadIceSubWiseIceScoreData={getLTHeadIceSubWiseIceScoreV2Data}
                      GetParameterLabel={GetParameterLabel}
                      getIceIndexData={getIceIndexData}
                      IndexNumber={"12"}
                      getIceDistributionData={getIceDistributionV2Data}
                      crrSubReportType={crrSubReportType}

                      getLTHeadIceScoreV4Data={getLTHeadIceScoreV4Data}
                      getLTHeadIceParaScoreV4Data={getLTHeadIceParaScoreV4Data}
                    />

                    {/* <FeedbackResponseSubDepartment
                      getSubWiseParaScoreData={getSubWiseParaScoreData}
                      createdAt={createdAt}
                      userName={userName}
                      title={"Open Ended Feedback"}
                      GetParameterLabel={GetParameterLabel}
                    /> */}


                    <FeedbackResponseTopicDesc
                      getSubWiseParaScoreData={getSubWiseStrengthImprovementData}
                      createdAt={createdAt}
                      userName={userName}
                      title={"Open Ended Feedback (Strengths)"}
                      GetParameterLabel={GetParameterLabel}
                      type={"Strengths"}
                    />

                    <FeedbackResponseTopicDesc
                      getSubWiseParaScoreData={getSubWiseStrengthImprovementV2Data}
                      createdAt={createdAt}
                      userName={userName}
                      title={"Open Ended Feedback (Improvement)"}
                      GetParameterLabel={GetParameterLabel}
                      type={"Improvement"}
                    />

                  </>}



              </PDFExport>
            </div>

          </div>
        </div>
      </div>

    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SubHitachiReport);

