import React, { Component } from 'react';
import { connect } from 'react-redux';

import MultiBarChart from './Charts/MultiBarChart';
// import RadarChartPage1 from './Charts/RadarChartPage1';
import BarDistribution1 from './Charts/BarDistribution1';



// import EmployeeDropdown from '../Filter/EmployeeDropdown';
// import Filter from './Filter/Filter';


class RespondantSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',

      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: '',
      crrSelectedEmp: {}
    }
  }

  componentDidMount() {

  }

  render() {


    let { GetParameterLabel, get360RelationResponseData, getColorByRelation, sequence_rel, renameWords } = this.props;


    function getCompletionRate(element) {
      let value = 0
      let sentCount = element["sentCount"];
      let completeCount = element["completeCount"];
      value = (completeCount * 100) / sentCount
      value = value.toFixed(2)
      return value ? Number(value) : 0
    }

    function sortRelation(list) {
      if (list && list.length > 0) {
        let List1 = list.filter(prev => prev.reviewer_relation === "Self")
        let List2 = list.filter(prev => prev.reviewer_relation !== "Self")
        let listMerged = List1.concat(List2)
        return listMerged
      } else {
        return []
      }
    }

    //console.log("p1 get360RelationResponseData-->",get360RelationResponseData)
    let newList = sortRelation(get360RelationResponseData);


    function getColor(value) {
      let color = "#757575";
      if (value >= 0 && value <= 25) {
        color = "#EF5350"
      } else if (value > 25 && value <= 50) {
        color = "#FFC107"
      } else if (value > 50 && value <= 75) {
        color = "#8BC34A"
      } else if (value > 75 && value <= 100) {
        color = "#4CAF50"
      }
      return color
    }


    function getRandomColor() {
      let colorList = ["#E57373", "#F06292", "#BA68C8", "#BA68C8", "#9575CD", "#7986CB", "#64B5F6", "#4FC3F7", "#4DD0E1", "#4DB6AC", "#81C784", "#DCE775", "#FFB74D", "#FF8A65", "#90A4AE", "#757575"]
      var color = colorList[Math.floor(Math.random() * colorList.length)];
      return color
    }

    let { userName, createdAt } = this.props;

    const sort2Order = (list, sequence) => {
      console.log('Sorting list based on sequence:', sequence);
      if (list && list.length > 0) {
        return list.sort((a, b) => sequence.indexOf(a.reviewer_relation) - sequence.indexOf(b.reviewer_relation));
      }
      return [];
    };

    return (
      <section className='p-6 px-6 flex flex-col justify-between page-break'>
        {/* report header */}
        <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
          <p className='font-medium italic text-base '>360 Feedback</p>
          <p className='font-medium italic text-base'>{userName} | {createdAt}</p>
        </div>
        <div className='space-y-4 flex flex-col  '>
          <h1 className='text-2xl text-[#212121] font-medium capitalize'>respondent Summary </h1>
          <div className='space-y-4 text-sm text-slate-800'>
            <p className='text-[#212121]/80 italic'> The following is a summary of the group of respondents who were invited to participate and provide feedback for you.</p>

            <table className='w-full border-collapse '>
              <thead className='text-sm  capitalize bg-[#8BC34A]/25'>
                <tr className='border-b-2 border-white'>
                  <th className='text-[#212121] font-medium p-4 py-3 text-sm text-left border-r-2 border-white'>
                    Relationship
                  </th>
                  <th className='text-[#212121] font-medium p-4 py-3 text-sm text-left border-r-2 border-white'>
                    Nominated
                  </th>
                  <th className='text-[#212121] font-medium p-4 py-3 text-sm text-left border-r-2 border-white'>
                    Completed
                  </th>
                  <th className='text-[#212121] font-medium p-4 py-3 text-sm text-left border-r-2 border-white'>
                    Completion Rate
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y-2 divide-white '>
                {newList && newList.length > 0 ?
                  sort2Order(newList, sequence_rel).map((element) =>

                    <tr className='even:bg-gray-100 odd:bg-gray-50'>
                      <td className='p-2 px-4 border-r-2 border-white'>
                        <div className='flex items-center space-x-3'>
                          <div className='flex items-center justify-center w-8 h-8 text-base text-white uppercase bg-yellow-500 rounded-full'
                            style={{ background: getColorByRelation(element["reviewer_relation"]) }}>
                            {element && element["reviewer_relation"] ? element["reviewer_relation"][0] : ""}
                          </div>
                          <p className='font-medium '>{renameWords(element["reviewer_relation"])}</p>
                        </div>
                      </td>
                      <td className='p-2 px-4 text-center border-r-2 border-white'>
                        {element["sentCount"]}
                      </td>
                      <td className='p-2 px-4 text-center border-r-2 border-white'>
                        {element["completeCount"]}
                      </td>
                      <td className='p-2 px-4 '>
                        <div className='flex items-center space-x-2 justify-left h-fit'>
                          <div className='w-full h-5 overflow-hidden bg-gray-200'>
                            <div className='w-3/6 h-5 bg-green-500' style={{ width: (getCompletionRate(element).toString() + "%"), background: getColor(getCompletionRate(element)) }} >
                            </div>
                          </div>
                          <p className='font-medium w-fit'>{getCompletionRate(element).toString() + "%"}</p>
                        </div>
                      </td>
                    </tr>

                  ) : null}

              </tbody>

            </table>




          </div>


        </div>

        {/* <div className='flex items-center justify-between py-4'>
                  <img src="/img/logo/happypluslogo.png" class=" w-24 object-cover" alt="default_survey_logo" />
                  <p className='text-slate-600'>Page 1</p>
                  <div/>
                </div> */}

      </section>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(RespondantSummary);
