import React, { Component } from "react";
import { connect } from "react-redux";
import "tippy.js/dist/tippy.css";
import HorizontalBar from "./Charts/HorizontalBar";

class ClusterChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedText: "",
    };
  }

  componentDidMount() {}

  handleKeyword = (data) => {
    let { handleKeywordCall } = this.props;
    handleKeywordCall(data.text);
    this.setState({ SelectedText: data.text + " (" + data.value + ")" });
  };

  handleKeywordTooltip = (data) => {
    this.setState({ SelectedText: data.text + " (" + data.value + ")" });
  };

  render() {
    let {
      getClusterLabel,
      clusterNumber,
      getTextClusterSentimentCountData,
      wordCloudError,
      wordCloudData,
      loading,
      getTextClusterData,
      getKeywordsByCluster,
    } = this.props;

    let max = 0;

    let getTextClusterList = [];
    if (getTextClusterData && getTextClusterData.length > 0) {
      getTextClusterData.map((element) => {
        getTextClusterList.push(element);

        if (element.count > max) {
          max = element.count;
        }
      });

      getTextClusterList.sort((a, b) => a._id - b._id);
    }

    function spliceDecimal(no) {
      if (no) {
        let str = no.toString();
        let first = str.split(".")[0];
        let second = str.split(".")[1];
        let second2 = second ? second.substring(0, 2) : "";
        let final = first + "." + second2;
        if (second2) {
          return parseFloat(final).toFixed(2);
        } else {
          return no;
        }
      } else {
        return 0;
      }
    }

    let getTCSCData = getTextClusterSentimentCountData;

    function getSentimentSummary(element, list) {
      let clusterNo = element && element._id ? element._id : 0;
      let sentiment = {
        total: 0,
      };
      if (list && list.length > 0) {
        let getIndex = list.findIndex((prev) => prev.cluster === clusterNo);
        if (getIndex !== -1) {
          sentiment =
            list &&
            list[getIndex] &&
            list[getIndex]["sentiment"] &&
            list[getIndex]["sentiment"][0]
              ? list[getIndex]["sentiment"][0]
              : 0;
        }
      }

      if (sentiment && sentiment.total > 0) {
        return {
          total: sentiment["total"],
          positive: sentiment["positive"],
          negative: sentiment["negative"],
          neutral: sentiment["neutral"],

          positivePerc: sentiment["positive"]
            ? spliceDecimal((sentiment["positive"] * 100) / sentiment["total"])
            : 0,
          negativePerc: sentiment["negative"]
            ? spliceDecimal((sentiment["negative"] * 100) / sentiment["total"])
            : 0,
          neutralPerc: sentiment["neutral"]
            ? spliceDecimal((sentiment["neutral"] * 100) / sentiment["total"])
            : 0,
        };
      } else {
        return {
          total: "-",
          positive: "-",
          negative: "-",
          neutral: "-",

          positivePerc: "-",
          negativePerc: "-",
          neutralPerc: "-",
        };
      }
    }

    return (
      <>
        <div className="bg-white hover:shadow-lg duration-150 transition  border p-6 rounded-lg space-y-6   cursor-pointer">
          <h1 className="text-sm text-gray-500 ">
            <b className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap ">
              Text Cluster
              <span className="text-blue-500 hidden">
                {
                  " ( A text cluster is a collection or cluster of texts depicted in different sizes. The bigger and bolder the text appears, the more often it’s mentioned within a given text and the more important it is,on right side words with count bar graph is there. ) "
                }
              </span>
            </b>
          </h1>

          {/* chart */}

          {true ? (
            <div className="overflow-x-auto whitespace-nowrap ">
              <table className="w-full border ">
                <thead>
                  <tr className="bg-[#F9FAFB] border-b text-sm   text-[#3D405B] capitalize">
                    <th className="p-4 border-r font-semibold  2xl:w-[25%] text-left ">
                      Cluster
                    </th>
                    <th className="p-4 border-r font-semibold  text-left 2xl:w-[30%] W-[25%]">
                      Number of feedbacks
                    </th>
                    <th className="p-4 border-r font-semibold  text-green-500 bg-[#E9F9EF] w-[15%]">
                      Positive
                    </th>
                    <th className="p-4 border-r font-semibold  text-red-500 bg-[#FFEBEE] w-[15%]">
                      Negative
                    </th>
                    <th className="p-4 border-r font-semibold  text-yellow-500 bg-[#FFF8E1] w-[15%]">
                      Neutral
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {getTextClusterList && getTextClusterList.length > 0
                    ? getTextClusterList.map((element, index) => (
                        <tr
                          key={index}
                          className="bg-white  mb-10 lg:mb-0 text-sm text-[#3D405B]"
                        >
                          <td className="px-2 border border-b  text-center font-bold whitespace-nowrap">
                            <div
                              className="flex items-center space-x-2 "
                              onClick={() =>
                                getKeywordsByCluster(
                                  element && element._id ? element._id : 0
                                )
                              }
                            >
                              <button>
                                {(element && element._id
                                  ? element._id
                                  : "-") === clusterNumber ? (
                                  <span class="material-symbols-outlined text-[1.3rem] text-[#2196F3] mt-0.5">
                                    radio_button_checked
                                  </span>
                                ) : (
                                  <span class="material-symbols-outlined text-[1.3rem] text-[#3D405b]/50 mt-0.5">
                                    radio_button_unchecked
                                  </span>
                                )}
                              </button>
                              <p className="font-normal">
                                {getClusterLabel(
                                  element && element._id ? element._id : 0
                                )}
                              </p>
                            </div>
                          </td>
                          <td className="border border-b cursor-pointer ">
                            <HorizontalBar
                              count={
                                element && element.count ? element.count : 0
                              }
                              max={max}
                            />
                          </td>

                          <td className=" border border-b  text-center">
                            {getSentimentSummary(element, getTCSCData)[
                              "positivePerc"
                            ] + "%"}
                          </td>
                          <td className=" border border-b  text-center">
                            {getSentimentSummary(element, getTCSCData)[
                              "negativePerc"
                            ] + "%"}
                          </td>
                          <td className=" border border-b  text-center">
                            {getSentimentSummary(element, getTCSCData)[
                              "neutralPerc"
                            ] + "%"}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          ) : (
            <>
              {!loading ? (
                <div className="text-center text-2XL text-gray-400 py-20">
                  {"Error"}
                </div>
              ) : null}
            </>
          )}
        </div>

        {/* 
                    <div className="flex justify-between items-center">
                    <h1 className="text-sm text-gray-900 font-semibold">Word Cloud
                    <span className="text-blue-500 font-normal">{" ( A word cloud is a collection or cluster of words depicted in different sizes. The bigger and bolder the word appears, the more often it’s mentioned within a given text and the more important it is,on right side words with count bar graph is there. ) "}</span>
                    </h1>
                    </div>
                    <div style={{height:'400px',marginTop:'10px'}}>
                      {words && words.length>0?
                        <ReactWordcloud 
                        callbacks={callbacks}
                        options={options}
                        words={words} 
                        />
                      :
                <>
                {!loading?
                <div className="text-center text-3xl text-gray-400 pt-40">We have not calculated this section!</div>:null}
                </>
                      }
                    </div>
                    <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div>

             */}
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn,
  };
}
export default connect(mapStateToProps)(ClusterChart);
