
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const growService = {
    logout,
    getGrowMonth,
    startGrowMonth,
    getGrowWeekListbyMonthId,
    getGrowDayListbyWeekId,
    getGrowDayDatabyDayId,
    startGrowDay,
    startGrowWeek,
    startGrowDayAction,
    startGrowDayReflection,
    submitGrowWeek,

    getAllGrowNote,
    submitGrowDay,
    startGrowDayKnowledge,
    getGrowParameterList,
    
    updateEmployeeSubactivityId,
    getSubactivityActivityId,
    updatecomment,

    getGrowPlusBucket_User,
    getGrowPlusMonth_User,
    getGrowPlusDay_User,
    getGrowPlusTask_User,
    saveGrowPlusTask_User,
    getGrowPlusTaskStatus_User,
    forceGrowPlusTaskComplete_User
};


async function forceGrowPlusTaskComplete_User(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/forceGrowPlusTaskComplete_User`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                forceGrowPlusTaskComplete_UserData: data.data
            }
            return ProfileObj;
        });
}

async function getGrowPlusTaskStatus_User(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getGrowPlusTaskStatus_User`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getGrowPlusTaskStatus_UserData: data.data
            }
            return ProfileObj;
        });
}

async function getGrowPlusTask_User(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getGrowPlusTask_User`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getGrowPlusTask_UserData: data.data
            }
            return ProfileObj;
        });
}

async function saveGrowPlusTask_User(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/saveGrowPlusTask_User`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                saveGrowPlusTask_UserData: data.data
            }
            return ProfileObj;
        });
}


async function getGrowPlusDay_User(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getGrowPlusDay_User`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getGrowPlusDay_UserData: data.data
            }
            return ProfileObj;
        });
}

async function getGrowPlusMonth_User(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getGrowPlusMonth_User`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getGrowPlusMonth_UserData: data.data
            }
            return ProfileObj;
        });
}

async function getGrowPlusBucket_User(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getGrowPlusBucket_User`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getGrowPlusBucket_UserData: data.data
            }
            return ProfileObj;
        });
}

function logout() {
    localStorage.removeItem('adminuser');
    window.location.href = "#/login";
}


function handleResponse(response) {
    // ////////console.log("response22222   ", response);
 
     return response.text().then(text => {
         const data = text && JSON.parse(text);
         if (!response.ok) {
             if (response.status === 401) {
                 // auto logout if 401 response returned from api
                 logoutSession();
                 //location.reload(true);
             }
             // ////////console.log("datadatadata ", response);
 
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         //////////console.log("datadatadatadatadata   ", data.error);
         if (data.error) {
             // ////////console.log("datadatadatadatadata   ", data);
             if (data.code===3) {
                 logoutSession();
             }
             if(data && data.message && (data.message === "UnauthorizedError")){
                logout()

             }
//              else if (data.code===1) {
//                 logoutSession();
//             }
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         return data;
     });
 }
 
 

function handleResponse2(response) {
    // ////////console.log("response22222   ", response);
 
     return response.text().then(text => {
         const data = text && JSON.parse(text);
         if (!response.ok) {
             if (response.status === 401) {
                 // auto logout if 401 response returned from api
                 logout();
                 //location.reload(true);
             }
             // ////////console.log("datadatadata ", response);
 
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         //////////console.log("datadatadatadatadata   ", data.error);
         if (data.error) {
             // ////////console.log("datadatadatadatadata   ", data);
             if (data.code===3) {
                 logout();
             }
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         return data;
     });
 }
 



function logoutSession(data) {

    let header = new Headers({
            'Content-Type': 'application/json',
            'Authorization': authHeader().Authorization
        });
        const requestOptions = {
            method: "POST",
            headers: header,
            body: JSON.stringify(data)
        }
        return fetch(CONST.BACKEND_URL + `/userlogout`, requestOptions)
            .then(handleResponse2)
            .then(user => {
                let userObj = {
                    userLogout: user
                }
                localStorage.removeItem('adminuser');
                window.location.href = "#/login";
                return userObj;
            });
}



async function startGrowDayAction(data) {
    ////console.log("Data Ready For API: ", data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/startGrowDayAction`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let obj = {
                startGrowDayAction: data.data
            }
            // //console.log("startGrowDayActionstartGrowDayAction", obj);
            return obj;
        });
}


async function startGrowDayReflection(data) {
    ////console.log("Data Ready For API: ", data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/startGrowDayReflection`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let obj = {
                startGrowDayReflection: data.data
            }
          
            return obj;
        });
}

async function startGrowDayKnowledge(data) {
    ////console.log("Data Ready For API: ", data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/startGrowDayKnowledge`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let obj = {
                startGrowDayKnowledge: data.data
            }
          
            return obj;
        });
}

async function startGrowDay(data) {
    ////console.log("Data Ready For API: ", data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/startGrowDay`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let obj = {
                startGrowDay: data.data
            }
            // //console.log("getgetGrowDayDataetGrowDayDatabyDayId", obj);
            return obj;
        });
}

async function startGrowWeek(data) {
    ////console.log("Data Ready For API: ", data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/startGrowWeek`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let obj = {
                startGrowWeek: data.data
            }
            // //console.log("getstartGrowWeekDataetGrowDayDatabyDayId", obj);
            return obj;
        });
}

async function getGrowDayDatabyDayId(data) {
    ////console.log("Data Ready For API: ", data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getGrowDayDatabyDayId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let growparameterObj = {
                getGrowDayDatabyDayId: data.data
            }
            // //console.log("getgetGrowDayDatabyDayIdgetGrowDayDatabyDayIdGrowDayDatabyDayIdgetGrowDayDatabyDayIdgetGrowDayDatabyDayId", growparameterObj);
            return growparameterObj;
        });
}

async function getGrowMonth(data) {
    ////console.log("Data Ready For API: ", data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getGrowMonth`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let growparameterObj = {
                getMonth: data.data
            }
            // //console.log("getMonthgetMonthgetMonthgetMonthgetMonth", growparameterObj);
            return growparameterObj;
        });
}

async function getGrowDayListbyWeekId(data) {
    //console.log("Data Ready For API___________getGrowDayListbyWeekId::: ", data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getGrowDayListbyWeekId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let growparameterObj = {
                getGrowDayListbyWeekId: data.data
            }
            // //console.log("getMonthgetMonthgetMonthgetMonthgetMonth", growparameterObj);
            return growparameterObj;
        });
}

async function getGrowWeekListbyMonthId(data) {
    //console.log("Data Ready For API: ", data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getGrowWeekListbyMonthId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let growparameterObj = {
                getGrowWeekListbyMonthId: data.data
            }
            // //console.log("getGrowWeekListbyMonth", growparameterObj);
            return growparameterObj;
        });
}

async function startGrowMonth(data) {
    ////console.log("Data Ready For API: ", data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/startGrowMonth`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let growparameterObj = {
                startGrowMonth: data.data
            }
            // //console.log("startGrowMonthstartGrowMonthstartGrowMonth", growparameterObj);
            return growparameterObj;
        });
}


async function getGrowParameterList(data) {
    ////console.log("Data Ready For API: ", data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getGrowParameterList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let growparameterObj = {
                allGrowParametersList: data.data
            }
            //console.log("growparameterObjgrowparameterObjgrowparameterObj", growparameterObj);
            return growparameterObj;
        });
}

async function submitGrowDay(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/submitGrowDay`, requestOptions)
        .then(handleResponse)
    
        .then(data => {
           
            let bucketObj = {
                submitGrowDay: data.data
            }
            // //console.log();
            return bucketObj;
        });
}


async function submitGrowWeek(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/submitGrowWeek`, requestOptions)
        .then(handleResponse)
    
        .then(data => {
           
            let bucketObj = {
                submitGrowWeek: data.data
            }
            //console.log("submitGrowWeek",bucketObj);
            return bucketObj;
        });
}

async function getAllGrowNote(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getAllGrowNote`, requestOptions)
        .then(handleResponse)
    
        .then(data => {
           
            let bucketObj = {
                getAllGrowNote: data.data
            }
            // //console.log();
            return bucketObj;
        });
}

function updateEmployeeSubactivityId(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateEmployeeSubactivityId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let bucketObj = {
                updateEmployeeTrackerData: data.data
            }
            // //console.log();
            return bucketObj;
        });
}

function getSubactivityActivityId(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getSubactivityActivityId`, requestOptions)
        .then(handleResponse)
    
        .then(data => {
           
            let bucketObj = {
                getSubactivityActivityId: data.data
            }
            //console.log("bucketObj",bucketObj);
            return bucketObj;
        });
}

function updatecomment(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updatecomment`, requestOptions)
        .then(handleResponse)
    
        .then(data => {
           
            let bucketObj = {
                updatecomment: data.data
            }
            //console.log("bucketObj",bucketObj);
            return bucketObj;
        });
}
