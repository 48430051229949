import React, { Component } from "react";
import { connect } from "react-redux";

function GuidingPrinciples(props) {


  let { userName, createdAt } = props;



  return (
    <section className="p-6 px-6 flex flex-col justify-between page-break">
       {/* report header */}
       <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>360 Feedback</p>
                    <p className='font-medium italic text-base'>{userName} | {createdAt}</p>
                  </div>
      <div className="space-y-4 flex flex-col  ">
        <h1 className="text-2xl text-[#212121] font-medium capitalize">
          Guiding Principles
        </h1>

        <div className="text-slate-800 space-y-4 text-sm">
          <p className="italic ">
            Individual development is critical to deliver best performance each
            time and lead a thriving life experience.
          </p>
        </div>

        <div>
          <div className="text-sm text-slate-800">

            <div className="flex ">
                <img src={"/img/icon/dot.png"} style={{height:'2px',weigh:'2px'}}  className="w-1 h-1 p-1 rounded-full bg-gray-900 mt-1.5"/>   
                <p className="mx-2">{"Development starts with the self understanding. Primary responsibility for the development rests with the individual, the organization supports and fosters it."}</p>
            </div>
            <div className="flex">
                <img src={"/img/icon/dot.png"} style={{height:'2px',weigh:'2px'}} className="w-1 h-1 p-1 rounded-full bg-gray-900 mt-1.5"/>   
                <p className="mx-2">{"Development is a process and a journey, not an event."}</p>
            </div>

          </div>
        </div>

        <div className="bg-red-100 flex justify-start items-center p-4 px-6 space-x-6">
          <div className="w-28 h-28 rounded-full bg-white flex items-center  justify-center  ">
          <img src="/img/no_development.png" className="w-20 h-20"/> 
          </div>
          <p className="text-lg text-slate-800 uppercase flex-1 font-semibold ">
            No development can take place unless the Individual want  it and work it and work toward it .
          </p>
        </div>
        <div className="bg-green-100 flex justify-start items-center p-4 px-6 space-x-6">
          <div className="w-28 h-28 rounded-full bg-white flex items-center  justify-center  ">
          <img src="/img/manage_strengths_vs_weakness.png" className="w-20 h-20"/>

            
          </div>
          <p className="text-lg text-slate-800 uppercase flex-1 font-semibold ">
            strength vs weakness, focus  on  strength and Manage weakness.
          </p>
        </div>
        <div className="bg-blue-100 flex justify-start items-center p-4 px-6 space-x-6">
          <div className="w-28 h-28 rounded-full bg-white flex items-center  justify-center  ">
          <img src="/img/repeating.png" className="w-20 h-20"/>

           
          </div>
          <p className="text-lg text-slate-800 uppercase flex-1 font-semibold ">
            Best way of learning is by doing reflecting and again doing
          </p>
        </div>
      </div>
    </section>
  );
}
export default GuidingPrinciples;
