import React, { Component } from 'react';
import { connect } from 'react-redux';
import Summary from './Components/Summary/Summary';

class HappinessIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionType: 1
    }
  }
  componentDidMount() {

  }

  onOptionType = (type) => {
    this.setState({ optionType: type });

    if (type === 2 || type === 5) {
      let { handleQuestion } = this.props;
      handleQuestion();
    }

    if (type === 3) {
      let { handleQuestionMeanScore, EmpFilterData } = this.props;
      if (EmpFilterData && EmpFilterData.length > 0) {
        let level = EmpFilterData[0].name;
        handleQuestionMeanScore(level);
      }
    }

  }


  onSwitchOptionScreen = () => {
    let { getQuestionNPSResultData, getQuestionOthersResultData, handleOthersFilterCall, getQuestionOthersResultFilterData,
      currentQuestionId, getIndexFilterData, EmpFilterData } = this.props;

    let { optionType } = this.state;

    switch (optionType) {
      case 1: return <Summary
        getQuestionOthersResultFilterData={getQuestionOthersResultFilterData}
        getQuestionNPSResultData={getQuestionNPSResultData} getQuestionOthersResultData={getQuestionOthersResultData}
        handleOthersFilterCall={handleOthersFilterCall} currentQuestionId={currentQuestionId} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}



      />
      // case 4: return <DriverSummary  getMeanScoreQwiseData={getMeanScoreQwiseData} OverviewList={OverviewList}
      // cardType={cardType} ItemSaveData = {ItemSaveData} EmpFilterData={EmpFilterData} handleDemographicFilter={handleDemographicFilter} getMeanScoreData={getMeanScoreData} 
      // />
      // case 5: return <DimensionPriority 
      // cardType={cardType} getQuestionDistOverallData={getQuestionDistOverallData} getIndexFilterData={getIndexFilterData} handleResponseDistributionFilter={handleResponseDistributionFilter} ItemSaveData = {ItemSaveData} getQuestionDistData = {getQuestionDistData} EmpFilterData={EmpFilterData} getMeanScoreQwiseData={getMeanScoreQwiseData}
      // />
      // case 2: return <ResponseDistribution 
      // cardType={cardType} getQuestionDistOverallData={getQuestionDistOverallData} getIndexFilterData={getIndexFilterData} handleResponseDistributionFilter={handleResponseDistributionFilter} ItemSaveData = {ItemSaveData} getQuestionDistData = {getQuestionDistData} EmpFilterData={EmpFilterData} getMeanScoreQwiseData={getMeanScoreQwiseData}
      // />
      // case 3: return <QuestionMeanTab 
      //   cardType={cardType} getIndexFilterData={getIndexFilterData} handleQuestionMeanScore={handleQuestionMeanScore} handleResponseDistributionFilter={handleResponseDistributionFilter} ItemSaveData = {ItemSaveData} getQuestionDistData = {getQuestionDistData} EmpFilterData={EmpFilterData} getMeanScoreQwiseData={getMeanScoreQwiseData} handleDemographicOverallQFilter={handleDemographicOverallQFilter}
      // />
      default: return <div>Default Option</div>
    }


  }

  render() {
    let { ItemSaveData, cardType } = this.props;
    let { optionType } = this.state;

    //console.log('--------->ItemSaveData:',ItemSaveData)
    let indexName = ''
    if (ItemSaveData && ItemSaveData.name) {
      indexName = ItemSaveData.name;
    }

    return (
      <>

        <div className="">
          {this.onSwitchOptionScreen()}
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HappinessIndex);
