import React, { Component } from "react";
import { connect } from "react-redux";

function ActionPlanContinue(props) {
  let { userName, createdAt } = props;

  return (
    <section className="p-6 px-6 flex flex-col justify-between page-break">
      {/* report header */}
      <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
        <p className='font-medium italic text-base '>360 Feedback</p>
        <p className='font-medium italic text-base'>{userName} | {createdAt}</p>
      </div>
      <div className="space-y-4 flex flex-col  ">
        <h1 className="text-2xl text-[#212121] font-medium capitalize">
          Development Action Plan (Continue)
        </h1>

        <span className="text-lg font-medium text-sky-500 underline  ">
          Part 2:
        </span>
        <p className="text-sm font-medium italic  ">
          MILESTONES | What are the key milestones in this goal and what steps
          should take me closure to them.{" "}
        </p>

        <div className=" ">
          <div className="  flex justify-center items-center px-4 py-2 border-t border-r border-l border-collapse">
            <p className="text-lg text-slate-800 font-semibold">Milestone 1</p>
          </div>
          <table className="w-full border-collapse ">
            <thead>
              <tr className="bg-gray-100 capitalize">
                <th className=" p-4 text-left font-medium text-xs border">
                  Steps/ Actions
                </th>
                <th className=" p-4 text-left font-medium text-xs border">
                  Success Measures
                </th>
                <th className=" p-4 text-left font-medium text-xs border">
                  Dimension / Area
                </th>
                <th className=" p-4 text-left font-medium text-xs border">
                  Start Date
                </th>
                <th className=" p-4 text-left font-medium text-xs border">
                  Status / Comments
                </th>
                <th className=" p-4 text-left font-medium text-xs border">
                  Manager/ Coach Comments
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="p-2 px-4 border">1</td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
              </tr>
              <tr>
                <td className="p-2 px-4 border">2</td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
              </tr>
              <tr>
                <td className="p-2 px-4 border">3</td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className=" ">
          <div className="  flex justify-center items-center px-4 py-2 border-t border-r border-l border-collapse">
            <p className="text-lg text-slate-800 font-semibold">Milestone 2</p>
          </div>
          <table className="w-full border-collapse ">
            <thead>
              <tr className="bg-gray-100 capitalize">
                <th className=" p-4 text-left font-medium text-xs border">
                  Steps/ Actions
                </th>
                <th className=" p-4 text-left font-medium text-xs border">
                  Success Measures
                </th>
                <th className=" p-4 text-left font-medium text-xs border">
                  Dimension / Area
                </th>
                <th className=" p-4 text-left font-medium text-xs border">
                  Start Date
                </th>
                <th className=" p-4 text-left font-medium text-xs border">
                  Status / Comments
                </th>
                <th className=" p-4 text-left font-medium text-xs border">
                  Manager/ Coach Comments
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="p-2 px-4 border">1</td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
              </tr>
              <tr>
                <td className="p-2 px-4 border">2</td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
              </tr>
              <tr>
                <td className="p-2 px-4 border">3</td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className=" ">
          <div className="  flex justify-center items-center px-4 py-2 border-t border-r border-l border-collapse">
            <p className="text-lg text-slate-800 font-semibold">Milestone 3</p>
          </div>
          <table className="w-full border-collapse ">
            <thead>
              <tr className="bg-gray-100 capitalize">
                <th className=" p-4 text-left font-medium text-xs border">
                  Steps/ Actions
                </th>
                <th className=" p-4 text-left font-medium text-xs border">
                  Success Measures
                </th>
                <th className=" p-4 text-left font-medium text-xs border">
                  Dimension / Area
                </th>
                <th className=" p-4 text-left font-medium text-xs border">
                  Start Date
                </th>
                <th className=" p-4 text-left font-medium text-xs border">
                  Status / Comments
                </th>
                <th className=" p-4 text-left font-medium text-xs border">
                  Manager/ Coach Comments
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="p-2 px-4 border">1</td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
              </tr>
              <tr>
                <td className="p-2 px-4 border">2</td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
              </tr>
              <tr>
                <td className="p-2 px-4 border">3</td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
                <td className="p-2 px-4 border"></td>
              </tr>
            </tbody>
          </table>
        </div>

        <table className="w-full border">
          <thead>
            <tr className=" ">
              <th className="w-5/12"></th>
              <th className="w-7/12"></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className=" bg-gray-100 border-r">
                <div className="flex   p-2 px-4  ">
                  <p>
                    <b className="font-semibold  "> SUPPORT:</b> What will help
                    me to reach this goal faster – accountability partner,
                    guidance, coach, organization support etc.
                  </p>
                </div>
              </td>
              <td className="p-2 px-4"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
}
export default ActionPlanContinue;
