import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import PageTemplate from './PageTemplate';
import EmployeeDropdown from './Components/EmployeeDropdown';
import LanguageDropdown from './Components/LanguageDropdown';

import CoverPage from './ReportComponent/CoverPage';
import IntroductionPage from './ReportComponent/IntroductionPage';
import DomainsFive from './ReportComponent/DomainsFive';
import SubDomains from './ReportComponent/SubDomains';
import SubDomainspart2 from './ReportComponent/SubDomainspart2';
import Summary from './ReportComponent/Summary';
import SummaryDetails from './ReportComponent/SummaryDetails';
import moment from 'moment';

class AssessmentReport extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();

    this.hindiPDFstatic = [...Array(700).keys()].map((index) => (React.createRef()))

    this.state = {
      crrSelectedEmp: "",
      languageName: 'English',
      languageShortName: 'en'
    }

  }

  componentDidMount() {

  }

  // exportPDFWithComponent = () => {
  //   if (this.pdfExportComponent.current) {
  //     this.pdfExportComponent.current.save();
  //   }
  // };

  exportPDFWithComponent = async () => {

    if (this.state.languageShortName === 'hi') {


      // const doc = new jsPDF('p', 'mm', [210, 297]);
      // console.log("this.hindiPDFstatic---->", this.hindiPDFstatic)
      // if (this.hindiPDFstatic && this.hindiPDFstatic.length > 0) {
      //   let list = this.hindiPDFstatic.filter(prev => prev.current !== null);
      //   console.log("this.hindiPDFstatic---->", this.hindiPDFstatic)

      //   if (list && list.length > 0) {
      //     console.log("list---->", list)

      //     for (var i = 0; i < list.length; i++) {
      //       let dataUrl = await getImage(list[i].current);
      //       if (dataUrl) {
      //         console.log("i---->", i)
      //         doc.addImage(dataUrl, 'PNG', 0, 0, 210, 287);

      //         if(i<(list.length-1)){
      //           doc.addPage();
      //         }

      //       }
      //     }
      //   }
      //   doc.save("AssessmentReport.pdf");
      // }
      // this.hindiPDFstatic = [...Array(700).keys()].map((index) => (React.createRef()))


    } else {

      if (this.pdfExportComponent.current) {
        this.pdfExportComponent.current.save();
      }
    }
  };


  handleSelectEmployee = (value) => {
    this.setState({ crrSelectedEmp: value }, () => {
      let { onLoadReportFn } = this.props;
      let emp_id = "";
      if (value && value.emp_id) {
        emp_id = value.emp_id;
      } else {
        emp_id = "";
      }
      onLoadReportFn(emp_id)

    })
  }


  handleLanguageLabel = (data) => {
    this.setState({
      languageName: data.name,
      languageShortName: data.shortName
    })
  }



  render() {

    let { getLeadershipTextDetailsData, GetParameterLabel, getLeadershipParaCategoryData, getLeadershipReportScoreData, getAssessmentTop5DescByNamesData, getAssessmentDimensionsV2Data, getAllAnsweredAssessmentEmpDataList, getAssessmentDimensionsData, onDownloadAssessmentType2ByEmpID, closeReportModal, getTranslateElementAllData, getAssessmentReportByEmpIDData, getAnsweredEmployeesData, getLanguageData } = this.props;
    let { languageName, languageShortName } = this.state;

    const elements10 = Array.from({ length: 5 });
    const elements4 = Array.from({ length: 4 });


    let userName = (this.state.crrSelectedEmp && this.state.crrSelectedEmp["name"] ? this.state.crrSelectedEmp["name"] : "");
    let userEmpId = (this.state.crrSelectedEmp && this.state.crrSelectedEmp["emp_id"] ? this.state.crrSelectedEmp["emp_id"] : "");
    let createdAt = moment(Date.now()).format("DD MMMM, YYYY");


    console.log("---this.state.crrSelectedEmp----", this.state.crrSelectedEmp)


    const domainThemeDetails = [

      {
        attribute: "Leading Self",
        colorOne: "#FFF5D9",
        colorTwo: "#FFC107",
        description: "The Leading Self domain focuses on cultivating a deep understanding of one’s own thoughts, behaviors, and motivations. This includes awareness across key dimensions: intellectual versus practical thinking, extroverted versus introverted communication, rationality versus emotional influence, authenticity through honesty and purpose, and a high level of self-awareness to recognize strengths and potential blind spots.",
      },
      {
        attribute: "Leading Others",
        colorOne: "#E4F3E4",
        colorTwo: "#4CAF50",
        description: "The Leading Others domain emphasizes building meaningful relationships and fostering an environment of trust, collaboration, and feedback. Key dimensions within this domain include the ability to form deep connections or network widely, the choice to engage with everyone or a select few, a focus on caring for the individual or the task, delivering feedback constructively or critically, and building trust either easily or gradually.",
      },
      {
        attribute: "Leading Team",
        colorOne: "#DEF0FD",
        colorTwo: "#2196F3",
        description: "The Leading Team domain centers on fostering team cohesion, navigating conflicts, and aligning personal and collective goals for optimal performance. Key dimensions in this domain include adopting a collaborative or independent approach, handling conflict either directly or by promoting harmony, leading from the front or guiding from behind, prioritizing others' interests or focusing on self-interest, and aiming for team goals versus personal achievements.",
      },
      {
        attribute: "Leading Results",
        colorOne: "#E8E2F5",
        colorTwo: "#673AB7",
        description: "The Leading Results domain focuses on driving outcomes through ownership, innovation, and adaptability in pursuit of organizational goals. This domain encompasses key dimensions such as the degree of ownership versus delegation, a disruptive versus incremental approach to solutions, transformational versus transactional change, a preference for rigid or fluid structures, and a focus on achieving external or internal benchmarks.",
      },
      {
        attribute: "Leading Community",
        colorOne: "#E2E4F3",
        colorTwo: "#3F51B5",
        description: "The Leading Community domain emphasizes a leaders role in fostering a positive, connected, and socially responsible environment beyond the organization. Key dimensions within this domain include a connected or disjointed worldview, a positive or highly cautious outlook, a tendency to give or take in interactions, a high or minimal focus on community-oriented thinking, and an altruistic versus self-care approach to social concerns.",
      }
    ];


    const GetTextByLanguage = (name, text) => {
      if (languageShortName && languageShortName !== "en") {
        let sentence = "";
        if (getTranslateElementAllData && getTranslateElementAllData.length > 0) {
          let getIndex = getTranslateElementAllData.findIndex(prev => prev.name === name);
          if (getIndex !== -1) {
            sentence = getTranslateElementAllData && getTranslateElementAllData[getIndex] && getTranslateElementAllData[getIndex]["transl"] && getTranslateElementAllData[getIndex]["transl"][languageShortName] ? getTranslateElementAllData[getIndex]["transl"][languageShortName] : ""
          }
        }
        return sentence ? sentence : text
      } else {
        return text
      }
    }

    const GetDetailsByLanguage = (element, name, text) => {
      if (languageShortName && languageShortName !== "en") {
        let sentence = element && element["details"] && element["details"]["transl"] && element["details"]["transl"][languageShortName] && element["details"]["transl"][languageShortName][name] ?
          element["details"]["transl"][languageShortName][name] : ""
        return sentence ? sentence : text
      } else {
        return text
      }
    }


    function EditButtonFn(name, text) {
      return (
        <>
          {GetTextByLanguage(name, text)}
        </>
      )
    }
    function EditDetailsFn(element, name, text) {
      return (
        <>
          {GetDetailsByLanguage(element, name, text)}
        </>
      )
    }



    return (


      <div className="fixed top-0 bottom-0 left-0 right-0 z-40 w-full h-full bg-black bg-opacity-60" >
        <div className="flex items-start justify-center h-screen p-4 overflow-hidden">

          <div className="mx-auto overflow-hidden overflow-y-auto bg-white rounded-md 2xl:w-7/12 xl:w-9/12 lg:w-5/6 customscroll5 h-[calc(100vh-2rem)]" >
            <div className="sticky top-0 z-20 flex flex-col items-center justify-between p-4 px-6 bg-white border-b lg:flex-row gap-4"  >
              <h1 className="text-base font-semibold text-[#212121] md:text-lg ">
                Team Report
              </h1>

              <div className='flex md:flex-row flex-col items-center gap-2 md:w-auto w-full'>

                {/* <LanguageDropdown
                  getLanguageData={getLanguageData}
                  languageName={languageName}
                  languageShortName={languageShortName}
                  handleLanguageLabel={this.handleLanguageLabel}
                /> */}


                <EmployeeDropdown
                  getAnsweredEmployeesData={getAnsweredEmployeesData}
                  crrSelectedEmp={this.state.crrSelectedEmp}
                  handleSelectEmployee={this.handleSelectEmployee}
                />

                {/* {getAssessmentDimensionsData && getAssessmentDimensionsData.length > 0 ? */}

                <div onClick={() => {
                  this.state.languageShortName === 'hi' ?
                    onDownloadAssessmentType2ByEmpID(this.state.crrSelectedEmp && this.state.crrSelectedEmp.emp_id ? this.state.crrSelectedEmp.emp_id : "") :
                    this.exportPDFWithComponent()
                }}
                  className="flex items-center py-3.5 px-4 text-sm border rounded-md cursor-pointer font-medium text-[#212121] md:w-auto w-full" >
                  <span class="material-symbols-outlined mr-2">
                    file_download
                  </span> Download PDF
                </div>

                {/* : null} */}



                <div onClick={() => closeReportModal(false)} className="inline-flex items-center justify-center w-10 h-10 text-gray-500 bg-gray-100 rounded-full cursor-pointer hover:text-gray-800" >
                  <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                  </svg>
                </div>
              </div>

            </div>

            {true ?
              <div >
                <PDFExport ref={this.pdfExportComponent}
                  pageTemplate={(pageObj) =>
                    <PageTemplate
                      pageNum={pageObj.pageNum}
                    // surveyLogo={surveyLogo}
                    />}
                  scale={0.7}
                  forcePageBreak=".page-break"
                  paperSize="A4"
                  margin={0}
                  fileName={`Leadership ` + ` Report ${userName} - ${createdAt}`}
                  author="Happyplus Team"
                  landscape={false}
                >

                  {!userName ?
                    <>
                      <div className='py-24 text-2xl font-bold text-center text-gray-500'>{"Please Select Users!"}</div>
                    </>
                    :
                    <>
                      <CoverPage
                        createdAt={createdAt}
                        userName={userName}
                        userEmpId={userEmpId}
                      />

                      <IntroductionPage
                        createdAt={createdAt}
                        userName={userName}
                      />

                      <DomainsFive
                        createdAt={createdAt}
                        userName={userName}
                        domainThemeDetails={domainThemeDetails}
                      />

                      <SubDomains
                        createdAt={createdAt}
                        userName={userName}
                        elements10={elements10}
                      />

                      <SubDomainspart2
                        createdAt={createdAt}
                        userName={userName}
                        elements10={elements10}
                      />

                      <Summary
                        GetParameterLabel={GetParameterLabel}
                        getLeadershipParaCategoryData={getLeadershipParaCategoryData}
                        getLeadershipReportScoreData={getLeadershipReportScoreData}
                        elements4={elements4}
                        createdAt={createdAt}
                        userName={userName}
                        domainThemeDetails={domainThemeDetails}
                      />

                      <SummaryDetails
                        GetParameterLabel={GetParameterLabel}
                        getLeadershipParaCategoryData={getLeadershipParaCategoryData}
                        getLeadershipReportScoreData={getLeadershipReportScoreData}
                        getLeadershipTextDetailsData={getLeadershipTextDetailsData}
                        EditButtonFn={EditButtonFn}
                        EditDetailsFn={EditDetailsFn}
                        elements4={elements4}
                        createdAt={createdAt}
                        userName={userName}
                        domainThemeDetails={domainThemeDetails}
                      />
                    </>}



                </PDFExport>
              </div>
              : <div className='py-24 text-2xl font-bold text-center text-gray-500'>{"Please Select"}</div>}

          </div>
        </div >
      </div >

    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(AssessmentReport);
