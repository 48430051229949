import React, { Component } from "react";
import { connect } from "react-redux";
import { MdAdd, MdRemove, MdDeleteOutline } from 'react-icons/md';
import Graph from "react-graph-vis";
import TreeBranches from "./Components/TreeBranches";
import StackedBarChart from "./Components/StackedBarChart";
import WordDetails from "./WordDetails";
import { pmsActions } from '../../../../../../_actions';

class NeighbourGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NetworkValue: "",
      ViewGraph: "tree",
      checkKeywordSingleType: true,

      SelectedText: "",
      totalWords: 0,
      showModal: false,
      avoidList: [],
      currentName: "",
      crrDropDownDmg: false,
      realTimeAvoidList: [],
      isKeywordSelected: false
    };
  }

  componentDidMount() { }

  handleView = (value) => {
    this.setState({ ViewGraph: value });
  };

  keyWordsFn = (keyword) => {
    let { handleKeywordCall } = this.props;
    handleKeywordCall(keyword, false);
    this.setState({ checkKeywordSingleType: true, isKeywordSelected: true  });
  };

  keyWordsMultiFn = (a, b) => {
    let { handleKeywordCall } = this.props;
    let keyword = [a, b];
    handleKeywordCall(keyword, true);
    this.setState({ checkKeywordSingleType: false, isKeywordSelected: true });
  };


  openHelpModal = () => {
    let { getTextKeywordsData } = this.props;
    this.setState({
      showModal: true,
      avoidList: getTextKeywordsData && getTextKeywordsData.length > 0 ? getTextKeywordsData : []
    });
  };

  closeHelpModal = () => {
    this.setState({ showModal: false });
  };

  handlePiMinusBoldClick = (newV2Words) => {
    let { realTimeAvoidList } = this.state;

    if (newV2Words && newV2Words.length) {
      realTimeAvoidList.push(newV2Words[newV2Words.length - 1])
    }
    this.setState({ realTimeAvoidList })
  };

  handlePiAddBoldClick = () => {
    let { realTimeAvoidList } = this.state;
    if (realTimeAvoidList && realTimeAvoidList.length) {
      realTimeAvoidList.splice(realTimeAvoidList.length - 1, 1);
    }
    this.setState({ realTimeAvoidList })
  };

  handlePiPlusBoldClick = () => {
    this.setState({ showModal: true });
  };

  handleKeywordSearch = (value) => {
    let { avoidList } = this.state;
    let currentList = avoidList
    currentList.push(this.state.currentName)
    this.setState({ avoidList: currentList })
    this.setState({ currentName: "" })
  }

  handleSubmitKeyword = () => {
    let { avoidList } = this.state;
    console.log('handleSubmitKeywordhandleSubmitKeywordhandleSubmitKeyword', avoidList);
    let { addKeywordsAPI } = this.props;
    addKeywordsAPI(avoidList);
    this.closeHelpModal();
  }

  handleSearchDelete = (value) => {
    let { avoidList } = this.state;
    let currentList = avoidList
    currentList.pop(this.state.currentName)
    this.setState({ avoidList: currentList })
    this.setState({ currentName: "" })
  }

  changeName = (e) => {
    console.log('e.target_______', e.target);
    let { name, value } = e.target;
    this.setState({ currentName: value })
  }

  deleteWordInput = (word) => {
    let { avoidList } = this.state;
    let getIndex = avoidList.findIndex(prev => prev === word);
    if (getIndex !== -1) {
      avoidList.splice(getIndex, 1)
    }
    this.setState({ avoidList })
  }

  handleCrrDropDownDmg = (value) => {
    this.setState({ crrDropDownDmg: value });
  }

  handleAvopidWordInput = (word) => {
    let { avoidList } = this.state;
    let getIndex = avoidList.findIndex(prev => prev === word);
    if (getIndex !== -1) {
      avoidList.splice(getIndex, 1)
    } else {
      avoidList.push(word)
    }
    this.setState({ avoidList })
  }


  render() {
    let { EmpFilterData2, GetParameterLabel, neighborGraphError, cooccurmatrixData, neighborGraphData, neighborGraphTextData, neighborGraphKeyword, neighborGraphTextData2, neighborGraphKeyword2, wordCloudError, wordCloudData, getTextKeywordsData } = this.props;

    let { isKeywordSelected, ViewGraph, checkKeywordSingleType, showModal, avoidList, currentName, crrDropDownDmg, realTimeAvoidList } = this.state;

    let textData1 = checkKeywordSingleType ? neighborGraphTextData : neighborGraphTextData2;
    let keyWord1 = checkKeywordSingleType ? neighborGraphKeyword : neighborGraphKeyword2;

    let NewData = [];
    let OldData = {};
    if (neighborGraphData && neighborGraphData.list) {
      NewData = Object.keys(neighborGraphData.list);
      OldData = neighborGraphData.list;
    }


    let NewList = [];
    if (NewData && NewData.length > 0) {
      NewData.forEach((item) => {
        if (OldData && OldData[item] && OldData[item].length > 0) {
          OldData[item].forEach((value) => {
            NewList.push({
              source: item,
              target: value[0],
              value: value[1],
            });
          });
        }
      });
    }

    // let words = []

    let cooccurmatrix = cooccurmatrixData && cooccurmatrixData.list && cooccurmatrixData.list.length > 0 ? cooccurmatrixData.list : [];


    let LinkList = [];
    let NodeList = [];

    if (cooccurmatrix && cooccurmatrix.length > 0) {
      cooccurmatrix.forEach((item) => {
        if (NodeList.findIndex((prev) => prev === item[0][0]) === -1) {
          NodeList.push(item[0][0]);
        }

        if (NodeList.findIndex((prev) => prev === item[0][1]) === -1) {
          NodeList.push(item[0][1]);
        }

        LinkList.push({
          source: item[0][0],
          target: item[0][1],
          value: item[1],
        });
      });
    }

    let Colors = [
      "#ff8080",
      "#9fff80",
      "#66b3ff",
      "#b366ff",
      "#ffc266",
      "#66d9ff",
      "#b3b3cc",
      "#e6e600",
      "#ff66ff",
      "#99cc00",
      "#ff9966",
      "#cccc00",
      "#66ff33",
      "#ff6666",
      "#ff6600",
    ];

    let NodeValue = [];
    if (NewData && NewData.length > 0) {
      NewData.forEach((node, index) => {
        NodeValue.push({
          id: index,
          label: node,
          color: Colors[Math.floor(Math.random() * Colors.length)],
        });
      });
    }

    let LinkValue = [];
    if (NewList && NewList.length > 0) {
      NewList.forEach((item, index) => {
        LinkValue.push({
          from: NodeValue.findIndex((prev) => prev.label === item.source),
          to: NodeValue.findIndex((prev) => prev.label === item.target),
        });
      });
    }

    // const data = {
    //   nodes: NodeValue,
    //   links: LinkList
    // };

    const graph = {
      nodes: NodeValue,
      edges: LinkValue,
    };

    const options = {
      layout: {
        randomSeed: undefined,
        improvedLayout: true,
        clusterThreshold: 150,
        hierarchical: {
          enabled: false,
          levelSeparation: 150,
          nodeSpacing: 100,
          treeSpacing: 200,
          blockShifting: true,
          edgeMinimization: true,
          parentCentralization: true,
          direction: "UD",
          sortMethod: "hubsize",
          shakeTowards: "leaves",
        },
      },
      edges: {
        color: "#000000",
      },
      height: "500px",
    };

    const events = {
      select: function (event) {
        // var { nodes, edges } = event;
      },
    };




    let newWords = []
    if (NewData && NewData.length > 0) {
      for (var i = 0; i < NewData.length; i++) {
        let ele = NewData[i];
        if (getTextKeywordsData && getTextKeywordsData.length > 0) {
          if (!getTextKeywordsData.includes(ele)) {
            newWords.push(ele)
          }
        } else {
          newWords.push(ele)
        }
      }
    }


    let newV2Words = []

    if (newWords && newWords.length > 0) {
      for (var i = 0; i < newWords.length; i++) {
        let ele = newWords[i];
        if (realTimeAvoidList && realTimeAvoidList.length > 0) {
          if (!realTimeAvoidList.includes(ele)) {
            newV2Words.push(ele)
          }
        } else {
          newV2Words.push(ele)
        }
      }
    }


    console.log('getTextKeywordsData:::::::::::::::::::::::::1111111>', getTextKeywordsData);
    console.log('realTimeAvoidList:::::::::::::::::::::::::1111111>', realTimeAvoidList);
    console.log('newV2Words:::::::::::::::::::::::::1111111>', newV2Words);


    return (
      <>
        <div className={isKeywordSelected ? "grid grid-cols-1 gap-4 xl:grid-cols-2 " : "grid grid-cols-1 gap-4 xl:grid-cols-1 "}>

          <div className="transition duration-150 bg-white border rounded-lg hover:shadow-lg">
            {/* <h1 class="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap px-6 py-4 border-b ">
              Network Graph
            </h1> */}
            <div className="flex items-center justify-between px-6 py-4 border-b ">
              <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">Word Cloud</h1>
              <div className="flex items-center space-x-2 text-[#3D405B] ">
                <div className="flex items-center space-x-2">
                  <p className="text-sm font-medium text-[#3D405B]">No of Words</p>
                  <div className="flex text-sm border divide-x rounded-md">
                    <button className=" p-2 py-1 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition"
                      onClick={() => this.handlePiMinusBoldClick(newV2Words)}>
                      <MdRemove className="text-[1rem]" />
                    </button>
                    <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] font-medium">
                      {newV2Words && newV2Words.length > 0 ? newV2Words.length : 0}
                    </button>
                    <button
                      onClick={() => this.handlePiAddBoldClick(newV2Words)}
                      className="p-2 py-1 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition" >
                      <MdAdd className="text-[1rem]" />
                    </button>
                  </div>
                  <button className="bg-blue-100 p-1.5 px-3  rounded-md border text-[#2196f3] group hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2 " onClick={() => this.openHelpModal(true)}>
                    <MdDeleteOutline className="text-[1.4rem] mr-1 w-5 h-6" />
                    Remove Words</button>
                </div>
              </div>
            </div>

            {NewData && NewData.length > 0 ? (
              <div className="h-[760px]  p-0 overflow-y-auto customscroll4 relative ">
                {/* <button className="bg-blue-100 p-2 px-4 absolute left-6 top-4  rounded-md border text-[#2196f3] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2 " onClick={() => this.openHelpModal(true)}>
                  <MdDeleteOutline className="text-[1.5rem] mr-1" />
                  Remove Words</button>
                <div className="h-12" ></div> */}
                <TreeBranches
                  keyList={newV2Words}
                  DataObj={OldData}
                  keyWordsFn={this.keyWordsFn}
                  keyWordsMultiFn={this.keyWordsMultiFn}
                  width={100}
                />

              </div>
            ) : (
              <>
                <div className="h-[600px]">
                  <h1 class="text-sm text-[#3d405B]/70 p-6 border-t text-center">* We are not showing data where employee count is less than 5</h1>
                </div>

              </>

            )}


          </div>

          {isKeywordSelected ?
            <>
          {checkKeywordSingleType ? (
            <WordDetails
              EmpFilterData2={EmpFilterData2}
              GetParameterLabel={GetParameterLabel}
              wordCloudTextData={textData1}
              wordTextKeyWord={keyWord1}
            />
          ) : (
            <WordDetails
              EmpFilterData2={EmpFilterData2}
              GetParameterLabel={GetParameterLabel}
              wordCloudTextData={textData1}
              wordTextKeyWord={keyWord1}
            />
          )}
                 </>
            : null}


        </div>


        {showModal ? (
          <div className="fixed top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center w-full h-full p-4 bg-black bg-opacity-40 md:p-0">
            <div className="w-5/6 mx-auto bg-white rounded-md xl:w-4/12 lg:w-4/6">
              <div className="flex items-center justify-between p-4 border-b">
                <h1 className="p-1 text-lg text-[#3D405B] font-medium capitalize">Remove Words</h1>
                <span onClick={() => this.closeHelpModal(false)} className=" material-symbols-outlined bg-gray-50 hover:bg-gray-100 rounded-full p-1.5  cursor-pointer transition duration-150">close</span>
              </div>

              <div className="px-4" style={{ overflowY: "auto", overflowX: "auto", height: "300px", }}>
                <div className="items-center justify-center p-4">
                  <div>
                    <label for="first_name" class="block mb-2 text-base font-medium text-gray-900">Select words to be removed</label>

                    <div className="flex flex-row items-center gap-6">
                      <div
                        //onClick={() => handleCrrDropDownDmgV2(ele.name)}
                        className="relative flex items-center justify-between w-full p-2 px-4 text-gray-700 border rounded-md cursor-pointer"
                      >

                        {avoidList && avoidList.length > 0 ?
                          <>
                            <ul
                              // onClick={() => this.handleCrrDropDownDmg(!crrDropDownDmg)}
                              className="flex flex-row flex-wrap w-full gap-2 ">
                              {avoidList.map((text) => (
                                <li className="bg-[#F9FAFB] p-2 py-1 border text-sm flex items-center space-x-1 rounded-md">
                                  <span>{text}</span>

                                  <svg
                                    onClick={() => this.deleteWordInput(text)}
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-x"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <path d="M18 6l-12 12"></path>
                                    <path d="M6 6l12 12"></path>
                                  </svg>
                                </li>
                              ))}
                            </ul>
                          </>
                          :
                          <>
                            <ul
                              onClick={() => this.handleCrrDropDownDmg(!crrDropDownDmg)}
                              className="flex flex-row flex-wrap w-full gap-2">
                              <li className="w-full"></li>
                            </ul>
                          </>}


                        <svg

                          onClick={() => this.handleCrrDropDownDmg(!crrDropDownDmg)}

                          xmlns="http://www.w3.org/2000/svg"
                          className="cursor-pointer icon icon-tabler icon-tabler-caret-down-filled text-[#3D405B]/70"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path
                            d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z"
                            stroke-width="0"
                            fill="currentColor"
                          ></path>
                        </svg>

                        {crrDropDownDmg ? (
                          <div className="absolute right-0 z-10 w-full py-4 overflow-y-auto bg-white border rounded-md shadow-lg top-12 max-h-40 min-h-auto"
                          >
                            {newV2Words && newV2Words.length > 0 ?
                              newV2Words.map((item, index) => (
                                <label
                                  onClick={() => {
                                    this.handleCrrDropDownDmg(false);
                                    this.handleAvopidWordInput(item);
                                  }}
                                  className="items-center flex space-x-2 text-[#3D405B]/70 w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 text-sm"
                                >
                                  <input
                                    style={{ zoom: 1.2 }}
                                    type="checkbox"
                                    checked={avoidList.includes(item) ? true : false}
                                    className=""
                                  />
                                  <span>{item}</span>
                                </label>
                              ))
                              : null}
                          </div>
                        ) : null}
                      </div>
                    </div>

                  </div>


                  <div className="flex items-end justify-end mt-3">
                    <button onClick={() => this.handleSubmitKeyword()} class="cursor-pointer text-sm py-2 px-4 font-medium bg-[#2196f3] text-white rounded-md border border-[#2196f3]">Done</button>
                  </div>


                </div>
              </div>
            </div>
          </div>
        ) : null}

      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn,
  };
}
export default connect(mapStateToProps)(NeighbourGraph);
