import React from "react";

export default function Dashboard(props) {
  let {
    getQuestionOverallData,
    getQuestionManagerData,
    getDMGQuestionData,
    ManagerDetails,
    SectionName,
    IndexDimensionList,
    GetParameterLabel,
    getDemographicIndexData,
    CurrentManagerData,
    getSurveyData,
    manageName,
    createdAt,
    teamSize,
    getIndexDMGData,
    getAllBenchMarkStaticData
    // QuestionList,SectionName,EmpFilterData, avoidList
  } = props;

  //////console.log("------>getQuestionManagerData---------->",getQuestionManagerData)
  //////console.log("------>IndexDimensionList---------->",IndexDimensionList)

  function CalculateScore(ques) {

    let total =
      ques["1"] + ques["2"] + ques["3"] + ques["4"] + ques["5"] + ques["6"];
    let sum = ques["5"] + ques["6"];
    let score = (sum * 100) / total;
    return score && score.toFixed(2) ? score.toFixed(2) : 0;
  }

  let NewQuestionList = [];
  let OverallQuestionObj = {};
  let ManagerQuestionObj = {};
  let DMGQuestionObj = {};

  if (IndexDimensionList && IndexDimensionList.length > 0) {
    IndexDimensionList.forEach((para) => {
      if (getQuestionOverallData && getQuestionOverallData.length > 0) {
        getQuestionOverallData.forEach((ques) => {
          if (para === ques.parameterDimensionName) {
            let getIndex = NewQuestionList.findIndex(
              (prev) => prev.para === ques.parameterDimensionName
            );
            if (getIndex === -1) {
              NewQuestionList.push({
                name: ques.name,
                para: ques.parameterDimensionName,
                showPara: true,
                countPara: 1,
              });
            } else {
              NewQuestionList.push({
                name: ques.name,
                para: ques.parameterDimensionName,
                showPara: false,
                countPara: 0,
              });
              NewQuestionList[getIndex]["countPara"] += 1;
            }

            OverallQuestionObj[ques.name] = CalculateScore(ques);
          }
        });
      }
    });
  }

  // if(getQuestionOverallData && getQuestionOverallData.length>0){
  //   getQuestionOverallData.forEach((ques)=>{
  //     OverallQuestionObj[ques.name] = CalculateScore(ques);
  //   });
  // }
  if (getQuestionManagerData && getQuestionManagerData.length > 0) {
    getQuestionManagerData.forEach((ques) => {
      ManagerQuestionObj[ques.name] = CalculateScore(ques);
    });
  }
  if (getDMGQuestionData && getDMGQuestionData.length > 0) {
    getDMGQuestionData.forEach((ques) => {
      DMGQuestionObj[ques.name] = CalculateScore(ques);
    });
  }

  let ValueObj = {};

  if (IndexDimensionList && IndexDimensionList.length > 0) {
    IndexDimensionList.forEach((item) => {
      ValueObj[item + "Avg"] = 0;
    });
  }

  let managerId =
    CurrentManagerData && CurrentManagerData.id ? CurrentManagerData.id : "";
  let index = getDemographicIndexData
    ? getDemographicIndexData.findIndex(
      (prev) => prev.name.toString() === managerId.toString()
    )
    : -1;

  let DataItem = {};

  if (index !== -1) {
    DataItem = getDemographicIndexData[index];
  }

  //////console.log("-------------> DataItem--->",DataItem)

  if (IndexDimensionList && IndexDimensionList.length > 0) {
    IndexDimensionList.forEach((item) => {
      ValueObj[item + "Avg"] =
        DataItem && DataItem[item + "Avg"] && DataItem[item + "Avg"].toFixed(2)
          ? DataItem[item + "Avg"].toFixed(2)
          : 0;
    });
  }

  function getScore(ques, type) {
    let score = 0;
    if (type === "overall") {
      score =
        OverallQuestionObj && OverallQuestionObj[ques.name]
          ? OverallQuestionObj[ques.name]
          : "0";
    } else if (type === "manager") {
      score =
        ManagerQuestionObj && ManagerQuestionObj[ques.name]
          ? ManagerQuestionObj[ques.name]
          : "0";
    } else if (type === "dmg") {
      score =
        DMGQuestionObj && DMGQuestionObj[ques.name]
          ? DMGQuestionObj[ques.name]
          : "0";
    } else if (type === "N_Manager") {
      let quesIndex =
        getQuestionManagerData && getQuestionManagerData.length > 0
          ? getQuestionManagerData.findIndex((prev) => prev.name === ques.name)
          : -1;
      //////console.log("quesIndex====>",quesIndex)
      if (quesIndex !== -1) {
        let quesData = getQuestionManagerData[quesIndex];
        //////console.log("quesData====>",quesData)
        score =
          quesData && quesData["count"] && quesData["count"] > 4
            ? quesData["count"]
            : "-";
      }
    } else if (type === "para_Manager") {
      //////console.log("ques.para--->",ques.para)
      //////console.log("ValueObj--->",ValueObj)

      score = ValueObj[ques.para + "Avg"];

      // let sum = 0
      // let filterManagerQues = getQuestionManagerData && getQuestionManagerData.length>0? getQuestionManagerData.filter(x=>x.parameterDimensionName === ques.para):[];
      // let filterManagerLength = getQuestionManagerData && getQuestionManagerData.length>0? getQuestionManagerData.filter(x=>x.parameterDimensionName === ques.para).length:0;
      // if(filterManagerQues && filterManagerQues.length>0){
      //   filterManagerQues.forEach((ques)=>{
      //     sum+=parseFloat(CalculateScore(ques));
      //   });
      // }
      // let scoreValue = sum/filterManagerLength;
      // score = scoreValue && scoreValue.toFixed(2)?scoreValue.toFixed(2):0
    }
    return score;
  }

  let dmgXLabel = "";
  if (getIndexDMGData && getIndexDMGData.length > 0) {
    dmgXLabel = getIndexDMGData[0]["name"]
  }



  function getSelectiveScores(list, item, type) {
    let scoreObj = {
      "HWScore": 0,
      "OverallScore": 0
    };
    if (list && list.length > 0) {

      if (type === "dimension") {
        let newList = list.filter(prev => prev.type === "dimension");
        let getIndex = newList.findIndex(prev => prev.name === item);
        if (getIndex !== -1) {
          scoreObj["HWScore"] = newList[getIndex]["HWScore"];
          scoreObj["OverallScore"] = newList[getIndex]["OverallScore"];
        }
      } else if (type === "index") {
        let newList = list.filter(prev => prev.type === "index");
        console.log("newList------x------>", newList)
        console.log("item------x------>", item)

        let getIndex = newList.findIndex(prev => prev.name === item);
        if (getIndex !== -1) {
          scoreObj["HWScore"] = Number(newList[getIndex]["HWScore"]);
          scoreObj["OverallScore"] = Number(newList[getIndex]["OverallScore"]);
        }
      }

    }
    return {
      "HWScore": scoreObj["HWScore"] > 0 ? scoreObj["HWScore"] : "-",
      "OverallScore": scoreObj["OverallScore"] > 0 ? scoreObj["OverallScore"] : "-"
    }
  }


  function getFocusPoint(score, getAllBenchMarkStaticData) {

    let lower = getSelectiveScores(getAllBenchMarkStaticData, "Manager", "index")["OverallScore"];
    let upper = getSelectiveScores(getAllBenchMarkStaticData, "Manager", "index")["HWScore"];
    console.log("score", score)
    console.log("lower", lower)
    console.log("upper", upper)


    let objTeam = {
      "color": ""
    }
    if (score <= lower) {
      objTeam["color"] = "#EF5350"
    } else if (score >= upper) {
      objTeam["color"] = "#4CAF50"
    } else {
      objTeam["color"] = "#FFFFFF"
    }
    console.log("objTeam", objTeam)

    return objTeam
  }


  let SentenceObj = {

    "Pride": "Embrace pride, promote positivity, and advocate for the organization. Employees who cultivate a deep sense of pride in their work exhibit heightened energy, focus, and motivation.",
    "Presence": "The intent of an employee to have an intense desire to be a member of the organization mentally and physically, fueled by a consistent positive drive to align with the organization mission.",
    "Performance": "Achieving high performance and consistently striving to exceed goals to drive organizational success, while maintaining alignment with the business core purpose.",

    "Connect": "Manager to care and support with team and knowing them individually.",
    "ACT": "Develop hi-performing team and continuously perform to achieve organization goal.",
    "Respect": "Build an environment of trust and respect.",
    "Empower": "Manager to empower team and bring the best.",

  }

  let ListX1 = [];
  let scoreList = []
  if (NewQuestionList && NewQuestionList.length > 0) {
    NewQuestionList.forEach((item, index) => {
      ListX1.push({
        sno: index + 1,
        showPara: item.showPara,
        countPara: item.countPara,
        para: GetParameterLabel(item.para),
        para: GetParameterLabel(item.para),
        desc: SentenceObj && SentenceObj[item.para] ? SentenceObj[item.para] : "",
        name: item.name,
        managerScore: getScore(item, "manager"),
        overallScore: getScore(item, "overall"),
        focusAreas: parseFloat(getScore(item, "manager")) < parseFloat(getScore(item, "overall")) ? true : false
      });

      scoreList.push({
        sno: index + 1,
        score: parseFloat(getScore(item, "manager"))
      })

    })

    scoreList.sort((a, b) => (a.score - b.score))
  }

  let bottonScore = []
  if (scoreList && scoreList.length > 0) {
    scoreList.forEach((ele, index) => {
      if (index < 3) {
        bottonScore.push(ele.sno)
      }
    })
  }


  return (
    <>
      <section className=" space-y-2   px-10">
        <main className=" w-full pb-4 space-y-6 text-left  ">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/70 italic">
            <h1 className="text-base font-semibold">Manager Score Card</h1>
            <p className="text-base font-semibold">{manageName + " | " + createdAt}</p>
          </div>
          <h1 className="px-6 text-4xl text-left text-[#fff] uppercase bg-[#2364AD] p-4 py-4">

            {SectionName === "Engagement" ? "3P FRAMEWORK SCORES" : "CARE FRAMEWORK SCORES"}
          </h1>


          <div className="w-full  space-y-4">
            <p className="">
              *Percentage scores reflect the level of satisfaction (Agree - 5 & Strongly Agree - 6)
            </p>
          </div>
          <table className="w-full bg-[#2364AD]/10">
            <thead>
              <tr className="text-left text-[#fff] capitalize  border-b-2 border-white  text-sm bg-[#2364AD] ">
                <th className="w-[28%] font-semibold p-2 px-4  text-left border-r-2 border-white">
                  Dimension
                </th>
                <th className="w-[30%] font-semibold p-2 text-left border-r-2 border-white">
                  Questions
                </th>
                <th className="w-[10%] font-semibold p-2 text-center border-r-2 border-white  ">
                  Team (%)
                  Top 2
                </th>
                <th className="w-[8%] font-semibold p-2 text-center border-r-2 border-white  ">
                  Org (%)
                  Top 2
                </th>
                {/* <th className="w-[7%] font-semibold p-2 text-center border-r-2 border-white  ">
                  Focus Areas
                </th> */}
              </tr>
            </thead>
            <tbody className="divide-y-2 divide-white ">

              {ListX1 && ListX1.length > 0
                ? ListX1.map((item, index) => (
                  <tr className="border-b text-[#212121] text-sm border-l border-r border-white">
                    {item.showPara ?
                      <td rowSpan={item.countPara} className="p-2 px-4   border-r-2 border-white">
                        <span className="font-bold capitalize">{GetParameterLabel(item.para)}</span><br />
                        {item.desc}
                      </td>
                      : null
                    }

                    <td className="p-2 px-4 text-sm border-r-2 border-white">
                      {item.name}
                    </td>
                    <td className="p-2  text-sm text-center border-r-2 border-white font-bold">
                      {item.managerScore}%
                    </td>
                    <td className="p-2 text-sm text-center border-r-2 border-white font-bold">
                      {item.overallScore}%
                    </td>
                    {/* <td className="p-2 text-sm text-center border-r-2 border-white p-4" >
                      {parseFloat(item.overallScore) >= parseFloat(item.managerScore) ?
                        <div className="flex justify-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                            <path d="M1.18164 9.80088C3.96875 12.8165 6.67109 15.5313 9.26875 18.9649C12.093 13.3478 14.9836 7.71104 19.7531 1.60674L18.468 1.01807C14.4406 5.28916 11.3117 9.33213 8.59297 14.1368C6.70234 12.4337 3.64688 10.0235 1.78125 8.78525L1.18164 9.80088Z" fill="#2364AD" />
                          </svg>
                        </div>
                        : null
                      }
                    </td> */}
                  </tr>
                ))
                : null}


            </tbody>
          </table>

        </main>
      </section>
    </>
  );
}