import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";


class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList: [],
      EmpIndexA: 0,
      EmpIndexB: 4
    }
  }
  componentDidMount() {

  }

  handleMatrix = (question) => {
    let { stage } = this.props;
    if (stage == 2) {
      let { handleMatrixDetails } = this.props;
      handleMatrixDetails(question, stage)
    }

  }


  render() {
    let { stage, renderType, getQuestionMatrixResultData, questionsList } = this.props;


    // --------------NEW------------------------------------------------------------------------------------

    function FormatMatriXOptionHeading(question, isOption) {
      let paraObjShow = {};
      if (question && question.optionsList && question.optionsList.length > 0) {
        question.optionsList.forEach((item) => {
          if (paraObjShow && paraObjShow[item.heading]) {
            paraObjShow[item.heading] += 1
          }
          else {
            paraObjShow[item.heading] = 1
          }
        });
      }

      let headingList = []
      let tempKey = Object.keys(paraObjShow);
      if (tempKey && tempKey.length > 0) {
        tempKey.forEach((item) => {
          headingList.push({
            name: item,
            span: paraObjShow[item]
          })
        });
      }

      if (isOption) {
        let OptionList = []
        if (headingList && headingList.length > 0) {
          headingList.forEach((heading) => {
            if (question && question.optionsList && question.optionsList.length > 0) {
              question.optionsList.forEach((option) => {
                if (heading.name === option.heading) {
                  OptionList.push(option)
                }
              });
            }
          });
        }
        return OptionList
      }
      else {
        return headingList
      }

    }





    function getCountPerc(SubQues, option, heading) {
      let total = 0;
      let count = 0;
      if (SubQues && SubQues.optionsList && SubQues.optionsList.length > 0) {
        SubQues.optionsList.forEach((item) => {
          if (item.heading === heading) {
            total += parseInt(item.count);
            if (item.answerText === option) {
              count += parseInt(item.count);
            }
          }
        });
      }
      return (parseFloat((count * 100) / total).toFixed(2)).toString() + "%";
    }



    let MainQuesList = []
    if (questionsList && questionsList.length > 0) {
      questionsList.forEach((ques) => {
        if ((ques.templateId === 13) && !ques.parentId) {
          MainQuesList.push({
            id: ques.id,
            name: ques.name,
            optionsList: ques.optionsList,
            SubQuestionList: []
          });
        }
      });
    }


    if (getQuestionMatrixResultData && getQuestionMatrixResultData.length > 0) {
      getQuestionMatrixResultData.forEach((item) => {

        let newIndex = MainQuesList.findIndex(prev => prev.id === (item && item.total && item.total[0] && item.total[0]._id.qparentId ? item.total[0]._id.qparentId : ""));
        if (newIndex !== -1) {
          let temp = {
            "name": item && item.question && item.question[0] && item.question[0].name ? item.question[0].name : "",
            "optionsList": [],
            "qtemplate": item && item.total && item.total[0] && item.total[0]._id.qtemplate ? item.total[0]._id.qtemplate : "",
            "qparentId": item && item.total && item.total[0] && item.total[0]._id.qparentId ? item.total[0]._id.qparentId : "",
            "questionId": item && item._id ? item._id : "",
          }
          if (item && item.total && item.total.length > 0) {
            item.total.forEach((otp) => {
              if (otp && otp._id) {
                temp["optionsList"].push({
                  "answer": otp && otp._id && otp._id.answer ? otp._id.answer : "",
                  "answerText": otp && otp._id && otp._id.answerText ? otp._id.answerText : "",
                  "heading": otp && otp._id && otp._id.heading ? otp._id.heading : "",
                  "count": otp && otp.count ? otp.count : "",

                });
              }
            });
          }

          MainQuesList[newIndex]["SubQuestionList"].push(temp)
        }

      });
    }






    let ALLMatrixList = [];

    if (stage == 2) {
      ALLMatrixList = MainQuesList;
    }
    else {
      ALLMatrixList = MainQuesList && MainQuesList.length > 0 ? [MainQuesList[0]] : [];
    }


    return (
      <>
        <div className={stage == 2 ? "grid xl:grid-cols-3 md:grid-cols-2  md:gap-6 gap-4" : ""}>

          {ALLMatrixList && ALLMatrixList.length > 0 ?
            ALLMatrixList.map((question, index) =>
              <>
                <div className={stage == 2 ? " bg-white p-4" : " bg-white p-4 border"} onClick={() => this.handleMatrix(question)}>



                  {/* <div className="flex justify-between">
        {stage!==3?
          <div><h1 className="text-xl font-medium">Matrix</h1>
            <p className="py-1 text-sm">{question && question.name?"1. "+question.name:""}</p>
          </div>
        :null}
          
          <div className="ml-4 whitespace-nowrap"><span className="text-center text-blue-500 cursor-pointer hover:text-blue-700">{stage==2?"View More":(stage==3?"":"View More")}</span></div>
        </div> */}


                  {/* <div className="flex justify-between pb-2">
                            <h1 className="text-lg font-medium">Matrix</h1>
                            <div className="ml-4 cursor-pointer whitespace-nowrap"><span className="px-4 py-2 text-xs text-center text-white bg-blue-500 rounded-md hover:bg-blue-600 ">{stage==2?"View More":(stage==3?"":"View More")}</span></div>
                        </div> 


                        <p className="py-1 text-sm text-gray-800 truncate">
                                {stage==2?
                                <b>{"Q"+(index+1)+"."}</b>
                                :
                                <b>Q1.</b>
                                }'
                        {question && question.name?"1. "+question.name:""}</p> */}


                  <div className="flex justify-between pb-2">
                    <div className="relative inline-block dropdown tooltipshow">
                      <h1 className="text-lg font-medium truncate cursor-pointer">
                        {/* {stage==2?
                                <b>{"Q"+(index+1)+"."}</b>
                                :
                                <b>Q1.</b>
                                } */}
                        {question && question.name && question.name.length > 30 ? question.name.slice(0, 30) + "..." : (question && question.name ? question.name : "")}</h1>
                      <div className="absolute z-30 hidden p-4 pt-1 text-lg font-semibold bg-white rounded-md shadow-md cursor-pointer tooltipcard dropdown-menu -left-4 -top-12">
                        {question && question.name ? question.name : ""}
                      </div>
                    </div>


                    <div className="ml-4 cursor-pointer whitespace-nowrap">
                      <button class=" text-[#2196f3]  cursor-pointer transition duration-150 flex items-center uppercase font-medium xl:text-base text-sm ">{stage == 2 ? "View More" : (stage == 3 ? "" : "View More")}<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right ml-2" width="34" height="34" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2196f3" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="5" y1="12" x2="19" y2="12"></line><line x1="15" y1="16" x2="19" y2="12"></line><line x1="15" y1="8" x2="19" y2="12"></line></svg>
                      </button>
                    </div>

                    {/* <div className="ml-4 cursor-pointer whitespace-nowrap">
                      <span className="space-x-2 text-[#2196f3]  cursor-pointer transition duration-150 flex items-center capitalize font-medium xl:text-base text-sm">
                        {stage == 2 ? "View More" : (stage == 3 ? "" : "View More")}</span>
                    </div> */}
                  </div>


                  {/* table */}
                  {/* component */}
                  <div className="w-full mt-4 " id="chatbot" style={{ height: '300px', overflowY: 'auto' }}>
                    <table className="w-full mb-2 border">
                      <thead className="z-10">

                        <tr className="border-b">


                          {FormatMatriXOptionHeading(question, false) && FormatMatriXOptionHeading(question, false).length > 0 ?
                            FormatMatriXOptionHeading(question, false).map((heading, optionindex) =>
                              <th className={optionindex !== 1 ? " border-r cursor-pointer text-xs  text-white bg-blue-400" : "border-r cursor-pointer text-xs  text-white bg-green-400 "}>
                                <p className="w-full p-2 text-sm font-medium border-b">{heading.name.split("-")[0]}</p>


                                <div className="flex font-normal ">
                                  {FormatMatriXOptionHeading(question, true) && FormatMatriXOptionHeading(question, true).length > 0 ?
                                    FormatMatriXOptionHeading(question, true).map((option, optionindex) =>
                                      option.heading === heading.name ?
                                        <p className="w-full p-2 border-r">{option.name}</p>
                                        : null
                                    ) : null}
                                </div>


                              </th>
                            ) : null}



                        </tr>


                      </thead>
                      <tbody>
                        {question && question.SubQuestionList && question.SubQuestionList.length > 0 ?
                          question.SubQuestionList.map((SubQues, index) =>
                            <>
                              <tr className="text-xs text-gray-600 bg-white border-b">
                                <td className="p-1 text-center border-r bg-gray-50" colSpan={2}> {SubQues.name} </td>
                              </tr>
                              <tr className="text-xs text-gray-600 bg-white border-b">

                                {FormatMatriXOptionHeading(question, false) && FormatMatriXOptionHeading(question, false).length > 0 ?
                                  FormatMatriXOptionHeading(question, false).map((heading, optionindex) =>
                                    <td className="border-r ">
                                      <div className="flex">
                                        {FormatMatriXOptionHeading(question, true) && FormatMatriXOptionHeading(question, true).length > 0 ?
                                          FormatMatriXOptionHeading(question, true).map((option, optionindex) =>
                                            option.heading === heading.name ?
                                              <div className="flex justify-center w-full font-semibold">{getCountPerc(SubQues, option.name, heading.name)}</div>
                                              : null) : null}
                                      </div>
                                    </td>
                                  ) : null}

                              </tr>
                            </>
                          ) : null}

                      </tbody>
                    </table>
                  </div>
                  {/* table end */}
                </div>
              </>
            ) : null}


        </div>







      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(PieChart);
