import React, { Component } from 'react';
import { connect } from 'react-redux';

class DriverTable extends Component {
  constructor(props) {
    super(props);
    this.state = {


    }
  }

  componentDidMount() {

  }


  render() {
    let { crrManagersList, getIndexDriverManagerPIDWiseData, IndexDriverData, GetParameterLabel, DemographicRestriction, getQuestionData, optionTypeM, DemographicDriverManagerData, Name, ManagerDynaObj, EmpFilterData, CurrentManagerData, ManagerIndexDriverData, ParaLabelList } = this.props;

    console.log("getIndexDriverManagerPIDWiseData---------->", getIndexDriverManagerPIDWiseData)

    let listPara = [];
    if (optionTypeM === 1) {
      listPara = ["Time", "Being", "WorkLife", "Achivement", "Relationship", "Meeningfull"];
    }
    else if (optionTypeM === 2) {
      listPara = ["Pride", "Presence", "Performance"];
    }
    else if (optionTypeM === 5) {
      listPara = ["Connect", "ACT", "Respect", "Empower"];
    }


    //-------------------------------------------------------------------------------------
    let DataFinalList = listPara;

    function getManagerName(mngrId) {
      let name = ""
      if (crrManagersList && crrManagersList.length > 0) {
        let getIndex = crrManagersList.findIndex(prev => prev.emp_id.toString() === mngrId.toString());
        if (getIndex !== -1) {
          name = crrManagersList[getIndex]["name"]
        }
      }
      return name ? name : mngrId
    }

    function getFavourabilityScore(para, type) {
      let score = 0;
      if (type === "overall") {
        score = IndexDriverData && IndexDriverData[para + "Count"] ? parseFloat((IndexDriverData[para + "Count"]).toFixed(2)) : 0;
      } else {

        if (getIndexDriverManagerPIDWiseData && getIndexDriverManagerPIDWiseData.length > 0) {
          let getIndex1 = getIndexDriverManagerPIDWiseData.findIndex(prev => prev.mngrId === type);
          if (getIndex1 !== -1) {
            score = getIndexDriverManagerPIDWiseData && getIndexDriverManagerPIDWiseData[getIndex1] && getIndexDriverManagerPIDWiseData[getIndex1]["finalData"] && getIndexDriverManagerPIDWiseData[getIndex1]["finalData"][para + "Count"] ? parseFloat((getIndexDriverManagerPIDWiseData[getIndex1]["finalData"][para + "Count"]).toFixed(2)) : 0;
          }

        }
      }

      return score;
    }




    //-------------------------------------------------------------------------------------

    ////console.log('>>>>-----------compare------>>>>; ')

    let ParameterObj = {}
    if (DemographicDriverManagerData && DemographicDriverManagerData.length > 0) {
      ParameterObj = DemographicDriverManagerData[0]
    }
    let ParameterList1 = []
    if (Object.keys(ParameterObj) && Object.keys(ParameterObj).length > 0) {
      Object.keys(ParameterObj).forEach((item) => {
        if (item !== 'name' && item != 'id') {
          var newStr = item.slice(0, item.length - 3)
          ParameterList1.push(newStr);
        }
      });
    }

    ////console.log('>>->>,><<.---->>ParameterList; ',ParameterList1);

    let ParameterList = []

    if (listPara && listPara.length > 0) {
      listPara.forEach((item) => {
        if (ParameterList1 && ParameterList1.length > 0) {
          ParameterList1.forEach((item1) => {
            if (item1 === item) {
              ParameterList.push(item1);
            }
          });
        }

      })
    }






    ////console.log('>>>>ManagerIndexDriverData; ',ManagerIndexDriverData)

    let colorList = ['#dab3ff', '#ffdf91', '#94ebcd', '#ffb4b4']

    let ManagerDimensionList = []

    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((filter) => {
        if (DemographicRestriction.findIndex(prev => prev === filter.name) !== -1) {
          ManagerDimensionList.push({
            "filterName": filter.name,
            "filterValue": CurrentManagerData[filter.name]
          });
        }
      })
    }

    let FieldList = []
    if (ManagerDimensionList && ManagerDimensionList.length > 0) {
      ManagerDimensionList.forEach((value) => {
        if (ManagerDynaObj && ManagerDynaObj.length > 0) {
          ManagerDynaObj.forEach((obj) => {

            if (obj.name === value.filterValue) {
              let data = {
                ...obj,
                level: value.filterName
              }
              FieldList.push(data)
            }
          })
        }
      })
    }












    let paraList = []

    let count = 0;
    let rowSpan = 0;
    let NewParaList = []

    if (getQuestionData && getQuestionData.length > 0) {

      getQuestionData.forEach((ques) => {
        if (paraList.findIndex(prev => prev === ques.parameterDimensionName) === -1) {
          paraList.push(ques.parameterDimensionName);
          count += 1
        }
        else {
        }
      })

      let obj = {}
      paraList.forEach((para) => {
        obj[para] = 0
        getQuestionData.forEach((ques) => {
          if (para === ques.parameterDimensionName) {
            obj[para] += 1
          }
        })
      })


      NewParaList = Object.keys(obj).map((key) => [key, obj[key]])
      //   ////console.log('data NewParaList: ',NewParaList)

      rowSpan = (getQuestionData.length / count)

      // ////console.log('Your Engagement paraList: ',NewParaList,count,rowSpan,getQuestionData.length)

    }







    let QuestionList = []

    if (NewParaList && NewParaList.length > 0) {
      NewParaList.forEach((para, index) => {
        if (getQuestionData && getQuestionData.length > 0) {
          getQuestionData.forEach((ques, queIndex) => {
            if (ques.parameterDimensionName === para[0]) {
              let total = ques["1"] + ques["2"] + ques["3"] + ques["4"] + ques["5"] + ques["6"];
              let sum = ques["5"] + ques["6"];

              let score = (((sum * 100) / total).toFixed(2)).toString() + "%"

              QuestionList.push({
                "name": ques.name,
                "score": score,
                "para": ques.parameterDimensionName
              })
            }
          })
        }
      })
    }

    let paraStore = []
    let previousPara = ''
    if (QuestionList && QuestionList.length > 0) {
      QuestionList.forEach((question) => {
        let show = 1
        if (question.para === previousPara) {
          show = 0;
        }
        else {
          show = 1
        }

        paraStore.push({
          "para": question.para,
          "show": show
        })

        show = 1;
        previousPara = question.para;


      })
    }





    // if(FieldList && FieldList.length>0){
    //   FieldList.forEach((item)=>{
    //     if(item.level ==='ManagerPID'){
    //       ////////console.log("---->>>>---->>>",item["TimeAvg"])
    //     }
    //   })
    // }




    let overAllNewObj = {

    }



    if (IndexDriverData) {
      let DriverScoreList = Object.keys(IndexDriverData);
      if (DriverScoreList && DriverScoreList.length > 0) {
        DriverScoreList.forEach((para) => {
          const driverName = para.slice(0, -5);
          if (driverName) {
            overAllNewObj[driverName + 'Avg'] = IndexDriverData[para];
          }
        });
      }
    }
    ////console.log('aaaaaaaaaaaaaaaaaaaoverAllNewObj: ',overAllNewObj);







    ////console.log('ManagerIndexDriverData: ',ManagerIndexDriverData);
    function getColor(score, overall) {

      if (score === 0) {
        return { Color: '#ffffff', diffTextColor: '#000000' }

      }
      else {

        let diffNo = parseInt(Math.abs(parseFloat(overall) - parseFloat(score)));
        // ////////console.log("diffNo:",diffNo)
        if (parseFloat(overall) <= parseFloat(score)) { //greater (blue)
          if (diffNo >= 0 && diffNo <= 2) {
            return { Color: '#33cc33', diffTextColor: '#ffffff' }

          }
          else if (diffNo >= 2 && diffNo <= 12) {
            return { Color: '#70db70', diffTextColor: '#00000' }

          }
          else {
            return { Color: '#adebad', diffTextColor: '#00000' }
          }

        }
        else { //lesse (red)
          if (diffNo >= 0 && diffNo <= 2) {
            return { Color: '#f5babd', diffTextColor: '#000000' }

          }
          else if (diffNo >= 2 && diffNo <= 12) {
            return { Color: '#ea95a1', diffTextColor: '#000000' }

          }
          else {
            return { Color: '#d03e4d', diffTextColor: '#ffffff' }

          }
        }
      }

    }

    return (
      <>

        <div className="pb-2  whitespace-nowrap overflow-x-auto ">
          <table className="border-collapse w-full ">
            <thead>
              <tr className="bg-gray-100 border-b text-sm  text-[#3D405B]">


                <th className="px-4 py-2 font-bold text-left border ">{"Drivers"}</th>
                <th className="px-4 py-2 font-bold text-left border ">{"Overall (%)"}</th>


                {getIndexDriverManagerPIDWiseData && getIndexDriverManagerPIDWiseData.length > 0 ?
                  getIndexDriverManagerPIDWiseData.map((item) =>
                    <th className="px-4 py-2 font-bold text-center border ">{getManagerName(item.mngrId)}</th>
                  )
                  : null}

              </tr>
            </thead>
            <tbody>
              <>
                {DataFinalList && DataFinalList.length > 0 ?
                  DataFinalList.map((driver, driverIndex) =>
                    <tr key={driverIndex} className="border-separate border border-black-800">
                      <td className="px-4 py-2 text-sm border border-b  text-left">{GetParameterLabel(driver)}</td>
                      <td className="px-4 py-2 text-sm border border-b  text-left">
                        {getFavourabilityScore(driver, "overall")}</td>

                      {getIndexDriverManagerPIDWiseData && getIndexDriverManagerPIDWiseData.length > 0 ?
                        getIndexDriverManagerPIDWiseData.map((item, index) =>
                          <td className="px-4 py-2 text-sm border border-b text-center bg-blue-500" style={{

                            background: getColor(getFavourabilityScore(driver, item.mngrId), getFavourabilityScore(driver, "overall")).Color,
                            color: getColor(getFavourabilityScore(driver, item.mngrId), getFavourabilityScore(driver, "overall")).diffTextColor


                          }}>  {getFavourabilityScore(driver, item.mngrId)} </td>
                        )
                        : null}
                    </tr>
                  )
                  : null}
              </>



            </tbody>
          </table>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DriverTable);
