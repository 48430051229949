import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";
import { graphic } from 'echarts';

class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList: [],

    }
  }
  componentDidMount() {

  }


  render() {

    let { handleSelectEDA, valueList, GetParameterLabel } = this.props;

    let list = valueList;


    function spliceDecimal(no) {
      if (no) {
        let str = no.toString();
        let first = str.split('.')[0];
        let second = str.split('.')[1];
        let second2 = second ? second.substring(0, 2) : ""
        let final = first + '.' + second2;
        if (second2) {
          return parseFloat(final).toFixed(2)
        } else {
          return no
        }
      } else {
        return 0
      }
    }



    let ParaNameList = []
    let RadarValueList = []

    let insertData = []


    list.sort((a, b) => a[1] - b[1])

    if (list && list.length > 0) {

      list.forEach((axisData) => {
        ParaNameList.push((axisData[0]));

        if (axisData[1]) {
          insertData.push(spliceDecimal(axisData[1]))
        }
        else {
          insertData.push(spliceDecimal(axisData[1]))
        }


      })

    }
    console.log('list--->>>:', list)
    console.log('ParaNameList--->>>:', ParaNameList)
    console.log('insertData--->>>:', insertData)



    function getCountByName(nameValue) {
      let count = 0;
      if (list && list.length > 0) {
        let getIndex = list.findIndex(prev => prev[0] === nameValue);
        if (getIndex !== -1) {
          count = list && list[getIndex] && list[getIndex][2] ? list[getIndex][2] : 0
        }
      }
      return count
    }



    console.log('insertData--------------->', insertData)




    return (


      <div className=" w-full">

        {list && list.length > 0 ?
          <ReactEcharts
            className='!w-full'
            onEvents={{
              'click': (params) => handleSelectEDA(params)
            }}
            style={{
              height: list && list.length > 12 ? '1500px' : '600px'
            }}
            option={{
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                },
                fonteS: 'bold',
                formatter: function (a, b, c, d) {
                  let nameLabel = a && a[0] && a[0].name ? a[0].name : "";
                  let valueLabel = a && a[0] && a[0].value ? a[0].value : "NA";
                  let valueScore = (nameLabel ? '<b>' + nameLabel + ': ' + (valueLabel && valueLabel !== "NA" ? valueLabel + '%' : "NA") + '</b> ' : '</b> ');
                  let htmlElement = '<p>' + valueScore + '</p>'
                  if (getCountByName(nameLabel)) {
                    htmlElement += '<p>' + '<b>Count: ' + getCountByName(nameLabel) + '</b></p>'
                  }
                  return htmlElement
                }
              },
              legend: {
                data: []
              },

              xAxis: {
                type: 'value',
                splitLine: {
                  lineStyle: {
                    color: '#f2f2f2'
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#000'
                  }
                }
              },
              yAxis: {
                type: 'category',
                data: ParaNameList,
                axisLine: {
                  lineStyle: {
                    color: '#000'
                  }
                },
                axisLabel: {
                  color: "#212121",
                  fontFamily: 'Poppins, sans-serif', // Title font family
                  // fontStyle: 'bold',
                  textStyle: {
                    fontSize: 14,
                    fontWeight: '400'
                  },
                  formatter: function (value) {
                    let wordNEW = GetParameterLabel(value)
                    let word = wordNEW.replace('_', '')

                    let word2 = word;
                    if (word.length > 40) {
                      word2 = word.substring(0, 40) + "..";
                    } else {
                      word2 = word;
                    }

                    return word2
                  }
                },
              },
              // axisLabel: {
              //   color: "#000",
              //   interval: 0,
              //   formatter: function(value) {
              //     if (value.length > 40) {
              //       return value.substring(0, 40) + "..";
              //     } else {
              //       return value;
              //     }
              //   }
              // },
              series: [
                {
                  name: 'Score',
                  type: 'bar',
                  barWidth: '20px',
                  barCategoryGap: '10px',
                  stack: '总量',
                  label: {
                    show: true,
                    fontFamily: 'Poppins, sans-serif', // Title font family
                    // fontStyle: 'bold',
                    textStyle: {
                      fontSize: 14,
                      fontWeight: 'bold'
                    },
                    position: 'right',
                    formatter: function (value) {
                      return value.data > 0 ? value.data + '%' : "0";
                    }
                  },
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#62cff4'
                    },
                    {
                      offset: 1,
                      color: '#2c67f2'
                    }]),
                  data: insertData

                },

              ]
              ,
              grid: {
                left: '2%',
                right: '2%',
                top: '0%',
                bottom: '0%',
                containLabel: true
              },
            }}
          />
          : null}




      </div>

    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(BarChart);
