import React, { Component } from "react";
import { connect } from "react-redux";
import DemographicFilter from "./Filters/DemographicFilter";
import QuestionFilter from "./Filters/QuestionFilter";
import ClusterChart from "./Content/ClusterChart";
import ClusterKeywords from "./Content/ClusterKeywords";

import WordDetails from "./Content/WordDetails";
import Comments from "./Content/Comments";
import Commentary from "../../../Index/Components/Commentary/Commentary";
import WordsBarChart from "./Content/Charts/WordsBarChart";
import NudgesTextDist from "../../../../CommonComponents/Nudges/NudgesTextDist";

class TextCluster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuesId: "",
      DemographicFilterName: "",
      DemographicFilterValue: "",
      FilterList: [],
      showFilter: false,
      showValue: "",

      showViewBy: false,
      clusterNo: 1,
      crrkeyword:''
    };
  }

  componentDidMount() {}

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };

  closeFilterQues = (check1) => {
    this.setState({ showViewBy: check1 });
  };

  handleChangeQuesIdSave = (Id) => {
    this.setState({ currentQuesId: Id });
  };

  FilterSave = (name, value) => {
    this.setState({
      DemographicFilterName: name,
      DemographicFilterValue: value,
    });
  };

  handleKeywordCall = (keyWord) => {
    let {
      currentQuesId,
      DemographicFilterName,
      DemographicFilterValue,
      FilterList,
    } = this.state;
    let { getTextQuestionData, callWordCloudText } = this.props;

    let initQuesId = "";
    if (currentQuesId) {
      initQuesId = currentQuesId;
    } else {
      initQuesId =
        getTextQuestionData && getTextQuestionData.length > 0
          ? getTextQuestionData[0].id
          : "";
    }

    callWordCloudText(initQuesId, keyWord, FilterList);
  };

  handleMultifilterIndexScore2 = (finalFilter) => {
    // let { getTextQuestionData } = this.props;
    // let { currentQuesId , graphType } = this.state;
    // let initQuesId = '';
    // if(currentQuesId){
    //   initQuesId = currentQuesId;
    // }
    // else{
    //   initQuesId = getTextQuestionData && getTextQuestionData.length>0?getTextQuestionData[0].id:'';
    // }

    // let { handleWordCloudDemographicFilter }= this.props;

    // this.setState({FilterList:finalFilter});

    // handleWordCloudDemographicFilter(initQuesId,finalFilter, graphType, "wordCloud");

    let { getClusterSentences, getTextQuestionData, getTextClusterFilter } =
      this.props;

    let { currentQuesId, clusterNo } = this.state;

    let initQuesId = "";
    if (currentQuesId) {
      initQuesId = currentQuesId;
    } else {
      initQuesId =
        getTextQuestionData && getTextQuestionData.length > 0
          ? getTextQuestionData[0].id
          : "";
    }
    this.setState({ FilterList: finalFilter });
    getTextClusterFilter(initQuesId, finalFilter);
  };

  callQuestionWordCloudQuesChange = (initQuesId) => {
    let {
      callWordCloudText,
      handleWordCloudDemographicFilter,
      getTextClusterFilter,
    } = this.props;
    let { FilterList } = this.state;
    // callWordCloudText(initQuesId, "", FilterList);

    getTextClusterFilter(initQuesId, FilterList);
    // handleWordCloudDemographicFilter(initQuesId,FilterList, "", "wordCloud");
    // getClusterSentences(initQuesId,FilterList,clusterNo,keyword);
  };

  get1KeywordsByCluster = (clusterNo) => {
    let { getKeywordsByCluster, getTextQuestionData } = this.props;

    let { currentQuesId, FilterList } = this.state;
    this.setState({ clusterNo: clusterNo });

    let initQuesId = "";
    if (currentQuesId) {
      initQuesId = currentQuesId;
    } else {
      initQuesId =
        getTextQuestionData && getTextQuestionData.length > 0
          ? getTextQuestionData[0].id
          : "";
    }

    getKeywordsByCluster(initQuesId, FilterList, clusterNo);
  };

  getClusterSentencesCall = (keyword) => {
    let { getClusterSentences, getTextQuestionData } = this.props;

    let { currentQuesId, FilterList, clusterNo } = this.state;

    let initQuesId = "";
    if (currentQuesId) {
      initQuesId = currentQuesId;
    } else {
      initQuesId =
        getTextQuestionData && getTextQuestionData.length > 0
          ? getTextQuestionData[0].id
          : "";
    }
    this.setState({crrkeyword:keyword})

    getClusterSentences(initQuesId, FilterList, clusterNo, keyword);
  };

  render() {
    let {
      GetParameterLabel,
      getClusterSaveKeyWord,
      getClusterSentencesData,
      getClusterKeywordsData,
      getKeywordsByCluster,
      getTextClusterSentimentCountData,
      getTextClusterData,
      ErrorOtherObj,
      wordCloudError,
      loading,
      callQuestionWordCloud,
      wordTextKeyWord,
      wordCloudTextData,
      getTextQuestionData,
      handleChangeQuestion,
      wordCloudData,
      EmpFilterData,
      getIndexByFilterDMGData,
      topicCloudData
    } = this.props;

    let { currentQuesId, clusterNo } = this.state;


    console.log('topicCloudData--------------> ',topicCloudData);

    

    let initQuesId = "";

    if (currentQuesId) {
      initQuesId = currentQuesId;
    } else {
      initQuesId =
        getTextQuestionData && getTextQuestionData.length > 0
          ? getTextQuestionData[0].id
          : "";
    }

    let { CountRestrict } = this.props;
    let count =
      wordCloudData && wordCloudData.count ? wordCloudData.count : "NaN";
    let CloudData = CountRestrict(count) ? [] : wordCloudData;
    let CloudTextData = CountRestrict(count) ? [] : wordCloudTextData;
    let wordTextKeyWordData = CountRestrict(count) ? "" : wordTextKeyWord;
    let newWordList = CountRestrict(count)
      ? []
      : wordCloudData && wordCloudData.list && wordCloudData.list.length > 0
      ? wordCloudData.list
      : [];

    let maxCount = 0;
    if (newWordList && newWordList.length > 0) {
      newWordList.forEach((item) => {
        if (item[1] > maxCount) {
          maxCount = item[1];
        }
      });
    }
    let { indexType, optionType, getCommentData } = this.props;

    console.log("getClusterKeywordsData---->", getClusterKeywordsData);

    function getClusterLabel(elementNo) {
      let clusterLabels = [
        "First Cluster",
        "Second Cluster",
        "Third Cluster",
        "Fourth Cluster",
        "Fifth Cluster",
        "Sixth Cluster",
        "Seventh Cluster",
        "Eighth Cluster",
        "Nineth Cluster",
        "Tenth Cluster",
      ];
      let item =
        clusterLabels && clusterLabels.length > 0
          ? elementNo
            ? clusterLabels[Number(elementNo) - 1]
            : "-"
          : "-";
      return item;
    }

    return (
      <>
        <div onClick={() => this.closeFilter(false)}>
          <div className="flex flex-wrap items-start justify-between w-full gap-4">
            <QuestionFilter
              showViewBy={this.state.showViewBy}
              closeFilterQues={this.closeFilterQues}
              callQuestionWordCloud={this.callQuestionWordCloudQuesChange}
              getTextQuestionData={getTextQuestionData}
              handleChangeQuestion={handleChangeQuestion}
              handleChangeQuesIdSave={this.handleChangeQuesIdSave}
            />
          </div>
        </div>

        {/*sub nav bar 2 */}
        <div className="items-center justify-between space-y-4 md:flex lg:space-y-0">
          <DemographicFilter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}
            EmpFilterData2={EmpFilterData}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
          />

         <div className="flex flex-wrap gap-2 ">
            <NudgesTextDist
              name={"Word Cloud"}
              list={
                CloudData && CloudData.list && CloudData.list.length > 0
                  ? CloudData.list
                  : []
              }
            />
            <Commentary
              indexType={indexType}
              optionType={optionType}
              getCommentData={getCommentData}
            />
          </div>

          {/* <div className="flex items-center space-x-3 capitalize">
          <p className="text-sm text-gray-500 capitalize ">total comments :<b className="font-semibold text-gray-600">{wordCloudTextData && wordCloudTextData.length>0?wordCloudTextData.length:0}</b></p>
        </div> */}
        </div>

        {ErrorOtherObj && ErrorOtherObj["wordCloudTextError"] ? (
          <>
            <div className="my-24 text-2xl font-medium text-center text-gray-400">
              {ErrorOtherObj["wordCloudTextError"]}
            </div>
          </>
        ) : (
          <>
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-1 ">
              <ClusterChart
                getTextClusterData={getTextClusterData}
                getTextClusterSentimentCountData={
                  getTextClusterSentimentCountData
                }
                getKeywordsByCluster={this.get1KeywordsByCluster}
                clusterNumber={clusterNo}
                getClusterLabel={getClusterLabel}
                wordCloudError={wordCloudError}
                loading={loading}
                wordCloudData={CloudData}
                handleKeywordCall={this.handleKeywordCall}
              />
            </div>

            <div className="grid grid-cols-1 gap-4 2xl:grid-cols-4 lg:grid-cols-3 ">
               
                <ClusterKeywords
                  getClusterKeywordsData={getClusterKeywordsData}
                  getClusterSentencesCall={this.getClusterSentencesCall}
                  clusterNumber={clusterNo}
                  getClusterLabel={getClusterLabel}
                  wordCloudError={wordCloudError}
                  loading={loading}
                  wordCloudData={CloudData}
                  handleKeywordCall={this.handleKeywordCall}
                  crrkeyword={getClusterSaveKeyWord}
                />
             

              
                <WordDetails
                  GetParameterLabel={GetParameterLabel}
                  getClusterSentencesData={getClusterSentencesData}
                  EmpFilterData2={EmpFilterData}
                  loading={loading}
                  wordCloudTextData={CloudTextData}
                  getClusterSaveKeyWord={getClusterSaveKeyWord}
                />
              
            </div>
          </>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn,
  };
}
export default connect(mapStateToProps)(TextCluster);
