import React, { Component } from 'react';
import { connect } from 'react-redux';

import Filter from '../../../../../CommonComponents/Filters/IndexFilter/Filter';
import HeatMap from './Components/HeatMap';
import BarChart from './Components/BarChart';

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: 'none',
      viewScore: true,
      showLeaderBoard: false,
      showFilter: false,
      showValue: '',
      XAxis: ""
    }
  }
  componentDidMount() {

  }
  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleFilterSave = (name) => {
    this.setState({ currFilterName: name })
  }



  handleMultifilterIndexScore2 = (finalFilter, XAxis) => {
    ////console.log(':----------------------------------------------------------------------------------------------------------------XAxis:-------------------->',finalFilter, XAxis)

    let { handleOthersFilterCall } = this.props;
    this.setState({ FilterValues: finalFilter, XAxis: XAxis });
    handleOthersFilterCall(finalFilter, XAxis);

  }

  render() {
    let {
      getQuestionOthersResultData, getQuestionOthersResultFilterData, getEMPFilterLabel, currentQuestionId, EmpFilterData } = this.props;

    let { currFilterName, FilterValues, XAxis } = this.state;

    //-------------------------------------------------------------------------------------------------------------------------

    let nEwAxis = "";
    if (XAxis) {
      nEwAxis = XAxis;
    }
    else if (EmpFilterData && EmpFilterData.length > 0) {
      nEwAxis = EmpFilterData[0].name;
    }





    ////////console.log("//-------------------------------------------------------------------------------------------------------------------------getQuestionOthersResultData",getQuestionOthersResultData)

    let QuestionName = "";

    function FormattFun(inputData) {
      let LIST = []
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {


          if (16 === ques.total[0]._id.qtemplate) {


            let temp = {
              name: ques && ques.question[0] && ques.question[0].name ? ques.question[0].name : "",
              answerList: []
            }
            QuestionName = temp["name"];

            if (ques && ques.total && ques.total.length > 0) {
              ques.total.forEach((item) => {
                let ans = {
                  count: item.count,
                  answerText: item._id.answerText,
                  template: item._id.qtemplate,
                  dmgValue: item._id[nEwAxis]
                }
                temp["answerList"].push(ans);
              });
            }
            LIST.push(temp);
          }
        });
      }
      return LIST
    }

    let NPSQuesList = FormattFun(getQuestionOthersResultData);
    let NPSQuesListFilter = FormattFun(getQuestionOthersResultFilterData);
    ////////console.log("//-------------------------------------------------------------------------------------------------------------------------getQuestionOthersResultData",getQuestionOthersResultData)
    //////console.log("aaaaaaaaaaaaaaaaaaa==========>currentQuestionId",currentQuestionId)
    //////console.log("aaaaaaa==========>NPSQuesList",NPSQuesList)
    //////console.log("aaaaaaa==========>NPSQuesListFilter",NPSQuesListFilter)
    ////////console.log("NPSQuesListFilter 16",NPSQuesListFilter)

    let totalCount = 0;

    let Promoters = 0;
    let Passsives = 0;
    let Distractors = 0;

    let DemographicValues = [];
    let DemographicValuesObj = {};

    let item = NPSQuesList[0];
    let itemOthers = NPSQuesListFilter[0];



    let AnswerObjOverall = {};
    if (item && item.answerList && item.answerList.length > 0) {
      item.answerList.forEach((ans) => {
        //////console.log('ans (NPS)',ans)
        if (ans && ans.answerText) {
          if (AnswerObjOverall && AnswerObjOverall[ans.answerText]) {
            AnswerObjOverall[ans.answerText] += (ans.count);
          }
          else {
            AnswerObjOverall[ans.answerText] = (ans.count)
          }
        }


      });
    }


    ////////console.log("--AnswerObjOverall 16---",AnswerObjOverall)

    let KeyList = AnswerObjOverall ? Object.keys(AnswerObjOverall) : []

    let OverAllValueList = []

    let TotalOverAllCount = 0;

    if (KeyList && KeyList.length > 0) {
      KeyList.forEach((item3) => {
        TotalOverAllCount += parseInt(AnswerObjOverall[item3])
        OverAllValueList.push(AnswerObjOverall[item3])
      })
    }


    let AnswerObj = {};
    let AnswerObjList = [];

    if (itemOthers && itemOthers.answerList && itemOthers.answerList.length > 0) {




      itemOthers.answerList.forEach((ans) => {
        //////console.log("--ans 16---",ans)

        let getIndex = DemographicValues.findIndex(prev => prev === ans.dmgValue);
        if (getIndex === -1) {
          DemographicValues.push(ans.dmgValue);

          DemographicValuesObj[ans.dmgValue] = {
            "count": 0
          }

          if (KeyList && KeyList.length > 0) {
            KeyList.forEach((item2) => {
              DemographicValuesObj[ans.dmgValue][item2] = 0
            });
          }


        }
        //////console.log('ans (NPS)',ans)
        //console.log('//console.log DemographicValuesObj',DemographicValuesObj)

        let code = ans.answerText + ans.dmgValue;

        let getIndex1 = AnswerObjList.findIndex(prev => prev.code === code);
        if (getIndex1 === -1) {
          AnswerObjList.push({
            "text": ans.answerText,
            "name": ans.dmgValue,
            "value": ans.count,
            "code": ans.answerText + ans.dmgValue
          });
        }
        else {
          AnswerObjList[getIndex1].value += ans.count;
        }


        //  if(AnswerObj && AnswerObj[ans.answerText] && AnswerObj[ans.answerText].name){
        //    AnswerObj[ans.answerText]["value"] += (ans.count);
        //  }
        //  else{
        //    AnswerObj[ans.answerText]={
        //      "name":ans.dmgValue,
        //      "value":(ans.count)
        //    }
        //  }

      });
    }


    //////console.log('AnswerObj:',AnswerObj)

    if (AnswerObjList && AnswerObjList.length > 0) {

      ////console.log('--ssssssss->AnswerObjList:',AnswerObjList)
      ////console.log('--->DemographicValues:',DemographicValues)



      //////console.log('test : AnswerObj',AnswerObj)
      AnswerObjList.forEach(item1 => {
        //  let score = parseInt(AnswerObj[key]["value"]);
        //  let name = AnswerObj[key]["name"];
        //////console.log('test : score',score)
        //////console.log('test : name',name)
        //////console.log('test : key',parseInt(key))

        let key = item1.text;
        let name = item1.name;
        let score = item1.value;

        totalCount++;
        DemographicValuesObj[name]["count"] += score;

        if (KeyList && KeyList.length > 0) {
          KeyList.forEach((opt) => {
            if ((key) === opt) {
              DemographicValuesObj[name][opt] += score;
            }

          });
        }



      });
    }








    //////console.log('NPSQuesList:',NPSQuesList)
    //////console.log('handleDemographicFilter:',handleDemographicFilter)


    let { getIndexFilterData } = this.props;




    ////////console.log("DemographicValues-------------->",DemographicValues)
    //////console.log("ddddDemographicValuesObj-------------->",DemographicValuesObj)





    return (
      <>
        <div className="items-center justify-between md:flex">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}
            EmpFilterData2={EmpFilterData}
            getIndexFilterData={getIndexFilterData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />
        </div>

        <div onClick={() => this.closeFilter(false)} className="">
          <div className="flex w-full" >

            {!(FilterValues && FilterValues.length > 0) ?
              <>
                <div className="w-full px-4 pb-10 bg-white border rounded">
                  <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] pt-4">{QuestionName}
                    {/* <p className="text-sm font-semibold text-gray-500">NPS QUESTION</p> */}
                  </h1>
                  {item && item.answerList[0] && item.answerList[0].template === 16 ?
                    <>
                      <BarChart
                        AnswerObjOverall={AnswerObjOverall}
                        DriverName={KeyList}
                        DriverValues={OverAllValueList}
                        TotalOverAllCount={TotalOverAllCount}
                      />
                    </>
                    : null}
                </div>

              </>
              :
              <div className="w-full px-4 bg-white border rounded mb-60">
                <div className="flex items-center justify-between px-1 py-4 text-base font-semibold text-gray-700">
                  <h1 className="font-medium xl:text-xl text-lg text-[#3D405B]">{QuestionName}
                    {/* <p className="text-sm font-semibold text-gray-500">NPS QUESTION</p> */}
                    <p className="text-base font-semibold text-gray-700"><span className="text-blue-500">{(currFilterName !== 'none') ? "( " + currFilterName + " wise )" : ""}</span></p>
                  </h1>
                </div>

                <HeatMap
                  AnswerObjOverall={AnswerObjOverall}
                  DemographicValues={DemographicValues}
                  DemographicValuesObj={DemographicValuesObj}
                  XAxis={XAxis}
                />


              </div>
            }

          </div>
        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
