import React from "react";
import { GoDotFill } from "react-icons/go";

function Content(props) {

  let { multiFeedbackList, responseList, handleInnerTextInput, saveFeedbackText } = props;

  const getTextValue = (list, taskId, name) => {
    let textValue = "";
    if (list && list.length > 0) {
      let getIndex = list.findIndex(prev => prev.taskId.toString() === taskId.toString());
      if (getIndex !== -1) {
        let innerList = list && list[getIndex] && list[getIndex]["feedList"] && list[getIndex]["feedList"].length > 0 ? list[getIndex]["feedList"] : [];
        console.log("innerList====>", innerList, "-------name----------->", name)
        if (innerList && innerList.length > 0) {
          let getIndeY = innerList.findIndex(prev => prev.name === name);
          if (getIndeY !== -1) {
            textValue = innerList[getIndeY]["text"];
          }
        }

      }
    }
    return textValue
  }

  return (
    <>

      <div className='border rounded-lg overflow-hidden'>
        <div className='flex items-center justify-between w-full p-4 bg-[#E6F5F3] border-b'>
          <h2 className='text-lg font-semibold'>Activity</h2>
          <button className='text-[#009688]'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
          </button>
        </div>

        <div className='px-4 space-y-4 py-4 '>
          <div className='divide-y'>
            {multiFeedbackList && multiFeedbackList.length > 0 && multiFeedbackList.map((task) =>
              <>
                <div className='py-4'>
                  <p className='font-semibold'>{task && task.desc ? task.desc : ""}</p>
                  {task && task.list && task.list.length > 0 &&
                    task.list.map((ele, index) =>
                      <div className='flex items-center px-4 space-y-4 space-x-3 py-4' key={index}>
                        <div className='font-semibold w-56 whitespace-nowrap'>{ele.title}: {" "}</div>
                        <textarea onChange={(e) => handleInnerTextInput(task.id, ele.title, e.target.value)}
                          value={getTextValue(responseList, task.id, ele.title)}
                          className='border p-4 w-full rounded-lg' placeholder={ele.desc}></textarea>
                      </div>
                    )}
                </div>

                <button
                  onClick={() => saveFeedbackText(task.id, true, 2)}
                  className='flex items-center space-x-2 bg-[#009688] px-6 py-2 rounded-md text-white w-fit ml-auto'>
                  <span>Save & Continue</span>
                </button>

              </>
            )}
          </div>

        </div >

      </div >

    </>
  );

}
export default Content;