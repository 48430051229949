import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accessModule } from '../../_helpers';
//api call
import { dashboardActions, questionsActions, surveyActions, userActions, generalActions } from '../../_actions';
//Layout
import Sidebar from './Sidebar/Sidebar';
// DASBOARD PAGES
import ICEIndexDetails from './DashComponents/ICEIndexDetails';
import ManagerIndexDetails from './DashComponents/ManagerIndexDetails';
import OrgCore from './DashComponents/OrgCore';
import ENPS from './DashComponents/ENPS';
//libraries
import LoadingOverlay from 'react-loading-overlay';
import Overview from './Overview/Overview';
import Header from '../CommonComponents/DashboardHeader/Header';
import { CONST } from '../../_config';
import TextAnalysis from '../Dashboard/TextAnalysis/TextAnalysis';


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexType: 0,
      CurrentSurveyName: '',
      CurrentSurveyId: this.props.match.params.id,
      optionType: 0,
      showFilterDropdownName: false,
      showFilterDropdownValue: false,
      CurrentIndexName: '',
      filterSelectIndex: 0,
      loaderActive: false,
      filterOn: false,
      FilterList2: [],
      showFilter: true,

      loadReport: false,
      isFilter: false,

      showMobileSidebar: false,

      showOverview: true,
      showIndex: false,
      showManagerList: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showCustomScoreCard: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showReport: false,
      showOtherTypes: false,
      showPeopleToMeet: false,

      StackObject: [],
      StackObjectTotal: [],
      ReturnFinalFilterList: [],
      selectedFilterList: [],
      selectedFilterValue: 'none',
      HeatMapSelectedFilter: 'Department',

      DynaObj: [],

      Mindextype: '',

      customeScoreData: "",

      ManagerDynaObj: [],

      QuesDynaObj: {},

      QuesMngrHappinessReportObject: {},
      QuesMngrEngagementReportObject: {},
      QuesMngrStressReportObject: {},
      QuesMngrManagerReportObject: {},

      DriverMngrHappinessReportObject: {},
      DriverMngrEngagementReportObject: {},
      DriverMngrStressReportObject: {},
      DriverMngrManagerReportObject: {},

      MQuesIndextype: '',
      rwaIndexType: 1,
      ManagerListDasboardDataObj: [],
      ManagerListQuestionDataObj: [],

      showSidebar: false,
      currentQuestionId: '',
      currentQuestionTemplate: '',
      showAllQuestions: true,

      showReportDMGModal: false,

      ReportDMGindexType: "",
      ReportDMGcheck: "",
      ReportDMGfiltersListNew: "",
      ReportxAxis: "",
      isReportOverall: false,

      ManagerReportFConfig: {
        "managerIdType": CONST.MANAGER_ID_TYPE //Int or String
      },
      currentTextQuesId: "",
      crrSubReportType: "LT Head"

    }
  }


  componentDidMount() {
    this.callOverviewAPI();
    this.LoadOverviewInitFn();
  }


  callOverviewAPI = () => {


    let data3x3 = {
      "parentName": ["ICE", "ICE MANAGER INDEX", "ORGCROSSECORE"]

    }
    this.props.dispatch(surveyActions.getParaLibraryByParentNames(data3x3));


    let data3 = {
      "surveyId": this.state.CurrentSurveyId,
      "type": "LT Head"
    }

    this.props.dispatch(dashboardActions.getLTHeadEmpList(data3));

    let data3x = {
      "surveyId": this.state.CurrentSurveyId
    }

    this.props.dispatch(dashboardActions.getIceIndex(data3x));

    this.props.dispatch(dashboardActions.getEmpFilter());
    this.props.dispatch(surveyActions.getIndexByFilterDMG());
    this.props.dispatch(dashboardActions.getSubWiseParaScore());



  }

  LoadOverviewInitFn = () => {
    let data1 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }

    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": []
    }

    let data5 = {
      "surveyId": this.state.CurrentSurveyId
    }

    this.props.dispatch(dashboardActions.getCompanyNameOnly());
    this.props.dispatch(dashboardActions.getRespondantDetails(data5));
    this.props.dispatch(surveyActions.getSurveyById(data5));
    this.props.dispatch(dashboardActions.getClientProfile());


    let data23 = {
      "surveyId": this.state.CurrentSurveyId,
      "qtemplate": [21], //ICE
      "template": 11
    }
    this.props.dispatch(dashboardActions.getIceDmgQuestionWiseDist(data23));


    let data24 = {
      "surveyId": this.state.CurrentSurveyId,
      "qtemplate": [22],
      "xAxis": "Department"
    }

    this.props.dispatch(dashboardActions.getIceDmgWiseAnsCount(data24));

    let data25 = {
      "surveyId": this.state.CurrentSurveyId,
      // "qtemplate": [22],
      "xAxis": "Department"
    }
    this.props.dispatch(dashboardActions.getIceIndexDMGWise(data25));

    let data26 = {
      "surveyId": this.state.CurrentSurveyId,
      "template": 11, //ICE
      "xAxis": "Department"
    }
    this.props.dispatch(dashboardActions.getIceDMGDimensionScore(data26));

  }

  handleLogout = () => {
    this.props.dispatch(userActions.logout());
  }

  handleSidebar = (check) => {
    this.setState({ showSidebar: check })
  }

  handleMobileSidebar = (check) => {
    this.setState({ showMobileSidebar: check })
    this.setState({ showSidebar: check })
  }

  handleSummaryFilter = (name, value) => {
    let { CurrentSurveyId } = this.state;
    let data = {
      "surveyId": CurrentSurveyId,
      "filters": [
        { "level": name, "value": value }
      ]
    }
    this.props.dispatch(dashboardActions.getIndexByFilter(data));
  }

  handleQuestionFilter = (name, value) => {
    let { indexType, CurrentSurveyId } = this.state;
    let data = {
      "surveyId": CurrentSurveyId,
      "template": indexType,
      "filters": [
        { "level": name, "value": value }
      ]
    }
    // //////console.log('data: ',data)
    this.props.dispatch(dashboardActions.getQuestionFilter(data));
  }

  handleDistributionFilter = (name) => {
    let { indexType } = this.state;
    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": name
    }
    if (indexType === 1) {
      this.props.dispatch(dashboardActions.getDemographicIndexHappiness(data));
    }
    else if (indexType === 2) {
      this.props.dispatch(dashboardActions.getDemographicIndexEngagement(data));
    }
    else if (indexType === 4) {
      this.props.dispatch(dashboardActions.getDemographicIndexStress(data));
    }
    else if (indexType === 5) {
      this.props.dispatch(dashboardActions.getDemographicIndexManager(data));
    }
    else if (indexType === 6) {
      this.props.dispatch(dashboardActions.getDemographicIndexLeader(data));
    }
    else if (indexType === 8) {
      this.props.dispatch(dashboardActions.getDemographicIndexOrg(data));
    }
  }

  onCustomScoreCard = (item) => {

    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showReport: false,
      showOtherTypes: false,
      showAllQuestions: false,
      showCustomScoreCard: true,
      currentQuestionTemplate: '',

      customeScoreData: item,
      showPeopleToMeet: false

    })
  }

  onOverviewFromHeader = () => {
    let { CurrentSurveyId, showOverview } = this.state;
    if (showOverview) {
      this.props.history.goBack()
    }
    else {
      this.onOptionType(CurrentSurveyId, 0, 1);
    }

  }

  onOverview = (surveyId) => {
    this.setState({
      showOverview: true,
      showIndex: false,
      showManagerList: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showCustomScoreCard: false,
      showReport: false,
      showOtherTypes: false,
      showPeopleToMeet: false


    })
    // this.setState({CurrentSurveyId:surveyId})

  }

  get360DimensionItemDetails = (FilterValues, isFilterTwice, name, black, emp_id) => {
    //console.log("A3 get360DimensionItemDetails--------->",FilterValues,isFilterTwice,name, black, emp_id)

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }


    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": Filters
    }
    if (emp_id) {
      data7["emp_id"] = emp_id
    }

    //console.log("data7------------>",emp_id)
    this.props.dispatch(dashboardActions.get360DimensionQuestions(data7));
    this.props.dispatch(dashboardActions.get360RelationResponse(data7));
    this.props.dispatch(dashboardActions.getFeedbackAndQuestionName(data7));
    this.props.dispatch(dashboardActions.getTextAndQuestionName(data7));

    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": Filters
    }

    this.props.dispatch(dashboardActions.getAnsweredEmployees(data2));

  }

  get360Overview = (FilterValues, isFilterTwice, name, black, emp_id) => {

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }





    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": Filters
    }
    if (emp_id) {
      data["emp_id"] = emp_id
    }

    this.props.dispatch(dashboardActions.get360DimensionRelationScore(data));

    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": Filters
    }

    this.props.dispatch(dashboardActions.getAnsweredEmployees(data2));

  }

  get360RelationResponse = (FilterValues, isFilterTwice, name, black, emp_id) => {

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }





    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": Filters
    }
    if (emp_id) {
      data["emp_id"] = emp_id
    }

    this.props.dispatch(dashboardActions.get360RelationResponse(data));

  }

  getFeedbackAndQuestionName = (FilterValues, isFilterTwice, name, black, emp_id) => {


    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }


    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": Filters
    }
    if (emp_id) {
      data["emp_id"] = emp_id
    }


    this.props.dispatch(dashboardActions.getFeedbackAndQuestionName(data));
    this.props.dispatch(dashboardActions.getTextAndQuestionName(data));

  }


  getTemp360EmpStrengthCall = (emp_id) => {

    if (emp_id) {
      let data = {
        "surveyId": this.state.CurrentSurveyId,
        "emp_id": emp_id
      }
      this.props.dispatch(dashboardActions.getTemp360EmpStrength(data));

    }

  }

  onLoadReport = () => {

    let data1 = {
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getRespondantDetails(data1));

    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": "Department"
    }
    this.props.dispatch(dashboardActions.getMetaResponseAnalyticsFilter(data2));

    let data3 = {
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getIceIndex(data3));


    let data4 = {
      "surveyId": this.state.CurrentSurveyId,
      "qtemplate": [21],
      "template": 11 //ICE
    }
    this.props.dispatch(dashboardActions.getIceDistribution(data4));

    let data41 = {
      "surveyId": this.state.CurrentSurveyId,
      "qtemplate": [1],
      "template": 12  //ICE MANAGER
    }
    this.props.dispatch(dashboardActions.getIceDistributionV2(data41));



    let data5 = {
      "surveyId": this.state.CurrentSurveyId,
      "qtemplate": [21],
      "template": 13 //org
    }
    this.props.dispatch(dashboardActions.getIceCrossDist(data5));



    let data6 = {
      "surveyId": this.state.CurrentSurveyId,
      "qtemplate": [21],
      "template": 11 //ICE
    }
    this.props.dispatch(dashboardActions.getIceMixHeatMap(data6));


    let data61 = {
      "surveyId": this.state.CurrentSurveyId,
      "qtemplate": [21],
      "template": 13 //org
    }
    this.props.dispatch(dashboardActions.getIceMixHeatMapV2(data61));


    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "qtemplate": [22]
    }
    this.props.dispatch(dashboardActions.getIceNPSRowWise(data7));



    let data8 = {
      "surveyId": this.state.CurrentSurveyId,
      "qtemplate": [23]
    }
    this.props.dispatch(dashboardActions.getIceFeedbackList(data8));

  }





  onOptionType = (surveyId, value, type) => {
    if (value) {
      this.setState({ showOverview: false })
    } else {
      this.setState({ showOverview: true })

    }
    this.setState({ indexType: value }, () => {
      if (value === 13) {

        let data5 = {
          "surveyId": this.state.CurrentSurveyId,
          "qtemplate": [21],
          "template": 13 //ICE
        }
        this.props.dispatch(dashboardActions.getIceCrossDist(data5));

      } else if (value === 4) {

        let data7 = {
          "surveyId": this.state.CurrentSurveyId,
          "qtemplate": [22]
        }
        this.props.dispatch(dashboardActions.getIceNPSRowWise(data7));


      } else if (value === 5) {

        let data2 = {
          "surveyId": this.state.CurrentSurveyId
        }
        let data2x = {
          "surveyId": this.state.CurrentSurveyId,
          "otherType": [3, 4, 23]
        }
        this.props.dispatch(dashboardActions.getTextQuestion(data2x, 1));
        this.props.dispatch(dashboardActions.GetTextKeywords(data2));



      }
    })
  }


  handleDashICEFilter = (FilterValues, isFilterTwice, name, option, indexNo) => {
    console.log("working....", FilterValues, isFilterTwice, name, option, indexNo)
    let XAxis = "";
    let Filters = [];
    let isFilter = false;
    if (FilterValues && FilterValues.length > 0) {
      isFilter = true;
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    if (indexNo === 11) { //ICE

      if (option === 1) {
        let data7 = {
          "surveyId": this.state.CurrentSurveyId,
          "xAxis": isFilterTwice ? name : XAxis,
          "filters": Filters
        }
        this.props.dispatch(dashboardActions.getIceIndexDMGWise(data7));
      } else if (option === 2) {
        if (isFilter) {
          let data8 = {
            "surveyId": this.state.CurrentSurveyId,
            "xAxis": isFilterTwice ? name : XAxis,
            "filters": Filters,
            "template": 11
          }
          this.props.dispatch(dashboardActions.getIceDMGDimensionScore(data8));

        } else {
          let data5 = {
            "surveyId": this.state.CurrentSurveyId,
            "qtemplate": [21],
            "template": 11
          }
          this.props.dispatch(dashboardActions.getIceCrossDist(data5));
        }

      } else if (option === 3) {

        let data23 = {
          "surveyId": this.state.CurrentSurveyId,
          "qtemplate": [21],
          "template": 11,
          "filters": Filters

        }
        this.props.dispatch(dashboardActions.getIceDmgQuestionWiseDist(data23));
      }
    }



    else if (indexNo === 12) { // ICE MANAGER

      if (option === 1) {
        let data7 = {
          "surveyId": this.state.CurrentSurveyId,
          "xAxis": isFilterTwice ? name : XAxis,
          "filters": Filters
        }
        this.props.dispatch(dashboardActions.getIceIndexDMGWise(data7));
      } else if (option === 2) {

        if (isFilter) {
          let data8 = {
            "surveyId": this.state.CurrentSurveyId,
            "xAxis": isFilterTwice ? name : XAxis,
            "filters": Filters,
            "template": 12
          }
          this.props.dispatch(dashboardActions.getIceDMGDimensionScore(data8));

        } else {
          let data5 = {
            "surveyId": this.state.CurrentSurveyId,
            "qtemplate": [1],
            "template": 12
          }
          this.props.dispatch(dashboardActions.getIceCrossDist(data5));
        }



      } else if (option === 3) {

        let data23 = {
          "surveyId": this.state.CurrentSurveyId,
          "qtemplate": [1],
          "template": 12,
          "filters": Filters
        }
        this.props.dispatch(dashboardActions.getIceDmgQuestionWiseDist(data23));
      }
    }

    else if (indexNo === 13) { // ORG CORE

      if (option === 1) {

      } else if (option === 2) {

        if (isFilter) {
          let data8 = {
            "surveyId": this.state.CurrentSurveyId,
            "xAxis": isFilterTwice ? name : XAxis,
            "filters": Filters,
            "template": 13
          }
          this.props.dispatch(dashboardActions.getIceDMGDimensionScore(data8));

        } else {
          let data5 = {
            "surveyId": this.state.CurrentSurveyId,
            "qtemplate": [21],
            "template": 13
          }
          this.props.dispatch(dashboardActions.getIceCrossDist(data5));
        }

      } else if (option === 3) {

        let data23 = {
          "surveyId": this.state.CurrentSurveyId,
          "qtemplate": [21],
          "template": 13,
          "filters": Filters
        }
        this.props.dispatch(dashboardActions.getIceDmgQuestionWiseDist(data23));
      }
    }


  }




  handleNPSFilter = (FilterValues, isFilterTwice, name) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    let data24 = {
      "surveyId": this.state.CurrentSurveyId,
      "qtemplate": [22],
      "xAxis": isFilterTwice ? name : XAxis,
      "filters": Filters
    }

    this.props.dispatch(dashboardActions.getIceDmgWiseAnsCount(data24));


  }

  handleWordCloudDemographicFilter = (QuesId, FilterValues, dmgValue, type, wordList) => {
    let { CurrentSurveyId } = this.state;
    this.setState({ currentTextQuesId: QuesId });

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }





    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters

    }

    if (dmgValue) {
      data2["dmgValue"] = dmgValue;
    }

    console.log("------------------------------type====", type)

    if (type === "advance") {
      this.props.dispatch(dashboardActions.AdvanceWordCloud(data2));
    }
    else if (type === "wordCloud") {
      let data2X21 = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId
      }


      this.props.dispatch(dashboardActions.getAllFeedbackWords(data2X21));
      this.props.dispatch(dashboardActions.wordCloud(data2));

    }
    else if (type === "topicModelling") {

      let data2x = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "wordList": wordList,
        // "wordwithKeywordList":wordList,
        "filters": Filters

      }
      if (dmgValue) {
        data2x["dmgValue"] = dmgValue;
      }
      ////////console.log("topic input ------------->",data2x)

      this.props.dispatch(dashboardActions.topicModelling(data2x));
    }
    else if (type === "topicModellingCustom") {

      let data2x = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "wordList": wordList,
        // "wordwithKeywordList":wordList,
        "filters": Filters

      }
      if (dmgValue) {
        data2x["dmgValue"] = dmgValue;
      }
      ////////console.log("topic input ------------->",data2x)

      this.props.dispatch(dashboardActions.topicModelling(data2x));
    }
    else {
      let data3 = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "xAxis": FilterValues && FilterValues.length > 0 ? FilterValues[0].level : "Department"
      }
      if (dmgValue) {
        data3["dmgValue"] = dmgValue;
      }
      if (type === "textDistTreeMap") {
        this.props.dispatch(dashboardActions.textDistTreeMap(data3));

      }
      else if (type === "textDist") {
        this.props.dispatch(dashboardActions.textDist(data2));
        this.props.dispatch(dashboardActions.GetTextDistHighlights({}, true));
      }
      else {
        let data32 = {
          "surveyId": CurrentSurveyId,
          "questionId": QuesId,
          "xAxis": FilterValues && FilterValues.length > 0 ? FilterValues[0].level : "Department",
          "filters": Filters
        }
        if (dmgValue) {
          data32["dmgValue"] = dmgValue;
        }
        this.props.dispatch(dashboardActions.TreeMap(data32));
      }

    }



  }

  onTopicCloud = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId,
      "otherType": [3, 4, 23]
    }

    this.props.dispatch(dashboardActions.getTextQuestion(data2, 10));

  }

  onTextDistSentence = (sentences, dmgValue) => {
    if (sentences && sentences.length > 0) {
      let data2 = {
        "surveyId": this.state.CurrentSurveyId,
        "sentences": sentences.map((ele) => (ele[0]))
      }

      this.props.dispatch(dashboardActions.GetTextDistHighlights(data2));
    } else {
      this.props.dispatch(dashboardActions.GetTextDistHighlights({}, true));
    }
  }

  onTextDistSentence = (sentences, dmgValue) => {
    if (sentences && sentences.length > 0) {
      let data2 = {
        "surveyId": this.state.CurrentSurveyId,
        "sentences": sentences.map((ele) => (ele[0]))
      }

      this.props.dispatch(dashboardActions.GetTextDistHighlights(data2));
    } else {
      this.props.dispatch(dashboardActions.GetTextDistHighlights({}, true));
    }
  }



  onNeightboardGraph = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId,
      "otherType": [3, 4, 23]
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 3));

  }



  onTextWordDistribution = (EMPFieldFirstName) => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId,
      "otherType": [3, 4, 23]
    }
    console.log("EMPFieldFirstName---------->", EMPFieldFirstName)
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 5, [], EMPFieldFirstName));

  }


  callQuestionNetworkCloud = (QuesId, graphType, FilterValues, dmgValue) => {
    let { CurrentSurveyId } = this.state;


    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }



    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters && Filters.length > 0 ? Filters : []
    }

    if (dmgValue) {
      data2["dmgValue"] = dmgValue;
    }

    if (graphType === 1) {
      this.props.dispatch(dashboardActions.cooccurmatrix(data2));

    }
    else if (graphType === 2) {
      this.props.dispatch(dashboardActions.neighborGraph(data2));
    }


  }


  handleNetworkDemographicFilter = (Qid, FilterValues, graphType, dmgValue) => {
    let { CurrentSurveyId } = this.state;


    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }



    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": Qid,
      "filters": Filters
    }

    if (dmgValue) {
      data2["dmgValue"] = dmgValue;
    }

    if (graphType === 1) {
      this.props.dispatch(dashboardActions.cooccurmatrix(data2));
    }
    else if (graphType === 2) {
      this.props.dispatch(dashboardActions.neighborGraph(data2));
    }

  }

  onTopicModel = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId,
      "otherType": [3, 4, 23]
    }

    let data1 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }

    this.props.dispatch(dashboardActions.getTopicList(data1));

    this.props.dispatch(dashboardActions.getTextQuestion(data2, 7));


  }



  onCreateTopicSubmit = (data) => {
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }

    this.props.dispatch(dashboardActions.createTopic(data, temp))
  }
  onFeedbackCloud = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId
    }

    this.props.dispatch(dashboardActions.getFeedbackTextQuestion(data2));


  }
  callAnswerFeedbackText = (QuesId, keyWord, FilterList, FilterValue, isMultiKeyword, type) => {
    let { CurrentSurveyId } = this.state;

    let XAxis = "";
    let Filters = [];
    if (FilterList && FilterList.length > 0) {
      let getIndex = FilterList.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterList[getIndex].level;
        FilterList.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterList[FilterList.length - 1].level;
        Filters = FilterList
      }

    }

    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "keyWord": keyWord,
      "filters": Filters && Filters.length > 0 ? Filters : []
    }
    this.props.dispatch(dashboardActions.AnswerFeedbackText(data2));


  }

  handleAnswerFeedbackDemographicFilter = (QuesId, FilterValues, blank, type, wordList) => {
    let { CurrentSurveyId } = this.state;


    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }





    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters

    }

    this.props.dispatch(dashboardActions.getOrgCoreDriverSentiment(data2));



  }


  onTextCluster = () => {
    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "otherType": [3, 4, 23]
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data, 9));

  }

  getKeywordsByCluster = (QuesId, FilterValues, clusterNo) => {
    let { CurrentSurveyId } = this.state;


    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }





    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters,
      "cluster": clusterNo

    }


    this.props.dispatch(dashboardActions.getClusterKeywords(data2));

  }


  getClusterSentences = (QuesId, FilterValues, clusterNo, keyword) => {
    let { CurrentSurveyId } = this.state;


    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }





    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters,
      "cluster": clusterNo,
      "keyWord": keyword

    }

    this.props.dispatch(dashboardActions.getClusterSentences(data2));

  }

  getTextClusterFilter = (QuesId, FilterValues) => {
    let { CurrentSurveyId } = this.state;

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }
    }

    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters
    }

    this.props.dispatch(dashboardActions.getTextCluster(data2));

  }

  onTopicCloud = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId,
      "otherType": [3, 4, 23]
    }

    this.props.dispatch(dashboardActions.getTextQuestion(data2, 10));

  }

  handleTopicCloudDemographicFilter = (QuesId, FilterValues, dmgValue, type, wordList) => {
    let { CurrentSurveyId } = this.state;


    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }





    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters
    }

    if (dmgValue) {
      data2["dmgValue"] = dmgValue;
    }

    console.log("------------------------------type====", type)

    this.props.dispatch(dashboardActions.topicCloud(data2));


  }



  callTopicCloudText = (QuesId) => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": []
    }
    this.props.dispatch(dashboardActions.topicCloud(data2));

  }



  addKeywordsAPI = (avoidList) => {
    let data = {
      surveyId: this.state.CurrentSurveyId,
      keywordList: avoidList,
    };
    let tempo = {
      surveyId: this.state.CurrentSurveyId
    };
    this.props.dispatch(dashboardActions.AddTextKeywords(data, tempo));
  }




  onLoadSubReport = (emp_id, type) => {

    if (type === 1) {

      let { crrSubReportType } = this.state;


      let dataUT = { //RESPONSE
        "surveyId": this.state.CurrentSurveyId,
        "User_Name": emp_id,
        "Report_Type": crrSubReportType
      }
      this.props.dispatch(dashboardActions.getLTHeadIceDmgWiseICENPS(dataUT));




      if (true) {



        let dataxx = {
          "surveyId": this.state.CurrentSurveyId
        }
        this.props.dispatch(dashboardActions.getIceIndex(dataxx));

        let dataxx7 = {
          "surveyId": this.state.CurrentSurveyId,
          "qtemplate": [22]
        }
        this.props.dispatch(dashboardActions.getIceNPSRowWise(dataxx7));

        let data4x = {
          "surveyId": this.state.CurrentSurveyId,
          "qtemplate": [21],
          "template": 11 //ICE
        }
        this.props.dispatch(dashboardActions.getIceDistribution(data4x));

        let data4x1 = {
          "surveyId": this.state.CurrentSurveyId,
          "qtemplate": [1],
          "template": 12 //ICE MANAGER
        }
        this.props.dispatch(dashboardActions.getIceDistributionV2(data4x1));




        let data1r = { //RESPONSE
          "surveyId": this.state.CurrentSurveyId,
          "User_Name": emp_id,
          "Report_Type": crrSubReportType
        }
        this.props.dispatch(dashboardActions.getLTHeadIceDmgResponses(data1r));




        let data1 = { //ICE
          "surveyId": this.state.CurrentSurveyId,
          "template": 11,
          "User_Name": emp_id,
          "Report_Type": crrSubReportType,
          "isLTHead": false
        }
        this.props.dispatch(dashboardActions.getLTHeadIceScore(data1));

        let data2 = { //ICE
          "surveyId": this.state.CurrentSurveyId,
          "template": 11,
          "User_Name": emp_id,
          "Report_Type": crrSubReportType,
          "isLTHead": false
        }
        this.props.dispatch(dashboardActions.getLTHeadIceParaScore(data2));





        let data1H = { //ICE V2
          "surveyId": this.state.CurrentSurveyId,
          "template": 11,
          "User_Name": emp_id,
          "Report_Type": crrSubReportType,
          "isLTHead": true
        }
        this.props.dispatch(dashboardActions.getLTHeadIceScoreV3(data1H));

        let data2H = { //ICE V2
          "surveyId": this.state.CurrentSurveyId,
          "template": 11,
          "User_Name": emp_id,
          "Report_Type": crrSubReportType,
          "isLTHead": true
        }
        this.props.dispatch(dashboardActions.getLTHeadIceParaScoreV3(data2H));




        let data3 = { //ICE
          "surveyId": this.state.CurrentSurveyId,
          "template": 11,
          "User_Name": emp_id,
          "Report_Type": crrSubReportType
        }
        this.props.dispatch(dashboardActions.getLTHeadIceSubWiseIceScore(data3));


        let data4 = { //ICE DIST
          "surveyId": this.state.CurrentSurveyId,
          "template": 11,
          "User_Name": emp_id,
          "Report_Type": crrSubReportType
        }
        this.props.dispatch(dashboardActions.getSubWiseParaFeedDist(data4));


        let data5 = { //ORG
          "surveyId": this.state.CurrentSurveyId,
          "template": 13,
          "User_Name": emp_id,
          "Report_Type": crrSubReportType
        }
        this.props.dispatch(dashboardActions.getSubWiseFeedAnsCount(data5));

        let data6 = { //NPS
          "surveyId": this.state.CurrentSurveyId,
          "qtemplate": 22,
          "User_Name": emp_id,
          "Report_Type": crrSubReportType
        }
        this.props.dispatch(dashboardActions.getSubWiseFeedParaScore(data6));


        let dataxx7x = {
          "surveyId": this.state.CurrentSurveyId,
          "qtemplate": [22],
          "User_Name": emp_id,
          "Report_Type": crrSubReportType,
          "isLTHead": true
        }
        this.props.dispatch(dashboardActions.getIceNPSRowWiseLTHead(dataxx7x));



        let data7 = { //TEXT
          "surveyId": this.state.CurrentSurveyId,
          "qtemplate": 23,
          "User_Name": emp_id,
          "Report_Type": crrSubReportType
        }
        this.props.dispatch(dashboardActions.getSubWiseParaScore(data7));

        let data7x = { //Strengths
          "surveyId": this.state.CurrentSurveyId,
          // "qtemplate": 23,
          "User_Name": emp_id,
          "mode": "Strengths",
          "Report_Type": crrSubReportType
        }
        this.props.dispatch(dashboardActions.getSubWiseStrengthImprovement(data7x));

        let data7x1 = { //Improvement
          "surveyId": this.state.CurrentSurveyId,
          // "qtemplate": 23,
          "User_Name": emp_id,
          "mode": "Improvement",
          "Report_Type": crrSubReportType
        }
        this.props.dispatch(dashboardActions.getSubWiseStrengthImprovementV2(data7x1));



        let data1m = { //MNGR
          "surveyId": this.state.CurrentSurveyId,
          "template": 12,
          "User_Name": emp_id,
          "Report_Type": crrSubReportType
        }
        this.props.dispatch(dashboardActions.getLTHeadIceScoreV2(data1m));

        let data2m = { //MNGR
          "surveyId": this.state.CurrentSurveyId,
          "template": 12,
          "User_Name": emp_id,
          "Report_Type": crrSubReportType
        }
        this.props.dispatch(dashboardActions.getLTHeadIceParaScoreV2(data2m));



        let data1m2 = { //MNGR V4
          "surveyId": this.state.CurrentSurveyId,
          "template": 12,
          "User_Name": emp_id,
          "Report_Type": crrSubReportType,
          "isLTHead": true
        }
        this.props.dispatch(dashboardActions.getLTHeadIceScoreV4(data1m2));

        let data2m2 = { //MNGR V4
          "surveyId": this.state.CurrentSurveyId,
          "template": 12,
          "User_Name": emp_id,
          "Report_Type": crrSubReportType,
          "isLTHead": true
        }
        this.props.dispatch(dashboardActions.getLTHeadIceParaScoreV4(data2m2));





        let data3m = { //MNGR
          "surveyId": this.state.CurrentSurveyId,
          "template": 12,
          "User_Name": emp_id,
          "Report_Type": crrSubReportType
        }
        this.props.dispatch(dashboardActions.getLTHeadIceSubWiseIceScoreV2(data3m));

      }


    } else if (type === 2) {

      this.setState({ crrSubReportType: emp_id }, () => {
        let data1 = {
          "surveyId": this.state.CurrentSurveyId,
          "type": emp_id
        }
        this.props.dispatch(dashboardActions.getLTHeadEmpList(data1));
      });

    }


  }


  render() {

    let { dashboard, questions, survey, general } = this.props;
    let { questionsList } = questions;
    let { getParameterLabelByNameData, getIndexByFilterDMGData, getParaLibraryByParentNamesData } = survey;
    let { getQuestionAvgScoreData, getQuestion2OthersResultFilterData, getQuestionOthersResultData, getQuestionOthersResultFilterData, getQuestionOthersMultiResultFilterData } = general;
    let { currentTextQuesId, crrSubReportType, showPeopleToMeet, customeScoreData, showCustomScoreCard, showReportDMGModal, ManagerReportFConfig, loadReport, isFilter, currentQuestionId, currentQuestionTemplate } = this.state;
    let {

      getLTHeadIceScoreV3Data, getLTHeadIceParaScoreV3Data,
      getLTHeadIceScoreV4Data, getLTHeadIceParaScoreV4Data,
      getIceNPSRowWiseLTHeadData,

      getLTHeadEmpListData, getSubWiseParaFeedDistData, getSubWiseParaFeedDistV2Data, getLTHeadIceScoreData, getLTHeadIceParaScoreData, getLTHeadIceSubWiseIceScoreData,
      getLTHeadIceDmgResponsesData, getLTHeadIceDmgWiseICENPSData,
      getLTHeadIceScoreV2Data, getLTHeadIceParaScoreV2Data, getLTHeadIceSubWiseIceScoreV2Data, getSubWiseStrengthImprovementData, getSubWiseStrengthImprovementV2Data,

      getIceFeedbackListData, getSubWiseFeedParaScoreData, getSubWiseFeedAnsCountData, getIceMixHeatMapV2Data, getIceIndexData, getIceDistributionData, getIceCrossDistData, getIceMixHeatMapData, getIceNPSRowWiseData, getIceDistributionV2Data, getMetaResponseAnalyticsFilterData, getTemp360EmpStrengthData, get360DimensionRelationScoreData, get360DimensionQuestionsData, getAnsweredEmployeesData, get360RelationResponseData, getFeedbackAndQuestionNameData, getTextAndQuestionNameData, getParameterLibraryLabelByParentNameData, getCompanyNameOnlyData, ClientProfileData, SurveyList, EmpFilterData, loading, getIndexFilterData, getDemographicIndexHappinessData, getDemographicIndexEngagementData, getDemographicIndexStressData, getDemographicIndexManagerData, getQuestionManagerOfHappinessData, getQuestionManagerOfEngagementData, getQuestionManagerOfStressData, getQuestionManagerOfManagerData, getCommentData, getHappinessIndexV2Data, getEngagementIndexV2Data, getStressIndexV2Data, getManagerIndexV2Data, getIceDmgWiseParaScoreData, getIceDmgQuestionWiseDistData, getIceDmgWiseAnsCountData, getIceIndexDMGWiseData, getIceDMGDimensionScoreData, getSubWiseParaScoreData } = dashboard;



    let {
      getTextQuestionData,
      getAllFeedbackWordsData,
      getTextKeywordsData,
      getTextDistHighlightData,
      textDistError,
      TreeMapError,
      topicModellingError,
      neighborGraphError,
      wordCloudError,
      wordCloudData,
      wordTextKeyWord,
      cooccurmatrixData,
      wordCloudMultiTextData,
      wordMultiTextKeyWord,
      textDistData,
      AdvanceWordCloudData,
      TreeMapData,
      textDistTreeMapData,
      topicModellingData,
      getTopicListData,
      ErrorOtherObj,
      getOrgCoreDriverSentimentData,
      AnswerFeedbackTextData,
      AnswerFeedbackTextKeyWord,
      getFeedbackTextQuestionData,
      getTextClusterData,
      getTextClusterSentimentCountData,
      getClusterKeywordsData,
      getClusterSentencesData,
      getClusterSaveKeyWord,
      topicCloudData


    } = dashboard;

    let { showManagerGoalPlan, Mindextype, MQuesIndextype, ManagerListDasboardDataObj, ManagerListQuestionDataObj, SelectedAPDimension, SelectedAPAction, SelectedAPTask } = this.state;

    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }

    let SHOW_ELEMENT = false;
    let isCrole = false;
    let clientData = this.props.dashboard.ClientProfileData;

    if (clientData && clientData.role) {
      if (clientData.role === "ADMIN") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if (clientData.role === "MNGR") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "CROLE") {
        isCrole = true
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "EMP") {
        SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }

    function accessAllow(item) {
      return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))
    }

    let ManagerIndexQuestionData = '';

    if (MQuesIndextype === 1) {
      ManagerIndexQuestionData = getQuestionManagerOfHappinessData;
    }
    else if (MQuesIndextype === 2) {
      ManagerIndexQuestionData = getQuestionManagerOfEngagementData;
    }
    else if (MQuesIndextype === 4) {
      ManagerIndexQuestionData = getQuestionManagerOfStressData;
    }
    else if (MQuesIndextype === 5) {
      ManagerIndexQuestionData = getQuestionManagerOfManagerData;
    }

    let ManagerIndexDriverData = [];

    if (Mindextype === 1) {
      ManagerIndexDriverData = getDemographicIndexHappinessData;
    }
    else if (Mindextype === 2) {
      ManagerIndexDriverData = getDemographicIndexEngagementData;
    }
    else if (Mindextype === 4) {
      ManagerIndexDriverData = getDemographicIndexStressData;
    }
    else if (Mindextype === 5) {
      ManagerIndexDriverData = getDemographicIndexManagerData;
    }

    let { showAllQuestions, showOtherTypes, showReport, showSidebar, CurrentSurveyId, indexType, showOverview, showIndex, showManagerList, showAdvanceComparision, showTextAnalysis, showStrengthAndWeaknesses, showManagerActionPlan, DynaObj, ManagerDynaObj, QuesDynaObj } = this.state;
    let { getAllParameterListData, getAllManagerActionPlanData, getManagerActionPlanData, getIndexData, getDemographicIndexForManagerData, wordCloudTextData, neighborGraphData, getRespondantDetailsData } = dashboard;

    let ManagerNameIdList = getDemographicIndexForManagerData;

    if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "MNGR") {
      this.props.history.push(`/app/manageractionplan`)
    } else if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "EMP") {
      this.props.history.push(`/app/employeeactionplan`)
    }

    function checkIndex(key) {
      let check = false
      if (key === 1) { check = true }
      else if (key === 2) { check = true }
      else if (key === 3) { check = true }
      else if (key === 4) { check = true }
      else if (key === 5) { check = true }
      else if (key === 6) { check = true }
      else if (key === 7) { check = true }
      else if (key === 9) { check = true }
      else { check = false }

      return check;
    }

    let IndexList = []
    let newFilterObj = {}

    if (getIndexData) {
      IndexList = Object.keys(getIndexData).map((key) => [Number(key), getIndexData[key]]);
      if (getIndexFilterData) {
        if (IndexList && IndexList.length > 0) {
          IndexList.forEach((data) => {
            if (checkIndex(data[0])) {
              newFilterObj[data[0]] = getIndexFilterData[data[0]];
            }
          });
        };
      }
    }

    let IndexDimension = {
      "Happiness": ['Time', 'Being', 'Relationship', 'WorkLife', 'Achivement', 'Meeningfull'],
      "Engagement": ['Pride', 'Presence', 'Performance'],
      "Stress": ['Recognition', 'WorkSatisfaction', 'Inclusion', 'Collaboration', 'Empowerment'],
      "Manager": ['Connect', 'ACT', 'Respect', 'Empower'],
      "Leader": ['Vision', 'Impact', 'Execute', 'Wisdom']
    }

    function CountRestrict(count, isOverall, section) {
      let isCountLessThanFive = false;

      // ////////console.log("count---",count)

      if (count !== "NaN") {
        if (isOverall) {
          isCountLessThanFive = false;
        }
        else {
          if (section === "rwa") {
            if (parseInt(count) < 30) {
              isCountLessThanFive = true;
            }
          }
          else {
            if (parseInt(count) < 5) {
              isCountLessThanFive = true;
            }
          }

        }
      }
      // ////////console.log("isCountLessThanFive---",isCountLessThanFive)

      return isCountLessThanFive
    }

    let { getSurveyData, parameterList } = survey;

    function GetParameterLabel(name) {
      let AllParameterObj = {};
      let NewParameterList = getParaLibraryByParentNamesData && getParaLibraryByParentNamesData.length > 0 ? getParaLibraryByParentNamesData : []
      if (NewParameterList && NewParameterList.length > 0) {
        NewParameterList.forEach((item) => {
          AllParameterObj[item.name] = item && item.label ? item.label : item.name;
        });
      }
      let label = (AllParameterObj && AllParameterObj[name] ? AllParameterObj[name] : name);
      return label
    }

    function GetParameterDesc(name) {
      let AllParameterObj = {};
      if (getParameterLibraryLabelByParentNameData && getParameterLibraryLabelByParentNameData.length > 0) {
        getParameterLibraryLabelByParentNameData.forEach((item) => {
          //console.log("name------>",name)
          //console.log("item------>",item)
          AllParameterObj[item.name] = item && item.desc ? item.desc : "";
        });
      }
      //console.log("name------>",name)
      //console.log("AllParameterObj------>",AllParameterObj)
      //console.log("getParameterLibraryLabelByParentNameData------>",getParameterLibraryLabelByParentNameData)

      return (AllParameterObj && AllParameterObj[name] ? AllParameterObj[name] : "");
    }

    let surveyLogo = "";

    let surveyId = getSurveyData && getSurveyData.id ? getSurveyData.id : "";

    if (surveyId === "618b673c9503f41bad61667f") {
      surveyLogo = 'img/logo/kotaklifelogo.png';
    }
    else if (surveyId === "61541d6cf0f59b75a9b50d44") {
      surveyLogo = 'img/logo/tcillogo.jpeg';
    }
    else if (surveyId === "61b1850ada0e4ed403288e52") {
      surveyLogo = 'img/logo/tcellogo.jpg';
    }
    else if (surveyId === "61c06c871a708fbb5cf1c9a3") {
      surveyLogo = 'img/logo/bppllogo.png';
    }
    else if (surveyId === "5fa04d316ede0f0336066cce") {
      surveyLogo = '';
    }
    else if (surveyId === "621dc1de0f73b3a8e532f1b5") {
      surveyLogo = 'img/logo/axissecuritylogo.png';
    }
    else if (surveyId === "639ac5961282de9a6f9b545f") {
      surveyLogo = 'img/logo/gennovalogo.png';
    }
    else if (surveyId === "63d754f650a5f4d496779086") {
      surveyLogo = 'img/logo/gennovalogo2.jpg';
    }
    else if (surveyId === "65cef2f7fb55390f82124cca") {
      surveyLogo = 'img/logo/udyoglogo.png';
    }
    else if (surveyId === "660e8994d31b9f08913b47d7") {
      surveyLogo = 'img/logo/urbanriselogo.png';
    }

    function getEMPFilterLabel(name) {

      let label = name;
      if (EmpFilterData && EmpFilterData.length > 0) {
        let getIndex = EmpFilterData.findIndex(prev => prev.name === name);
        if (getIndex !== 1) {
          label = EmpFilterData && EmpFilterData[getIndex] && EmpFilterData[getIndex].label ? EmpFilterData[getIndex].label : name
        }

      }
      return label
    }

    function getSurveyDetails(name) {
      return getSurveyData && getSurveyData[name] ? getSurveyData[name] : "NaN";
    }

    let isLifecycle = false
    if (getSurveyData && getSurveyData.isRiskSurveyType === "DEFAULTDAYS") {
      isLifecycle = true
    }





    let isDMGText = false;
    if (getTextQuestionData?.length && getAllFeedbackWordsData?.length) {
      const currentTextQues = currentTextQuesId
        ? getTextQuestionData.find(({ id }) => id === currentTextQuesId)
        : getTextQuestionData[0];

      if (currentTextQues?.templateId === 23) {
        isDMGText = true;
      }
    }


    console.log("isDMGText-->", isDMGText)


    let OptionList = [
      {
        "name": "Dashboard",
        "template": 1
      },
      {
        "name": "Question Analysis",
        "template": 2
      }
    ]

    return (
      <>
        <LoadingOverlay
          active={loading}
          // active={false}

          spinner
          text='Loading Data...'
          className="loader"
        >


          <div className='h-screen overflow-hidden bg-gray-50'>

            <Header
              ClientProfileData={ClientProfileData}
              handleLogout={this.handleLogout}
              SurveyList={SurveyList}
              CurrentSurveyId={CurrentSurveyId}
              props={this.props}
              onOverviewFromHeader={this.onOverviewFromHeader}
              getSurveyData={getSurveyData}
              companyName={getCompanyNameOnlyData && getCompanyNameOnlyData.name ? getCompanyNameOnlyData.name : ""}
            />

            {/*sub nav bar 1 */}
            <div className="px-4  md:flex  justify-between items-center bg-white
      border-b py-2.5">
              <div className="flex justify-between w-full space-x-2 text-gray-700">

                <div className='flex space-x-2'>
                  {/* <span className="material-icons-outlined -mt-0.5">description</span> */}
                  <h2 className="text-sm font-medium truncate"> {getSurveyDetails("name")} </h2>
                </div>

                <div onClick={() => this.handleMobileSidebar(!this.state.showMobileSidebar)}>
                  <span className="cursor-pointer material-icons lg:hidden">menu_open</span>
                </div>

              </div>

              {/* <div className="inline-flex items-center">
          <button className="px-4 py-2 text-xs font-medium uppercase border rounded-full border-sky-500 text-sky-500 ">VIEW CAMPAIGN</button>
        </div> */}

            </div>
            {/* end */}
            <div className="flex w-full" style={{ height: 'calc(100% - 6.5rem)' }}>
              {/*this is side bar  */}


              <Sidebar
                SurveyList={SurveyList}
                onOverview={this.onOverview}
                onOptionType={this.onOptionType}
                onManagerList={this.onManagerList}
                onOrganizationCore={this.onOrganizationCore}
                indexType={indexType}
                getIndexData={newFilterObj}
                CurrentSurveyId={CurrentSurveyId}
                showSidebar={showSidebar}
                handleSidebar={this.handleSidebar}
                showMobileSidebar={this.state.showMobileSidebar}
                isLifecycle={isLifecycle}
              />

              {showOverview ?
                <Overview
                  getSubWiseParaFeedDistData={getSubWiseParaFeedDistData}
                  onLoadSubReport={this.onLoadSubReport}
                  getSubWiseParaFeedDistV2Data={getSubWiseParaFeedDistV2Data}
                  getLTHeadIceScoreData={getLTHeadIceScoreData}
                  getLTHeadIceParaScoreData={getLTHeadIceParaScoreData}
                  getLTHeadIceSubWiseIceScoreData={getLTHeadIceSubWiseIceScoreData}
                  getSubWiseFeedAnsCountData={getSubWiseFeedAnsCountData}
                  getSubWiseFeedParaScoreData={getSubWiseFeedParaScoreData}
                  getSubWiseParaScoreData={getSubWiseParaScoreData}
                  getLTHeadEmpListData={getLTHeadEmpListData}
                  crrSubReportType={crrSubReportType}
                  getLTHeadIceDmgResponsesData={getLTHeadIceDmgResponsesData}
                  getLTHeadIceDmgWiseICENPSData={getLTHeadIceDmgWiseICENPSData}
                  getIceNPSRowWiseLTHeadData={getIceNPSRowWiseLTHeadData}

                  getLTHeadIceScoreV3Data={getLTHeadIceScoreV3Data}
                  getLTHeadIceParaScoreV3Data={getLTHeadIceParaScoreV3Data}

                  getLTHeadIceScoreV4Data={getLTHeadIceScoreV4Data}
                  getLTHeadIceParaScoreV4Data={getLTHeadIceParaScoreV4Data}

                  getSubWiseStrengthImprovementData={getSubWiseStrengthImprovementData}
                  getSubWiseStrengthImprovementV2Data={getSubWiseStrengthImprovementV2Data}

                  getLTHeadIceScoreV2Data={getLTHeadIceScoreV2Data}
                  getLTHeadIceParaScoreV2Data={getLTHeadIceParaScoreV2Data}
                  getLTHeadIceSubWiseIceScoreV2Data={getLTHeadIceSubWiseIceScoreV2Data}
                  getMetaResponseAnalyticsFilterData={getMetaResponseAnalyticsFilterData}
                  getIceIndexData={getIceIndexData}
                  getIceDistributionData={getIceDistributionData}
                  getIceCrossDistData={getIceCrossDistData}
                  getIceMixHeatMapData={getIceMixHeatMapData}
                  getIceNPSRowWiseData={getIceNPSRowWiseData}
                  getIceDistributionV2Data={getIceDistributionV2Data}
                  getIceFeedbackListData={getIceFeedbackListData}
                  getIceMixHeatMapV2Data={getIceMixHeatMapV2Data}
                  onLoadReport={this.onLoadReport}
                  getIndexData={newFilterObj}
                  SurveyList={SurveyList}
                  CurrentSurveyId={CurrentSurveyId}
                  onOptionType={this.onOptionType}
                  getHappinessIndexV2Data={getHappinessIndexV2Data}
                  getEngagementIndexV2Data={getEngagementIndexV2Data}
                  getStressIndexV2Data={getStressIndexV2Data}
                  getManagerIndexV2Data={getManagerIndexV2Data}
                  getRespondantDetailsData={getRespondantDetailsData}
                  EmpFilterData={EmpFilterData}
                  getIndexFilterData={getIndexFilterData}
                  getQuestionOthersResultData={getQuestionOthersResultData}
                  onAllQuestions={this.onAllQuestions}
                  getQuestionAvgScoreData={getQuestionAvgScoreData}
                  onCustomScoreCard={this.onCustomScoreCard}
                  get360Overview={this.get360Overview}
                  get360DimensionItemDetails={this.get360DimensionItemDetails}
                  get360DimensionRelationScoreData={get360DimensionRelationScoreData}
                  get360DimensionQuestionsData={get360DimensionQuestionsData}
                  getIndexByFilterDMGData={getIndexByFilterDMGData}
                  getEMPFilterLabel={getEMPFilterLabel}
                  CountRestrict={CountRestrict}
                  GetParameterLabel={GetParameterLabel}
                  loading={loading}
                  indexType={indexType}
                  getCommentData={getCommentData}
                  handle360QuestionFilter={this.get360DimensionItemDetails}
                  getAnsweredEmployeesData={getAnsweredEmployeesData}
                  get360RelationResponse={this.get360RelationResponse}
                  get360RelationResponseData={get360RelationResponseData}
                  getFeedbackAndQuestionName={this.getFeedbackAndQuestionName}
                  getFeedbackAndQuestionNameData={getFeedbackAndQuestionNameData}
                  getTextAndQuestionNameData={getTextAndQuestionNameData}
                  GetParameterDesc={GetParameterDesc}
                  getSurveyData={getSurveyData}
                  surveyLogo={surveyLogo}
                  getTemp360EmpStrengthCall={this.getTemp360EmpStrengthCall}
                  getTemp360EmpStrengthData={getTemp360EmpStrengthData}

                />
                :

                <>
                  {indexType === 11 && // ICE
                    <ICEIndexDetails
                      getIceIndexData={getIceIndexData}
                      EmpFilterData={EmpFilterData}
                      getEMPFilterLabel={getEMPFilterLabel}
                      getIndexFilterData={getIndexByFilterDMGData}
                      getIceDMGDimensionScoreData={getIceDMGDimensionScoreData}
                      handleDashICEFilter={this.handleDashICEFilter}
                      indexNumber={11}
                      getIceIndexDMGWiseData={getIceIndexDMGWiseData}
                      getIceDmgQuestionWiseDistData={getIceDmgQuestionWiseDistData}
                      getIceCrossDistData={getIceCrossDistData}
                      GetParameterLabel={GetParameterLabel}
                    />
                  }

                  {indexType === 12 && // ICEMANAGER
                    <>
                      <ManagerIndexDetails
                        getIceIndexData={getIceIndexData}
                        EmpFilterData={EmpFilterData}
                        getEMPFilterLabel={getEMPFilterLabel}
                        getIndexFilterData={getIndexByFilterDMGData}
                        getIceDMGDimensionScoreData={getIceDMGDimensionScoreData}
                        handleDashICEFilter={this.handleDashICEFilter}
                        indexNumber={12}
                        getIceIndexDMGWiseData={getIceIndexDMGWiseData}
                        getIceDmgQuestionWiseDistData={getIceDmgQuestionWiseDistData}
                        getIceCrossDistData={getIceCrossDistData}
                        GetParameterLabel={GetParameterLabel}
                      />
                    </>
                  }


                  {indexType === 13 && // next sidebar
                    <>
                      <OrgCore
                        getIceIndexData={getIceIndexData}
                        EmpFilterData={EmpFilterData}
                        getEMPFilterLabel={getEMPFilterLabel}
                        getIndexFilterData={getIndexByFilterDMGData}
                        getIceDMGDimensionScoreData={getIceDMGDimensionScoreData}
                        handleDashICEFilter={this.handleDashICEFilter}
                        indexNumber={13}
                        getIceIndexDMGWiseData={getIceIndexDMGWiseData}
                        getIceDmgQuestionWiseDistData={getIceDmgQuestionWiseDistData}
                        getIceCrossDistData={getIceCrossDistData}
                        GetParameterLabel={GetParameterLabel}
                      />
                    </>
                  }
                  {indexType === 4 && // next sidebar
                    <>
                      <ENPS
                        getIceIndexData={getIceIndexData}
                        EmpFilterData={EmpFilterData}
                        getEMPFilterLabel={getEMPFilterLabel}
                        getIndexFilterData={getIndexByFilterDMGData}
                        getIceNPSRowWiseData={getIceNPSRowWiseData}
                        handleNPSFilter={this.handleNPSFilter}
                        getIceDmgWiseAnsCountData={getIceDmgWiseAnsCountData}
                        GetParameterLabel={GetParameterLabel}
                      />

                    </>
                  }

                  {indexType === 5 && // next sidebar
                    <>
                      <TextAnalysis
                        isDMGText={isDMGText}
                        getAllFeedbackWordsData={getAllFeedbackWordsData}
                        handleDMGCloudDemographicFilter={this.handleDMGCloudDemographicFilter}

                        getTextKeywordsData={getTextKeywordsData}
                        getTextDistHighlightData={getTextDistHighlightData}
                        onTextDistSentence={this.onTextDistSentence}
                        textDistError={textDistError}
                        TreeMapError={TreeMapError}
                        topicModellingError={topicModellingError}
                        neighborGraphError={neighborGraphError}
                        wordCloudError={wordCloudError}
                        indexType={104}
                        getTextQuestionData={getTextQuestionData}

                        handleChangeQuestion={this.handleChangeQuestion}

                        wordCloudData={wordCloudData}

                        EmpFilterData={EmpFilterData}
                        getIndexFilterData={getIndexFilterData}

                        handleWordCloudDemographicFilter={this.handleWordCloudDemographicFilter}

                        callWordCloudText={this.callWordCloudText}

                        wordCloudTextData={wordCloudTextData}

                        wordTextKeyWord={wordTextKeyWord}

                        callQuestionWordCloud={this.callQuestionWordCloud}

                        cooccurmatrixData={cooccurmatrixData}

                        onWorldCloud={this.onWorldCloud}

                        onNetworkGraph={this.onNetworkGraph}
                        onNeightboardGraph={this.onNeightboardGraph}
                        onTextWordDistribution={this.onTextWordDistribution}

                        callQuestionNetworkCloud={this.callQuestionNetworkCloud}
                        handleNetworkDemographicFilter={this.handleNetworkDemographicFilter}


                        neighborGraphData={neighborGraphData}

                        handleNeighborGraph={this.handleNeighborGraph}

                        getCommentData={getCommentData}

                        loading={loading}

                        GetParameterLabel={GetParameterLabel}

                        CountRestrict={CountRestrict}

                        wordCloudMultiTextData={wordCloudMultiTextData}
                        wordMultiTextKeyWord={wordMultiTextKeyWord}

                        textDistData={textDistData}


                        onAdvanceWorldCloud={this.onAdvanceWorldCloud}
                        AdvanceWordCloudData={AdvanceWordCloudData}

                        onTreeMap={this.onTreeMap}
                        TreeMapData={TreeMapData}
                        textDistTreeMapData={textDistTreeMapData}

                        getEMPFilterLabel={getEMPFilterLabel}

                        getIndexByFilterDMGData={getIndexByFilterDMGData}




                        getDemographicIndexHappinessDataTreeMap={{}}
                        getDemographicIndexEngagementDataTreeMap={{}}
                        getDemographicIndexStressDataTreeMap={{}}
                        getDemographicIndexManagerDataTreeMap={{}}
                        getDemographicIndexLeaderDataTreeMap={{}}


                        getDemographicIndexMultiFilterDataTreeMap={{}}


                        getIndexData={getIndexData}


                        getHappinessIndexDriverData={{}}
                        getEngagementIndexDriverData={{}}
                        getStressIndexDriverData={{}}
                        getManagerIndexDriverData={{}}
                        getLeaderIndexDriverData={{}}


                        onTopicModel={this.onTopicModel}
                        topicModellingData={topicModellingData}

                        getTopicListData={getTopicListData}

                        onCreateTopicSubmit={this.onCreateTopicSubmit}

                        ErrorOtherObj={ErrorOtherObj}



                        //AnswerFeedback
                        getOrgCoreDriverSentimentData={getOrgCoreDriverSentimentData}
                        AnswerFeedbackTextData={AnswerFeedbackTextData}
                        AnswerFeedbackTextKeyWord={AnswerFeedbackTextKeyWord}
                        getFeedbackTextQuestionData={getFeedbackTextQuestionData}
                        onFeedbackCloud={this.onFeedbackCloud}
                        callAnswerFeedbackText={this.callAnswerFeedbackText}
                        handleAnswerFeedbackDemographicFilter={this.handleAnswerFeedbackDemographicFilter}

                        //onTextCluster
                        getTextClusterData={getTextClusterData}
                        getTextClusterSentimentCountData={getTextClusterSentimentCountData}
                        getClusterKeywordsData={getClusterKeywordsData}
                        getClusterSentencesData={getClusterSentencesData}
                        getClusterSaveKeyWord={getClusterSaveKeyWord}
                        onTextCluster={this.onTextCluster}
                        getKeywordsByCluster={this.getKeywordsByCluster}
                        getClusterSentences={this.getClusterSentences}
                        getTextClusterFilter={this.getTextClusterFilter}

                        onTopicCloud={this.onTopicCloud}
                        topicCloudData={topicCloudData}


                        handleTopicCloudDemographicFilter={this.handleTopicCloudDemographicFilter}
                        callTopicCloudText={this.callTopicCloudText}
                        addKeywordsAPI={this.addKeywordsAPI}
                      />

                    </>
                  }



                </>}


            </div>
          </div>



        </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard, questions, survey, general } = state;
  return {
    loggingIn,
    users,
    dashboard,
    questions,
    survey,
    general
  };
}
export default connect(mapStateToProps)(Dashboard);