import React, { Component } from "react";
import { connect } from "react-redux";
import moment, { max } from "moment";
import IndexKPI from "./Components/IndexKPI";
import Filter from "./Components/Filter";
import DonutChart from "./Components/DonutChart";
import Commentary from "../../../Index/Components/Commentary/Commentary";
import ManagerWise from "./Components/Filters/ManagerList";

class Dimension extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: "",
      showTaskDropdown: [],
      showMAPView: true,

      FilterValues: [],
      showFilter: false,
      showValue: "",

      currEMPData: {},

      showMngrActionsPage: 0,
      currACTData: {},
      showChangeStatus: "all",
      crrMngrEmp_idList: [],
      crrShowDimenAction: {
        action: "",
        dimen: "",
      },
    };
  }
  componentDidMount() { }

  openDropdown = (emp) => {
    if (this.state.showDropdown === emp.employeeId) {
      this.setState({ showDropdown: "" });
    } else {
      this.setState({ showDropdown: emp.employeeId });
    }
  };

  openTaskDropdown = (act) => {
    let { showTaskDropdown } = this.state;

    let getIndex = showTaskDropdown.findIndex((prev) => prev === act.id);
    if (getIndex === -1) {
      showTaskDropdown.push(act.id);
    } else {
      showTaskDropdown.splice(getIndex, 1);
    }
    this.setState({ showTaskDropdown });
  };

  handleMAPView = (val) => {
    this.setState({ showMAPView: val });
  };

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { handleMultiFilterGetQuestion } = this.props;
    this.setState({ FilterValues: finalFilter });

    //console.log('========s===================>',finalFilter)
    // handleMultiFilterGetQuestion(finalFilter);
  };

  showActions = (empData) => {
    //////console.log("empData--->",empData);
    this.setState({
      currEMPData: empData,
      showMngrActionsPage: 1,
      showChangeStatus: "all",
    });
  };

  showTasks = (actData) => {
    //////console.log("actData--->",actData);
    this.setState({
      currACTData: actData,
      showMngrActionsPage: 2,
      showChangeStatus: "all",
    });
  };

  handleBackActionTask = () => {
    let { showMngrActionsPage } = this.state;
    if (showMngrActionsPage === 2) {
      this.setState({ showMngrActionsPage: 1, showChangeStatus: "all" });
    } else if (showMngrActionsPage === 1) {
      this.setState({ showMngrActionsPage: 0, showChangeStatus: "all" });
    }
  };

  handleChangeStatus = (check) => {
    this.setState({ showChangeStatus: check });
  };

  handleSelectMngrValue = (list, value) => {
    let { crrMngrEmp_idList } = this.state;
    if (value === "All") {
      this.setState({ crrMngrEmp_idList: [] });
    } else {
      let getINdex = crrMngrEmp_idList.findIndex((prev) => prev === value);
      if (getINdex === -1) {
        crrMngrEmp_idList.push(value);
      } else {
        crrMngrEmp_idList.splice(getINdex, 1);
      }
      this.setState({ crrMngrEmp_idList });
    }
  };

  handleShowTask = (action1, dimen1) => {
    let { crrShowDimenAction } = this.state;
    if (
      crrShowDimenAction["action"] === action1 &&
      crrShowDimenAction["dimen"] === dimen1
    ) {
      crrShowDimenAction = {
        action: "",
        dimen: "",
      };
    } else {
      crrShowDimenAction = {
        action: action1,
        dimen: dimen1,
      };
    }

    this.setState({ crrShowDimenAction });
  };

  render() {
    let {
      getAllManagerActionPlanData,
      getDemographicIndexForManagerData,
      GetParameterLabel,
      EmpFilterData,
      getIndexFilterData,
    } = this.props;

    let {
      crrShowDimenAction,
      crrMngrEmp_idList,
      showMngrActionsPage,
      showChangeStatus,
      currACTData,
      currEMPData,
      showMAPView,
      showDropdown,
      showTaskDropdown,
      FilterValues,
    } = this.state;

    //-------------------------------------------------------------------------------------------

    let DmgForMngrData = [];
    //console.log('x FilterValues: ',FilterValues);

    if (
      getDemographicIndexForManagerData &&
      getDemographicIndexForManagerData.length > 0
    ) {
      getDemographicIndexForManagerData.forEach((Emp) => {
        let isCheck = 0;
        if (FilterValues && FilterValues.length > 0) {
          FilterValues.forEach((filter) => {
            if (filter.value !== "All") {
              if (!(Emp[filter.level] === filter.value)) {
                isCheck++;
              }
            }

            //console.log('x 1: ',Emp[filter.level]);
            //console.log('x 2: ',filter.value);
          });
        }
        // //console.log('x Emp: ',Emp);
        // //console.log('x isCheck: ',isCheck);

        if (isCheck === 0) {
          DmgForMngrData.push(Emp);
        }
      });
    }

    //console.log('x DmgForMngrData: ',DmgForMngrData);

    let AllMngrAPlanData = [];

    if (getAllManagerActionPlanData && getAllManagerActionPlanData.length > 0) {
      getAllManagerActionPlanData.forEach((action) => {
        let emp_id =
          action && action.employeeId && action.employeeId.emp_id
            ? action.employeeId.emp_id
            : "";

        let getIndex = DmgForMngrData.findIndex(
          (prev) => prev.emp_id === emp_id
        );
        if (getIndex !== -1 || true) {
          AllMngrAPlanData.push(action);
        }
      });
    }

    //-------------------------------------------------------------------------------

    //console.log('getAllManagerActionPlanData: ',getAllManagerActionPlanData);
    // ////console.log('DmgForMngrData: ',DmgForMngrData);

    let EmployeeList = [];
    let actionTotalCount = 0;
    let actionCompleteCount = 0;
    let totalTasks = 0;
    let completedTasks = 0;
    let StartedTasks = 0;

    if (AllMngrAPlanData && AllMngrAPlanData.length > 0) {
      AllMngrAPlanData.forEach((action) => {
        if (
          action &&
          action.employeeTaskList &&
          action.employeeTaskList.length > 0
        ) {
          // ////console.log('action.employeeTaskList: ',action.employeeTaskList);

          totalTasks += action.employeeTaskList.length;
          completedTasks += action.employeeTaskList.filter(
            (x) => x.isComplete == true
          ).length;

          StartedTasks += action.employeeTaskList.filter(
            (x) => x.isStarted == true
          ).length;
        }

        if (action.isComplete) {
          actionCompleteCount++;
        }
        actionTotalCount++;
        let emp_id =
          action && action.employeeId && action.employeeId.emp_id
            ? action.employeeId.emp_id
            : "";
        let EmpEmail =
          action && action.employeeId && action.employeeId.email
            ? action.employeeId.email
            : "";
        let EmpName =
          action && action.employeeId && action.employeeId.name
            ? action.employeeId.name
            : "";

        let getIndex = EmployeeList.findIndex((prev) => prev.emp_id === emp_id);
        if (getIndex === -1) {
          EmployeeList.push({
            emp_id: emp_id,
            EmpName: EmpName,
            EmpEmail: EmpEmail,
          });
        }
      });
    }

    let EmployeeActionList = [];
    if (EmployeeList && EmployeeList.length > 0) {
      EmployeeList.forEach((emp) => {
        let actionFilteredList = AllMngrAPlanData.filter(
          (x) =>
            (x && x.employeeId && x.employeeId.emp_id
              ? x.employeeId.emp_id
              : "") == emp.emp_id
        );
        let completedActionCount = actionFilteredList.filter(
          (action) => action.isComplete == true
        ).length;
        let totalActionCount =
          actionFilteredList && actionFilteredList.length > 0
            ? actionFilteredList.length
            : 0;
        //////console.log('actionFilteredList: ',actionFilteredList);
        let startedActionCount = actionFilteredList.filter(
          (action) => action.isStarted == true
        ).length;

        let actionFilteredStartDate = AllMngrAPlanData.filter(
          (x) => x.isStarted == true
        );
        let actionFilteredEndDate = AllMngrAPlanData.filter(
          (x) => x.isComplete == true
        );
        ////console.log('actionFilteredStartDate: ',actionFilteredStartDate);
        //////console.log('actionFilteredEndDate: ',actionFilteredEndDate);

        let startDateList = [];
        if (actionFilteredStartDate && actionFilteredStartDate.length > 0) {
          actionFilteredStartDate.forEach((item) => {
            startDateList.push(item.startDate);
          });
        }
        let endDateList = [];
        if (actionFilteredEndDate && actionFilteredEndDate.length > 0) {
          actionFilteredEndDate.forEach((item) => {
            endDateList.push(item.startDate);
          });
        }
        ////console.log('startDateList: emp',emp,startDateList);

        // let min = 0
        // let max = 0
        // if(startDateList && startDateList.length>0){
        //   min = Math.min(...startDateList);
        // }
        // if(endDateList && endDateList.length>0){
        //   max = Math.max(...endDateList);
        // }

        //////console.log('minimum: ',min);
        //////console.log('maximum: ',max);

        let ManagerList = [];
        if (DmgForMngrData && DmgForMngrData.length > 0) {
          ManagerList = DmgForMngrData;
        }
        let getIndex = ManagerList.findIndex(
          (prev) => prev.emp_id === emp.emp_id
        );
        let AllStartDates = [];
        let AllEndDates = [];
        if (actionFilteredList && actionFilteredList.length > 0) {
          actionFilteredList.forEach((item) => {
            if (true) {
              ////console.log('action---item: ',item);

              if (item.isStarted) {
                AllStartDates.push(item.startDate);
              }
              if (item.isComplete) {
                AllEndDates.push(item.endDate);
              }
            }
          });
        }
        ////console.log('AllStartDates: ',AllStartDates);
        ////console.log('AllEndDates: ',AllEndDates);

        let tempList = {
          EmpName: emp.EmpName,
          EmpEmail: emp.EmpEmail,
          emp_id: emp.emp_id,
          emp_details: getIndex !== -1 ? DmgForMngrData[getIndex] : "",
          action_list: actionFilteredList,
          startDate:
            AllStartDates && AllStartDates.length > 0
              ? Math.min(...AllStartDates)
              : 0,
          endDate:
            AllEndDates && AllEndDates.length > 0
              ? Math.max(...AllEndDates)
              : 0,
          isStarted: startedActionCount ? true : false,
          isComplete: completedActionCount === totalActionCount ? true : false,
        };
        EmployeeActionList.push(tempList);
      });
    }
    ////console.log('EmployeeActionList: ',EmployeeActionList);

    // let ActionType2List = [];
    // if(AllMngrAPlanData && AllMngrAPlanData.length>0){
    //   AllMngrAPlanData.forEach((item)=>{
    //     let getIndex = ActionType2List.findIndex(prev=>prev.actionId.id===item.actionId.id);
    //     if(getIndex===-1){
    //       let temp= {
    //         actionId:item.actionId,
    //         empList:[]
    //       }
    //       if(item.emp_id){
    //         temp["empList"].push({
    //           "createdAt":item.createdAt,
    //           "emp_id":item.emp_id
    //         });
    //         ActionType2List.push(temp);
    //       }
    //     }
    //     else{
    //       if(item.emp_id){
    //         ActionType2List[getIndex]["empList"].push({
    //           "createdAt":item.createdAt,
    //           "emp_id":item.emp_id
    //         });
    //       }
    //     }
    //   });
    // }

    //console.log('AllMngrAPlanData: ',AllMngrAPlanData)

    let TaskList = [];

    if (AllMngrAPlanData && AllMngrAPlanData.length > 0) {
      AllMngrAPlanData.forEach((action) => {
        if (
          action &&
          action.employeeTaskList &&
          action.employeeTaskList.length > 0
        ) {
          action.employeeTaskList.forEach((task) => {
            let getIndex = TaskList.findIndex(
              (prev) => prev.taskId.id === task.taskId.id
            );
            if (getIndex === -1) {
              TaskList.push({
                ...task,
                managerList: [
                  { managerId: task.emp_id, isComplete: task.isComplete },
                ],
                taskCount: 1,
                actionName: action.actionId.name,
                parameterName: action.actionId.parameterName,
              });
            } else {
              TaskList[getIndex]["managerList"].push({
                managerId: task.emp_id,
                isComplete: task.isComplete,
              });
              TaskList[getIndex]["taskCount"] += 1;
            }

            // let getIndex = TaskList.findIndex(prev=>prev.id===task.id);
            // if(getIndex === -1){
            //   TaskList.push({
            //     ...task,
            //     completeList:[task.isComplete],
            //     taskCount:1,
            //     actionName:action.actionId.name,
            //     parameterName:action.actionId.parameterName
            //   });
            // }
            // else{
            //   TaskList[getIndex]["completeList"].push(task.isComplete);
            //   TaskList[getIndex]["taskCount"] += 1;
            // }
          });
        }
      });
    }

    // let PendingTaskList = [];
    // let CompletedTaskList = [];

    // if(TaskList && TaskList.length>0){
    //   TaskList.forEach((item)=>{

    //     let booleanCount = 0;
    //     if(item && item.completeList && item.completeList.length>0){
    //       booleanCount = item.completeList.filter(x=>x === true).length;
    //     }

    //     if(booleanCount === item.taskCount){
    //       CompletedTaskList.push(item);
    //     }
    //     else{
    //       PendingTaskList.push(item);
    //     }

    //   });
    // }

    // if(TaskList && TaskList.length>0){
    //   TaskList.forEach((item)=>{

    //     if(item.isComplete){
    //       CompletedTaskList.push(item);
    //     }
    //     else{
    //       PendingTaskList.push(item);
    //     }

    //   });
    // }

    // //console.log('PendingTaskList:--------> ',PendingTaskList)
    // //console.log('CompletedTaskList:--------> ',CompletedTaskList)

    let EmpFilterData2 = [];
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== "ManagerPID") {
          EmpFilterData2.push(emp);
        }
      });
    }

    ////console.log('showTaskDropdown :',showTaskDropdown)

    let FilteredAllManagerActionPlanData = [];
    if (getAllManagerActionPlanData && getAllManagerActionPlanData.length > 0) {
      getAllManagerActionPlanData.forEach((Emp) => {
        let empData = Emp && Emp.employeeId ? Emp.employeeId : "";
        let isCheck = 0;
        if (FilterValues && FilterValues.length > 0) {
          FilterValues.forEach((filter) => {
            if (filter.value !== "All") {
              if (!(empData[filter.level] === filter.value)) {
                isCheck++;
              }
            }
          });
        }

        if (isCheck === 0) {
          FilteredAllManagerActionPlanData.push(Emp);
        }
      });
    }

    function ManagerStatus(task) {
      let ResponseAction = {
        assigned:
          task && task.managerList.length > 0 ? task.managerList.length : 0,
        pending:
          task && task.managerList.length > 0
            ? task.managerList.filter((x) => x.isComplete === false).length
            : 0,
        completed:
          task && task.managerList.length > 0
            ? task.managerList.filter((x) => x.isComplete === true).length
            : 0,
      };
      return ResponseAction;
    }

    let EmployeeList2 = [];
    if (
      FilteredAllManagerActionPlanData &&
      FilteredAllManagerActionPlanData.length > 0
    ) {
      FilteredAllManagerActionPlanData.forEach((emp) => {
        let employeeDetails = emp && emp.employeeId ? emp.employeeId : "";
        let employeeId =
          emp && emp.employeeId && emp.employeeId.id ? emp.employeeId.id : "";

        let getIndex = EmployeeList2.findIndex(
          (prev) => prev.employeeId === employeeId
        );

        if (getIndex !== -1) {
          EmployeeList2[getIndex]["actionList"].push(emp);
        } else {
          EmployeeList2.push({
            actionList: [emp],
            employeeDetails: employeeDetails,
            employeeId: employeeId,
          });
        }
      });
    }

    let TaskList2 = [];
    let DimenList2 = [];
    if (
      FilteredAllManagerActionPlanData &&
      FilteredAllManagerActionPlanData.length > 0
    ) {
      FilteredAllManagerActionPlanData.forEach((action) => {
        let taskList =
          action &&
            action.employeeTaskList &&
            action.employeeTaskList.length > 0
            ? action.employeeTaskList
            : [];
        if (taskList && taskList.length > 0) {
          taskList.forEach((task) => {
            console.log("---task1---", task);

            let getIndex = TaskList2.findIndex(
              (prev) => prev.taskId.id === task.taskId.id
            );
            if (getIndex === -1) {
              TaskList2.push({
                ...task,
                managerList: [
                  {
                    managerId: task.employeeId,
                    isComplete: task.isComplete,
                    isStarted: task.isStarted,
                  },
                ],
                taskCount: 1,
                actionName: action.actionId.name,
                parameterName: action.actionId.parameterName,
              });
            } else {
              TaskList2[getIndex]["managerList"].push({
                managerId: task.employeeId,
                isComplete: task.isComplete,
                isStarted: task.isStarted,
              });
              TaskList2[getIndex]["taskCount"] += 1;
            }

            let getINDEX1 = DimenList2.findIndex(
              (prev) => prev === action.actionId.parameterName
            );
            if (getINDEX1 === -1) {
              DimenList2.push(action.actionId.parameterName);
            }
          });
        }
      });
    }

    if (
      FilteredAllManagerActionPlanData &&
      FilteredAllManagerActionPlanData.length > 0
    ) {
      FilteredAllManagerActionPlanData.forEach((action) => {
        if (
          action &&
          action.employeeTaskList &&
          action.employeeTaskList.length > 0
        ) {
          totalTasks += action.employeeTaskList.length;
          completedTasks += action.employeeTaskList.filter(
            (x) => x.isComplete == true
          ).length;
          StartedTasks += action.employeeTaskList.filter(
            (x) => x.isStarted == true
          ).length;
        }
      });
    }

    //console.log('(((((((((((((getAllManagerActionPlanData :',getAllManagerActionPlanData)

    // let ResponseAction = {
    //   "assigned":totalTasks,
    //   "pending":totalTasks-completedTasks,
    //   "completed":completedTasks
    // }

    let totalTaskCount = 0;
    let completedTaskCount = 0;
    let pendingTaskCount = 0;
    let notstartedTaskCount = 0;
    if (TaskList2 && TaskList2.length > 0) {
      TaskList2.forEach((item) => {
        if (item && item.managerList && item.managerList.length > 0) {
          totalTaskCount += item.managerList.length;
          item.managerList.forEach((task) => {
            // console.log("---task---",task)
            if (task.isComplete) {
              completedTaskCount++;
            }
            if (task.isStarted && !task.isComplete) {
              pendingTaskCount++;
            }
            if (!task.isStarted && !task.isComplete) {
              notstartedTaskCount++;
            }
          });
        }
      });
    }

    let ResponseAction = {
      assigned: totalTaskCount,
      pending: pendingTaskCount,
      completed: completedTaskCount,
      notstarted: notstartedTaskCount,
    };

    //console.log('(((((((((((((EmployeeList2 :',EmployeeList2);

    //console.log('TaskList:--------> ',TaskList);
    console.log("TaskList2:--------> ", TaskList2);

    let DimenListFinal = [];
    if (
      DimenList2 &&
      DimenList2.length > 0 &&
      TaskList2 &&
      TaskList2.length > 0
    ) {
      DimenList2.forEach((dimen) => {
        DimenListFinal.push({
          dimen: dimen,
          data: TaskList2.filter((prev) => prev.parameterName === dimen),
        });
      });
    }

    //console.log('(((((((((((((getDemographicIndexForManagerData :',getDemographicIndexForManagerData)

    //console.log('(((((((((((((EmployeeActionList :',EmployeeActionList)

    function getValue(data, type) {
      if (type === "name") {
        let name =
          data && data.employeeDetails && data.employeeDetails.name
            ? data.employeeDetails.name
            : "";
        return name;
      } else if (type === "email") {
        let email =
          data && data.employeeDetails && data.employeeDetails.email
            ? data.employeeDetails.email
            : "";
        return email;
      } else if (type === "emp_id") {
        let emp_id =
          data && data.employeeDetails && data.employeeDetails.emp_id
            ? data.employeeDetails.emp_id
            : "";
        return emp_id;
      } else if (type === "emp_startdate") {
        let ActionList = data && data.actionList ? data.actionList : "";
        let StartedActionList = ActionList.filter((x) => x.isStarted === true);

        let startDate = 0;
        if (StartedActionList && StartedActionList.length > 0) {
          StartedActionList.forEach((item, index) => {
            if (index === 0) {
              startDate = item.startDate;
            } else {
              if (item.startDate < startDate) {
                startDate = item.startDate;
              }
            }
          });
        }

        return startDate ? moment.unix(startDate).format("DD MMM, yyyy") : "";
      } else if (type === "emp_enddate") {
        let ActionList = data && data.actionList ? data.actionList : "";
        let CompleteActionList = ActionList.filter(
          (x) => x.isComplete === true
        );

        let endDate = 0;
        if (CompleteActionList && CompleteActionList.length > 0) {
          CompleteActionList.forEach((item, index) => {
            if (index === 0) {
              endDate = item.endDate;
            } else {
              if (item.endDate > endDate) {
                endDate = item.endDate;
              }
            }
          });
        }

        return endDate ? moment.unix(endDate).format("DD MMM, yyyy") : "";
      } else {
        return "";
      }
    }

    function getActionCalculation(action, type) {
      //////console.log("action----------------->",action)
      let totalTaskCount = 0;
      let completedTaskCount = 0;
      if (action && action.actionList && action.actionList.length > 0) {
        totalTaskCount = action.actionList.length;
        completedTaskCount = action.actionList.filter(
          (x) => x.isComplete === true
        ).length;
      }
      if (type === 1) {
        return completedTaskCount.toString() + "/" + totalTaskCount.toString();
      } else if (type === 2) {
        return (completedTaskCount * 100) / totalTaskCount + "%";
      } else if (type === 3) {
        return completedTaskCount === totalTaskCount;
      } else if (type === 4) {
        return completedTaskCount !== totalTaskCount;
      } else if (type === 5) {
        return completedTaskCount > 0
          ? completedTaskCount !== totalTaskCount
          : false;
      } else {
        return 0;
      }
    }

    function getMNGRDetails(value) {
      return currEMPData &&
        currEMPData["employeeDetails"] &&
        currEMPData["employeeDetails"][value]
        ? currEMPData["employeeDetails"][value]
        : "";
    }
    function getTaskCalculation(activity, type) {
      //////console.log("activity---------------->",activity);
      let totalTaskCount = 0;
      let completedTaskCount = 0;
      if (
        activity &&
        activity.employeeTaskList &&
        activity.employeeTaskList.length > 0
      ) {
        totalTaskCount = activity.employeeTaskList.length;
        completedTaskCount = activity.employeeTaskList.filter(
          (x) => x.isComplete === true
        ).length;
      }
      if (type === 1) {
        return completedTaskCount.toString() + "/" + totalTaskCount.toString();
      } else if (type === 2) {
        return (completedTaskCount * 100) / totalTaskCount + "%";
      } else {
        return 0;
      }
    }

    let taskDataList = [];
    let actionDataList = [];
    let empDataList = [];

    if (showMngrActionsPage === 2) {
      if (
        currACTData &&
        currACTData["employeeTaskList"] &&
        currACTData["employeeTaskList"].length > 0
      ) {
        if (showChangeStatus === "completed") {
          taskDataList = currACTData["employeeTaskList"].filter(
            (x) => x.isComplete === true
          );
        } else if (showChangeStatus === "pending") {
          taskDataList = currACTData["employeeTaskList"].filter(
            (x) => x.isComplete === false
          );
        } else {
          taskDataList = currACTData["employeeTaskList"];
        }
      }
    } else if (showMngrActionsPage === 1) {
      if (
        currEMPData &&
        currEMPData["actionList"] &&
        currEMPData["actionList"].length > 0
      ) {
        if (showChangeStatus === "completed") {
          actionDataList = currEMPData["actionList"].filter(
            (x) => x.isComplete === true
          );
        } else if (showChangeStatus === "pending") {
          actionDataList = currEMPData["actionList"].filter(
            (x) => x.isComplete === false
          );
        } else {
          actionDataList = currEMPData["actionList"];
        }
      }
    } else {
      if (EmployeeList2 && EmployeeList2.length > 0) {
        if (showChangeStatus === "completed") {
          empDataList = EmployeeList2.filter((x) => getActionCalculation(x, 3));
        } else if (showChangeStatus === "pending") {
          empDataList = EmployeeList2.filter((x) => getActionCalculation(x, 4));
        } else {
          empDataList = EmployeeList2;
        }
      }
    }

    let { indexType, getCommentData } = this.props;
    console.log("indexType------getCommentData--->", indexType, getCommentData);

    console.log("taskDataList--------->", taskDataList);

    function groupByKey(list11) {
      let result = list11.reduce(function (r, a) {
        r[a.actionName] = r[a.actionName] || [];
        r[a.actionName].push(a);
        return r;
      }, Object.create(null));
      console.log("result--------->", result);
      return result;
    }

    function getActionStatus(list) {
      let completeCoun1 = 0;
      let pendingCount1 = 0;
      let notstartedCount1 = 0;
      let totalCount1 = 0;
      if (list && list.length > 0) {
        list.forEach((task) => {
          totalCount1 += Number(ManagerStatus(task).assigned);
          completeCoun1 += Number(ManagerStatus(task).completed);
          pendingCount1 += Number(ManagerStatus(task).pending);
        });
      }
      if (totalCount1 === completeCoun1) {
        return {
          value: "Completed",
          color: "bg-green-500",
          colorBg: "#00CD7E"
        };
      } else if (totalCount1 > pendingCount1 && pendingCount1 > 0) {
        return {
          value: "Pending",
          color: "bg-yellow-100",
          colorBg: "#EAB308"
        };
      } else {
        return {
          value: "Not Started",
          color: "bg-red-400",
          colorBg: "#90A4AE"
        };
      }
    }



    function hexToRgbA(hex, opacity) {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
      throw hex;
    }

    return (
      <>
        {showMngrActionsPage === 0 ? (
          <div className="md:p-6 p-4 space-y-4 w-full flex-1 overflow-y-auto bg-[#f9fafb] ">
            <div className="justify-between md:flex">
              {/* left side */}
              <div className="flex items-center">
                <h1 class=" xl:text-2xl text-xl font-medium text-[#3D405B]  ">
                  {" "}
                  Manager action plan{" "}
                </h1>
              </div>
            </div>

            {/* filter desing */}
            <div className="justify-between flex flex-wrap gap-4 ">
              <Filter
                showFilter={this.state.showFilter}
                showValue={this.state.showValue}
                closeFilter={this.closeFilter}
                EmpFilterData2={EmpFilterData2}
                getIndexFilterData={getIndexFilterData}
                handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
              />

              <Commentary
                indexType={indexType}
                optionType={1}
                getCommentData={getCommentData}
              />
            </div>

            {/* Task Cards desing */}
            <IndexKPI ResponseAction={ResponseAction} />


            {/* dropdown menu desing  */}
            <div className="flex flex-wrap justify-between mb-4 gap-4 text-sm">
              <ManagerWise
                empDataList={empDataList}
                handleSelectMngrValue={this.handleSelectMngrValue}
                crrMngrEmp_idList={crrMngrEmp_idList}
              />
              <div className="flex flex-wrap gap-2">
                <button
                  onClick={() => this.handleMAPView(true)}
                  className={
                    showMAPView
                      ? " bg-white p-2 px-4 rounded-md border text-[#2196f3] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2"
                      : " bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2"
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={
                      showMAPView
                        ? "group-hover:text-[#2196f3] transition-all duration-150  text-[#2196f3] icon icon-tabler icon-tabler-table"
                        : "group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-table"
                    }
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <rect x="4" y="4" width="16" height="16" rx="2"></rect>
                    <line x1="4" y1="10" x2="20" y2="10"></line>
                    <line x1="10" y1="4" x2="10" y2="20"></line>
                  </svg>
                  <span>Manager Wise</span>
                </button>

                <button
                  onClick={() => this.handleMAPView(false)}
                  className={
                    !showMAPView
                      ? " bg-white p-2 px-4 rounded-md border text-[#2196f3] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2"
                      : " bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2"
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={
                      !showMAPView
                        ? "group-hover:text-[#2196f3] transition-all duration-150  text-[#2196f3] icon icon-tabler icon-tabler-table"
                        : "group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-table"
                    }
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <rect x="4" y="4" width="16" height="16" rx="2"></rect>
                    <line x1="4" y1="10" x2="20" y2="10"></line>
                    <line x1="10" y1="4" x2="10" y2="20"></line>
                  </svg>
                  <span>Task View</span>
                </button>
              </div>
            </div>


            {/* show card list design */}
            {showMAPView ? (
              <>
                {empDataList && empDataList.length > 0 ? (
                  <div className="grid gap-2 lg:grid-cols-1 md:grid-cols-1">
                    {empDataList.map((empAct, index) =>
                      (
                        crrMngrEmp_idList && crrMngrEmp_idList.length > 0
                          ? crrMngrEmp_idList.some(
                            (prev) => prev === getValue(empAct, "emp_id")
                          )
                          : true
                      ) ? (
                        <div
                          key={index}
                          className="bg-white hover:shadow-lg duration-150 transition text-[#3D405B]  border 2xl:px-6 p-4 rounded-lg  cursor-pointer flex lg:flex-row flex-col lg:justify-between justify-start lg:items-center gap-4 relative"
                        >
                          <div className="flex  flex-row items-center gap-2 lg:w-72  flex-shrink-0">
                            <div className="p-2 rounded-full bg-slate-100">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-user"
                                width="30"
                                height="30"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#2c3e50"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <circle cx="12" cy="7" r="4" />
                                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                              </svg>
                            </div>
                            <div className="space-y-0 ">
                              <h1 className="text-base font-semibold text-[#3D405B]">
                                {getValue(empAct, "name")}
                              </h1>
                              <p className="text-[#3D405B]/70 text-sm capitalize font-medium">
                                Emp ID:{" "}
                                <b className="font-medium">
                                  {getValue(empAct, "emp_id")}
                                </b>
                              </p>
                            </div>
                            <div className="lg:hidden block absolute top-4 right-4">
                              {getActionCalculation(empAct, 3) ? (
                                <button className="flex items-center justify-center px-4 p-2 2xl:text-sm text-xs font-medium text-green-600 uppercase bg-green-100 rounded-full cursor-pointer ">
                                 
                                  completed 
                                </button>
                              ) : getActionCalculation(empAct, 5) ? (
                                <button className="flex items-center justify-center px-4 p-2 2xl:text-sm text-xs font-medium text-red-600 uppercase bg-red-100 rounded-full cursor-pointer ">
                                  {" "}
                                  not started{" "}
                                </button>
                              ) : (
                                <button className="flex items-center justify-center px-4 p-2 2xl:text-sm text-xs font-medium text-yellow-600 uppercase bg-yellow-100 rounded-full cursor-pointer ">
                                  {" "}
                                  pending{" "}
                                </button>
                              )}
                            </div>
                          </div>

                          <div className="flex flex-row items-center  gap-4">

                            <p className="text-[#3D405B]/70 text-sm capitalize font-medium">
                              {/* Email:{" "} */}
                              <b className="font-medium lowercase">
                                {getValue(empAct, "email")}
                              </b>
                            </p>

                          </div>

                          <div className="lg:block hidden">
                            {getActionCalculation(empAct, 3) ? (
                              <button className="flex items-center justify-center px-4 py-2 2xl:text-sm text-xs font-medium text-green-600 uppercase bg-green-100 rounded-md cursor-pointer w-24 ">
                                {" "}
                                completed{" "}
                              </button>
                            ) : getActionCalculation(empAct, 5) ? (
                              <button className="flex items-center justify-center px-4 py-2 2xl:text-sm text-xs font-medium text-red-600 uppercase bg-red-100 rounded-md cursor-pointer w-24 ">
                                {" "}
                                not started{" "}
                              </button>
                            ) : (
                              <button className="flex items-center justify-center px-4 py-2 2xl:text-sm text-xs font-medium text-yellow-600 uppercase bg-yellow-100 rounded-md cursor-pointer w-24 ">
                                {" "}
                                pending{" "}
                              </button>
                            )}
                          </div>

                          <div className="space-y-1.5 flex-shrink-0">
                            <div className="flex justify-between text-sm font-medium">
                              <p>Action</p>{" "}
                              <p>{getActionCalculation(empAct, 1)}</p>
                            </div>
                            <div className="h-1 overflow-hidden bg-gray-100 rounded-full lg:w-40 w-full">
                              <div
                                className={
                                  getActionCalculation(empAct, 3)
                                    ? "bg-green-400 h-1"
                                    : "bg-yellow-400 h-1"
                                }
                                style={{
                                  width: getActionCalculation(empAct, 2),
                                }}
                              />
                            </div>
                          </div>

                          <button
                            onClick={() => this.showActions(empAct)}
                            className="flex items-center justify-center 2xl:text-base lg:text-sm text-base px-4 py-2 text-white rounded-md bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] capitalize"
                          >
                            view Action
                          </button>
                        </div>
                      ) : null
                    )}
                  </div>
                ) : (
                  <>
                    <div className="py-4 text-2xl font-medium text-center text-gray-400">
                      Data Not Available
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="p-2">
                  {DimenListFinal && DimenListFinal.length > 0
                    ? DimenListFinal.map((item5) => (
                      <>
                        <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap   py-4   ">
                          {item5.dimen}
                        </h1>

                        <div className="space-y-4">
                          {item5 &&
                            item5.data &&
                            item5.data.length > 0 &&
                            Object.keys(groupByKey(item5.data)) &&
                            Object.keys(groupByKey(item5.data)).length > 0 ? (
                            <>
                              {Object.keys(groupByKey(item5.data)).map(
                                (action1) => (
                                  <>
                                    <div className="bg-white space-y-10  items-center hover:shadow-lg duration-150 transition   text-[#3D405B]  border p-6 py-4 rounded-lg  cursor-pointer ">
                                      <div className="flex items-center justify-between w-full">
                                        <div className="text-[#3D405B] flex flex-row gap-4 items-center ">
                                          <div class=" flex items-center rounded-lg  cursor-pointer p-3  border "
                                            style={{
                                              background: hexToRgbA(getActionStatus(
                                                groupByKey(item5.data)[
                                                action1
                                                ]
                                              )["colorBg"], "0.20")
                                            }}>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              class="icon icon-tabler icon-tabler-clipboard-listtext-green-500"
                                              width="30"
                                              height="30"
                                              viewBox="0 0 24 24"
                                              stroke-width="1.5"
                                              stroke="currentColor"
                                              fill="none"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            >
                                              <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                              ></path>
                                              <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path>
                                              <rect
                                                x="9"
                                                y="3"
                                                width="6"
                                                height="4"
                                                rx="2"
                                              ></rect>
                                              <line
                                                x1="9"
                                                y1="12"
                                                x2="9.01"
                                                y2="12"
                                              ></line>
                                              <line
                                                x1="13"
                                                y1="12"
                                                x2="15"
                                                y2="12"
                                              ></line>
                                              <line
                                                x1="9"
                                                y1="16"
                                                x2="9.01"
                                                y2="16"
                                              ></line>
                                              <line
                                                x1="13"
                                                y1="16"
                                                x2="15"
                                                y2="16"
                                              ></line>
                                            </svg>
                                          </div>
                                          <div className="space-y-0 ">
                                            <h1 className="text-lg font-medium text-[#3D405B]">
                                              {" "}
                                              {action1}
                                            </h1>
                                            <div className="flex items-center space-x-2">
                                              <p className=" text-sm font-medium capitalize text-[#3D405B]/70">
                                                Action:
                                              </p>
                                              <div
                                                style={{
                                                  background: getActionStatus(
                                                    groupByKey(item5.data)[
                                                    action1
                                                    ]
                                                  )["colorBg"]
                                                }}
                                                className={
                                                  "cursor-pointer text-xs capitalize  rounded-full flex items-center px-3 py-1 font-medium text-white "
                                                }
                                              >
                                                {
                                                  getActionStatus(
                                                    groupByKey(item5.data)[
                                                    action1
                                                    ]
                                                  )["value"]
                                                }
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="flex items-center justify-between">

                                          <button
                                            className="flex items-center justify-center 2xl:text-base lg:text-sm text-base md:px-4 p-2 text-white rounded-md bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] capitalize"
                                            onClick={() =>
                                              this.handleShowTask(
                                                action1,
                                                item5.dimen
                                              )
                                            }
                                          >
                                            <span className="md:block hidden"> Full View</span>
                                            <span className="cursor-pointer material-icons">
                                              {crrShowDimenAction[
                                                "action"
                                              ] === action1 &&
                                                crrShowDimenAction["dimen"] ===
                                                item5.dimen
                                                ? "expand_less"
                                                : "expand_more"}
                                            </span>
                                          </button>
                                        </div>
                                      </div>

                                      {crrShowDimenAction["action"] ===
                                        action1 &&
                                        crrShowDimenAction["dimen"] ===
                                        item5.dimen ? (
                                        <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                                          {groupByKey(item5.data)[
                                            action1
                                          ].map((task, index) => (
                                            <>
                                              <div className="capitalize bg-white border rounded-md ">
                                                <div className="relative items-center justify-between p-3 text-sm text-gray-500 md:flex ">
                                                  <div className="w-full">
                                                    <div className="flex flex-row gap-4 items-center">
                                                      <div className="flex-shrink-0 w-36  items-center">
                                                        <DonutChart
                                                          managerStatus={ManagerStatus(
                                                            task
                                                          )}
                                                        />
                                                      </div>
                                                      <div
                                                        title="Employee id"
                                                        className="text-[#3D405B] flex flex-wrap gap-2 font-medium "
                                                      >
                                                        <div className="flex items-center space-x-2">
                                                          <span className="w-4 h-2 rounded-[10px] bg-blue-500 flex"></span>
                                                          <span className="">
                                                            Assigned
                                                          </span>{" "}
                                                          <span className="text-sm font-medium">
                                                            {
                                                              ManagerStatus(
                                                                task
                                                              ).assigned
                                                            }
                                                          </span>
                                                        </div>
                                                        <div className="flex items-center space-x-2">
                                                          <span className="w-4 h-2 rounded-[10px] bg-yellow-500 flex"></span>
                                                          <span className="">
                                                            Pending
                                                          </span>{" "}
                                                          <span className="text-sm font-medium">
                                                            (
                                                            {
                                                              ManagerStatus(
                                                                task
                                                              ).pending
                                                            }
                                                            )
                                                          </span>
                                                        </div>
                                                        <div className="flex items-center space-x-2">
                                                          <span className="w-4 h-2 rounded-[10px] bg-green-500 flex"></span>
                                                          <span className="">
                                                            Completed
                                                          </span>{" "}
                                                          <span className="text-sm font-medium">
                                                            ({" "}
                                                            {
                                                              ManagerStatus(
                                                                task
                                                              ).completed
                                                            }
                                                            )
                                                          </span>
                                                        </div>

                                                      </div>
                                                    </div>

                                                    <h1 className="text-sm font-medium text-[#3D405B]">
                                                      <span className="text-black">
                                                        TASK:
                                                      </span>{" "}
                                                      {task &&
                                                        task.taskId &&
                                                        task.taskId.name
                                                        ? task.taskId.name
                                                        : ""}
                                                    </h1>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ))}
                                        </div>
                                      ) : null}
                                    </div>
                                  </>
                                )
                              )}
                            </>
                          ) : null}
                        </div>
                      </>
                    ))
                    : null}
                </div>
              </>
            )}
          </div>
        ) : null}

        {showMngrActionsPage === 1 ? (
          <div className=" md:p-6 p-4 space-y-4 w-full flex-1 overflow-y-auto bg-[#f9fafb]">
            <div className="justify-between md:flex">
              {/* left side */}
              <div className="flex items-center space-x-4 lg:space-x-6">
                <button
                  onClick={() => this.handleBackActionTask()}
                  className="text-blue-500 bg-[#edf6fe] rounded-full cursor-pointer hover:bg-blue-100 trasition duration-150 p-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-arrow-left  "
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#2196f3"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                    <line x1="5" y1="12" x2="11" y2="18"></line>
                    <line x1="5" y1="12" x2="11" y2="6"></line>
                  </svg>
                </button>
                <h1 class=" xl:text-2xl text-xl font-medium text-[#3D405B]">
                  {" "}
                  Action plans
                </h1>
              </div>

              <div className="md:flex  hidden items-center text-sm text-[#3D405B]">
                <span className="mx-2 material-symbols-rounded">home</span>
                <span>/</span>
                <span className="px-2">My survey</span>
                <span>/</span>
                <span className="px-2">Manager action plan</span>
                <span>/</span>
                <span className="px-2 font-semibold">Action plans</span>
              </div>
            </div>
            {/* end */}
            <div className="bg-white flex space-x-6 items-center hover:shadow-lg duration-150 transition   text-[#3D405B]  border p-6 rounded-lg  cursor-pointer ">
              <div className="p-4 rounded-full bg-slate-100 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-user"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#2c3e50"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx="12" cy="7" r="4" />
                  <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                </svg>
              </div>
              <div className="space-y-2">
                <h1 className="text-xl font-semibold">
                  {getMNGRDetails("name")}
                </h1>
                <p className=" text-sm capitalize text-[#3D405B]/70">
                  Employee ID:{" "}
                  <b className="font-semibold">{getMNGRDetails("emp_id")}</b>
                </p>
                <p className=" text-sm capitalize text-[#3D405B]/70">
                  Email:{" "}
                  <b className="font-semibold">{getMNGRDetails("email")}</b>
                </p>
              </div>
            </div>
            {/*sub nav bar 1 */}
            <div className="items-center justify-between border-b md:flex">
              <div className="flex space-x-2 overflow-hidden overflow-x-auto text-sm font-medium text-gray-600 capitalize cursor-pointer whitespace-nowrap tab-scroll">
                <span
                  onClick={() => this.handleChangeStatus("all")}
                  className={
                    showChangeStatus === "all"
                      ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                      : "hover:bg-[#F9FAFB] transition-all duration-150   cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "
                  }
                >
                  All{" "}
                </span>
                <span
                  onClick={() => this.handleChangeStatus("pending")}
                  className={
                    showChangeStatus === "pending"
                      ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                      : "hover:bg-[#F9FAFB] transition-all duration-150   cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "
                  }
                >
                  pending
                </span>
                <span
                  onClick={() => this.handleChangeStatus("completed")}
                  className={
                    showChangeStatus === "completed"
                      ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                      : "hover:bg-[#F9FAFB] transition-all duration-150   cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "
                  }
                >
                  Completed
                </span>
              </div>
            </div>
            {/* end */}
            {/* card work */}

            {actionDataList && actionDataList.length > 0 ? (
              <div className="grid gap-4 lg:grid-cols-4 md:grid-cols-2">
                {actionDataList.map((activity) => (
                  <div className="bg-white hover:shadow-lg duration-150 transition space-y-4 text-[#3D405B]  border p-6 rounded-lg  cursor-pointer flex flex-col justify-between h-full">
                    <div>
                      <h1 className="text-lg font-semibold">
                        {activity && activity.actionId && activity.actionId.name
                          ? activity.actionId.name
                          : ""}
                      </h1>
                      <p className="text-[#3D405B]/70 text-sm capitalize">
                        created date{" "}
                        {moment(new Date(parseInt(activity.createdAt)))
                          .utcOffset("+05:30")
                          .format("DD MMM YYYY")}
                      </p>
                    </div>

                    <p className="text-base text-[#3D405B]  line-clamp-3">
                      {activity && activity.actionId && activity.actionId.desc
                        ? activity.actionId.desc
                        : ""}
                    </p>

                    <div className="space-y-1.5">
                      <div className="flex justify-between text-base font-semibold text-[#3D405B] ">
                        <p>Task</p> <p>{getTaskCalculation(activity, 1)}</p>
                      </div>
                      <div className="h-1 overflow-hidden bg-gray-100 rounded-full">
                        <div
                          className="h-1 bg-blue-500 w-0/6"
                          style={{ width: getTaskCalculation(activity, 2) }}
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => this.showTasks(activity)}
                      className="flex items-center justify-between text-sky-500"
                    >
                      <h1 className="text-sm font-medium uppercase cursor-pointer">
                        view tasks
                      </h1>
                      {activity.isComplete ? (
                        <button className="flex items-center px-4 py-2 text-sm font-medium text-white uppercase bg-green-500 rounded-md cursor-pointer">
                          {" "}
                          completed{" "}
                        </button>
                      ) : (
                        <button className="flex items-center px-4 py-2 text-sm font-medium text-white uppercase bg-yellow-500 rounded-md cursor-pointer">
                          {" "}
                          pending{" "}
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <div className="py-4 text-2xl font-medium text-center text-gray-400">
                  Data Not Available
                </div>
              </>
            )}
          </div>
        ) : null}

        {showMngrActionsPage === 2 ? (
          <div className="md:p-6 p-4 space-y-4 w-full flex-1 overflow-y-auto bg-[#f9fafb]">
            <div className="justify-between md:flex">
              {/* left side */}
              <div className="flex items-center space-x-4 lg:space-x-6">
                <button
                  onClick={() => this.handleBackActionTask()}
                  className="text-blue-500 bg-[#edf6fe] rounded-full cursor-pointer hover:bg-blue-100 trasition duration-150 p-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-arrow-left  "
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#2196f3"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                    <line x1="5" y1="12" x2="11" y2="18"></line>
                    <line x1="5" y1="12" x2="11" y2="6"></line>
                  </svg>
                </button>
                <h1 class=" xl:text-2xl text-xl font-medium text-[#3D405B]">
                  {" "}
                  Action plans
                </h1>
              </div>

              <div className="md:flex  hidden items-center text-sm text-[#3D405B]">
                <span className="mx-2 material-symbols-rounded">home</span>
                <span>/</span>
                <span className="px-2">My survey</span>
                <span>/</span>
                <span className="px-2">Manager action plan</span>
                <span>/</span>
                <span className="px-2 font-semibold">Action plans</span>
              </div>
            </div>
            {/* end */}
            <div className="bg-white flex space-x-6 items-center hover:shadow-lg duration-150 transition   text-[#3D405B]  border p-6 rounded-lg  cursor-pointer ">
              <div className="p-4 rounded-full bg-slate-100 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-user"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#2c3e50"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx="12" cy="7" r="4" />
                  <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                </svg>
              </div>
              <div className="space-y-2">
                <h1 className="text-xl font-semibold">
                  {getMNGRDetails("name")}
                </h1>
                <p className=" text-sm capitalize text-[#3D405B]/70">
                  Employee ID:{" "}
                  <b className="font-semibold">{getMNGRDetails("emp_id")}</b>
                </p>
                <p className=" text-sm capitalize text-[#3D405B]/70">
                  Email:{" "}
                  <b className="font-semibold">{getMNGRDetails("email")}</b>
                </p>
              </div>
            </div>
            {/*sub nav bar 1 */}
            <div className="items-center justify-between border-b md:flex">
              <div className="flex space-x-2 overflow-hidden overflow-x-auto text-sm font-medium text-gray-600 capitalize cursor-pointer whitespace-nowrap tab-scroll">
                <span
                  onClick={() => this.handleChangeStatus("all")}
                  className={
                    showChangeStatus === "all"
                      ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                      : "hover:bg-[#F9FAFB] transition-all duration-150   cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "
                  }
                >
                  All{" "}
                </span>
                <span
                  onClick={() => this.handleChangeStatus("pending")}
                  className={
                    showChangeStatus === "pending"
                      ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                      : "hover:bg-[#F9FAFB] transition-all duration-150   cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "
                  }
                >
                  pending
                </span>
                <span
                  onClick={() => this.handleChangeStatus("completed")}
                  className={
                    showChangeStatus === "completed"
                      ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                      : "hover:bg-[#F9FAFB] transition-all duration-150   cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "
                  }
                >
                  Completed
                </span>
              </div>
            </div>
            {/* end */}
            {/* card work */}

            {taskDataList && taskDataList.length > 0 ? (
              <div className="grid gap-4 lg:grid-cols-1 md:grid-cols-1">
                {taskDataList.map((task) => (
                  <div
                    className="bg-white flex space-x-6 items-center hover:shadow-lg duration-150 transition   text-[#3D405B]  border p-6 rounded-lg  cursor-pointer"
                    style={{
                      backgroundImage: 'url("img/default_sm.png")',
                      backgroundSize: "cover",
                    }}
                  >
                    <div
                      className={
                        " flex items-center rounded-lg  cursor-pointer p-6 px-4 border " +
                        (task.isComplete
                          ? "bg-green-50"
                          : task.isStarted
                            ? "bg-yellow-50"
                            : "bg-blue-50")
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={
                          "icon icon-tabler icon-tabler-clipboard-list" +
                          (task.isComplete
                            ? "text-green-500"
                            : task.isStarted
                              ? "text-yellow-500"
                              : "text-blue-500")
                        }
                        width="60"
                        height="60"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                        <rect x="9" y="3" width="6" height="4" rx="2" />
                        <line x1="9" y1="12" x2="9.01" y2="12" />
                        <line x1="13" y1="12" x2="15" y2="12" />
                        <line x1="9" y1="16" x2="9.01" y2="16" />
                        <line x1="13" y1="16" x2="15" y2="16" />
                      </svg>
                    </div>
                    <div className="space-y-2 ">
                      <h1 className="text-lg font-medium text-[#3D405B]">
                        {task && task.taskId && task.taskId.name
                          ? task.taskId.name
                          : ""}
                      </h1>
                      <p className=" text-sm font-medium capitalize text-[#3D405B]/70">
                        created date{" "}
                        {moment(new Date(parseInt(task.createdAt)))
                          .utcOffset("+05:30")
                          .format("DD MMM YYYY")}
                      </p>
                      {task.isComplete ? (
                        <button className="flex items-center px-4 py-2 text-sm text-white bg-green-500 rounded-md cursor-pointer ">
                          Completed
                        </button>
                      ) : (
                        <button className="flex items-center px-4 py-2 text-sm text-white bg-yellow-500 rounded-md cursor-pointer ">
                          Pending
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <div className="py-4 text-2xl font-medium text-center text-gray-400">
                  Data Not Available
                </div>
              </>
            )}
          </div>
        ) : null}
      </>
    );
  }
}
function mapStateToProps(state) {
  ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(Dimension);
