import { growConstants } from '../_constants';
import { growService } from '../_services';


export const growActions = {
    getGrowMonth,
    startGrowMonth,
    getGrowWeekListbyMonthId,
    getGrowDayListbyWeekId,
    getGrowDayDatabyDayId,
    startGrowDay,
    startGrowWeek,
    startGrowDayAction,
    startGrowDayReflection,
    submitGrowWeek,

    getAllGrowNote,
    submitGrowDay,
    startGrowDayKnowledge,
    getGrowParameterList,

    updateEmployeeSubactivityId,
    getSubactivityActivityId,
    updatecomment,

    getGrowPlusBucket_User,
    getGrowPlusMonth_User,
    getGrowPlusDay_User,
    getGrowPlusTask_User,
    changeGrowPlusTask_Input,
    changeGrowPlusResponse_Input,
    saveGrowPlusTask_User,
    getGrowPlusTaskStatus_User,
    forceGrowPlusTaskComplete_User
};

function forceGrowPlusTaskComplete_User(data) {
    let obj = {
        forceGrowPlusTaskComplete_UserData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        growService.forceGrowPlusTaskComplete_User(data)
            .then(
                grow => {
                    dispatch(success(grow))
                    dispatch(this.getGrowPlusTaskStatus_User())
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: growConstants.FORCE_GROW_PLUS_DAY_TASK_COMPLETE_USER_REQUEST } }
    function success(grow) { return { type: growConstants.FORCE_GROW_PLUS_DAY_TASK_COMPLETE_USER_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.FORCE_GROW_PLUS_DAY_TASK_COMPLETE_USER_FAILURE, error } }
}
function getGrowPlusTaskStatus_User(data) {

    return dispatch => {
        dispatch(request());
        growService.getGrowPlusTaskStatus_User(data)
            .then(
                grow => dispatch(success(grow)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: growConstants.GET_GROW_PLUS_DAY_TASK_STATUS_USER_REQUEST } }
    function success(grow) { return { type: growConstants.GET_GROW_PLUS_DAY_TASK_STATUS_USER_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_GROW_PLUS_DAY_TASK_STATUS_USER_FAILURE, error } }
}
function getGrowPlusTask_User(data) {
    let obj = {
        getGrowPlusTask_UserData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        growService.getGrowPlusTask_User(data)
            .then(
                grow => dispatch(success(grow)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: growConstants.GET_GROW_PLUS_DAY_TASK_USER_REQUEST } }
    function success(grow) { return { type: growConstants.GET_GROW_PLUS_DAY_TASK_USER_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_GROW_PLUS_DAY_TASK_USER_FAILURE, error } }
}


function saveGrowPlusTask_User(data, temp) {
    let obj = {
        saveGrowPlusTask_UserData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        growService.saveGrowPlusTask_User(data)
            .then(
                grow => {
                    dispatch(success(grow))
                    dispatch(this.getGrowPlusTask_User(temp));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: growConstants.SAVE_GROW_PLUS_DAY_TASK_USER_REQUEST } }
    function success(grow) { return { type: growConstants.SAVE_GROW_PLUS_DAY_TASK_USER_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.SAVE_GROW_PLUS_DAY_TASK_USER_FAILURE, error } }
}


function changeGrowPlusTask_Input(getGrowPlusTask_UserData, taskId, text) {
    return dispatch => {

        if (getGrowPlusTask_UserData && getGrowPlusTask_UserData["responseList"] && getGrowPlusTask_UserData["responseList"].length > 0) {
            let getIndex = getGrowPlusTask_UserData["responseList"].findIndex(prev => prev.taskId === taskId);
            if (getIndex !== -1) {
                getGrowPlusTask_UserData["responseList"][getIndex]["feedback"] = text;
            } else {
                getGrowPlusTask_UserData["responseList"].push({
                    "taskId": taskId,
                    "feedback": text
                })
            }
        } else {
            getGrowPlusTask_UserData["responseList"] = [];
            getGrowPlusTask_UserData["responseList"].push({
                "taskId": taskId,
                "feedback": text
            })
        }

        let obj = {
            getGrowPlusTask_UserData: getGrowPlusTask_UserData
        }

        dispatch(success(obj))

    };
    function request() { return { type: growConstants.GET_GROW_PLUS_DAY_TASK_USER_REQUEST } }
    function success(grow) { return { type: growConstants.GET_GROW_PLUS_DAY_TASK_USER_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_GROW_PLUS_DAY_TASK_USER_FAILURE, error } }
}

function changeGrowPlusResponse_Input(getGrowPlusTask_UserData, taskId, name, text) {
    return dispatch => {

        if (getGrowPlusTask_UserData && getGrowPlusTask_UserData["responseList"] && getGrowPlusTask_UserData["responseList"].length > 0) {
            let getIndex = getGrowPlusTask_UserData["responseList"].findIndex(prev => prev.taskId === taskId);
            if (getIndex !== -1) {
                let obj = getGrowPlusTask_UserData["responseList"][getIndex];

                if (obj && obj.feedList && obj.feedList.length > 0) {
                    let getIndeY = obj.feedList.findIndex(prev => prev.name === name);
                    if (getIndeY !== -1) {
                        getGrowPlusTask_UserData["responseList"][getIndex]["feedList"][getIndeY]["text"] = text;
                    } else {
                        getGrowPlusTask_UserData["responseList"][getIndex]["feedList"].push({ "name": name, "text": text })
                    }

                } else {
                    getGrowPlusTask_UserData["responseList"][getIndex]["feedList"] = [{ "name": name, "text": text }]
                }
            } else {
                getGrowPlusTask_UserData["responseList"].push({
                    "taskId": taskId,
                    "feedback": text
                })
            }
        } else {
            getGrowPlusTask_UserData["responseList"] = [];
            getGrowPlusTask_UserData["responseList"].push({
                "taskId": taskId,
                "feedback": "",
                "feedList": [{ "name": name, "text": text }]
            })
        }

        let obj = {
            getGrowPlusTask_UserData: getGrowPlusTask_UserData
        }

        dispatch(success(obj))

    };
    function request() { return { type: growConstants.GET_GROW_PLUS_DAY_TASK_USER_REQUEST } }
    function success(grow) { return { type: growConstants.GET_GROW_PLUS_DAY_TASK_USER_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_GROW_PLUS_DAY_TASK_USER_FAILURE, error } }
}
function getGrowPlusDay_User(data) {
    let obj = {
        getGrowPlusDay_UserData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        growService.getGrowPlusDay_User(data)
            .then(
                grow => {
                    dispatch(success(grow))
                    if (grow && grow.getGrowPlusDay_UserData && grow.getGrowPlusDay_UserData.length > 0 &&
                        grow.getGrowPlusDay_UserData[0].id
                    ) {
                        let reqData = {
                            "dayId": grow.getGrowPlusDay_UserData[0].id
                        }
                        dispatch(this.getGrowPlusTask_User(reqData));
                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: growConstants.GET_GROW_PLUS_DAY_USER_REQUEST } }
    function success(grow) { return { type: growConstants.GET_GROW_PLUS_DAY_USER_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_GROW_PLUS_DAY_USER_FAILURE, error } }
}

function getGrowPlusMonth_User(data) {
    let obj = {
        getGrowPlusMonth_UserData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        growService.getGrowPlusMonth_User(data)
            .then(
                grow => {
                    dispatch(success(grow));
                    if (grow && grow.getGrowPlusMonth_UserData && grow.getGrowPlusMonth_UserData.length > 0 &&
                        grow.getGrowPlusMonth_UserData[0].id
                    ) {
                        let reqData = {
                            "monthId": grow.getGrowPlusMonth_UserData[0].id
                        }
                        dispatch(this.getGrowPlusDay_User(reqData));
                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: growConstants.GET_GROW_PLUS_MONTHS_REQUEST } }
    function success(grow) { return { type: growConstants.GET_GROW_PLUS_MONTHS_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_GROW_PLUS_MONTHS_FAILURE, error } }
}


function getGrowPlusBucket_User(data) {
    let obj = {
        getGrowPlusBucket_UserData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        growService.getGrowPlusBucket_User(data)
            .then(
                grow => dispatch(success(grow)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: growConstants.GET_GROW_PLUS_BUCKET_REQUEST } }
    function success(grow) { return { type: growConstants.GET_GROW_PLUS_BUCKET_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_GROW_PLUS_BUCKET_FAILURE, error } }
}

function startGrowDayAction(data1) {
    return dispatch => {
        dispatch(request());
        growService.startGrowDayAction(data1)
            .then(
                grow => dispatch(success(grow)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: growConstants.START_GROW_GETALL_REQUEST } }
    function success(grow) { return { type: growConstants.START_GROW_GETALL_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.START_GROW_GETALL_FAILURE, error } }
}

function startGrowDay(data, alsoCall, data2) {


    return dispatch => {

        dispatch(request());
        growService.startGrowDay(data)
            .then(

                grow => {

                    dispatch(success(grow));
                    if (alsoCall === "getGrowDayDatabyDayId") {
                        dispatch(this.getGrowDayDatabyDayId(data2));
                    }

                },

                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: growConstants.GET_GROWDAY_REQUEST } }
    function success(grow) { return { type: growConstants.GET_GROWDAY_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_GROWDAY_FAILURE, error } }
}

function startGrowWeek(data) {


    return dispatch => {

        dispatch(request());
        growService.startGrowWeek(data)
            .then(

                grow => {

                    dispatch(success(grow));

                },

                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: growConstants.GET_GROWWEEK_REQUEST } }
    function success(grow) { return { type: growConstants.GET_GROWWEEK_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_GROWWEEK_FAILURE, error } }
}

function submitGrowWeek(data) {


    return dispatch => {

        dispatch(request());
        growService.submitGrowWeek(data)
            .then(

                grow => {

                    dispatch(success(grow));

                },

                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: growConstants.GET_GROWDAYWEEK_REQUEST } }
    function success(grow) { return { type: growConstants.GET_GROWDAYWEEK_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_GROWDAYWEEK_FAILURE, error } }
}

function getGrowDayDatabyDayId(data) {

    return dispatch => {

        dispatch(request());
        growService.getGrowDayDatabyDayId(data)
            .then(

                grow => {

                    dispatch(success(grow));

                },

                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: growConstants.GET_GROWDAY_DAY_REQUEST } }
    function success(grow) { return { type: growConstants.GET_GROWDAY_DAY_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_GROWDAY_DAY_FAILURE, error } }
}




function startGrowDayReflection(data) {

    return dispatch => {

        dispatch(request());
        growService.startGrowDayReflection(data)
            .then(

                grow => {

                    dispatch(success(grow));

                },

                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: growConstants.GET_REFLECTION_DAY_REQUEST } }
    function success(grow) { return { type: growConstants.GET_REFLECTION_DAY_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_REFLECTION_DAY_FAILURE, error } }
}


function getGrowDayListbyWeekId(data) {

    // console.log("ACTION_______data%%%%%%%%%%",data);

    return dispatch => {

        dispatch(request());
        growService.getGrowDayListbyWeekId(data)
            .then(

                grow => {

                    dispatch(success(grow));

                },

                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: growConstants.GET_GROW_DAY_REQUEST } }
    function success(grow) { return { type: growConstants.GET_GROW_DAY_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_GROW_DAY_FAILURE, error } }
}

function getGrowWeekListbyMonthId(data) {

    return dispatch => {

        dispatch(request());
        growService.getGrowWeekListbyMonthId(data)
            .then(

                grow => {

                    dispatch(success(grow));

                },

                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: growConstants.GET_WEEK_MONTH_REQUEST } }
    function success(grow) { return { type: growConstants.GET_WEEK_MONTH_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_WEEK_MONTH_FAILURE, error } }
}


function startGrowMonth(data, alsoCall) {

    return dispatch => {

        dispatch(request());
        growService.startGrowMonth(data)
            .then(

                grow => {

                    dispatch(success(grow));
                    if (alsoCall === "getGrowWeekListbyMonthId") {
                        dispatch(this.getGrowWeekListbyMonthId(grow));
                    }

                },

                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: growConstants.START_MONTH_REQUEST } }
    function success(grow) { return { type: growConstants.START_MONTH_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.START_MONTH_FAILURE, error } }
}

function getGrowMonth(data) {

    return dispatch => {

        dispatch(request());
        growService.getGrowMonth(data)
            .then(
                grow => {
                    dispatch(success(grow));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: growConstants.GET_MONTH_REQUEST } }
    function success(grow) { return { type: growConstants.GET_MONTH_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_MONTH_FAILURE, error } }
}




function getAllGrowNote(data) {

    return dispatch => {
        dispatch(request());
        growService.getAllGrowNote(data)
            .then(
                grow => dispatch(success(grow)),

                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: growConstants.DASHBOARD_GROWNOTE_REQUEST } }
    function success(grow) { return { type: growConstants.DASHBOARD_GROWNOTE_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.DASHBOARD_GROWNOTE_FAILURE, error } }
}

function submitGrowDay(data, data1, props) {
    // console.log("dataaaa",data1)
    return dispatch => {
        dispatch(request());
        growService.submitGrowDay(data)

            .then(


                grow => {
                    dispatch(success(grow))
                    // console.log("dashboarddashboard",  dispatch(success(grow)))
                    let newdata = grow && grow.submitGrowDay && grow.submitGrowDay.weekId
                    // props.history.push('/app/Notetoself/' + newdata)

                    let dat32 = { weekId: newdata }
                    dispatch(this.getAllGrowNote(dat32))

                },
                // console.log("dashboarddashboard",grow),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: growConstants.DASHBOARD_SUBMITGROW_REQUEST } }
    function success(grow) { return { type: growConstants.DASHBOARD_SUBMITGROW_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.DASHBOARD_SUBMITGROW_FAILURE, error } }
}

function startGrowDayKnowledge(data, datatemp) {
    return dispatch => {
        dispatch(request());
        growService.startGrowDayKnowledge(data)
            .then(
                grow => {
                    dispatch(success(grow));



                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: growConstants.GET_KNOWLEDGE_REQUEST } }
    function success(grow) { return { type: growConstants.GET_KNOWLEDGE_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_KNOWLEDGE_FAILURE, error } }
}


function getGrowParameterList(data) {
    return dispatch => {
        dispatch(request());
        growService.getGrowParameterList(data)
            .then(
                grow => {
                    dispatch(success(grow));
                },
                error => {

                    // dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: growConstants.GROWPARAMETERLIST_BYTYPE_GETALL_REQUEST } }
    function success(grow) { return { type: growConstants.GROWPARAMETERLIST_GETALL_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GROWPARAMETERLIST_GETALL_FAILURE, error } }
}


function updateEmployeeSubactivityId(data, datatemp) {
    // console.log("log",datatemp);
    return dispatch => {
        dispatch(request());
        growService.updateEmployeeSubactivityId(data)

            .then(
                grow => {
                    dispatch(success(grow));
                    //  console.log("bbbbbbbbbb",datatemp)
                    // dispatch(growActions.getSubactivityActivityId({"activityId": [datatemp]}));
                    dispatch(this.getSubactivityActivityId({ "activityId": [datatemp] }))

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: growConstants.UPDATE_EMPLOYEE_TRACKER_REQUEST } }
    function success(grow) { return { type: growConstants.UPDATE_EMPLOYEE_TRACKER_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.UPDATE_EMPLOYEE_TRACKER_FAILURE, error } }
}

function getSubactivityActivityId(data) {
    return dispatch => {
        dispatch(request());
        growService.getSubactivityActivityId(data)
            .then(
                grow => dispatch(success(grow)),
                // dispatch(getMyRewardsProductsWithLevel()),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: growConstants.SUB_ACT_REQUEST } }
    function success(grow) { return { type: growConstants.SUB_ACT_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.SUB_ACT_FAILURE, error } }
}




function updatecomment(data) {
    return dispatch => {
        dispatch(request());
        growService.updatecomment(data)
            .then(
                grow => dispatch(success(grow)),
                // dispatch(getMyRewardsProductsWithLevel()),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: growConstants.UPDATE_COMMENT_REQUEST } }
    function success(grow) { return { type: growConstants.UPDATE_COMMENT_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.UPDATE_COMMENT_FAILURE, error } }
}
