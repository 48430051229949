import React from 'react';
import ParameterView from '../Parameter/ParameterView';
import Parameter from '../Parameter/Parameter';

export default function NPS(props) {
  let { SurveyTemplateId, question, quesindex, currQuesEditId, showTranslatePage, translShortName,
    translQuestion, handleTranslateQuestion, handleTranslateOption, onTranslateQuestionSave,

    handleQuestionInput, handleOptionInput, onQuesMandatory, onSaveQues, innerEdit, handleQuesCancel, GetParameterLabel, handleNPSLable,
    handleQuesSecInput, surveyModuleNo, handleNudgeModal,




    handleParameterBucket, parameterList, handleParameterQuestion, paraBucketName,
    showBucketDropdown, showDimensionDropdown, handleParameterDimension, getParameterListbyParentData,
    paraDimensionName, isPackage, onCreateNewBucket, surveyDetails, handleDMGModal



  } = props;

console.log("question--122121->",question)

  let NPSLabels = [
    {
      id: "Ratehere",
      name: "Rate here",
    },
    {
      id: "Extremelydissatisfied",
      name: "Extremely dissatisfied"
    },
    {
      id: "Dissatisfied",
      name: "Dissatisfied"
    },
    {
      id: "Neither",
      name: "Neither"
    },
    {
      id: "Satisfied",
      name: "Satisfied"
    },
    {
      id: "Extremelysatisfied",
      name: "Extremely satisfied"
    }
  ]

  let [showBotResEdit, handleBotRespEdit] = React.useState(false);


  return (
    <>
      {currQuesEditId === question.id ?
        <>
          {showTranslatePage ?
            <>
              <div className="flex justify-between items-center">
                <div className="text-sm font-medium text-[#212121]">Question</div>
                <div className="flex px-2">
                  <div onClick={() => onTranslateQuestionSave(true, translShortName)} className="py-1 px-3 rounded-sm border border-1 border-green-500 cursor-pointer text-sm text-green-500 mr-2">Save</div>
                  <div onClick={() => onTranslateQuestionSave(false, translShortName)} className="py-1 px-2 rounded-sm border border-1 border-red-500 cursor-pointer text-sm text-red-500">Cancel</div>
                </div>                    </div>
              <input
                type="text"
                value={question.name}
                onChange={(event) => handleQuestionInput(event.target.value, question.id)}
                placeholder={question.name === "Question" ? "Type your question here..." : "Q." + (quesindex + 1) + " " + question.name}
                className="py-2 px-4 border outline-none w-full mt-2 rounded-md"
              />
              <input
                type="text"
                value={translQuestion["transl"][translShortName].name}
                onChange={(event) => handleTranslateQuestion(event.target.value, question.id, translShortName)}
                placeholder="Translate here..."
                className="py-2 px-4 border-2 border-purple-500 outline-none w-full mt-2"
              />

              <hr />
              <ul className="pb-4">
                <div className="flex ">
                  <div className="text-sm font-medium w-8/12 mr-16 text-[#212121]">Options</div>
                </div>
                {NPSLabels && NPSLabels.length > 0 ?
                  NPSLabels.map((option, optionindex) =>
                    <div className="flex items-center justify-between py-2">
                      <div className=" w-full">
                        <input
                          type="text"
                          value={option.name}
                          onChange={(event) => handleOptionInput(event.target.value, question.id, option.id)}
                          placeholder={option.name}
                          className="border border-gray-500 outline-none  px-4 text-sm py-1.5 w-9/12 mr-4"
                        />
                      </div>
                      <div className=" w-full">
                        <input
                          type="text"
                          value={translQuestion && translQuestion["transl"] && translQuestion["transl"][translShortName] && translQuestion["transl"][translShortName].optionsList && translQuestion["transl"][translShortName].optionsList[option.id] ? translQuestion["transl"][translShortName].optionsList[option.id] : ""}
                          onChange={(event) => handleTranslateOption(event.target.value, question.id, option.id, translShortName)}
                          className="border border-purple-500 outline-none  px-4 text-sm py-1.5 w-9/12 mr-4"
                          placeholder="Translate here..."
                        />
                      </div>
                    </div>
                  )
                  : null}
              </ul>


            </>
            :
            <>

              <div className="flex justify-between items-center">
                <div className="text-sm font-medium text-[#212121]">Question</div>
                <div onClick={() => handleQuesCancel(false)} className="border-1 border-red-500 cursor-pointer text-sm font-semibold text-red-400 mr-2">Cancel</div>
              </div>
              <input
                type="text"
                value={question.name}
                onChange={(event) => handleQuestionInput(event.target.value, question.id)}
                placeholder={question.name === "Question" ? "Type your question here..." : "Q." + (quesindex + 1) + " " + question.name}
                className="py-2 px-4 border outline-none w-full mt-2 rounded-md"
              />
              <ul className="pb-4">
                {/* <p className="text-xs text-gray-500 pb-2">Answer</p> */}
                <div className="mb-1.5 mt-2" >


                  <div className='flex justify-between px-2'>
                    <div className="py-1" >
                      <input
                        id="mandatory-check"
                        value={question.isMandatory}
                        checked={question.isMandatory}
                        type="checkbox"
                        onChange={() => { }}
                        className="cursor-pointer ml-1"
                        onClick={() => onQuesMandatory(question.isMandatory, question.id)}
                      />
                      <label htmlFor="mandatory-check" className="text-sm text-gray-500 pb-8 pl-2">Mandatory</label>

                    </div>

                    {surveyModuleNo === 6 ?
                      <div className="py-1" >
                        <input
                          value={question.seconds}
                          type="number"
                          onChange={(event) => handleQuesSecInput(parseInt(event.target.value), question.id)}
                          className="cursor-pointer ml-1 border border-gray-500 w-24 px-2"
                        />
                        <label htmlFor="mandatory-check" className="text-sm text-gray-500 pb-8 pl-2">Sec</label>
                      </div>
                      : null}



                    <div className="py-1 flex space-x-2" >
                    <div onClick={() => handleDMGModal(question, true)}
                        className='cursor-pointer text-sm py-2 px-4 font-medium bg-[#757575] text-white rounded-md '>
                        Add Demographic</div>
                      <div onClick={() => handleNudgeModal(question, true)}
                        className='cursor-pointer text-sm py-2 px-4 font-medium bg-[#2196f3] text-white rounded-md '>
                        Add Nudge</div>
                      <div onClick={() => handleQuestionInput(" [variable] ", question.id, true)}
                        className='cursor-pointer text-sm py-2 px-4 font-medium bg-[#2196f3] text-white rounded-md '>
                        Add Variable</div>
                      <div onClick={() => handleBotRespEdit(!showBotResEdit)} className={showBotResEdit ?
                        'cursor-pointer text-sm py-2 px-4 font-medium bg-yellow-500 text-white rounded-md ' :
                        'cursor-pointer text-sm py-2 px-4 font-medium bg-purple-500 text-white rounded-md '
                      }>Bot Edit</div>
                    </div>

                  </div>



                  <div className='my-2'></div>

                  {/* --------------------- Parameters -------------------------- */}

                  <Parameter
                    showBucketDropdown={showBucketDropdown}
                    parameterList={parameterList}
                    handleParameterBucket={handleParameterBucket}
                    handleParameterQuestion={handleParameterQuestion}
                    getParameterListbyParentData={getParameterListbyParentData}
                    paraBucketName={paraBucketName}
                    question={question}
                    showDimensionDropdown={showDimensionDropdown}
                    paraDimensionName={paraDimensionName}
                    handleParameterDimension={handleParameterDimension}
                    GetParameterLabel={GetParameterLabel}
                    onCreateNewBucket={onCreateNewBucket}
                    SurveyTemplateId={SurveyTemplateId}
                    surveyModuleNo={surveyModuleNo}
                    isPackage={isPackage}
                    surveyDetails={surveyDetails}
                  />



                  {showBotResEdit ?
                    <div className="py-4">
                      {question && question.optionsList && question.optionsList.length ?
                        question.optionsList.map((option, optionindex) =>
                          <>
                            <div className="flex items-center justify-between py-1">
                              <div className='ml-4 w-2/12'>{option.name + ") Bot: "}</div>
                              <div className=" w-full">
                                <input
                                  type="text"
                                  value={option.bot}
                                  onChange={(event) => handleOptionInput(event.target.value, question.id, option.id, "bot")}
                                  placeholder={option.bot}
                                  className="border border-purple-500 outline-none  px-4 text-xs py-1.5 w-10/12 mr-4"
                                />

                              </div>
                            </div>

                          </>
                        ) : null}
                    </div>
                    : null}




                </div>

              </ul>

              {!showBotResEdit ?
                <ul className="pb-4">
                  <div className="flex ">
                    <div className="text-sm font-semibold w-6/12 text-gray-400">Labels</div>
                    <div className="text-sm font-semibold w-2/12 text-gray-400">Names</div>
                  </div>
                  {NPSLabels && NPSLabels.length > 0 ?
                    NPSLabels.map((option, optionindex) =>
                      <div className="flex items-center justify-between py-2">
                        <div className=" w-full">
                          <input
                            type="text"
                            value={option.name}
                            onChange={(event) => handleOptionInput(event.target.value, question.id, option.id)}
                            placeholder={option.name}
                            disabled={true}
                            className="border border-gray-500 rounded-lg outline-none  px-4 text-sm py-1.5 w-9/12 mr-4"
                          />
                        </div>
                        <div className=" w-full">
                          <input
                            type="text"
                            value={translQuestion && translQuestion["transl"] && translQuestion["transl"]["customNPS"] && translQuestion["transl"]["customNPS"][option.id] ? translQuestion["transl"]["customNPS"][option.id] : ""}
                            onChange={(event) => handleNPSLable(event.target.value, question.id, option.id, "customNPS")}
                            className="border border-blue-500 outline-none rounded-lg  px-4 text-sm py-1.5 w-9/12 mr-4"
                            placeholder="Write here..."
                          />
                        </div>
                      </div>
                    )
                    : null}
                </ul>
                : null}



              <div className={"flex justify-center py-2 " + (showBotResEdit ? "mt-96" : "mt-10")}>
                <span className="cursor-pointer text-sm rounded uppercase   bg-[#2196f3] py-2 px-4 text-white w-28  mx-1 text-center" onClick={() => onSaveQues(question)}> save</span>
              </div>

            </>}

        </>
        :
        <div title={(question && question.isNonEdit ? "" : "Double Click to Edit!")} onDoubleClick={() => innerEdit(question, SurveyTemplateId)} className="space-y-4">
          <>
            <ParameterView question={question} GetParameterLabel={GetParameterLabel} />
            <div className="text-slate-800 font-semibold text-sm inline-flex">{question.parentId ? <p className="pr-2">{" - "}</p> : <p className="pr-2">Q{quesindex + 1}.</p>}  <h1>{question.name}{(question.isMandatory) ? '*' : null}</h1> </div>


            <ul className="py-4 pl-4 flex">
              <span className='p-2 text-sm'>Demographic</span>
              {[...Array(11).keys()].map((item, index) =>
                <div className="cursor-default inline-block w-30 h-30 px-2">
                  <div><img alt="icon" style={{ height: '40px', width: "40px" }} src={"img/nps/" + (index) + ".png"} /></div>
                  <div className="text-center">{item}</div>
                </div>
              )}
            </ul>
          </>
        </div>
      }
    </>
  );
}