import React, { Component } from "react";
import { connect } from "react-redux";
import Header from '../Components/Header';

function ResponsePage(props) {

  let { list, userName, createdAt, ParticipationObj } = props;

  const elements2 = Array.from({ length: 12 });


  console.log("list------->", list)


  function getFormat(apiData, field) {
    let ParticipationObj = {};
    ParticipationObj['total'] = apiData && apiData['total'] ? apiData['total'].toFixed(0) : 0;
    ParticipationObj['mailSent'] = apiData && apiData['mailSent'] ? apiData['mailSent'].toFixed(0) : 0;
    ParticipationObj['clicked'] = apiData && apiData['clicked'] ? apiData['clicked'].toFixed(0) : 0;
    ParticipationObj['completed'] = apiData && apiData['completed'] ? apiData['completed'].toFixed(0) : 0;
    ParticipationObj['participated'] = apiData['completed'] && apiData['total'] ? (apiData['completed'] * 100 / apiData['total']).toFixed(0) : 0;
    return ParticipationObj && ParticipationObj[field] ? ParticipationObj[field] : 0
  }




  function chunkArray(array, chunkSize) {
    let result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      let chunk = array.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  }


  function SortList(list) {
    let temp = [];
    if (list && list.length > 0) {
      temp = list.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }
    return temp;
  }

  let finalList = list && list.length > 0 ? chunkArray(SortList(list), 14) : [];

  return (
    <>
      {finalList && finalList.length > 0 &&
        finalList.map((innerList, index) =>
          <section className='w-full p-6 px-6 flex flex-col justify-between page-break'>
            <Header createdAt={createdAt} />
            <div className="flex flex-col space-y-6 w-full">
              <h1 className="text-2xl font-bold capitalize  bg-[#212121]/90 text-white p-4">{index ? "Respondent summary (Continue)" : "Respondent summary"}</h1>

              <div className="grid grid-cols-3 gap-4">
                <div className="flex items-center p-4  cursor-pointer bg-[#7E57C2]/10 space-x-4">
                  <div className="p-2 bg-[#673AB7] text-[#fff] ">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-mail-check" width={50} height={50} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none" />  <path d="M11 19h-6a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v6" />  <path d="M3 7l9 6l9 -6" />  <path d="M15 19l2 2l4 -4" /></svg>
                  </div>
                  <div className="flex items-center w-full ">
                    <div className="space-y-1">
                      <p className="block text-base text-[#212112] w-full capitalize font-medium ">
                        Invited
                      </p>
                      <h1 className="text-2xl font-medium w-full text-[#212121]">{ParticipationObj['total']}</h1>
                    </div>
                  </div>
                </div>
                <div className="flex items-center p-4  cursor-pointer bg-[#4CAF50]/10 space-x-4">
                  <div className="p-2 bg-[#4CAF50] text-[#fff] ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-rosette-discount-check"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1" /><path d="M9 12l2 2l4 -4" /></svg>
                  </div>
                  <div className="flex items-center w-full ">
                    <div className="space-y-1">
                      <p className="block text-base text-[#212112] w-full capitalize font-medium ">
                        Completed
                      </p>
                      <h1 className="text-2xl font-medium w-full text-[#212121]">{ParticipationObj['completed']}</h1>
                    </div>
                  </div>
                </div>
                <div className="flex items-center p-4  cursor-pointer bg-[#4CAF50]/10 space-x-4">
                  <div className="p-2 bg-[#7CB342] text-[#fff] ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-circle-percentage"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M9 15.075l6 -6" /><path d="M9 9.105v.015" /><path d="M15 15.12v.015" /></svg>
                  </div>
                  <div className="flex items-center w-full ">
                    <div className="space-y-1">
                      <p className="block text-base text-[#212112] w-full capitalize font-medium ">
                        {"Completion Rate"}
                      </p>
                      <h1 className="text-2xl font-medium w-full text-[#212121]">{ParticipationObj['participated'] + '%'}</h1>
                    </div>
                  </div>
                </div>
              </div>


              <table className="w-full bg-[#F1F5F9] divide-y-2 divide-white">
                <thead className="divide-x-2 divide-white bg-[#EAEFF4]">
                  <th className="p-2  px-4 text-sm font-bold text-[#212121] text-left w-[30%]">Department</th>
                  <th className="p-2 text-sm font-bold text-[#212121] w-[15%]">Invited</th>
                  <th className="p-2 text-sm font-bold text-[#212121] w-[15%]">Completed</th>
                  <th className="p-2 text-sm font-bold text-[#212121] w-[30%]">{"Completion Rate (in %)"}</th>
                </thead>
                <tbody className="divide-y-2 divide-white">
                  {innerList ? innerList.length ? innerList.map((ele) => (
                    <tr className="text-sm font-normal divide-x-2 divide-white ">
                      <td className="p-2 px-4  font-normal text-left">{ele.name}</td>
                      <td className="p-2 text-center">{getFormat(ele.data, "total")}</td>
                      <td className="p-2 text-center">{getFormat(ele.data, "completed")}</td>
                      <td className="p-2 w-fit">
                        <div className="flex items-center justify-center space-x-2">
                          <div className="bg-[#E5E7EB] w-full h-5 overflow-hidden">
                            <div className="bg-[#7CB342] w-[50%] h-5" style={{ width: getFormat(ele.data, "participated") + "%" }} />
                          </div>
                          <span className="w-10">{getFormat(ele.data, "participated")}</span>
                        </div>
                      </td>
                    </tr>
                  )) : [] : []}
                </tbody>
              </table>

            </div>
          </section>
        )}

    </>

  );
}
export default ResponsePage;
