import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";


export default function GaugeScore(props) {

  let { item, Benchmark } = props;





  return (
    <div>
      <div className='2xl:!w-[450px] 2xl:!h-[270px] md:!w-[400px] md:!h-[250px] !w-[300px] !h-[200px] '>

        <ReactSpeedometer  
           fluidWidth={true}
          value={item.score}
          minValue={0}
          maxValue={6}
          needleColor="#4db8ff"
          segments={4}
          segmentColors={[
            "#e53935",
            "#ffc107",
            "#a2e37d",
            "#00cc66"
          ]}
          ringWidth={50}
          customSegmentStops={[0, 3, 4.5, 6, 6]}
          // fluidWidth={true}
          needleTransitionDuration={3333}
          needleTransition="easeElastic"
        />
         </div>
        {Benchmark && Benchmark.score || true ?
          <div className="gauge-outer">
            <div className="gauge-inner" style={{ transform: 'rotate(calc(1deg * (-45 + ' + 0 + ' * 3.02)))' }}></div>
          </div>
          : null}

     

    </div>
  );
}
