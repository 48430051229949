import { surveyConstants } from '../_constants';
import { surveyService } from '../_services';

import { questionsActions, campaignActions, customerActions } from '../_actions';

export const surveyActions = {
    getSurveyList,
    getQuestionTypeList,
    getSurveyById,
    emptySurveyById,
    onSurveyQueRandomize,

    getParameterList,
    getParameterListbyParentId,

    updateSurveyWelcome,
    setExpireSurvey,

    createSurvey,
    deleteSurvey,
    updateSurvey,


    responseAnalysisBySurveyId,
    response360AnalysisBySurveyId,
    getCampaignEmployeesByIDSurveyWise,

    resendCampain,
    resend360Campain,



    createSurveyFromLib,
    getSurveyFromLib,


    getEmployeeList,

    getEmployeeBatchRange,
    getCustomerBatchRange,
    saveEmployeeList,
    saveCustomerList,

    save360RelationList,
    savePMSRelationList,

    updateEmployeeStatus,
    updateCustomFilterStatus,

    getLanguageLabel,
    getLanguage,

    getEmployeeColumnName,
    getEmployeeFilterListByCompanyId,
    getEmployeeFilterListBySurveyId,
    createEmployeeFilter,
    createSurveyFilter,
    updateEmployeeFilterStatus,
    updateSurveyWiseFilterStatus,
    deleteEmployeeFilter,
    deleteSurveyWiseFilter,
    updateEmployeeFilterRank,
    updateEmployeeShowReportStatus,
    updateEmployeeFilterLabel,


    assignRole,
    updateEmployee,

    getQuestionLibraryList,
    getAllFilterValues,

    getAssignedRoles,
    getEmployeeSearch,
    getIndexByFilterDMG,

    getResponseAnalyticsPagination,
    getCustomerResponseAnalyticsPagination,
    getResponse360AnalyticsPagination,


    getResponseAnalyticsKeyWord,
    removeRole,
    updateSurveyDesign,
    getAllResponseAnalyticsBySurveyId,

    customerResponsePaginationByCampaignId,

    updateEmployeeEngagementComparisonStatus,
    updateEmployeeEDAStatus,

    getParameterLibraryListbyParameterType,
    copyParaLibToParameter,
    autoUpdateParameters,
    getEmployeeLimitedList,

    createEmployeeRecord,
    getLifecycleStageList,

    allTooltipList,
    getDefaultRiskSurvey,
    updateSurveyClusterSurveyId,
    getEmployeeFields,
    getCustomerFields,

    loadDefaultRecDaysSurvey,
    loadDefaultOnboardingSurvey,

    updateActiveStatusRecCampaign,

    allLifecycleListenList,
    generateCompanyBotLink,
    
    getSurveyOneTemplate,

    getSurveyListByCategory,
    changeLifecycleDashStatus,

    get360RelationList,
    getPMSRelationList,

    copy360Parameters,
    copyPackageParameters,
    getSurveyListV3,
    getSurveyListV4,

    getParameterLabelByName,
    saveEmpDateField,

    getSurveyListByRole,
    autoChangeDashboardType,
    getEmployeesByEmpIdList,
    emptygetEmployeesByEmpIdList,
    getAllEmployeeList,

    empty360Save,
    emptyPMSSave,

    saveCustEmpRelationList,
    getCustEmpRelationList,
    handle360FeedbackDisable,
    setSurveyCampaignType,
    getTranslateElementAll,

    getAssessmentByTopBottom,

    getResponseAssessmentShortCode,
    resendAssessmentCampain,

    copySurvey,
    updateEmpFilterDMGStatus,
    getSurveyDMGLaunchFilter,
    getSurveyLaunchByDMGValue,
    responseIceDownloadBySurveyId,
    getQuestionNameIDOnly,
    getParaLibraryByParentNames
};

function getSurveyList(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getSurveyList(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.SURVEY_GETALL_REQUEST } }
    function success(survey) { return { type: surveyConstants.SURVEY_GETALL_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.SURVEY_GETALL_FAILURE, error } }
}



function getQuestionNameIDOnly(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getQuestionNameIDOnly(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_QUES_NAME_ID_ONLY_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_QUES_NAME_ID_ONLY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_QUES_NAME_ID_ONLY_FAILURE, error } }
}


function responseIceDownloadBySurveyId(data) {
    return dispatch => {
        dispatch(request());
        surveyService.responseIceDownloadBySurveyId(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.REPONSE_ICEDOWNLOAD_BY_SURVEY_REQUEST } }
    function success(survey) { return { type: surveyConstants.REPONSE_ICEDOWNLOAD_BY_SURVEY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.REPONSE_ICEDOWNLOAD_BY_SURVEY_FAILURE, error } }
}



function copySurvey(data,tempdata) {
    return dispatch => {
        dispatch(request());
        surveyService.copySurvey(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getSurveyList(tempdata));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.SURVEY_COPY_SURVEY_REQUEST } }
    function success(survey) { return { type: surveyConstants.SURVEY_COPY_SURVEY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.SURVEY_COPY_SURVEY_FAILURE, error } }
}

function getTranslateElementAll(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getTranslateElementAll(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_TRANSLATE_ELEMENT_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_TRANSLATE_ELEMENT_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_TRANSLATE_ELEMENT_FAILURE, error } }
}

function getQuestionTypeList(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getQuestionTypeList(data)
            .then(
                survey => dispatch(success(survey)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.QUESTIONTYPE_GETALL_REQUEST } }
    function success(survey) { return { type: surveyConstants.QUESTIONTYPE_GETALL_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.QUESTIONTYPE_GETALL_FAILURE, error } }
}

function getSurveyById(data,alsoCallApi) {
    return dispatch => {
        dispatch(request());
        surveyService.getSurveyById(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    if(alsoCallApi==="getQuestionListBySurveyID"){
                        dispatch(questionsActions.getQuestionListBySurveyID(data));
                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_SURVEY_DATA_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_SURVEY_DATA_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_SURVEY_DATA_FAILURE, error } }
}


function emptySurveyById() {
    let obj = {
        getSurveyData:{}
    }
    return dispatch => {
        dispatch(success(obj));

    };
    function success(survey) { return { type: surveyConstants.GET_SURVEY_DATA_SUCCESS, survey } }
}



function onSurveyQueRandomize(data) {
    return dispatch => {
        dispatch(request());
        surveyService.onSurveyQueRandomize(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getSurveyById(data))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_SURVEY_QUE_RANDOMIZE_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_SURVEY_QUE_RANDOMIZE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_SURVEY_QUE_RANDOMIZE_FAILURE, error } }
}


function getParameterList(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getParameterList(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.PARAMETERLIST_GETALL_REQUEST } }
    function success(survey) { return { type: surveyConstants.PARAMETERLIST_GETALL_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.PARAMETERLIST_GETALL_FAILURE, error } }
}


function updateSurveyWelcome(data,props, temp) {

    return dispatch => {
        dispatch(request());
        surveyService.updateSurveyWelcome(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    props.dispatch(questionsActions.getQuestionListBySurveyID(temp));
                    dispatch(this.getSurveyById(temp));

                    // dispatch(this.getSurveyById(temp));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.WELCOME_SURVEY_REQUEST } }
    function success(survey) { return { type: surveyConstants.WELCOME_SURVEY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.WELCOME_SURVEY_FAILURE, error } }
}

function getParameterListbyParentId(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getParameterListbyParentId(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_REQUEST } }
    function success(survey) { return { type: surveyConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_FAILURE, error } }
}


function getParaLibraryByParentNames(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getParaLibraryByParentNames(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.PARA_LIB_BY_PARENTS_NAMES_REQUEST } }
    function success(survey) { return { type: surveyConstants.PARA_LIB_BY_PARENTS_NAMES_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.PARA_LIB_BY_PARENTS_NAMES_FAILURE, error } }
}


function setExpireSurvey(data,surveyId) {
    return dispatch => {
        dispatch(request());
        surveyService.setExpireSurvey(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getSurveyById({surveyId:surveyId}));

                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.SET_EXPIRY_SURVEY_REQUEST } }
    function success(survey) { return { type: surveyConstants.SET_EXPIRY_SURVEY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.SET_EXPIRY_SURVEY_FAILURE, error } }
}


function createSurvey(data,tempdata,check,packageList,props, isPulse,tempdataV3) {
    return dispatch => {
        dispatch(request());
        surveyService.createSurvey(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    if(isPulse){
                        dispatch(this.getSurveyListV3(tempdataV3));
                    }
                    if(check){
                        dispatch(this.getSurveyList(tempdata));
                        if(survey && survey.createSurveyData && survey.createSurveyData.id ){
                            props.history.push(`/app/question/${survey.createSurveyData.id}`);
                        }
                    }
                    else{
                        if(survey && survey.createSurveyData && survey.createSurveyData.id ){
                            let reqData = {
                                "surveyId": survey.createSurveyData.id,
                                "surveyLib": packageList
                              } 
                              dispatch(this.createSurveyFromLib(reqData, tempdata,props));
                        }
                    }
  
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.CREATE_NEW_SURVEY_REQUEST } }
    function success(survey) { return { type: surveyConstants.CREATE_NEW_SURVEY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.CREATE_NEW_SURVEY_FAILURE, error } }
}

function deleteSurvey(data,tempdata,isPulse,tempdatav3) {
    return dispatch => {
        dispatch(request());
        surveyService.deleteSurvey(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getSurveyList(tempdata));
                    if(isPulse){
                        dispatch(this.getSurveyListV3(tempdatav3));
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.DELETE_SURVEY_REQUEST } }
    function success(survey) { return { type: surveyConstants.DELETE_SURVEY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.DELETE_SURVEY_FAILURE, error } }
}


function updateSurvey(data,tempdata) {
    return dispatch => {
        dispatch(request());
        surveyService.updateSurvey(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getSurveyById(tempdata));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_SURVEY_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_SURVEY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_SURVEY_FAILURE, error } }
}


function responseAnalysisBySurveyId(data) {
    return dispatch => {
        dispatch(request());
        surveyService.responseAnalysisBySurveyId(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.RESPONSE_ANALYTICS_SURVEY_REQUEST } }
    function success(survey) { return { type: surveyConstants.RESPONSE_ANALYTICS_SURVEY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.RESPONSE_ANALYTICS_SURVEY_FAILURE, error } }
}


function response360AnalysisBySurveyId(data) {
    return dispatch => {
        dispatch(request());
        surveyService.response360AnalysisBySurveyId(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.RESPONSE_360_ANALYTICS_SURVEY_REQUEST } }
    function success(survey) { return { type: surveyConstants.RESPONSE_360_ANALYTICS_SURVEY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.RESPONSE_360_ANALYTICS_SURVEY_FAILURE, error } }
}



function getCampaignEmployeesByIDSurveyWise(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getCampaignEmployeesByIDSurveyWise(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.CAMPAIGN_EMPLOYEES_SURVEY_REQUEST } }
    function success(survey) { return { type: surveyConstants.CAMPAIGN_EMPLOYEES_SURVEY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.CAMPAIGN_EMPLOYEES_SURVEY_FAILURE, error } }
}


function getSurveyDMGLaunchFilter(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getSurveyDMGLaunchFilter(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.GET_SURVEY_DMG_FILTER_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_SURVEY_DMG_FILTER_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_SURVEY_DMG_FILTER_FAILURE, error } }
}




function resendCampain(data) {
    return dispatch => {
        dispatch(request());
        surveyService.resendCampain(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.RESEND_CAMPAIGN_REQUEST } }
    function success(survey) { return { type: surveyConstants.RESEND_CAMPAIGN_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.RESEND_CAMPAIGN_FAILURE, error } }
}


function resendAssessmentCampain(data) {
    return dispatch => {
        dispatch(request());
        surveyService.resendAssessmentCampain(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.RESEND_ASSESSMENT_CAMPAIGN_REQUEST } }
    function success(survey) { return { type: surveyConstants.RESEND_ASSESSMENT_CAMPAIGN_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.RESEND_ASSESSMENT_CAMPAIGN_FAILURE, error } }
}


function resend360Campain(data) {
    return dispatch => {
        dispatch(request());
        surveyService.resend360Campain(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.RESEND_360_CAMPAIGN_REQUEST } }
    function success(survey) { return { type: surveyConstants.RESEND_360_CAMPAIGN_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.RESEND_360_CAMPAIGN_FAILURE, error } }
}



function createSurveyFromLib(data, tempData,props) {
    return dispatch => {
        dispatch(request());
        surveyService.createSurveyFromLib(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    setTimeout(()=>{
                        props.history.push(`/app/question/${data.surveyId}`);
                    },1500)


                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.CREATE_SURVEY_FROM_LIB_REQUEST } }
    function success(survey) { return { type: surveyConstants.CREATE_SURVEY_FROM_LIB_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.CREATE_SURVEY_FROM_LIB_FAILURE, error } }
}



function getSurveyFromLib(data) {
    let obj = {
        getSurveyFromLibData:[],
        getSurveyFromLibTotal:0
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        surveyService.getSurveyFromLib(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.GET_SURVEY_FROM_LIB_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_SURVEY_FROM_LIB_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_SURVEY_FROM_LIB_FAILURE, error } }
}


function getEmployeeList(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getEmployeeList(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getEmployeeFields());
                    
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.GET_EMPLOYEE_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_EMPLOYEE_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_EMPLOYEE_LIST_FAILURE, error } }
}


function getEmployeesByEmpIdList(data) {
    let surveyObj = {
        getEmployeesByEmpIdListData: []
    }
    return dispatch => {
        dispatch(success(surveyObj));
        dispatch(request());
        surveyService.getEmployeesByEmpIdList(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    // dispatch(this.getEmployeeFields());
                    
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.GET_EMPLOYEE_BY_ID_LIST_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_EMPLOYEE_BY_ID_LIST_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_EMPLOYEE_BY_ID_LIST_LIST_FAILURE, error } }
}
function emptygetEmployeesByEmpIdList() {
    let surveyObj = {
        getEmployeesByEmpIdListData: []
    }
    return dispatch => {
        dispatch(success(surveyObj));
    };
    function success(survey) { return { type: surveyConstants.GET_EMPLOYEE_BY_ID_LIST_LIST_SUCCESS, survey } }
}



function getEmployeeSearch(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getEmployeeSearch(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.GET_EMPLOYEE_SEARCH_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_EMPLOYEE_SEARCH_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_EMPLOYEE_SEARCH_FAILURE, error } }
}


function getEmployeeBatchRange(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getEmployeeBatchRange(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.GET_EMPLOYEE_BATCH_RANGE_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_EMPLOYEE_BATCH_RANGE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_EMPLOYEE_BATCH_RANGE_FAILURE, error } }
}
function getCustomerBatchRange(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getCustomerBatchRange(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.GET_CUSTOMER_BATCH_RANGE_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_CUSTOMER_BATCH_RANGE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_CUSTOMER_BATCH_RANGE_FAILURE, error } }
}


function saveEmployeeList(data,temp) {
    return dispatch => {
        dispatch(request());
        surveyService.saveEmployeeList(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getEmployeeList(temp));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.SAVE_EMPLOYEE_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.SAVE_EMPLOYEE_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.SAVE_EMPLOYEE_LIST_FAILURE, error } }


}

function saveCustomerList(data,temp) {
    return dispatch => {
        dispatch(request());
        surveyService.saveCustomerList(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(customerActions.getCustomerList(temp));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.SAVE_CUSTOMER_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.SAVE_CUSTOMER_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.SAVE_CUSTOMER_LIST_FAILURE, error } }
}


function save360RelationList(data,temp) {
    return dispatch => {
        dispatch(request());
        surveyService.save360RelationList(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.get360RelationList(temp));   
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.SAVE_360_RELATION_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.SAVE_360_RELATION_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.SAVE_360_RELATION_LIST_FAILURE, error } }
}
function savePMSRelationList(data,temp) {
    return dispatch => {
        dispatch(request());
        surveyService.savePMSRelationList(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getPMSRelationList(temp));   
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.SAVE_PMS_RELATION_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.SAVE_PMS_RELATION_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.SAVE_PMS_RELATION_LIST_FAILURE, error } }
}




function updateEmployeeStatus(data) {
    return dispatch => {
        dispatch(request());
        surveyService.updateEmployeeStatus(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_EMPLOYEE_STATUS_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_EMPLOYEE_STATUS_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_EMPLOYEE_STATUS_FAILURE, error } }
}

function updateCustomFilterStatus(data) {
    return dispatch => {
        dispatch(request());
        surveyService.updateCustomFilterStatus(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getSurveyById(data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_EMPLOYEE_STATUS_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_EMPLOYEE_STATUS_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_EMPLOYEE_STATUS_FAILURE, error } }
}

function getLanguageLabel(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getLanguageLabel(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.GET_ALL_LANGUGAGE_LABEL_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_ALL_LANGUGAGE_LABEL_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_ALL_LANGUGAGE_LABEL_FAILURE, error } }
}




function getLanguage(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getLanguage(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.GET_ALL_LANGUGAGE_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_ALL_LANGUGAGE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_ALL_LANGUGAGE_FAILURE, error } }
}



function getEmployeeColumnName(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getEmployeeColumnName(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_EMPLOYEE_COLUMN_NAME_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_EMPLOYEE_COLUMN_NAME_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_EMPLOYEE_COLUMN_NAME_FAILURE, error } }
}


function getEmployeeFilterListByCompanyId(data) {
    let obj = {
        getEmployeeFilterListByCompanyIdData:[]
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        surveyService.getEmployeeFilterListByCompanyId(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_EMPLOYEE_FILTER_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_EMPLOYEE_FILTER_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_EMPLOYEE_FILTER_LIST_FAILURE, error } }
}

function getEmployeeFilterListBySurveyId(data) {
    let obj = {
        getEmployeeFilterListByCompanyIdData:[]
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        surveyService.getEmployeeFilterListBySurveyId(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_EMPLOYEE_FILTER_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_EMPLOYEE_FILTER_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_EMPLOYEE_FILTER_LIST_FAILURE, error } }
}

function createEmployeeFilter(data) {
    return dispatch => {
        dispatch(request());
        surveyService.createEmployeeFilter(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getEmployeeFilterListByCompanyId())
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.CREATE_EMPLOYEE_FILTER_REQUEST } }
    function success(survey) { return { type: surveyConstants.CREATE_EMPLOYEE_FILTER_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.CREATE_EMPLOYEE_FILTER_FAILURE, error } }
}


function createSurveyFilter(data) {
    return dispatch => {
        dispatch(request());
        surveyService.createSurveyFilter(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getEmployeeFilterListBySurveyId({surveyId:data.surveyId}))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.CREATE_EMPLOYEE_FILTER_REQUEST } }
    function success(survey) { return { type: surveyConstants.CREATE_EMPLOYEE_FILTER_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.CREATE_EMPLOYEE_FILTER_FAILURE, error } }
}



function updateEmployeeFilterRank(data) {
    return dispatch => {
        dispatch(request());
        surveyService.updateEmployeeFilterRank(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getEmployeeFilterListByCompanyId())
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_EMPLOYEE_FILTER_RANK_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_EMPLOYEE_FILTER_RANK_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_EMPLOYEE_FILTER_RANK_FAILURE, error } }
}

function updateEmployeeFilterStatus(data) {
    return dispatch => {
        dispatch(request());
        surveyService.updateEmployeeFilterStatus(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getEmployeeFilterListByCompanyId())
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_FAILURE, error } }
}


function updateSurveyWiseFilterStatus(data, tempData) {
    return dispatch => {
        dispatch(request());
        surveyService.updateEmployeeFilterStatus(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getEmployeeFilterListBySurveyId(tempData))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_FAILURE, error } }
}


function updateEmployeeShowReportStatus(data) {
    return dispatch => {
        dispatch(request());
        surveyService.updateEmployeeShowReportStatus(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getEmployeeFilterListByCompanyId())
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_SHOW_REPORT_STATUS_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_SHOW_REPORT_STATUS_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_SHOW_REPORT_STATUS_FAILURE, error } }
}

function deleteEmployeeFilter (data) {
    return dispatch => {
        dispatch(request());
        surveyService.deleteEmployeeFilter(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getEmployeeFilterListByCompanyId())
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.DELETE_EMPLOYEE_FILTER_REQUEST } }
    function success(survey) { return { type: surveyConstants.DELETE_EMPLOYEE_FILTER_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.DELETE_EMPLOYEE_FILTER_FAILURE, error } }
}
function deleteSurveyWiseFilter (data, defaultData) {
    return dispatch => {
        dispatch(request());
        surveyService.deleteEmployeeFilter(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getEmployeeFilterListBySurveyId(defaultData))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.DELETE_EMPLOYEE_FILTER_REQUEST } }
    function success(survey) { return { type: surveyConstants.DELETE_EMPLOYEE_FILTER_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.DELETE_EMPLOYEE_FILTER_FAILURE, error } }
}

function updateEmployeeFilterLabel(data) {
    return dispatch => {
        dispatch(request());
        surveyService.updateEmployeeFilterLabel(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getEmployeeFilterListByCompanyId())
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_EMPLOYEE_FILTER_LABEL_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_EMPLOYEE_FILTER_LABEL_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_EMPLOYEE_FILTER_LABEL_FAILURE, error } }
}

function assignRole(data,tempData) {
    return dispatch => {
        dispatch(request());
        surveyService.assignRole(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getAssignedRoles(tempData));
                    
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.USER_ASSIGN_ROLE_REQUEST } }
    function success(survey) { return { type: surveyConstants.USER_ASSIGN_ROLE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.USER_ASSIGN_ROLE_FAILURE, error } }
}


function updateEmployee(data,temp) {
    return dispatch => {
        dispatch(request());
        surveyService.updateEmployee(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getEmployeeList(temp));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_EMPLOYEE_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_EMPLOYEE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_EMPLOYEE_FAILURE, error } }
}




function getQuestionLibraryList(data,temp) {
    return dispatch => {
        dispatch(request());
        surveyService.getQuestionLibraryList(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                    // dispatch(this.getEmployeeList(temp));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.QUESTION_LIBRARY_REQUEST } }
    function success(survey) { return { type: surveyConstants.QUESTION_LIBRARY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.QUESTION_LIBRARY_FAILURE, error } }
}


function getAllFilterValues(data,temp) {
    return dispatch => {
        dispatch(request());
        surveyService.getAllFilterValues(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                    // dispatch(this.getEmployeeList(temp));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.ALL_FILTER_VALUES_REQUEST } }
    function success(survey) { return { type: surveyConstants.ALL_FILTER_VALUES_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.ALL_FILTER_VALUES_FAILURE, error } }
}


function getAssignedRoles(data,temp) {
    return dispatch => {
        dispatch(request());
        surveyService.getAssignedRoles(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_ASSIGNED_ROLES_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_ASSIGNED_ROLES_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_ASSIGNED_ROLES_FAILURE, error } }
}



function getIndexByFilterDMG(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getIndexByFilterDMG(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_INDEX_BY_FILTER_DMG_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_INDEX_BY_FILTER_DMG_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_INDEX_BY_FILTER_DMG_FAILURE, error } }
}


function getSurveyLaunchByDMGValue(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getSurveyLaunchByDMGValue(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_SURVEY_LAUNCH_BY_DMG_VALUE_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_SURVEY_LAUNCH_BY_DMG_VALUE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_SURVEY_LAUNCH_BY_DMG_VALUE_FAILURE, error } }
}


function getResponseAnalyticsPagination(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getResponseAnalyticsPagination(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_RESPONSE_ANALYTICS_PAGINATION_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_RESPONSE_ANALYTICS_PAGINATION_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_RESPONSE_ANALYTICS_PAGINATION_FAILURE, error } }
}



function getCustomerResponseAnalyticsPagination(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getCustomerResponseAnalyticsPagination(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_CUSTOMER_V1_RESPONSE_ANALYTICS_PAGINATION_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_CUSTOMER_V1_RESPONSE_ANALYTICS_PAGINATION_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_CUSTOMER_V1_RESPONSE_ANALYTICS_PAGINATION_FAILURE, error } }
}



function getResponse360AnalyticsPagination(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getResponse360AnalyticsPagination(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_RESPONSE_360_ANALYTICS_PAGINATION_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_RESPONSE_360_ANALYTICS_PAGINATION_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_RESPONSE_360_ANALYTICS_PAGINATION_FAILURE, error } }
}


function getResponseAnalyticsKeyWord(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getResponseAnalyticsKeyWord(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_RESPONSE_ANALYTICS_KEYWORD_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_RESPONSE_ANALYTICS_KEYWORD_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_RESPONSE_ANALYTICS_KEYWORD_FAILURE, error } }
}

function removeRole(data) {
    return dispatch => {
        dispatch(request());
        surveyService.removeRole(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.REMOVE_USER_ROLE_REQUEST } }
    function success(survey) { return { type: surveyConstants.REMOVE_USER_ROLE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.REMOVE_USER_ROLE_FAILURE, error } }
}


function updateSurveyDesign(data, temp) {
    return dispatch => {
        dispatch(request());
        surveyService.updateSurveyDesign(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getSurveyById(temp));

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_SURVEY_DESIGN_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_SURVEY_DESIGN_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_SURVEY_DESIGN_FAILURE, error } }
}



function getAllResponseAnalyticsBySurveyId(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getAllResponseAnalyticsBySurveyId(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_FAILURE, error } }
}



function customerResponsePaginationByCampaignId(data) {
    let obj = {
        customerResponsePaginationByCampaignIdData:[]
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        surveyService.customerResponsePaginationByCampaignId(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_FAILURE, error } }
}


function updateEmployeeEngagementComparisonStatus(data) {
    return dispatch => {
        dispatch(request());
        surveyService.updateEmployeeEngagementComparisonStatus(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getEmployeeFilterListByCompanyId())
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_ENGAGEMENT_COMPARISON_STATUS_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_ENGAGEMENT_COMPARISON_STATUS_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_ENGAGEMENT_COMPARISON_STATUS_FAILURE, error } }
}

function updateEmpFilterDMGStatus(data) {
    return dispatch => {
        dispatch(request());
        surveyService.updateEmpFilterDMGStatus(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getEmployeeFilterListByCompanyId())
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_EMP_FILTER_STATUS_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_EMP_FILTER_STATUS_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_EMP_FILTER_STATUS_FAILURE, error } }
}



function updateEmployeeEDAStatus(data) {
    return dispatch => {
        dispatch(request());
        surveyService.updateEmployeeEDAStatus(data)
            .then(
                survey => {
                    ////console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getEmployeeFilterListByCompanyId())
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_EDA_STATUS_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_EDA_STATUS_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_EDA_STATUS_FAILURE, error } }
}



function getParameterLibraryListbyParameterType(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getParameterLibraryListbyParameterType(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_PARA_LIB_BY_PARA_TYPE_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_PARA_LIB_BY_PARA_TYPE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_PARA_LIB_BY_PARA_TYPE_FAILURE, error } }
}




function copyParaLibToParameter(data, temp) {
    return dispatch => {
        dispatch(request());
        surveyService.copyParaLibToParameter(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getParameterList(temp));

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.COPY_PARA_LIB_TO_PARAMETER_REQUEST } }
    function success(survey) { return { type: surveyConstants.COPY_PARA_LIB_TO_PARAMETER_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.COPY_PARA_LIB_TO_PARAMETER_FAILURE, error } }
}





function autoUpdateParameters(data,temp2) {
    return dispatch => {
        dispatch(request());
        surveyService.autoUpdateParameters(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getParameterList(temp2));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.AUTO_UPDATE_PARAMETERS_REQUEST } }
    function success(survey) { return { type: surveyConstants.AUTO_UPDATE_PARAMETERS_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.AUTO_UPDATE_PARAMETERS_FAILURE, error } }
}




function getEmployeeLimitedList(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getEmployeeLimitedList(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_EMPLOYEE_LIMITED_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_EMPLOYEE_LIMITED_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_EMPLOYEE_LIMITED_LIST_FAILURE, error } }
}



function createEmployeeRecord(data,temp) {
    return dispatch => {
        dispatch(request());
        surveyService.createEmployeeRecord(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getEmployeeList(temp));                    
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.CREATE_EMPLOYEE_RECORD_REQUEST } }
    function success(survey) { return { type: surveyConstants.CREATE_EMPLOYEE_RECORD_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.CREATE_EMPLOYEE_RECORD_FAILURE, error } }
}



function getLifecycleStageList(data,temp) {
    return dispatch => {
        dispatch(request());
        surveyService.getLifecycleStageList(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    // dispatch(this.getEmployeeList(temp));                    
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_LIFECYCLESTAGE_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_LIFECYCLESTAGE_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_LIFECYCLESTAGE_LIST_FAILURE, error } }
}



function allTooltipList(data,temp) {
    return dispatch => {
        dispatch(request());
        surveyService.allTooltipList(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    // dispatch(this.getEmployeeList(temp));                    
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.ALL_TOOLTIP_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.ALL_TOOLTIP_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.ALL_TOOLTIP_LIST_FAILURE, error } }
}

function getDefaultRiskSurvey(data,temp) {
    return dispatch => {
        dispatch(request());
        surveyService.getDefaultRiskSurvey(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getSurveyList(temp));                    
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_DEFAULT_RISK_SURVEY_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_DEFAULT_RISK_SURVEY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_DEFAULT_RISK_SURVEY_FAILURE, error } }
}



function updateSurveyClusterSurveyId(data,tempdata) {
    return dispatch => {
        dispatch(request());
        surveyService.updateSurveyClusterSurveyId(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getSurveyList(tempdata));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_SURVEY_CLUSTER_SURVEY_ID_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_SURVEY_CLUSTER_SURVEY_ID_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_SURVEY_CLUSTER_SURVEY_ID_FAILURE, error } }
}


function getEmployeeFields(data,tempdata) {
    return dispatch => {
        dispatch(request());
        surveyService.getEmployeeFields(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_EMP_FIELDS_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_EMP_FIELDS_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_EMP_FIELDS_FAILURE, error } }
}


function getCustomerFields(data,tempdata) {
    return dispatch => {
        dispatch(request());
        surveyService.getCustomerFields(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_CMP_FIELDS_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_CMP_FIELDS_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_CMP_FIELDS_FAILURE, error } }
}




function loadDefaultRecDaysSurvey(data,tempdata) {
    return dispatch => {
        dispatch(request());
        surveyService.loadDefaultRecDaysSurvey(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getSurveyList(tempdata));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.LOAD_DEF_REC_DAYS_SURVEYS_REQUEST } }
    function success(survey) { return { type: surveyConstants.LOAD_DEF_REC_DAYS_SURVEYS_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.LOAD_DEF_REC_DAYS_SURVEYS_FAILURE, error } }
}


function loadDefaultOnboardingSurvey(data,tempdata) {
    return dispatch => {
        dispatch(request());
        surveyService.loadDefaultOnboardingSurvey(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getSurveyList(tempdata));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.LOAD_DEF_ONBOARDING_SURVEYS_REQUEST } }
    function success(survey) { return { type: surveyConstants.LOAD_DEF_ONBOARDING_SURVEYS_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.LOAD_DEF_ONBOARDING_SURVEYS_FAILURE, error } }
}




function updateActiveStatusRecCampaign(data,tempdata) {
    return dispatch => {
        dispatch(request());
        surveyService.updateActiveStatusRecCampaign(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(campaignActions.getCampaignListBySurveyID(tempdata));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_ACTIVE_STATUS_REC_CAMP_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_ACTIVE_STATUS_REC_CAMP_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_ACTIVE_STATUS_REC_CAMP_FAILURE, error } }
}



function allLifecycleListenList(data,tempdata) {
    return dispatch => {
        dispatch(request());
        surveyService.allLifecycleListenList(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getSurveyList(tempdata));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.ALL_LIFECYCLE_LISTEN_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.ALL_LIFECYCLE_LISTEN_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.ALL_LIFECYCLE_LISTEN_LIST_FAILURE, error } }
}




function generateCompanyBotLink(data) {
    return dispatch => {
        dispatch(request());
        surveyService.generateCompanyBotLink(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GENERATE_COMPANY_BOT_REQUEST } }
    function success(survey) { return { type: surveyConstants.GENERATE_COMPANY_BOT_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GENERATE_COMPANY_BOT_FAILURE, error } }
}



function getSurveyOneTemplate(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getSurveyOneTemplate(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_SURVEY_ONE_TEMPLATE_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_SURVEY_ONE_TEMPLATE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_SURVEY_ONE_TEMPLATE_FAILURE, error } }
}





function getSurveyListByCategory(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getSurveyListByCategory(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_SURVEY_LIST_BY_CATEGORY_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_SURVEY_LIST_BY_CATEGORY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_SURVEY_LIST_BY_CATEGORY_FAILURE, error } }
}



function changeLifecycleDashStatus(data,dataX2) {
    return dispatch => {
        dispatch(request());
        surveyService.changeLifecycleDashStatus(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getSurveyList(dataX2))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.CHANGE_LIFECYCLE_DASH_STATUS_REQUEST } }
    function success(survey) { return { type: surveyConstants.CHANGE_LIFECYCLE_DASH_STATUS_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.CHANGE_LIFECYCLE_DASH_STATUS_FAILURE, error } }
}



function get360RelationList(data,dataX2) {
    let obj = {
        get360RelationListData:[],
        get360RelationListTotal:0
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        surveyService.get360RelationList(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_360_RELATION_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_360_RELATION_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_360_RELATION_LIST_FAILURE, error } }
}

function getPMSRelationList(data,dataX2) {
    let obj = {
        getPMSRelationListData:[],
        getPMSRelationListTotal:0
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        surveyService.getPMSRelationList(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_PMS_RELATION_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_PMS_RELATION_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_PMS_RELATION_LIST_FAILURE, error } }
}




function copy360Parameters(data) {
    return dispatch => {
        dispatch(request());
        surveyService.copy360Parameters(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.COPY_360_PARAMETERS_REQUEST } }
    function success(survey) { return { type: surveyConstants.COPY_360_PARAMETERS_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.COPY_360_PARAMETERS_FAILURE, error } }
}

function copyPackageParameters(data) {
    return dispatch => {
        dispatch(request());
        surveyService.copyPackageParameters(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.COPY_PACKAGE_PARAMETERS_REQUEST } }
    function success(survey) { return { type: surveyConstants.COPY_PACKAGE_PARAMETERS_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.COPY_PACKAGE_PARAMETERS_FAILURE, error } }
}

function getSurveyListV3(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getSurveyListV3(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_SURVEY_LIST_V3_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_SURVEY_LIST_V3_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_SURVEY_LIST_V3_FAILURE, error } }
}


function getSurveyListV4(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getSurveyListV4(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_SURVEY_LIST_V4_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_SURVEY_LIST_V4_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_SURVEY_LIST_V4_FAILURE, error } }
}


function getParameterLabelByName(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getParameterLabelByName(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_PARAMETER_LABEL_NAME_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_PARAMETER_LABEL_NAME_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_PARAMETER_LABEL_NAME_FAILURE, error } }
}


function saveEmpDateField(data) {
    return dispatch => {
        dispatch(request());
        surveyService.saveEmpDateField(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.SAVE_EMP_DATE_FIELD_REQUEST } }
    function success(survey) { return { type: surveyConstants.SAVE_EMP_DATE_FIELD_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.SAVE_EMP_DATE_FIELD_FAILURE, error } }
}



function getSurveyListByRole(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getSurveyListByRole(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_CROLE_SURVEY_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_CROLE_SURVEY_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_CROLE_SURVEY_LIST_FAILURE, error } }
}





function autoChangeDashboardType(data) {
    return dispatch => {
        dispatch(request());
        surveyService.autoChangeDashboardType(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.AUTO_CHANGE_DASHBOARD_TYPE_REQUEST } }
    function success(survey) { return { type: surveyConstants.AUTO_CHANGE_DASHBOARD_TYPE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.AUTO_CHANGE_DASHBOARD_TYPE_FAILURE, error } }
}



function getAllEmployeeList(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getAllEmployeeList(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_ALL_EMPLOYEE_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_ALL_EMPLOYEE_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_ALL_EMPLOYEE_LIST_FAILURE, error } }
}



function saveCustEmpRelationList(data) {
    return dispatch => {
        dispatch(request());
        surveyService.saveCustEmpRelationList(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.SAVE_CUST_EMP_RELATION_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.SAVE_CUST_EMP_RELATION_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.SAVE_CUST_EMP_RELATION_LIST_FAILURE, error } }
}




function getCustEmpRelationList(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getCustEmpRelationList(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_CUST_EMP_RELATION_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_CUST_EMP_RELATION_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_CUST_EMP_RELATION_LIST_FAILURE, error } }
}



function empty360Save() {
    let obj = {
        save360RelationListData:{}
    }
    return dispatch => {
        dispatch(success(obj));

    };
    function success(survey) { return { type: surveyConstants.SAVE_360_RELATION_LIST_SUCCESS, survey } }
}
function emptyPMSSave() {
    let obj = {
        savePMSRelationListData:{}
    }
    return dispatch => {
        dispatch(success(obj));

    };
    function success(survey) { return { type: surveyConstants.SAVE_PMS_RELATION_LIST_SUCCESS, survey } }
}


function handle360FeedbackDisable(data,dataX2) {
    return dispatch => {
        dispatch(request());
        surveyService.handle360FeedbackDisable(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.get360RelationList(dataX2))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.HANDLE_360_FEEDBACK_DISABLE_REQUEST } }
    function success(survey) { return { type: surveyConstants.HANDLE_360_FEEDBACK_DISABLE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.HANDLE_360_FEEDBACK_DISABLE_FAILURE, error } }
}



function setSurveyCampaignType(data) {
    return dispatch => {
        dispatch(request());
        surveyService.setSurveyCampaignType(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getSurveyById({"surveyId":data.surveyId}))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.SET_SURVEY_CAMPAIGN_TYPE_REQUEST } }
    function success(survey) { return { type: surveyConstants.SET_SURVEY_CAMPAIGN_TYPE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.SET_SURVEY_CAMPAIGN_TYPE_FAILURE, error } }
}




function getAssessmentByTopBottom(data) {
    let obj = {
        getAssessmentByTopBottomData:[]
    }
    return dispatch => {
        dispatch(request());
        dispatch(success(obj));
        surveyService.getAssessmentByTopBottom(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_ASSESSMENT_BY_TOP_BOTTOM_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_ASSESSMENT_BY_TOP_BOTTOM_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_ASSESSMENT_BY_TOP_BOTTOM_FAILURE, error } }
}




function getResponseAssessmentShortCode(data) {
    let obj = {
        getResponseAssessmentShortCodeData:[]
    }
    return dispatch => {
        dispatch(request());
        dispatch(success(obj));
        surveyService.getResponseAssessmentShortCode(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_RESPONSE_ASSESSMENT_SHORT_CODE_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_RESPONSE_ASSESSMENT_SHORT_CODE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_RESPONSE_ASSESSMENT_SHORT_CODE_FAILURE, error } }
}