import React, { Component } from "react";
import moment from "moment";
import Header from "../Components/Header";

export default class Engagement_Index_Report extends Component {
  render() {
    let {
      getEMPFilterLabel,
      FilterValues,
      getSurveyData,
      surveyLogo,
      ReportFilterObj,
      surveyNAME,
      createdAt
    } = this.props;

    console.log("getSurveyData--------->", getSurveyData);

    return (
      <>
        <main>
          <section className=" flex flex-col items-center justify-between space-y-10">
            <div className="space-y-8 text-center flex flex-col items-center">
              <div className="flex justify-center items-center pt-10 w-[90%]" style={{ justifyContent: 'space-between' }}>
                <div />
                <img
                  src={surveyLogo}
                  className="w-fit object-cover"
                  alt="default_survey_logo"
                />
                <div />
              </div>

              <div className="space-y-4 text-center w-full">
                <h1 className="text-5xl text-[#212121] font-normal text-center items-center justify-center">
                  {surveyNAME && surveyNAME ? surveyNAME : ""}
                </h1>
                <p className="text-lg font-semibold text-[#212121]/85 italic">
                  {createdAt}{" "}
                </p>

              </div>
              <div className="flex justify-center pt-6">
                <img src="/img/employee-wellness-report.webp" className="w-full h-full" />
              </div>
              <div class="text-center space-y-2 pt-24">
                <p class="text-lg text-[#212121] font-medium">
                  {"Powered by HappyPlus © 2024"}
                </p>
                <p class="text-sm text-[#212121]/80">
                  {"This report is confidential and should not be distributed without permission"}
                </p>
              </div>
              <div class="flex justify-center pt-12">
                {/* <img
                  src="/img/managerreport/manager-report-cover.png"
                  class="w-full h-full object-cover"
                /> */}
              </div>
            </div>

          </section>
        </main>

      </>

    );
  }
}
