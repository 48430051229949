import React, { Component } from 'react';
import { connect } from 'react-redux';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FilterName:'',
      FilterList2:[],
      selectedFilterList:[],
      FinalFilterList:[],
      StackObject:{},


      selectedFilterItem:'',
      InitDataIndex:'',

      // showViewBy:false,
      searchName:''

    }
  }
  componentDidMount() {
  }

  openDropdown=(check)=>{
    // this.setState({showViewBy:!check})
    this.props.closeFilterQues(!check)
    setTimeout(()=>{ 
      // this.setState({showViewBy:false})
      // this.props.closeFilterQues(false)
     }, 7000);
     
  }


  handleFilterName=(item,index)=>{
    let { handleChangeQuesIdSave, callQuestionWordCloud } =this.props;
    this.setState({selectedFilterItem:item, InitDataIndex:index})
    this.props.closeFilterQues(false)
    callQuestionWordCloud(item.id);
    handleChangeQuesIdSave(item.id);
  }
  handleFilterValue=(val)=>{
    let { handleSummaryFilter } =this.props;
    let { selectedFilterName } =this.state;

    if(selectedFilterName !=='none' && val !=='none'){
      handleSummaryFilter(selectedFilterName, val)
    }
  }
  handleSearch=(e)=>{
    this.setState({searchName:e.target.value});
  }
  render() {
        let {  getTextQuestionData, showViewBy } = this.props;
        let { searchName,selectedFilterItem, InitDataIndex} = this.state;


        let InitDataName = '';
        let InitId = '';
        let InitIndex = 0;

      
        if(selectedFilterItem && selectedFilterItem.name){
          InitDataName=(selectedFilterItem && selectedFilterItem.name)?selectedFilterItem.name:'';
          InitId=(selectedFilterItem && selectedFilterItem.id)?selectedFilterItem.id:'';
          InitIndex = InitDataIndex;

        }
        else{
          InitDataName = getTextQuestionData && getTextQuestionData.length>0?getTextQuestionData[0].name:'';
          InitId = getTextQuestionData && getTextQuestionData.length>0?getTextQuestionData[0].id:'';
          InitIndex = 0;

        }

        let questionData = getTextQuestionData;

        // let questionData = [
        //   {
        //     id: "5fa0fe746ede0f0336066eeb",
        //     name: "Two things my organization should continue doing to help me remain happy & productive."
        //   },
        //   {
        //     id: "5fa0fe746ede0f0336066eec",
        //     name: "Four things my organization should continue doing to help me remain happy & productive."
        //   }
        // ]

        //console.log('selectedFilterItem: ',selectedFilterItem)
        // function SortList(list){
        //   let temp = [];
        //   if(list && list.length>0){
        //     temp= list.sort()
        //   }
        //   return temp;
        // }
    

        let questionName = (InitDataName) ? InitIndex + 1 + ') ' + InitDataName  : 'No Data';

    return (
      <>

<div className='w-full relative' >
  <div className="flex justify-between bg-white border px-4 p-2 rounded-lg capitalize cursor-pointer items-center text-[#3D405B]">
    <h1 onClick={() => this.openDropdown(showViewBy)} className="text-sm truncate  font-medium ">{questionName}</h1>
    <span onClick={() => this.openDropdown(showViewBy)} className="material-symbols-outlined">arrow_drop_down</span></div>

  {showViewBy ?
    <>
      <div className="z-10 absolute top-12 bg-white shadow-lg p-3 font-normal text-xs w-full overflow-y-auto h-60" >
        <input type="text" name="searchName" value={searchName} onChange={this.handleSearch} placeholder="Search" className="w-full border p-1.5 rounded-sm focus:outline-none text-gray-500 mb-2 focus:ring-blue-500 focus:ring-1" />
        {/* <div className="py-2 m-1 flex items-center"><input type="checkbox" checked={finalFilter.some(prev=>(prev.level === item)&&(prev.value === "All"))?true:false} onClick={()=>this.handleSelectValue('All',item)}  /><label className="ml-2">Select all</label> </div> */}
        {questionData && questionData.length > 0 ?
          questionData.map((item, index) =>
            item && item.name && item.name.match(new RegExp(`${searchName}`, 'gi')) ?
              <div className="py-2 m-1 flex items-center"><input type="checkbox" checked={(InitId === item.id) ? true : false} onClick={() => this.handleFilterName(item, index)} onChange={() => { }} /><label onClick={() => this.handleFilterName(item, index)} className="ml-2">{index + 1 + ') ' + item.name}</label> </div>
              : null)
          : null}
      </div>
    </>
    : null}
</div>



      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(Filter);
