import React, { Component } from "react";
import { connect } from "react-redux";
import Header from '../Components/Header';
import ReactEcharts from 'echarts-for-react';

function ResponsePage(props) {

  let { list, userName, createdAt } = props;

  function getRandomColor() {
    let colorList = ["#42A5F5", "#7E57C2", "#FFC107", "#66BB6A", "#5C6BC0"]
    var color = colorList[Math.floor(Math.random() * colorList.length)];
    return color
  }



  function getIndexColor(index) {
    let colorList = ["#42A5F5", "#7E57C2", "#FFC107", "#66BB6A", "#5C6BC0"]
    index = index % 5;
    var color = colorList[index];
    return color
  }


  const getChartPie = (responsesList) => {

    let series_x_data = responsesList && responsesList.length > 0 && responsesList.map((res) => ({
      value: getscorePerc(res, responsesList),
      name: res && res._id ? res._id : "-",
      itemStyle: {
        color: res.color,
      },
      label: {
        show: false,
        formatter: '10',
        itemStyle: {
          color: '#fff',
        },
      },
    }));

    // data: [
    //   {
    //     value: 10,
    //     name: 'self',
    //     itemStyle: {
    //       color: '#42A5F5', // Custom color for Product A
    //     },
    //     label: {
    //       show: false,
    //       formatter: '10', // Displays the name and value
    //       itemStyle: {
    //         color: '#fff', // Custom color for Product A
    //       },
    //     },
    //   },
    //   {
    //     value: 6,
    //     name: 'Cross-functional',
    //     itemStyle: {
    //       color: '#7E57C2', // Custom color for Product A
    //     },
    //     label: {
    //       show: false,
    //       formatter: '6', // Displays the name and value
    //       itemStyle: {
    //         color: '#fff', // Custom color for Product A
    //       },
    //     },
    //   },
    // ]

    const optionPie = {
      title: {
        show: false,
      },
      tooltip: {
        trigger: 'item',
      },
      legend: {
        show: false,
        orient: 'horizontal',
        bottom: 'center',
      },
      series: [
        {
          name: 'Sales',
          type: 'pie',
          radius: '100%',
          data: series_x_data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
      grid: {
        left: '6px',
        right: '10px',
        bottom: '0px',
        top: '4px',
        containLabel: true,
      },

    };
    return optionPie
  }

  console.log("list--->responses-->", list)

  const getscorePerc = (res, all) => {
    let scoreX = 0;
    if (all && all.length > 0) {
      let total = 0;
      let sum = res && res.uniqueEmpCount ? res.uniqueEmpCount : 0;
      all.forEach(ele => {
        total += ele.uniqueEmpCount;
      });
      scoreX = total > 0 ? ((parseFloat(sum) * 100) / total).toFixed(0) : 0;
    }
    return scoreX
  }

  const getTotalCount = (ele, dmgValue) => {
    let total = 0;
    if (ele && ele.demographics && ele.demographics.length > 0) {
      ele.demographics.forEach((inner) => {
        if (inner.level === dmgValue) {
          if (inner && inner.responses && inner.responses.length > 0) {
            inner.responses.forEach((res) => {
              total += (res.uniqueEmpCount);
            })

          }
        }

      })
    }
    return total
  }


  const listaddedcolor = (listx) => {
    return listx.map((ele, index) => ({ ...ele, color: getIndexColor(index) }))
  }




  let sizeChunk = 2;
  function chunkArray(array, chunkSize) {
    let result = [];
    if (array && array.length > 0) {
      for (let i = 0; i < array.length; i += chunkSize) {
        let chunk = array.slice(i, i + chunkSize);
        result.push(chunk);
      }
    }

    return result;
  }


  const sortingList = (list) => {
    let sortedList = []
    if (list && list.length > 0) {
      sortedList = list.sort((a, b) => {
        return ('' + a.xAxis).localeCompare(('' + b.xAxis));
      });
    }
    return sortedList
  }


  let finalList = chunkArray(sortingList(list), sizeChunk);

  const sortingName = (level, response) => {
    let sortedList = []
    if (level === "Grade") {
      if (response && response.length > 0) {
        sortedList = response.sort((a, b) => {
          return ('' + a._id).localeCompare(('' + b._id));
        });
      }
    } else if (level === "Zone") {
      if (response && response.length > 0) {
        sortedList = response.sort((a, b) => {
          return ('' + a._id).localeCompare(('' + b._id));
        });
      }
    } else if (level === "Tenure") {

      let newArr = [
        "Less than 6 months",
        "Between 6 months to 3 yrs",
        "Between 3 to 5 yrs",
        "Between 5 to 10 yrs",
        "More than 10 Yrs"
      ];

      if (response && response.length > 0) {
        sortedList = response.sort((a, b) => {
          return newArr.indexOf('' + a._id) - newArr.indexOf('' + b._id);
        });

      }
    }

    return sortedList
  }

  return (
    <>
      {finalList && finalList.length > 0 &&
        finalList.map((innerList, index) =>
          <section className='w-full p-6 px-6 flex flex-col justify-between page-break'>
            <Header userName={userName} createdAt={createdAt} />
            <div className="flex flex-col space-y-6 w-full">
              <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">Respondent summary (Self Responses + Cross Responses)
              </h1>
              {/* <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">{index ? "Respondent summary (Continue)" : "Respondent summary"}</h1> */}

              <div className={innerList && innerList.length > 1 ? "grid grid-cols-2 divide-x" : "grid grid-cols-1 divide-x"}>
                {/* 1 compare */}

                {innerList && innerList.length > 0 && innerList.map((ele) =>
                  <>
                    <div className="space-y-2 pr-4 pl-4">
                      <div className="text-sm font-bold text-[#212121] bg-[#F1F5F9] p-2 px-2 flex justify-between items-center">
                        <p>{ele && ele.xAxis ? ele.xAxis : ""}</p>
                        <div className="flex items-center justify-between space-x-2">
                          <span className="font-bold">{getTotalCount(ele, "Gender")}</span>
                          <span className="text-sm font-normal">Respondents</span>
                        </div>
                      </div>

                      {ele && ele.demographics && ele.demographics.length > 0 && ele.demographics.map((inner) =>
                        <>

                          {inner && inner.level && inner.level === "Tenure" &&
                            <div className="pr-2">
                              <p className="text-sm font-bold" >{inner.level}</p>
                              <div className="grid grid-cols-5 gap-4 py-2 border-b ">
                                {inner && inner.responses && inner.responses.length > 0 && sortingName(inner.level, inner.responses).map((res) =>
                                  <div className="space-y-2  ">
                                    <div className="flex items-center justify-between px-0.5 text-xs">
                                      <span>{res && res.uniqueEmpCount ? res.uniqueEmpCount : "0 - "}</span>
                                      <span>{" - "}</span>
                                      <span>{getscorePerc(res, inner.responses) + "%"}</span>
                                    </div>
                                    <div className="w-full h-16 bg-[#E8F4FD] flex items-end">
                                      <div className="bg-[#42A5F5] h-[20%] w-full" style={{
                                        height: getscorePerc(res, inner.responses) + "%",
                                        background: getRandomColor()
                                      }}></div>
                                    </div>
                                    <p className="text-center text-[#212121]/70 text-xs leading-snug font-medium">
                                      {res && res._id ? res._id : "-"}</p>
                                  </div>
                                )}
                              </div>
                            </div>}

                          {inner && inner.level && inner.level === "Gender" &&
                            <div className="pr-2">
                              <p className="text-sm font-bold" >{inner.level}</p>
                              <div className="grid grid-cols-1 gap-2 py-1 border-b">
                                {inner && inner.responses && inner.responses.length > 0 && inner.responses.map((res) =>
                                  <>
                                    {res && res._id && res._id === "M" && <>
                                      <div className=" flex items-center space-x-2 ">
                                        <div className="flex-shrink-0 text-[#42A5F5] flex items-center">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#42A5F5" class="icon icon-tabler icons-tabler-filled icon-tabler-man"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M15 8c1.628 0 3.2 .787 4.707 2.293a1 1 0 0 1 -1.414 1.414c-.848 -.848 -1.662 -1.369 -2.444 -1.587l-.849 5.944v4.936a1 1 0 0 1 -2 0v-4h-2v4a1 1 0 0 1 -2 0v-4.929l-.85 -5.951c-.781 .218 -1.595 .739 -2.443 1.587a1 1 0 1 1 -1.414 -1.414c1.506 -1.506 3.08 -2.293 4.707 -2.293z" /><path d="M12 1a3 3 0 1 1 -3 3l.005 -.176a3 3 0 0 1 2.995 -2.824" /></svg>
                                        </div>
                                        <div className="w-full h-5 bg-[#E8F4FD] flex items-start">
                                          <div className="bg-[#42A5F5] w-[20%] h-5" style={{ width: getscorePerc(res, inner.responses) + "%" }}></div>
                                        </div>
                                        <div className="flex flex-shrink-0 items-center justify-between px-2 text-base space-x-4 font-medium w-20">
                                          <span>{res && res.uniqueEmpCount ? res.uniqueEmpCount : "0"}</span>
                                          <span>{getscorePerc(res, inner.responses) + "%"}</span>
                                        </div>
                                      </div>
                                    </>}

                                    {res && res._id && res._id === "F" && <>
                                      <div className=" flex items-center space-x-2 ">
                                        <div className="flex-shrink-0  text-[#7E57C2] flex items-center">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#7E57C2" class="icon icon-tabler icons-tabler-filled icon-tabler-woman"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 8c1.91 0 3.79 .752 5.625 2.219a1 1 0 1 1 -1.25 1.562c-1.019 -.815 -2.016 -1.345 -2.997 -1.6l1.584 5.544a1 1 0 0 1 -.962 1.275h-1v4a1 1 0 0 1 -2 0v-4h-2v4a1 1 0 0 1 -2 0v-4h-1a1 1 0 0 1 -.962 -1.275l1.584 -5.545c-.98 .256 -1.978 .786 -2.997 1.601a1 1 0 1 1 -1.25 -1.562c1.733 -1.386 3.506 -2.133 5.307 -2.212l.335 -.007z" /><path d="M12 1a3 3 0 1 1 -3 3l.005 -.176a3 3 0 0 1 2.995 -2.824" /></svg>
                                        </div>
                                        <div className="w-full h-5 bg-[#E8F4FD] flex items-start">
                                          <div className="bg-[#7E57C2] w-[20%] h-5" style={{ width: getscorePerc(res, inner.responses) + "%" }}></div>
                                        </div>
                                        <div className="flex flex-shrink-0 items-center justify-between px-2 text-base space-x-4 font-medium w-20">
                                          <span>{res && res.uniqueEmpCount ? res.uniqueEmpCount : "0"}</span>
                                          <span>{getscorePerc(res, inner.responses) + "%"}</span>
                                        </div>
                                      </div>
                                    </>}
                                  </>
                                )}
                              </div>
                            </div>}

                          {inner && inner.level && inner.level === "Zone" &&
                            <div className="">
                              <p className="text-sm font-bold" >{inner.level}</p>
                              <div className="flex items-center space-x-6 justify-center py-2 border-b">
                                {inner && inner.responses && inner.responses.length > 0 ?
                                  <ReactEcharts option={getChartPie(listaddedcolor(inner.responses))} style={{ height: '100px', width: '170px' }} className="" />
                                  : null
                                }
                                <table className="w-full">
                                  <tbody>
                                    {inner && inner.responses && inner.responses.length > 0 && sortingName(inner.level, listaddedcolor(inner.responses)).map((res) =>
                                      <tr className="bg-[#F1F5F9]">
                                        <td className="text-[#212121] w-40 flex-shrink-0 font-medium text-left text-xs p-1 border-b-2 border-r-2 border-white">
                                          <div className="flex itmes-center space-x-2">
                                            <div className="w-3 h-3 bg-[#42A5F5] mt-0.5" style={{ background: res.color }} />
                                            <span>{res && res._id ? res._id : "-"}</span>
                                          </div>
                                        </td>
                                        <td className="font-medium w-10 text-left text-xs p-1 border-b-2 border-r-2 border-white">
                                          {res && res.uniqueEmpCount ? res.uniqueEmpCount + "" : ""}
                                        </td>
                                        <td className="font-medium w-10 text-left text-xs p-1 border-b-2 border-r-2 border-white">
                                          {getscorePerc(res, inner.responses) + "%"}
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>}

                          {inner && inner.level && inner.level === "Grade" &&
                            <div className=" ">
                              <p className="text-sm font-bold">Grade</p>
                              <div className="py-2 border-b w-full">
                                <table className="w-full">
                                  <tbody>
                                    {inner && inner.responses && inner.responses.length > 0 && sortingName(inner.level, inner.responses).map((res) =>
                                      <tr className=" ">
                                        <td className="text-[#212121] w-40 flex-shrink-0 font-medium text-right text-[10px] p-2 py-1">{res && res._id ? res._id : "-"}</td>

                                        <td className={innerList && innerList.length > 1 ? "w-[45%] p-2 py-1" : "w-[65%] p-2 py-1"}>
                                          {/* <td className="w-[45%] p-2 py-1">*/}
                                          <div className="bg-[#E8F4FD] w-full h-4 overflow-hidden flex items-center space-x-2">
                                            <div className="w-[45%] h-4" style={{ width: getscorePerc(res, inner.responses) + "%", backgroundColor: "#42A5F5" }} />
                                          </div>
                                        </td>
                                        <td className="font-medium w-8 text-left text-xs p-2 py-1">{res && res.uniqueEmpCount ? res.uniqueEmpCount + "" : ""}</td>
                                        <td className="font-medium w-8 text-left text-xs p-2 py-1">{getscorePerc(res, inner.responses) + "%"}</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>}

                          {inner && inner.level && inner.level === "EmployeeType" &&
                            <div className="  ">
                              <p className="text-sm font-bold" >Employee Type</p>
                              <div className="flex items-center space-x-6 justify-center py-4 ">
                                <ReactEcharts option={getChartPie(listaddedcolor(inner.responses))} style={{ height: '100px', width: '170px' }} className="" />
                                <table className="w-full">
                                  <tbody>
                                    {inner && inner.responses && inner.responses.length > 0 && listaddedcolor(inner.responses).map((res) =>
                                      <tr className="bg-[#F1F5F9]">
                                        <td className="text-[#212121] w-40 flex-shrink-0 font-medium text-left text-sm p-2 border-b-2 border-r-2 border-white"
                                          style={{ width: getscorePerc(res, inner.responses) + "%" }}>
                                          <div className="flex itmes-center space-x-2   ">
                                            <div className="w-4 h-4 bg-[#42A5F5]" style={{ background: res.color }} />
                                            <span>{res && res._id ? res._id : "-"}</span>
                                          </div>
                                        </td>
                                        <td className="font-medium w-10 text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                          {res && res.uniqueEmpCount ? res.uniqueEmpCount + "" : ""}
                                        </td>
                                        <td className="font-medium w-10 text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                          {getscorePerc(res, inner.responses) + "%"}
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>}
                        </>
                      )}
                    </div>
                  </>
                )
                }
              </div>
            </div >
          </section >
        )}

    </>

  );
}
export default ResponsePage;
