import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../Components/Header';


class FeedbackResponseSubDepartment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',

      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: '',
      crrSelectedEmp: {}
    }
  }

  componentDidMount() {

  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { get360Overview } = this.props;
    this.setState({ FilterValues: finalFilter });
    get360Overview(finalFilter, "", "", false);

  }

  handleSelectEmployee = (value) => {
    this.setState({ crrSelectedEmp: value }, () => {
      let { get360Overview } = this.props;

      let emp_id = "";
      if (value && value.emp_id) {
        emp_id = value.emp_id;
      } else {
        emp_id = value.emp_id;
      }
      let { FilterValues } = this.state;
      get360Overview(FilterValues, "", "", false, emp_id)

    })
  }


  render() {


    let { getSubWiseParaScoreData, userName, createdAt, title } = this.props;


    function getRandomColor() {
      let colorList = ["#E57373", "#F06292", "#BA68C8", "#BA68C8", "#9575CD", "#7986CB", "#64B5F6", "#4FC3F7", "#4DD0E1", "#4DB6AC", "#81C784", "#DCE775", "#FFB74D", "#FF8A65", "#90A4AE", "#757575"]
      var color = colorList[Math.floor(Math.random() * colorList.length)];
      return color
    }


    let sizeChunk = 5;
    function chunkArray(array, chunkSize) {
      let result = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        let chunk = array.slice(i, i + chunkSize);
        result.push(chunk);
      }
      return result;
    }

 
    
    return (
      <>
        {getSubWiseParaScoreData && getSubWiseParaScoreData.length > 0 &&
          getSubWiseParaScoreData.map((dataX) =>
            <>
              {dataX && dataX.feedAnswers && dataX.feedAnswers.length > 0 &&
                chunkArray(dataX.feedAnswers, sizeChunk).map((listxy, indexy) =>
                  <>
                    {listxy && listxy.length > 0 &&
                      listxy.map((ele, index) =>
                        <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>

                          <Header createdAt={createdAt} userName={userName} />

                          <div className="flex flex-col space-y-4 w-full ">
                            <h1 className="text-2xl font-medium bg-[#212121]/90 text-white p-4">
                              {(index ? title + " - " + (dataX && dataX.xAxis ? "Department: "+dataX.xAxis : "") + " (Continue)" : title + " - " + (dataX && dataX.xAxis ? "Department: "+dataX.xAxis : ""))}

                            </h1>


                            <div className='text-[#212121] space-y-4 text-sm'>
                              <p className='text-[#212121] italic'> This section provides verbatim comments from all respondents. You will gain the most value if you pay attention to the frequently occurring topics and suggestions. Try to view the information objectively and reconcile it with the information in the previous rating sections.</p>

                              <div>
                                <h1 className='font-medium text-[#212121] text-lg'>{"Q." + (index + 1).toString() + " " + (ele && ele.question)}</h1>

                                {ele && ele.answerFeedbackList && ele.answerFeedbackList.length > 0 ?
                                  ele.answerFeedbackList.map((text, index2) =>
                                    <div class="space-x-4 flex items-end py-2">
                                      <div>
                                        <div className='bg-green-500 flex items-center justify-center h-8 w-8 rounded-full p-1' style={{ background: getRandomColor() }}>
                                          <img src='/img/profile.svg' />
                                        </div>
                                      </div>
                                      <div className=' rounded-tl-xl rounded-r-xl p-4 w-full '
                                        style={{
                                          background: `${getRandomColor()}1A`,
                                        }}
                                      >
                                        <p class="text-sm text-slate-600">{text && text.answerText}</p>
                                        <p class="text-sx text-gray-400">{text && text.feedback}</p>
                                      </div>
                                    </div>
                                  ) : null}

                              </div>



                            </div>


                          </div>
                        </section>
                      )}
                  </>
                )}
            </>
          )}
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(FeedbackResponseSubDepartment);
