import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class DriverRadar extends Component {
  constructor(props) {
    super(props);
    this.state = {


    }
  }

  componentDidMount() {

  }


  render() {
    let { GetParameterLabel, getQuestionData, getColorByRelation, sequence_rel } = this.props;




    let DimensionListV2 = []
    let relationListV2 = []
    if (getQuestionData && getQuestionData.length > 0) {
      getQuestionData.forEach((question, index) => {

        let getIndex = DimensionListV2.findIndex(prev => prev === question.parameterDimensionName);
        if (getIndex === -1) {
          DimensionListV2.push((question.parameterDimensionName))
        }

        if (index === 0) {
          if (question && question.relationList && question.relationList.length > 0) {
            question.relationList.forEach((relData) => {
              let getIndex2 = relationListV2.findIndex(prev => prev === relData);
              if (getIndex2 === -1) {
                relationListV2.push(relData)
              }
            })
          }
        }



      })
    }



    function getListSortedObj(list) {
      if (list && list.length > 0) {
        let List1 = list.filter(prev => prev === "Self")
        let List2 = list.filter(prev => prev !== "Self")
        let RelSortedList = List1.concat(List2)
        return RelSortedList
      } else {
        return []
      }
    }
    let relationListV2Sorted = getListSortedObj(relationListV2)




    function getScoreValue(questionXList, relation) {
      let scoreCalc = 0;
      let oSum = 0;
      let oCount = 0;
      if (questionXList && questionXList.length > 0) {
        questionXList.forEach((item) => {
          let questionX = item;
          let scoreMean = 0;
          let otherSum = Number(questionX[relation + "_sum"]);
          let otherCount = Number(questionX[relation + "_count"])
          scoreMean = Number((otherSum / otherCount));

          oSum += Number((scoreMean))
          oCount += 1
        })
      }
      scoreCalc = Number(oSum / oCount)
      return scoreCalc ? scoreCalc : 0
    }


    function getScoreDimenRel(dimension, relation) {
      let score = 0
      if (getQuestionData && getQuestionData.length > 0) {
        let qListD = getQuestionData.filter(prev => prev.parameterDimensionName === dimension);
        score = getScoreValue(qListD, relation)
      }
      return score
    }

    let sourceListV2 = [
      ['Dimensions']
    ]
    let barListV2 = []
    let NewFinalList = []
    if (relationListV2Sorted && relationListV2Sorted.length > 0) {
      relationListV2Sorted.forEach((rel) => {
        sourceListV2[0].push(rel)
        barListV2.push({ type: 'bar', label: { show: true, position: 'top' } })
      })
    }


    // if(DimensionListV2 && DimensionListV2.length>0){
    //   DimensionListV2.forEach((dimen)=>{
    //     let valueList = [GetParameterLabel(dimen)]
    //     if(relationListV2Sorted && relationListV2Sorted.length>0){
    //       relationListV2Sorted.forEach((rel)=>{

    //         let score = getScoreDimenRel(dimen,rel)
    //         let score2 = score?score.toFixed(2):0
    //         valueList.push(score2)
    //       })
    //     }
    //     sourceListV2.push(valueList)
    //   })
    // }



    if (DimensionListV2 && DimensionListV2.length > 0) {
      DimensionListV2.forEach((dimen) => {
        let valueList = [GetParameterLabel(dimen)]
        if (relationListV2Sorted && relationListV2Sorted.length > 0) {
          relationListV2Sorted.forEach((rel) => {

            let score = getScoreDimenRel(dimen, rel)
            let score2 = score ? score.toFixed(2) : 0
            valueList.push(score2)
          })
        }
        sourceListV2.push(valueList)
      })
    }

    let seriesList = [];
    let colorList = [];
    if (relationListV2Sorted && relationListV2Sorted.length > 0) {
      relationListV2Sorted.forEach((rel) => {

        let temp = {
          name: rel,
          type: 'bar',
          data: [],
          label: {
            show: true,
            position: 'right',
            color: "#000",
            fontStyle: 'bold'
          }
        }

        colorList.push(getColorByRelation(rel))

        if (DimensionListV2 && DimensionListV2.length > 0) {
          DimensionListV2.forEach((dimen) => {
            let score = getScoreDimenRel(dimen, rel)
            let score1 = score ? score.toFixed(2) : 0
            temp["data"].push(score1)
          })
        }

        seriesList.push(temp)


        // let score = getScoreDimenRel(dimen,rel)
        // let score2 = score?score.toFixed(2):0
        // valueList.push(score2)
      })
    }


    let finalList = []
    if (DimensionListV2 && DimensionListV2.length > 0) {
      DimensionListV2.forEach((dimen) => {
        let temp = {
          "dimension": dimen,
          "relationList": []
        }

        if (relationListV2Sorted && relationListV2Sorted.length > 0) {
          relationListV2Sorted.forEach((rel) => {
            let score = getScoreDimenRel(dimen, rel)
            let score1 = score ? score.toFixed(2) : 0
            temp["relationList"].push({
              "relationName": rel,
              "score": score1,
              "color": getColorByRelation(rel)
            })
          })
        }

        finalList.push(temp);
      })
    }



    console.log("------------------------------------------------");
    console.log("------------------------------------------------");
    console.log("finalList---->", finalList)

    function getScorePerc(score, reList) {
      let max = 0;
      if (reList && reList.length > 0) {
        reList.forEach((inner) => {
          let scoreOne = parseFloat(inner.score);
          if (scoreOne > max)
            max = scoreOne
        });
      }
      console.log("max---->", max)
      console.log("score---->", parseFloat(score))

      return parseFloat((parseFloat(score) * 100) / max).toString();

    }


    function chunkList(array) {
      const chunkSize = 4;
      let list = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        list.push(chunk);
      }
      //console.log("list--->", list);
      return list;
    }


    function getRefinedText(text) {
      if (text) {
        return text.replace("_", " ")
      } else {
        return ""
      }
    }

    let { userName, createdAt } = this.props;

    function sort2Order(fun1, fun2) {
      let list = []
      if (fun1 && fun1.length > 0) {
        list = fun1.sort((a, b) => fun2.indexOf(a) - fun2.indexOf(b));
      }
      return list
    }

    function sort2xOrder(fun1, fun2) {
      let list = []
      if (fun1 && fun1.length > 0) {
        list = fun1.sort((a, b) => fun2.indexOf(a.relationName) - fun2.indexOf(b.relationName));
      }
      return list
    }

    
    return (
      <>

        {getQuestionData && getQuestionData.length > 0 && barListV2 && barListV2.length > 0 ?
          <>
            {finalList && finalList.length > 0 ?
              chunkList(finalList).map((eleItems,index11) =>
                <section className=" p-6 px-6 flex flex-col justify-between page-break">
                  <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>360 Feedback</p>
                    <p className='font-medium italic text-base'>{userName} | {createdAt}</p>
                  </div>
                  <div className="space-y-4 flex flex-col  ">


                    <h1 className='text-2xl text-slate-800 font-medium capitalize'>
                      {"Competency Summary (Bar Chart) " + (index11 > 0 ? "(Continue)" : "")}</h1>



                    <div className="text-slate-800 space-y-4 text-sm">
                      <p className="text-[#212121]/80 italic">
                        {" "}
                        The Competency Summary bar chart below shows your ratings for
                        each rating group across all competencies. Bar charts are useful
                        in easily spotting gaps between rater groups' perceptions and
                        observations of your workplace behavior.{" "}
                      </p>  

                      {eleItems && eleItems.length > 0 ?
                        eleItems.map((ele) => 
                          <table className="w-full ">
                            <tbody className="divide-y">
                              <tr className="bg-[#F5F5F5]">
                                <td className="p-4 border-r-2 border-white w-4/12  ">
                                  <div className="flex items-center  ">
                                    <p className=" text-base font-semibold text-[#212121]/80">
                                      {getRefinedText(ele && ele.dimension ? ele.dimension : "")}
                                    </p>
                                  </div>
                                </td>
                                <td className="w-4/12 divide-y-2 divide-white border-r-2 border-white whitespace-nowrap text-right  text-[#212121]/80 font-medium">
                                  {relationListV2Sorted && relationListV2Sorted.length > 0 ?
                                    sort2Order(relationListV2Sorted,sequence_rel).map((rel) =>
                                      <p className="py-2 h-10 p-2 text-right odd:bg-[#F5F5F5] even:bg-[#E9E9E9]">
                                        {rel}
                                      </p>
                                    ) : null}
                                </td>
                                <td className="w-4/12 divide-y-2 divide-white">
                                  {ele && ele.relationList && ele.relationList.length > 0 ?
                                    sort2xOrder(ele.relationList,sequence_rel).map((inner) =>
                                      <div className="flex items-center justify-left space-x-2 h-10 p-2 dd:bg-[#F5F5F5] even:bg-[#E9E9E9]">
                                        <div className="bg-[#FFC107] w-3/6 h-5" style={{ background: inner.color, width: getScorePerc((inner && inner.score ? inner.score : 0), ele.relationList) + "%" }} />
                                        <p className="w-fit font-medium">{inner && inner.score}</p>
                                      </div>
                                    ) : null}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ) : null}


                    </div>
                  </div>
                </section>
              ) : null}


          </>
          :
          <>
            <div className="text-center text-3xl text-gray-400 pt-40">Data Not Available!</div>
          </>}


        {/* <table className="w-full ">
          <tbody className="divide-y">
            <tr className="bg-gray-50">
              <td className="p-4 border-r-2 border-white w-4/12  ">
                <div className="flex items-center  ">
                  <p className="italic text-base font-semibold text-[#212121]/80">
                    Managing Difficult Conversations
                  </p>
                </div>
              </td>
              <td className="w-4/12 divide-y-2 divide-white border-r-2 border-white whitespace-nowrap  text-[#212121]/80 font-medium">
                <p className="py-2 h-10 p-2 text-right">
                  Self
                </p>
                <p className="py-2 h-10 p-2 text-right bg-[#212121]/5">
                  Team Member
                </p>
                <p className="py-2 h-10 p-2 text-right">
                  Peer
                </p>
                <p className="py-2 h-10 p-2 text-right bg-[#212121]/5">
                  Direct Manager
                </p>
                <p className="py-2 h-10 p-2 text-right">
                  Direct Manager
                </p>
              </td>
              <td className="w-4/12 divide-y-2 divide-white">
                <div className="flex items-center justify-left space-x-2 h-10 p-2">
                  <div className="bg-[#FFC107] w-3/6 h-5" />
                  <p className="w-fit font-medium">7.8</p>
                </div>
                <div className="flex items-center justify-left space-x-2 h-10 p-2 bg-[#212121]/5">
                  <div className="bg-green-500 w-8/12 h-5" />
                  <p className="w-fit font-medium">6.5</p>
                </div>
                <div className="flex items-center justify-left space-x-2 h-10 p-2">
                  <div className="bg-[#9C27B0] w-3/6 h-5" /> <p className="w-fit font-medium">5.5</p>
                </div>
                <div className="flex items-center justify-left space-x-2 h-10 p-2 bg-[#212121]/5">
                  <div className="bg-[#1E88E5] w-10/12 h-5" /> <p className="w-fit font-medium">7.8</p>
                </div>
                <div className="flex items-center justify-left space-x-2 h-10 p-2">
                  <div className="bg-[#3F51B5] w-3/6 h-5" /><p className="w-fit font-medium">7.8</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="w-full ">
          <tbody className="divide-y">
            <tr className="bg-gray-50">
              <td className="p-4 border-r-2 border-white w-4/12  ">
                <div className="flex items-center  ">
                  <p className="italic text-base font-semibold text-[#212121]/80">
                    Managing Difficult Conversations
                  </p>
                </div>
              </td>
              <td className="w-4/12 divide-y-2 divide-white border-r-2 border-white whitespace-nowrap  text-[#212121]/80 font-medium">
                <p className="py-2 h-10 p-2 text-right">
                  Self
                </p>
                <p className="py-2 h-10 p-2 text-right bg-[#212121]/5">
                  Team Member
                </p>
                <p className="py-2 h-10 p-2 text-right">
                  Peer
                </p>
                <p className="py-2 h-10 p-2 text-right bg-[#212121]/5">
                  Direct Manager
                </p>
                <p className="py-2 h-10 p-2 text-right">
                  Direct Manager
                </p>
              </td>
              <td className="w-4/12 divide-y-2 divide-white">
                <div className="flex items-center justify-left space-x-2 h-10 p-2">
                  <div className="bg-[#FFC107] w-3/6 h-5" />
                  <p className="w-fit font-medium">7.8</p>
                </div>
                <div className="flex items-center justify-left space-x-2 h-10 p-2 bg-[#212121]/5">
                  <div className="bg-green-500 w-8/12 h-5" />
                  <p className="w-fit font-medium">6.5</p>
                </div>
                <div className="flex items-center justify-left space-x-2 h-10 p-2">
                  <div className="bg-[#9C27B0] w-3/6 h-5" /> <p className="w-fit font-medium">5.5</p>
                </div>
                <div className="flex items-center justify-left space-x-2 h-10 p-2 bg-[#212121]/5">
                  <div className="bg-[#1E88E5] w-10/12 h-5" /> <p className="w-fit font-medium">7.8</p>
                </div>
                <div className="flex items-center justify-left space-x-2 h-10 p-2">
                  <div className="bg-[#3F51B5] w-3/6 h-5" /><p className="w-fit font-medium">7.8</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="w-full ">
          <tbody className="divide-y">
            <tr className="bg-gray-50">
              <td className="p-4 border-r-2 border-white w-4/12  ">
                <div className="flex items-center  ">
                  <p className="italic text-base font-semibold text-[#212121]/80">
                    Managing Difficult Conversations
                  </p>
                </div>
              </td>
              <td className="w-4/12 divide-y-2 divide-white border-r-2 border-white whitespace-nowrap  text-[#212121]/80 font-medium">
                <p className="py-2 h-10 p-2 text-right">
                  Self
                </p>
                <p className="py-2 h-10 p-2 text-right bg-[#212121]/5">
                  Team Member
                </p>
                <p className="py-2 h-10 p-2 text-right">
                  Peer
                </p>
                <p className="py-2 h-10 p-2 text-right bg-[#212121]/5">
                  Direct Manager
                </p>
                <p className="py-2 h-10 p-2 text-right">
                  Direct Manager
                </p>
              </td>
              <td className="w-4/12 divide-y-2 divide-white">
                <div className="flex items-center justify-left space-x-2 h-10 p-2">
                  <div className="bg-[#FFC107] w-3/6 h-5" />
                  <p className="w-fit font-medium">7.8</p>
                </div>
                <div className="flex items-center justify-left space-x-2 h-10 p-2 bg-[#212121]/5">
                  <div className="bg-green-500 w-8/12 h-5" />
                  <p className="w-fit font-medium">6.5</p>
                </div>
                <div className="flex items-center justify-left space-x-2 h-10 p-2">
                  <div className="bg-[#9C27B0] w-3/6 h-5" /> <p className="w-fit font-medium">5.5</p>
                </div>
                <div className="flex items-center justify-left space-x-2 h-10 p-2 bg-[#212121]/5">
                  <div className="bg-[#1E88E5] w-10/12 h-5" /> <p className="w-fit font-medium">7.8</p>
                </div>
                <div className="flex items-center justify-left space-x-2 h-10 p-2">
                  <div className="bg-[#3F51B5] w-3/6 h-5" /><p className="w-fit font-medium">7.8</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="w-full ">
          <tbody className="divide-y">
            <tr className="bg-gray-50">
              <td className="p-4 border-r-2 border-white w-4/12  ">
                <div className="flex items-center  ">
                  <p className="italic text-base font-semibold text-[#212121]/80">
                    Managing Difficult Conversations
                  </p>
                </div>
              </td>
              <td className="w-4/12 divide-y-2 divide-white border-r-2 border-white whitespace-nowrap  text-[#212121]/80 font-medium">
                <p className="py-2 h-10 p-2 text-right">
                  Self
                </p>
                <p className="py-2 h-10 p-2 text-right bg-[#212121]/5">
                  Team Member
                </p>
                <p className="py-2 h-10 p-2 text-right">
                  Peer
                </p>
                <p className="py-2 h-10 p-2 text-right bg-[#212121]/5">
                  Direct Manager
                </p>
                <p className="py-2 h-10 p-2 text-right">
                  Direct Manager
                </p>
              </td>
              <td className="w-4/12 divide-y-2 divide-white">
                <div className="flex items-center justify-left space-x-2 h-10 p-2">
                  <div className="bg-[#FFC107] w-3/6 h-5" />
                  <p className="w-fit font-medium">7.8</p>
                </div>
                <div className="flex items-center justify-left space-x-2 h-10 p-2 bg-[#212121]/5">
                  <div className="bg-green-500 w-8/12 h-5" />
                  <p className="w-fit font-medium">6.5</p>
                </div>
                <div className="flex items-center justify-left space-x-2 h-10 p-2">
                  <div className="bg-[#9C27B0] w-3/6 h-5" /> <p className="w-fit font-medium">5.5</p>
                </div>
                <div className="flex items-center justify-left space-x-2 h-10 p-2 bg-[#212121]/5">
                  <div className="bg-[#1E88E5] w-10/12 h-5" /> <p className="w-fit font-medium">7.8</p>
                </div>
                <div className="flex items-center justify-left space-x-2 h-10 p-2">
                  <div className="bg-[#3F51B5] w-3/6 h-5" /><p className="w-fit font-medium">7.8</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table> */}






        {/* <div className="bg-white  cursor-pointer">
          <div className='space-y-6' >
            {getQuestionData && getQuestionData.length > 0 && barListV2 && barListV2.length > 0 ?
              <div className="  w-full  mb-4 flex  justify-center">
                <ReactEcharts className='w-full'
                  style={{ height: '900px', width: '900px' }}

                  option={{
                    color: colorList,
                    legend: {},
                    grid: {
                      left: '3%',
                      right: '4%',
                      bottom: '3%',
                      containLabel: true
                    },
                    xAxis: {
                      type: 'value',
                      boundaryGap: [0, 0.01],
                      splitLine: {
                        show: false,
                        lineStyle: {
                          color: '#fff'
                        }
                      }, axisLabel: {
                        show: false
                      }

                    },
                    yAxis: {
                      type: 'category',
                      data: DimensionListV2,
                      axisLine: {
                        lineStyle: {
                          color: '#fff'
                        }
                      },
                      axisLabel: {
                        color: "#000",
                        fontStyle: 'bold'
                      },
                    },
                    series: seriesList
                  }}

                />

              </div>
              :
              <>
                <div className="text-center text-3xl text-gray-400 pt-40">Data Not Available!</div>
              </>}


          </div>
        </div> */}



      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DriverRadar);
