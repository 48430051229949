import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class StackChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  openChartList=()=>{
    if(this.state.showChartList){
      this.setState({showChartList:false})
    }
    else{
      this.setState({showChartList:true})
    }
  }

  handleChartType=(item)=>{
    this.setState({chartTypeName:item})
  }

  render() {
    let { item, FilterList } = this.props;


    ////console.log('item: ',item);



    let overallDrivers = item && item.indexDrivers?item.indexDrivers:{}
    let filerDrivers = item && item.indexFilterDrivers?item.indexFilterDrivers:{}

    let indexScore = item && item.indexScore?item.indexScore:0
    let indexFilterScore = item && item.indexFilterScore?item.indexFilterScore:0

    let indexName = item && item.indexName?item.indexName:"Index"

    let sourceData = []

    sourceData.push({
      product: indexName, 
      Overall: indexScore && indexScore.toFixed(2)?indexScore.toFixed(2):0,
      Score: indexFilterScore && indexFilterScore.toFixed(2)?indexFilterScore.toFixed(2):0
    });


    let list = Object.keys(filerDrivers);
    if(list && list.length>0){
      list.forEach((item)=>{
        if(item!=="count" && item!=="name"){

          sourceData.push({
            product: item.replace("Avg",""), 
            Overall: overallDrivers && overallDrivers[item.replace("Avg","Count")] && overallDrivers[item.replace("Avg","Count")].toFixed(2)?overallDrivers[item.replace("Avg","Count")].toFixed(2):0,
            Score: filerDrivers && filerDrivers[item] && filerDrivers[item].toFixed(2)?filerDrivers[item].toFixed(2):0, 
          });

        }

      });
    }


    return (
      <>

          <div className="stack-body">
              {true?
              <>
                          <ReactEcharts
                            option={{
                              legend: {},
                              tooltip: {
                                formatter:function(a,b){
                                  ////////console.log("a,b",a,b)
                                  let point = a && a.data && a.data.product?a.data.product:"Driver";
                                  let overall = a && a.data && a.data.Overall?a.data.Overall:0;
                                  let score = a && a.data && a.data.Score?a.data.Score:0;
                                  let seriesName = a && a.seriesName?a.seriesName:0;
                                  
                                  if(FilterList && FilterList.length){
                                    if(seriesName==="Overall"){
                                      return (
                                        '<p><b>Point:</b> '+point+'</p>'+
                                        '<p><b>'+FilterList[0]["level"]+':</b>  '+'Overall'+'</p>'+
                                        '<p><b>Overall:</b> '+overall+'</p>')
                                    }
                                    else{
                                      return (
                                        '<p><b>Point:</b> '+point+'</p>'+
                                        '<p><b>'+FilterList[0]["level"]+':</b> '+FilterList[0]["value"]+'</p>'+
                                        '<p><b>Score:</b> '+score+'</p>')
                                    }
                                  }
                                  else{
                                    return (
                                      '<p><b>Point:</b> '+point+'</p>'+
                                      '<p><b>Overall:</b> '+overall+'</p>'+
                                      '<p><b>Score:</b> '+score+'</p>')
                                    }


                                  
                                }
                              },
                              dataset: {
                                dimensions: ['product', 'Overall', 'Score'],
                                source: sourceData
                              },
                              xAxis: { type: 'category' },
                              yAxis: {},
                              series: [{ 
                                type: 'bar',
                                barWidth:'20px',
                                label:{
                                  position:'top',
                                  show:false,
                                  fontSize:'10px'
                                }
                               }, 
                               { 
                                 type: 'bar',
                                 barWidth:'20px',
                                 label:{
                                  position:'top',
                                  show:true,
                                  fontSize:'12px'

                                }

                              }]
                            }}
                          />
                </>
              :null}
          </div>
     

      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(StackChart);
