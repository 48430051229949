import React from "react";
import moment from "moment";

export default function AssessmentScore(props) {
  let { userName, userEmpId, createdAt } = props;

  return (
    <>
      <section className='w-full py-10 flex flex-col justify-between'>
        <div className="flex flex-col space-y-8 w-full ">
          <img className="w-48 mx-auto" src="/img/logo/happypluslogo.png" />
          <div>
            <h1 className="text-4xl font-bold uppercase text-[#009688] text-center">{userName}</h1>
            <p className="text-center text-[#212121]/80 font-bold pt-2">{"Emp ID: " + userEmpId}</p>
          </div>
          <p className="text-center text-[#212121]/80 italic font-medium">{createdAt}</p>
          <img className="w-full h-[700px]" src='./img/Leadership-Assessment-Report.webp' />
          <p className="text-center text-[#212121] font-medium">This report is confidential and should not be distributed without permission
          </p>
          <p className="text-sm text-[#212121]/80 text-center">Copyright © 2024 HappyPlus, Inc. All rights reserved.</p>
        </div>
      </section>
    </>
  );
}
