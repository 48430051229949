import React, { Component } from 'react';
import { connect } from 'react-redux';
import DataNotFound from '../../../../Components/DataNotFound';
class QuestionTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OptimizedQuesDynaObj: {}

    }
  }

  componentDidMount() {

  }


  render() {
    let { crrManagersList, getQuestionManagerPIDWiseData, GetParameterLabel, DemographicRestriction, getQuestionOverallData, getQuestionData, optionTypeM, ManagerListQuestionDataObj, Name, QuesDynaObj, ManagerIndexQuestionData, EmpFilterData, ManagerDynaObj, CurrentManagerData } = this.props;
    let { OptimizedQuesDynaObj } = this.state;

    let colorList = ['#dab3ff', '#ffdf91', '#94ebcd', '#ffb4b4']

    let NewQuesDynaObj = []





    //----------------------------------------------------------------------------------------------------------------------------------
    let list1Para = [];
    if (optionTypeM === 1) {
      list1Para = ["Time", "Being", "WorkLife", "Achivement", "Relationship", "Meeningfull"];
    }
    else if (optionTypeM === 2) {
      list1Para = ["Pride", "Presence", "Performance"];
    }
    else if (optionTypeM === 5) {
      list1Para = ["Connect", "ACT", "Respect", "Empower"];
    }

    let QuestionAList = [];
    if (getQuestionData && getQuestionData.length > 0) {
      list1Para.forEach((para) => {
        getQuestionData.forEach((ques) => {
          if (para === ques.parameterDimensionName) {
            QuestionAList.push(ques)
          }
        })
      })
    }

    console.log("-----------------------QuestionAList------------>", QuestionAList)

    function getFavourabilityScore(ques, type) {
      let score = 0;
      if (QuestionAList && QuestionAList.length > 0) {
        if (type === "overall") {
          let total = ques['1'] + ques['2'] + ques['3'] + ques['4'] + ques['5'] + ques['6'];
          score = parseFloat(((ques['5'] + ques['6']) * 100 / total).toFixed(2))
        } else {

          if (getQuestionManagerPIDWiseData && getQuestionManagerPIDWiseData.length > 0) {
            let getIndex1 = getQuestionManagerPIDWiseData.findIndex(prev => prev.mngrId === type);
            if (getIndex1 !== -1) {
              let questionList = getQuestionManagerPIDWiseData[getIndex1]["questionList"];
              if (questionList && questionList.length > 0) {
                let getIndex2 = questionList.findIndex(prev => prev.name === ques.name);
                if (getIndex2 !== -1) {
                  ques = questionList[getIndex2]
                  let total = ques['1'] + ques['2'] + ques['3'] + ques['4'] + ques['5'] + ques['6'];
                  score = parseFloat(((ques['5'] + ques['6']) * 100 / total).toFixed(2))
                }
              }
            }

          }
        }
      }

      return score;
    }

    console.log("crrManagersList", crrManagersList)

    function getManagerName(mngrId) {
      let name = ""
      if (crrManagersList && crrManagersList.length > 0) {
        let getIndex = crrManagersList.findIndex(prev => prev.emp_id.toString() === mngrId.toString());
        if (getIndex !== -1) {
          name = crrManagersList[getIndex]["name"]
        }
      }
      return name ? name : mngrId
    }








    //----------------------------------------------------------------------------------------------------------------------------------







    function getColor(score, overall) {

      if (score === 0) {
        return { Color: '#ffffff', diffTextColor: '#000000' }

      }
      else {

        let diffNo = parseInt(Math.abs(parseFloat(overall) - parseFloat(score)));
        // ////////console.log("diffNo:",diffNo)
        if (parseFloat(overall) <= parseFloat(score)) { //greater (blue)
          if (diffNo >= 0 && diffNo <= 2) {
            return { Color: '#33cc33', diffTextColor: '#ffffff' }

          }
          else if (diffNo >= 2 && diffNo <= 12) {
            return { Color: '#70db70', diffTextColor: '#00000' }

          }
          else {
            return { Color: '#adebad', diffTextColor: '#00000' }
          }

        }
        else { //lesse (red)
          if (diffNo >= 0 && diffNo <= 2) {
            return { Color: '#f5babd', diffTextColor: '#000000' }

          }
          else if (diffNo >= 2 && diffNo <= 12) {
            return { Color: '#ea95a1', diffTextColor: '#000000' }

          }
          else {
            return { Color: '#d03e4d', diffTextColor: '#ffffff' }

          }
        }
      }

    }



    return (
      <>
        {QuestionAList && QuestionAList.length > 0 ?

          <div className="  overflow-x-auto whitespace-nowrap ">
            <table className="w-full border ">
              <thead>
                <tr className="bg-gray-100 border-b text-sm  text-[#3D405B]">
                  {/* <th className="w-1/4 text-left text-white bg-blue-500 border border-separate border-black-800"></th> */}
                  <th className="px-4 py-2 font-bold border-r w-fit"><div className="flex items-center justify-left">{"Dimension"}</div></th>
                  <th className="px-4 py-2 font-bold border-r"><div className="flex items-center justify-left">{"Question"}</div></th>
                  <th className="px-4 py-2 font-bold border-r"><div className="flex items-center justify-center">{"Overall (%)"}</div></th>

                  {/* <th className="w-1/2 text-left text-white bg-blue-500 border border-separate border-black-800">{Name} Items</th> */}
                  {/* <th className="text-left text-white bg-blue-500 border border-separate w-30 border-black-800">Question Score</th> */}

                  {getQuestionManagerPIDWiseData && getQuestionManagerPIDWiseData.length > 0 ?
                    getQuestionManagerPIDWiseData.map((item, queIndex) =>
                      <th className="px-4 py-2 font-bold border-r"><div className="flex items-center justify-center">{getManagerName(item.mngrId)}</div></th>
                    )
                    : null}

                </tr>
              </thead>
              <tbody>
                <>
                  {QuestionAList && QuestionAList.length > 0 ?
                    QuestionAList.map((ques, queIndex) =>
                      <tr className="border border-separate border-black-800 text-[#3D405B]">

                        <td className="px-4 p-2 text-sm border border-b tooltip-response-question1">
                          <div className="whitespace-pre-line ">{GetParameterLabel(ques.parameterDimensionName)}</div>
                        </td>
                        <td className="px-4 p-2 text-sm border border-b tooltip-response-question1">
                          <div className="whitespace-pre-line w-96">{ques.name}</div>
                        </td>



                        <td className="px-4 p-2 text-sm text-center border border-b">
                          {getFavourabilityScore(ques, "overall")}
                        </td>


                        {getQuestionManagerPIDWiseData && getQuestionManagerPIDWiseData.length > 0 ?
                          getQuestionManagerPIDWiseData.map((item, queIndex) =>
                            <td className="px-4 p-2 text-sm text-center border border-b" style={{

                              background: getColor(getFavourabilityScore(ques, item.mngrId), getFavourabilityScore(ques, "overall")).Color,
                              color: getColor(getFavourabilityScore(ques, item.mngrId), getFavourabilityScore(ques, "overall")).diffTextColor

                            }}>{getFavourabilityScore(ques, item.mngrId)}</td>
                          )
                          : null}


                      </tr>
                    )
                    : null}
                </>
              </tbody>
            </table>
          </div>

          : <DataNotFound />}
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionTable);

