import { dashboardConstants } from '../_constants';
import { dashboardService } from '../_services';
import { generalActions, surveyActions } from '../_actions';

export const dashboardActions = {
    getClientProfile,
    getGrowProfile,
    getSurvey,
    generateCompanySurveyCode,

    // assignRole,
    PMSAddAppraisalCycle1,
    newStackList,



    getIndex,
    getIndex2,

    getIndexVsNps,
    getEmpFilter,

    getIndexFilter,
    getIndex2Filter,

    getDemographicIndex,

    // summary score
    getHappinessIndexV2,
    getHappinessIndexV22,

    getEngagementIndexV2,
    getEngagementIndexV22,


    getHopeIndexV2,

    getStressIndexV2,
    getStressIndexV22,

    getManagerIndexV2,
    getLeaderIndexV2,
    getSeniorleadershipIndexV2,


    // driver
    getDemographicIndexHappiness,
    getDemographicIndexEngagement,
    getDemographicIndexStress,
    getDemographicIndexManager,
    getDemographicIndexLeader,
    getDemographicIndexOrg,
    getDemographicIndexMultiAxisOrg,

    getDemographicIndexExperience,



    getHappinessIndexDriver,
    getEngagementIndexDriver,
    getStressIndexDriver,
    getManagerIndexDriver,
    getLeaderIndexDriver,
    getOrgIndexDriver,

    getExperienceIndexDriver,


    // question analysis
    getQuestion,
    getQuestionOverall,
    getQuestionReport,
    getMultiManagerQuestionList,
    getQuestionFilter,


    getHappinessQuestion,
    getHappinessQuestion2,

    getHappinessManagerQuestion,
    getHappinessDMGQuestion,

    getEngagementQuestion,
    getEngagementQuestion2,

    getEngagementManagerQuestion,
    getEngagementDMGQuestion,

    getHopeQuestion,

    getStressQuestion,
    getStressQuestion2,

    getStressManagerQuestion,
    getStressDMGQuestion,

    getManagerQuestion,
    getManagerQuestion2,

    getManagerManagerQuestion,
    getManagerDMGQuestion,

    getLeaderQuestion,
    getLeaderQuestion2,

    getLeaderManagerQuestion,
    getLeaderDMGQuestion,



    // score distribution
    getHappinessIndexV2Demographic,
    getEngagementIndexV2Demographic,
    getStressIndexV2Demographic,


    getManagerList,
    getDemographicIndexForManager,

    getManagerListDashboardManagerIndex,

    getAllBenchMark,
    getOrgIndexV2,

    getRespondantDetails,
    getRespondantDetails2,

    getMetaRespondantDetails,
    getMetaRespondantDetails2,


    getMeta360RespondantDetails,

    getCustomerRespondantDetails,

    getManagerListDomainFilter,



    getQuestionManagerOfHappiness,
    getQuestionManagerOfEngagement,
    getQuestionManagerOfStress,
    getQuestionManagerOfManager,



    getIndexQuantile,

    getDemographicIndexScatter,


    getHappinessCorrelation,
    getHappinessCorrelationOverAll,

    getEngagementCorrelation,
    getEngagementCorrelationOverAll,


    getStressCorrelation,
    getStressCorrelationOverAll,

    getManagerCorrelation,
    getManagerCorrelationOverAll,


    getLeaderCorrelation,
    getLeaderCorrelationOverAll,


    getRWAnalysis,


    getTextQuestion,
    getNameQuestion,
    getFeedbackTextQuestion,

    wordCloud,
    textcluster,

    textDist,
    wordCloudText,
    wordCloudMultiText,
    AdvanceWordCloud,

    cooccurmatrix,

    neighborGraph,

    getEngagementRWA,

    cronbatchAlphaVariance,

    getQuestionFavorability,
    getQuestionFavorability2,
    getQuestionFavorabilityAPI2,
    getQuestionFavorabilityAPI4,


    getHistoryIndex,


    getResponseAnalytics,
    getMetaResponseAnalyticsFilter,
    getMeta360ResponseAnalyticsFilter,
    getComment,




    getDemographicIndexMultiFilter,
    getDemographicIndexMultiAxisFilter,

    AssignManagerActionPlan,

    getManagerActionPlan,
    getEmployeeActionPlan,

    updateEmployeeTask,
    updateEmployeeResource,
    emptyClientProfile,

    getAllManagerActionPlan,
    crossTab,
    crossTabDMG,

    emptycrossTabAnalysis,
    emptyManagerActionPlan,

    crossTabAnalysis,
    getCorrelationQuestionType2,

    getAllParameterList,

    getCorrelationDimenionVsDimension,
    getCorrelationDimensionVsDimensionFilter,
    getDemographicIndexFilterReport,

    getDemographicOrgReport,
    getDemographicOrgReport2,

    TreeMap,
    EmptyTreeMap,

    textDistTreeMap,





    getManagerProfile,
    getMNGRSurveyList,



    getHappinessIndexByFilter,
    getEngagementIndexByFilter,
    getStressIndexByFilter,
    getManagerIndexByFilter,
    getLeaderIndexByFilter,


    getDemographicIndexMultiFilterEngComp,
    getDemographicIndexMultiFilterEngComp2,

    getOverallIndex,
    getOverallIndex2,

    getDemographicBranchCodeIndexMultiFilter,

    getParameterTypeList,

    createParameter,

    getOrgIndexV2Filter,
    getOrgIndexV22Filter,

    getHappinessIndexV2Filter,
    getHappinessIndexV22Filter,


    getEngagementIndexV2Filter,
    getEngagementIndexV22Filter,


    getStressIndexV2Filter,
    getStressIndexV22Filter,

    getHappinessIndexDriverFilter,
    getHappinessIndexDriver2Filter,

    getEngagementIndexDriverFilter,
    getEngagementIndexDriver2Filter,

    getStressIndexDriverFilter,

    getParameterLibraryList,

    clusterAnalysis,
    emptyclusterAnalysis,
    getClusterEmpInfoList,

    topicModelling,

    createTopic,
    updateTopic,
    deleteTopic,
    getTopicList,
    getAllWordKeywordsText,
    getDemographicIndexEngCompOverall,

    getOverallScoreBySurveyId,
    getAllManagerScores,

    createGoal,
    getGoalList,
    updateGoal,
    deleteGoal,
    createPlan,
    updatePlan,
    deletePlan,
    startPlan,
    closePlan,
    getAllManagerGoalPlan,

    getIDPLastDate,
    editIDPLastDate,

    getCompanyNameOnly,

    managerDownloadStatus,
    getMngrReportDownloadStatus,
    getIndexByFilterAllStages,
    getQuestionMap,

    getDemographicIndexMultiManyFilter,
    getDemographicIndexOrgManyFilter,
    getQuestionDistribution,
    getQuesDistributionIndexOrg,
    getQuesDistributionIndexOrg_Clear,

    getQuestionCustomTemplate,
    EmptyWordCloudText,





    getCategoryOverallIndex,
    getLifecycleListenById,


    get360DimensionRelationScore,
    get360DimensionQuestions,
    getAnsweredEmployees,

    getOrgQuestion,
    getOrgManagerQuestion,
    getOrgDMGQuestion,


    getQuestionManagerPIDWise,
    getIndexScoreManagerPIDWise,

    getHappinessIndexDriverManagerPIDWise,
    getEngagementIndexDriverManagerPIDWise,
    getStressIndexDriverManagerPIDWise,
    getManagerIndexDriverManagerPIDWise,
    getOrgCoreDriverSentiment,
    AnswerFeedbackText,

    get360RelationResponse,
    getRespondantAllLifecycleDetails,
    getUserAccess,
    getFeedbackAndQuestionName,
    getTextAndQuestionName,

    getParameterLibraryLabelByParentName,

    getIndexTrendChartByMonth,
    getQuestionTrendChartByMonth,

    getBenchmarkQuantile,
    getCustomerResponseAnalyticsFilter,
    getLifecycleOverallPTM,

    getTextCluster,
    getTextClusterSentimentCount,
    getClusterKeywords,
    getClusterSentences,
    driverVsNpsRWA,
    getAssessmentReportByEmpID,
    getType2AssessmentReportByEmpID,
    emptyAssessmentReportByEmpID,
    getTemp360EmpStrength,

    getAssessmentDashboard,
    getAssessmentByTheme,

    getAssessmentReportByEmpIDOnly,


    // ExitDashboard
    getExitQuestion1,
    getExitQuestionAvg,
    getExitQuestionYesNo,
    getExitQuestionAllCount,
    getExitQuestionAllDemographics,
    getExitQuestionAllDemographics,
    rolkeywordbyemployee,
    getAssessmentDownloadStatus,
    getAssessmentDimensions,
    getAssessmentDimensionsV2,
    getAssessmentManagerList,
    getAssessmentManagerReport,
    getAssessmentOnlyDetails,
    getAssessmentEmpListByMngr,

    getHappinessRWA,
    getEngagementRWA,
    getStressRWA,
    getManagerRWA,
    getLeaderRWA,
    getReportType,
    getReportIndexYearCheck,
    getDemographicWiseRankedScoreIndexTop,
    getDemographicWiseRankedScoreIndexBottom,

    getIndexOverallScore,
    getIndexOverallDistribution,
    PMSGetAppraisalCycleById,

    PMSAddModuleSetting2,
    PMSGetModuleSetting2,

    PMSAddRaterSetting3,
    PMSGetRaterSetting3,


    PMSApplicableFor3,
    PMSApplicableFor4,
    PMSAppraisalCycleList,

    getPMSModuleNames,
    getPMSRaterNames,
    PMSGetProcessList,

    handleReducerInput,
    handleReducerListInput,
    handlerRaterReducerInput,
    CreateNpsSurvey,
    getNpsSurvey,
    PMSNormalization5,
    getEmployeeRatingAndNormalization,
    saveEmployeeNormalization,
    getSurveyTakenDateAdmin,
    PMSDMGEmployeeList,
    topicCloud,
    handleReducerDateInput,
    PMSAddProcessList,
    AddTextKeywords,
    GetTextKeywords,
    GetTextDistHighlights,
    getAllAnsweredAssessmentEmpData,
    getNudgeLibList,
    mapNudgeToQuestion,
    getNudgeTextQuesList,
    getNudgeTextBySurveyId,
    getQuestionWiseExcel,
    getQuestionWiseExcel_Clear,
    getReportQuesWiseWorldCloud,
    getAssessmentTop5DescByNames,

    getEngagementIndexByFilterV2,
    getManagerIndexByFilterV2,
    getDemographicIndexEngagementV2,
    getDemographicIndexManagerV2,
    getMngrTeamSize,
    getAllBenchMarkStatic,
    getAllFeedbackWords,

    getIceIndex,
    getIceDistribution,
    getIceCrossDist,
    getIceMixHeatMap,
    getIceMixHeatMapV2,
    getIceNPSRowWise,
    getIceDistributionV2,

    getIceDmgWiseParaScore,
    getIceDmgQuestionWiseDist,
    getIceDmgWiseAnsCount,
    getIceFeedbackList,
    getIceIndexDMGWise,
    getIceDMGDimensionScore,
    getSubWiseParaScore,
    getSubWiseParaFeedDist,
    getSubWiseParaFeedDistV2,
    getSubWiseFeedAnsCount,
    getSubWiseFeedParaScore,

    getLTHeadIceScore,
    getLTHeadIceScoreV2,

    getLTHeadIceParaScore,
    getLTHeadIceParaScoreV2,

    getLTHeadIceSubWiseIceScore,
    getLTHeadIceSubWiseIceScoreV2,

    getLTHeadEmpList,

    getLeadershipReportScore,
    getLeadershipParaCategory,
    getLeadershipTextDetails
};

function getLTHeadIceSubWiseIceScore(data) {
    let obj = {
        getLTHeadIceSubWiseIceScoreData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getLTHeadIceSubWiseIceScore(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LT_HEAD_ICE_SUBWISE_ICE_SCORE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LT_HEAD_ICE_SUBWISE_ICE_SCORE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LT_HEAD_ICE_SUBWISE_ICE_SCORE_FAILURE, error } }
}
function getLeadershipReportScore(data) {
    console.log("x-data-->",data)

    let obj = {
        getLeadershipReportScoreData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getLeadershipReportScore(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADERSHIP_REPORT_SCORE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADERSHIP_REPORT_SCORE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADERSHIP_REPORT_SCORE_FAILURE, error } }
}
function getLeadershipParaCategory(data) {
    let obj = {
        getLeadershipParaCategoryData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getLeadershipParaCategory(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADERSHIP_PARA_CATEGORY_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADERSHIP_PARA_CATEGORY_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADERSHIP_PARA_CATEGORY_FAILURE, error } }
}
function getLeadershipTextDetails(data) {
    let obj = {
        getLeadershipTextDetailsData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getLeadershipTextDetails(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADERSHIP_TEXT_DETAILS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADERSHIP_TEXT_DETAILS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADERSHIP_TEXT_DETAILS_FAILURE, error } }
}




function getLTHeadIceSubWiseIceScoreV2(data) {
    let obj = {
        getLTHeadIceSubWiseIceScoreV2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getLTHeadIceSubWiseIceScore(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LT_HEAD_ICE_SUBWISE_ICE_SCORE_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LT_HEAD_ICE_SUBWISE_ICE_SCORE_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LT_HEAD_ICE_SUBWISE_ICE_SCORE_V2_FAILURE, error } }
}


function getLTHeadEmpList(data) {
    let obj = {
        getLTHeadEmpListData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getLTHeadEmpList(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LT_HEAD_EMP_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LT_HEAD_EMP_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LT_HEAD_EMP_LIST_FAILURE, error } }
}

function getLTHeadIceParaScore(data) {
    let obj = {
        getLTHeadIceParaScoreData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getLTHeadIceParaScore(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LT_HEAD_ICE_PARA_SCORE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LT_HEAD_ICE_PARA_SCORE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LT_HEAD_ICE_PARA_SCORE_FAILURE, error } }
}

function getLTHeadIceParaScoreV2(data) {
    let obj = {
        getLTHeadIceParaScoreV2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getLTHeadIceParaScore(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LT_HEAD_ICE_PARA_SCORE_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LT_HEAD_ICE_PARA_SCORE_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LT_HEAD_ICE_PARA_SCORE_V2_FAILURE, error } }
}


function getLTHeadIceScore(data) {
    let obj = {
        getLTHeadIceScoreData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getLTHeadIceScore(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LT_HEAD_ICE_SCORE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LT_HEAD_ICE_SCORE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LT_HEAD_ICE_SCORE_FAILURE, error } }
}

function getLTHeadIceScoreV2(data) {
    let obj = {
        getLTHeadIceScoreV2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getLTHeadIceScore(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LT_HEAD_ICE_SCORE_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LT_HEAD_ICE_SCORE_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LT_HEAD_ICE_SCORE_V2_FAILURE, error } }
}



function getSubWiseFeedParaScore(data) {
    let obj = {
        getSubWiseFeedParaScoreData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getSubWiseFeedParaScore(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_SUB_WISE_FEED_PARA_SCORE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_SUB_WISE_FEED_PARA_SCORE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_SUB_WISE_FEED_PARA_SCORE_FAILURE, error } }
}

function getSubWiseFeedAnsCount(data) {
    let obj = {
        getSubWiseFeedAnsCountData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getSubWiseFeedAnsCount(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_SUB_WISE_FEED_ANS_COUNT_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_SUB_WISE_FEED_ANS_COUNT_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_SUB_WISE_FEED_ANS_COUNT_FAILURE, error } }
}

function getSubWiseParaFeedDist(data) {
    let obj = {
        getSubWiseParaFeedDistData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getSubWiseParaFeedDist(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_SUB_WISE_PARA_FEED_DIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_SUB_WISE_PARA_FEED_DIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_SUB_WISE_PARA_FEED_DIST_FAILURE, error } }
}

function getSubWiseParaFeedDistV2(data) {
    let obj = {
        getSubWiseParaFeedDistV2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getSubWiseParaFeedDist(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_SUB_WISE_PARA_FEED_DIST_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_SUB_WISE_PARA_FEED_DIST_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_SUB_WISE_PARA_FEED_DIST_V2_FAILURE, error } }
}

function getSubWiseParaScore(data) {
    let obj = {
        getSubWiseParaScoreData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getSubWiseParaScore(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_SUB_WISE_PARA_SCORE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_SUB_WISE_PARA_SCORE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_SUB_WISE_PARA_SCORE_FAILURE, error } }
}

function getIceDMGDimensionScore(data) {
    let obj = {
        getIceDMGDimensionScoreData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIceDMGDimensionScore(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ICE_DIMENSION_DMG_WISE_SCORE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ICE_DIMENSION_DMG_WISE_SCORE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ICE_DIMENSION_DMG_WISE_SCORE_FAILURE, error } }
}

function getIceIndexDMGWise(data) {
    let obj = {
        getIceIndexDMGWiseData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIceIndexDMGWise(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ICE_INDEX_DMG_WISE_SCORE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ICE_INDEX_DMG_WISE_SCORE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ICE_INDEX_DMG_WISE_SCORE_FAILURE, error } }
}

function getIceDmgWiseAnsCount(data) {
    let obj = {
        getIceDmgWiseAnsCountData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIceDmgWiseAnsCount(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ICE_DMG_WISE_ANS_COUNT_SCORE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ICE_DMG_WISE_ANS_COUNT_SCORE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ICE_DMG_WISE_ANS_COUNT_SCORE_FAILURE, error } }
}

function getIceDmgQuestionWiseDist(data) {
    let obj = {
        getIceDmgQuestionWiseDistData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIceDmgQuestionWiseDist(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ICE_DMG_QUESTION_DIST_SCORE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ICE_DMG_QUESTION_DIST_SCORE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ICE_DMG_QUESTION_DIST_SCORE_FAILURE, error } }
}

function getIceDmgWiseParaScore(data) {
    let obj = {
        getIceDmgWiseParaScoreData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIceDmgWiseParaScore(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ICE_DMG_PARA_SCORE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ICE_DMG_PARA_SCORE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ICE_DMG_PARA_SCORE_FAILURE, error } }
}

function getIceIndex(data) {
    let obj = {
        getIceIndexData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIceIndex(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ICE_INDEX_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ICE_INDEX_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ICE_INDEX_FAILURE, error } }
}


function getIceMixHeatMap(data) {
    let obj = {
        getIceMixHeatMapData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIceMixHeatMap(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ICE_MIX_HEAT_MAP_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ICE_MIX_HEAT_MAP_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ICE_MIX_HEAT_MAP_FAILURE, error } }
}

function getIceMixHeatMapV2(data) {
    let obj = {
        getIceMixHeatMapV2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIceMixHeatMap(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ICE_MIX_HEAT_V2_MAP_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ICE_MIX_HEAT_V2_MAP_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ICE_MIX_HEAT_V2_MAP_FAILURE, error } }
}


function getIceNPSRowWise(data) {
    let obj = {
        getIceNPSRowWiseData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIceNPSRowWise(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ICE_NPS_ROW_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ICE_NPS_ROW_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ICE_NPS_ROW_FAILURE, error } }
}



function getIceCrossDist(data) {
    let obj = {
        getIceCrossDistData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIceCrossDist(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ICE_CROSS_DIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ICE_CROSS_DIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ICE_CROSS_DIST_FAILURE, error } }
}

function getIceDistribution(data) {
    let obj = {
        getIceDistributionData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIceDistribution(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ICE_DIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ICE_DIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ICE_DIST_FAILURE, error } }
}



function getIceDistributionV2(data) {
    let obj = {
        getIceDistributionV2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIceDistribution(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ICE_DIST_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ICE_DIST_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ICE_DIST_V2_FAILURE, error } }
}


function getIceFeedbackList(data) {
    let obj = {
        getIceFeedbackListData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIceFeedbackList(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ICE_FEED_BACK_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ICE_FEED_BACK_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ICE_FEED_BACK_FAILURE, error } }
}




function getAllBenchMarkStatic(data) {
    let obj = {
        getAllBenchMarkStaticData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getAllBenchMarkStatic(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STATIC_BENCHMARK_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STATIC_BENCHMARK_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STATIC_BENCHMARK_FAILURE, error } }
}




function getMngrTeamSize(data) {
    let obj = {
        getMngrTeamSizeData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getMngrTeamSize(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_TEAM_SIZE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_TEAM_SIZE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_TEAM_SIZE_FAILURE, error } }
}



function getEngagementIndexByFilterV2(data) {
    let obj = {
        getEngagementIndexByFilterV2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIndexFilter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_BY_FILTER_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_BY_FILTER_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_BY_FILTER_V2_FAILURE, error } }
}

function getManagerIndexByFilterV2(data) {
    let obj = {
        getManagerIndexByFilterV2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIndexFilter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_INDEX_BY_FILTER_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_INDEX_BY_FILTER_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_INDEX_BY_FILTER_V2_FAILURE, error } }
}


function getDemographicIndexEngagementV2(data) {
    let obj = {
        getDemographicIndexEngagementV2Data: []
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        dashboardService.getDemographicIndexEngagement(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_V2_FAILURE, error } }
}



function getDemographicIndexManagerV2(data) {
    let obj = {
        getDemographicIndexManagerV2Data: []
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        dashboardService.getDemographicIndexManager(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANAGER_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANAGER_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANAGER_V2_FAILURE, error } }
}







function getAssessmentTop5DescByNames(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getAssessmentTop5DescByNames(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ASSESSMENT_TOP5_DESC_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ASSESSMENT_TOP5_DESC_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ASSESSMENT_TOP5_DESC_FAILURE, error } }
}


function getReportQuesWiseWorldCloud(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getReportQuesWiseWorldCloud(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_REPORT_QUES_WISE_WORLD_CLOUD_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_REPORT_QUES_WISE_WORLD_CLOUD_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_REPORT_QUES_WISE_WORLD_CLOUD_FAILURE, error } }
}



function getQuestionWiseExcel(data) {
    let obj = {
        getQuestionWiseExcelData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getQuestionWiseExcel(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_WISE_EXCEL_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_WISE_EXCEL_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_WISE_EXCEL_FAILURE, error } }
}


function getQuestionWiseExcel_Clear() {
    let obj = {
        getQuestionWiseExcelData: []
    }
    return dispatch => {
        dispatch(success(obj));
    };
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_WISE_EXCEL_SUCCESS, dashboard } }
}


function getNudgeTextBySurveyId(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getNudgeTextBySurveyId(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_NUDGE_TEXT_SURVEYID_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_NUDGE_TEXT_SURVEYID_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_NUDGE_TEXT_SURVEYID_FAILURE, error } }
}



function getAllAnsweredAssessmentEmpData(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getAllAnsweredAssessmentEmpData(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.All_ASSESSMENT_EMP_DATA_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.All_ASSESSMENT_EMP_DATA_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.All_ASSESSMENT_EMP_DATA_FAILURE, error } }
}



function mapNudgeToQuestion(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.mapNudgeToQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    dispatch(this.getNudgeTextQuesList({ "surveyId": data.surveyId }));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.MAP_NUDGE_TO_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.MAP_NUDGE_TO_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.MAP_NUDGE_TO_QUESTION_FAILURE, error } }
}

function getNudgeTextQuesList(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getNudgeTextQuesList(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_NUDGE_TEXT_QUES_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_NUDGE_TEXT_QUES_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_NUDGE_TEXT_QUES_LIST_FAILURE, error } }
}

function getNudgeLibList(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getNudgeLibList(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_NUDGE_LIB_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_NUDGE_LIB_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_NUDGE_LIB_LIST_FAILURE, error } }
}

function AddTextKeywords(data, temp) {
    return dispatch => {
        dispatch(request());
        dashboardService.AddTextKeywords(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    dispatch(this.GetTextKeywords(temp));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.ADD_TEXT_KEYWORD_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.ADD_TEXT_KEYWORD_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.ADD_TEXT_KEYWORD_FAILURE, error } }
}


function GetTextKeywords(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.GetTextKeywords(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_TEXT_KEYWORD_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_TEXT_KEYWORD_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_TEXT_KEYWORD_FAILURE, error } }
}

function GetTextDistHighlights(data, isEmpty) {
    let obj = {
        getTextDistHighlightData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        if (isEmpty) {
            dispatch(success(obj));
        } else {
            dashboardService.GetTextDistHighlights(data)
                .then(
                    dashboard => {
                        dispatch(success(dashboard));
                    },
                    error => {
                        dispatch(failure(error))
                    }
                );
        }

    };
    function request() { return { type: dashboardConstants.GET_TEXT_DIST_HIGHLIGHTS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_TEXT_DIST_HIGHLIGHTS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_TEXT_DIST_HIGHLIGHTS_FAILURE, error } }
}

function getSurveyTakenDateAdmin(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getSurveyTakenDateAdmin(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_SURVEY_TAKEN_DATE_ADMIN_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_SURVEY_TAKEN_DATE_ADMIN_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_SURVEY_TAKEN_DATE_ADMIN_FAILURE, error } }
}


function saveEmployeeNormalization(data, props, data2) {
    return dispatch => {
        dispatch(request());
        dashboardService.saveEmployeeNormalization(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    props.dispatch(dashboardActions.getEmployeeRatingAndNormalization(data2));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.SAVE_NORMALIZATION_SURVEY_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.SAVE_NORMALIZATION_SURVEY_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.SAVE_NORMALIZATION_SURVEY_FAILURE, error } }
}
function getEmployeeRatingAndNormalization(data) {

    return dispatch => {

        dispatch(request());
        dashboardService.getEmployeeRatingAndNormalization(data)
            .then(
                dashboard => dispatch(success(dashboard)),

                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.EMP_RATING_SURVEY_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.EMP_RATING_SURVEY_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.EMP_RATING_SURVEY_FAILURE, error } }
}
function PMSNormalization5(data) {

    return dispatch => {

        dispatch(request());
        dashboardService.PMSNormalization5(data)
            .then(
                dashboard => dispatch(success(dashboard)),

                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.NORMAL_SURVEY_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.NORMAL_SURVEY_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.NORMAL_SURVEY_FAILURE, error } }
}

function getNpsSurvey(data) {

    return dispatch => {

        dispatch(request());
        dashboardService.getNpsSurvey(data)
            .then(
                dashboard => dispatch(success(dashboard)),

                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_NPS_SURVEY_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_NPS_SURVEY_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_NPS_SURVEY_FAILURE, error } }
}

function CreateNpsSurvey(data) {

    return dispatch => {

        dispatch(request());
        dashboardService.CreateNpsSurvey(data)
            .then(
                dashboard => dispatch(success(dashboard)),

                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.CREATE_NPS_SURVEY_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.CREATE_NPS_SURVEY_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.CREATE_NPS_SURVEY_FAILURE, error } }
}

function getPMSModuleNames(data) {

    return dispatch => {

        dispatch(request());
        dashboardService.getPMSModuleNames(data)
            .then(
                dashboard => dispatch(success(dashboard)),

                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_PMSMODULE_NAMES_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_PMSMODULE_NAMES_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_PMSMODULE_NAMES_LIST_FAILURE, error } }
}
function getPMSRaterNames(data) {

    return dispatch => {

        dispatch(request());
        dashboardService.getPMSRaterNames(data)
            .then(
                dashboard => dispatch(success(dashboard)),

                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_PMSRATER_NAMES_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_PMSRATER_NAMES_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_PMSRATER_NAMES_LIST_FAILURE, error } }
}

function PMSGetProcessList(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.PMSGetProcessList(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.PMS_GET_PROCESS_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.PMS_GET_PROCESS_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.PMS_GET_PROCESS_LIST_FAILURE, error } }

}


function PMSAddProcessList(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.PMSAddProcessList(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.PMS_ADD_PROCESS_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.PMS_ADD_PROCESS_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.PMS_ADD_PROCESS_LIST_FAILURE, error } }

}



function PMSAppraisalCycleList(data) {

    return dispatch => {

        dispatch(request());
        dashboardService.PMSAppraisalCycleList(data)
            .then(
                dashboard => dispatch(success(dashboard)),

                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_PMSMODULE_APPRAISAL_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_PMSMODULE_APPRAISAL_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_PMSMODULE_APPRAISAL_LIST_FAILURE, error } }
}
function PMSApplicableFor4(data) {

    return dispatch => {

        dispatch(request());
        dashboardService.PMSApplicableFor4(data)
            .then(
                dashboard => dispatch(success(dashboard)),

                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_PMSMODULE_APPLICABLE4_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_PMSMODULE_APPLICABLE4_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_PMSMODULE_APPLICABLE4_FAILURE, error } }
}
function PMSApplicableFor3(data) {

    return dispatch => {

        dispatch(request());
        dashboardService.PMSApplicableFor3(data)
            .then(
                dashboard => dispatch(success(dashboard)),

                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_PMSMODULE_APPLICABLE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_PMSMODULE_APPLICABLE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_PMSMODULE_APPLICABLE_FAILURE, error } }
}
function PMSAddRaterSetting3(data) {

    return dispatch => {

        dispatch(request());
        dashboardService.PMSAddRaterSetting3(data)
            .then(
                dashboard => dispatch(success(dashboard)),

                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_PMSMODULE_RATER_SETTING_CYCLE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_PMSMODULE_RATER_SETTING_CYCLE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_PMSMODULE_RATER_SETTING_CYCLE_FAILURE, error } }
}

function PMSGetRaterSetting3(data) {

    return dispatch => {

        dispatch(request());
        dashboardService.PMSGetRaterSetting3(data)
            .then(
                dashboard => dispatch(success(dashboard)),

                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_GET_PMSMODULE_RATER_SETTING_CYCLE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_GET_PMSMODULE_RATER_SETTING_CYCLE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_GET_PMSMODULE_RATER_SETTING_CYCLE_FAILURE, error } }
}

function PMSAddModuleSetting2(data) {

    return dispatch => {

        dispatch(request());
        dashboardService.PMSAddModuleSetting2(data)
            .then(
                dashboard => dispatch(success(dashboard)),

                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_PMSMODULE_SETTING_CYCLE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_PMSMODULE_SETTING_CYCLE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_PMSMODULE_SETTING_CYCLE_FAILURE, error } }
}

function PMSGetModuleSetting2(data) {

    return dispatch => {

        dispatch(request());
        dashboardService.PMSGetModuleSetting2(data)
            .then(
                dashboard => dispatch(success(dashboard)),

                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_PMS_GET_MODULE_SETTING_CYCLE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_PMS_GET_MODULE_SETTING_CYCLE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_PMS_GET_MODULE_SETTING_CYCLE_FAILURE, error } }
}

function PMSGetAppraisalCycleById(data) {

    return dispatch => {

        dispatch(request());
        dashboardService.PMSGetAppraisalCycleById(data)
            .then(
                dashboard => dispatch(success(dashboard)),

                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_APPRAISAL_CYCLE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_APPRAISAL_CYCLE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_APPRAISAL_CYCLE_FAILURE, error } }
}
function PMSAddAppraisalCycle1(data, props) {
    // console.log("gagagag",JSON.stringify(data));

    return dispatch => {

        dispatch(request());
        dashboardService.PMSAddAppraisalCycle1(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                    let data = { "id": dashboard && dashboard.PMSAddAppraisalCycle1 && dashboard.PMSAddAppraisalCycle1.id }

                    props.dispatch(dashboardActions.PMSGetAppraisalCycleById(data));
                    props.dispatch(dashboardActions.PMSGetModuleSetting2(data));
                    props.dispatch(dashboardActions.PMSGetRaterSetting3(data));


                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_PMS_ADD_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_PMS_ADD_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_PMS_ADD_FAILURE, error } }
}


function emptyClientProfile() {
    let obj = {
        ClientProfileData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getClientProfile()
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_CLIENT_PROFILE_DATA_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_CLIENT_PROFILE_DATA_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_CLIENT_PROFILE_DATA_FAILURE, error } }
}


function getGrowProfile() {
    let obj = {
        GrowProfileData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getGrowProfile()
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_GROW_PROFILE_DATA_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_GROW_PROFILE_DATA_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_GROW_PROFILE_DATA_FAILURE, error } }
}

function getClientProfile() {
    let obj = {
        ClientProfileData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getClientProfile()
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_CLIENT_PROFILE_DATA_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_CLIENT_PROFILE_DATA_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_CLIENT_PROFILE_DATA_FAILURE, error } }
}


function generateCompanySurveyCode(data, temp) {
    let obj = {
        generateCompanySurveyCodeData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.generateCompanySurveyCode(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    dispatch(surveyActions.getSurveyById(temp));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GENERATE_COMPANY_SURVEY_CODE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GENERATE_COMPANY_SURVEY_CODE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GENERATE_COMPANY_SURVEY_CODE_FAILURE, error } }
}

function getSurvey(data, type) {
    let obj = {
        SurveyList: [],
        SurveyTotal: 0
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getSurvey(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    dispatch(this.getAllManagerActionPlan({ surveyId: data.surveyId }));
                    // if(type==="ManagerActionPlan"){
                    //     if(dashboard && dashboard.SurveyData && dashboard.SurveyData.list && dashboard.SurveyData.list.length>0 && dashboard.SurveyData.list[0] && dashboard.SurveyData.list[0].id){
                    //         const firstSurveyId = dashboard.SurveyData.list[0].id;
                    //         dispatch(this.AssignManagerActionPlan({surveyId:firstSurveyId}));
                    //     }
                    // }
                    // if(dashboard && dashboard.SurveyData && dashboard.SurveyData.list && dashboard.SurveyData.list.length>0 && dashboard.SurveyData.list[0] && dashboard.SurveyData.list[0].id){
                    //     let data2 = {
                    //         "surveyId": dashboard.SurveyData.list[0].id
                    //     }
                    //     let data3 = {
                    //         "surveyId": dashboard.SurveyData.list[0].id,
                    //         "filters":null
                    //     }
                    //     if(data2.surveyId){
                    //         dispatch(this.getRespondantDetails(data2));

                    //         dispatch(this.getIndex(data2));

                    //     }

                    // }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_SURVEY_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_SURVEY_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_SURVEY_FAILURE, error } }
}



function getEmpFilter(apiName, surveyId, temp) {
    let obj = {
        EmpFilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getEmpFilter(temp)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if (dashboard && dashboard.EmpFilterData && dashboard.EmpFilterData.length > 0) {
                        let Level = dashboard.EmpFilterData[0].name;

                        if (apiName === "getMeanScoreQwise") {
                            let data4 = {
                                "surveyId": surveyId,
                                "xAxis": Level
                            }
                            dispatch(generalActions.getMeanScoreQwise(data4));
                        } else if (apiName === "getExitQuestionAllDemographics") {
                            let data2 = {
                                "surveyId": surveyId,
                                "xAxis": Level
                            }
                            dispatch(this.getExitQuestionAllDemographics(data2));
                        }

                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_EMP_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_EMP_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_EMP_FILTER_FAILURE, error } }
}



// function assignRole(data) {
//     return dispatch => {
//         dispatch(request());
//         dashboardService.assignRole(data)
//             .then(
//                 dashboard => dispatch(success(dashboard)),
//                 error => {
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: dashboardConstants.GET_ASSIGN_ROLE_REQUEST } }
//     function success(dashboard) { return { type: dashboardConstants.GET_ASSIGN_ROLE_SUCCESS, dashboard } }
//     function failure(error) { return { type: dashboardConstants.GET_ASSIGN_ROLE_FAILURE, error } }
// }


function newStackList() {
    let obj = {
        StackListData: []
    }
    return dispatch => {
        dispatch(success2(obj));
    };
    function success2(dashboard) { return { type: dashboardConstants.GET_STACK_LIST_SUCCESS, dashboard } }
}


// new api s------------------------------------>

function getIndex(data) {
    let obj = {
        getIndexData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIndex(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_INDEX_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_INDEX_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_INDEX_FAILURE, error } }
}
function getIndex2(data) {
    let obj = {
        getIndex2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIndex(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_INDEX_2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_INDEX_2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_INDEX_2_FAILURE, error } }
}




function getIndexVsNps(data) {
    let obj = {
        getIndexVsNpsData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIndexVsNps(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_INDEX_VS_NPS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_INDEX_VS_NPS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_INDEX_VS_NPS_FAILURE, error } }
}


function getOverallIndex(data) {
    let obj = {
        getOverallIndexData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getOverallIndex(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_OVERALL_INDEX_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_OVERALL_INDEX_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_OVERALL_INDEX_FAILURE, error } }
}

function getOverallIndex2(data) {
    let obj = {
        getOverallIndex2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getOverallIndex2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_OVERALL_INDEX_2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_OVERALL_INDEX_2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_OVERALL_INDEX_2_FAILURE, error } }
}

function getIndexFilter(data) {
    let obj = {
        getIndexFilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIndexFilter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_INDEX_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_INDEX_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_INDEX_FILTER_FAILURE, error } }
}

function getIndex2Filter(data) {
    let obj = {
        getIndex2FilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIndexFilter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_INDEX_2_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_INDEX_2_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_INDEX_2_FILTER_FAILURE, error } }
}

function getDemographicIndex(data) {
    let obj = {
        getDemographicIndexData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(clear());
        dispatch(request());
        dashboardService.getDemographicIndex(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_FAILURE, error } }
    function clear(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_CLEAR, error } }
}


function getQuestion(data) {
    let Obj = {
        getQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_FAILURE, error } }
}

function getQuestionDistribution(data) {
    let Obj = {
        getQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getQuestionDistribution(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_ONLY_STRESS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_ONLY_STRESS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_ONLY_STRESS_FAILURE, error } }
}


function getQuesDistributionIndexOrg(data) {
    let Obj = {
        getQuesDistributionIndexOrgData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getQuesDistributionIndexOrg(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_INDEX_ORG_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_INDEX_ORG_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_INDEX_ORG_FAILURE, error } }
}


function getQuesDistributionIndexOrg_Clear() {
    let Obj = {
        getQuesDistributionIndexOrgData: []
    }
    return dispatch => {
        dispatch(success(Obj));
    };
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_INDEX_ORG_SUCCESS, dashboard } }
}

function getHappinessQuestion(data) {
    let Obj = {
        getHappinessQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getHappinessQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPINESS_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPINESS_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPINESS_QUESTION_FAILURE, error } }
}
function getHappinessQuestion2(data) {
    let Obj = {
        getHappinessQuestion2Data: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getHappinessQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPINESS_QUESTION_2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPINESS_QUESTION_2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPINESS_QUESTION_2_FAILURE, error } }
}


function getHappinessManagerQuestion(data) {
    let Obj = {
        getQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getHappinessQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPINESS_MANAGER_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPINESS_MANAGER_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPINESS_MANAGER_QUESTION_FAILURE, error } }
}
function getHappinessDMGQuestion(data) {
    let Obj = {
        getQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getHappinessQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPINESS_DMG_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPINESS_DMG_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPINESS_DMG_QUESTION_FAILURE, error } }
}


function getEngagementQuestion(data) {
    let Obj = {
        getEngagementQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getEngagementQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_QUESTION_FAILURE, error } }
}
function getEngagementQuestion2(data) {
    let Obj = {
        getEngagementQuestion2Data: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getEngagementQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_QUESTION_2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_QUESTION_2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_QUESTION_2_FAILURE, error } }
}

function getEngagementManagerQuestion(data) {
    let Obj = {
        getQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getEngagementQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_MANAGER_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_MANAGER_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_MANAGER_QUESTION_FAILURE, error } }
}
function getEngagementDMGQuestion(data) {
    let Obj = {
        getQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getEngagementQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_DMG_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_DMG_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_DMG_QUESTION_FAILURE, error } }
}



function getHopeQuestion(data) {
    let Obj = {
        getQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getHopeQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HOPE_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HOPE_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HOPE_QUESTION_FAILURE, error } }
}

function getStressQuestion(data) {
    let Obj = {
        getQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getStressQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_QUESTION_FAILURE, error } }
}
function getStressQuestion2(data) {
    let Obj = {
        getStressQuestion2Data: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getStressQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_QUESTION_2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_QUESTION_2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_QUESTION_2_FAILURE, error } }
}


function getStressManagerQuestion(data) {
    let Obj = {
        getQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getStressQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_MANAGER_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_MANAGER_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_MANAGER_QUESTION_FAILURE, error } }
}
function getStressDMGQuestion(data) {
    let Obj = {
        getQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getStressQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_DMG_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_DMG_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_DMG_QUESTION_FAILURE, error } }
}



function getManagerQuestion(data) {
    let Obj = {
        getManagerQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getManagerQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_QUESTION_FAILURE, error } }
}



function getManagerQuestion2(data) {
    let Obj = {
        getManagerQuestion2Data: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getManagerQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_QUESTION_2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_QUESTION_2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_QUESTION_2_FAILURE, error } }
}



function getManagerManagerQuestion(data) {
    let Obj = {
        getQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getManagerQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_MANAGER_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_MANAGER_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_MANAGER_QUESTION_FAILURE, error } }
}

function getManagerDMGQuestion(data) {
    let Obj = {
        getQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getManagerQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_DMG_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_DMG_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_DMG_QUESTION_FAILURE, error } }
}



function getLeaderQuestion(data) {
    let Obj = {
        getQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getLeaderQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADER_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADER_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADER_QUESTION_FAILURE, error } }
}

function getLeaderQuestion2(data) {
    let Obj = {
        getLeaderQuestion2Data: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getLeaderQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADER_QUESTION_2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADER_QUESTION_2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADER_QUESTION_2_FAILURE, error } }
}

function getLeaderManagerQuestion(data) {
    let Obj = {
        getQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getLeaderQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADER_MANAGER_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADER_MANAGER_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADER_MANAGER_QUESTION_FAILURE, error } }
}

function getLeaderDMGQuestion(data) {
    let Obj = {
        getQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getLeaderQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADER_DMG_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADER_DMG_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADER_DMG_QUESTION_FAILURE, error } }
}



function getQuestionOverall(data) {
    let Obj = {
        getQuestionOverallData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getQuestionOverall(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_OVERALL_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_OVERALL_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_OVERALL_FAILURE, error } }
}

function getQuestionReport(data, template) {
    let obj = {
        getQuestionReportData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getQuestionReport(data, template)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_REPORT_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_REPORT_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_REPORT_FAILURE, error } }
}



function getQuestionFilter(data) {
    let obj = {
        getQuestionFilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getQuestionFilter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard))
                    ////console.log('dashboard: ',dashboard)
                },

                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_FILTER_FAILURE, error } }
}


function getHappinessIndexV2(data) {
    let obj = {
        getHappinessIndexV2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getHappinessIndexV2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPPINESS_INDEX_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_V2_FAILURE, error } }
}

function getHappinessIndexV22(data) {
    let obj = {
        getHappinessIndexV22Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getHappinessIndexV2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPPINESS_INDEX_V2_2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_V2_2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_V2_2_FAILURE, error } }
}


function getEngagementIndexV2(data) {
    let obj = {
        getEngagementIndexV2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getEngagementIndexV2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_FAILURE, error } }
}

function getEngagementIndexV22(data) {
    let obj = {
        getEngagementIndexV22Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getEngagementIndexV2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_2_FAILURE, error } }
}

function getHopeIndexV2(data) {
    let obj = {
        getHopeIndexV2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getHopeIndexV2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HOPE_INDEX_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HOPE_INDEX_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HOPE_INDEX_V2_FAILURE, error } }
}


function getStressIndexV2(data) {
    let obj = {
        getStressIndexV2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getStressIndexV2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_INDEX_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_INDEX_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_INDEX_V2_FAILURE, error } }
}

function getStressIndexV22(data) {
    let obj = {
        getStressIndexV22Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getStressIndexV2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_INDEX_V2_2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_INDEX_V2_2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_INDEX_V2_2_FAILURE, error } }
}


function getManagerIndexV2(data) {
    let obj = {
        getHappinessIndexV2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getManagerIndexV2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_INDEX_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_INDEX_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_INDEX_V2_FAILURE, error } }
}


function getLeaderIndexV2(data) {
    let obj = {
        getHappinessIndexV2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getLeaderIndexV2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADER_INDEX_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADER_INDEX_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADER_INDEX_V2_FAILURE, error } }
}


function getSeniorleadershipIndexV2(data) {
    let obj = {
        getHappinessIndexV2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getSeniorleadershipIndexV2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_SENIORLEADERSHIP_INDEX_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_SENIORLEADERSHIP_INDEX_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_SENIORLEADERSHIP_INDEX_V2_FAILURE, error } }
}

function getOrgIndexV2(data) {
    let obj = {
        getOrgIndexV2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getOrgIndexV2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ORG_INDEX_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ORG_INDEX_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ORG_INDEX_V2_FAILURE, error } }
}



function getDemographicIndexHappiness(data, type, ManagerDynaObj, isReport, DriverMngrHappinessReportObject, filters) {
    let obj = {
        getDemographicIndexHappinessData: []
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        dashboardService.getDemographicIndexHappiness(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if (isReport) {
                        if (dashboard && dashboard.getDemographicIndexHappinessData) {
                            dashboard.getDemographicIndexHappinessData.forEach((item) => {
                                if (filters.value === item.name) {
                                    if (DriverMngrHappinessReportObject && DriverMngrHappinessReportObject[filters.level] && DriverMngrHappinessReportObject[filters.level].findIndex(prev => prev.name === item.name) === -1) {
                                        DriverMngrHappinessReportObject[filters.level].push(item);
                                    }
                                    else {
                                        if (DriverMngrHappinessReportObject && DriverMngrHappinessReportObject[filters.level] && DriverMngrHappinessReportObject[filters.level].findIndex(prev => prev.name === item.name)) {
                                            let getIndex = DriverMngrHappinessReportObject[filters.level].findIndex(prev => prev.name === item.name);
                                            DriverMngrHappinessReportObject[filters.level][getIndex] = item;
                                        }
                                    }
                                }
                            });
                        }
                    }
                    if (type === 5) {
                        if (dashboard && dashboard.getDemographicIndexHappinessData) {
                            dashboard.getDemographicIndexHappinessData.forEach((item) => {
                                if (ManagerDynaObj.findIndex(prev => prev.name === item.name) === -1) {
                                    ManagerDynaObj.push(item);
                                }
                                else {
                                    let getIndex = ManagerDynaObj.findIndex(prev => prev.name === item.name);
                                    ManagerDynaObj[getIndex] = item
                                }
                            })
                        }
                    }
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_HAPPINESS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_HAPPINESS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_HAPPINESS_FAILURE, error } }
}


function getDemographicIndexEngagement(data, type, ManagerDynaObj, isReport, DriverMngrEngagementReportObject, filters) {
    let obj = {
        getDemographicIndexEngagementData: []
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        dashboardService.getDemographicIndexEngagement(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if (isReport) {
                        if (dashboard && dashboard.getDemographicIndexEngagementData) {
                            dashboard.getDemographicIndexEngagementData.forEach((item) => {
                                if (filters.value === item.name) {
                                    if (DriverMngrEngagementReportObject && DriverMngrEngagementReportObject[filters.level] && DriverMngrEngagementReportObject[filters.level].findIndex(prev => prev.name === item.name) === -1) {
                                        DriverMngrEngagementReportObject[filters.level].push(item);
                                    }
                                    else {
                                        if (DriverMngrEngagementReportObject && DriverMngrEngagementReportObject[filters.level] && DriverMngrEngagementReportObject[filters.level].findIndex(prev => prev.name === item.name)) {
                                            let getIndex = DriverMngrEngagementReportObject[filters.level].findIndex(prev => prev.name === item.name);
                                            DriverMngrEngagementReportObject[filters.level][getIndex] = item;
                                        }
                                    }
                                }
                            });
                        }
                    }
                    if (type === 5) {
                        if (dashboard && dashboard.getDemographicIndexEngagementData) {
                            // ManagerDynaObj.concat(dashboard.getDemographicIndexEngagementData)
                            dashboard.getDemographicIndexEngagementData.forEach((item) => {
                                if (ManagerDynaObj.findIndex(prev => prev.name === item.name) === -1) {
                                    ManagerDynaObj.push(item);
                                }
                                else {
                                    let getIndex = ManagerDynaObj.findIndex(prev => prev.name === item.name);
                                    ManagerDynaObj[getIndex] = item
                                }
                            })
                        }
                    }
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_FAILURE, error } }
}

function getDemographicIndexStress(data, type, ManagerDynaObj, isReport, DriverMngrStressReportObject, filters) {
    let obj = {
        getDemographicIndexStressData: []
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        dashboardService.getDemographicIndexStress(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if (isReport) {
                        if (dashboard && dashboard.getDemographicIndexStressData) {
                            dashboard.getDemographicIndexStressData.forEach((item) => {
                                if (filters.value === item.name) {
                                    if (DriverMngrStressReportObject && DriverMngrStressReportObject[filters.level] && DriverMngrStressReportObject[filters.level].findIndex(prev => prev.name === item.name) === -1) {
                                        DriverMngrStressReportObject[filters.level].push(item);
                                    }
                                    else {
                                        if (DriverMngrStressReportObject && DriverMngrStressReportObject[filters.level] && DriverMngrStressReportObject[filters.level].findIndex(prev => prev.name === item.name)) {
                                            let getIndex = DriverMngrStressReportObject[filters.level].findIndex(prev => prev.name === item.name);
                                            DriverMngrStressReportObject[filters.level][getIndex] = item;
                                        }
                                    }
                                }
                            });
                        }
                    }
                    if (type === 5) {
                        if (dashboard && dashboard.getDemographicIndexStressData) {
                            // ManagerDynaObj.concat(dashboard.getDemographicIndexStressData)
                            dashboard.getDemographicIndexStressData.forEach((item) => {
                                if (ManagerDynaObj.findIndex(prev => prev.name === item.name) === -1) {
                                    ManagerDynaObj.push(item);
                                }
                                else {
                                    let getIndex = ManagerDynaObj.findIndex(prev => prev.name === item.name);
                                    ManagerDynaObj[getIndex] = item
                                }
                            })
                        }
                    }
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_STRESS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_STRESS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_STRESS_FAILURE, error } }
}


function getDemographicIndexManager(data, type, ManagerDynaObj, isReport, DriverMngrManagerReportObject, filters) {
    let obj = {
        getDemographicIndexManagerData: []
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        dashboardService.getDemographicIndexManager(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if (isReport) {
                        if (dashboard && dashboard.getDemographicIndexManagerData) {
                            dashboard.getDemographicIndexManagerData.forEach((item) => {
                                if (filters.value === item.name) {
                                    if (DriverMngrManagerReportObject && DriverMngrManagerReportObject[filters.level] && DriverMngrManagerReportObject[filters.level].findIndex(prev => prev.name === item.name) === -1) {
                                        DriverMngrManagerReportObject[filters.level].push(item);
                                    }
                                    else {
                                        if (DriverMngrManagerReportObject && DriverMngrManagerReportObject[filters.level] && DriverMngrManagerReportObject[filters.level].findIndex(prev => prev.name === item.name)) {
                                            let getIndex = DriverMngrManagerReportObject[filters.level].findIndex(prev => prev.name === item.name);
                                            DriverMngrManagerReportObject[filters.level][getIndex] = item;
                                        }
                                    }
                                }
                            });
                        }
                    }
                    if (type === 5) {
                        if (dashboard && dashboard.getDemographicIndexManagerData) {
                            // ManagerDynaObj.concat(dashboard.getDemographicIndexManagerData)
                            dashboard.getDemographicIndexManagerData.forEach((item) => {
                                if (ManagerDynaObj.findIndex(prev => prev.name === item.name) === -1) {
                                    ManagerDynaObj.push(item);
                                }
                                else {
                                    let getIndex = ManagerDynaObj.findIndex(prev => prev.name === item.name);
                                    ManagerDynaObj[getIndex] = item
                                }
                            })
                        }
                    }
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANAGER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANAGER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANAGER_FAILURE, error } }
}

function getDemographicIndexLeader(data, type, ManagerDynaObj) {
    let obj = {
        getDemographicIndexLeaderData: []
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        dashboardService.getDemographicIndexLeader(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if (type === 5) {
                        if (dashboard && dashboard.getDemographicIndexLeaderData) {
                            // ManagerDynaObj.concat(dashboard.getDemographicIndexLeaderData)
                            dashboard.getDemographicIndexLeaderData.forEach((item) => {
                                if (ManagerDynaObj.findIndex(prev => prev.name === item.name) === -1) {
                                    ManagerDynaObj.push(item);
                                }
                                else {
                                    let getIndex = ManagerDynaObj.findIndex(prev => prev.name === item.name);
                                    ManagerDynaObj[getIndex] = item
                                }
                            })
                        }
                    }
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_LEADER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_LEADER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_LEADER_FAILURE, error } }
}


function getDemographicIndexExperience(data, type, ExperienceDynaObj, isReport, DriverMngrExperienceReportObject, filters) {
    let obj = {
        getDemographicIndexExperienceData: []
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        dashboardService.getDemographicIndexExperience(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if (isReport) {
                        if (dashboard && dashboard.getDemographicIndexExperienceData) {
                            dashboard.getDemographicIndexExperienceData.forEach((item) => {
                                if (filters.value === item.name) {
                                    if (DriverMngrExperienceReportObject && DriverMngrExperienceReportObject[filters.level] && DriverMngrExperienceReportObject[filters.level].findIndex(prev => prev.name === item.name) === -1) {
                                        DriverMngrExperienceReportObject[filters.level].push(item);
                                    }
                                    else {
                                        if (DriverMngrExperienceReportObject && DriverMngrExperienceReportObject[filters.level] && DriverMngrExperienceReportObject[filters.level].findIndex(prev => prev.name === item.name)) {
                                            let getIndex = DriverMngrExperienceReportObject[filters.level].findIndex(prev => prev.name === item.name);
                                            DriverMngrExperienceReportObject[filters.level][getIndex] = item;
                                        }
                                    }
                                }
                            });
                        }
                    }
                    if (type === 5) {
                        if (dashboard && dashboard.getDemographicIndexExperienceData) {
                            // ExperienceDynaObj.concat(dashboard.getDemographicIndexExperienceData)
                            dashboard.getDemographicIndexExperienceData.forEach((item) => {
                                if (ExperienceDynaObj.findIndex(prev => prev.name === item.name) === -1) {
                                    ExperienceDynaObj.push(item);
                                }
                                else {
                                    let getIndex = ExperienceDynaObj.findIndex(prev => prev.name === item.name);
                                    ExperienceDynaObj[getIndex] = item
                                }
                            })
                        }
                    }
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_EXPERIENCE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_EXPERIENCE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_EXPERIENCE_FAILURE, error } }
}


function getDemographicIndexOrg(data) {
    let obj = {
        getDemographicIndexOrgData: []
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        dashboardService.getDemographicIndexOrg(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_ORG_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_ORG_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_ORG_FAILURE, error } }
}


function getDemographicIndexMultiAxisOrg(data) {
    let obj = {
        getDemographicIndexMultiAxisOrgData: []
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        dashboardService.getDemographicIndexMultiAxisOrg(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_MULTI_AXIS_INDEX_ORG_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_MULTI_AXIS_INDEX_ORG_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_MULTI_AXIS_INDEX_ORG_FAILURE, error } }
}

function getHappinessIndexDriver(data) {
    let obj = {
        getHappinessIndexDriverData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getHappinessIndexDriver(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_FAILURE, error } }
}

function getEngagementIndexDriver(data) {
    let obj = {
        getEngagementIndexDriverData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getEngagementIndexDriver(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_DRIVER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_DRIVER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_DRIVER_FAILURE, error } }
}

function getStressIndexDriver(data) {
    let obj = {
        getStressIndexDriverData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getStressIndexDriver(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_INDEX_DRIVER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_INDEX_DRIVER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_INDEX_DRIVER_FAILURE, error } }
}


function getManagerIndexDriver(data) {
    let obj = {
        getManagerIndexDriverData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getManagerIndexDriver(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_INDEX_DRIVER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_INDEX_DRIVER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_INDEX_DRIVER_FAILURE, error } }
}


function getExperienceIndexDriver(data) {
    let obj = {
        getExperienceIndexDriverData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getExperienceIndexDriver(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_EXPERIENCE_INDEX_DRIVER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_EXPERIENCE_INDEX_DRIVER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_EXPERIENCE_INDEX_DRIVER_FAILURE, error } }
}



function getLeaderIndexDriver(data) {
    let obj = {
        getLeaderIndexDriverData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getLeaderIndexDriver(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADER_INDEX_DRIVER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADER_INDEX_DRIVER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADER_INDEX_DRIVER_FAILURE, error } }
}

function getOrgIndexDriver(data) {
    let obj = {
        getOrgIndexDriverData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getOrgIndexDriver(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ORG_INDEX_DRIVER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ORG_INDEX_DRIVER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ORG_INDEX_DRIVER_FAILURE, error } }
}






function getHappinessIndexV2Demographic(data) {
    let obj = {
        getHappinessIndexV2DemographicData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getHappinessIndexV2Demographic(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_FAILURE, error } }
}

function getEngagementIndexV2Demographic(data) {
    let obj = {
        getEngagementIndexV2DemographicData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getEngagementIndexV2Demographic(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_FAILURE, error } }
}

function getStressIndexV2Demographic(data) {
    let obj = {
        getStressIndexV2DemographicData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getStressIndexV2Demographic(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_INDEX_V2_DEMOGRAPHIC_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_INDEX_V2_DEMOGRAPHIC_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_INDEX_V2_DEMOGRAPHIC_FAILURE, error } }
}


function getManagerList(data) {
    let obj = {
        ManagerListData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getManagerList(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    // if(dashboard && dashboard.ManagerList && dashboard.ManagerList.list && dashboard.ManagerList.list.length>0 && dashboard.ManagerList.list[0] && dashboard.ManagerList.list[0].ManagerPID){
                    //     let data1 = {
                    //         "surveyId": data.surveyId,
                    //         "filters": [
                    //             {
                    //                 "level": "ManagerPID",
                    //                 "value":dashboard.ManagerList.list[0].ManagerPID
                    //             }
                    //         ]
                    //     }
                    //     dispatch(this.getIndexByManagerWise(data1));
                    // }

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_LIST_FAILURE, error } }
}


function getManagerListDashboardManagerIndex(data, mngr, ManagerListDasboardDataObj) {
    let obj = {
        getManagerListDashboardManagerIndexData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getManagerListDashboardManagerIndex(data, mngr)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if (dashboard && dashboard.getManagerListDashboardManagerIndexData && dashboard.getManagerListDashboardManagerIndexData.ManagerPID) {
                        let managerId = dashboard.getManagerListDashboardManagerIndexData.ManagerPID;
                        let getIndex = ManagerListDasboardDataObj.findIndex(prev => prev.ManagerPID === managerId)
                        if (getIndex === -1) {
                            ManagerListDasboardDataObj.push(dashboard.getManagerListDashboardManagerIndexData);
                        }
                        else {
                            ManagerListDasboardDataObj[getIndex] = dashboard.getManagerListDashboardManagerIndexData;
                        }


                    }
                    // else{
                    //     ManagerListDasboardDataObj.push({

                    //     })
                    // }

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_FAILURE, error } }
}


function getDemographicIndexForManager(data, type, DynaObj, EmpFilterData, surveyId) {
    let obj = {
        getDemographicIndexForManagerData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getDemographicIndexForManager(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if (type === 5) {

                        if (dashboard && dashboard.getDemographicIndexForManagerData && dashboard.getDemographicIndexForManagerData.length > 0) {


                            // ////////console.log("EmpFilterData[0]",EmpFilterData)
                            let data2 = dashboard.getDemographicIndexForManagerData[0];


                            let filterdataX = {
                                "surveyId": surveyId,
                                "mngrList": [data2["ManagerPID"]]
                            }

                            dispatch(this.getIndexScoreManagerPIDWise(filterdataX, 5, DynaObj));



                            for (let i = 0; i < 2; i++) {
                                if (EmpFilterData && EmpFilterData.length > 0) {
                                    // ////////console.log("dashboard[0]",data2)

                                    // let filterdata2 = {
                                    //     "surveyId":surveyId,
                                    //     "filters":[{
                                    //          "level":"ManagerPID",
                                    //          "value":data2["id"]
                                    //      }]
                                    // }
                                    // ////////console.log("dashboard[0]",filterdata2)

                                    // dispatch(this.getManagerListDomainFilter(filterdata2,5,DynaObj));
                                    EmpFilterData.forEach((filter) => {
                                        if (["ManagerPID", "Department", "Function", "Business"].findIndex(prev => prev === filter.name) !== -1) {


                                            if (data2 && data2[filter.name]) {
                                                let filterdata = {
                                                    "surveyId": surveyId,
                                                    "filters": [{
                                                        "level": filter.name,
                                                        "value": data2[filter && filter.name === "ManagerPID" ? "id" : filter.name]
                                                    }]
                                                }

                                                dispatch(this.getManagerListDomainFilter(filterdata, 5, DynaObj));
                                            }

                                        }

                                    })
                                }

                            }

                        }

                    }

                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_FAILURE, error } }
}


function getAllBenchMark(data) {
    let obj = {
        getAllBenchMarkData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getAllBenchMark(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ALL_BENCHMARK_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ALL_BENCHMARK_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ALL_BENCHMARK_FAILURE, error } }
}

function getRespondantDetails(data) {
    let obj = {
        getRespondantDetailsData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getRespondantDetails(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_RESPONDANT_DETAILS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_RESPONDANT_DETAILS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_RESPONDANT_DETAILS_FAILURE, error } }
}

function getRespondantDetails2(data) {
    let obj = {
        getRespondantDetails2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getRespondantDetails(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_RESPONDANT_DETAILS_2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_RESPONDANT_DETAILS_2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_RESPONDANT_DETAILS_2_FAILURE, error } }
}


function getMetaRespondantDetails(data) {
    let obj = {
        getMetaRespondantDetailsData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getMetaRespondantDetails(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_META_RESPONDANT_DETAILS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_META_RESPONDANT_DETAILS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_META_RESPONDANT_DETAILS_FAILURE, error } }
}

function getMetaRespondantDetails2(data) {
    let obj = {
        getMetaRespondantDetails2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getMetaRespondantDetails(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_META_RESPONDANT_DETAILS_2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_META_RESPONDANT_DETAILS_2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_META_RESPONDANT_DETAILS_2_FAILURE, error } }
}


function getMeta360RespondantDetails(data) {
    let obj = {
        getMeta360RespondantDetailsData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getMeta360RespondantDetails(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_META_360_RESPONDANT_DETAILS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_META_360_RESPONDANT_DETAILS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_META_360_RESPONDANT_DETAILS_FAILURE, error } }
}



function getCustomerRespondantDetails(data) {
    let obj = {
        getCustomerRespondantDetailsData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getCustomerRespondantDetails(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_CUSTOMER_RESPONDANT_DETAILS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_CUSTOMER_RESPONDANT_DETAILS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_CUSTOMER_RESPONDANT_DETAILS_FAILURE, error } }
}


function getManagerListDomainFilter(data, indexType, DynaObj) {
    let refresh = {
        getManagerListDomainFilterData: {}
    }
    return dispatch => {
        dispatch(success(refresh));
        dispatch(request());
        dashboardService.getManagerListDomainFilter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if (indexType === 5) {
                        if (dashboard && dashboard.getManagerListDomainFilterData) {
                            let newData = dashboard.getManagerListDomainFilterData;
                            if (DynaObj.findIndex(prev => prev.name[0] === newData.name[0]) === -1) {
                                DynaObj.push(newData);
                            }
                            else {
                                let getIndex = DynaObj.findIndex(prev => prev.name[0] === newData.name[0]);
                                DynaObj[getIndex] = newData;
                            }
                        }
                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_LIST_DOMAIN_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_LIST_DOMAIN_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_LIST_DOMAIN_FILTER_FAILURE, error } }
}





function getQuestionManagerOfHappiness(data, type, QuesDynaObj, isReport, QuesMngrHappinessReportObject) {
    let Obj = {
        getQuestionManagerOfHappinessData: []
    }
    return dispatch => {

        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getQuestionManagerOfHappiness(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if (true) {
                        if (dashboard && dashboard.getQuestionManagerOfHappinessData) {


                            if (dashboard && dashboard.getQuestionManagerOfHappinessData) {
                                dashboard.getQuestionManagerOfHappinessData.forEach((item) => {



                                    if (QuesMngrHappinessReportObject && QuesMngrHappinessReportObject[item.filters[0].level] && QuesMngrHappinessReportObject[item.filters[0].level].findIndex(prev => prev.name === item.name) === -1) {
                                        QuesMngrHappinessReportObject[item.filters[0].level].push(item);
                                    }
                                    else {
                                        if (QuesMngrHappinessReportObject && QuesMngrHappinessReportObject[item.filters[0].level] && QuesMngrHappinessReportObject[item.filters[0].level].findIndex(prev => prev.name === item.name)) {
                                            let getIndex = QuesMngrHappinessReportObject[item.filters[0].level].findIndex(prev => prev.name === item.name);
                                            QuesMngrHappinessReportObject[item.filters[0].level][getIndex] = item;
                                        }
                                    }




                                }
                                )

                            }
                        }

                    }
                    if (type === 5) {
                        if (dashboard && dashboard.getQuestionManagerOfHappinessData) {

                            dashboard.getQuestionManagerOfHappinessData.forEach((item) => {



                                if (QuesDynaObj && QuesDynaObj[item.filters[0].level] && QuesDynaObj[item.filters[0].level].findIndex(prev => prev.name === item.name) === -1) {
                                    QuesDynaObj[item.filters[0].level].push(item);
                                }
                                else {
                                    if (QuesDynaObj && QuesDynaObj[item.filters[0].level] && QuesDynaObj[item.filters[0].level].findIndex(prev => prev.name === item.name)) {
                                        let getIndex = QuesDynaObj[item.filters[0].level].findIndex(prev => prev.name === item.name);
                                        QuesDynaObj[item.filters[0].level][getIndex] = item;
                                    }

                                }




                            }
                            )

                        }
                    }


                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_HAPPINESS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_HAPPINESS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_HAPPINESS_FAILURE, error } }
}

function getQuestionManagerOfEngagement(data, type, QuesDynaObj, isReport, QuesMngrEngagementReportObject) {
    let Obj = {
        getQuestionManagerOfEngagementData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getQuestionManagerOfEngagement(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if (true) {
                        if (dashboard && dashboard.getQuestionManagerOfEngagementData) {

                            if (dashboard && dashboard.getQuestionManagerOfEngagementData) {

                                dashboard.getQuestionManagerOfEngagementData.forEach((item) => {
                                    if (QuesMngrEngagementReportObject && QuesMngrEngagementReportObject[item.filters[0].level] && QuesMngrEngagementReportObject[item.filters[0].level].findIndex(prev => prev.name === item.name) === -1) {
                                        QuesMngrEngagementReportObject[item.filters[0].level].push(item);
                                    }
                                    else {
                                        if (QuesMngrEngagementReportObject && QuesMngrEngagementReportObject[item.filters[0].level] && QuesMngrEngagementReportObject[item.filters[0].level].findIndex(prev => prev.name === item.name)) {
                                            let getIndex = QuesMngrEngagementReportObject[item.filters[0].level].findIndex(prev => prev.name === item.name);
                                            QuesMngrEngagementReportObject[item.filters[0].level][getIndex] = item;
                                        }
                                    }
                                }
                                )

                            }
                        }

                    }
                    if (type === 5) {
                        if (dashboard && dashboard.getQuestionManagerOfEngagementData) {

                            dashboard.getQuestionManagerOfEngagementData.forEach((item) => {



                                if (QuesDynaObj && QuesDynaObj[item.filters[0].level] && QuesDynaObj[item.filters[0].level].findIndex(prev => prev.name === item.name) === -1) {
                                    QuesDynaObj[item.filters[0].level].push(item);
                                }
                                else {
                                    if (QuesDynaObj && QuesDynaObj[item.filters[0].level] && QuesDynaObj[item.filters[0].level].findIndex(prev => prev.name === item.name)) {
                                        let getIndex = QuesDynaObj[item.filters[0].level].findIndex(prev => prev.name === item.name);
                                        QuesDynaObj[item.filters[0].level][getIndex] = item;
                                    }

                                }

                            }
                            )

                        }
                    }


                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_ENGAGEMENT_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_ENGAGEMENT_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_ENGAGEMENT_FAILURE, error } }
}

function getQuestionManagerOfStress(data, type, QuesDynaObj, isReport, QuesMngrStressReportObject) {
    let Obj = {
        getQuestionManagerOfStressData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getQuestionManagerOfStress(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    ////console.log('QuesDynaObj: ',QuesDynaObj)
                    if (true) {
                        if (dashboard && dashboard.getQuestionManagerOfStressData) {

                            if (dashboard && dashboard.getQuestionManagerOfStressData) {

                                dashboard.getQuestionManagerOfStressData.forEach((item) => {
                                    if (QuesMngrStressReportObject && QuesMngrStressReportObject[item.filters[0].level] && QuesMngrStressReportObject[item.filters[0].level].findIndex(prev => prev.name === item.name) === -1) {
                                        QuesMngrStressReportObject[item.filters[0].level].push(item);
                                    }
                                    else {
                                        if (QuesMngrStressReportObject && QuesMngrStressReportObject[item.filters[0].level] && QuesMngrStressReportObject[item.filters[0].level].findIndex(prev => prev.name === item.name)) {
                                            let getIndex = QuesMngrStressReportObject[item.filters[0].level].findIndex(prev => prev.name === item.name);
                                            QuesMngrStressReportObject[item.filters[0].level][getIndex] = item;
                                        }

                                    }
                                }
                                )

                            }
                        }

                    }
                    if (type === 5) {
                        if (dashboard && dashboard.getQuestionManagerOfStressData) {

                            dashboard.getQuestionManagerOfStressData.forEach((item) => {
                                if (QuesDynaObj && QuesDynaObj[item.filters[0].level] && QuesDynaObj[item.filters[0].level].findIndex(prev => prev.name === item.name) === -1) {
                                    QuesDynaObj[item.filters[0].level].push(item);
                                }
                                else {
                                    if (QuesDynaObj && QuesDynaObj[item.filters[0].level] && QuesDynaObj[item.filters[0].level].findIndex(prev => prev.name === item.name)) {
                                        let getIndex = QuesDynaObj[item.filters[0].level].findIndex(prev => prev.name === item.name);
                                        QuesDynaObj[item.filters[0].level][getIndex] = item;
                                    }

                                }
                            }
                            )

                        }
                    }
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_STRESS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_STRESS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_STRESS_FAILURE, error } }
}

function getQuestionManagerOfManager(data, type, QuesDynaObj, isReport, QuesMngrManagerReportObject) {
    let Obj = {
        getQuestionManagerOfManagerData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getQuestionManagerOfManager(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    ////console.log('QuesDynaObj: ',QuesDynaObj)
                    if (true) {
                        if (dashboard && dashboard.getQuestionManagerOfManagerData) {

                            if (dashboard && dashboard.getQuestionManagerOfManagerData) {

                                dashboard.getQuestionManagerOfManagerData.forEach((item) => {
                                    if (QuesMngrManagerReportObject && QuesMngrManagerReportObject[item.filters[0].level] && QuesMngrManagerReportObject[item.filters[0].level].findIndex(prev => prev.name === item.name) === -1) {
                                        QuesMngrManagerReportObject[item.filters[0].level].push(item);
                                    }
                                    else {
                                        if (QuesMngrManagerReportObject && QuesMngrManagerReportObject[item.filters[0].level] && QuesMngrManagerReportObject[item.filters[0].level].findIndex(prev => prev.name === item.name)) {
                                            let getIndex = QuesMngrManagerReportObject[item.filters[0].level].findIndex(prev => prev.name === item.name);
                                            QuesMngrManagerReportObject[item.filters[0].level][getIndex] = item;
                                        }

                                    }
                                }
                                )

                            }
                        }

                    }
                    if (type === 5) {
                        if (dashboard && dashboard.getQuestionManagerOfManagerData) {

                            dashboard.getQuestionManagerOfManagerData.forEach((item) => {
                                if (QuesDynaObj && QuesDynaObj[item.filters[0].level] && QuesDynaObj[item.filters[0].level].findIndex(prev => prev.name === item.name) === -1) {
                                    QuesDynaObj[item.filters[0].level].push(item);
                                }
                                else {
                                    if (QuesDynaObj && QuesDynaObj[item.filters[0].level] && QuesDynaObj[item.filters[0].level].findIndex(prev => prev.name === item.name)) {
                                        let getIndex = QuesDynaObj[item.filters[0].level].findIndex(prev => prev.name === item.name);
                                        QuesDynaObj[item.filters[0].level][getIndex] = item;
                                    }

                                }
                            }
                            )

                        }
                    }
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_MANAGER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_MANAGER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_MANAGER_FAILURE, error } }
}




function getIndexQuantile(data) {
    let obj = {
        getIndexQuantileData: [],
        getIndexQuantileCount: 0
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIndexQuantile(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_INDEX_QUANTILE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_INDEX_QUANTILE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_INDEX_QUANTILE_FAILURE, error } }
}


function getDemographicIndexScatter(data) {
    let obj = {
        getDemographicIndexScatterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getDemographicIndexScatter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_SCATTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_SCATTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_SCATTER_FAILURE, error } }
}


function getHappinessCorrelation(data) {
    let obj = {
        getHappinessCorrelationData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getHappinessCorrelation(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPPINESS_CORRELATION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPPINESS_CORRELATION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPPINESS_CORRELATION_FAILURE, error } }
}


function getHappinessCorrelationOverAll(data) {
    let obj = {
        getHappinessCorrelationData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getHappinessCorrelationOverAll(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPPINESS_CORRELATION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPPINESS_CORRELATION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPPINESS_CORRELATION_FAILURE, error } }
}



function getEngagementCorrelation(data) {
    let obj = {
        getEngagementCorrelationData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getEngagementCorrelation(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_CORRELATION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_CORRELATION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_CORRELATION_FAILURE, error } }
}

function getEngagementCorrelationOverAll(data) {
    let obj = {
        getEngagementCorrelationData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getEngagementCorrelationOverAll(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_CORRELATION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_CORRELATION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_CORRELATION_FAILURE, error } }
}



function getStressCorrelation(data) {
    let obj = {
        getStressCorrelationData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getStressCorrelation(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_CORRELATION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_CORRELATION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_CORRELATION_FAILURE, error } }
}


function getStressCorrelationOverAll(data) {
    let obj = {
        getStressCorrelationData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getStressCorrelationOverAll(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_CORRELATION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_CORRELATION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_CORRELATION_FAILURE, error } }
}



function getManagerCorrelation(data) {
    let obj = {
        getManagerCorrelationData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getManagerCorrelation(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_CORRELATION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_CORRELATION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_CORRELATION_FAILURE, error } }
}

function getManagerCorrelationOverAll(data) {
    let obj = {
        getManagerCorrelationData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getManagerCorrelationOverAll(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_CORRELATION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_CORRELATION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_CORRELATION_FAILURE, error } }
}




function getLeaderCorrelation(data) {
    let obj = {
        getLeaderCorrelationData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getLeaderCorrelation(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADER_CORRELATION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADER_CORRELATION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADER_CORRELATION_FAILURE, error } }
}

function getLeaderCorrelationOverAll(data) {
    let obj = {
        getLeaderCorrelationData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getLeaderCorrelationOverAll(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADER_CORRELATION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADER_CORRELATION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADER_CORRELATION_FAILURE, error } }
}



function getRWAnalysis(data) {
    let obj = {
        getRWAnalysisData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getRWAnalysis(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_RWA_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_RWA_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_RWA_FAILURE, error } }
}



function getNameQuestion(data, check, data2) {
    let obj = {
        getNameQuestionData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getNameQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if (check) {
                        if (dashboard.getNameQuestionData && dashboard.getNameQuestionData.length > 0) {
                            data2["questionId"] = dashboard.getNameQuestionData[0].id;
                            if (check === 1) {
                                dispatch(this.getIndexVsNps(data2));
                            } else if (check === 2) {
                                dispatch(this.driverVsNpsRWA(data2));
                            }

                        }
                    }

                },
                error => {
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: dashboardConstants.GET_NAME_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_NAME_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_NAME_QUESTION_FAILURE, error } }

}




function getTextQuestion(data, check, filer, xAxis) {
    let obj = {
        getTextQuestionData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getTextQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));


                    if (dashboard.getTextQuestionData && dashboard.getTextQuestionData.length > 0) {
                        let data2 = {
                            "surveyId": data.surveyId,
                            "questionId": dashboard.getTextQuestionData[0].id,
                            "filters": filer && filer.length > 0 ? filer : []
                        }

                        let templateId = dashboard.getTextQuestionData[0].templateId;


                        if (check === 1) {

                            if (templateId === 23) {
                                dispatch(this.getAllFeedbackWords(data2, check, xAxis));
                            } else {
                                dispatch(this.wordCloud(data2));
                            }

                        }
                        else if (check === 2) {
                            if (templateId === 23) {
                                dispatch(this.getAllFeedbackWords(data2, check, xAxis));
                            } else {
                                dispatch(this.cooccurmatrix(data2));
                            }
                        }
                        else if (check === 3) {
                            if (templateId === 23) {
                                dispatch(this.getAllFeedbackWords(data2, check, xAxis));
                            } else {
                                dispatch(this.neighborGraph(data2));
                            }
                        }
                        else if (check === 4) {
                            dispatch(this.AdvanceWordCloud(data2));
                        }
                        else if (check === 5) {
                            if (templateId === 23) {
                                dispatch(this.getAllFeedbackWords(data2, check, xAxis));
                            } else {
                                dispatch(this.textDist(data2));
                                let data2X1 = {
                                    "surveyId": data.surveyId,
                                    "questionId": dashboard.getTextQuestionData[0].id,
                                    "xAxis": xAxis
                                }
                                dispatch(this.textDistTreeMap(data2X1));
                            }

                        }
                        else if (check === 6) {
                            if (templateId === 23) {
                                dispatch(this.getAllFeedbackWords(data2, check, xAxis));
                            } else {
                                let data3 = {
                                    "surveyId": data.surveyId,
                                    "questionId": dashboard.getTextQuestionData[0].id,
                                    "xAxis": xAxis
                                }
                                dispatch(this.TreeMap(data3));
                            }
                        }
                        else if (check === 111) {

                            let FilteredObj = {
                                getQuestionCommentsData: []
                            };

                            if (dashboard.getTextQuestionData && dashboard.getTextQuestionData.length > 0) {
                                dashboard.getTextQuestionData.forEach((ques, index2) => {
                                    let item = {
                                        "surveyId": data.surveyId,
                                        "questionId": ques.id,
                                        "filters": filer && filer.length > 0 ? filer : []
                                    };


                                    dashboardService.wordCloud(item)
                                        .then(
                                            dashboardx1 => {
                                                if (dashboardx1 && dashboardx1.wordCloudData) {
                                                    let List = dashboardx1.wordCloudData.list;
                                                    if (!(List && List.length > 0)) {
                                                        dispatch(this.EmptyWordCloudText());
                                                    }
                                                    List.sort((a, b) => b[0] - a[0])
                                                    if (List && List.length > 0 && List.length >= 5) {
                                                        let KeyWord = List[0][0];

                                                        let data3 = {
                                                            ...item,
                                                            "keyWord": KeyWord
                                                        }


                                                        dashboardService.wordCloudText(data3)
                                                            .then(
                                                                dashboardx2 => {
                                                                    if (dashboardx2 && dashboardx2.wordCloudTextData) {


                                                                        FilteredObj['getQuestionCommentsData'].push({
                                                                            "questionId": ques.id,
                                                                            "questionName": ques.name,
                                                                            "Data": dashboardx2.wordCloudTextData
                                                                        });
                                                                    }

                                                                    if ((index2 + 1) === dashboard.getTextQuestionData.length) {
                                                                        dispatch(success2(FilteredObj))
                                                                    }

                                                                },
                                                                error => {
                                                                    // dispatch(failure(error))
                                                                }
                                                            );

                                                    }



                                                }
                                            },
                                            error => {
                                                // dispatch(failure(error))
                                            }
                                        );








                                })
                            }

                        }
                        else if (check === 7) {
                            if (templateId === 23) {
                                dispatch(this.getAllFeedbackWords(data2, check, xAxis));
                            } else {
                                dispatch(this.wordCloud(data2, true));
                            }
                        } else if (check === 8) {
                            if (templateId === 23) {
                                dispatch(this.getAllFeedbackWords(data2, check, xAxis));
                            } else {
                                dispatch(this.getOrgCoreDriverSentiment(data2));
                            }
                        }
                        else if (check === 9) {
                            if (templateId === 23) {
                                dispatch(this.getAllFeedbackWords(data2, check, xAxis));
                            } else {
                                dispatch(this.getTextCluster(data2));
                                dispatch(this.getTextClusterSentimentCount(data2));
                            }
                        }
                        else if (check === 10) {
                            if (templateId === 23) {
                                dispatch(this.getAllFeedbackWords(data2, check, xAxis));
                            } else {
                                dispatch(this.topicCloud(data2));
                            }
                        }


                    }


                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_TEXT_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_TEXT_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_TEXT_QUESTION_FAILURE, error } }

    function success2(dashboard) { return { type: dashboardConstants.GET_QUESTION_COMMENT_SUCCESS, dashboard } }

}

function getFeedbackTextQuestion(data, filer) {
    let obj = {
        getFeedbackTextQuestionData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getFeedbackTextQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                    if (dashboard.getFeedbackTextQuestionData && dashboard.getFeedbackTextQuestionData.length > 0) {
                        let data2 = {
                            "surveyId": data.surveyId,
                            "questionId": dashboard.getFeedbackTextQuestionData[0].id,
                            "filters": filer && filer.length > 0 ? filer : []
                        }

                        dispatch(this.getOrgCoreDriverSentiment(data2));
                    }

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_FEEDBACK_TEXT_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_FEEDBACK_TEXT_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_FEEDBACK_TEXT_QUESTION_FAILURE, error } }
}



function textDist(data) {
    let obj = {
        textDistData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.textDist(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.TEXT_DIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.TEXT_DIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.TEXT_DIST_FAILURE, error } }
}




function wordCloud(data, type) {
    let obj = {
        wordCloudData: []
    }

    return dispatch => {
        dispatch(this.EmptyWordCloudText());
        dispatch(success(obj));
        dispatch(request());
        dashboardService.wordCloud(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                    if (dashboard && dashboard.wordCloudData && dashboard.wordCloudData.list.length > 0) {
                        let List = dashboard.wordCloudData.list;

                        if (!(List && List.length > 0)) {
                            dispatch(this.EmptyWordCloudText());
                        }

                        if (type) {
                            let keyWorkList = []
                            if (List && List.length > 0 && List.length >= 5) {
                                List.forEach((item, index) => {
                                    if (index < 2) {
                                        let KeyWord = item[0];
                                        keyWorkList.push({
                                            "topic": KeyWord,
                                            "keywordList": [],
                                            "isCustom": false
                                        });
                                    }

                                });
                            }

                            dispatch(this.topicModelling({
                                ...data,
                                "wordList": keyWorkList
                            }))

                        }
                        else {
                            List.sort((a, b) => b[0] - a[0]);
                            if (List && List.length > 0) {
                                let KeyWord = List[0][0];

                                let data3 = {
                                    ...data,
                                    "keyWord": KeyWord
                                }


                                dispatch(this.wordCloudText(data3))

                            }
                        }





                    }

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.WORD_CLOUD_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.WORD_CLOUD_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.WORD_CLOUD_FAILURE, error } }
}


function getAllFeedbackWords(data, check, xAxis) {
    let obj = {
        getAllFeedbackWordsData: []
    }

    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getAllFeedbackWords(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if (dashboard && dashboard.getAllFeedbackWordsData && dashboard.getAllFeedbackWordsData.length > 0) {
                        function SortList(list) {
                            let temp = [];
                            if (list && list.length > 0) {
                                temp = list.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
                            }
                            return temp;
                        }

                        let initWord = SortList(dashboard.getAllFeedbackWordsData)[0];
                        console.log("initWord----------->", initWord)
                        let reqData = {
                            "surveyId": data.surveyId,
                            "questionId": data.questionId,
                            "filters": data.filters,
                            "dmgValue": SortList(dashboard.getAllFeedbackWordsData)[0]
                        }
                        if (check === 1) {
                            dispatch(this.wordCloud(reqData));
                        } else if (check === 2) {
                            dispatch(this.cooccurmatrix(reqData));
                        } else if (check === 3) {
                            dispatch(this.neighborGraph(reqData));
                        } else if (check === 4) {
                            dispatch(this.neighborGraph(reqData));
                        } else if (check === 5) {
                            dispatch(this.textDist(reqData));
                            let data2X1 = {
                                "surveyId": data.surveyId,
                                "questionId": data.questionId,
                                "xAxis": xAxis,
                                "dmgValue": dashboard.getAllFeedbackWordsData[0]
                            }
                            dispatch(this.textDistTreeMap(data2X1));
                        } else if (check === 6) {
                            let data3 = {
                                "surveyId": data.surveyId,
                                "questionId": data.questionId,
                                "xAxis": xAxis,
                                "dmgValue": dashboard.getAllFeedbackWordsData[0]
                            }
                            dispatch(this.TreeMap(data3));
                        }
                        else if (check === 7) {
                            dispatch(this.wordCloud(reqData, true));
                        } else if (check === 8) {
                            dispatch(this.getOrgCoreDriverSentiment(reqData));
                        }
                        else if (check === 9) {
                            dispatch(this.getTextCluster(reqData));
                            dispatch(this.getTextClusterSentimentCount(reqData));
                        }
                        else if (check === 10) {
                            dispatch(this.topicCloud(reqData));
                        }

                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ALL_FEEDBACK_WORDS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ALL_FEEDBACK_WORDS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ALL_FEEDBACK_WORDS_FAILURE, error } }
}


function topicCloud(data, type) {
    let obj = {
        topicCloudData: []
    }

    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.topicCloud(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.TOPIC_CLOUD_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.TOPIC_CLOUD_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.TOPIC_CLOUD_FAILURE, error } }
}




function textcluster(data) {
    let obj = {
        textclusterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.textcluster(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.TEXT_CLUSTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.TEXT_CLUSTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.TEXT_CLUSTER_FAILURE, error } }
}

function AdvanceWordCloud(data) {
    let obj = {
        AdvanceWordCloudData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.AdvanceWordCloud(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                    if (dashboard && dashboard.AdvanceWordCloudData && dashboard.AdvanceWordCloudData.list.length > 0) {
                        let List = dashboard.AdvanceWordCloudData.list;
                        List.sort((a, b) => b[0] - a[0])
                        if (List && List.length > 0) {
                            let KeyWord = List[0][0];

                            let data3 = {
                                ...data,
                                "keyWord": KeyWord
                            }


                            dispatch(this.wordCloudText(data3))

                        }
                    }

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.ADVANCE_WORD_CLOUD_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.ADVANCE_WORD_CLOUD_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.ADVANCE_WORD_CLOUD_FAILURE, error } }
}



// function wordCloudText(data) {
//     let obj = {
//         wordCloudTextData:[],
//         wordTextKeyWord:null
//     }
//     return dispatch => {
//         dispatch(success(obj));
//         dispatch(request());
//         dashboardService.wordCloudText(data)
//             .then(
//                 dashboard => {
//                     dispatch(success(dashboard));
//                  },
//                 error => {
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: dashboardConstants.WORD_CLOUD_TEXT_REQUEST } }
//     function success(dashboard) { return { type: dashboardConstants.WORD_CLOUD_TEXT_SUCCESS, dashboard } }
//     function failure(error) { return { type: dashboardConstants.WORD_CLOUD_TEXT_FAILURE, error } }
// }


function EmptyWordCloudText() {
    let obj = {
        wordCloudTextData: [],
        wordTextKeyWord: null
    }
    return dispatch => {
        dispatch(success(obj));
    };
    function success(dashboard) { return { type: dashboardConstants.WORD_CLOUD_TEXT_SUCCESS, dashboard } }
}
function wordCloudText(data) {
    let obj = {
        wordCloudTextData: [],
        wordTextKeyWord: null
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.wordCloudText(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.WORD_CLOUD_TEXT_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.WORD_CLOUD_TEXT_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.WORD_CLOUD_TEXT_FAILURE, error } }
}



function wordCloudMultiText(data) {
    let obj = {
        wordCloudMultiTextData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.wordCloudMultiText(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.WORD_CLOUD_MULTI_TEXT_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.WORD_CLOUD_MULTI_TEXT_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.WORD_CLOUD_MULTI_TEXT_FAILURE, error } }
}

function cooccurmatrix(data) {
    let obj = {
        cooccurmatrixData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.cooccurmatrix(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.COOCCUR_MATRIX_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.COOCCUR_MATRIX_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.COOCCUR_MATRIX_FAILURE, error } }
}

function neighborGraph(data) {
    let obj = {
        neighborGraphData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.neighborGraph(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    // console.log("dashboard.neighborGraphData-->",dashboard.neighborGraphData)
                    let objText = dashboard && dashboard.neighborGraphData && dashboard.neighborGraphData && dashboard.neighborGraphData.list ? dashboard.neighborGraphData.list : {}
                    let objCount = dashboard && dashboard.neighborGraphData && dashboard.neighborGraphData && dashboard.neighborGraphData.count ? dashboard.neighborGraphData.count : 0

                    // console.log("objText",objText)
                    // console.log("objCount",objCount)
                    let flag = 0
                    if (objCount >= 5) {
                        let firstKeyword = Object.keys(objText) && Object.keys(objText)[0] ? Object.keys(objText)[0] : "";

                        if (firstKeyword) {
                            flag = 0
                            let data3 = {
                                ...data,
                                "keyWord": firstKeyword
                            }
                            // console.log("firstKeyword",firstKeyword)

                            dispatch(this.wordCloudText(data3))
                        } else {
                            flag = 1
                        }


                    } else {
                        flag = 1
                    }
                    if (flag === 1) {
                        dispatch(this.EmptyWordCloudText());
                    }

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.NEIGHBOR_GRAPH_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.NEIGHBOR_GRAPH_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.NEIGHBOR_GRAPH_FAILURE, error } }

}


function getHappinessRWA(data) {
    let obj = {
        getHappinessRWAData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getEngagementRWA(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPINESS_RWA_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPINESS_RWA_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPINESS_RWA_FAILURE, error } }
}


function getEngagementRWA(data) {
    let obj = {
        getEngagementRWAData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getEngagementRWA(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_RWA_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_RWA_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_RWA_FAILURE, error } }
}

function getStressRWA(data) {
    let obj = {
        getStressRWAData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getEngagementRWA(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_RWA_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_RWA_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_RWA_FAILURE, error } }
}

function getManagerRWA(data) {
    let obj = {
        getManagerRWAData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getEngagementRWA(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_RWA_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_RWA_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_RWA_FAILURE, error } }
}

function getLeaderRWA(data) {
    let obj = {
        getLeaderRWAData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getEngagementRWA(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADER_RWA_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADER_RWA_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADER_RWA_FAILURE, error } }
}


function cronbatchAlphaVariance(data) {
    let ClearObj = {
        cronbatchAlphaVarianceData: {}
    }
    return dispatch => {
        dispatch(success2(ClearObj));
        dispatch(request());
        dashboardService.cronbatchAlphaVariance(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.CRONE_BATCH_ALPHA_VARIANCE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.CRONE_BATCH_ALPHA_VARIANCE_SUCCESS, dashboard } }
    function success2(dashboard) { return { type: dashboardConstants.CRONE_BATCH_ALPHA_VARIANCE_SUCCESS_TWO, dashboard } }
    function failure(error) { return { type: dashboardConstants.CRONE_BATCH_ALPHA_VARIANCE_FAILURE, error } }

}


function getQuestionFavorability(data) {
    let obj = {
        getQuestionFavorabilityData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getQuestionFavorability(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_FAVOURABILITY_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_FAVOURABILITY_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_FAVOURABILITY_FAILURE, error } }

}

function getQuestionFavorability2(data) {
    let obj = {
        getQuestionFavorability2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getQuestionFavorability2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_FAVOURABILITY_2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_FAVOURABILITY_2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_FAVOURABILITY_2_FAILURE, error } }

}

function getQuestionFavorabilityAPI2(data) {
    let obj = {
        getQuestionFavorabilityAPI2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getQuestionFavorability(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_FAVOURABILITY_API_2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_FAVOURABILITY_API_2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_FAVOURABILITY_API_2_FAILURE, error } }

}

function getQuestionFavorabilityAPI4(data) {
    let obj = {
        getQuestionFavorabilityAPI4Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getQuestionFavorability(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_FAVOURABILITY_API_4_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_FAVOURABILITY_API_4_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_FAVOURABILITY_API_4_FAILURE, error } }

}

function getHistoryIndex(data) {
    let obj = {
        getHistoryIndexData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getHistoryIndex(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HISTORY_INDEX_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HISTORY_INDEX_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HISTORY_INDEX_FAILURE, error } }

}



function getResponseAnalytics(data) {
    let obj = {
        getResponseAnalyticsData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getResponseAnalytics(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_RESPONSE_ANALYTIYCS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_RESPONSE_ANALYTIYCS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_RESPONSE_ANALYTIYCS_FAILURE, error } }

}

function getMetaResponseAnalyticsFilter(data) {
    let obj = {
        getMetaResponseAnalyticsFilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getMetaResponseAnalyticsFilter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_RESPONSE_META_ANALYTIYCS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_RESPONSE_META_ANALYTIYCS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_RESPONSE_META_ANALYTIYCS_FAILURE, error } }

}
function getMeta360ResponseAnalyticsFilter(data) {
    let obj = {
        getMeta360ResponseAnalyticsFilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getMeta360ResponseAnalyticsFilter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_RESPONSE_META_360_ANALYTIYCS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_RESPONSE_META_360_ANALYTIYCS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_RESPONSE_META_360_ANALYTIYCS_FAILURE, error } }

}


function getCustomerResponseAnalyticsFilter(data) {
    let obj = {
        getResponseAnalyticsData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getCustomerResponseAnalyticsFilter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_RESPONSE_CUST_V2_ANALYTIYCS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_RESPONSE_CUST_V2_ANALYTIYCS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_RESPONSE_CUST_V2_ANALYTIYCS_FAILURE, error } }

}



function getComment(data) {
    let obj = {
        getCommentData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getComment(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_COMMENT_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_COMMENT_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_COMMENT_FAILURE, error } }

}

function getMultiManagerQuestionList(data, mngr, ManagerListQuestionDataObj) {
    let obj = {
        getMultiManagerQuestionListData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getMultiManagerQuestionList(data, mngr)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if (dashboard && dashboard.getMultiManagerQuestionListData && dashboard.getMultiManagerQuestionListData.ManagerPID) {
                        let managerId = dashboard.getMultiManagerQuestionListData.ManagerPID;
                        let getIndex = ManagerListQuestionDataObj.findIndex(prev => prev.ManagerPID === managerId)
                        if (getIndex === -1) {
                            ManagerListQuestionDataObj.push(dashboard.getMultiManagerQuestionListData);
                        }
                        else {
                            ManagerListQuestionDataObj[getIndex] = dashboard.getMultiManagerQuestionListData;
                        }


                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MULTIMANAGER_QUESTIONLIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MULTIMANAGER_QUESTIONLIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MULTIMANAGER_QUESTIONLIST_FAILURE, error } }

}



//------------- multifilter ---------------------------

function getDemographicIndexMultiFilter(data) {
    let obj = {
        getDemographicIndexMultiFilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getDemographicIndexMultiFilter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_MULTI_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_MULTI_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_MULTI_FILTER_FAILURE, error } }

}

function getDemographicIndexMultiAxisFilter(data) {
    let obj = {
        getDemographicIndexMultiAxisFilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getDemographicIndexMultiAxisFilter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_MULTI_AXIS_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_MULTI_AXIS_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_MULTI_AXIS_FILTER_FAILURE, error } }

}



function getDemographicIndexFilterReport(data) {


    return dispatch => {
        dispatch(request());

        let FilteredObj = {
            getDemographicIndexFilterReportData: []
        };
        if (data && data.length > 0) {
            data.forEach((item) => {

                dashboardService.getDemographicIndexFilterReport(item)
                    .then(
                        dashboard => {
                            if (dashboard && dashboard.getDemographicIndexFilterReportData) {
                                FilteredObj['getDemographicIndexFilterReportData'].push({
                                    "xAxis": item.xAxis,
                                    "Data": dashboard.getDemographicIndexFilterReportData
                                });
                            }
                        },
                        error => {
                            // dispatch(failure(error))
                        }
                    );


            });
        }
        dispatch(success(FilteredObj))
    };



    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_REPORT_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_REPORT_FILTER_SUCCESS, dashboard } }
    // function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_REPORT_FILTER_FAILURE, error } }

}


function getDemographicIndexMultiManyFilter(data) {


    return async dispatch => {
        dispatch(request());

        let FilteredObj = {
            getDemographicIndexMultiManyFilterData: []
        };

        function getLoop(data1, FilteredObj1) {
            if (data1 && data1.length > 0) {
                for (var i = 0; i < data1.length; i++) {
                    let item = data1[i];
                    // tempList.forEach((item)=>{

                    dashboardService.getDemographicIndexMultiManyFilter(item)
                        .then(
                            dashboard => {
                                if (dashboard && dashboard.getDemographicIndexMultiManyFilterData) {
                                    FilteredObj1['getDemographicIndexMultiManyFilterData'].push({
                                        "xAxis": item.xAxis,
                                        "Data": dashboard.getDemographicIndexMultiManyFilterData
                                    });
                                }
                            },
                            error => {
                                // dispatch(failure(error))
                            }
                        );

                    // });


                }

            }
            return JSON.parse(JSON.stringify(FilteredObj1))
        }

        let finalList = await getLoop(data, FilteredObj)
        ////////console.log("finalList------>",finalList)
        dispatch(success(FilteredObj))
    };



    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANY_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANY_SUCCESS, dashboard } }
    // function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANY_FAILURE, error } }

}

function getDemographicIndexOrgManyFilter(data, driver, type) {


    return async dispatch => {
        dispatch(request());

        let FilteredObj = {
            getDemographicIndexOrgManyFilterData: []
        };

        function getLoop(data1, FilteredObj1) {
            if (data1 && data1.length > 0) {
                for (var i = 0; i < data1.length; i++) {
                    let item = data1[i];
                    // tempList.forEach((item)=>{

                    dashboardService.getDemographicIndexOrgManyFilter(item)
                        .then(
                            dashboard => {
                                if (dashboard && dashboard.getDemographicIndexOrgManyFilterData) {
                                    FilteredObj1['getDemographicIndexOrgManyFilterData'].push({
                                        "xAxis": item.xAxis,
                                        "driver": driver,
                                        "type": type,
                                        "Data": dashboard.getDemographicIndexOrgManyFilterData
                                    });
                                }
                            },
                            error => {
                                // dispatch(failure(error))
                            }
                        );

                    // });


                }

            }
            return JSON.parse(JSON.stringify(FilteredObj1))
        }

        let finalList = await getLoop(data, FilteredObj)
        ////////console.log("finalList------>",finalList)
        dispatch(success(FilteredObj))
    };



    function request() { return { type: dashboardConstants.GET_ORG_DEMOGRAPHIC_INDEX_MANY_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ORG_DEMOGRAPHIC_INDEX_MANY_SUCCESS, dashboard } }
    // function failure(error) { return { type: dashboardConstants.GET_ORG_DEMOGRAPHIC_INDEX_MANY_FAILURE, error } }

}


function AssignManagerActionPlan(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.AssignManagerActionPlan(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    dispatch(this.getClientProfile({}));
                    // dispatch(this.getManagerActionPlan({surveyId:data.surveyId}));
                    dispatch(this.getAllManagerActionPlan({ surveyId: data.surveyId }));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.ASSIGN_MANAGER_ACTION_PLAN_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.ASSIGN_MANAGER_ACTION_PLAN_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.ASSIGN_MANAGER_ACTION_PLAN_FAILURE, error } }
}


function getManagerActionPlan(data) {
    let obj = {
        getManagerActionPlanData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getManagerActionPlan(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_ACTION_PLAN_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_ACTION_PLAN_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_ACTION_PLAN_FAILURE, error } }
}

function getEmployeeActionPlan(data) {
    let obj = {
        getEmployeeActionPlanData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getEmployeeActionPlan(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_EMPLOYEE_ACTION_PLAN_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_EMPLOYEE_ACTION_PLAN_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_EMPLOYEE_ACTION_PLAN_FAILURE, error } }
}



function emptyManagerActionPlan() {
    let obj = {
        getManagerActionPlanDat: []
    }
    return dispatch => {
        dispatch(success(obj));
    };
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_ACTION_PLAN_SUCCESS, dashboard } }
}


function updateEmployeeTask(data, type) {
    let obj = {
        updateEmployeeTaskData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.updateEmployeeTask(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if (type === 2) {
                        dispatch(this.getEmployeeActionPlan());
                    } else {
                        dispatch(this.getManagerActionPlan());
                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.UPDATE_EMPLOYEE_TASK_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.UPDATE_EMPLOYEE_TASK_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.UPDATE_EMPLOYEE_TASK_FAILURE, error } }
}


function updateEmployeeResource(data) {
    let obj = {
        updateEmployeeResourceData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.updateEmployeeResource(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    dispatch(this.getManagerActionPlan({ surveyId: data.surveyId }));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.UPDATE_EMPLOYEE_RESOURCE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.UPDATE_EMPLOYEE_RESOURCE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.UPDATE_EMPLOYEE_RESOURCE_FAILURE, error } }
}


function getAllManagerActionPlan(data) {
    let obj = {
        getAllManagerActionPlanData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getAllManagerActionPlan(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ALL_MANAGER_ACTION_PLAN_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ALL_MANAGER_ACTION_PLAN_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ALL_MANAGER_ACTION_PLAN_FAILURE, error } }
}


function crossTab(data) {
    let obj = {
        crossTabData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.crossTab(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.CROSS_TAB_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.CROSS_TAB_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.CROSS_TAB_FAILURE, error } }
}

function emptycrossTabAnalysis() {
    let obj = {
        "crossTabAnalysisData": []
    }
    return dispatch => {
        dispatch(request());
        dispatch(success(obj));
    };
    function request() { return { type: dashboardConstants.CROSS_TAB_ANALYSIS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.CROSS_TAB_ANALYSIS_SUCCESS, dashboard } }
    // function failure(error) { return { type: dashboardConstants.CROSS_TAB_FAILURE, error } }

}



function crossTabDMG(data) {
    let obj = {
        crossTabDMGData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.crossTabDMG(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.CROSS_TAB_DMG_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.CROSS_TAB_DMG_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.CROSS_TAB_DMG_FAILURE, error } }
}


function crossTabAnalysis(data) {
    let obj = {
        crossTabAnalysisData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.crossTabAnalysis(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.CROSS_TAB_ANALYSIS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.CROSS_TAB_ANALYSIS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.CROSS_TAB_ANALYSIS_FAILURE, error } }
}


function getAllParameterList(data) {
    let obj = {
        getAllParameterListData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getAllParameterList(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ALL_PARAMETER_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ALL_PARAMETER_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ALL_PARAMETER_LIST_FAILURE, error } }
}


function getCorrelationDimenionVsDimension(data) {
    let obj = {
        getCorrelationDimenionVsDimensionData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getCorrelationDimenionVsDimension(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_CORRELATION_OVERALL_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_CORRELATION_OVERALL_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_CORRELATION_OVERALL_FAILURE, error } }
}

function getCorrelationDimensionVsDimensionFilter(data) {
    let obj = {
        getCorrelationDimensionVsDimensionFilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getCorrelationDimensionVsDimensionFilter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_CORRELATION_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_CORRELATION_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_CORRELATION_FILTER_FAILURE, error } }
}



function getCorrelationQuestionType2(data) {
    let obj = {
        getCorrelationQuestionType2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getCorrelationQuestionType2(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.CORRELATION_TYPE2_ANALYSIS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.CORRELATION_TYPE2_ANALYSIS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.CORRELATION_TYPE2_ANALYSIS_FAILURE, error } }
}



function getDemographicOrgReport(data) {
    let obj = {
        getDemographicOrgReportData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());

        let FilteredObj = {
            getDemographicOrgReportData: []
        };
        if (data && data.length > 0) {
            data.forEach((item) => {

                dashboardService.getDemographicOrgReport(item)
                    .then(
                        dashboard => {
                            if (dashboard && dashboard.getDemographicOrgReportData) {
                                FilteredObj['getDemographicOrgReportData'].push({
                                    "xAxis": item.xAxis,
                                    "Data": dashboard.getDemographicOrgReportData
                                });
                            }
                        },
                        error => {
                            // dispatch(failure(error))
                        }
                    );


            });
        }
        dispatch(success(FilteredObj))
    };



    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_ORG_REPORT_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_ORG_REPORT_SUCCESS, dashboard } }
    // function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_ORG_REPORT_FAILURE, error } }
}
function getDemographicOrgReport2(data) {
    let obj = {
        getDemographicOrgReport2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());

        let FilteredObj = {
            getDemographicOrgReport2Data: []
        };
        if (data && data.length > 0) {
            data.forEach((item) => {

                dashboardService.getDemographicOrgReport(item)
                    .then(
                        dashboard => {
                            if (dashboard && dashboard.getDemographicOrgReport2Data) {
                                FilteredObj['getDemographicOrgReport2Data'].push({
                                    "xAxis": item.xAxis,
                                    "Data": dashboard.getDemographicOrgReportData
                                });
                            }
                        },
                        error => {
                            // dispatch(failure(error))
                        }
                    );


            });
        }
        dispatch(success(FilteredObj))
    };



    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_ORG_REPORT_2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_ORG_REPORT_2_SUCCESS, dashboard } }
    // function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_ORG_REPORT_2_FAILURE, error } }
}


function EmptyTreeMap(data) {
    let obj = {
        TreeMapData: []
    }
    let obj2 = {
        wordCloudMultiTextData: []
    }
    return dispatch => {
        dispatch(success2(obj2))
        dispatch(success(obj))

    };
    function success(dashboard) { return { type: dashboardConstants.TREE_MAP_SUCCESS, dashboard } }
    function success2(dashboard) { return { type: dashboardConstants.WORD_CLOUD_MULTI_TEXT_SUCCESS, dashboard } }

}



function TreeMap(data) {
    let obj = {
        TreeMapData: []
    }
    let obj2 = {
        wordCloudMultiTextData: []
    }
    return dispatch => {
        dispatch(success2(obj2))
        dispatch(success(obj))
        dispatch(request());
        dashboardService.TreeMap(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.TREE_MAP_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.TREE_MAP_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.TREE_MAP_FAILURE, error } }

    function success2(dashboard) { return { type: dashboardConstants.WORD_CLOUD_MULTI_TEXT_SUCCESS, dashboard } }

}



function textDistTreeMap(data) {
    let obj = {
        textDistTreeMapData: []
    }
    let obj2 = {
        wordCloudMultiTextData: []
    }
    return dispatch => {
        dispatch(success2(obj2))
        dispatch(success(obj))
        dispatch(request());
        dashboardService.textDistTreeMap(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.TREE_MAP_LEN_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.TREE_MAP_LEN_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.TREE_MAP_LEN_FAILURE, error } }

    function success2(dashboard) { return { type: dashboardConstants.WORD_CLOUD_MULTI_TEXT_SUCCESS, dashboard } }

}















function getManagerProfile() {
    let obj = {
        ManagerProfileData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getManagerProfile()
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_PROFILE_DATA_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_PROFILE_DATA_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_PROFILE_DATA_FAILURE, error } }
}


function getMNGRSurveyList() {
    let obj = {
        getMNGRSurveyListData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getMNGRSurveyList()
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_SURVEY_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_SURVEY_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_SURVEY_LIST_FAILURE, error } }
}





function getHappinessIndexByFilter(data) {
    let obj = {
        getHappinessIndexByFilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIndexFilter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPPINESS_INDEX_BY_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_BY_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_BY_FILTER_FAILURE, error } }
}

function getEngagementIndexByFilter(data) {
    let obj = {
        getEngagementIndexByFilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIndexFilter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_BY_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_BY_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_BY_FILTER_FAILURE, error } }
}

function getStressIndexByFilter(data) {
    let obj = {
        getStressIndexByFilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIndexFilter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_INDEX_BY_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_INDEX_BY_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_INDEX_BY_FILTER_FAILURE, error } }
}

function getManagerIndexByFilter(data) {
    let obj = {
        getManagerIndexByFilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIndexFilter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_INDEX_BY_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_INDEX_BY_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_INDEX_BY_FILTER_FAILURE, error } }
}


function getLeaderIndexByFilter(data) {
    let obj = {
        getLeaderIndexByFilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIndexFilter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADER_INDEX_BY_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADER_INDEX_BY_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADER_INDEX_BY_FILTER_FAILURE, error } }
}





function getDemographicIndexMultiFilterEngComp(data) {

    ////////console.log("(((((((((((((((((((---getDemographicIndexMultiFilterEngComp---",data)

    let obj = {
        getDemographicIndexMultiFilterEngCompData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());

        let FilteredObj = {
            getDemographicIndexMultiFilterEngCompData: []
        };
        if (data && data.length > 0) {
            data.forEach((item) => {

                dashboardService.getDemographicIndexMultiFilterEngComp(item)
                    .then(
                        dashboard => {
                            if (dashboard && dashboard.getDemographicIndexMultiFilterEngCompData) {
                                FilteredObj['getDemographicIndexMultiFilterEngCompData'].push({
                                    "xAxis": item.xAxis,
                                    "Data": dashboard.getDemographicIndexMultiFilterEngCompData
                                });
                            }
                        },
                        error => {
                            // dispatch(failure(error))
                        }
                    );


            });
        }
        dispatch(success(FilteredObj))
    };

    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_COMPARISON_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_COMPARISON_SUCCESS, dashboard } }
    // function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_COMPARISON_FAILURE, error } }
}

function getDemographicIndexMultiFilterEngComp2(data) {

    ////////console.log("(((((((((((((((((((---getDemographicIndexMultiFilterEngComp2---",data)

    let obj = {
        getDemographicIndexMultiFilterEngComp2Data: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());

        let FilteredObj = {
            getDemographicIndexMultiFilterEngComp2Data: []
        };
        if (data && data.length > 0) {
            data.forEach((item) => {

                dashboardService.getDemographicIndexMultiFilterEngComp2(item)
                    .then(
                        dashboard => {
                            if (dashboard && dashboard.getDemographicIndexMultiFilterEngComp2Data) {
                                FilteredObj['getDemographicIndexMultiFilterEngComp2Data'].push({
                                    "xAxis": item.xAxis,
                                    "Data": dashboard.getDemographicIndexMultiFilterEngComp2Data
                                });
                            }
                        },
                        error => {
                            // dispatch(failure(error))
                        }
                    );


            });
        }
        dispatch(success(FilteredObj))
    };

    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_COMPARISON_2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_COMPARISON_2_SUCCESS, dashboard } }
    // function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_COMPARISON_FAILURE, error } }
}


function getDemographicIndexEngCompOverall(data) {

    // ////////console.log("(((((((((((((((((((---getDemographicIndexEngCompOverall---",data)

    let obj = {
        getDemographicIndexEngCompOverallData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());

        let FilteredObj = {
            getDemographicIndexEngCompOverallData: []
        };
        if (data && data.length > 0) {
            data.forEach((item) => {

                dashboardService.getDemographicIndexEngCompOverall(item)
                    .then(
                        dashboard => {
                            if (dashboard && dashboard.getDemographicIndexEngCompOverallData) {
                                FilteredObj['getDemographicIndexEngCompOverallData'].push({
                                    "xAxis": item.xAxis,
                                    "Data": dashboard.getDemographicIndexEngCompOverallData
                                });
                            }
                        },
                        error => {
                            // dispatch(failure(error))
                        }
                    );


            });
        }
        dispatch(success(FilteredObj))
    };

    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_COMPARISON_OVERALL_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_COMPARISON_OVERALL_SUCCESS, dashboard } }
    // function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_COMPARISON_OVERALL_FAILURE, error } }
}


function getDemographicBranchCodeIndexMultiFilter(data) {
    let obj = {
        getDemographicBranchCodeIndexMultiFilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getDemographicBranchCodeIndexMultiFilter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_BRANCODE_INDEX_SCORE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_BRANCODE_INDEX_SCORE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_BRANCODE_INDEX_SCORE_FAILURE, error } }
}



function getParameterTypeList(data, check) {
    let obj = {
        getParameterTypeListData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getParameterTypeList(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if (check) {

                        let dataParaType = { "id": "" }
                        if (dashboard && dashboard.getParameterTypeListData && dashboard.getParameterTypeListData.list && dashboard.getParameterTypeListData.list.length > 0) {
                            let tempList = dashboard.getParameterTypeListData.list;
                            let getIndex = tempList.findIndex(prev => prev.level === 1);
                            dataParaType = tempList[getIndex];
                        }

                        dispatch(surveyActions.getParameterLibraryListbyParameterType({
                            "parameterTypeId": dataParaType.id
                        }));

                    }

                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_PARAMETER_TYPE_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_PARAMETER_TYPE_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_PARAMETER_TYPE_LIST_FAILURE, error } }
}


function createParameter(data, temp, isBucket) {
    return dispatch => {
        dispatch(request());
        dashboardService.createParameter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                    if (isBucket) {
                        dispatch(surveyActions.getParameterList(temp));
                    }
                    else {
                        dispatch(surveyActions.getParameterListbyParentId(temp));

                    }

                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.CREATE_PARAMETER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.CREATE_PARAMETER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.CREATE_PARAMETER_FAILURE, error } }
}







function getOrgIndexV2Filter(data) {
    let obj = {
        getOrgIndexV2FilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getOrgIndexV2Filter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ORG_INDEX_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ORG_INDEX_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ORG_INDEX_FILTER_FAILURE, error } }
}
function getOrgIndexV22Filter(data) {
    let obj = {
        getOrgIndexV22FilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getOrgIndexV2Filter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ORG_INDEX_FILTER_2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ORG_INDEX_FILTER_2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ORG_INDEX_FILTER_2_FAILURE, error } }
}





function getHappinessIndexV2Filter(data) {
    let obj = {
        getHappinessIndexV2FilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getHappinessIndexV2Filter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPINESS_INDEX_V2_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPINESS_INDEX_V2_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPINESS_INDEX_V2_FILTER_FAILURE, error } }
}



function getHappinessIndexV22Filter(data) {
    let obj = {
        getHappinessIndexV22FilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getHappinessIndexV2Filter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPINESS_INDEX_V2_2_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPINESS_INDEX_V2_2_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPINESS_INDEX_V2_2_FILTER_FAILURE, error } }
}



function getEngagementIndexV2Filter(data) {
    let obj = {
        getEngagementIndexV2FilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getEngagementIndexV2Filter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_FILTER_FAILURE, error } }
}
function getEngagementIndexV22Filter(data) {
    let obj = {
        getEngagementIndexV22FilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getEngagementIndexV2Filter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_2_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_2_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_2_FILTER_FAILURE, error } }
}



function getStressIndexV2Filter(data) {
    let obj = {
        getStressIndexV2FilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getStressIndexV2Filter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_INDEX_V2_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_INDEX_V2_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_INDEX_V2_FILTER_FAILURE, error } }
}
function getStressIndexV22Filter(data) {
    let obj = {
        getStressIndexV22FilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getStressIndexV2Filter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_INDEX_V2_2_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_INDEX_V2_2_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_INDEX_V2_2_FILTER_FAILURE, error } }
}




function getHappinessIndexDriverFilter(data) {
    let obj = {
        getHappinessIndexDriverFilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getHappinessIndexDriverFilter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_FILTER_FAILURE, error } }
}

function getHappinessIndexDriver2Filter(data) {
    let obj = {
        getHappinessIndexDriver2FilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getHappinessIndexDriverFilter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_2_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_2_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_2_FILTER_FAILURE, error } }
}


function getEngagementIndexDriverFilter(data) {
    let obj = {
        getEngagementIndexDriverFilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getEngagementIndexDriverFilter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_FAILURE, error } }
}


function getEngagementIndexDriver2Filter(data) {
    let obj = {
        getEngagementIndexDriver2FilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getEngagementIndexDriverFilter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENAGAGEMENT_INDEX_DRIVER_2_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENAGAGEMENT_INDEX_DRIVER_2_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENAGAGEMENT_INDEX_DRIVER_2_FILTER_FAILURE, error } }
}



function getStressIndexDriverFilter(data) {
    let obj = {
        getStressIndexDriverFilterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getStressIndexDriverFilter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_INDEX_DRIVER_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_INDEX_DRIVER_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_INDEX_DRIVER_FILTER_FAILURE, error } }
}





function getParameterLibraryList(data) {
    let obj = {
        getParameterLibraryListData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getParameterLibraryList(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_PARAMETER_LIBRARY_ALL_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_PARAMETER_LIBRARY_ALL_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_PARAMETER_LIBRARY_ALL_LIST_FAILURE, error } }
}





function clusterAnalysis(data) {
    let obj = {
        clusterAnalysisData: [],
        clusterAnalysisCount: 0
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.clusterAnalysis(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.CLUSTER_ANALYSIS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.CLUSTER_ANALYSIS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.CLUSTER_ANALYSIS_FAILURE, error } }
}



function emptyclusterAnalysis() {
    let obj = {
        clusterAnalysisData: [],
        clusterAnalysisCount: 0
    }
    return dispatch => {
        dispatch(success(obj));
    };
    function success(dashboard) { return { type: dashboardConstants.CLUSTER_ANALYSIS_SUCCESS, dashboard } }
}



function getClusterEmpInfoList(data) {
    let Obj = {
        getClusterEmpInfoListData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getClusterEmpInfoList(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_CLUSTER_EMP_INFO_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_CLUSTER_EMP_INFO_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_CLUSTER_EMP_INFO_LIST_FAILURE, error } }
}



function topicModelling(data) {
    let Obj = {
        topicModellingData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.topicModelling(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.TOPIC_MODELLING_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.TOPIC_MODELLING_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.TOPIC_MODELLING_FAILURE, error } }
}



function createTopic(data, temp) {
    return dispatch => {
        dispatch(request());
        dashboardService.createTopic(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    dispatch(this.getTopicList(temp));

                },

                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.CREATE_TOPIC_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.CREATE_TOPIC_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.CREATE_TOPIC_FAILURE, error } }
}



function updateTopic(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.updateTopic(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.UPDATE_TOPIC_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.UPDATE_TOPIC_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.UPDATE_TOPIC_FAILURE, error } }
}


function deleteTopic(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.deleteTopic(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.DELETE_TOPIC_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.DELETE_TOPIC_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.DELETE_TOPIC_FAILURE, error } }
}



function getTopicList(data) {
    let Obj = {
        getTopicListData: [],
        getTopicListTotal: 0
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getTopicList(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_TOPIC_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_TOPIC_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_TOPIC_LIST_FAILURE, error } }
}

function getAllWordKeywordsText(data, topic) {
    let Obj = {
        wordCloudTextData: [],
        wordTextKeyWord: null
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getAllWordKeywordsText(data, topic)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ALL_KEYWORDS_TEXT_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ALL_KEYWORDS_TEXT_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ALL_KEYWORDS_TEXT_LIST_FAILURE, error } }
}


function getOverallScoreBySurveyId(data) {
    let Obj = {
        getOverallScoreBySurveyIdData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getOverallScoreBySurveyId(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_OVERALL_SCORE_BY_SURVEY_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_OVERALL_SCORE_BY_SURVEY_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_OVERALL_SCORE_BY_SURVEY_FAILURE, error } }
}




function getAllManagerScores(data) {
    let Obj = {
        getAllManagerScoresData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getAllManagerScores(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ALL_MANAGER_SCORES_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ALL_MANAGER_SCORES_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ALL_MANAGER_SCORES_FAILURE, error } }
}



function createGoal(data, temp) {
    return dispatch => {
        dispatch(request());
        dashboardService.createGoal(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    dispatch(this.getGoalList(temp));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.CREATE_GOAL_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.CREATE_GOAL_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.CREATE_GOAL_FAILURE, error } }
}


function getGoalList(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getGoalList(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_GOAL_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_GOAL_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_GOAL_LIST_FAILURE, error } }
}


function updateGoal(data, temp) {
    return dispatch => {
        dispatch(request());
        dashboardService.updateGoal(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    dispatch(this.getGoalList(temp));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.UPDATE_GOAL_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.UPDATE_GOAL_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.UPDATE_GOAL_FAILURE, error } }
}


function deleteGoal(data, temp) {
    return dispatch => {
        dispatch(request());
        dashboardService.deleteGoal(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    dispatch(this.getGoalList(temp));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.DELETE_GOAL_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.DELETE_GOAL_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.DELETE_GOAL_FAILURE, error } }
}

function createPlan(data, temp) {
    return dispatch => {
        dispatch(request());
        dashboardService.createPlan(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    dispatch(this.getGoalList(temp));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.CREATE_PLAN_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.CREATE_PLAN_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.CREATE_PLAN_FAILURE, error } }
}


function updatePlan(data, temp) {
    return dispatch => {
        dispatch(request());
        dashboardService.updatePlan(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    dispatch(this.getGoalList(temp));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.UPDATE_PLAN_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.UPDATE_PLAN_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.UPDATE_PLAN_FAILURE, error } }
}

function deletePlan(data, temp) {
    return dispatch => {
        dispatch(request());
        dashboardService.deletePlan(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    dispatch(this.getGoalList(temp));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.DELETE_PLAN_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.DELETE_PLAN_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.DELETE_PLAN_FAILURE, error } }
}


function startPlan(data, temp) {
    let Obj = {
        closePlanData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.startPlan(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    dispatch(this.getGoalList(temp));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.START_PLAN_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.START_PLAN_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.START_PLAN_FAILURE, error } }
}



function closePlan(data, temp) {
    let Obj = {
        closePlanData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.closePlan(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    dispatch(this.getGoalList(temp));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.CLOSE_PLAN_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.CLOSE_PLAN_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.CLOSE_PLAN_FAILURE, error } }
}



function getAllManagerGoalPlan(data, temp) {
    let Obj = {
        getAllManagerGoalPlanData: [],
        getAllManagerGoalPlanTotal: 0
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getAllManagerGoalPlan(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ALL_MANAGER_GOAL_PLAN_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ALL_MANAGER_GOAL_PLAN_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ALL_MANAGER_GOAL_PLAN_FAILURE, error } }
}



function getIDPLastDate(data) {
    let Obj = {
        getIDPLastDateData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getIDPLastDate(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_IDP_LAST_DATE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_IDP_LAST_DATE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_IDP_LAST_DATE_FAILURE, error } }
}


function editIDPLastDate(data, temp) {
    let Obj = {
        editIDPLastDateData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.editIDPLastDate(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    dispatch(this.getIDPLastDate(temp));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.EDIT_IDP_LAST_DATE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.EDIT_IDP_LAST_DATE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.EDIT_IDP_LAST_DATE_FAILURE, error } }
}




function getCompanyNameOnly(data) {
    let Obj = {
        getCompanyNameOnlyData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getCompanyNameOnly(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_COMPANY_NAME_ONLY_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_COMPANY_NAME_ONLY_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_COMPANY_NAME_ONLY_FAILURE, error } }
}





function managerDownloadStatus(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.managerDownloadStatus(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_DOWNLOAD_STATUS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_DOWNLOAD_STATUS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_DOWNLOAD_STATUS_FAILURE, error } }
}




function getMngrReportDownloadStatus(data) {
    let Obj = {
        getMngrReportDownloadStatusData: [],
        getMngrReportDownloadStatusTotal: 0
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getMngrReportDownloadStatus(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ALL_MANAGER_DOWNLOAD_STATUS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ALL_MANAGER_DOWNLOAD_STATUS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ALL_MANAGER_DOWNLOAD_STATUS_FAILURE, error } }
}



function getIndexByFilterAllStages(data) {
    let Obj = {
        getIndexByFilterAllStagesData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getIndexByFilterAllStages(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_INDEX_BY_FILTER_ALL_STAGES_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_INDEX_BY_FILTER_ALL_STAGES_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_INDEX_BY_FILTER_ALL_STAGES_FAILURE, error } }
}





function getQuestionMap(data) {
    let Obj = {
        getQuestionMapData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getQuestionMap(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_MAP_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_MAP_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_MAP_FAILURE, error } }
}




function getQuestionCustomTemplate(data) {
    let Obj = {
        getQuestionCustomTemplateData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getQuestionCustomTemplate(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_CUSTOM_TEMPLATE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_CUSTOM_TEMPLATE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_CUSTOM_TEMPLATE_FAILURE, error } }
}




function getCategoryOverallIndex(data) {
    let Obj = {
        getCategoryOverallIndexData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getCategoryOverallIndex(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_CAT_OVERALL_INDEX_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_CAT_OVERALL_INDEX_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_CAT_OVERALL_INDEX_FAILURE, error } }
}



function getLifecycleListenById(data) {
    let Obj = {
        getLifecycleListenByIdData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getLifecycleListenById(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LIFECYCLE_LISTEN_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LIFECYCLE_LISTEN_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LIFECYCLE_LISTEN_FAILURE, error } }
}




function get360DimensionRelationScore(data) {
    let Obj = {
        get360DimensionRelationScoreData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.get360DimensionRelationScore(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_360_DIMEN_RELATION_SCORE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_360_DIMEN_RELATION_SCORE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_360_DIMEN_RELATION_SCORE_FAILURE, error } }
}



function get360DimensionQuestions(data) {
    let Obj = {
        get360DimensionQuestionsData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.get360DimensionQuestions(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_360_DIMEN_QUESTIONS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_360_DIMEN_QUESTIONS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_360_DIMEN_QUESTIONS_FAILURE, error } }
}



function getAnsweredEmployees(data) {
    let obj = {
        getAnsweredEmployeesData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getAnsweredEmployees(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ANSWERED_EMPLOYEES_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ANSWERED_EMPLOYEES_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ANSWERED_EMPLOYEES_FAILURE, error } }
}






function getOrgQuestion(data) {
    let Obj = {
        getOrgQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getOrgQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ORG_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ORG_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ORG_QUESTION_FAILURE, error } }
}
function getOrgManagerQuestion(data) {
    let Obj = {
        getOrgManagerQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getOrgQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ORG_MANAGER_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ORG_MANAGER_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ORG_MANAGER_QUESTION_FAILURE, error } }
}
function getOrgDMGQuestion(data) {
    let Obj = {
        getOrgDMGQuestionData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getOrgQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ORG_DMG_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ORG_DMG_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ORG_DMG_QUESTION_FAILURE, error } }
}



function getQuestionManagerPIDWise(data) {
    let Obj = {
        getQuestionManagerPIDWiseData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getQuestionManagerPIDWise(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUES_MNGR_MANAGERPID_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUES_MNGR_MANAGERPID_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUES_MNGR_MANAGERPID_QUESTION_FAILURE, error } }
}




function getIndexScoreManagerPIDWise(data) {
    let Obj = {
        getIndexScoreManagerPIDWiseData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getIndexScoreManagerPIDWise(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_INDEX_MNGR_MANAGERPID_SCORE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_INDEX_MNGR_MANAGERPID_SCORE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_INDEX_MNGR_MANAGERPID_SCORE_FAILURE, error } }
}





function getHappinessIndexDriverManagerPIDWise(data) {
    let Obj = {
        getIndexDriverManagerPIDWiseData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getHappinessIndexDriverManagerPIDWise(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPPINESS_INDEX_MNGR_WISE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_MNGR_WISE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_MNGR_WISE_FAILURE, error } }
}


function getEngagementIndexDriverManagerPIDWise(data) {
    let Obj = {
        getIndexDriverManagerPIDWiseData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getEngagementIndexDriverManagerPIDWise(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_MNGR_WISE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_MNGR_WISE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_MNGR_WISE_FAILURE, error } }
}

function getStressIndexDriverManagerPIDWise(data) {
    let Obj = {
        getIndexDriverManagerPIDWiseData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getStressIndexDriverManagerPIDWise(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_INDEX_MNGR_WISE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_INDEX_MNGR_WISE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_INDEX_MNGR_WISE_FAILURE, error } }
}

function getManagerIndexDriverManagerPIDWise(data) {
    let Obj = {
        getIndexDriverManagerPIDWiseData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getManagerIndexDriverManagerPIDWise(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_INDEX_MNGR_WISE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_INDEX_MNGR_WISE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_INDEX_MNGR_WISE_FAILURE, error } }
}



function getOrgCoreDriverSentiment(data) {
    let Obj = {
        getOrgCoreDriverSentimentData: []
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getOrgCoreDriverSentiment(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if (dashboard && dashboard.getOrgCoreDriverSentimentData && dashboard.getOrgCoreDriverSentimentData.list.length > 0) {
                        let List = dashboard.getOrgCoreDriverSentimentData.list;
                        List.sort((a, b) => b[0] - a[0])
                        if (List && List.length > 0) {
                            let KeyWord = List[0][0];

                            let data3 = {
                                ...data,
                                "keyWord": KeyWord
                            }


                            dispatch(this.AnswerFeedbackText(data3))

                        }
                    }

                },
                error => {

                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ORG_CORE_DRIVER_SENTIMENT_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ORG_CORE_DRIVER_SENTIMENT_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ORG_CORE_DRIVER_SENTIMENT_FAILURE, error } }
}



function AnswerFeedbackText(data) {
    let obj = {
        AnswerFeedbackTextData: [],
        wordTextKeyWord: null
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.AnswerFeedbackText(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.ANSWER_FEEDBACK_TEXT_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.ANSWER_FEEDBACK_TEXT_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.ANSWER_FEEDBACK_TEXT_FAILURE, error } }
}


function get360RelationResponse(data) {
    let obj = {
        get360RelationResponseData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.get360RelationResponse(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_360_RELATION_RESPONSE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_360_RELATION_RESPONSE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_360_RELATION_RESPONSE_FAILURE, error } }
}



function getRespondantAllLifecycleDetails(data) {
    let obj = {
        getRespondantAllLifecycleDetailsData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getRespondantAllLifecycleDetails(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_RESPONDANT_ALL_LIFECYCLE_DETAILS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_RESPONDANT_ALL_LIFECYCLE_DETAILS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_RESPONDANT_ALL_LIFECYCLE_DETAILS_FAILURE, error } }
}





function getUserAccess(data) {
    let obj = {
        getUserAccessData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getUserAccess(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_USER_ACCESS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_USER_ACCESS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_USER_ACCESS_FAILURE, error } }
}




function getFeedbackAndQuestionName(data) {
    let obj = {
        getFeedbackAndQuestionNameData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getFeedbackAndQuestionName(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_FEEDBACK_AND_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_FEEDBACK_AND_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_FEEDBACK_AND_QUESTION_FAILURE, error } }
}
function getTextAndQuestionName(data) {
    let obj = {
        getTextAndQuestionNameData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getTextAndQuestionName(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_TEXT_AND_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_TEXT_AND_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_TEXT_AND_QUESTION_FAILURE, error } }
}


function getParameterLibraryLabelByParentName(data) {
    let obj = {
        getParameterLibraryLabelByParentNameData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getParameterLibraryLabelByParentName(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_PARAMETER_LIBARARY_LABEL_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_PARAMETER_LIBARARY_LABEL_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_PARAMETER_LIBARARY_LABEL_FAILURE, error } }
}





function getBenchmarkQuantile(data) {
    let obj = {
        getBenchmarkQuantileData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getBenchmarkQuantile(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_BENCHMARK_QUANTILE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_BENCHMARK_QUANTILE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_BENCHMARK_QUANTILE_FAILURE, error } }
}





function getIndexTrendChartByMonth(data) {
    let obj = {
        getIndexTrendChartByMonthData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIndexTrendChartByMonth(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_INDEX_TREND_CHART_BY_MONTH_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_INDEX_TREND_CHART_BY_MONTH_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_INDEX_TREND_CHART_BY_MONTH_FAILURE, error } }
}


function getQuestionTrendChartByMonth(data) {
    let obj = {
        getQuestionTrendChartByMonthData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getQuestionTrendChartByMonth(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    dispatch(this.getQuestion({
                        "surveyId": data.surveyId,
                        "template": data.template,
                        "filters": [],
                    }))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_TREND_CHART_BY_MONTH_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_TREND_CHART_BY_MONTH_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_TREND_CHART_BY_MONTH_FAILURE, error } }
}






function getLifecycleOverallPTM(data) {
    let obj = {
        getLifecycleOverallPTMData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getLifecycleOverallPTM(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_OVERALL_LIFECYCLE_PTM_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_OVERALL_LIFECYCLE_PTM_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_OVERALL_LIFECYCLE_PTM_FAILURE, error } }
}




function getTextCluster(data) {
    let obj = {
        getTextClusterData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getTextCluster(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                    dispatch(this.getClusterKeywords({
                        ...data,
                        cluster: 1
                    }));
                    dispatch(this.getClusterSentences({
                        ...data,
                        cluster: 1
                    }));

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_TEXT_CLUSTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_TEXT_CLUSTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_TEXT_CLUSTER_FAILURE, error } }
}





function getTextClusterSentimentCount(data) {
    let obj = {
        getTextClusterSentimentCountData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getTextClusterSentimentCount(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_TEXT_CLUSTER_SENTIMENT_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_TEXT_CLUSTER_SENTIMENT_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_TEXT_CLUSTER_SENTIMENT_FAILURE, error } }
}




function getClusterKeywords(data) {
    let obj = {
        getClusterKeywordsData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getClusterKeywords(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                    dispatch(this.getClusterSentences({
                        ...data,
                        cluster: data && data.cluster ? data.cluster : 0
                    }));


                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_TEXT_CLUSTER_KEYWORD_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_TEXT_CLUSTER_KEYWORD_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_TEXT_CLUSTER_KEYWORD_FAILURE, error } }
}


function getClusterSentences(data) {
    let obj = {
        getClusterSentencesData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getClusterSentences(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_CLUSTER_SENTENCES_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_CLUSTER_SENTENCES_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_CLUSTER_SENTENCES_FAILURE, error } }
}



function driverVsNpsRWA(data) {
    let obj = {
        driverVsNpsRWAData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.driverVsNpsRWA(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.DRIVER_VS_NPS_RWA_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.DRIVER_VS_NPS_RWA_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.DRIVER_VS_NPS_RWA_FAILURE, error } }
}


function getAssessmentReportByEmpID(data) {
    let obj = {
        getAssessmentReportByEmpIDData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getAssessmentReportByEmpID(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ASSESSMENT_REPORT_EMP_ID_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ASSESSMENT_REPORT_EMP_ID_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ASSESSMENT_REPORT_EMP_ID_FAILURE, error } }
}


function getType2AssessmentReportByEmpID(data) {
    let obj = {
        getType2AssessmentReportByEmpIDData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getType2AssessmentReportByEmpID(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_TYPE2_ASSESSMENT_REPORT_EMP_ID_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_TYPE2_ASSESSMENT_REPORT_EMP_ID_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_TYPE2_ASSESSMENT_REPORT_EMP_ID_FAILURE, error } }
}



function getAssessmentReportByEmpIDOnly(data, isEmpty) {
    let obj = {
        getAssessmentReportByEmpIDOnlyData: []
    }
    if (isEmpty) {
        return dispatch => {
            dispatch(success(obj));
        };
    } else {
        return dispatch => {
            dispatch(success(obj));
            dispatch(request());
            dashboardService.getAssessmentReportByEmpIDOnly(data)
                .then(
                    dashboard => {
                        dispatch(success(dashboard));
                    },
                    error => {
                        dispatch(failure(error))
                    }
                );
        };
    }


    function request() { return { type: dashboardConstants.GET_ASSESSMENT_REPORT_EMP_ONLY_ID_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ASSESSMENT_REPORT_EMP_ONLY_ID_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ASSESSMENT_REPORT_EMP_ONLY_ID_FAILURE, error } }
}



function emptyAssessmentReportByEmpID(data) {
    let obj = {
        getAssessmentReportByEmpIDData: []
    }
    return dispatch => {
        dispatch(success(obj));
    };
    function success(dashboard) { return { type: dashboardConstants.GET_ASSESSMENT_REPORT_EMP_ID_SUCCESS, dashboard } }
}




function getTemp360EmpStrength(data) {
    let obj = {
        getTemp360EmpStrengthData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getTemp360EmpStrength(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_TEMP_360_EMP_STRENGTH_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_TEMP_360_EMP_STRENGTH_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_TEMP_360_EMP_STRENGTH_FAILURE, error } }
}



function getAssessmentDashboard(data) {
    let obj = {
        getAssessmentDashboardData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getAssessmentDashboard(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ASSESSMENT_DASHBOARD_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ASSESSMENT_DASHBOARD_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ASSESSMENT_DASHBOARD_FAILURE, error } }
}




function getAssessmentByTheme(data) {
    let obj = {
        getAssessmentByThemeData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getAssessmentByTheme(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ASSESSMENT_THEME_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ASSESSMENT_THEME_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ASSESSMENT_THEME_FAILURE, error } }
}



function getExitQuestion1(data) {
    let obj = {
        ClientProfileData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getExitQuestion1(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_EXIT_QUESTION_ONE_DATA_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_EXIT_QUESTION_ONE_DATA_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_EXIT_QUESTION_ONE_DATA_FAILURE, error } }
}

function getExitQuestionAvg(data) {
    let obj = {
        ClientProfileData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getExitQuestionAvg(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_EXIT_QUESTION_AVERAGE_DATA_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_EXIT_QUESTION_AVERAGE_DATA_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_EXIT_QUESTION_AVERAGE_DATA_FAILURE, error } }
}


function getExitQuestionYesNo(data) {
    let obj = {
        ClientProfileData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getExitQuestionYesNo(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_EXIT_QUESTION_YES_NO_DATA_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_EXIT_QUESTION_YES_NO_DATA_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_EXIT_QUESTION_YES_NO_DATA_FAILURE, error } }
}

function getExitQuestionAllCount(data, data2) {
    let obj = {
        getExitQuestionAllCountData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getExitQuestionAllCount(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                    let firstListName = [];

                    let emplist = [];
                    if (dashboard && dashboard.getExitQuestionAllCountData && dashboard.getExitQuestionAllCountData.length > 0) {
                        let temp = dashboard.getExitQuestionAllCountData.sort((b, a) => a.count - b.count);

                        if (temp && temp[0]._id) {
                            firstListName = temp[0]._id;
                            emplist = temp[0]["emplist"]
                        }


                        let data11 = {
                            surveyId: data.surveyId,
                            questionId: data2.questionId,
                            emplist: emplist,
                        };
                        dispatch(dashboardActions.rolkeywordbyemployee(data11))



                    }






                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_EXIT_QUESTION_ALL_COUNT_DATA_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_EXIT_QUESTION_ALL_COUNT_DATA_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_EXIT_QUESTION_ALL_COUNT_DATA_FAILURE, error } }
}




function getExitQuestionAllDemographics(data) {
    let obj = {
        getExitQuestionAllDemographicsData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getExitQuestionAllDemographics(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_EXIT_QUES_ALL_DMG_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_EXIT_QUES_ALL_DMG_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_EXIT_QUES_ALL_DMG_FAILURE, error } }
}

function rolkeywordbyemployee(data) {
    let obj = {
        rolkeywordbyemployeeData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.rolkeywordbyemployee(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ROL_KEYWORD_EMP_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ROL_KEYWORD_EMP_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ROL_KEYWORD_EMP_FAILURE, error } }
}




function getAssessmentDownloadStatus(data) {
    let obj = {
        getAssessmentDownloadStatusData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getAssessmentDownloadStatus(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ASSESSMENT_DOWNLOAD_STATUS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ASSESSMENT_DOWNLOAD_STATUS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ASSESSMENT_DOWNLOAD_STATUS_FAILURE, error } }
}






function getAssessmentDimensions(data) {
    let obj = {
        getAssessmentDimensionsData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getAssessmentDimensions(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ASSESSMENT_DIMENSION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ASSESSMENT_DIMENSION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ASSESSMENT_DIMENSION_FAILURE, error } }
}



function getAssessmentDimensionsV2(data) {
    let obj = {
        getAssessmentDimensionsV2Data: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getAssessmentDimensions(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ASSESSMENT_DIMENSION_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ASSESSMENT_DIMENSION_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ASSESSMENT_DIMENSION_V2_FAILURE, error } }
}


function getAssessmentManagerList(data) {
    let obj = {
        getAssessmentManagerListData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getAssessmentManagerList(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ASSESSMENT_MANAGER_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ASSESSMENT_MANAGER_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ASSESSMENT_MANAGER_LIST_FAILURE, error } }
}




function getAssessmentManagerReport(data) {
    let obj = {
        getAssessmentManagerReportData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getAssessmentManagerReport(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ASSESSMENT_MANAGER_REPORT_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ASSESSMENT_MANAGER_REPORT_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ASSESSMENT_MANAGER_REPORT_FAILURE, error } }
}


function getAssessmentOnlyDetails(data) {
    let obj = {
        getAssessmentOnlyDetailsData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getAssessmentOnlyDetails(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ASSESSMENT_ONLY_DETAILS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ASSESSMENT_ONLY_DETAILS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ASSESSMENT_ONLY_DETAILS_FAILURE, error } }
}

function getAssessmentEmpListByMngr(data) {
    let obj = {
        getAssessmentEmpListByMngrData: []
    }
    let obj2 = {
        getAssessmentReportByEmpIDData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(success2(obj2));
        dispatch(request());
        dashboardService.getAssessmentEmpListByMngr(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ASSESSMENT_EMP_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ASSESSMENT_EMP_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ASSESSMENT_EMP_LIST_FAILURE, error } }

    function success2(dashboard) { return { type: dashboardConstants.GET_ASSESSMENT_REPORT_EMP_ID_SUCCESS, dashboard } }
}





function getReportType(data) {
    let obj = {
        getReportTypeData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getReportType(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_REPORT_TYPE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_REPORT_TYPE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_REPORT_TYPE_FAILURE, error } }
}


function getReportIndexYearCheck(data) {
    let obj = {
        getReportIndexYearCheckData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getReportIndexYearCheck(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_REPORT_INDEX_YEAR_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_REPORT_INDEX_YEAR_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_REPORT_INDEX_YEAR_FAILURE, error } }
}




function getDemographicWiseRankedScoreIndexTop(data) {
    let obj = {
        getDemographicWiseRankedScoreIndexTopData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getDemographicWiseRankedScore(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DMG_WISE_RANK_SCORE_INDEX_TOP_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DMG_WISE_RANK_SCORE_INDEX_TOP_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DMG_WISE_RANK_SCORE_INDEX_TOP_FAILURE, error } }
}


function getDemographicWiseRankedScoreIndexBottom(data) {
    let obj = {
        getDemographicWiseRankedScoreIndexBottomData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getDemographicWiseRankedScore(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DMG_WISE_RANK_SCORE_INDEX_BOTTOM_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DMG_WISE_RANK_SCORE_INDEX_BOTTOM_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DMG_WISE_RANK_SCORE_INDEX_BOTTOM_FAILURE, error } }
}


function getIndexOverallScore(data) {
    let obj = {
        getIndexOverallScoreData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIndexOverallScoreDistribution(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_INDEX_OVERALL_SCORE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_INDEX_OVERALL_SCORE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_INDEX_OVERALL_SCORE_FAILURE, error } }
}

function getIndexOverallDistribution(data) {
    let obj = {
        getIndexOverallDistributionData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getIndexOverallScoreDistribution(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_INDEX_OVERALL_DISTRIBUTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_INDEX_OVERALL_DISTRIBUTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_INDEX_OVERALL_DISTRIBUTION_FAILURE, error } }
}




function handleReducerInput(PMSGetModuleSetting2, moduleId, value, nameType, name1, name2) {
    if (PMSGetModuleSetting2 && PMSGetModuleSetting2.length > 0) {
        let getIndex = PMSGetModuleSetting2.findIndex(prev => prev.id === moduleId);
        if (getIndex !== -1) {
            if (nameType === 2) {
                PMSGetModuleSetting2[getIndex][name1][name2] = value;
            } else if (nameType === 1) {
                PMSGetModuleSetting2[getIndex][name1] = value;
            }

            return dispatch => {
                dispatch(success({
                    PMSGetModuleSetting2: PMSGetModuleSetting2
                }));
            };
            function success(dashboard) { return { type: dashboardConstants.GET_PMS_GET_MODULE_SETTING_CYCLE_SUCCESS, dashboard } }
        }
    }
}

function handleReducerListInput(PMSGetModuleSetting2, moduleId, value, nameType, name1, labelId, name2) {
    if (PMSGetModuleSetting2 && PMSGetModuleSetting2.length > 0) {
        let getIndex = PMSGetModuleSetting2.findIndex(prev => prev.id === moduleId);
        if (getIndex !== -1) {
            if (nameType === 2) {
                let getIndex2 = PMSGetModuleSetting2[getIndex][name1].findIndex(prev => prev._id === labelId);
                if (getIndex2 !== -1) {
                    PMSGetModuleSetting2[getIndex][name1][getIndex2][name2] = value;
                }

            } else if (nameType === 1) {
                PMSGetModuleSetting2[getIndex][name1] = value;
            }

            return dispatch => {
                dispatch(success({
                    PMSGetModuleSetting2: PMSGetModuleSetting2
                }));
            };
            function success(dashboard) { return { type: dashboardConstants.GET_PMS_GET_MODULE_SETTING_CYCLE_SUCCESS, dashboard } }
        }
    }
}

function handlerRaterReducerInput(PMSGetRaterSetting3, raterName, value, nameType, name1, name2) {
    // console.log("PMSGetRaterSetting3,raterName,value,nameType,name1--->",PMSGetRaterSetting3,raterName,value,nameType,name1)
    if (PMSGetRaterSetting3 && PMSGetRaterSetting3.length > 0) {
        let getIndex = PMSGetRaterSetting3.findIndex(prev => prev.raterName === raterName);
        if (getIndex !== -1) {
            if (nameType === 3) {
                let getIndex2 = PMSGetRaterSetting3[getIndex][name1].findIndex(prev => prev === value);
                if (getIndex2 === -1) {
                    PMSGetRaterSetting3[getIndex][name1].push(value);
                }
            } else if (nameType === 4) {
                let getIndex2 = PMSGetRaterSetting3[getIndex][name1].findIndex(prev => prev === value);
                if (getIndex2 === -1) {
                    PMSGetRaterSetting3[getIndex][name1].splice(getIndex2, 1);
                }
            } else if (nameType === 5) {
                PMSGetRaterSetting3[getIndex][name1] = value;
            }

            return dispatch => {
                dispatch(success({
                    PMSGetRaterSetting3: PMSGetRaterSetting3
                }));
            };

            function success(dashboard) { return { type: dashboardConstants.GET_GET_PMSMODULE_RATER_SETTING_CYCLE_SUCCESS, dashboard } }
        }
    }
}



function PMSDMGEmployeeList(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.PMSDMGEmployeeList(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.PMS_DMG_EMPLOYEE_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.PMS_DMG_EMPLOYEE_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.PMS_DMG_EMPLOYEE_LIST_FAILURE, error } }
}



function handleReducerDateInput(PMSGetProcessDataList, moduleName, value, rank, field) {
    if (PMSGetProcessDataList && PMSGetProcessDataList.length > 0) {
        let getIndex = PMSGetProcessDataList.findIndex(prev => prev.moduleName === moduleName);
        if (getIndex !== -1) {

            let getIndex2 = PMSGetProcessDataList[getIndex]["processList"].findIndex(prev => prev.rank === rank);
            if (getIndex2 !== -1) {
                PMSGetProcessDataList[getIndex]["processList"][getIndex2][field] = value
            }

            return dispatch => {
                dispatch(success({
                    PMSGetProcessDataList: PMSGetProcessDataList
                }));
            };
            function success(dashboard) { return { type: dashboardConstants.PMS_GET_PROCESS_LIST_SUCCESS, dashboard } }
        }
    }
}
