export const dashboardConstants = {

    GET_CLIENT_PROFILE_DATA_REQUEST: 'GET_CLIENT_PROFILE_DATA_REQUEST',
    GET_CLIENT_PROFILE_DATA_SUCCESS: 'GET_CLIENT_PROFILE_DATA_SUCCESS',
    GET_CLIENT_PROFILE_DATA_FAILURE: 'GET_CLIENT_PROFILE_DATA_FAILURE',

    GET_GROW_PROFILE_DATA_REQUEST: 'GET_GROW_PROFILE_DATA_REQUEST',
    GET_GROW_PROFILE_DATA_SUCCESS: 'GET_GROW_PROFILE_DATA_SUCCESS',
    GET_GROW_PROFILE_DATA_FAILURE: 'GET_GROW_PROFILE_DATA_FAILURE',

    GET_PMS_ADD_REQUEST: 'GET_PMS_ADD_REQUEST',
    GET_PMS_ADD_SUCCESS: 'GET_PMS_ADD_SUCCESS',
    GET_PMS_ADD_FAILURE: 'GET_PMS_ADD_FAILURE',
    GET_SURVEY_REQUEST: 'GET_SURVEY_REQUEST',
    GET_SURVEY_SUCCESS: 'GET_SURVEY_SUCCESS',
    GET_SURVEY_FAILURE: 'GET_SURVEY_FAILURE',

    SAVE_NORMALIZATION_SURVEY_REQUEST: 'SAVE_NORMALIZATION_SURVEY_REQUEST',
    SAVE_NORMALIZATION_SURVEY_SUCCESS: 'SAVE_NORMALIZATION_SURVEY_SUCCESS',
    SAVE_NORMALIZATION_SURVEY_FAILURE: 'SAVE_NORMALIZATION_SURVEY_FAILURE',

    GET_NPS_SURVEY_REQUEST: 'GET_NPS_SURVEY_REQUEST',
    GET_NPS_SURVEY_SUCCESS: 'GET_NPS_SURVEY_SUCCESS',
    GET_NPS_SURVEY_FAILURE: 'GET_NPS_SURVEY_FAILURE',

    GET_LEADERSHIP_REPORT_SCORE_REQUEST: 'GET_LEADERSHIP_REPORT_SCORE_REQUEST',
    GET_LEADERSHIP_REPORT_SCORE_SUCCESS: 'GET_LEADERSHIP_REPORT_SCORE_SUCCESS',
    GET_LEADERSHIP_REPORT_SCORE_FAILURE: 'GET_LEADERSHIP_REPORT_SCORE_FAILURE',

    GET_ENGAGEMENT_INDEX_BY_FILTER_V2_REQUEST: 'GET_ENGAGEMENT_INDEX_BY_FILTER_V2_REQUEST',
    GET_ENGAGEMENT_INDEX_BY_FILTER_V2_SUCCESS: 'GET_ENGAGEMENT_INDEX_BY_FILTER_V2_SUCCESS',
    GET_ENGAGEMENT_INDEX_BY_FILTER_V2_FAILURE: 'GET_ENGAGEMENT_INDEX_BY_FILTER_V2_FAILURE',

    GET_MANAGER_INDEX_BY_FILTER_V2_REQUEST: 'GET_MANAGER_INDEX_BY_FILTER_V2_REQUEST',
    GET_MANAGER_INDEX_BY_FILTER_V2_SUCCESS: 'GET_MANAGER_INDEX_BY_FILTER_V2_SUCCESS',
    GET_MANAGER_INDEX_BY_FILTER_V2_FAILURE: 'GET_MANAGER_INDEX_BY_FILTER_V2_FAILURE',

    GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_V2_REQUEST: 'GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_V2_REQUEST',
    GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_V2_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_V2_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_V2_FAILURE: 'GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_V2_FAILURE',

    GET_DEMOGRAPHIC_INDEX_MANAGER_V2_REQUEST: 'GET_DEMOGRAPHIC_INDEX_MANAGER_V2_REQUEST',
    GET_DEMOGRAPHIC_INDEX_MANAGER_V2_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_MANAGER_V2_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_MANAGER_V2_FAILURE: 'GET_DEMOGRAPHIC_INDEX_MANAGER_V2_FAILURE',


    EMP_RATING_SURVEY_REQUEST: 'EMP_RATING_SURVEY_REQUEST',
    EMP_RATING_SURVEY_SUCCESS: 'EMP_RATING_SURVEY_SUCCESS',
    EMP_RATING_SURVEY_FAILURE: 'EMP_RATING_SURVEY_FAILURE',

    GET_STATIC_BENCHMARK_REQUEST: 'GET_STATIC_BENCHMARK_REQUEST',
    GET_STATIC_BENCHMARK_SUCCESS: 'GET_STATIC_BENCHMARK_SUCCESS',
    GET_STATIC_BENCHMARK_FAILURE: 'GET_STATIC_BENCHMARK_FAILURE',


    NORMAL_SURVEY_REQUEST: 'NORMAL_SURVEY_REQUEST',
    NORMAL_SURVEY_SUCCESS: 'NORMAL_SURVEY_SUCCESS',
    NORMAL_SURVEY_FAILURE: 'NORMAL_SURVEY_FAILURE',

    CREATE_NPS_SURVEY_REQUEST: 'CREATE_NPS_SURVEY_REQUEST',
    CREATE_NPS_SURVEY_SUCCESS: 'CREATE_NPS_SURVEY_SUCCESS',
    CREATE_NPS_SURVEY_FAILURE: 'CREATE_NPS_SURVEY_FAILURE',


    GET_PMSMODULE_NAMES_LIST_REQUEST: 'GET_PMSMODULE_NAMES_LIST_REQUEST',
    GET_PMSMODULE_NAMES_LIST_SUCCESS: 'GET_PMSMODULE_NAMES_LIST_SUCCESS',
    GET_PMSMODULE_NAMES_LIST_FAILURE: 'GET_PMSMODULE_NAMES_LIST_FAILURE',

    GET_PMSMODULE_APPRAISAL_LIST_REQUEST: 'GET_PMSMODULE_APPRAISAL_LIST_REQUEST',
    GET_PMSMODULE_APPRAISAL_LIST_SUCCESS: 'GET_PMSMODULE_APPRAISAL_LIST_SUCCESS',
    GET_PMSMODULE_APPRAISAL_LIST_FAILURE: 'GET_PMSMODULE_APPRAISAL_LIST_FAILURE',

    GET_PMSMODULE_APPLICABLE_REQUEST: 'GET_PMSMODULE_APPLICABLE_REQUEST',
    GET_PMSMODULE_APPLICABLE_SUCCESS: 'GET_PMSMODULE_APPLICABLE_SUCCESS',
    GET_PMSMODULE_APPLICABLE_FAILURE: 'GET_PMSMODULE_APPLICABLE_FAILURE',

    GET_PMSMODULE_APPLICABLE4_REQUEST: 'GET_PMSMODULE_APPLICABLE4_REQUEST',
    GET_PMSMODULE_APPLICABLE4_SUCCESS: 'GET_PMSMODULE_APPLICABLE4_SUCCESS',
    GET_PMSMODULE_APPLICABLE4_FAILURE: 'GET_PMSMODULE_APPLICABLE4_FAILURE',


    GET_PMSMODULE_RATER_SETTING_CYCLE_REQUEST: 'GET_PMSMODULE_RATER_SETTING_CYCLE_REQUEST',
    GET_PMSMODULE_RATER_SETTING_CYCLE_SUCCESS: 'GET_PMSMODULE_RATER_SETTING_CYCLE_SUCCESS',
    GET_PMSMODULE_RATER_SETTING_CYCLE_FAILURE: 'GET_PMSMODULE_RATER_SETTING_CYCLE_FAILURE',

    GET_GET_PMSMODULE_RATER_SETTING_CYCLE_REQUEST: 'GET_GET_PMSMODULE_RATER_SETTING_CYCLE_REQUEST',
    GET_GET_PMSMODULE_RATER_SETTING_CYCLE_SUCCESS: 'GET_GET_PMSMODULE_RATER_SETTING_CYCLE_SUCCESS',
    GET_GET_PMSMODULE_RATER_SETTING_CYCLE_FAILURE: 'GET_GET_PMSMODULE_RATER_SETTING_CYCLE_FAILURE',


    GET_PMSMODULE_SETTING_CYCLE_REQUEST: 'GET_PMSMODULE_SETTING_CYCLE_REQUEST',
    GET_PMSMODULE_SETTING_CYCLE_SUCCESS: 'GET_PMSMODULE_SETTING_CYCLE_SUCCESS',
    GET_PMSMODULE_SETTING_CYCLE_FAILURE: 'GET_PMSMODULE_SETTING_CYCLE_FAILURE',

    GET_APPRAISAL_CYCLE_REQUEST: 'GET_APPRAISAL_CYCLE_REQUEST',
    GET_APPRAISAL_CYCLE_SUCCESS: 'GET_APPRAISAL_CYCLE_SUCCESS',
    GET_APPRAISAL_CYCLE_FAILURE: 'GET_APPRAISAL_CYCLE_FAILURE',

    TREE_MAP_REQUEST: 'TREE_MAP_REQUEST',
    TREE_MAP_SUCCESS: 'TREE_MAP_SUCCESS',
    TREE_MAP_FAILURE: 'TREE_MAP_FAILURE',


    TREE_MAP_LEN_REQUEST: 'TREE_MAP_LEN_REQUEST',
    TREE_MAP_LEN_SUCCESS: 'TREE_MAP_LEN_SUCCESS',
    TREE_MAP_LEN_FAILURE: 'TREE_MAP_LEN_FAILURE',


    GENERATE_COMPANY_SURVEY_CODE_REQUEST: 'GENERATE_COMPANY_SURVEY_CODE_REQUEST',
    GENERATE_COMPANY_SURVEY_CODE_SUCCESS: 'GENERATE_COMPANY_SURVEY_CODE_SUCCESS',
    GENERATE_COMPANY_SURVEY_CODE_FAILURE: 'GENERATE_COMPANY_SURVEY_CODE_FAILURE',

    GET_HAPPINESS_INDEX_REQUEST: 'GET_HAPPINESS_INDEX_REQUEST',
    GET_HAPPINESS_INDEX_SUCCESS: 'GET_HAPPINESS_INDEX_SUCCESS',
    GET_HAPPINESS_INDEX_FAILURE: 'GET_HAPPINESS_INDEX_FAILURE',

    GET_ENGAGEMENT_INDEX_REQUEST: 'GET_ENGAGEMENT_INDEX_REQUEST',
    GET_ENGAGEMENT_INDEX_SUCCESS: 'GET_ENGAGEMENT_INDEX_SUCCESS',
    GET_ENGAGEMENT_INDEX_FAILURE: 'GET_ENGAGEMENT_INDEX_FAILURE',

    GET_DEMOGRAPHIC_INDEX_MANY_REQUEST: 'GET_DEMOGRAPHIC_INDEX_MANY_REQUEST',
    GET_DEMOGRAPHIC_INDEX_MANY_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_MANY_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_MANY_FAILURE: 'GET_DEMOGRAPHIC_INDEX_MANY_FAILURE',


    GET_HOPE_INDEX_REQUEST: 'GET_HOPE_INDEX_REQUEST',
    GET_HOPE_INDEX_SUCCESS: 'GET_HOPE_INDEX_SUCCESS',
    GET_HOPE_INDEX_FAILURE: 'GET_HOPE_INDEX_FAILURE',

    GET_STRESS_INDEX_REQUEST: 'GET_STRESS_INDEX_REQUEST',
    GET_STRESS_INDEX_SUCCESS: 'GET_STRESS_INDEX_SUCCESS',
    GET_STRESS_INDEX_FAILURE: 'GET_STRESS_INDEX_FAILURE',

    GET_MANAGER_INDEX_REQUEST: 'GET_MANAGER_INDEX_REQUEST',
    GET_MANAGER_INDEX_SUCCESS: 'GET_MANAGER_INDEX_SUCCESS',
    GET_MANAGER_INDEX_FAILURE: 'GET_MANAGER_INDEX_FAILURE',

    GET_DEMOGRAPHIC_REPORT_FILTER_REQUEST: 'GET_DEMOGRAPHIC_REPORT_FILTER_REQUEST',
    GET_DEMOGRAPHIC_REPORT_FILTER_SUCCESS: 'GET_DEMOGRAPHIC_REPORT_FILTER_SUCCESS',
    GET_DEMOGRAPHIC_REPORT_FILTER_FAILURE: 'GET_DEMOGRAPHIC_REPORT_FILTER_FAILURE',

    GET_ORG_DEMOGRAPHIC_INDEX_MANY_REQUEST: 'GET_ORG_DEMOGRAPHIC_INDEX_MANY_REQUEST',
    GET_ORG_DEMOGRAPHIC_INDEX_MANY_SUCCESS: 'GET_ORG_DEMOGRAPHIC_INDEX_MANY_SUCCESS',
    GET_ORG_DEMOGRAPHIC_INDEX_MANY_FAILURE: 'GET_ORG_DEMOGRAPHIC_INDEX_MANY_FAILURE',



    GET_DEMOGRAPHIC_ORG_REPORT_REQUEST: 'GET_DEMOGRAPHIC_ORG_REPORT_REQUEST',
    GET_DEMOGRAPHIC_ORG_REPORT_SUCCESS: 'GET_DEMOGRAPHIC_ORG_REPORT_SUCCESS',
    GET_DEMOGRAPHIC_ORG_REPORT_FAILURE: 'GET_DEMOGRAPHIC_ORG_REPORT_FAILURE',

    GET_DEMOGRAPHIC_ORG_REPORT_2_REQUEST: 'GET_DEMOGRAPHIC_ORG_REPORT_2_REQUEST',
    GET_DEMOGRAPHIC_ORG_REPORT_2_SUCCESS: 'GET_DEMOGRAPHIC_ORG_REPORT_2_SUCCESS',
    GET_DEMOGRAPHIC_ORG_REPORT_2_FAILURE: 'GET_DEMOGRAPHIC_ORG_REPORT_2_FAILURE',


    GET_LEADER_INDEX_REQUEST: 'GET_LEADER_INDEX_REQUEST',
    GET_LEADER_INDEX_SUCCESS: 'GET_LEADER_INDEX_SUCCESS',
    GET_LEADER_INDEX_FAILURE: 'GET_LEADER_INDEX_FAILURE',

    TEXT_DIST_REQUEST: 'TEXT_DIST_REQUEST',
    TEXT_DIST_SUCCESS: 'TEXT_DIST_SUCCESS',
    TEXT_DIST_FAILURE: 'TEXT_DIST_FAILURE',

    GET_HAPPINESS_INDEX_MNGR_REQUEST: 'GET_HAPPINESS_INDEX_MNGR_REQUEST',
    GET_HAPPINESS_INDEX_MNGR_SUCCESS: 'GET_HAPPINESS_INDEX_MNGR_SUCCESS',
    GET_HAPPINESS_INDEX_MNGR_FAILURE: 'GET_HAPPINESS_INDEX_MNGR_FAILURE',

    GET_ENGAGEMENT_INDEX_MNGR_REQUEST: 'GET_ENGAGEMENT_INDEX_MNGR_REQUEST',
    GET_ENGAGEMENT_INDEX_MNGR_SUCCESS: 'GET_ENGAGEMENT_INDEX_MNGR_SUCCESS',
    GET_ENGAGEMENT_INDEX_MNGR_FAILURE: 'GET_ENGAGEMENT_INDEX_MNGR_FAILURE',

    GET_HOPE_INDEX_MNGR_REQUEST: 'GET_HOPE_INDEX_MNGR_REQUEST',
    GET_HOPE_INDEX_MNGR_SUCCESS: 'GET_HOPE_INDEX_MNGR_SUCCESS',
    GET_HOPE_INDEX_MNGR_FAILURE: 'GET_HOPE_INDEX_MNGR_FAILURE',

    GET_STRESS_INDEX_MNGR_REQUEST: 'GET_STRESS_INDEX_MNGR_REQUEST',
    GET_STRESS_INDEX_MNGR_SUCCESS: 'GET_STRESS_INDEX_MNGR_SUCCESS',
    GET_STRESS_INDEX_MNGR_FAILURE: 'GET_STRESS_INDEX_MNGR_FAILURE',

    GET_HAPPINESS_INDEX_FILTER_REQUEST: 'GET_HAPPINESS_INDEX_FILTER_REQUEST',
    GET_HAPPINESS_INDEX_FILTER_SUCCESS: 'GET_HAPPINESS_INDEX_FILTER_SUCCESS',
    GET_HAPPINESS_INDEX_FILTER_FAILURE: 'GET_HAPPINESS_INDEX_FILTER_FAILURE',

    GET_ENGAGEMENT_INDEX_FILTER_REQUEST: 'GET_ENGAGEMENT_INDEX_FILTER_REQUEST',
    GET_ENGAGEMENT_INDEX_FILTER_SUCCESS: 'GET_ENGAGEMENT_INDEX_FILTER_SUCCESS',
    GET_ENGAGEMENT_INDEX_FILTER_FAILURE: 'GET_ENGAGEMENT_INDEX_FILTER_FAILURE',

    GET_HOPE_INDEX_FILTER_REQUEST: 'GET_HOPE_INDEX_FILTER_REQUEST',
    GET_HOPE_INDEX_FILTER_SUCCESS: 'GET_HOPE_INDEX_FILTER_SUCCESS',
    GET_HOPE_INDEX_FILTER_FAILURE: 'GET_HOPE_INDEX_FILTER_FAILURE',

    GET_STRESS_INDEX_FILTER_REQUEST: 'GET_STRESS_INDEX_FILTER_REQUEST',
    GET_STRESS_INDEX_FILTER_SUCCESS: 'GET_STRESS_INDEX_FILTER_SUCCESS',
    GET_STRESS_INDEX_FILTER_FAILURE: 'GET_STRESS_INDEX_FILTER_FAILURE',

    GET_MANAGER_INDEX_FILTER_REQUEST: 'GET_MANAGER_INDEX_FILTER_REQUEST',
    GET_MANAGER_INDEX_FILTER_SUCCESS: 'GET_MANAGER_INDEX_FILTER_SUCCESS',
    GET_MANAGER_INDEX_FILTER_FAILURE: 'GET_MANAGER_INDEX_FILTER_FAILURE',

    GET_LEADER_INDEX_FILTER_REQUEST: 'GET_LEADER_INDEX_FILTER_REQUEST',
    GET_LEADER_INDEX_FILTER_SUCCESS: 'GET_LEADER_INDEX_FILTER_SUCCESS',
    GET_LEADER_INDEX_FILTER_FAILURE: 'GET_LEADER_INDEX_FILTER_FAILURE',

    GET_EMP_FILTER_REQUEST: 'GET_EMP_FILTER_REQUEST',
    GET_EMP_FILTER_SUCCESS: 'GET_EMP_FILTER_SUCCESS',
    GET_EMP_FILTER_FAILURE: 'GET_EMP_FILTER_FAILURE',

    GET_MANAGER_LIST_REQUEST: 'GET_MANAGER_LIST_REQUEST',
    GET_MANAGER_LIST_SUCCESS: 'GET_MANAGER_LIST_SUCCESS',
    GET_MANAGER_LIST_FAILURE: 'GET_MANAGER_LIST_FAILURE',

    GET_QUESTION_ANALYSIS_REQUEST: 'GET_QUESTION_ANALYSIS_REQUEST',
    GET_QUESTION_ANALYSIS_SUCCESS: 'GET_QUESTION_ANALYSIS_SUCCESS',
    GET_QUESTION_ANALYSIS_FAILURE: 'GET_QUESTION_ANALYSIS_FAILURE',

    GET_QUESTION_ANALYSIS_NEW_REQUEST: 'GET_QUESTION_ANALYSIS_NEW_REQUEST',
    GET_QUESTION_ANALYSIS_NEW_SUCCESS: 'GET_QUESTION_ANALYSIS_NEW_SUCCESS',
    GET_QUESTION_ANALYSIS_NEW_FAILURE: 'GET_QUESTION_ANALYSIS_NEW_FAILURE',

    GET_QUESTION_ANALYSIS_FILTER_REQUEST: 'GET_QUESTION_ANALYSIS_FILTER_REQUEST',
    GET_QUESTION_ANALYSIS_FILTER_SUCCESS: 'GET_QUESTION_ANALYSIS_FILTER_SUCCESS',
    GET_QUESTION_ANALYSIS_FILTER_FAILURE: 'GET_QUESTION_ANALYSIS_FILTER_FAILURE',

    GET_COMPANY_EMP_LIST_REQUEST: 'GET_COMPANY_EMP_LIST_REQUEST',
    GET_COMPANY_EMP_LIST_SUCCESS: 'GET_COMPANY_EMP_LIST_SUCCESS',
    GET_COMPANY_EMP_LIST_FAILURE: 'GET_COMPANY_EMP_LIST_FAILURE',

    GET_ASSIGN_ROLE_REQUEST: 'GET_ASSIGN_ROLE_REQUEST',
    GET_ASSIGN_ROLE_SUCCESS: 'GET_ASSIGN_ROLE_SUCCESS',
    GET_ASSIGN_ROLE_FAILURE: 'GET_ASSIGN_ROLE_FAILURE',

    GET_HAPPINESS_INDEX_NEW_REQUEST: 'GET_HAPPINESS_INDEX_NEW_REQUEST',
    GET_HAPPINESS_INDEX_NEW_SUCCESS: 'GET_HAPPINESS_INDEX_NEW_SUCCESS',
    GET_HAPPINESS_INDEX_NEW_FAILURE: 'GET_HAPPINESS_INDEX_NEW_FAILURE',

    GET_ENGAGEMENT_INDEX_NEW_REQUEST: 'GET_ENGAGEMENT_INDEX_NEW_REQUEST',
    GET_ENGAGEMENT_INDEX_NEW_SUCCESS: 'GET_ENGAGEMENT_INDEX_NEW_SUCCESS',
    GET_ENGAGEMENT_INDEX_NEW_FAILURE: 'GET_ENGAGEMENT_INDEX_NEW_FAILURE',

    GET_HOPE_INDEX_NEW_REQUEST: 'GET_HOPE_INDEX_NEW_REQUEST',
    GET_HOPE_INDEX_NEW_SUCCESS: 'GET_HOPE_INDEX_NEW_SUCCESS',
    GET_HOPE_INDEX_NEW_FAILURE: 'GET_HOPE_INDEX_NEW_FAILURE',

    GET_STRESS_INDEX_NEW_REQUEST: 'GET_STRESS_INDEX_NEW_REQUEST',
    GET_STRESS_INDEX_NEW_SUCCESS: 'GET_STRESS_INDEX_NEW_SUCCESS',
    GET_STRESS_INDEX_NEW_FAILURE: 'GET_STRESS_INDEX_NEW_FAILURE',

    GET_MANAGER_INDEX_NEW_REQUEST: 'GET_MANAGER_INDEX_NEW_REQUEST',
    GET_MANAGER_INDEX_NEW_SUCCESS: 'GET_MANAGER_INDEX_NEW_SUCCESS',
    GET_MANAGER_INDEX_NEW_FAILURE: 'GET_MANAGER_INDEX_NEW_FAILURE',

    GET_DATA_FILTER_OBJECT_SUCCESS: 'GET_DATA_FILTER_OBJECT_SUCCESS',

    GET_STACK_LIST_SUCCESS: 'GET_STACK_LIST_SUCCESS',

    GET_ORG_CORE_INDEX_REQUEST: 'GET_ORG_CORE_INDEX_REQUEST',
    GET_ORG_CORE_INDEX_SUCCESS: 'GET_ORG_CORE_INDEX_SUCCESS',
    GET_ORG_CORE_INDEX_FAILURE: 'GET_ORG_CORE_INDEX_FAILURE',

    GET_LEADERSHIP_TEXT_DETAILS_REQUEST: 'GET_LEADERSHIP_TEXT_DETAILS_REQUEST',
    GET_LEADERSHIP_TEXT_DETAILS_SUCCESS: 'GET_LEADERSHIP_TEXT_DETAILS_SUCCESS',
    GET_LEADERSHIP_TEXT_DETAILS_FAILURE: 'GET_LEADERSHIP_TEXT_DETAILS_FAILURE',

    GET_INDEX_REQUEST: 'GET_INDEX_REQUEST',
    GET_INDEX_SUCCESS: 'GET_INDEX_SUCCESS',
    GET_INDEX_FAILURE: 'GET_INDEX_FAILURE',

    GET_INDEX_2_REQUEST: 'GET_INDEX_2_REQUEST',
    GET_INDEX_2_SUCCESS: 'GET_INDEX_2_SUCCESS',
    GET_INDEX_2_FAILURE: 'GET_INDEX_2_FAILURE',


    GET_INDEX_VS_NPS_REQUEST: 'GET_INDEX_VS_NPS_REQUEST',
    GET_INDEX_VS_NPS_SUCCESS: 'GET_INDEX_VS_NPS_SUCCESS',
    GET_INDEX_VS_NPS_FAILURE: 'GET_INDEX_VS_NPS_FAILURE',



    GET_OVERALL_INDEX_REQUEST: 'GET_OVERALL_INDEX_REQUEST',
    GET_OVERALL_INDEX_SUCCESS: 'GET_OVERALL_INDEX_SUCCESS',
    GET_OVERALL_INDEX_FAILURE: 'GET_OVERALL_INDEX_FAILURE',

    GET_OVERALL_INDEX_2_REQUEST: 'GET_OVERALL_INDEX_2_REQUEST',
    GET_OVERALL_INDEX_2_SUCCESS: 'GET_OVERALL_INDEX_2_SUCCESS',
    GET_OVERALL_INDEX_2_FAILURE: 'GET_OVERALL_INDEX_2_FAILURE',






    GET_INDEX_FILTER_REQUEST: 'GET_INDEX_FILTER_REQUEST',
    GET_INDEX_FILTER_SUCCESS: 'GET_INDEX_FILTER_SUCCESS',
    GET_INDEX_FILTER_FAILURE: 'GET_INDEX_FILTER_FAILURE',

    GET_INDEX_2_FILTER_REQUEST: 'GET_INDEX_2_FILTER_REQUEST',
    GET_INDEX_2_FILTER_SUCCESS: 'GET_INDEX_2_FILTER_SUCCESS',
    GET_INDEX_2_FILTER_FAILURE: 'GET_INDEX_2_FILTER_FAILURE',


    GET_DEMOGRAPHIC_INDEX_REQUEST: 'GET_DEMOGRAPHIC_INDEX_REQUEST',
    GET_DEMOGRAPHIC_INDEX_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_FAILURE: 'GET_DEMOGRAPHIC_INDEX_FAILURE',
    GET_DEMOGRAPHIC_INDEX_CLEAR: 'GET_DEMOGRAPHIC_INDEX_CLEAR',

    GET_QUESTION_REQUEST: 'GET_QUESTION_REQUEST',
    GET_QUESTION_SUCCESS: 'GET_QUESTION_SUCCESS',
    GET_QUESTION_FAILURE: 'GET_QUESTION_FAILURE',



    GET_HAPINESS_QUESTION_REQUEST: 'GET_HAPINESS_QUESTION_REQUEST',
    GET_HAPINESS_QUESTION_SUCCESS: 'GET_HAPINESS_QUESTION_SUCCESS',
    GET_HAPINESS_QUESTION_FAILURE: 'GET_HAPINESS_QUESTION_FAILURE',

    GET_HAPINESS_QUESTION_2_REQUEST: 'GET_HAPINESS_QUESTION_2_REQUEST',
    GET_HAPINESS_QUESTION_2_SUCCESS: 'GET_HAPINESS_QUESTION_2_SUCCESS',
    GET_HAPINESS_QUESTION_2_FAILURE: 'GET_HAPINESS_QUESTION_2_FAILURE',




    GET_HAPINESS_MANAGER_QUESTION_REQUEST: 'GET_HAPINESS_MANAGER_QUESTION_REQUEST',
    GET_HAPINESS_MANAGER_QUESTION_SUCCESS: 'GET_HAPINESS_MANAGER_QUESTION_SUCCESS',
    GET_HAPINESS_MANAGER_QUESTION_FAILURE: 'GET_HAPINESS_MANAGER_QUESTION_FAILURE',

    GET_HAPINESS_DMG_QUESTION_REQUEST: 'GET_HAPINESS_DMG_QUESTION_REQUEST',
    GET_HAPINESS_DMG_QUESTION_SUCCESS: 'GET_HAPINESS_DMG_QUESTION_SUCCESS',
    GET_HAPINESS_DMG_QUESTION_FAILURE: 'GET_HAPINESS_DMG_QUESTION_FAILURE',



    GET_ENGAGEMENT_QUESTION_REQUEST: 'GET_ENGAGEMENT_QUESTION_REQUEST',
    GET_ENGAGEMENT_QUESTION_SUCCESS: 'GET_ENGAGEMENT_QUESTION_SUCCESS',
    GET_ENGAGEMENT_QUESTION_FAILURE: 'GET_ENGAGEMENT_QUESTION_FAILURE',

    GET_ENGAGEMENT_QUESTION_2_REQUEST: 'GET_ENGAGEMENT_QUESTION_2_REQUEST',
    GET_ENGAGEMENT_QUESTION_2_SUCCESS: 'GET_ENGAGEMENT_QUESTION_2_SUCCESS',
    GET_ENGAGEMENT_QUESTION_2_FAILURE: 'GET_ENGAGEMENT_QUESTION_2_FAILURE',


    GET_ENGAGEMENT_MANAGER_QUESTION_REQUEST: 'GET_ENGAGEMENT_MANAGER_QUESTION_REQUEST',
    GET_ENGAGEMENT_MANAGER_QUESTION_SUCCESS: 'GET_ENGAGEMENT_MANAGER_QUESTION_SUCCESS',
    GET_ENGAGEMENT_MANAGER_QUESTION_FAILURE: 'GET_ENGAGEMENT_MANAGER_QUESTION_FAILURE',

    GET_ENGAGEMENT_DMG_QUESTION_REQUEST: 'GET_ENGAGEMENT_DMG_QUESTION_REQUEST',
    GET_ENGAGEMENT_DMG_QUESTION_SUCCESS: 'GET_ENGAGEMENT_DMG_QUESTION_SUCCESS',
    GET_ENGAGEMENT_DMG_QUESTION_FAILURE: 'GET_ENGAGEMENT_DMG_QUESTION_FAILURE',




    GET_STRESS_QUESTION_REQUEST: 'GET_STRESS_QUESTION_REQUEST',
    GET_STRESS_QUESTION_SUCCESS: 'GET_STRESS_QUESTION_SUCCESS',
    GET_STRESS_QUESTION_FAILURE: 'GET_STRESS_QUESTION_FAILURE',

    GET_STRESS_QUESTION_2_REQUEST: 'GET_STRESS_QUESTION_2_REQUEST',
    GET_STRESS_QUESTION_2_SUCCESS: 'GET_STRESS_QUESTION_2_SUCCESS',
    GET_STRESS_QUESTION_2_FAILURE: 'GET_STRESS_QUESTION_2_FAILURE',


    GET_STRESS_MANAGER_QUESTION_REQUEST: 'GET_STRESS_MANAGER_QUESTION_REQUEST',
    GET_STRESS_MANAGER_QUESTION_SUCCESS: 'GET_STRESS_MANAGER_QUESTION_SUCCESS',
    GET_STRESS_MANAGER_QUESTION_FAILURE: 'GET_STRESS_MANAGER_QUESTION_FAILURE',

    GET_STRESS_DMG_QUESTION_REQUEST: 'GET_STRESS_DMG_QUESTION_REQUEST',
    GET_STRESS_DMG_QUESTION_SUCCESS: 'GET_STRESS_DMG_QUESTION_SUCCESS',
    GET_STRESS_DMG_QUESTION_FAILURE: 'GET_STRESS_DMG_QUESTION_FAILURE',





    GET_HOPE_QUESTION_REQUEST: 'GET_HOPE_QUESTION_REQUEST',
    GET_HOPE_QUESTION_SUCCESS: 'GET_HOPE_QUESTION_SUCCESS',
    GET_HOPE_QUESTION_FAILURE: 'GET_HOPE_QUESTION_FAILURE',



    GET_MANAGER_QUESTION_REQUEST: 'GET_MANAGER_QUESTION_REQUEST',
    GET_MANAGER_QUESTION_SUCCESS: 'GET_MANAGER_QUESTION_SUCCESS',
    GET_MANAGER_QUESTION_FAILURE: 'GET_MANAGER_QUESTION_FAILURE',

    GET_MANAGER_QUESTION_2_REQUEST: 'GET_MANAGER_QUESTION_2_REQUEST',
    GET_MANAGER_QUESTION_2_SUCCESS: 'GET_MANAGER_QUESTION_2_SUCCESS',
    GET_MANAGER_QUESTION_2_FAILURE: 'GET_MANAGER_QUESTION_2_FAILURE',


    GET_MANAGER_MANAGER_QUESTION_REQUEST: 'GET_MANAGER_MANAGER_QUESTION_REQUEST',
    GET_MANAGER_MANAGER_QUESTION_SUCCESS: 'GET_MANAGER_MANAGER_QUESTION_SUCCESS',
    GET_MANAGER_MANAGER_QUESTION_FAILURE: 'GET_MANAGER_QUESTION_FAILURE',

    GET_MANAGER_DMG_QUESTION_REQUEST: 'GET_MANAGER_DMG_QUESTION_REQUEST',
    GET_MANAGER_DMG_QUESTION_SUCCESS: 'GET_MANAGER_DMG_QUESTION_SUCCESS',
    GET_MANAGER_DMG_QUESTION_FAILURE: 'GET_MANAGER_DMG_QUESTION_FAILURE',



    GET_LEADER_QUESTION_REQUEST: 'GET_LEADER_QUESTION_REQUEST',
    GET_LEADER_QUESTION_SUCCESS: 'GET_LEADER_QUESTION_SUCCESS',
    GET_LEADER_QUESTION_FAILURE: 'GET_LEADER_QUESTION_FAILURE',

    GET_LEADER_QUESTION_2_REQUEST: 'GET_LEADER_QUESTION_2_REQUEST',
    GET_LEADER_QUESTION_2_SUCCESS: 'GET_LEADER_QUESTION_2_SUCCESS',
    GET_LEADER_QUESTION_2_FAILURE: 'GET_LEADER_QUESTION_2_FAILURE',


    GET_LEADER_MANAGER_QUESTION_REQUEST: 'GET_LEADER_MANAGER_QUESTION_REQUEST',
    GET_LEADER_MANAGER_QUESTION_SUCCESS: 'GET_LEADER_MANAGER_QUESTION_SUCCESS',
    GET_LEADER_MANAGER_QUESTION_FAILURE: 'GET_LEADER_MANAGER_QUESTION_FAILURE',

    GET_LEADER_DMG_QUESTION_REQUEST: 'GET_LEADER_DMG_QUESTION_REQUEST',
    GET_LEADER_DMG_QUESTION_SUCCESS: 'GET_LEADER_DMG_QUESTION_SUCCESS',
    GET_LEADER_DMG_QUESTION_FAILURE: 'GET_LEADER_DMG_QUESTION_FAILURE',



    GET_QUESTION_REPORT_REQUEST: 'GET_QUESTION_REPORT_REQUEST',
    GET_QUESTION_REPORT_SUCCESS: 'GET_QUESTION_REPORT_SUCCESS',
    GET_QUESTION_REPORT_FAILURE: 'GET_QUESTION_REPORT_FAILURE',

    GET_QUESTION_FILTER_REQUEST: 'GET_QUESTION_FILTER_REQUEST',
    GET_QUESTION_FILTER_SUCCESS: 'GET_QUESTION_FILTER_SUCCESS',
    GET_QUESTION_FILTER_FAILURE: 'GET_QUESTION_FILTER_FAILURE',



    GET_HAPPINESS_INDEX_V2_REQUEST: 'GET_HAPPINESS_INDEX_V2_REQUEST',
    GET_HAPPINESS_INDEX_V2_SUCCESS: 'GET_HAPPINESS_INDEX_V2_SUCCESS',
    GET_HAPPINESS_INDEX_V2_FAILURE: 'GET_HAPPINESS_INDEX_V2_FAILURE',

    GET_HAPPINESS_INDEX_V2_2_REQUEST: 'GET_HAPPINESS_INDEX_V2_2_REQUEST',
    GET_HAPPINESS_INDEX_V2_2_SUCCESS: 'GET_HAPPINESS_INDEX_V2_2_SUCCESS',
    GET_HAPPINESS_INDEX_V2_2_FAILURE: 'GET_HAPPINESS_INDEX_V2_2_FAILURE',


    GET_ENGAGEMENT_INDEX_V2_REQUEST: 'GET_ENGAGEMENT_INDEX_V2_REQUEST',
    GET_ENGAGEMENT_INDEX_V2_SUCCESS: 'GET_ENGAGEMENT_INDEX_V2_SUCCESS',
    GET_ENGAGEMENT_INDEX_V2_FAILURE: 'GET_ENGAGEMENT_INDEX_V2_FAILURE',

    GET_ENGAGEMENT_INDEX_V2_2_REQUEST: 'GET_ENGAGEMENT_INDEX_V2_2_REQUEST',
    GET_ENGAGEMENT_INDEX_V2_2_SUCCESS: 'GET_ENGAGEMENT_INDEX_V2_2_SUCCESS',
    GET_ENGAGEMENT_INDEX_V2_2_FAILURE: 'GET_ENGAGEMENT_INDEX_V2_2_FAILURE',


    GET_HOPE_INDEX_V2_REQUEST: 'GET_HOPE_INDEX_V2_REQUEST',
    GET_HOPE_INDEX_V2_SUCCESS: 'GET_HOPE_INDEX_V2_SUCCESS',
    GET_HOPE_INDEX_V2_FAILURE: 'GET_HOPE_INDEX_V2_FAILURE',

    GET_STRESS_INDEX_V2_REQUEST: 'GET_STRESS_INDEX_V2_REQUEST',
    GET_STRESS_INDEX_V2_SUCCESS: 'GET_STRESS_INDEX_V2_SUCCESS',
    GET_STRESS_INDEX_V2_FAILURE: 'GET_STRESS_INDEX_V2_FAILURE',

    GET_STRESS_INDEX_V2_2_REQUEST: 'GET_STRESS_INDEX_V2_2_REQUEST',
    GET_STRESS_INDEX_V2_2_SUCCESS: 'GET_STRESS_INDEX_V2_2_SUCCESS',
    GET_STRESS_INDEX_V2_2_FAILURE: 'GET_STRESS_INDEX_V2_2_FAILURE',


    GET_MANAGER_INDEX_V2_REQUEST: 'GET_MANAGER_INDEX_V2_REQUEST',
    GET_MANAGER_INDEX_V2_SUCCESS: 'GET_MANAGER_INDEX_V2_SUCCESS',
    GET_MANAGER_INDEX_V2_FAILURE: 'GET_MANAGER_INDEX_V2_FAILURE',

    GET_LEADER_INDEX_V2_REQUEST: 'GET_LEADER_INDEX_V2_REQUEST',
    GET_LEADER_INDEX_V2_SUCCESS: 'GET_LEADER_INDEX_V2_SUCCESS',
    GET_LEADER_INDEX_V2_FAILURE: 'GET_LEADER_INDEX_V2_FAILURE',

    GET_SENIORLEADERSHIP_INDEX_V2_REQUEST: 'GET_SENIORLEADERSHIP_INDEX_V2_REQUEST',
    GET_SENIORLEADERSHIP_INDEX_V2_SUCCESS: 'GET_SENIORLEADERSHIP_INDEX_V2_SUCCESS',
    GET_SENIORLEADERSHIP_INDEX_V2_FAILURE: 'GET_SENIORLEADERSHIP_INDEX_V2_FAILURE',

    GET_ORG_INDEX_V2_REQUEST: 'GET_ORG_INDEX_V2_REQUEST',
    GET_ORG_INDEX_V2_SUCCESS: 'GET_ORG_INDEX_V2_SUCCESS',
    GET_ORG_INDEX_V2_FAILURE: 'GET_ORG_INDEX_V2_FAILURE',




    GET_DEMOGRAPHIC_INDEX_HAPPINESS_REQUEST: 'GET_DEMOGRAPHIC_INDEX_HAPPINESS_REQUEST',
    GET_DEMOGRAPHIC_INDEX_HAPPINESS_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_HAPPINESS_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_HAPPINESS_FAILURE: 'GET_DEMOGRAPHIC_INDEX_HAPPINESS_FAILURE',

    GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_REQUEST: 'GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_REQUEST',
    GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_FAILURE: 'GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_FAILURE',

    GET_DEMOGRAPHIC_INDEX_STRESS_REQUEST: 'GET_DEMOGRAPHIC_INDEX_STRESS_REQUEST',
    GET_DEMOGRAPHIC_INDEX_STRESS_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_STRESS_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_STRESS_FAILURE: 'GET_DEMOGRAPHIC_INDEX_STRESS_FAILURE',

    GET_DEMOGRAPHIC_INDEX_MANAGER_REQUEST: 'GET_DEMOGRAPHIC_INDEX_MANAGER_REQUEST',
    GET_DEMOGRAPHIC_INDEX_MANAGER_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_MANAGER_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_MANAGER_FAILURE: 'GET_DEMOGRAPHIC_INDEX_MANAGER_FAILURE',

    GET_DEMOGRAPHIC_INDEX_LEADER_REQUEST: 'GET_DEMOGRAPHIC_INDEX_LEADER_REQUEST',
    GET_DEMOGRAPHIC_INDEX_LEADER_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_LEADER_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_LEADER_FAILURE: 'GET_DEMOGRAPHIC_INDEX_LEADER_FAILURE',

    GET_DEMOGRAPHIC_INDEX_ORG_REQUEST: 'GET_DEMOGRAPHIC_INDEX_ORG_REQUEST',
    GET_DEMOGRAPHIC_INDEX_ORG_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_ORG_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_ORG_FAILURE: 'GET_DEMOGRAPHIC_INDEX_ORG_FAILURE',


    GET_DEMOGRAPHIC_INDEX_EXPERIENCE_REQUEST: 'GET_DEMOGRAPHIC_INDEX_EXPERIENCE_REQUEST',
    GET_DEMOGRAPHIC_INDEX_EXPERIENCE_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_EXPERIENCE_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_EXPERIENCE_FAILURE: 'GET_DEMOGRAPHIC_INDEX_EXPERIENCE_FAILURE',




    GET_HAPPINESS_INDEX_DRIVER_REQUEST: 'GET_HAPPINESS_INDEX_DRIVER_REQUEST',
    GET_HAPPINESS_INDEX_DRIVER_SUCCESS: 'GET_HAPPINESS_INDEX_DRIVER_SUCCESS',
    GET_HAPPINESS_INDEX_DRIVER_FAILURE: 'GET_HAPPINESS_INDEX_DRIVER_FAILURE',

    GET_ENGAGEMENT_INDEX_DRIVER_REQUEST: 'GET_ENGAGEMENT_INDEX_DRIVER_REQUEST',
    GET_ENGAGEMENT_INDEX_DRIVER_SUCCESS: 'GET_ENGAGEMENT_INDEX_DRIVER_SUCCESS',
    GET_ENGAGEMENT_INDEX_DRIVER_FAILURE: 'GET_ENGAGEMENT_INDEX_DRIVER_FAILURE',

    GET_STRESS_INDEX_DRIVER_REQUEST: 'GET_STRESS_INDEX_DRIVER_REQUEST',
    GET_STRESS_INDEX_DRIVER_SUCCESS: 'GET_STRESS_INDEX_DRIVER_SUCCESS',
    GET_STRESS_INDEX_DRIVER_FAILURE: 'GET_STRESS_INDEX_DRIVER_FAILURE',

    GET_MANAGER_INDEX_DRIVER_REQUEST: 'GET_MANAGER_INDEX_DRIVER_REQUEST',
    GET_MANAGER_INDEX_DRIVER_SUCCESS: 'GET_MANAGER_INDEX_DRIVER_SUCCESS',
    GET_MANAGER_INDEX_DRIVER_FAILURE: 'GET_MANAGER_INDEX_DRIVER_FAILURE',

    GET_EXPERIENCE_INDEX_DRIVER_REQUEST: 'GET_EXPERIENCE_INDEX_DRIVER_REQUEST',
    GET_EXPERIENCE_INDEX_DRIVER_SUCCESS: 'GET_EXPERIENCE_INDEX_DRIVER_SUCCESS',
    GET_EXPERIENCE_INDEX_DRIVER_FAILURE: 'GET_EXPERIENCE_INDEX_DRIVER_FAILURE',


    GET_LEADER_INDEX_DRIVER_REQUEST: 'GET_LEADER_INDEX_DRIVER_REQUEST',
    GET_LEADER_INDEX_DRIVER_SUCCESS: 'GET_LEADER_INDEX_DRIVER_SUCCESS',
    GET_LEADER_INDEX_DRIVER_FAILURE: 'GET_LEADER_INDEX_DRIVER_FAILURE',

    GET_ORG_INDEX_DRIVER_REQUEST: 'GET_ORG_INDEX_DRIVER_REQUEST',
    GET_ORG_INDEX_DRIVER_SUCCESS: 'GET_ORG_INDEX_DRIVER_SUCCESS',
    GET_ORG_INDEX_DRIVER_FAILURE: 'GET_ORG_INDEX_DRIVER_FAILURE',

    GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_REQUEST: 'GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_REQUEST',
    GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_SUCCESS: 'GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_SUCCESS',
    GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_FAILURE: 'GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_FAILURE',

    GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_REQUEST: 'GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_REQUEST',
    GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_SUCCESS: 'GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_SUCCESS',
    GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_FAILURE: 'GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_FAILURE',

    GET_STRESS_INDEX_V2_DEMOGRAPHIC_REQUEST: 'GET_STRESS_INDEX_V2_DEMOGRAPHIC_REQUEST',
    GET_STRESS_INDEX_V2_DEMOGRAPHIC_SUCCESS: 'GET_STRESS_INDEX_V2_DEMOGRAPHIC_SUCCESS',
    GET_STRESS_INDEX_V2_DEMOGRAPHIC_FAILURE: 'GET_STRESS_INDEX_V2_DEMOGRAPHIC_FAILURE',

    GET_INDEX_BY_MANAGER_WISE_REQUEST: 'GET_INDEX_BY_MANAGER_WISE_REQUEST',
    GET_INDEX_BY_MANAGER_WISE_SUCCESS: 'GET_INDEX_BY_MANAGER_WISE_SUCCESS',
    GET_INDEX_BY_MANAGER_WISE_FAILURE: 'GET_INDEX_BY_MANAGER_WISE_FAILURE',

    GET_ALL_BENCHMARK_REQUEST: 'GET_ALL_BENCHMARK_REQUEST',
    GET_ALL_BENCHMARK_SUCCESS: 'GET_ALL_BENCHMARK_SUCCESS',
    GET_ALL_BENCHMARK_FAILURE: 'GET_ALL_BENCHMARK_FAILURE',

    GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_REQUEST: 'GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_REQUEST',
    GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_FAILURE: 'GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_FAILURE',

    GET_RESPONDANT_DETAILS_REQUEST: 'GET_RESPONDANT_DETAILS_REQUEST',
    GET_RESPONDANT_DETAILS_SUCCESS: 'GET_RESPONDANT_DETAILS_SUCCESS',
    GET_RESPONDANT_DETAILS_FAILURE: 'GET_RESPONDANT_DETAILS_FAILURE',


    GET_RESPONDANT_DETAILS_2_REQUEST: 'GET_RESPONDANT_DETAILS_2_REQUEST',
    GET_RESPONDANT_DETAILS_2_SUCCESS: 'GET_RESPONDANT_DETAILS_2_SUCCESS',
    GET_RESPONDANT_DETAILS_2_FAILURE: 'GET_RESPONDANT_DETAILS_2_FAILURE',


    GET_META_360_RESPONDANT_DETAILS_REQUEST: 'GET_META_360_RESPONDANT_DETAILS_REQUEST',
    GET_META_360_RESPONDANT_DETAILS_SUCCESS: 'GET_META_360_RESPONDANT_DETAILS_SUCCESS',
    GET_META_360_RESPONDANT_DETAILS_FAILURE: 'GET_META_360_RESPONDANT_DETAILS_FAILURE',

    GET_META_RESPONDANT_DETAILS_REQUEST: 'GET_META_RESPONDANT_DETAILS_REQUEST',
    GET_META_RESPONDANT_DETAILS_SUCCESS: 'GET_META_RESPONDANT_DETAILS_SUCCESS',
    GET_META_RESPONDANT_DETAILS_FAILURE: 'GET_META_RESPONDANT_DETAILS_FAILURE',

    GET_META_RESPONDANT_DETAILS_2_REQUEST: 'GET_META_RESPONDANT_DETAILS_2_REQUEST',
    GET_META_RESPONDANT_DETAILS_2_SUCCESS: 'GET_META_RESPONDANT_DETAILS_2_SUCCESS',
    GET_META_RESPONDANT_DETAILS_2_FAILURE: 'GET_META_RESPONDANT_DETAILS_2_FAILURE',


    GET_CUSTOMER_RESPONDANT_DETAILS_REQUEST: 'GET_CUSTOMER_RESPONDANT_DETAILS_REQUEST',
    GET_CUSTOMER_RESPONDANT_DETAILS_SUCCESS: 'GET_CUSTOMER_RESPONDANT_DETAILS_SUCCESS',
    GET_CUSTOMER_RESPONDANT_DETAILS_FAILURE: 'GET_CUSTOMER_RESPONDANT_DETAILS_FAILURE',

    GET_MANAGER_LIST_DOMAIN_FILTER_REQUEST: 'GET_MANAGER_LIST_DOMAIN_FILTER_REQUEST',
    GET_MANAGER_LIST_DOMAIN_FILTER_SUCCESS: 'GET_MANAGER_LIST_DOMAIN_FILTER_SUCCESS',
    GET_MANAGER_LIST_DOMAIN_FILTER_FAILURE: 'GET_MANAGER_LIST_DOMAIN_FILTER_FAILURE',


    GET_QUESTION_MANAGER_OF_HAPPINESS_REQUEST: 'GET_QUESTION_MANAGER_OF_HAPPINESS_REQUEST',
    GET_QUESTION_MANAGER_OF_HAPPINESS_SUCCESS: 'GET_QUESTION_MANAGER_OF_HAPPINESS_SUCCESS',
    GET_QUESTION_MANAGER_OF_HAPPINESS_FAILURE: 'GET_QUESTION_MANAGER_OF_HAPPINESS_FAILURE',

    GET_QUESTION_MANAGER_OF_ENGAGEMENT_REQUEST: 'GET_QUESTION_MANAGER_OF_ENGAGEMENT_REQUEST',
    GET_QUESTION_MANAGER_OF_ENGAGEMENT_SUCCESS: 'GET_QUESTION_MANAGER_OF_ENGAGEMENT_SUCCESS',
    GET_QUESTION_MANAGER_OF_ENGAGEMENT_FAILURE: 'GET_QUESTION_MANAGER_OF_ENGAGEMENT_FAILURE',

    GET_QUESTION_MANAGER_OF_STRESS_REQUEST: 'GET_QUESTION_MANAGER_OF_STRESS_REQUEST',
    GET_QUESTION_MANAGER_OF_STRESS_SUCCESS: 'GET_QUESTION_MANAGER_OF_STRESS_SUCCESS',
    GET_QUESTION_MANAGER_OF_STRESS_FAILURE: 'GET_QUESTION_MANAGER_OF_STRESS_FAILURE',

    GET_QUESTION_MANAGER_OF_MANAGER_REQUEST: 'GET_QUESTION_MANAGER_OF_MANAGER_REQUEST',
    GET_QUESTION_MANAGER_OF_MANAGER_SUCCESS: 'GET_QUESTION_MANAGER_OF_MANAGER_SUCCESS',
    GET_QUESTION_MANAGER_OF_MANAGER_FAILURE: 'GET_QUESTION_MANAGER_OF_MANAGER_FAILURE',

    GET_INDEX_QUANTILE_REQUEST: 'GET_INDEX_QUANTILE_REQUEST',
    GET_INDEX_QUANTILE_SUCCESS: 'GET_INDEX_QUANTILE_SUCCESS',
    GET_INDEX_QUANTILE_FAILURE: 'GET_INDEX_QUANTILE_FAILURE',


    GET_DEMOGRAPHIC_INDEX_SCATTER_REQUEST: 'GET_DEMOGRAPHIC_INDEX_SCATTER_REQUEST',
    GET_DEMOGRAPHIC_INDEX_SCATTER_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_SCATTER_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_SCATTER_FAILURE: 'GET_DEMOGRAPHIC_INDEX_SCATTER_FAILURE',


    GET_HAPPINESS_CORRELATION_REQUEST: 'GET_HAPPINESS_CORRELATION_REQUEST',
    GET_HAPPINESS_CORRELATION_SUCCESS: 'GET_HAPPINESS_CORRELATION_SUCCESS',
    GET_HAPPINESS_CORRELATION_FAILURE: 'GET_HAPPINESS_CORRELATION_FAILURE',

    GET_ENGAGEMENT_CORRELATION_REQUEST: 'GET_ENGAGEMENT_CORRELATION_REQUEST',
    GET_ENGAGEMENT_CORRELATION_SUCCESS: 'GET_ENGAGEMENT_CORRELATION_SUCCESS',
    GET_ENGAGEMENT_CORRELATION_FAILURE: 'GET_ENGAGEMENT_CORRELATION_FAILURE',

    GET_STRESS_CORRELATION_REQUEST: 'GET_STRESS_CORRELATION_REQUEST',
    GET_STRESS_CORRELATION_SUCCESS: 'GET_STRESS_CORRELATION_SUCCESS',
    GET_STRESS_CORRELATION_FAILURE: 'GET_STRESS_CORRELATION_FAILURE',

    GET_MANAGER_CORRELATION_REQUEST: 'GET_MANAGER_CORRELATION_REQUEST',
    GET_MANAGER_CORRELATION_SUCCESS: 'GET_MANAGER_CORRELATION_SUCCESS',
    GET_MANAGER_CORRELATION_FAILURE: 'GET_MANAGER_CORRELATION_FAILURE',

    GET_LEADER_CORRELATION_REQUEST: 'GET_LEADER_CORRELATION_REQUEST',
    GET_LEADER_CORRELATION_SUCCESS: 'GET_LEADER_CORRELATION_SUCCESS',
    GET_LEADER_CORRELATION_FAILURE: 'GET_LEADER_CORRELATION_FAILURE',

    GET_RWA_REQUEST: 'GET_RWA_REQUEST',
    GET_RWA_SUCCESS: 'GET_RWA_SUCCESS',
    GET_RWA_FAILURE: 'GET_RWA_FAILURE',

    GET_TEXT_QUESTION_REQUEST: 'GET_TEXT_QUESTION_REQUEST',
    GET_TEXT_QUESTION_SUCCESS: 'GET_TEXT_QUESTION_SUCCESS',
    GET_TEXT_QUESTION_FAILURE: 'GET_TEXT_QUESTION_FAILURE',

    GET_NAME_QUESTION_REQUEST: 'GET_NAME_QUESTION_REQUEST',
    GET_NAME_QUESTION_SUCCESS: 'GET_NAME_QUESTION_SUCCESS',
    GET_NAME_QUESTION_FAILURE: 'GET_NAME_QUESTION_FAILURE',

    WORD_CLOUD_REQUEST: 'WORD_CLOUD_REQUEST',
    WORD_CLOUD_SUCCESS: 'WORD_CLOUD_SUCCESS',
    WORD_CLOUD_FAILURE: 'WORD_CLOUD_FAILURE',

    GET_ALL_FEEDBACK_WORDS_REQUEST: 'GET_ALL_FEEDBACK_WORDS_REQUEST',
    GET_ALL_FEEDBACK_WORDS_SUCCESS: 'GET_ALL_FEEDBACK_WORDS_SUCCESS',
    GET_ALL_FEEDBACK_WORDS_FAILURE: 'GET_ALL_FEEDBACK_WORDS_FAILURE',

    TEXT_CLUSTER_REQUEST: 'TEXT_CLUSTER_REQUEST',
    TEXT_CLUSTER_SUCCESS: 'TEXT_CLUSTER_SUCCESS',
    TEXT_CLUSTER_FAILURE: 'TEXT_CLUSTER_FAILURE',

    ADVANCE_WORD_CLOUD_REQUEST: 'ADVANCE_WORD_CLOUD_REQUEST',
    ADVANCE_WORD_CLOUD_SUCCESS: 'ADVANCE_WORD_CLOUD_SUCCESS',
    ADVANCE_WORD_CLOUD_FAILURE: 'ADVANCE_WORD_CLOUD_FAILURE',

    WORD_CLOUD_TEXT_REQUEST: 'WORD_CLOUD_TEXT_REQUEST',
    WORD_CLOUD_TEXT_SUCCESS: 'WORD_CLOUD_TEXT_SUCCESS',
    WORD_CLOUD_TEXT_FAILURE: 'WORD_CLOUD_TEXT_FAILURE',

    WORD_CLOUD_MULTI_TEXT_REQUEST: 'WORD_CLOUD_MULTI_TEXT_REQUEST',
    WORD_CLOUD_MULTI_TEXT_SUCCESS: 'WORD_CLOUD_MULTI_TEXT_SUCCESS',
    WORD_CLOUD_MULTI_TEXT_FAILURE: 'WORD_CLOUD_MULTI_TEXT_FAILURE',

    COOCCUR_MATRIX_REQUEST: 'COOCCUR_MATRIX_REQUEST',
    COOCCUR_MATRIX_SUCCESS: 'COOCCUR_MATRIX_SUCCESS',
    COOCCUR_MATRIX_FAILURE: 'COOCCUR_MATRIX_FAILURE',

    NEIGHBOR_GRAPH_REQUEST: 'NEIGHBOR_GRAPH_REQUEST',
    NEIGHBOR_GRAPH_SUCCESS: 'NEIGHBOR_GRAPH_SUCCESS',
    NEIGHBOR_GRAPH_FAILURE: 'NEIGHBOR_GRAPH_FAILURE',

    GET_HAPINESS_RWA_REQUEST: 'GET_HAPINESS_RWA_REQUEST',
    GET_HAPINESS_RWA_SUCCESS: 'GET_HAPINESS_RWA_SUCCESS',
    GET_HAPINESS_RWA_FAILURE: 'GET_HAPINESS_RWA_FAILURE',

    GET_ENGAGEMENT_RWA_REQUEST: 'GET_ENGAGEMENT_RWA_REQUEST',
    GET_ENGAGEMENT_RWA_SUCCESS: 'GET_ENGAGEMENT_RWA_SUCCESS',
    GET_ENGAGEMENT_RWA_FAILURE: 'GET_ENGAGEMENT_RWA_FAILURE',

    GET_STRESS_RWA_REQUEST: 'GET_STRESS_RWA_REQUEST',
    GET_STRESS_RWA_SUCCESS: 'GET_STRESS_RWA_SUCCESS',
    GET_STRESS_RWA_FAILURE: 'GET_STRESS_RWA_FAILURE',

    GET_MANAGER_RWA_REQUEST: 'GET_MANAGER_RWA_REQUEST',
    GET_MANAGER_RWA_SUCCESS: 'GET_MANAGER_RWA_SUCCESS',
    GET_MANAGER_RWA_FAILURE: 'GET_MANAGER_RWA_FAILURE',

    GET_LEADER_RWA_REQUEST: 'GET_LEADER_RWA_REQUEST',
    GET_LEADER_RWA_SUCCESS: 'GET_LEADER_RWA_SUCCESS',
    GET_LEADER_RWA_FAILURE: 'GET_LEADER_RWA_FAILURE',


    CRONE_BATCH_ALPHA_VARIANCE_REQUEST: 'CRONE_BATCH_ALPHA_VARIANCE_REQUEST',
    CRONE_BATCH_ALPHA_VARIANCE_SUCCESS: 'CRONE_BATCH_ALPHA_VARIANCE_SUCCESS',
    CRONE_BATCH_ALPHA_VARIANCE_SUCCESS_TWO: 'CRONE_BATCH_ALPHA_VARIANCE_SUCCESS_TWO',
    CRONE_BATCH_ALPHA_VARIANCE_FAILURE: 'CRONE_BATCH_ALPHA_VARIANCE_FAILURE',

    GET_QUESTION_FAVOURABILITY_REQUEST: 'GET_QUESTION_FAVOURABILITY_REQUEST',
    GET_QUESTION_FAVOURABILITY_SUCCESS: 'GET_QUESTION_FAVOURABILITY_SUCCESS',
    GET_QUESTION_FAVOURABILITY_FAILURE: 'GET_QUESTION_FAVOURABILITY_FAILURE',

    GET_HISTORY_INDEX_REQUEST: 'GET_HISTORY_INDEX_REQUEST',
    GET_HISTORY_INDEX_SUCCESS: 'GET_HISTORY_INDEX_SUCCESS',
    GET_HISTORY_INDEX_FAILURE: 'GET_HISTORY_INDEX_FAILURE',


    GET_RESPONSE_ANALYTIYCS_REQUEST: 'GET_RESPONSE_ANALYTIYCS_REQUEST',
    GET_RESPONSE_ANALYTIYCS_SUCCESS: 'GET_RESPONSE_ANALYTIYCS_SUCCESS',
    GET_RESPONSE_ANALYTIYCS_FAILURE: 'GET_RESPONSE_ANALYTIYCS_FAILURE',

    GET_RESPONSE_META_ANALYTIYCS_REQUEST: 'GET_RESPONSE_META_ANALYTIYCS_REQUEST',
    GET_RESPONSE_META_ANALYTIYCS_SUCCESS: 'GET_RESPONSE_META_ANALYTIYCS_SUCCESS',
    GET_RESPONSE_META_ANALYTIYCS_FAILURE: 'GET_RESPONSE_META_ANALYTIYCS_FAILURE',

    GET_RESPONSE_META_360_ANALYTIYCS_REQUEST: 'GET_RESPONSE_META_360_ANALYTIYCS_REQUEST',
    GET_RESPONSE_META_360_ANALYTIYCS_SUCCESS: 'GET_RESPONSE_META_360_ANALYTIYCS_SUCCESS',
    GET_RESPONSE_META_360_ANALYTIYCS_FAILURE: 'GET_RESPONSE_META_360_ANALYTIYCS_FAILURE',

    GET_RESPONSE_CUST_V2_ANALYTIYCS_REQUEST: 'GET_RESPONSE_CUST_V2_ANALYTIYCS_REQUEST',
    GET_RESPONSE_CUST_V2_ANALYTIYCS_SUCCESS: 'GET_RESPONSE_CUST_V2_ANALYTIYCS_SUCCESS',
    GET_RESPONSE_CUST_V2_ANALYTIYCS_FAILURE: 'GET_RESPONSE_CUST_V2_ANALYTIYCS_FAILURE',


    GET_COMMENT_REQUEST: 'GET_COMMENT_REQUEST',
    GET_COMMENT_SUCCESS: 'GET_COMMENT_SUCCESS',
    GET_COMMENT_FAILURE: 'GET_COMMENT_FAILURE',

    GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_REQUEST: 'GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_REQUEST',
    GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_SUCCESS: 'GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_SUCCESS',
    GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_FAILURE: 'GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_FAILURE',

    GET_MULTIMANAGER_QUESTIONLIST_REQUEST: 'GET_MULTIMANAGER_QUESTIONLIST_REQUEST',
    GET_MULTIMANAGER_QUESTIONLIST_SUCCESS: 'GET_MULTIMANAGER_QUESTIONLIST_SUCCESS',
    GET_MULTIMANAGER_QUESTIONLIST_FAILURE: 'GET_MULTIMANAGER_QUESTIONLIST_FAILURE',

    GET_DEMOGRAPHIC_MULTI_FILTER_REQUEST: 'GET_DEMOGRAPHIC_MULTI_FILTER_REQUEST',
    GET_DEMOGRAPHIC_MULTI_FILTER_SUCCESS: 'GET_DEMOGRAPHIC_MULTI_FILTER_SUCCESS',
    GET_DEMOGRAPHIC_MULTI_FILTER_FAILURE: 'GET_DEMOGRAPHIC_MULTI_FILTER_FAILURE',

    GET_DEMOGRAPHIC_MULTI_AXIS_FILTER_REQUEST: 'GET_DEMOGRAPHIC_MULTI_AXIS_FILTER_REQUEST',
    GET_DEMOGRAPHIC_MULTI_AXIS_FILTER_SUCCESS: 'GET_DEMOGRAPHIC_MULTI_AXIS_FILTER_SUCCESS',
    GET_DEMOGRAPHIC_MULTI_AXIS_FILTER_FAILURE: 'GET_DEMOGRAPHIC_MULTI_AXIS_FILTER_FAILURE',

    ASSIGN_MANAGER_ACTION_PLAN_REQUEST: 'ASSIGN_MANAGER_ACTION_PLAN_REQUEST',
    ASSIGN_MANAGER_ACTION_PLAN_SUCCESS: 'ASSIGN_MANAGER_ACTION_PLAN_SUCCESS',
    ASSIGN_MANAGER_ACTION_PLAN_FAILURE: 'ASSIGN_MANAGER_ACTION_PLAN_FAILURE',

    GET_MANAGER_ACTION_PLAN_REQUEST: 'GET_MANAGER_ACTION_PLAN_REQUEST',
    GET_MANAGER_ACTION_PLAN_SUCCESS: 'GET_MANAGER_ACTION_PLAN_SUCCESS',
    GET_MANAGER_ACTION_PLAN_FAILURE: 'GET_MANAGER_ACTION_PLAN_FAILURE',

    GET_EMPLOYEE_ACTION_PLAN_REQUEST: 'GET_EMPLOYEE_ACTION_PLAN_REQUEST',
    GET_EMPLOYEE_ACTION_PLAN_SUCCESS: 'GET_EMPLOYEE_ACTION_PLAN_SUCCESS',
    GET_EMPLOYEE_ACTION_PLAN_FAILURE: 'GET_EMPLOYEE_ACTION_PLAN_FAILURE',

    UPDATE_EMPLOYEE_TASK_REQUEST: 'UPDATE_EMPLOYEE_TASK_REQUEST',
    UPDATE_EMPLOYEE_TASK_SUCCESS: 'UPDATE_EMPLOYEE_TASK_SUCCESS',
    UPDATE_EMPLOYEE_TASK_FAILURE: 'UPDATE_EMPLOYEE_TASK_FAILURE',

    UPDATE_EMPLOYEE_RESOURCE_REQUEST: 'UPDATE_EMPLOYEE_RESOURCE_REQUEST',
    UPDATE_EMPLOYEE_RESOURCE_SUCCESS: 'UPDATE_EMPLOYEE_RESOURCE_SUCCESS',
    UPDATE_EMPLOYEE_RESOURCE_FAILURE: 'UPDATE_EMPLOYEE_RESOURCE_FAILURE',

    GET_ALL_MANAGER_ACTION_PLAN_REQUEST: 'GET_ALL_MANAGER_ACTION_PLAN_REQUEST',
    GET_ALL_MANAGER_ACTION_PLAN_SUCCESS: 'GET_ALL_MANAGER_ACTION_PLAN_SUCCESS',
    GET_ALL_MANAGER_ACTION_PLAN_FAILURE: 'GET_ALL_MANAGER_ACTION_PLAN_FAILURE',



    GET_QUESTION_ONLY_STRESS_REQUEST: 'GET_QUESTION_ONLY_STRESS_REQUEST',
    GET_QUESTION_ONLY_STRESS_SUCCESS: 'GET_QUESTION_ONLY_STRESS_SUCCESS',
    GET_QUESTION_ONLY_STRESS_FAILURE: 'GET_QUESTION_ONLY_STRESS_FAILURE',

    GET_QUESTION_INDEX_ORG_REQUEST: 'GET_QUESTION_INDEX_ORG_REQUEST',
    GET_QUESTION_INDEX_ORG_SUCCESS: 'GET_QUESTION_INDEX_ORG_SUCCESS',
    GET_QUESTION_INDEX_ORG_FAILURE: 'GET_QUESTION_INDEX_ORG_FAILURE',



    CROSS_TAB_REQUEST: 'CROSS_TAB_REQUEST',
    CROSS_TAB_SUCCESS: 'CROSS_TAB_SUCCESS',
    CROSS_TAB_FAILURE: 'CROSS_TAB_FAILURE',

    CROSS_TAB_DMG_REQUEST: 'CROSS_TAB_DMG_REQUEST',
    CROSS_TAB_DMG_SUCCESS: 'CROSS_TAB_DMG_SUCCESS',
    CROSS_TAB_DMG_FAILURE: 'CROSS_TAB_DMG_FAILURE',

    GET_QUESTION_OVERALL_REQUEST: 'GET_QUESTION_OVERALL_REQUEST',
    GET_QUESTION_OVERALL_SUCCESS: 'GET_QUESTION_OVERALL_SUCCESS',
    GET_QUESTION_OVERALL_FAILURE: 'GET_QUESTION_OVERALL_FAILURE',

    CROSS_TAB_ANALYSIS_REQUEST: 'CROSS_TAB_ANALYSIS_REQUEST',
    CROSS_TAB_ANALYSIS_SUCCESS: 'CROSS_TAB_ANALYSIS_SUCCESS',
    CROSS_TAB_ANALYSIS_FAILURE: 'CROSS_TAB_ANALYSIS_FAILURE',

    GET_ALL_PARAMETER_LIST_REQUEST: 'GET_ALL_PARAMETER_LIST_REQUEST',
    GET_ALL_PARAMETER_LIST_SUCCESS: 'GET_ALL_PARAMETER_LIST_SUCCESS',
    GET_ALL_PARAMETER_LIST_FAILURE: 'GET_ALL_PARAMETER_LIST_FAILURE',

    GET_CORRELATION_OVERALL_REQUEST: 'GET_CORRELATION_OVERALL_REQUEST',
    GET_CORRELATION_OVERALL_SUCCESS: 'GET_CORRELATION_OVERALL_SUCCESS',
    GET_CORRELATION_OVERALL_FAILURE: 'GET_CORRELATION_OVERALL_FAILURE',

    GET_CORRELATION_FILTER_REQUEST: 'GET_CORRELATION_FILTER_REQUEST',
    GET_CORRELATION_FILTER_SUCCESS: 'GET_CORRELATION_FILTER_SUCCESS',
    GET_CORRELATION_FILTER_FAILURE: 'GET_CORRELATION_FILTER_FAILURE',

    CORRELATION_TYPE2_ANALYSIS_REQUEST: 'CORRELATION_TYPE2_ANALYSIS_REQUEST',
    CORRELATION_TYPE2_ANALYSIS_SUCCESS: 'CORRELATION_TYPE2_ANALYSIS_SUCCESS',
    CORRELATION_TYPE2_ANALYSIS_FAILURE: 'CORRELATION_TYPE2_ANALYSIS_FAILURE',

    GET_QUESTION_FAVOURABILITY_2_REQUEST: 'GET_QUESTION_FAVOURABILITY_2_REQUEST',
    GET_QUESTION_FAVOURABILITY_2_SUCCESS: 'GET_QUESTION_FAVOURABILITY_2_SUCCESS',
    GET_QUESTION_FAVOURABILITY_2_FAILURE: 'GET_QUESTION_FAVOURABILITY_2_FAILURE',

    GET_QUESTION_FAVOURABILITY_API_2_REQUEST: 'GET_QUESTION_FAVOURABILITY_API_2_REQUEST',
    GET_QUESTION_FAVOURABILITY_API_2_SUCCESS: 'GET_QUESTION_FAVOURABILITY_API_2_SUCCESS',
    GET_QUESTION_FAVOURABILITY_API_2_FAILURE: 'GET_QUESTION_FAVOURABILITY_API_2_FAILURE',

    GET_QUESTION_FAVOURABILITY_API_4_REQUEST: 'GET_QUESTION_FAVOURABILITY_API_4_REQUEST',
    GET_QUESTION_FAVOURABILITY_API_4_SUCCESS: 'GET_QUESTION_FAVOURABILITY_API_4_SUCCESS',
    GET_QUESTION_FAVOURABILITY_API_4_FAILURE: 'GET_QUESTION_FAVOURABILITY_API_4_FAILURE',


    //MANAGER
    GET_MANAGER_PROFILE_DATA_REQUEST: 'GET_MANAGER_PROFILE_DATA_REQUEST',
    GET_MANAGER_PROFILE_DATA_SUCCESS: 'GET_MANAGER_PROFILE_DATA_SUCCESS',
    GET_MANAGER_PROFILE_DATA_FAILURE: 'GET_MANAGER_PROFILE_DATA_FAILURE',


    GET_MANAGER_SURVEY_LIST_REQUEST: 'GET_MANAGER_SURVEY_LIST_REQUEST',
    GET_MANAGER_SURVEY_LIST_SUCCESS: 'GET_MANAGER_SURVEY_LIST_SUCCESS',
    GET_MANAGER_SURVEY_LIST_FAILURE: 'GET_MANAGER_SURVEY_LIST_FAILURE',



    GET_HAPPINESS_INDEX_BY_FILTER_REQUEST: 'GET_HAPPINESS_INDEX_BY_FILTER_REQUEST',
    GET_HAPPINESS_INDEX_BY_FILTER_SUCCESS: 'GET_HAPPINESS_INDEX_BY_FILTER_SUCCESS',
    GET_HAPPINESS_INDEX_BY_FILTER_FAILURE: 'GET_HAPPINESS_INDEX_BY_FILTER_FAILURE',

    GET_ENGAGEMENT_INDEX_BY_FILTER_REQUEST: 'GET_ENGAGEMENT_INDEX_BY_FILTER_REQUEST',
    GET_ENGAGEMENT_INDEX_BY_FILTER_SUCCESS: 'GET_ENGAGEMENT_INDEX_BY_FILTER_SUCCESS',
    GET_ENGAGEMENT_INDEX_BY_FILTER_FAILURE: 'GET_ENGAGEMENT_INDEX_BY_FILTER_FAILURE',

    GET_STRESS_INDEX_BY_FILTER_REQUEST: 'GET_STRESS_INDEX_BY_FILTER_REQUEST',
    GET_STRESS_INDEX_BY_FILTER_SUCCESS: 'GET_STRESS_INDEX_BY_FILTER_SUCCESS',
    GET_STRESS_INDEX_BY_FILTER_FAILURE: 'GET_STRESS_INDEX_BY_FILTER_FAILURE',

    GET_MANAGER_INDEX_BY_FILTER_REQUEST: 'GET_MANAGER_INDEX_BY_FILTER_REQUEST',
    GET_MANAGER_INDEX_BY_FILTER_SUCCESS: 'GET_MANAGER_INDEX_BY_FILTER_SUCCESS',
    GET_MANAGER_INDEX_BY_FILTER_FAILURE: 'GET_MANAGER_INDEX_BY_FILTER_FAILURE',

    GET_LEADER_INDEX_BY_FILTER_REQUEST: 'GET_LEADER_INDEX_BY_FILTER_REQUEST',
    GET_LEADER_INDEX_BY_FILTER_SUCCESS: 'GET_LEADER_INDEX_BY_FILTER_SUCCESS',
    GET_LEADER_INDEX_BY_FILTER_FAILURE: 'GET_LEADER_INDEX_BY_FILTER_FAILURE',

    GET_ENGAGEMENT_COMPARISON_REQUEST: 'GET_ENGAGEMENT_COMPARISON_REQUEST',
    GET_ENGAGEMENT_COMPARISON_SUCCESS: 'GET_ENGAGEMENT_COMPARISON_SUCCESS',
    GET_ENGAGEMENT_COMPARISON_FAILURE: 'GET_ENGAGEMENT_COMPARISON_FAILURE',

    GET_ENGAGEMENT_COMPARISON_2_REQUEST: 'GET_ENGAGEMENT_COMPARISON_2_REQUEST',
    GET_ENGAGEMENT_COMPARISON_2_SUCCESS: 'GET_ENGAGEMENT_COMPARISON_2_SUCCESS',
    GET_ENGAGEMENT_COMPARISON_2_FAILURE: 'GET_ENGAGEMENT_COMPARISON_2_FAILURE',


    GET_QUESTION_COMMENT_SUCCESS: 'GET_QUESTION_COMMENT_SUCCESS',


    GET_BRANCODE_INDEX_SCORE_REQUEST: 'GET_BRANCODE_INDEX_SCORE_REQUEST',
    GET_BRANCODE_INDEX_SCORE_SUCCESS: 'GET_BRANCODE_INDEX_SCORE_SUCCESS',
    GET_BRANCODE_INDEX_SCORE_FAILURE: 'GET_BRANCODE_INDEX_SCORE_FAILURE',

    GET_PARAMETER_TYPE_LIST_REQUEST: 'GET_PARAMETER_TYPE_LIST_REQUEST',
    GET_PARAMETER_TYPE_LIST_SUCCESS: 'GET_PARAMETER_TYPE_LIST_SUCCESS',
    GET_PARAMETER_TYPE_LIST_FAILURE: 'GET_PARAMETER_TYPE_LIST_FAILURE',


    CREATE_PARAMETER_REQUEST: 'CREATE_PARAMETER_REQUEST',
    CREATE_PARAMETER_SUCCESS: 'CREATE_PARAMETER_SUCCESS',
    CREATE_PARAMETER_FAILURE: 'CREATE_PARAMETER_FAILURE',


    GET_ORG_INDEX_FILTER_REQUEST: 'GET_ORG_INDEX_FILTER_REQUEST',
    GET_ORG_INDEX_FILTER_SUCCESS: 'GET_ORG_INDEX_FILTER_SUCCESS',
    GET_ORG_INDEX_FILTER_FAILURE: 'GET_ORG_INDEX_FILTER_FAILURE',

    GET_ORG_INDEX_FILTER_2_REQUEST: 'GET_ORG_INDEX_FILTER_2_REQUEST',
    GET_ORG_INDEX_FILTER_2_SUCCESS: 'GET_ORG_INDEX_FILTER_2_SUCCESS',
    GET_ORG_INDEX_FILTER_2_FAILURE: 'GET_ORG_INDEX_FILTER_2_FAILURE',


    GET_HAPINESS_INDEX_V2_FILTER_REQUEST: 'GET_HAPINESS_INDEX_V2_FILTER_REQUEST',
    GET_HAPINESS_INDEX_V2_FILTER_SUCCESS: 'GET_HAPINESS_INDEX_V2_FILTER_SUCCESS',
    GET_HAPINESS_INDEX_V2_FILTER_FAILURE: 'GET_HAPINESS_INDEX_V2_FILTER_FAILURE',

    GET_HAPINESS_INDEX_V2_2_FILTER_REQUEST: 'GET_HAPINESS_INDEX_V2_2_FILTER_REQUEST',
    GET_HAPINESS_INDEX_V2_2_FILTER_SUCCESS: 'GET_HAPINESS_INDEX_V2_2_FILTER_SUCCESS',
    GET_HAPINESS_INDEX_V2_2_FILTER_FAILURE: 'GET_HAPINESS_INDEX_V2_2_FILTER_FAILURE',



    GET_ENGAGEMENT_INDEX_V2_FILTER_REQUEST: 'GET_ENGAGEMENT_INDEX_V2_FILTER_REQUEST',
    GET_ENGAGEMENT_INDEX_V2_FILTER_SUCCESS: 'GET_ENGAGEMENT_INDEX_V2_FILTER_SUCCESS',
    GET_ENGAGEMENT_INDEX_V2_FILTER_FAILURE: 'GET_ENGAGEMENT_INDEX_V2_FILTER_FAILURE',

    GET_ENGAGEMENT_INDEX_V2_2_FILTER_REQUEST: 'GET_ENGAGEMENT_INDEX_V2_2_FILTER_REQUEST',
    GET_ENGAGEMENT_INDEX_V2_2_FILTER_SUCCESS: 'GET_ENGAGEMENT_INDEX_V2_2_FILTER_SUCCESS',
    GET_ENGAGEMENT_INDEX_V2_2_FILTER_FAILURE: 'GET_ENGAGEMENT_INDEX_V2_2_FILTER_FAILURE',


    GET_STRESS_INDEX_V2_FILTER_REQUEST: 'GET_STRESS_INDEX_V2_FILTER_REQUEST',
    GET_STRESS_INDEX_V2_FILTER_SUCCESS: 'GET_STRESS_INDEX_V2_FILTER_SUCCESS',
    GET_STRESS_INDEX_V2_FILTER_FAILURE: 'GET_STRESS_INDEX_V2_FILTER_FAILURE',

    GET_STRESS_INDEX_V2_2_FILTER_REQUEST: 'GET_STRESS_INDEX_V2_2_FILTER_REQUEST',
    GET_STRESS_INDEX_V2_2_FILTER_SUCCESS: 'GET_STRESS_INDEX_V2_2_FILTER_SUCCESS',
    GET_STRESS_INDEX_V2_2_FILTER_FAILURE: 'GET_STRESS_INDEX_V2_2_FILTER_FAILURE',


    GET_HAPPINESS_INDEX_DRIVER_FILTER_REQUEST: 'GET_HAPPINESS_INDEX_DRIVER_FILTER_REQUEST',
    GET_HAPPINESS_INDEX_DRIVER_FILTER_SUCCESS: 'GET_HAPPINESS_INDEX_DRIVER_FILTER_SUCCESS',
    GET_HAPPINESS_INDEX_DRIVER_FILTER_FAILURE: 'GET_HAPPINESS_INDEX_DRIVER_FILTER_FAILURE',

    GET_HAPPINESS_INDEX_DRIVER_2_FILTER_REQUEST: 'GET_HAPPINESS_INDEX_DRIVER_2_FILTER_REQUEST',
    GET_HAPPINESS_INDEX_DRIVER_2_FILTER_SUCCESS: 'GET_HAPPINESS_INDEX_DRIVER_2_FILTER_SUCCESS',
    GET_HAPPINESS_INDEX_DRIVER_2_FILTER_FAILURE: 'GET_HAPPINESS_INDEX_DRIVER_2_FILTER_FAILURE',


    GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_REQUEST: 'GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_REQUEST',
    GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_SUCCESS: 'GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_SUCCESS',
    GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_FAILURE: 'GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_FAILURE',

    GET_ENAGAGEMENT_INDEX_DRIVER_2_FILTER_REQUEST: 'GET_ENAGAGEMENT_INDEX_DRIVER_2_FILTER_REQUEST',
    GET_ENAGAGEMENT_INDEX_DRIVER_2_FILTER_SUCCESS: 'GET_ENAGAGEMENT_INDEX_DRIVER_2_FILTER_SUCCESS',
    GET_ENAGAGEMENT_INDEX_DRIVER_2_FILTER_FAILURE: 'GET_ENAGAGEMENT_INDEX_DRIVER_2_FILTER_FAILURE',



    GET_STRESS_INDEX_DRIVER_FILTER_REQUEST: 'GET_STRESS_INDEX_DRIVER_FILTER_REQUEST',
    GET_STRESS_INDEX_DRIVER_FILTER_SUCCESS: 'GET_STRESS_INDEX_DRIVER_FILTER_SUCCESS',
    GET_STRESS_INDEX_DRIVER_FILTER_FAILURE: 'GET_STRESS_INDEX_DRIVER_FILTER_FAILURE',

    GET_PARAMETER_LIBRARY_ALL_LIST_REQUEST: 'GET_PARAMETER_LIBRARY_ALL_LIST_REQUEST',
    GET_PARAMETER_LIBRARY_ALL_LIST_SUCCESS: 'GET_PARAMETER_LIBRARY_ALL_LIST_SUCCESS',
    GET_PARAMETER_LIBRARY_ALL_LIST_FAILURE: 'GET_PARAMETER_LIBRARY_ALL_LIST_FAILURE',


    CLUSTER_ANALYSIS_REQUEST: 'CLUSTER_ANALYSIS_REQUEST',
    CLUSTER_ANALYSIS_SUCCESS: 'CLUSTER_ANALYSIS_SUCCESS',
    CLUSTER_ANALYSIS_FAILURE: 'CLUSTER_ANALYSIS_FAILURE',

    GET_CLUSTER_EMP_INFO_LIST_REQUEST: 'GET_CLUSTER_EMP_INFO_LIST_REQUEST',
    GET_CLUSTER_EMP_INFO_LIST_SUCCESS: 'GET_CLUSTER_EMP_INFO_LIST_SUCCESS',
    GET_CLUSTER_EMP_INFO_LIST_FAILURE: 'GET_CLUSTER_EMP_INFO_LIST_FAILURE',


    TOPIC_MODELLING_REQUEST: 'TOPIC_MODELLING_REQUEST',
    TOPIC_MODELLING_SUCCESS: 'TOPIC_MODELLING_SUCCESS',
    TOPIC_MODELLING_FAILURE: 'TOPIC_MODELLING_FAILURE',



    CREATE_TOPIC_REQUEST: 'CREATE_TOPIC_REQUEST',
    CREATE_TOPIC_SUCCESS: 'CREATE_TOPIC_SUCCESS',
    CREATE_TOPIC_FAILURE: 'CREATE_TOPIC_FAILURE',

    UPDATE_TOPIC_REQUEST: 'UPDATE_TOPIC_REQUEST',
    UPDATE_TOPIC_SUCCESS: 'UPDATE_TOPIC_SUCCESS',
    UPDATE_TOPIC_FAILURE: 'UPDATE_TOPIC_FAILURE',

    DELETE_TOPIC_REQUEST: 'DELETE_TOPIC_REQUEST',
    DELETE_TOPIC_SUCCESS: 'DELETE_TOPIC_SUCCESS',
    DELETE_TOPIC_FAILURE: 'DELETE_TOPIC_FAILURE',

    GET_TOPIC_LIST_REQUEST: 'GET_TOPIC_LIST_REQUEST',
    GET_TOPIC_LIST_SUCCESS: 'GET_TOPIC_LIST_SUCCESS',
    GET_TOPIC_LIST_FAILURE: 'GET_TOPIC_LIST_FAILURE',

    GET_ALL_KEYWORDS_TEXT_LIST_REQUEST: 'GET_ALL_KEYWORDS_TEXT_LIST_REQUEST',
    GET_ALL_KEYWORDS_TEXT_LIST_SUCCESS: 'GET_ALL_KEYWORDS_TEXT_LIST_SUCCESS',
    GET_ALL_KEYWORDS_TEXT_LIST_FAILURE: 'GET_ALL_KEYWORDS_TEXT_LIST_FAILURE',


    GET_ENGAGEMENT_COMPARISON_OVERALL_REQUEST: 'GET_ENGAGEMENT_COMPARISON_OVERALL_REQUEST',
    GET_ENGAGEMENT_COMPARISON_OVERALL_SUCCESS: 'GET_ENGAGEMENT_COMPARISON_OVERALL_SUCCESS',
    GET_ENGAGEMENT_COMPARISON_OVERALL_FAILURE: 'GET_ENGAGEMENT_COMPARISON_OVERALL_FAILURE',

    GET_OVERALL_SCORE_BY_SURVEY_REQUEST: 'GET_OVERALL_SCORE_BY_SURVEY_REQUEST',
    GET_OVERALL_SCORE_BY_SURVEY_SUCCESS: 'GET_OVERALL_SCORE_BY_SURVEY_SUCCESS',
    GET_OVERALL_SCORE_BY_SURVEY_FAILURE: 'GET_OVERALL_SCORE_BY_SURVEY_FAILURE',

    GET_ALL_MANAGER_SCORES_REQUEST: 'GET_ALL_MANAGER_SCORES_REQUEST',
    GET_ALL_MANAGER_SCORES_SUCCESS: 'GET_ALL_MANAGER_SCORES_SUCCESS',
    GET_ALL_MANAGER_SCORES_FAILURE: 'GET_ALL_MANAGER_SCORES_FAILURE',


    CREATE_GOAL_REQUEST: 'CREATE_GOAL_REQUEST',
    CREATE_GOAL_SUCCESS: 'CREATE_GOAL_SUCCESS',
    CREATE_GOAL_FAILURE: 'CREATE_GOAL_FAILURE',

    GET_GOAL_LIST_REQUEST: 'GET_GOAL_LIST_REQUEST',
    GET_GOAL_LIST_SUCCESS: 'GET_GOAL_LIST_SUCCESS',
    GET_GOAL_LIST_FAILURE: 'GET_GOAL_LIST_FAILURE',

    UPDATE_GOAL_REQUEST: 'UPDATE_GOAL_REQUEST',
    UPDATE_GOAL_SUCCESS: 'UPDATE_GOAL_SUCCESS',
    UPDATE_GOAL_FAILURE: 'UPDATE_GOAL_FAILURE',

    DELETE_GOAL_REQUEST: 'DELETE_GOAL_REQUEST',
    DELETE_GOAL_SUCCESS: 'DELETE_GOAL_SUCCESS',
    DELETE_GOAL_FAILURE: 'DELETE_GOAL_FAILURE',


    CREATE_PLAN_REQUEST: 'CREATE_PLAN_REQUEST',
    CREATE_PLAN_SUCCESS: 'CREATE_PLAN_SUCCESS',
    CREATE_PLAN_FAILURE: 'CREATE_PLAN_FAILURE',

    UPDATE_PLAN_REQUEST: 'UPDATE_PLAN_REQUEST',
    UPDATE_PLAN_SUCCESS: 'UPDATE_PLAN_SUCCESS',
    UPDATE_PLAN_FAILURE: 'UPDATE_PLAN_FAILURE',

    DELETE_PLAN_REQUEST: 'DELETE_PLAN_REQUEST',
    DELETE_PLAN_SUCCESS: 'DELETE_PLAN_SUCCESS',
    DELETE_PLAN_FAILURE: 'DELETE_PLAN_FAILURE',

    START_PLAN_REQUEST: 'START_PLAN_REQUEST',
    START_PLAN_SUCCESS: 'START_PLAN_SUCCESS',
    START_PLAN_FAILURE: 'START_PLAN_FAILURE',

    CLOSE_PLAN_REQUEST: 'CLOSE_PLAN_REQUEST',
    CLOSE_PLAN_SUCCESS: 'CLOSE_PLAN_SUCCESS',
    CLOSE_PLAN_FAILURE: 'CLOSE_PLAN_FAILURE',

    GET_ALL_MANAGER_GOAL_PLAN_REQUEST: 'GET_ALL_MANAGER_GOAL_PLAN_REQUEST',
    GET_ALL_MANAGER_GOAL_PLAN_SUCCESS: 'GET_ALL_MANAGER_GOAL_PLAN_SUCCESS',
    GET_ALL_MANAGER_GOAL_PLAN_FAILURE: 'GET_ALL_MANAGER_GOAL_PLAN_FAILURE',

    GET_IDP_LAST_DATE_REQUEST: 'GET_IDP_LAST_DATE_REQUEST',
    GET_IDP_LAST_DATE_SUCCESS: 'GET_IDP_LAST_DATE_SUCCESS',
    GET_IDP_LAST_DATE_FAILURE: 'GET_IDP_LAST_DATE_FAILURE',

    EDIT_IDP_LAST_DATE_REQUEST: 'EDIT_IDP_LAST_DATE_REQUEST',
    EDIT_IDP_LAST_DATE_SUCCESS: 'EDIT_IDP_LAST_DATE_SUCCESS',
    EDIT_IDP_LAST_DATE_FAILURE: 'EDIT_IDP_LAST_DATE_FAILURE',

    GET_COMPANY_NAME_ONLY_REQUEST: 'GET_COMPANY_NAME_ONLY_REQUEST',
    GET_COMPANY_NAME_ONLY_SUCCESS: 'GET_COMPANY_NAME_ONLY_SUCCESS',
    GET_COMPANY_NAME_ONLY_FAILURE: 'GET_COMPANY_NAME_ONLY_FAILURE',

    GET_MANAGER_DOWNLOAD_STATUS_REQUEST: 'GET_MANAGER_DOWNLOAD_STATUS_REQUEST',
    GET_MANAGER_DOWNLOAD_STATUS_SUCCESS: 'GET_MANAGER_DOWNLOAD_STATUS_SUCCESS',
    GET_MANAGER_DOWNLOAD_STATUS_FAILURE: 'GET_MANAGER_DOWNLOAD_STATUS_FAILURE',

    GET_ALL_MANAGER_DOWNLOAD_STATUS_REQUEST: 'GET_ALL_MANAGER_DOWNLOAD_STATUS_REQUEST',
    GET_ALL_MANAGER_DOWNLOAD_STATUS_SUCCESS: 'GET_ALL_MANAGER_DOWNLOAD_STATUS_SUCCESS',
    GET_ALL_MANAGER_DOWNLOAD_STATUS_FAILURE: 'GET_ALL_MANAGER_DOWNLOAD_STATUS_FAILURE',

    GET_QUESTION_MAP_REQUEST: 'GET_QUESTION_MAP_REQUEST',
    GET_QUESTION_MAP_SUCCESS: 'GET_QUESTION_MAP_SUCCESS',
    GET_QUESTION_MAP_FAILURE: 'GET_QUESTION_MAP_FAILURE',


    GET_QUESTION_CUSTOM_TEMPLATE_REQUEST: 'GET_QUESTION_CUSTOM_TEMPLATE_REQUEST',
    GET_QUESTION_CUSTOM_TEMPLATE_SUCCESS: 'GET_QUESTION_CUSTOM_TEMPLATE_SUCCESS',
    GET_QUESTION_CUSTOM_TEMPLATE_FAILURE: 'GET_QUESTION_CUSTOM_TEMPLATE_FAILURE',

    GET_INDEX_BY_FILTER_ALL_STAGES_REQUEST: 'GET_INDEX_BY_FILTER_ALL_STAGES_REQUEST',
    GET_INDEX_BY_FILTER_ALL_STAGES_SUCCESS: 'GET_INDEX_BY_FILTER_ALL_STAGES_SUCCESS',
    GET_INDEX_BY_FILTER_ALL_STAGES_FAILURE: 'GET_INDEX_BY_FILTER_ALL_STAGES_FAILURE',



    GET_CAT_OVERALL_INDEX_REQUEST: 'GET_CAT_OVERALL_INDEX_REQUEST',
    GET_CAT_OVERALL_INDEX_SUCCESS: 'GET_CAT_OVERALL_INDEX_SUCCESS',
    GET_CAT_OVERALL_INDEX_FAILURE: 'GET_CAT_OVERALL_INDEX_FAILURE',


    GET_LIFECYCLE_LISTEN_REQUEST: 'GET_LIFECYCLE_LISTEN_REQUEST',
    GET_LIFECYCLE_LISTEN_SUCCESS: 'GET_LIFECYCLE_LISTEN_SUCCESS',
    GET_LIFECYCLE_LISTEN_FAILURE: 'GET_LIFECYCLE_LISTEN_FAILURE',


    GET_360_DIMEN_RELATION_SCORE_REQUEST: 'GET_360_DIMEN_RELATION_SCORE_REQUEST',
    GET_360_DIMEN_RELATION_SCORE_SUCCESS: 'GET_360_DIMEN_RELATION_SCORE_SUCCESS',
    GET_360_DIMEN_RELATION_SCORE_FAILURE: 'GET_360_DIMEN_RELATION_SCORE_FAILURE',

    GET_360_DIMEN_QUESTIONS_REQUEST: 'GET_360_DIMEN_QUESTIONS_REQUEST',
    GET_360_DIMEN_QUESTIONS_SUCCESS: 'GET_360_DIMEN_QUESTIONS_SUCCESS',
    GET_360_DIMEN_QUESTIONS_FAILURE: 'GET_360_DIMEN_QUESTIONS_FAILURE',

    GET_ANSWERED_EMPLOYEES_REQUEST: 'GET_ANSWERED_EMPLOYEES_REQUEST',
    GET_ANSWERED_EMPLOYEES_SUCCESS: 'GET_ANSWERED_EMPLOYEES_SUCCESS',
    GET_ANSWERED_EMPLOYEES_FAILURE: 'GET_ANSWERED_EMPLOYEES_FAILURE',

    GET_ORG_QUESTION_REQUEST: 'GET_ORG_QUESTION_REQUEST',
    GET_ORG_QUESTION_SUCCESS: 'GET_ORG_QUESTION_SUCCESS',
    GET_ORG_QUESTION_FAILURE: 'GET_ORG_QUESTION_FAILURE',

    GET_ORG_MANAGER_QUESTION_REQUEST: 'GET_ORG_MANAGER_QUESTION_REQUEST',
    GET_ORG_MANAGER_QUESTION_SUCCESS: 'GET_ORG_MANAGER_QUESTION_SUCCESS',
    GET_ORG_MANAGER_QUESTION_FAILURE: 'GET_ORG_MANAGER_QUESTION_FAILURE',

    GET_ORG_DMG_QUESTION_REQUEST: 'GET_ORG_DMG_QUESTION_REQUEST',
    GET_ORG_DMG_QUESTION_SUCCESS: 'GET_ORG_DMG_QUESTION_SUCCESS',
    GET_ORG_DMG_QUESTION_FAILURE: 'GET_ORG_DMG_QUESTION_FAILURE',

    GET_QUES_MNGR_MANAGERPID_QUESTION_REQUEST: 'GET_QUES_MNGR_MANAGERPID_QUESTION_REQUEST',
    GET_QUES_MNGR_MANAGERPID_QUESTION_SUCCESS: 'GET_QUES_MNGR_MANAGERPID_QUESTION_SUCCESS',
    GET_QUES_MNGR_MANAGERPID_QUESTION_FAILURE: 'GET_QUES_MNGR_MANAGERPID_QUESTION_FAILURE',

    GET_INDEX_MNGR_MANAGERPID_SCORE_REQUEST: 'GET_INDEX_MNGR_MANAGERPID_SCORE_REQUEST',
    GET_INDEX_MNGR_MANAGERPID_SCORE_SUCCESS: 'GET_INDEX_MNGR_MANAGERPID_SCORE_SUCCESS',
    GET_INDEX_MNGR_MANAGERPID_SCORE_FAILURE: 'GET_INDEX_MNGR_MANAGERPID_SCORE_FAILURE',

    GET_HAPPINESS_INDEX_MNGR_WISE_REQUEST: 'GET_HAPPINESS_INDEX_MNGR_WISE_REQUEST',
    GET_HAPPINESS_INDEX_MNGR_WISE_SUCCESS: 'GET_HAPPINESS_INDEX_MNGR_WISE_SUCCESS',
    GET_HAPPINESS_INDEX_MNGR_WISE_FAILURE: 'GET_HAPPINESS_INDEX_MNGR_WISE_FAILURE',

    GET_ENGAGEMENT_INDEX_MNGR_WISE_REQUEST: 'GET_ENGAGEMENT_INDEX_MNGR_WISE_REQUEST',
    GET_ENGAGEMENT_INDEX_MNGR_WISE_SUCCESS: 'GET_ENGAGEMENT_INDEX_MNGR_WISE_SUCCESS',
    GET_ENGAGEMENT_INDEX_MNGR_WISE_FAILURE: 'GET_ENGAGEMENT_INDEX_MNGR_WISE_FAILURE',

    GET_STRESS_INDEX_MNGR_WISE_REQUEST: 'GET_STRESS_INDEX_MNGR_WISE_REQUEST',
    GET_STRESS_INDEX_MNGR_WISE_SUCCESS: 'GET_STRESS_INDEX_MNGR_WISE_SUCCESS',
    GET_STRESS_INDEX_MNGR_WISE_FAILURE: 'GET_STRESS_INDEX_MNGR_WISE_FAILURE',

    GET_MANAGER_INDEX_MNGR_WISE_REQUEST: 'GET_MANAGER_INDEX_MNGR_WISE_REQUEST',
    GET_MANAGER_INDEX_MNGR_WISE_SUCCESS: 'GET_MANAGER_INDEX_MNGR_WISE_SUCCESS',
    GET_MANAGER_INDEX_MNGR_WISE_FAILURE: 'GET_MANAGER_INDEX_MNGR_WISE_FAILURE',

    GET_ORG_CORE_DRIVER_SENTIMENT_REQUEST: 'GET_ORG_CORE_DRIVER_SENTIMENT_REQUEST',
    GET_ORG_CORE_DRIVER_SENTIMENT_SUCCESS: 'GET_ORG_CORE_DRIVER_SENTIMENT_SUCCESS',
    GET_ORG_CORE_DRIVER_SENTIMENT_FAILURE: 'GET_ORG_CORE_DRIVER_SENTIMENT_FAILURE',

    ANSWER_FEEDBACK_TEXT_REQUEST: 'ANSWER_FEEDBACK_TEXT_REQUEST',
    ANSWER_FEEDBACK_TEXT_SUCCESS: 'ANSWER_FEEDBACK_TEXT_SUCCESS',
    ANSWER_FEEDBACK_TEXT_FAILURE: 'ANSWER_FEEDBACK_TEXT_FAILURE',

    GET_FEEDBACK_TEXT_QUESTION_REQUEST: 'GET_FEEDBACK_TEXT_QUESTION_REQUEST',
    GET_FEEDBACK_TEXT_QUESTION_SUCCESS: 'GET_FEEDBACK_TEXT_QUESTION_SUCCESS',
    GET_FEEDBACK_TEXT_QUESTION_FAILURE: 'GET_FEEDBACK_TEXT_QUESTION_FAILURE',


    GET_DEMOGRAPHIC_MULTI_AXIS_INDEX_ORG_REQUEST: 'GET_DEMOGRAPHIC_MULTI_AXIS_INDEX_ORG_REQUEST',
    GET_DEMOGRAPHIC_MULTI_AXIS_INDEX_ORG_SUCCESS: 'GET_DEMOGRAPHIC_MULTI_AXIS_INDEX_ORG_SUCCESS',
    GET_DEMOGRAPHIC_MULTI_AXIS_INDEX_ORG_FAILURE: 'GET_DEMOGRAPHIC_MULTI_AXIS_INDEX_ORG_FAILURE',

    GET_360_RELATION_RESPONSE_REQUEST: 'GET_360_RELATION_RESPONSE_REQUEST',
    GET_360_RELATION_RESPONSE_SUCCESS: 'GET_360_RELATION_RESPONSE_SUCCESS',
    GET_360_RELATION_RESPONSE_FAILURE: 'GET_360_RELATION_RESPONSE_FAILURE',

    GET_RESPONDANT_ALL_LIFECYCLE_DETAILS_REQUEST: 'GET_RESPONDANT_ALL_LIFECYCLE_DETAILS_REQUEST',
    GET_RESPONDANT_ALL_LIFECYCLE_DETAILS_SUCCESS: 'GET_RESPONDANT_ALL_LIFECYCLE_DETAILS_SUCCESS',
    GET_RESPONDANT_ALL_LIFECYCLE_DETAILS_FAILURE: 'GET_RESPONDANT_ALL_LIFECYCLE_DETAILS_FAILURE',

    GET_USER_ACCESS_REQUEST: 'GET_USER_ACCESS_REQUEST',
    GET_USER_ACCESS_SUCCESS: 'GET_USER_ACCESS_SUCCESS',
    GET_USER_ACCESS_FAILURE: 'GET_USER_ACCESS_FAILURE',

    GET_FEEDBACK_AND_QUESTION_REQUEST: 'GET_FEEDBACK_AND_QUESTION_REQUEST',
    GET_FEEDBACK_AND_QUESTION_SUCCESS: 'GET_FEEDBACK_AND_QUESTION_SUCCESS',
    GET_FEEDBACK_AND_QUESTION_FAILURE: 'GET_FEEDBACK_AND_QUESTION_FAILURE',

    GET_TEXT_AND_QUESTION_REQUEST: 'GET_TEXT_AND_QUESTION_REQUEST',
    GET_TEXT_AND_QUESTION_SUCCESS: 'GET_TEXT_AND_QUESTION_SUCCESS',
    GET_TEXT_AND_QUESTION_FAILURE: 'GET_TEXT_AND_QUESTION_FAILURE',

    GET_PARAMETER_LIBARARY_LABEL_REQUEST: 'GET_PARAMETER_LIBARARY_LABEL_REQUEST',
    GET_PARAMETER_LIBARARY_LABEL_SUCCESS: 'GET_PARAMETER_LIBARARY_LABEL_SUCCESS',
    GET_PARAMETER_LIBARARY_LABEL_FAILURE: 'GET_PARAMETER_LIBARARY_LABEL_FAILURE',

    GET_INDEX_TREND_CHART_BY_MONTH_REQUEST: 'GET_INDEX_TREND_CHART_BY_MONTH_REQUEST',
    GET_INDEX_TREND_CHART_BY_MONTH_SUCCESS: 'GET_INDEX_TREND_CHART_BY_MONTH_SUCCESS',
    GET_INDEX_TREND_CHART_BY_MONTH_FAILURE: 'GET_INDEX_TREND_CHART_BY_MONTH_FAILURE',

    GET_QUESTION_TREND_CHART_BY_MONTH_REQUEST: 'GET_QUESTION_TREND_CHART_BY_MONTH_REQUEST',
    GET_QUESTION_TREND_CHART_BY_MONTH_SUCCESS: 'GET_QUESTION_TREND_CHART_BY_MONTH_SUCCESS',
    GET_QUESTION_TREND_CHART_BY_MONTH_FAILURE: 'GET_QUESTION_TREND_CHART_BY_MONTH_FAILURE',

    GET_BENCHMARK_QUANTILE_REQUEST: 'GET_BENCHMARK_QUANTILE_REQUEST',
    GET_BENCHMARK_QUANTILE_SUCCESS: 'GET_BENCHMARK_QUANTILE_SUCCESS',
    GET_BENCHMARK_QUANTILE_FAILURE: 'GET_BENCHMARK_QUANTILE_FAILURE',

    GET_OVERALL_LIFECYCLE_PTM_REQUEST: 'GET_OVERALL_LIFECYCLE_PTM_REQUEST',
    GET_OVERALL_LIFECYCLE_PTM_SUCCESS: 'GET_OVERALL_LIFECYCLE_PTM_SUCCESS',
    GET_OVERALL_LIFECYCLE_PTM_FAILURE: 'GET_OVERALL_LIFECYCLE_PTM_FAILURE',

    GET_TEXT_CLUSTER_REQUEST: 'GET_TEXT_CLUSTER_REQUEST',
    GET_TEXT_CLUSTER_SUCCESS: 'GET_TEXT_CLUSTER_SUCCESS',
    GET_TEXT_CLUSTER_FAILURE: 'GET_TEXT_CLUSTER_FAILURE',

    GET_TEXT_CLUSTER_SENTIMENT_REQUEST: 'GET_TEXT_CLUSTER_SENTIMENT_REQUEST',
    GET_TEXT_CLUSTER_SENTIMENT_SUCCESS: 'GET_TEXT_CLUSTER_SENTIMENT_SUCCESS',
    GET_TEXT_CLUSTER_SENTIMENT_FAILURE: 'GET_TEXT_CLUSTER_SENTIMENT_FAILURE',

    GET_TEXT_CLUSTER_KEYWORD_REQUEST: 'GET_TEXT_CLUSTER_KEYWORD_REQUEST',
    GET_TEXT_CLUSTER_KEYWORD_SUCCESS: 'GET_TEXT_CLUSTER_KEYWORD_SUCCESS',
    GET_TEXT_CLUSTER_KEYWORD_FAILURE: 'GET_TEXT_CLUSTER_KEYWORD_FAILURE',

    GET_CLUSTER_SENTENCES_REQUEST: 'GET_CLUSTER_SENTENCES_REQUEST',
    GET_CLUSTER_SENTENCES_SUCCESS: 'GET_CLUSTER_SENTENCES_SUCCESS',
    GET_CLUSTER_SENTENCES_FAILURE: 'GET_CLUSTER_SENTENCES_FAILURE',

    DRIVER_VS_NPS_RWA_REQUEST: 'DRIVER_VS_NPS_RWA_REQUEST',
    DRIVER_VS_NPS_RWA_SUCCESS: 'DRIVER_VS_NPS_RWA_SUCCESS',
    DRIVER_VS_NPS_RWA_FAILURE: 'DRIVER_VS_NPS_RWA_FAILURE',

    GET_ASSESSMENT_REPORT_EMP_ID_REQUEST: 'GET_ASSESSMENT_REPORT_EMP_ID_REQUEST',
    GET_ASSESSMENT_REPORT_EMP_ID_SUCCESS: 'GET_ASSESSMENT_REPORT_EMP_ID_SUCCESS',
    GET_ASSESSMENT_REPORT_EMP_ID_FAILURE: 'GET_ASSESSMENT_REPORT_EMP_ID_FAILURE',

    GET_TYPE2_ASSESSMENT_REPORT_EMP_ID_REQUEST: 'GET_TYPE2_ASSESSMENT_REPORT_EMP_ID_REQUEST',
    GET_TYPE2_ASSESSMENT_REPORT_EMP_ID_SUCCESS: 'GET_TYPE2_ASSESSMENT_REPORT_EMP_ID_SUCCESS',
    GET_TYPE2_ASSESSMENT_REPORT_EMP_ID_FAILURE: 'GET_TYPE2_ASSESSMENT_REPORT_EMP_ID_FAILURE',

    GET_ASSESSMENT_REPORT_EMP_ONLY_ID_REQUEST: 'GET_ASSESSMENT_REPORT_EMP_ONLY_ID_REQUEST',
    GET_ASSESSMENT_REPORT_EMP_ONLY_ID_SUCCESS: 'GET_ASSESSMENT_REPORT_EMP_ONLY_ID_SUCCESS',
    GET_ASSESSMENT_REPORT_EMP_ONLY_ID_FAILURE: 'GET_ASSESSMENT_REPORT_EMP_ONLY_ID_FAILURE',

    GET_TEMP_360_EMP_STRENGTH_REQUEST: 'GET_TEMP_360_EMP_STRENGTH_REQUEST',
    GET_TEMP_360_EMP_STRENGTH_SUCCESS: 'GET_TEMP_360_EMP_STRENGTH_SUCCESS',
    GET_TEMP_360_EMP_STRENGTH_FAILURE: 'GET_TEMP_360_EMP_STRENGTH_FAILURE',

    GET_ASSESSMENT_DASHBOARD_REQUEST: 'GET_ASSESSMENT_DASHBOARD_REQUEST',
    GET_ASSESSMENT_DASHBOARD_SUCCESS: 'GET_ASSESSMENT_DASHBOARD_SUCCESS',
    GET_ASSESSMENT_DASHBOARD_FAILURE: 'GET_ASSESSMENT_DASHBOARD_FAILURE',

    GET_ASSESSMENT_THEME_REQUEST: 'GET_ASSESSMENT_THEME_REQUEST',
    GET_ASSESSMENT_THEME_SUCCESS: 'GET_ASSESSMENT_THEME_SUCCESS',
    GET_ASSESSMENT_THEME_FAILURE: 'GET_ASSESSMENT_THEME_FAILURE',




    GET_EXIT_QUESTION_ONE_DATA_REQUEST: 'GET_EXIT_QUESTION_ONE_DATA_REQUEST',
    GET_EXIT_QUESTION_ONE_DATA_SUCCESS: 'GET_EXIT_QUESTION_ONE_DATA_SUCCESS',
    GET_EXIT_QUESTION_ONE_DATA_FAILURE: 'GET_EXIT_QUESTION_ONE_DATA_FAILURE',

    GET_EXIT_QUESTION_YES_NO_DATA_REQUEST: 'GET_EXIT_QUESTION_YES_NO_DATA_REQUEST',
    GET_EXIT_QUESTION_YES_NO_DATA_SUCCESS: 'GET_EXIT_QUESTION_YES_NO_DATA_SUCCESS',
    GET_EXIT_QUESTION_YES_NO_DATA_FAILURE: 'GET_EXIT_QUESTION_YES_NO_DATA_FAILURE',


    GET_EXIT_QUESTION_AVERAGE_DATA_REQUEST: 'GET_EXIT_QUESTION_AVERAGE_DATA_REQUEST',
    GET_EXIT_QUESTION_AVERAGE_DATA_SUCCESS: 'GET_EXIT_QUESTION_AVERAGE_DATA_SUCCESS',
    GET_EXIT_QUESTION_AVERAGE_DATA_FAILURE: 'GET_EXIT_QUESTION_AVERAGE_DATA_FAILURE',

    GET_EXIT_QUESTION_ALL_COUNT_DATA_REQUEST: 'GET_EXIT_QUESTION_ALL_COUNT_DATA_REQUEST',
    GET_EXIT_QUESTION_ALL_COUNT_DATA_SUCCESS: 'GET_EXIT_QUESTION_ALL_COUNT_DATA_SUCCESS',
    GET_EXIT_QUESTION_ALL_COUNT_DATA_FAILURE: 'GET_EXIT_QUESTION_ALL_COUNT_DATA_FAILURE',

    GET_EXIT_QUES_ALL_DMG_REQUEST: 'GET_EXIT_QUES_ALL_DMG_REQUEST',
    GET_EXIT_QUES_ALL_DMG_SUCCESS: 'GET_EXIT_QUES_ALL_DMG_SUCCESS',
    GET_EXIT_QUES_ALL_DMG_FAILURE: 'GET_EXIT_QUES_ALL_DMG_FAILURE',

    GET_ROL_KEYWORD_EMP_REQUEST: 'GET_ROL_KEYWORD_EMP_REQUEST',
    GET_ROL_KEYWORD_EMP_SUCCESS: 'GET_ROL_KEYWORD_EMP_SUCCESS',
    GET_ROL_KEYWORD_EMP_FAILURE: 'GET_ROL_KEYWORD_EMP_FAILURE',

    GET_ASSESSMENT_DOWNLOAD_STATUS_REQUEST: 'GET_ASSESSMENT_DOWNLOAD_STATUS_REQUEST',
    GET_ASSESSMENT_DOWNLOAD_STATUS_SUCCESS: 'GET_ASSESSMENT_DOWNLOAD_STATUS_SUCCESS',
    GET_ASSESSMENT_DOWNLOAD_STATUS_FAILURE: 'GET_ASSESSMENT_DOWNLOAD_STATUS_FAILURE',

    GET_ASSESSMENT_DIMENSION_REQUEST: 'GET_ASSESSMENT_DIMENSION_REQUEST',
    GET_ASSESSMENT_DIMENSION_SUCCESS: 'GET_ASSESSMENT_DIMENSION_SUCCESS',
    GET_ASSESSMENT_DIMENSION_FAILURE: 'GET_ASSESSMENT_DIMENSION_FAILURE',

    GET_ASSESSMENT_MANAGER_LIST_REQUEST: 'GET_ASSESSMENT_MANAGER_LIST_REQUEST',
    GET_ASSESSMENT_MANAGER_LIST_SUCCESS: 'GET_ASSESSMENT_MANAGER_LIST_SUCCESS',
    GET_ASSESSMENT_MANAGER_LIST_FAILURE: 'GET_ASSESSMENT_MANAGER_LIST_FAILURE',

    GET_ASSESSMENT_MANAGER_REPORT_REQUEST: 'GET_ASSESSMENT_MANAGER_REPORT_REQUEST',
    GET_ASSESSMENT_MANAGER_REPORT_SUCCESS: 'GET_ASSESSMENT_MANAGER_REPORT_SUCCESS',
    GET_ASSESSMENT_MANAGER_REPORT_FAILURE: 'GET_ASSESSMENT_MANAGER_REPORT_FAILURE',

    GET_ASSESSMENT_ONLY_DETAILS_REQUEST: 'GET_ASSESSMENT_ONLY_DETAILS_REQUEST',
    GET_ASSESSMENT_ONLY_DETAILS_SUCCESS: 'GET_ASSESSMENT_ONLY_DETAILS_SUCCESS',
    GET_ASSESSMENT_ONLY_DETAILS_FAILURE: 'GET_ASSESSMENT_ONLY_DETAILS_FAILURE',

    GET_ASSESSMENT_EMP_LIST_REQUEST: 'GET_ASSESSMENT_EMP_LIST_REQUEST',
    GET_ASSESSMENT_EMP_LIST_SUCCESS: 'GET_ASSESSMENT_EMP_LIST_SUCCESS',
    GET_ASSESSMENT_EMP_LIST_FAILURE: 'GET_ASSESSMENT_EMP_LIST_FAILURE',

    GET_REPORT_TYPE_REQUEST: 'GET_REPORT_TYPE_REQUEST',
    GET_REPORT_TYPE_SUCCESS: 'GET_REPORT_TYPE_SUCCESS',
    GET_REPORT_TYPE_FAILURE: 'GET_REPORT_TYPE_FAILURE',

    GET_REPORT_INDEX_YEAR_REQUEST: 'GET_REPORT_INDEX_YEAR_REQUEST',
    GET_REPORT_INDEX_YEAR_SUCCESS: 'GET_REPORT_INDEX_YEAR_SUCCESS',
    GET_REPORT_INDEX_YEAR_FAILURE: 'GET_REPORT_INDEX_YEAR_FAILURE',

    GET_DMG_WISE_RANK_SCORE_INDEX_TOP_REQUEST: 'GET_DMG_WISE_RANK_SCORE_INDEX_TOP_REQUEST',
    GET_DMG_WISE_RANK_SCORE_INDEX_TOP_SUCCESS: 'GET_DMG_WISE_RANK_SCORE_INDEX_TOP_SUCCESS',
    GET_DMG_WISE_RANK_SCORE_INDEX_TOP_FAILURE: 'GET_DMG_WISE_RANK_SCORE_INDEX_TOP_FAILURE',

    GET_DMG_WISE_RANK_SCORE_INDEX_BOTTOM_REQUEST: 'GET_DMG_WISE_RANK_SCORE_INDEX_BOTTOM_REQUEST',
    GET_DMG_WISE_RANK_SCORE_INDEX_BOTTOM_SUCCESS: 'GET_DMG_WISE_RANK_SCORE_INDEX_BOTTOM_SUCCESS',
    GET_DMG_WISE_RANK_SCORE_INDEX_BOTTOM_FAILURE: 'GET_DMG_WISE_RANK_SCORE_INDEX_BOTTOM_FAILURE',


    GET_INDEX_OVERALL_SCORE_REQUEST: 'GET_INDEX_OVERALL_SCORE_REQUEST',
    GET_INDEX_OVERALL_SCORE_SUCCESS: 'GET_INDEX_OVERALL_SCORE_SUCCESS',
    GET_INDEX_OVERALL_SCORE_FAILURE: 'GET_INDEX_OVERALL_SCORE_FAILURE',

    GET_ICE_DMG_PARA_SCORE_REQUEST: 'GET_ICE_DMG_PARA_SCORE_REQUEST',
    GET_ICE_DMG_PARA_SCORE_SUCCESS: 'GET_ICE_DMG_PARA_SCORE_SUCCESS',
    GET_ICE_DMG_PARA_SCORE_FAILURE: 'GET_ICE_DMG_PARA_SCORE_FAILURE',

    GET_LT_HEAD_ICE_SCORE_REQUEST: 'GET_LT_HEAD_ICE_SCORE_REQUEST',
    GET_LT_HEAD_ICE_SCORE_SUCCESS: 'GET_LT_HEAD_ICE_SCORE_SUCCESS',
    GET_LT_HEAD_ICE_SCORE_FAILURE: 'GET_LT_HEAD_ICE_SCORE_FAILURE',

    GET_LT_HEAD_ICE_SCORE_V2_REQUEST: 'GET_LT_HEAD_ICE_SCORE_V2_REQUEST',
    GET_LT_HEAD_ICE_SCORE_V2_SUCCESS: 'GET_LT_HEAD_ICE_SCORE_V2_SUCCESS',
    GET_LT_HEAD_ICE_SCORE_V2_FAILURE: 'GET_LT_HEAD_ICE_SCORE_V2_FAILURE',


    GET_LT_HEAD_ICE_PARA_SCORE_REQUEST: 'GET_LT_HEAD_ICE_PARA_SCORE_REQUEST',
    GET_LT_HEAD_ICE_PARA_SCORE_SUCCESS: 'GET_LT_HEAD_ICE_PARA_SCORE_SUCCESS',
    GET_LT_HEAD_ICE_PARA_SCORE_FAILURE: 'GET_LT_HEAD_ICE_PARA_SCORE_FAILURE',

    GET_LT_HEAD_ICE_PARA_SCORE_V2_REQUEST: 'GET_LT_HEAD_ICE_PARA_SCORE_V2_REQUEST',
    GET_LT_HEAD_ICE_PARA_SCORE_V2_SUCCESS: 'GET_LT_HEAD_ICE_PARA_SCORE_V2_SUCCESS',
    GET_LT_HEAD_ICE_PARA_SCORE_V2_FAILURE: 'GET_LT_HEAD_ICE_PARA_SCORE_V2_FAILURE',



    GET_LT_HEAD_ICE_SUBWISE_ICE_SCORE_REQUEST: 'GET_LT_HEAD_ICE_SUBWISE_ICE_SCORE_REQUEST',
    GET_LT_HEAD_ICE_SUBWISE_ICE_SCORE_SUCCESS: 'GET_LT_HEAD_ICE_SUBWISE_ICE_SCORE_SUCCESS',
    GET_LT_HEAD_ICE_SUBWISE_ICE_SCORE_FAILURE: 'GET_LT_HEAD_ICE_SUBWISE_ICE_SCORE_FAILURE',

    GET_LT_HEAD_ICE_SUBWISE_ICE_SCORE_V2_REQUEST: 'GET_LT_HEAD_ICE_SUBWISE_ICE_SCORE_V2_REQUEST',
    GET_LT_HEAD_ICE_SUBWISE_ICE_SCORE_V2_SUCCESS: 'GET_LT_HEAD_ICE_SUBWISE_ICE_SCORE_V2_SUCCESS',
    GET_LT_HEAD_ICE_SUBWISE_ICE_SCORE_V2_FAILURE: 'GET_LT_HEAD_ICE_SUBWISE_ICE_SCORE_V2_FAILURE',


    GET_SUB_WISE_PARA_SCORE_REQUEST: 'GET_SUB_WISE_PARA_SCORE_REQUEST',
    GET_SUB_WISE_PARA_SCORE_SUCCESS: 'GET_SUB_WISE_PARA_SCORE_SUCCESS',
    GET_SUB_WISE_PARA_SCORE_FAILURE: 'GET_SUB_WISE_PARA_SCORE_FAILURE',

    GET_SUB_WISE_PARA_FEED_DIST_REQUEST: 'GET_SUB_WISE_PARA_FEED_DIST_REQUEST',
    GET_SUB_WISE_PARA_FEED_DIST_SUCCESS: 'GET_SUB_WISE_PARA_FEED_DIST_SUCCESS',
    GET_SUB_WISE_PARA_FEED_DIST_FAILURE: 'GET_SUB_WISE_PARA_FEED_DIST_FAILURE',

    GET_SUB_WISE_PARA_FEED_DIST_V2_REQUEST: 'GET_SUB_WISE_PARA_FEED_DIST_V2_REQUEST',
    GET_SUB_WISE_PARA_FEED_DIST_V2_SUCCESS: 'GET_SUB_WISE_PARA_FEED_DIST_V2_SUCCESS',
    GET_SUB_WISE_PARA_FEED_DIST_V2_FAILURE: 'GET_SUB_WISE_PARA_FEED_DIST_V2_FAILURE',

    GET_SUB_WISE_FEED_ANS_COUNT_REQUEST: 'GET_SUB_WISE_FEED_ANS_COUNT_REQUEST',
    GET_SUB_WISE_FEED_ANS_COUNT_SUCCESS: 'GET_SUB_WISE_FEED_ANS_COUNT_SUCCESS',
    GET_SUB_WISE_FEED_ANS_COUNT_FAILURE: 'GET_SUB_WISE_FEED_ANS_COUNT_FAILURE',

    GET_SUB_WISE_FEED_PARA_SCORE_REQUEST: 'GET_SUB_WISE_FEED_PARA_SCORE_REQUEST',
    GET_SUB_WISE_FEED_PARA_SCORE_SUCCESS: 'GET_SUB_WISE_FEED_PARA_SCORE_SUCCESS',
    GET_SUB_WISE_FEED_PARA_SCORE_FAILURE: 'GET_SUB_WISE_FEED_PARA_SCORE_FAILURE',

    GET_ICE_DMG_QUESTION_DIST_SCORE_REQUEST: 'GET_ICE_DMG_QUESTION_DIST_SCORE_REQUEST',
    GET_ICE_DMG_QUESTION_DIST_SCORE_SUCCESS: 'GET_ICE_DMG_QUESTION_DIST_SCORE_SUCCESS',
    GET_ICE_DMG_QUESTION_DIST_SCORE_FAILURE: 'GET_ICE_DMG_QUESTION_DIST_SCORE_FAILURE',

    GET_ICE_DMG_WISE_ANS_COUNT_SCORE_REQUEST: 'GET_ICE_DMG_WISE_ANS_COUNT_SCORE_REQUEST',
    GET_ICE_DMG_WISE_ANS_COUNT_SCORE_SUCCESS: 'GET_ICE_DMG_WISE_ANS_COUNT_SCORE_SUCCESS',
    GET_ICE_DMG_WISE_ANS_COUNT_SCORE_FAILURE: 'GET_ICE_DMG_WISE_ANS_COUNT_SCORE_FAILURE',

    GET_ICE_DIMENSION_DMG_WISE_SCORE_REQUEST: 'GET_ICE_DIMENSION_DMG_WISE_SCORE_REQUEST',
    GET_ICE_DIMENSION_DMG_WISE_SCORE_SUCCESS: 'GET_ICE_DIMENSION_DMG_WISE_SCORE_SUCCESS',
    GET_ICE_DIMENSION_DMG_WISE_SCORE_FAILURE: 'GET_ICE_DIMENSION_DMG_WISE_SCORE_FAILURE',

    GET_ICE_INDEX_DMG_WISE_SCORE_REQUEST: 'GET_ICE_INDEX_DMG_WISE_SCORE_REQUEST',
    GET_ICE_INDEX_DMG_WISE_SCORE_SUCCESS: 'GET_ICE_INDEX_DMG_WISE_SCORE_SUCCESS',
    GET_ICE_INDEX_DMG_WISE_SCORE_FAILURE: 'GET_ICE_INDEX_DMG_WISE_SCORE_FAILURE',

    GET_INDEX_OVERALL_DISTRIBUTION_REQUEST: 'GET_INDEX_OVERALL_DISTRIBUTION_REQUEST',
    GET_INDEX_OVERALL_DISTRIBUTION_SUCCESS: 'GET_INDEX_OVERALL_DISTRIBUTION_SUCCESS',
    GET_INDEX_OVERALL_DISTRIBUTION_FAILURE: 'GET_INDEX_OVERALL_DISTRIBUTION_FAILURE',

    GET_PMS_GET_MODULE_SETTING_CYCLE_REQUEST: 'GET_PMS_GET_MODULE_SETTING_CYCLE_REQUEST',
    GET_PMS_GET_MODULE_SETTING_CYCLE_SUCCESS: 'GET_PMS_GET_MODULE_SETTING_CYCLE_SUCCESS',
    GET_PMS_GET_MODULE_SETTING_CYCLE_FAILURE: 'GET_PMS_GET_MODULE_SETTING_CYCLE_FAILURE',


    GET_PMSRATER_NAMES_LIST_REQUEST: 'GET_PMSRATER_NAMES_LIST_REQUEST',
    GET_PMSRATER_NAMES_LIST_SUCCESS: 'GET_PMSRATER_NAMES_LIST_SUCCESS',
    GET_PMSRATER_NAMES_LIST_FAILURE: 'GET_PMSRATER_NAMES_LIST_FAILURE',


    GET_SURVEY_TAKEN_DATE_ADMIN_REQUEST: 'GET_SURVEY_TAKEN_DATE_ADMIN_REQUEST',
    GET_SURVEY_TAKEN_DATE_ADMIN_SUCCESS: 'GET_SURVEY_TAKEN_DATE_ADMIN_SUCCESS',
    GET_SURVEY_TAKEN_DATE_ADMIN_FAILURE: 'GET_SURVEY_TAKEN_DATE_ADMIN_FAILURE',

    PMS_DMG_EMPLOYEE_LIST_REQUEST: 'PMS_DMG_EMPLOYEE_LIST_REQUEST',
    PMS_DMG_EMPLOYEE_LIST_SUCCESS: 'PMS_DMG_EMPLOYEE_LIST_SUCCESS',
    PMS_DMG_EMPLOYEE_LIST_FAILURE: 'PMS_DMG_EMPLOYEE_LIST_FAILURE',

    TOPIC_CLOUD_REQUEST: 'TOPIC_CLOUD_REQUEST',
    TOPIC_CLOUD_SUCCESS: 'TOPIC_CLOUD_SUCCESS',
    TOPIC_CLOUD_FAILURE: 'TOPIC_CLOUD_FAILURE',

    PMS_GET_PROCESS_LIST_REQUEST: 'PMS_GET_PROCESS_LIST_REQUEST',
    PMS_GET_PROCESS_LIST_SUCCESS: 'PMS_GET_PROCESS_LIST_SUCCESS',
    PMS_GET_PROCESS_LIST_FAILURE: 'PMS_GET_PROCESS_LIST_FAILURE',

    PMS_ADD_PROCESS_LIST_REQUEST: 'PMS_ADD_PROCESS_LIST_REQUEST',
    PMS_ADD_PROCESS_LIST_SUCCESS: 'PMS_ADD_PROCESS_LIST_SUCCESS',
    PMS_ADD_PROCESS_LIST_FAILURE: 'PMS_ADD_PROCESS_LIST_FAILURE',

    ADD_TEXT_KEYWORD_REQUEST: 'ADD_TEXT_KEYWORD_REQUEST',
    ADD_TEXT_KEYWORD_SUCCESS: 'ADD_TEXT_KEYWORD_SUCCESS',
    ADD_TEXT_KEYWORD_FAILURE: 'ADD_TEXT_KEYWORD_FAILURE',

    GET_TEXT_KEYWORD_REQUEST: 'GET_TEXT_KEYWORD_REQUEST',
    GET_TEXT_KEYWORD_SUCCESS: 'GET_TEXT_KEYWORD_SUCCESS',
    GET_TEXT_KEYWORD_FAILURE: 'GET_TEXT_KEYWORD_FAILURE',

    GET_TEXT_DIST_HIGHLIGHTS_REQUEST: 'GET_TEXT_DIST_HIGHLIGHTS_REQUEST',
    GET_TEXT_DIST_HIGHLIGHTS_SUCCESS: 'GET_TEXT_DIST_HIGHLIGHTS_SUCCESS',
    GET_TEXT_DIST_HIGHLIGHTS_FAILURE: 'GET_TEXT_DIST_HIGHLIGHTS_FAILURE',

    All_ASSESSMENT_EMP_DATA_REQUEST: 'All_ASSESSMENT_EMP_DATA_REQUEST',
    All_ASSESSMENT_EMP_DATA_SUCCESS: 'All_ASSESSMENT_EMP_DATA_SUCCESS',
    All_ASSESSMENT_EMP_DATA_FAILURE: 'All_ASSESSMENT_EMP_DATA_FAILURE',

    GET_NUDGE_LIB_LIST_REQUEST: 'GET_NUDGE_LIB_LIST_REQUEST',
    GET_NUDGE_LIB_LIST_SUCCESS: 'GET_NUDGE_LIB_LIST_SUCCESS',
    GET_NUDGE_LIB_LIST_FAILURE: 'GET_NUDGE_LIB_LIST_FAILURE',

    MAP_NUDGE_TO_QUESTION_REQUEST: 'MAP_NUDGE_TO_QUESTION_REQUEST',
    MAP_NUDGE_TO_QUESTION_SUCCESS: 'MAP_NUDGE_TO_QUESTION_SUCCESS',
    MAP_NUDGE_TO_QUESTION_FAILURE: 'MAP_NUDGE_TO_QUESTION_FAILURE',

    GET_NUDGE_TEXT_QUES_LIST_REQUEST: 'GET_NUDGE_TEXT_QUES_LIST_REQUEST',
    GET_NUDGE_TEXT_QUES_LIST_SUCCESS: 'GET_NUDGE_TEXT_QUES_LIST_SUCCESS',
    GET_NUDGE_TEXT_QUES_LIST_FAILURE: 'GET_NUDGE_TEXT_QUES_LIST_FAILURE',

    GET_NUDGE_TEXT_SURVEYID_REQUEST: 'GET_NUDGE_TEXT_SURVEYID_REQUEST',
    GET_NUDGE_TEXT_SURVEYID_SUCCESS: 'GET_NUDGE_TEXT_SURVEYID_SUCCESS',
    GET_NUDGE_TEXT_SURVEYID_FAILURE: 'GET_NUDGE_TEXT_SURVEYID_FAILURE',

    GET_QUESTION_WISE_EXCEL_REQUEST: 'GET_QUESTION_WISE_EXCEL_REQUEST',
    GET_QUESTION_WISE_EXCEL_SUCCESS: 'GET_QUESTION_WISE_EXCEL_SUCCESS',
    GET_QUESTION_WISE_EXCEL_FAILURE: 'GET_QUESTION_WISE_EXCEL_FAILURE',

    GET_REPORT_QUES_WISE_WORLD_CLOUD_REQUEST: 'GET_REPORT_QUES_WISE_WORLD_CLOUD_REQUEST',
    GET_REPORT_QUES_WISE_WORLD_CLOUD_SUCCESS: 'GET_REPORT_QUES_WISE_WORLD_CLOUD_SUCCESS',
    GET_REPORT_QUES_WISE_WORLD_CLOUD_FAILURE: 'GET_REPORT_QUES_WISE_WORLD_CLOUD_FAILURE',


    GET_ASSESSMENT_DIMENSION_V2_REQUEST: 'GET_ASSESSMENT_DIMENSION_V2_REQUEST',
    GET_ASSESSMENT_DIMENSION_V2_SUCCESS: 'GET_ASSESSMENT_DIMENSION_V2_SUCCESS',
    GET_ASSESSMENT_DIMENSION_V2_FAILURE: 'GET_ASSESSMENT_DIMENSION_V2_FAILURE',


    GET_ASSESSMENT_TOP5_DESC_REQUEST: 'GET_ASSESSMENT_TOP5_DESC_REQUEST',
    GET_ASSESSMENT_TOP5_DESC_SUCCESS: 'GET_ASSESSMENT_TOP5_DESC_SUCCESS',
    GET_ASSESSMENT_TOP5_DESC_FAILURE: 'GET_ASSESSMENT_TOP5_DESC_FAILURE',

    GET_MANAGER_TEAM_SIZE_REQUEST: 'GET_MANAGER_TEAM_SIZE_REQUEST',
    GET_MANAGER_TEAM_SIZE_SUCCESS: 'GET_MANAGER_TEAM_SIZE_SUCCESS',
    GET_MANAGER_TEAM_SIZE_FAILURE: 'GET_MANAGER_TEAM_SIZE_FAILURE',

    GET_ICE_INDEX_REQUEST: 'GET_ICE_INDEX_REQUEST',
    GET_ICE_INDEX_SUCCESS: 'GET_ICE_INDEX_SUCCESS',
    GET_ICE_INDEX_FAILURE: 'GET_ICE_INDEX_FAILURE',

    GET_ICE_DIST_REQUEST: 'GET_ICE_DIST_REQUEST',
    GET_ICE_DIST_SUCCESS: 'GET_ICE_DIST_SUCCESS',
    GET_ICE_DIST_FAILURE: 'GET_ICE_DIST_FAILURE',

    GET_ICE_DIST_V2_REQUEST: 'GET_ICE_DIST_V2_REQUEST',
    GET_ICE_DIST_V2_SUCCESS: 'GET_ICE_DIST_V2_SUCCESS',
    GET_ICE_DIST_V2_FAILURE: 'GET_ICE_DIST_V2_FAILURE',

    GET_ICE_CROSS_DIST_REQUEST: 'GET_ICE_CROSS_DIST_REQUEST',
    GET_ICE_CROSS_DIST_SUCCESS: 'GET_ICE_CROSS_DIST_SUCCESS',
    GET_ICE_CROSS_DIST_FAILURE: 'GET_ICE_CROSS_DIST_FAILURE',

    GET_ICE_MIX_HEAT_MAP_REQUEST: 'GET_ICE_MIX_HEAT_MAP_REQUEST',
    GET_ICE_MIX_HEAT_MAP_SUCCESS: 'GET_ICE_MIX_HEAT_MAP_SUCCESS',
    GET_ICE_MIX_HEAT_MAP_FAILURE: 'GET_ICE_MIX_HEAT_MAP_FAILURE',

    GET_ICE_MIX_HEAT_V2_MAP_REQUEST: 'GET_ICE_MIX_HEAT_V2_MAP_REQUEST',
    GET_ICE_MIX_HEAT_V2_MAP_SUCCESS: 'GET_ICE_MIX_HEAT_V2_MAP_SUCCESS',
    GET_ICE_MIX_HEAT_V2_MAP_FAILURE: 'GET_ICE_MIX_HEAT_V2_MAP_FAILURE',

    GET_ICE_NPS_ROW_REQUEST: 'GET_ICE_NPS_ROW_REQUEST',
    GET_ICE_NPS_ROW_SUCCESS: 'GET_ICE_NPS_ROW_SUCCESS',
    GET_ICE_NPS_ROW_FAILURE: 'GET_ICE_NPS_ROW_FAILURE',

    GET_ICE_FEED_BACK_REQUEST: 'GET_ICE_FEED_BACK_REQUEST',
    GET_ICE_FEED_BACK_SUCCESS: 'GET_ICE_FEED_BACK_SUCCESS',
    GET_ICE_FEED_BACK_FAILURE: 'GET_ICE_FEED_BACK_FAILURE',

    GET_LT_HEAD_EMP_LIST_REQUEST: 'GET_LT_HEAD_EMP_LIST_REQUEST',
    GET_LT_HEAD_EMP_LIST_SUCCESS: 'GET_LT_HEAD_EMP_LIST_SUCCESS',
    GET_LT_HEAD_EMP_LIST_FAILURE: 'GET_LT_HEAD_EMP_LIST_FAILURE',

    GET_LEADERSHIP_PARA_CATEGORY_REQUEST: 'GET_LEADERSHIP_PARA_CATEGORY_REQUEST',
    GET_LEADERSHIP_PARA_CATEGORY_SUCCESS: 'GET_LEADERSHIP_PARA_CATEGORY_SUCCESS',
    GET_LEADERSHIP_PARA_CATEGORY_FAILURE: 'GET_LEADERSHIP_PARA_CATEGORY_FAILURE'

};

