import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuestionScore from './Charts/QuestionScore';
import Commentary from '../../../Index/Components/Commentary/Commentary';
import Filter from './Filter/Filter';
import { CSVLink } from "react-csv";

class DimensionPriorities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',

      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: ''
    }
  }
  componentDidMount() {

  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleMultiFilterGetQuestion } = this.props;
    this.setState({ FilterValues: finalFilter, currFilterName: name });
    handleMultiFilterGetQuestion(finalFilter, 2, name);

  }


  render() {
    let { selectedFilterValue, loading,
      indexType, optionType, getCommentData, CountRestrict, getEMPFilterLabel,

      getQuestionData, getQuestionMapData, getQuestionFilterData, handleQuestionFilter, EmpFilterData, getIndexFilterData, getIndexByFilterDMGData

    } = this.props;

    let { currentSelectValue, FilterValues } = this.state;



    let QuestionData = []

    if (this.state.currFilterValue === 'none') {
      QuestionData = getQuestionData
    }
    else {
      QuestionData = getQuestionMapData
    }





    // let QuestionList
    // for(var index234 = 0; index234 < getQuestionData.length; index234++ ){
    //   let DmgQuesList = getQuestionData[index234];


    // }

    let DMGList = []
    if (getQuestionMapData && getQuestionMapData.length > 0) {
      getQuestionMapData.forEach((DmgQuesObj) => {
        if (DmgQuesObj && DmgQuesObj.value && DmgQuesObj.questionList && DmgQuesObj.questionList.length > 0) {
          DMGList.push(DmgQuesObj.value)
        }
      })
    }


    //////console.log("DMGList------------.",DMGList)



    function getFavouribilityScore(question, dmgValue) {
      let score = "";
      if (getQuestionMapData && getQuestionMapData.length > 0) {

        let getIndex41 = getQuestionMapData.findIndex(prev => prev.value === dmgValue)
        if (getIndex41 !== -1) {
          let DmgQuesObj = getQuestionMapData[getIndex41];
          if (DmgQuesObj && DmgQuesObj.questionList && DmgQuesObj.questionList.length > 0) {
            let getIndex42 = DmgQuesObj.questionList.findIndex(prev => prev.name === question.name);
            if (getIndex42 !== -1) {
              let ques = DmgQuesObj.questionList[getIndex42]


              let total = 0;
              let quesData = Object.keys(ques["answers"]);
              //console.log('ORG ==== quesData ===== > : ', quesData)




              let scoreX = 0
              let scoreXZ = 0

              if (ques && ques.favourable && ques.favourable.length > 0) {
                ques.favourable.forEach((item2) => {
                  if (item2) {
                    scoreXZ += ques["answers"][item2]
                  }
                })
                total = ques["count"];

                scoreX = parseFloat(((scoreXZ) * 100 / total).toFixed(2))

              }


              score = parseFloat(((scoreX) * 100 / total).toFixed(2));


            }

          }
        }

      }
      return score ? score : 0
    }


    function getOverallScore(ques) {
      let total = 0;
      let quesData = Object.keys(ques["answers"]);
      //console.log('ORG ==== quesData ===== > : ', quesData)




      let scoreX = 0
      let scoreXZ = 0

      if (ques && ques.favourable && ques.favourable.length > 0) {
        ques.favourable.forEach((item2) => {
          if (item2) {
            scoreXZ += ques["answers"][item2]
          }
        })
        total = ques["count"];
        scoreX = parseFloat(((scoreXZ) * 100 / total).toFixed(2))

      }



      let score = scoreX;


      return score ? score : 0
    }




    function getColor(overallScore, dmgScore) {

      if (false) {
        return { Color: '#f1f1f1', diffTextColor: '#000' }

      }
      else {

        let diffNo = parseInt(Math.abs(parseFloat(overallScore) - parseFloat(dmgScore)));
        //////console.log("diffNo:",diffNo)


        if (parseFloat(overallScore) <= parseFloat(dmgScore)) { //greater (blue)
          if (diffNo >= 0 && diffNo <= 2) {
            return { Color: '#53d453', diffTextColor: '#ffffff' }

          }
          else if (diffNo >= 2 && diffNo <= 12) {
            return { Color: '#30ba30', diffTextColor: '#00000' }

          }
          else {
            return { Color: '#139c13', diffTextColor: '#00000' }
          }

        }
        else { //lesse (red)
          if (diffNo >= 0 && diffNo <= 2) {
            return { Color: '#f5babd', diffTextColor: '#000000' }

          }
          else if (diffNo >= 2 && diffNo <= 12) {
            return { Color: '#ea95a1', diffTextColor: '#000000' }

          }
          else {
            return { Color: '#d03e4d', diffTextColor: '#ffffff' }

          }
        }
      }

    }













    // //console.log('QuestionData--->', QuestionData)
    // //console.log('getQuestionMapData--->', getQuestionMapData)
    // //console.log('getQuestionFilterData--->', getQuestionFilterData)


    let paraList = []

    let count = 0;
    let rowSpan = 0;
    let NewParaList = []

    if (QuestionData && QuestionData.length > 0) {

      QuestionData.forEach((ques) => {
        if (paraList.findIndex(prev => prev === ques.parameterDimensionName) === -1) {
          paraList.push(ques.parameterDimensionName);
          count += 1
        }
        else {
        }
      })

      let obj = {}
      paraList.forEach((para) => {
        obj[para] = 0
        QuestionData.forEach((ques) => {
          if (para === ques.parameterDimensionName) {
            obj[para] += 1
          }
        })
      })


      NewParaList = Object.keys(obj).map((key) => [key, obj[key]])
      //   //console.log('data NewParaList: ',NewParaList)

      rowSpan = (QuestionData.length / count)

      // //console.log('Your Engagement paraList: ',NewParaList,count,rowSpan,QuestionData.length)

    }
    // //console.log('ORG ==== NewParaList ===== > : ',NewParaList)
    // //console.log('ORG ==== QuestionData ===== > : ',QuestionData)

    let NewQuesList = [];
    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((ques) => {
        let total = 0;
        let quesData = Object.keys(ques["answers"]);
        //console.log('ORG ==== quesData ===== > : ', quesData)
        //console.log('ques ==== ques ===== > : ', ques)




        let scoreX = 0
        let scoreXZ = 0

        if (ques && ques.favourable && ques.favourable.length > 0) {
          ques.favourable.forEach((item2) => {
            if (item2) {
              scoreXZ += ques["answers"][item2]
            }
          })
          total = ques["count"];

          scoreX = parseFloat(((scoreXZ) * 100 / total).toFixed(2))

        }

        //console.log('<<<item ==== scoreXZ ===== > : ', scoreXZ)
        //console.log('<<<item ==== scoreX ===== > : ', scoreX)
        //console.log('<<<item ==== total ===== > : ', total)

        NewQuesList.push({
          ...ques,
          score: scoreX
        })
      })
    }


    NewQuesList.sort((a, b) => b.score - a.score);



    //console.log('ORG 11==== NewQuesList ===== > : ',NewQuesList)





    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;
    //  let DemographicDataScore = [];
    //  let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }


      //  DemographicDataScore=getDemographicIndexMultiFilterData;
      //  DemographicDataDistribution=getIndexDistributionData;

    }
    let { GetParameterLabel, name } = this.props;

    //Export Excel Logic
    let ScoreExcel = []
    let filter = FilterValues && FilterValues.length > 0 ? " (" + FilterValues.map((element) => { return element.level + ": " + element.value }).join(",") + ")" : " (Filter: All)"

    if (NewQuesList && NewQuesList.length > 0) {
      NewQuesList.forEach((question) => {
        let para = question && question.parameterDimensionName ?
          GetParameterLabel(question.parameterDimensionName) :
          "";
        let Score = CountRestrict(question && question.count ? question.count : "NaN") ? 0 : question.score
        let temp = {}
        temp["Dimension " + filter] = para;
        temp["Question"] = question.name

        if (FilterValues && FilterValues.length > 0) {
          if (DMGList && DMGList.length > 0) {
            DMGList.forEach((dmg) => {
              temp[dmg] = getFavouribilityScore(question, dmg)
            })
          }

        }
        else {
          temp["Score(%)"] = Score
        }

        ScoreExcel.push(temp)
      });
    }





    return (
      <>
          <div className="md:items-center justify-between flex md:flex-row flex-col gap-4">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />

          {/* <span className="text-xs font-medium text-blue-500 hidden">Need Help?</span> */}
          <div className='flex space-x-4' >
           

          <div>
              {ScoreExcel ?
                <CSVLink data={ScoreExcel} filename={"EnagagementFavorability.csv"}>
                <p className=" bg-green-100 p-2 px-4 rounded-full text-green-500  cursor-pointer text-sm flex items-center">
                    {"Download Excel"}
                  </p>
                </CSVLink>
                : '...'}
            </div>

            <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />
          </div>



        </div>


        {true ?
          <>
            {!(FilterValues && FilterValues.length > 0) ?

              <div onClick={() => this.closeFilter(false)} className=" " >


                <div className="w-full" >

                  <div className="grid md:grid-cols-1 grid-cols-1 gap-4 ">

                    <div className=" bg-white hover:shadow-lg duration-150 transition  border p-6 rounded-lg   cursor-pointer">

                      <h1 className="font-medium text-sm pb-2">Item Ranking</h1>




                      <div className="overflow-x-auto whitespace-nowrap w-full overflow-hidden  mb-4">
                        <table className="w-full border ">
                          <thead>
                            <tr className="bg-gray-100 border-b text-xs   text-gray-800">
                              <th className="py-2 px-2 border-r font-bold"><div className="flex items-center justify-left">Dimension</div></th>
                              <th className="py-2 px-2 border-r font-bold"><div className="flex items-center justify-left">Question</div></th>
                              <th className="py-2 px-4 border-r font-bold"><div className="flex items-center justify-left">Favorability Score</div></th>
                            </tr>
                          </thead>
                          <tbody>
                            {NewQuesList && NewQuesList.length > 0 ?
                              NewQuesList.map((question, index) =>
                                <tr key={index} className="bg-white  mb-10 lg:mb-0 text-xs text-gray-800">
                                  <td className="px-2 border border-b w-2/12">
                                    <div style={{ width: '20%' }}>{question && question.parameterDimensionName ?
                                      GetParameterLabel(question.parameterDimensionName) :
                                      "Others"}</div>
                                  </td>
                                  <td className="tooltip-response-question1 px-2 border border-b w-2/12">
                                    <div className="cursor-pointer whitespace-pre-line w-96">{question.name}</div>

                                  </td>
                                  <td className=" border border-b w-10/12">
                                    <QuestionScore question={question} CountRestrict={CountRestrict} currFilterValue={this.state.currFilterValue} currFilterName={this.state.currFilterName} selectedFilterValue={selectedFilterValue} getQuestionFilterData={getQuestionFilterData} />
                                  </td>
                                </tr>
                              )
                              : null}

                          </tbody>
                        </table>
                      </div>
                      <div className="py-4 text-" style={{ color: "#3366ff" }}>* We are not showing data where employee count is less than 5</div>
                    </div>
                  </div>
                </div>


              </div>
              :

              <div onClick={() => this.closeFilter(false)} className=" " >


                <div className="w-full" >

                  <div className="grid md:grid-cols-1 grid-cols-1 gap-4 ">

                    <div className=" bg-white hover:shadow-lg duration-150 transition  border p-6 rounded-lg   cursor-pointer">

                      <h1 className="font-medium text-sm pb-2">{name} Behaviour Item</h1>




                      <div className="overflow-x-auto whitespace-nowrap w-full overflow-hidden  mb-4">
                        <table className="w-full border ">
                          <thead>
                            <tr className="bg-gray-100 border-b text-xs   text-gray-800">
                              <th className="p-3 font-semibold border-r text-gray-800"><div className="flex items-center justify-left">Dimension</div></th>
                              <th className="p-3 font-semibold border-r text-gray-800"><div className="flex items-center justify-left">Question</div></th>

                              <th className="p-3 font-semibold border-r text-gray-800">
                                <div className="flex items-center justify-left">{"Overall"}</div>
                              </th>

                              {DMGList && DMGList.length > 0 ?
                                DMGList.map((dmg) =>
                                  <th className="p-3 font-semibold border-r text-gray-800">
                                    <div className="flex items-center justify-left">{dmg}</div>
                                  </th>
                                ) : null}



                            </tr>
                          </thead>
                          <tbody>
                            {NewQuesList && NewQuesList.length > 0 ?
                              NewQuesList.map((question, index) =>
                                <tr key={index} className="border-b text-xs   text-gray-800">
                                  <td className="py-2 px-2 border-r font-normal">
                                    <div style={{ width: '20%' }}>{question && question.parameterDimensionName ?
                                      GetParameterLabel(question.parameterDimensionName) :
                                      ""}</div>
                                  </td>
                                  <td className="py-2 px-2 border-r font-normal">
                                    <div className="cursor-pointer whitespace-pre-line w-96">{question.name}</div>
                                  </td>

                                  <td className="py-2 px-2 border-r font-normal">
                                    <div className="cursor-pointer whitespace-pre-line text-center" >{getOverallScore(question)}</div>
                                  </td>


                                  {DMGList && DMGList.length > 0 ?
                                    DMGList.map((dmg) =>
                                      <td className="py-2 px-2 border-r font-normal" style={{ color: getColor(getOverallScore(question), getFavouribilityScore(question, dmg)).diffTextColor, background: getColor(getOverallScore(question), getFavouribilityScore(question, dmg)).Color }}>
                                        <div className="cursor-pointer whitespace-pre-line text-center" >{getFavouribilityScore(question, dmg)}</div>
                                      </td>
                                    ) : null}


                                </tr>
                              )
                              : null}

                          </tbody>
                        </table>
                      </div>
                      <div className="py-4 text-" style={{ color: "#3366ff" }}>* We are not showing data where employee count is less than 5</div>
                    </div>
                  </div>
                </div>


              </div>}


          </>
          :
          <>
            {!loading ?
              <div className="text-center text-3xl text-gray-400 pt-40">We have not calculated this section!</div>
              : null}
          </>
        }
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DimensionPriorities);
