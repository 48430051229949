import React, { Component } from 'react';
import { connect } from 'react-redux';

class DriverTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
    
      
        }
      }
    
      componentDidMount() {
    
      }
    
    
      render() {
        let { 
          GetParameterLabel, getQuestionData,optionTypeM,DemographicDriverManagerData, Name, 
          ManagerDynaObj, EmpFilterData, CurrentManagerData, ManagerIndexDriverData,ParaLabelList
        } = this.props;
        
        ////console.log("---------------------------------------TESTING-----------------------------------------")


        // let parameterLabels = []
        // if(ParaLabelList && ParaLabelList.length>0){
        //   ParaLabelList.forEach((para)=>{
        //     parameterLabels.push(para);

        //   });
        // }


        let overAllNewObj = {

        }

        if(ParaLabelList && ParaLabelList.length>0){
         ParaLabelList.forEach((para)=>{
           overAllNewObj[para+"Avg"] = 0;
         });


          if(ManagerIndexDriverData && ManagerIndexDriverData.length>0){
          ManagerIndexDriverData.forEach((driver,index)=>{
            if(ParaLabelList && ParaLabelList.length>0){
              ParaLabelList.forEach((para)=>{
                overAllNewObj[para+"Avg"] += driver[para+"Avg"];
                
                if((index+1)===ManagerIndexDriverData.length){
                  overAllNewObj[para+"Avg"] = overAllNewObj[para+"Avg"]/ManagerIndexDriverData.length;
                }
              });
              }
          });
          }

        }


        function getColor(score, index) {

          if(score<0){
            return {Color:'#ffffff',diffTextColor:'#000000'}
  
          }
          else{
  
            let diffNo = parseInt(Math.abs(parseFloat(overAllNewObj[index]) - parseFloat(score)));
            // ////console.log("diffNo:",diffNo)
            if(parseFloat(overAllNewObj[index]) <= parseFloat(score)){ //greater (blue)
              if(diffNo>=0 && diffNo<=2){
                return {Color:'#b8d7f2',diffTextColor:'#00000'}
  
              }
              else if(diffNo>=2 && diffNo<=12){
                return {Color:'#4b9ee7',diffTextColor:'#00000'}
  
              }
              else{
                return {Color:'#2a74d5',diffTextColor:'#ffffff'}
              }
  
            }
            else{ //lesse (red)
              if(diffNo>=0 && diffNo<=2){
                return {Color:'#f5babd',diffTextColor:'#000000'}
  
              }
              else if(diffNo>=2 && diffNo<=12){
                return {Color:'#ea95a1',diffTextColor:'#000000'}
  
              }
              else{
                return {Color:'#d03e4d',diffTextColor:'#ffffff'}
  
              }
            }
          }
  
        }

        let {  CollectDataManagerForActionPlan , managerCollectionList ,DemographicIndexForManagerData} = this.props;

        ////console.log("ccc======== data  DemographicIndexForManagerData ===========> ",  DemographicIndexForManagerData)

        function compareScore(score, index, data) {
          // ////console.log("ccc======== data  DemographicIndexForManagerData ===========> ",  DemographicIndexForManagerData)

          let getIndex = -1;
          let emp_id = "";
          if(DemographicIndexForManagerData && DemographicIndexForManagerData.length>0){
            getIndex = DemographicIndexForManagerData.findIndex(prev=>prev.name===data.name);
            if(getIndex !==-1){
              emp_id = DemographicIndexForManagerData[getIndex].emp_id
            }
          }
          let temp =  {
            "isLow":(parseFloat(overAllNewObj[index]) > parseFloat(score))?true:false,
            "IndexName":index,
            "overallScore":overAllNewObj&&overAllNewObj[index]?overAllNewObj[index]:0,
            "managerScore":parseFloat(score)?parseFloat(score):0,
            "emp_id":emp_id,
            "managerName":data.name,
            "code":(data.id+"_"+index).toString()

          }
          return temp
        }



        return (
          <>
               
               <div className="pb-2  whitespace-nowrap overflow-x-auto ">
              <table className="border-collapse w-full ">

                  <thead>
                    <tr className="text-xs">


                      <th className="p-3 font-semibold bg-gray-50 text-gray-800 border ">{"Drivers"}</th>


                      {DemographicDriverManagerData && DemographicDriverManagerData.length>0?
                        DemographicDriverManagerData.map((item)=>
                          <th className="p-3 font-semibold bg-gray-50 text-gray-800 border ">{item.name}</th>
                        )
                      :null}

                    </tr>
                  </thead>
                  
                  <tbody>
                  <>
                    {ParaLabelList && ParaLabelList.length>0?
                      ParaLabelList.map((driver, driverIndex)=>
                        <tr key={driverIndex} className="border-separate border border-black-800">
                            <td className="p-3 text-sm border border-b font-semibold text-center">{GetParameterLabel(driver)}</td> 
                            {DemographicDriverManagerData && DemographicDriverManagerData.length>0?
                                DemographicDriverManagerData.map((item, index)=>
                                <td className="p-3 text-sm border border-b text-center bg-blue-500" style={{

                                  background:getColor((item && item[driver+"Avg"])?item[driver+"Avg"]:0,driver+"Avg").Color,
                                  color:getColor((item && item[driver+"Avg"])?item[driver+"Avg"]:0,driver+"Avg").diffTextColor


                                }}>  {(item && item[driver+"Avg"])?item[driver+"Avg"].toFixed(2):'0.00'} 
                                <input type="checkbox" className="mx-2" checked={managerCollectionList.some(prev=>prev.code===(item.id+"_"+driver+"Avg").toString())} onChange={()=>{}} onClick={()=>CollectDataManagerForActionPlan(compareScore((item && item[driver+"Avg"])?item[driver+"Avg"]:0,driver+"Avg",item))} />
                                </td> 
                                  )
                            :null}
                        </tr>
                      )
                    :null}
                </>


                    
          </tbody>
        </table>
        </div>
          </>
        );
      }
    }
    function mapStateToProps(state) {
      // ////console.log("state  ", state);
      const { loggingIn } = state.authentication;
      const { users, dashboard } = state;
      return {
        loggingIn,
        users,
        dashboard
      };
    }
export default connect(mapStateToProps)(DriverTable);
