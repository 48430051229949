import React, { Component } from 'react';
import { connect } from 'react-redux';
// import ReactEcharts from "echarts-for-react";

class QuestionTable extends Component {
  constructor(props) {
    super(props);
    this.state = {


    }
  }

  componentDidMount() {

  }


  render() {
    let { getQuestionsData, name, type } = this.props;



    function getScoreValueX(relation, questionX) {
      let scoreMean = 0;
      if (relation !== "Self") {
        let otherSum = 0;
        let otherCount = 0;

        if (questionX && questionX.relationList && questionX.relationList.length > 0) {
          questionX.relationList.forEach((relation) => {
            if (relation !== "Self") {
              otherCount += Number(questionX[relation + "_count"])


              otherSum += Number(questionX[relation + "_sum"]);
            }
          })
        }
        scoreMean = Number((otherSum / otherCount).toFixed(2))
      }
      else {
        scoreMean = Number(questionX["Self_sum"] / questionX["Self_count"]);

      }
      return [Number((scoreMean).toFixed(2))]

    }


    let questionTableList = []
    if (getQuestionsData && getQuestionsData.length > 0) {
      getQuestionsData.forEach((element) => {
        let temp = {
          "name": element.name,
          "para": element.parameterDimensionName,
          "score": getScoreValueX("Other", element)
        }
        questionTableList.push(temp)
      })
      if (type === 1) {
        questionTableList.sort((b, a) => parseFloat(a.score) - parseFloat(b.score))
      } else {
        questionTableList.sort((a, b) => parseFloat(a.score) - parseFloat(b.score))
      }

    }



    console.log('-------->>>>getQuestionsData; ', getQuestionsData)




    return (
      <>
        <div className="p-4 bg-white border rounded-md cursor-pointer">
          <div className='space-y-6' >
            <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">{name}</h1>

            <div className="w-full mb-4 overflow-hidden overflow-x-auto whitespace-nowrap">
              <table className="w-full border ">
                <thead>
                  <tr className="text-sm text-gray-800 capitalize border-b" style={{ background: type==2?'#ffcccc':'#dcedc8', }} >

                    <th className="w-1/6 p-2 font-medium text-left border-r">Parameter</th>
                    <th className="w-4/6 p-2 font-medium text-left border-r">Question</th>
                    <th className="p-2 font-medium text-left border-r">{"Score (Exclude Self)"}</th>


                  </tr>
                </thead>
                <tbody>

                  {questionTableList && questionTableList.length > 0 ?
                    questionTableList.map((relation, index2) =>
                      (index2 < 10) ?
                        <tr key={index2} className="mb-10 text-sm text-gray-800 bg-white lg:mb-0">
                          <td className="w-1/6 p-2 text-left border border-b">
                            {relation.para}
                          </td>
                          <td className="w-4/6 p-2 text-left border border-b">
                            {relation.name}
                          </td>
                          <td className="p-2 text-left border border-b ">
                            {relation.score}
                          </td>
                        </tr>
                        : null
                    ) : null}


                </tbody>
              </table>
            </div>
          </div>
        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionTable);
