import React from 'react';
import ReactEcharts from "echarts-for-react";


export default function BarCorelation(props) {
    
  let { DriverName, DriverValues, TotalOverAllCount } = props;


  let XAxis = DriverName
  let List = []

  if(DriverValues && DriverValues.length>0){
    DriverValues.forEach((item4)=>{
      List.push((item4*100)/TotalOverAllCount)
    });
  }

  //////console.log("TotalOverAllCount",TotalOverAllCount)

  return (
    <>	
      <div className="pt-1 pb-20">

        <ReactEcharts
        style={{height:'400px'}}
          option={{
            tooltip:{
              trigger: 'item',
              formatter:function (a) {
                // //console.log('params: ', a)
                return '<b>Category:</b> '+XAxis[a.dataIndex]+'</br>'+'<b>Value:</b> '+((a.value)?a.value.toFixed(2)+"%":0).toString()
              }
            },
            xAxis: {
                type: 'category',
                data: XAxis,
                axisLabel:{
                  show:true,
                  rotate:0,
                  interval:0,
                  formatter:function(a){
                    //console.log(a)
                    return a?a.length>12?a.slice(0,12)+'..':a:a
                  }
                }
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: List,
                type: 'bar',
                showBackground: true,
                barWidth:'25PX',
                color:'#6536d1'
            }]
        }}
                          
        />
       <div className="px-2 py-4 text-center"><b>{"X-Axis: "}</b>{"Life evaluation response category (ladder 0-10)"}{", "}<b>{"Y-Axis: "}</b>{"Percentage of overall response"}</div>

    </div>


                 
    </>
  );
}
