import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dashboardActions, growActions } from "../../_actions";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../GrowActionPlan/Components/Header';

class ManageProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }

    this.inputRef = React.createRef();

  }
  componentDidMount() {

    this.props.dispatch(dashboardActions.getGrowProfile());
    this.props.dispatch(growActions.getGrowPlusBucket_User());
    this.props.dispatch(growActions.getGrowPlusTaskStatus_User());
  }

  handleFile = (event) => {
    this.props.dispatch(dashboardActions.uploadProfile(event.target.files[event.target.files.length - 1]));
    toast("Profile picture added successfully!");
  }

  render() {

    let { dashboard, grow } = this.props;
    let { GrowProfileData } = dashboard;
    let { getGrowPlusBucket_UserData, getGrowPlusTaskStatus_UserData } = grow;


    const formatDate = (dateString) => {
      // Split the date string into components
      const [day, month, year] = dateString.split('/');
      // Adjust the year to four digits, assuming the date is after the year 2000
      const formattedYear = year.length === 2 ? `20${year}` : year;
      // Return the date in "YYYY-MM-DD" format
      return `${formattedYear}-${month}-${day}`;
    };

    const checkStatusPerc = (list, bucketId, field) => {
      let perc = 0;
      if (list && list.length > 0) {
        let getIndex = list.findIndex(prev => prev.bucketId.toString() === bucketId.toString());
        if (getIndex !== -1) {
          perc = list && list[getIndex] && list[getIndex][field] ? list[getIndex][field] : 0
        }
      }
      return perc > 0 ? parseFloat(perc) : 0
    }


    return (
      <>
        <section className="relative">
          <Header />
          <div className='border-b py-4 px-4 mx-auto xl:w-11/12 xl:px-0'>
            <h1 className='2xl:text-2xl md:text-xl text-lg font-semibold text-[#212121]'> Profile</h1>
          </div>

          <div className='flex lg:flex-row flex-col items-start py-6  px-4 mx-auto xl:w-11/12 xl:px-0 2xl:gap-16 gap-10 '>
            <div className='space-y-4 lg:w-[28rem] w-full flex-shrink-0 '>
              <div className='border rounded-xl   w-full overflow-hidden'>
                <div className='h-36 bg-cover bg-center' style={{ backgroundImage: `url('img/grow/profile-bg.png')` }}>
                </div>
                {/* {GrowProfileData && GrowProfileData.imageLinkV2 ?
                  <img
                    src={GrowProfileData.imageLinkV2} onError={(e) => { e.target.onerror = null; e.target.src = "images/profile/user1.png" }}
                    className="z-10 w-28 h-28 rounded-full ring-4 ring-white mx-auto -mt-16"
                  />
                  : */}
                <img src="img/profile/user1.png" className="z-10 w-28 h-28 rounded-full ring-4 ring-white mx-auto -mt-16"
                />
                {/* } */}
                <div className='text-center font-medium text-[#212121]/70 py-4'>
                  <h1 className='text-[#212121] 2xl:text-2xl md:text-xl text-lg font-semibold'>{GrowProfileData && GrowProfileData.name || 'N/A'}</h1>
                  {/* <p className='md:text-base text-sm'>{GrowProfileData && GrowProfileData.emp_id || 'N/A'}</p> */}
                  <p className='md:text-base text-sm'>{GrowProfileData && GrowProfileData.email || 'N/A'}</p>
                </div>
              </div>
            </div>


            <div className='border rounded-xl w-full overflow-hidden'>
              <div className=' font-medium text-[#212121]/70 md:p-6 p-4 space-y-4'>
                <h1 className='text-[#212121] 2xl:text-2xl md:text-xl text-lg font-semibold'>Leadership programs</h1>


                {
                  getGrowPlusBucket_UserData && getGrowPlusBucket_UserData.length > 0 ?
                    getGrowPlusBucket_UserData.map((item, index) =>
                      <>
                        <div className="bg-[#E6F5F3] rounded-lg p-6 space-y-4">
                          <div className="space-y-2 ">
                            <h2 className="2xl:text-xl md:text-lg text-base font-semibold text-[#212121]">{item && item.name}</h2>
                            <div className="flex items-center justify-start font-medium space-x-1 text-[#212121]/70 md:text-base text-sm">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 25 25"
                                fill="none"
                              >
                                <path
                                  d="M6.5 2.5H18.5V8.5L14.5 12.5L18.5 16.5V22.5H6.5V16.5L10.5 12.5L6.5 8.5V2.5ZM16.5 17L12.5 13L8.5 17V20.5H16.5V17ZM12.5 12L16.5 8V4.5H8.5V8L12.5 12ZM10.5 6.5H14.5V7.25L12.5 9.25L10.5 7.25V6.5Z"
                                  fill="#212121"
                                  fillOpacity="0.54"
                                />
                              </svg>
                              <span>{checkStatusPerc(getGrowPlusTaskStatus_UserData, item.id, "totalMonths")} Months</span>
                            </div>
                            <div className="w-full h-2 rounded-full bg-white">
                              {/* <div className="h-2 rounded-full bg-[#009688] w-[40%]" /> */}
                              <div className="h-2 rounded-full bg-[#009688] w-[40%]" style={{ width: `${checkStatusPerc(getGrowPlusTaskStatus_UserData, item.id, "percent")}%` }} />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null
                }

              </div>
            </div>

            {/* <div className='w-full grid grid-cols-2 gap-6'>
              <div className='space-y-2 col-span-2 '>
                <p className='before:content-["*"] before:text-red-500 text-[#212121]/70 text-sm'>Full Name</p>
                <input className='w-full p-4 py-2.5 rounded-md  bg-[#F9FAFB] border focus:outline-none placeholder:text-[#212121] placeholder:font-medium font-medium' value={(GrowProfileData.name) ? GrowProfileData.name : 'User'} placeholder='Enter Name' />
              </div>
              <div className='space-y-2 col-span-2 '>
                <p className='before:content-["*"] before:text-red-500 text-[#212121]/70 text-sm'>Email</p>
                <input className='w-full p-4 py-2.5 rounded-md  bg-[#F9FAFB] border focus:outline-none placeholder:text-[#212121] placeholder:font-medium font-medium' value={(GrowProfileData.email) ? GrowProfileData.email : ''} placeholder='harikc@xyz.com' />
              </div>
              <div className='space-y-2 col-span-2 '>
                <p className='before:content-["*"] before:text-red-500 text-[#212121]/70 text-sm'>Mobile</p>
                <input className='w-full p-4 py-2.5 rounded-md  bg-[#F9FAFB] border focus:outline-none placeholder:text-[#212121] placeholder:font-medium font-medium' value={(GrowProfileData.mobile) ? GrowProfileData.mobile : ''} placeholder='+91' />
              </div>

              <div className='space-y-2 md:col-span-1 col-span-2 '>
                <p className='before:content-["*"] before:text-red-500 text-[#212121]/70 text-sm'>Role</p>
                <input className='w-full p-4 py-2.5 rounded-md  bg-[#F9FAFB] border focus:outline-none placeholder:text-[#212121] placeholder:font-medium font-medium' value={(GrowProfileData.Role) ? GrowProfileData.Role : ''} placeholder='Product Designer' />
              </div>
              <div className='space-y-2 md:col-span-1 col-span-2 '>
                <p className='before:content-["*"] before:text-red-500 text-[#212121]/70 text-sm'>Country</p>
                <input className='w-full p-4 py-2.5 rounded-md  bg-[#F9FAFB] border focus:outline-none placeholder:text-[#212121] placeholder:font-medium font-medium' value={(GrowProfileData.Country) ? GrowProfileData.Country : ''} placeholder='' />
              </div>
              <div className='space-y-2 md:col-span-1 col-span-2 '>
                <p className='before:content-["*"] before:text-red-500 text-[#212121]/70 text-sm'>Gender</p>

                <div className="flex items-center space-x-4">
                  <button className={`border flex items-center py-3 justify-center font-medium px-4 rounded-md w-full text-center ${GrowProfileData.gender === 'Male' ? 'border-[#009688] text-[#009688]' : 'text-[#212121]/70'}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-mars">  <path stroke="none" d="M0 0h24v24H0z" fill="none" />  <path d="M10 14m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0" />  <path d="M19 5l-5.4 5.4" />  <path d="M19 5l-5 0" />  <path d="M19 5l0 5" /></svg>
                    <span>Male</span>
                  </button>

                  <button className={`border flex items-center py-3 justify-center font-medium px-4 rounded-md w-full text-center ${GrowProfileData.gender === 'Female' ? 'border-[#009688] text-[#009688]' : 'text-[#212121]/70'}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-venus">  <path stroke="none" d="M0 0h24v24H0z" fill="none" />  <path d="M12 9m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0" />  <path d="M12 14l0 7" />  <path d="M9 18l6 0" /></svg>
                    <span>Female</span>
                  </button>

                  <button className={`border flex items-center py-3 justify-center font-medium px-4 rounded-md w-full text-center ${GrowProfileData.gender === 'Others' ? 'border-[#009688] text-[#009688]' : 'text-[#212121]/70'}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-gender-androgyne">  <path stroke="none" d="M0 0h24v24H0z" fill="none" />  <path d="M13 11l6 -6" />  <path d="M9 15m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0" />  <path d="M19 9v-4h-4" />  <path d="M16.5 10.5l-3 -3" /></svg><span>Others</span>
                  </button>
                </div>
              </div>
              <div className='space-y-2 md:col-span-1 col-span-2'>
                <p className='before:content-["*"] before:text-red-500 text-[#212121]/70 text-sm'>Date of Birth</p>
                <input type='date' className='w-full p-4 py-2.5 rounded-md  bg-[#F9FAFB] border focus:outline-none placeholder:text-[#212121] placeholder:font-medium font-medium'
                  // value={(GrowProfileData.Date) ? GrowProfileData.Date : ''}
                  value={GrowProfileData.Date ? formatDate(GrowProfileData.Date) : ''}
                  // value={GrowProfileData.Date ? new Date(GrowProfileData.Date).toISOString().split('T')[0] : ''}
                  placeholder='' />
              </div>

              <div>
                <button className='w-56  bg-[#009688] flex items-center py-3 justify-center font-medium px-4 text-[#fff] rounded-md  text-center uppercase'>
                  <span>Update</span></button>
              </div>


            </div> */}


          </div>
          <div className='border-t flex items-center justify-center py-4 bottom-0 left-0 bg-white fixed w-full '>
            <p className="text-[#3D405B] text-center lg:text-base text-sm">© Copyright GrowPlus Leadership Development Program 2024</p>
          </div>

          <div className='h-20' />




        </section>
        <ToastContainer />
      </>
    );
  }
}

function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, users, grow, dashboard } = state;
  return {
    loggingIn,
    questions,
    users,
    dashboard,
    grow
  };
}

export default connect(mapStateToProps)(ManageProfile)
