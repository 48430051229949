import React from "react";
import CustomHorizontalBar from "./CustomHorizontalBar";

export default function EmployeeWiseTable(props) {
  let {
    getAssessmentDimensionsData,
    getAllAnsweredAssessmentEmpDataList,

    getAssessmentReportByEmpIDData,
    crrSelectedEmp,
    EditDetailsFn,
    EditButtonFn,
    hindiPDFstatic,
    isTop
  } = props;

  // function hexToRgbA(hex, opacity) {
  //   var c;
  //   if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
  //     c = hex.substring(1).split("");
  //     if (c.length == 3) {
  //       c = [c[0], c[0], c[1], c[1], c[2], c[2]];
  //     }
  //     c = "0x" + c.join("");
  //     return (
  //       "rgba(" +
  //       [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
  //       "," +
  //       opacity +
  //       ")"
  //     );
  //   }
  //   throw hex;
  // }

  // let newList = [];
  // if (
  //   getAssessmentReportByEmpIDData &&
  //   getAssessmentReportByEmpIDData.length > 0
  // ) {
  //   getAssessmentReportByEmpIDData.forEach((element, index) => {
  //     let getIndex = newList.findIndex(
  //       (prev) => prev.bucket === element.bucket
  //     );
  //     if (getIndex === -1) {
  //       let temp = {
  //         bucket: element.bucket,
  //         colorCode: element.colorCode,
  //         dimensions: [
  //           {
  //             rank: index + 1,
  //             name: element.dimension,
  //           },
  //         ],
  //       };
  //       newList.push(temp);
  //     } else {
  //       newList[getIndex]["dimensions"].push({
  //         rank: index + 1,
  //         name: element.dimension,
  //       });
  //     }
  //   });
  // }

  // //console.log(
  //   "getAssessmentReportByEmpIDData--->",
  //   getAssessmentReportByEmpIDData
  // );
  // //console.log("newList--->", newList);

  // function getOpacity(rank, item) {
  //   let total =
  //     item && item.dimensions && item.dimensions.length > 0
  //       ? item.dimensions.length
  //       : 0;
  //   return (total + 1 - Number(rank)) / (total + 2);
  // }


  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
    throw hex;
  }





  let AssessmentList = []

  if (getAssessmentDimensionsData && getAssessmentDimensionsData.length > 0) {
    getAssessmentDimensionsData.forEach((element) => {
      AssessmentList.push({
        ...element,
        finalSum: (Number(element.count) * Number(element.rankSum))
      })
    })
    AssessmentList.sort((b, a) => (a.finalSum - b.finalSum))
  }

  //console.log("AssessmentList--1->", AssessmentList)



  function checkForGeneralship(bucket, dimension, isSchool) {
    let text = "";
    if (bucket && dimension) {
      if (isSchool) {
        text = "school_";
      } else if (dimension.toLowerCase() === "generalship" && bucket.toLowerCase() === "thinking talents") {
        text = "old";
      } else if (dimension.toLowerCase() === "enhancer" && bucket.toLowerCase() === "relating talents") {
        text = "old";
      } else if (dimension.toLowerCase() === "coach" && bucket.toLowerCase() === "leading talents") {
        text = "old";
      }
    }
    return text
  }


  function getTopBottomIndex(list, type) {
    let range1 = 0;
    let range2 = 0;
    if (list && list.length > 0) {
      if (type === "top") {
        range1 = 5;
        range2 = 5;
      } else if (type === "mid") {
        range1 = 6;
        range2 = list.length - 5;
      } else if (type === "bottom") {
        range1 = list.length - 4
        range2 = list.length - 4
      }
    }
    return {
      "range1": range1,
      "range2": range2
    }
  }



  function chunkArray(array, chunkSize) {
    let result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      let chunk = array.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  }

  let size = 10;
  let finalList = getAllAnsweredAssessmentEmpDataList && getAllAnsweredAssessmentEmpDataList.length > 0 ? chunkArray(getAllAnsweredAssessmentEmpDataList, size) : [];


  let title = isTop ? "Team Members Dominant Themes" : "Team Members Non Dominant Themes";
  return (
    <>
      {finalList && finalList.length > 0 &&
        finalList.map((innerList, index) =>
          <main className="p-10 px-6 space-y-6 bg-white page-break">
            <div className='page-break'>
              <div className="flex items-center justify-between w-full py-4 border-b">
                <h1 className="text-lg font-semibold"> {EditButtonFn("TalentAssessmentReport", "Team TalentPlusStrengths Assessment Report")}</h1>
                <p className="text-lg font-semibold">
                  {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""}
                </p>
              </div>

              <div className='py-2 space-y-6'>
                <h1 className='text-3xl text-[#212121] pt-2'>
                  {(index ? title + " (Continue)" : title)}
                </h1>

                <div className='w-full'>
                  <div>

                    {innerList && innerList.length > 0 ?
                      innerList.map((ele) =>
                        <div className='bg-[#F1F5F9] border-b border-white space-y-2 px-4 py-4' >
                          <div className='    '  >
                            {ele && ele.name}
                          </div>
                          <div className='  '  >
                            <CustomHorizontalBar
                              ele={ele}
                              EditDetailsFn={EditDetailsFn}
                              getAssessmentDimensionsData={getAssessmentDimensionsData}
                              isTop={isTop}
                            />
                          </div>
                        </div>
                      ) : null}


                  </div>

                </div>
              </div>
            </div>
          </main>
        )}


    </>
  );
}
