import React, { Component } from "react";
import { connect } from "react-redux";
import ReactWordcloud from "react-wordcloud";
import "tippy.js/dist/tippy.css";

class WordChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedText: "",
    };
  }

  componentDidMount() {}

  handleKeyword = (data) => {
    let { handleKeywordCall } = this.props;
    handleKeywordCall(data.text);
    this.setState({ SelectedText: data.text + " (" + data.value + ")" });
  };

  handleKeywordTooltip = (data) => {
    this.setState({ SelectedText: data.text + " (" + data.value + ")" });
  };

  render() {
    let { wordCloudError, wordCloudData, loading } = this.props;

    ////console.log('SelectedText: ',SelectedText)

    let words = [];

    if (wordCloudData && wordCloudData.list && wordCloudData.list.length > 0) {
      wordCloudData.list.forEach((item) => {
        words.push({
          text: item[0],
          value: item[1],
        });
      });
    }

    const callbacks = {
      // getWordColor: word => word.value > 50 ? "blue" : "red",
      onWordClick: (data) => this.handleKeyword(data),

      ////onWordMouseOver: console.log,
      // getWordTooltip: (data)=>{},
      getWordTooltip: function (a) {
        return a.text + ": " + a.value;
      },
    };

    const options = {
      rotations: 2,
      rotationAngles: [0, -90],
      padding: 5,
      fontWeight: 600,
      fontSizes: [20, 50],
    };

    return (
      <>
        <div className=" bg-white hover:shadow-lg duration-150 transition  border  rounded-lg   cursor-pointer">
        
        <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap px-6 py-4 border-b ">
          Word Cloud
        </h1>


          {/* chart */}

          {words && words.length > 0 ? (
            <div className="h-[600px] p-6">
              <ReactWordcloud
                callbacks={callbacks}
                options={options}
                words={words}
              />
            </div>
          ) : (
            <>
              {!loading ? (
                <div className="h-[600px] p-6 flex justify-center items-center">
                  {wordCloudError}
                </div>
              ) : null}
            </>
          )}
          <h1 className="text-sm text-[#3d405B]/70 p-6 border-t text-center">

            *We are not showing data where employee count is less than 5
          </h1>
        </div>

        {/* 
                    <div className="flex justify-between items-center">
                    <h1 className="text-sm text-gray-900 font-semibold">Word Cloud
                    <span className="text-blue-500 font-normal">{" ( A word cloud is a collection or cluster of words depicted in different sizes. The bigger and bolder the word appears, the more often it’s mentioned within a given text and the more important it is,on right side words with count bar graph is there. ) "}</span>
                    </h1>
                    </div>
                    <div style={{height:'400px',marginTop:'10px'}}>
                      {words && words.length>0?
                        <ReactWordcloud 
                        callbacks={callbacks}
                        options={options}
                        words={words} 
                        />
                      :
                <>
                {!loading?
                <div className="text-center text-3xl text-gray-400 pt-40">We have not calculated this section!</div>:null}
                </>
                      }
                    </div>
                    <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div>

             */}
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn,
  };
}
export default connect(mapStateToProps)(WordChart);
