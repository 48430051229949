import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class QuestionScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }


  handleWords=(params)=>{
    let { handleKeyword, allWordList }= this.props;
    //////console.log("params----------->",params)
    //////console.log("allWordList----------->",allWordList)
    // wordsList && wordsList.length>0?wordsList.findIndex(prev=>prev.text===params.seriesName)!==-1?wordsList[wordsList.findIndex(prev=>prev.text===params.seriesName)].value:0:0
    
    let objectValue = {}
    if(allWordList && allWordList.length>0){
      let getIndex = allWordList.findIndex(prev=>prev.topic===params.seriesName);
      if(getIndex!==-1){
        objectValue = allWordList[getIndex];
      }
    }
    
    if(params && params.seriesName){
      let data={
        "topic":params.seriesName,
        "value":objectValue&&objectValue.value?objectValue.value:0,
        "keywordList":objectValue&&objectValue.keywordList&&objectValue.keywordList.length>0?objectValue.keywordList:[],
        "isCustom":objectValue&&objectValue.isCustom?true:false

      }
      //////console.log("data----------->",data)

      handleKeyword(data);
    }


  } 


  render() {
    let {  question, getQuestionFilterData,  CountRestrict,
    
      word, size, sentimentObj
    
    } = this.props;
    

    //console.log('getQuestionFilterData:--ss-x> ',getQuestionFilterData)


    // let filterQuestion = {}
    // if(question && getQuestionFilterData && getQuestionFilterData.length>0){
    //     getQuestionFilterData.forEach((quesFilter)=>{
    //         if(quesFilter.name === question.name){
    //             filterQuestion = quesFilter;
    //         }
    //     })
    // }


    // let total = 0
    let names = []
    let weightage1 = [];
    let weightage2 = [];

    let Score = 0
    if (question) {

      Score = CountRestrict(question && question.count?question.count:"NaN")?0:question.score;
      //console.log('getQuestionFilterData:--ss-question> ', question,Score)

        names.push({
            value:"Overall",
            textStyle:{fontWeight:'bold'}
        })
        weightage1.push({
            value:Score,
            itemStyle: {color: '#6536d1'}
        });
        weightage2.push({
            value:100-Score,
            itemStyle: {color: '#ffffff'}

        });
    
    }



    let Positive1 = sentimentObj && sentimentObj["Positive"]? sentimentObj["Positive"]:0;
    let Negative1 = sentimentObj && sentimentObj["Negative"]? sentimentObj["Negative"]:0;
    let Neutral1 = sentimentObj && sentimentObj["Neutral"]? sentimentObj["Neutral"]:0;
    let total1 = Positive1 + Negative1 + Neutral1;
             
    function calcPer(obj,name){


      let value = obj && obj[name]? obj[name]:0;
      let final = parseFloat((value*100)/total1).toFixed(2);

      return final && final!=="NaN"? final:0
      
    }

    let data1 = []

    if(total1){
      data1 = [
        { value: calcPer(sentimentObj,"Positive"), name: 'Positive', itemStyle:{color:'#15d14d'}  },
        { value: calcPer(sentimentObj,"Negative"), name: 'Negative', itemStyle:{color:'#bf3632'}  },
        { value: calcPer(sentimentObj,"Neutral"), name: 'Neutral', itemStyle:{color:'#ffc619'}  }
      ]

    }
    else{
      data1 = [
        { value: calcPer(sentimentObj,"Positive"), name: 'Positive', itemStyle:{color:'#dbdbdb'} },
        { value: calcPer(sentimentObj,"Negative"), name: 'Negative', itemStyle:{color:'#dbdbdb'}  },
        { value: calcPer(sentimentObj,"Neutral"), name: 'Neutral', itemStyle:{color:'#dbdbdb'}  }
      ]
    }

    function getSize(size1){
      // return ((size1/maxSize)*150).toString()+"px"
      return (190).toString()+"px"

    }

    return (
      <>
      
              <ReactEcharts
              style={{height:getSize(size),width:getSize(size)}}
              onEvents= {{
                'click': (params)=>this.handleWords(params)
              }}
              option={{
                tooltip: {
                  trigger: 'item'
                },

                series: [
                  {
                    name: word,
                    type: 'pie',
                    radius: ['70%', '90%'],
                    avoidLabelOverlap: false,
                    label: {
                      show: true,
                      position: 'center',
                      fontSize: '17',
                      fontWeight: 'bold',
                      formatter: function(valueData) {
                        return valueData.seriesName?valueData.seriesName:"NaN"

                      }
                    },
                    emphasis: {
                      label: {
                        show: true,
                        fontSize: '17',
                        fontWeight: 'bold',
                        formatter: function(valueData) {
                          return valueData.seriesName?valueData.seriesName:"NaN"

                        }
                      }
                    },
                    labelLine: {
                      show: true
                    },
                    data: data1
                  }
                ]
              }}
          />
      </>
    );
  }
}
function mapStateToProps(state) {
//   //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionScore);
