import React, { Component } from "react";
import { connect } from "react-redux";
import QuestionScore from "./Charts/QuestionScore";
import Commentary from "../Commentary/Commentary";
import Filter from "../Filters/IndexFilter/Filter";
import { CSVLink } from "react-csv";
import NudgesBehaviourItem from "../Nudges/NudgesBehaviourItem";
import DataNotFound from "../../Dashboard/Components/DataNotFound";
class DimensionPriorities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: "none",
      currFilterName: "none",

      currentSelectValue: "",
      FilterValues: [],
      showFilter: false,
      showValue: "",
    };
  }
  componentDidMount() { }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { handleMultiFilterGetDimenPrioQuestion } = this.props;
    this.setState({ FilterValues: finalFilter });
    handleMultiFilterGetDimenPrioQuestion(finalFilter, "", "", false);
  };

  render() {
    let {
      ErrorIndexObj,
      selectedFilterValue,
      loading,
      indexType,
      optionType,
      getCommentData,
      CountRestrict,
      getEMPFilterLabel,

      getQuestionData,
      getQuestionFilterData,
      handleQuestionFilter,
      EmpFilterData,
      getIndexFilterData,
      getIndexByFilterDMGData,
      IndexName,
      getNudgeTextBySurveyIdData
    } = this.props;

    let { currentSelectValue, FilterValues } = this.state;

    let QuestionData = [];

    if (this.state.currFilterValue === "none") {
      QuestionData = getQuestionData;
    } else {
      QuestionData = getQuestionFilterData;
    }

    //console.log('QuestionData--->', QuestionData)
    //console.log('getQuestionData--->', getQuestionData)
    //console.log('getQuestionFilterData--->', getQuestionFilterData)

    let paraList = [];

    let count = 0;
    let rowSpan = 0;
    let NewParaList = [];

    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((ques) => {
        if (
          paraList.findIndex((prev) => prev === ques.parameterDimensionName) ===
          -1
        ) {
          paraList.push(ques.parameterDimensionName);
          count += 1;
        } else {
        }
      });

      let obj = {};
      paraList.forEach((para) => {
        obj[para] = 0;
        QuestionData.forEach((ques) => {
          if (para === ques.parameterDimensionName) {
            obj[para] += 1;
          }
        });
      });

      NewParaList = Object.keys(obj).map((key) => [key, obj[key]]);
      //   //console.log('data NewParaList: ',NewParaList)

      rowSpan = QuestionData.length / count;

      // //console.log('Your Engagement paraList: ',NewParaList,count,rowSpan,QuestionData.length)
    }
    // //console.log('ORG ==== NewParaList ===== > : ',NewParaList)
    // //console.log('ORG ==== QuestionData ===== > : ',QuestionData)

    let NewQuesList = [];
    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((ques) => {
        let total =
          ques["1"] + ques["2"] + ques["3"] + ques["4"] + ques["5"] + ques["6"];
        NewQuesList.push({
          ...ques,
          score: parseFloat(
            (((ques["5"] + ques["6"]) * 100) / total).toFixed(2)
          ),
        });
      });
    }

    NewQuesList.sort((b, a) => a.score - b.score);

    // //console.log('ORG ==== NewQuesList ===== > : ',NewQuesList)

    let EmpFilterData2 = [];
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== "ManagerPID") {
          EmpFilterData2.push(emp);
        }
      });
    }

    let isDisable = true;
    //  let DemographicDataScore = [];
    //  let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {
      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === "All") {
            isDisable = false;
          }
        });
      }

      //  DemographicDataScore=getDemographicIndexMultiFilterData;
      //  DemographicDataDistribution=getIndexDistributionData;
    }
    let { GetParameterLabel, name } = this.props;

    //Export Excel Logic
    let ScoreExcel = [];
    let filter =
      FilterValues && FilterValues.length > 0
        ? " (" +
        FilterValues.map((element) => {
          return element.level + ": " + element.value;
        }).join(",") +
        ")"
        : " (Filter: All)";

    if (NewQuesList && NewQuesList.length > 0) {
      NewQuesList.forEach((question) => {
        let para =
          question && question.parameterDimensionName
            ? GetParameterLabel(question.parameterDimensionName)
            : "";
        let Score = CountRestrict(
          question && question.count ? question.count : "NA"
        )
          ? 0
          : question.score;
        let temp = {};
        temp["Dimension " + filter] = para;
        temp["Question"] = question.name;
        temp["Score(%)"] = Score;

        ScoreExcel.push(temp);
      });
    }



    return (
      <>
        <div className="flex flex-col justify-between gap-4 md:items-center md:flex-row">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}
            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />

          {/* <span className="hidden text-xs font-medium text-blue-500">Need Help?</span> */}
          <div className="flex flex-wrap gap-2 ">

            <NudgesBehaviourItem
              indexType={indexType}
              IndexNme={IndexName}
              name={IndexName + (IndexName !== "Organisation" ? " Behaviour Item Summary" : " Item Ranking")}
              dataItem={{
                show: true,
                questionList: NewQuesList,
                nudgeTextList: getNudgeTextBySurveyIdData
              }}
              optionType={"item"}
              CountRestrict={CountRestrict}
              GetParameterLabel={GetParameterLabel}
            />

            <div>
              {ScoreExcel ? (
                <CSVLink
                  data={ScoreExcel}
                  filename={IndexName !== "Organisation" ? (IndexName + "BehaviourItemSummary.csv") : "ItemRanking.csv"}
                >
                  <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                      <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                      <path d="M10 12l4 4m0 -4l-4 4" />
                    </svg>

                    <span>Download Excel</span>
                  </button>
                </CSVLink>
              ) : (
                "..."
              )}
            </div>

            <Commentary
              indexType={indexType}
              optionType={optionType}
              getCommentData={getCommentData}
            />
          </div>
        </div>

        {true ? (
          <>
            {/* <div className="items-center justify-start xl:flex lg:flex md:flex"> */}

            {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        <Filter 
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        /> */}

            {/* </div> */}

            {/* 
            {FilterValues && FilterValues.length > 0 || true?
                <div className="items-center justify-between h-10 px-4 py-3 bg-white border-b xl:flex lg:flex md:flex">
                  <>
                  {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
                    <p className="flex mx-2 my-4 text-sm"><p className="mr-1 text-blue-500">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
                  ) : <p></p>}


                    {FilterValues && FilterValues.length > 0 || true?
                      <>
                      {ScoreExcel ?
                      <CSVLink data={ScoreExcel} filename={"IndexScore.csv"}>
                      <p className="text-sm font-normal text-purple-700 cursor-pointer hover:text-purple-500">
                        {"Download Excel"}
                      </p>                
                      </CSVLink>
                      : '...'}
                      </>
                      :<p></p>}


                    
                  </>
                  </div>
                : null} */}

            <div onClick={() => this.closeFilter(false)} className="">
              {/* <div className="flex w-full" >


                <div className="w-full px-4 m-4 bg-white rounded shadow-lg">
                  <div className="py-4 text-lg font-semibold">{name}  Behaviour Item</div> */} 
                <div className="grid grid-cols-1 gap-4 md:grid-cols-1 ">
                  <div className="p-4 space-y-4 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                    <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">
                      {IndexName !== "Organisation" ? IndexName + " Behaviour Item Summary" : "Item Ranking"}
                    </h1>

                    {ErrorIndexObj && ErrorIndexObj["getQuestionError"] ? (
                      <>
                        <div className="my-24 text-2xl font-medium text-center text-gray-400">
                          {ErrorIndexObj["getQuestionError"]}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="w-full mb-4 overflow-hidden overflow-x-auto customscroll2 ">
                          <table className="w-full border whitespace-nowrap ">
                            <thead>
                              <tr className="bg-gray-100 border-b text-sm  text-[#3D405B]">
                                <th className="py-2 px-2 border-r font-bold  md:w-[20%] w-32">
                                  <div className="flex items-center justify-left">
                                    Dimension
                                  </div>
                                </th>
                                <th className="py-2 px-2 border-r font-bold md:w-[50%] w-60">
                                  <div className="flex items-center justify-left">
                                    Question
                                  </div>
                                </th>
                                <th className="py-2 px-4 border-r font-bold md:w-[30%] w-40">
                                  <div className="flex items-center justify-left">
                                    Favorability Score
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {NewQuesList && NewQuesList.length > 0
                                ? NewQuesList.map((question, index) => (
                                  <tr
                                    key={index}
                                    className="bg-white  mb-10 lg:mb-0 text-sm text-[#3D405B]"
                                  >
                                    <td className="px-2 border border-b   ">
                                      <div className="">
                                        {question &&
                                          question.parameterDimensionName
                                          ? GetParameterLabel(
                                            question.parameterDimensionName
                                          )
                                          : ""}
                                      </div>
                                    </td>
                                    <td className="tooltip-response-question1 px-2 border border-b   ">
                                      <div className="md:w-auto w-60 whitespace-normal">
                                        {question.name}
                                      </div>
                                      {/* <div className="tooltip-response-question2">
                                    {question.name}
                                  </div> */}
                                    </td>
                                    <td className=" border border-b ">
                                      <div className="md:w-auto w-40">
                                        <QuestionScore
                                          question={question}
                                          CountRestrict={CountRestrict}
                                          currFilterValue={
                                            this.state.currFilterValue
                                          }
                                          currFilterName={
                                            this.state.currFilterName
                                          }
                                          selectedFilterValue={
                                            selectedFilterValue
                                          }
                                          getQuestionFilterData={
                                            getQuestionFilterData
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))
                                : null}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}

                    <div className="text-sm text-[#3d405B]/70">
                      * We are not showing data where employee count is less
                      than 5
                    </div>
                  </div>
                </div>
           
            </div>
          </>
        ) : (
          <>
            {!loading ? (
              <div className="bg-white p-6 border rounded-lg hover:shadow-lg">
                <DataNotFound />
              </div>
            ) : null}
          </>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state ;
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(DimensionPriorities);
