import React, { Component } from "react";
import { connect } from "react-redux";
// import Filter from './Filter';
import Filter from "./Filter/Filter";
import Commentary from "../../../Index/Components/Commentary/Commentary";

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: "none",
      viewScore: true,
      showDropDown1: false,
      showDropDown2: false,
      showFilter: false,
      showValue: "",
      DimensionList: [
        {
          index: 1,
          name: "Happiness",
          cutOff: 59,
          range: 72,
          type: "",
        },
        {
          index: 2,
          name: "Engagement",
          cutOff: 78,
          range: 100,
          type: "%",
        },
        {
          index: 4,
          name: "Stress",
          cutOff: 28,
          range: 60,
          type: "%",
        },
        {
          index: 5,
          name: "Manager",
          cutOff: 59,
          range: 100,
          type: "%",
        },
        {
          index: 6,
          name: "Leader",
          cutOff: 50,
          range: 100,
          type: "%",
        },
        // {
        //   index:8,
        //   name:'OrgCore',
        //   cutOff:59
        // },
      ],

      selectedItem1: {
        index: 1,
        name: "Happiness",
        cutOff: 59,
        range: 72,
        type: "",
      },
      selectedItem2: {
        index: 2,
        name: "Engagement",
        cutOff: 78,
        range: 100,
        type: "%",
      },

      currentSelectValue: "",
      FilterValues: [],
      showQueDrop: false,
      storeQues1: "",
      storeQues2: "",
    };
  }
  componentDidMount() { }

  handleViewScore = (data) => {
    this.setState({ viewScore: data });
  };

  handleDropdown1 = (check) => {
    this.setState({ showDropDown1: !check, showDropDown2: false });
  };
  handleDropdown2 = (check) => {
    this.setState({ showDropDown2: !check, showDropDown1: false });
  };

  changeItem1 = (item) => {
    this.setState({ selectedItem1: item });
    this.setState({ showDropDown1: false, showDropDown2: false });
  };

  changeItem2 = (item) => {
    this.setState({ selectedItem2: item });
    this.setState({ showDropDown1: false, showDropDown2: false });
  };

  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleMultiFilterAdvanc4Quadrant } = this.props;
    this.setState({ FilterValues: finalFilter });
    // handleMultiFilterAdvanc4Quadrant(finalFilter,true, name);
  };

  openQuesDrop = (check, no) => {
    if (no === 1) {
      this.setState({ showQueDrop1: check, showQueDrop2: false });
    }
    if (no === 2) {
      this.setState({ showQueDrop2: check, showQueDrop1: false });
    }
  };

  handleSelectQues = (Ques, no) => {
    if (no === 1) {
      this.setState({ storeQues1: Ques, showQueDrop1: false });
    }
    if (no === 2) {
      this.setState({ storeQues2: Ques, showQueDrop2: false });
    }
  };

  handleSaveCrossTab = () => {
    let { handleCrossTabApi } = this.props;
    let { storeQues1, storeQues2, FilterValues } = this.state;

    let list = [];

    if (storeQues1 && storeQues2) {
      let NewFilter = [];
      if (FilterValues && FilterValues.length > 0) {
        FilterValues.forEach((filter) => {
          if (filter.value !== "All") {
            NewFilter.push(filter);
          }
        });
      }
      handleCrossTabApi(storeQues1, storeQues2, NewFilter);
    }
  };

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };

  render() {
    let {
      crossTabAnalysisData,
      indexType,
      optionType,
      loading,
      getCommentData,
      getIndexData,
      getDemographicIndexData,
      EmpFilterData,
      getIndexFilterData,
    } = this.props;

    let {
      showQueDrop1,
      showQueDrop2,
      showDropDown1,
      showDropDown2,
      DimensionList,
      selectedItem1,
      selectedItem2,
      currentSelectValue,
      FilterValues,
    } = this.state;

    console.log(
      "crossTabAnalysisData:-----------------------------------------------------> ",
      crossTabAnalysisData
    );

    let optionList1 = [];
    let optionList2x = [];
    if (crossTabAnalysisData && crossTabAnalysisData.length > 0) {
      crossTabAnalysisData.forEach((item) => {
        let getIndex1 = optionList1.findIndex((prev) => prev === item[0]);
        if (getIndex1 === -1) {
          optionList1.push(item[0]);
        }

        let getIndex2 = optionList2x.findIndex((prev) => prev === item[1]);
        if (getIndex2 === -1) {
          optionList2x.push(item[1]);
        }
      });
    }

    console.log(
      "optionList1:-----------------------------------------------------> ",
      optionList1
    );
    console.log(
      "optionList2x:-----------------------------------------------------> ",
      optionList2x
    );

    function ordering(name) {
      let order = 111;

      if (name === "0") {
        order = 0;
      } else if (name === "1") {
        order = 1;
      } else if (name === "2") {
        order = 2;
      } else if (name === "3") {
        order = 3;
      } else if (name === "4") {
        order = 4;
      } else if (name === "5") {
        order = 5;
      } else if (name === "6") {
        order = 6;
      } else if (name === "7") {
        order = 7;
      } else if (name === "8") {
        order = 8;
      } else if (name === "9") {
        order = 9;
      } else if (name === "10") {
        order = 10;
      }

      return order;
    }

    let tempDat = [];
    if (optionList2x && optionList2x.length > 0) {
      optionList2x.forEach((item) => {
        tempDat.push({
          name: item,
          number: ordering(item),
        });
      });
    }

    let optionList2 = [];
    tempDat.sort((a, b) => a.number - b.number);

    if (tempDat && tempDat.length > 0) {
      tempDat.forEach((item1) => {
        optionList2.push(item1.name);
      });
    }

    let EmpFilterData2 = [];
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== "ManagerPID") {
          EmpFilterData2.push(emp);
        }
      });
    }

    let isDisable = true;
    // let DistributionIndexData = [];
    //  let DemographicDataDistribution = [];
    console.log("EmpFilterData: ", EmpFilterData);

    if (FilterValues && FilterValues.length > 0) {
      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === "All") {
            isDisable = false;
          }
        });
      }
    }

    let { questionsList, crossTabData } = this.props;
    let { showQueDrop, storeQues1, storeQues2 } = this.state;

    let storeQuesIDs = [];

    if (storeQues1) {
      storeQuesIDs.push(storeQues1);
    }
    if (storeQues2) {
      storeQuesIDs.push(storeQues2);
    }

    console.log("storeQues1", storeQues1 && storeQues1.id ? storeQues1.id : "");
    console.log("storeQues2", storeQues2 && storeQues2.id ? storeQues2.id : "");

    // let OptionList = [
    //   "Strongly Agree",
    //   "Agree",
    //   "Slightly Agree",
    //   "Slightly Disagree",
    //   "Disagree",
    //   "Strongly Disagree"
    // ];

    let QuestionObj = {};
    if (storeQuesIDs && storeQuesIDs.length > 0) {
      storeQuesIDs.forEach((QUE) => {
        QuestionObj[QUE.id] = {};
      });

      storeQuesIDs.forEach((QUE) => {
        if (crossTabData && crossTabData.length > 0) {
          optionList1.forEach((opt) => {
            crossTabData.forEach((cross) => {
              if (cross._id.questionId === QUE.id) {
                let temp = {
                  name: cross._id.answerText,
                  count: cross.count,
                };
                if (opt === cross._id.answerText) {
                  QuestionObj[QUE.id][opt] = cross.count;
                }
              }
            });
          });
        }
      });
    }

    console.log("Cross crossTabAnalysisData: ", crossTabAnalysisData);

    let ScoreListNew = [];
    // ScoreListNew = [storeQues1,storeQues2];

    if (crossTabAnalysisData && crossTabAnalysisData.length > 0) {
      crossTabAnalysisData.forEach((item) => {
        let AnsQue1 = item[0];
        let AnsQue2 = item[1];
        let AnsScore = item[2];
        let AnsPerc = item[3];

        let getIndex = ScoreListNew.findIndex((prev) => prev.q1 === AnsQue1);

        if (getIndex === -1) {
          let temp = {
            q1: AnsQue1,
          };
          temp[AnsQue2] = {
            count: AnsScore,
            per: AnsPerc,
          };
          ScoreListNew.push(temp);
        } else {
          if (
            !(
              ScoreListNew &&
              ScoreListNew[getIndex] &&
              ScoreListNew[getIndex][AnsQue2]
            )
          ) {
            ScoreListNew[getIndex][AnsQue2] = {
              count: AnsScore,
              per: AnsPerc,
            };
          }
        }
      });
    }

    console.log("Cross questionsList: ", questionsList);

    let ScoreList = [];
    if (optionList1 && optionList1.length > 0) {
      optionList1.forEach((opt) => {
        if (ScoreListNew && ScoreListNew.length > 0) {
          let flag = 0;
          ScoreListNew.forEach((item) => {
            if (item.q1 === opt) {
              ScoreList.push({
                ...item,
                number: ordering(opt),
              });
              flag++;
            }
          });
          if (!flag) {
            let temp = {
              q1: opt,
              number: ordering(opt),
            };
            optionList2.forEach((opt1) => {
              temp[opt1] = {
                count: 0,
                per: 0,
              };
            });
            ScoreList.push(temp);
          }
        }
      });
    }
    if (ScoreList && ScoreList.length > 0) {
      ScoreList.sort((a, b) => a.number - b.number);
    }
    console.log("Cross ScoreList:--------------------> ", ScoreList);

    // 0:
    // Agree:
    // count: 258
    // per: 22.872340425531913
    // __proto__: Object
    // Disagree: {count: 0,

    function valueFn(storeId, no, option) {
      let count = 0;
      if (storeId && storeId[no] && storeId[no].id) {
        let Id = storeId[no].id;
        if (QuestionObj && QuestionObj[Id] && QuestionObj[Id][option]) {
          count = QuestionObj[Id][option];
        }
      }
      return "-";
    }

    function spliceDecimal(no) {
      if (no) {
        let str = no.toString();
        let first = str.split(".")[0];
        let second = str.split(".")[1];
        let second2 = second ? second.substring(0, 2) : "";
        let final = first + "." + second2;
        if (second2) {
          return parseFloat(final).toFixed(2);
        } else {
          return no;
        }
      } else {
        return 0;
      }
    }

    function getSum(item, arr) {
      return arr
        .map((option) =>
          item && item[option] && item[option].count ? item[option].count : 0
        )
        .reduce((partialSum, a) => partialSum + a, 0);
    }

    function getSumBlock(arrA, arrB, listS) {
      let scoreValue = 0;
      if (listS && listS.length > 0) {
        listS.forEach((item) => {
          if (arrA.includes(Number(item.q1))) {
            scoreValue += getSum(item, arrB);
          }
        });
      }
      return scoreValue;
    }

    function npsLadderScore(name) {
      if (name === "Detractors") {
        return spliceDecimal(
          (getSumBlock(
            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            [1, 2, 3, 4, 5, 6],
            ScoreList
          ) *
            100) /
          total
        );
      } else if (name === "Passive") {
        return spliceDecimal(
          (getSumBlock([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], [7, 8], ScoreList) *
            100) /
          total
        );
      } else if (name === "Promoters") {
        return spliceDecimal(
          (getSumBlock([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], [9, 10], ScoreList) *
            100) /
          total
        );
      } else if (name === "UnHappy") {
        return spliceDecimal(
          (getSumBlock(
            [0, 1, 2, 3, 4, 5],
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            ScoreList
          ) *
            100) /
          total
        );
      } else if (name === "Moderate Happy") {
        return spliceDecimal(
          (getSumBlock([6, 7], [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], ScoreList) *
            100) /
          total
        );
      } else if (name === "Happy") {
        return spliceDecimal(
          (getSumBlock([8, 9, 10], [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], ScoreList) *
            100) /
          total
        );
      }
    }

    function getRegion(a, b, check, total) {
      if (0 <= a && a <= 5 && 1 <= b && b <= 6) {
        //UnHappy - Detractors

        return {
          text: "UnHappy - Detractors",

          npsType: "Detractors",
          ladderType: "UnHappy",

          npsScore: npsLadderScore("Detractors"),
          ladderScore: npsLadderScore("UnHappy"),

          rowSpan: a === 0 && b === 1 ? 6 : null,
          colSpan: a === 0 && b === 1 ? 6 : null,
          color: "#ff0000",
          value: check
            ? spliceDecimal(
              (getSumBlock(
                [0, 1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5, 6],
                ScoreList
              ) *
                100) /
              total
            )
            : 0,
        };
      } else if (6 <= a && a <= 7 && 1 <= b && b <= 6) {
        //Moderate Happy - Detractors
        return {
          text: "Moderate Happy - Detractors",

          npsType: "Detractors",
          ladderType: "Moderate Happy",

          npsScore: npsLadderScore("Detractors"),
          ladderScore: npsLadderScore("Moderate Happy"),

          rowSpan: a === 6 && b === 1 ? 2 : null,
          colSpan: a === 6 && b === 1 ? 6 : null,
          color: "#ffffff",
          value: check
            ? spliceDecimal(
              (getSumBlock([6, 7], [1, 2, 3, 4, 5, 6], ScoreList) * 100) /
              total
            )
            : 0,
        };
      } else if (8 <= a && a <= 10 && 1 <= b && b <= 6) {
        //Happy - Detractors
        return {
          text: "Happy - Detractors",

          npsType: "Detractors",
          ladderType: "Happy",

          npsScore: npsLadderScore("Detractors"),
          ladderScore: npsLadderScore("Happy"),

          rowSpan: a === 8 && b === 1 ? 3 : null,
          colSpan: a === 8 && b === 1 ? 6 : null,
          color: "#00ccff",
          value: check
            ? spliceDecimal(
              (getSumBlock([8, 9, 10], [1, 2, 3, 4, 5, 6], ScoreList) * 100) /
              total
            )
            : 0,
        };
      } else if (0 <= a && a <= 5 && 7 <= b && b <= 8) {
        //UnHappy - Passive
        return {
          text: "UnHappy - Passive",

          npsType: "Passive",
          ladderType: "UnHappy",

          npsScore: npsLadderScore("Passive"),
          ladderScore: npsLadderScore("UnHappy"),

          rowSpan: a === 0 && b === 7 ? 6 : null,
          colSpan: a === 0 && b === 7 ? 2 : null,
          color: "#d9d9d9",
          value: check
            ? spliceDecimal(
              (getSumBlock([0, 1, 2, 3, 4, 5], [7, 8], ScoreList) * 100) /
              total
            )
            : 0,
        };
      } else if (6 <= a && a <= 7 && 7 <= b && b <= 8) {
        //Moderate Happy - Passive
        return {
          text: "Moderate Happy - Passive",

          npsType: "Passive",
          ladderType: "Moderate Happy",

          npsScore: npsLadderScore("Passive"),
          ladderScore: npsLadderScore("Moderate Happy"),

          rowSpan: a === 6 && b === 7 ? 2 : null,
          colSpan: a === 6 && b === 7 ? 2 : null,
          color: "#66c2ff",
          value: check
            ? spliceDecimal(
              (getSumBlock([6, 7], [7, 8], ScoreList) * 100) / total
            )
            : 0,
        };
      } else if (8 <= a && a <= 10 && 7 <= b && b <= 8) {
        //Happy - Passive
        return {
          text: "Happy - Passive",

          npsType: "Passive",
          ladderType: "Happy",

          npsScore: npsLadderScore("Passive"),
          ladderScore: npsLadderScore("Happy"),

          rowSpan: a === 8 && b === 7 ? 3 : null,
          colSpan: a === 8 && b === 7 ? 2 : null,
          color: "#996633",
          value: check
            ? spliceDecimal(
              (getSumBlock([8, 9, 10], [7, 8], ScoreList) * 100) / total
            )
            : 0,
        };
      } else if (0 <= a && a <= 5 && 9 <= b && b <= 10) {
        //UnHappy - Promoters
        return {
          text: "UnHappy - Promoters",

          npsType: "Promoters",
          ladderType: "UnHappy",

          npsScore: npsLadderScore("Promoters"),
          ladderScore: npsLadderScore("UnHappy"),

          rowSpan: a === 0 && b === 9 ? 6 : null,
          colSpan: a === 0 && b === 9 ? 2 : null,
          color: "#ffcc00",
          value: check
            ? spliceDecimal(
              (getSumBlock([0, 1, 2, 3, 4, 5], [9, 10], ScoreList) * 100) /
              total
            )
            : 0,
        };
      } else if (6 <= a && a <= 7 && 9 <= b && b <= 10) {
        //Moderate Happy - Promoters
        return {
          text: "Moderate Happy - Promoters",

          npsType: "Promoters",
          ladderType: "Moderate Happy",

          npsScore: npsLadderScore("Promoters"),
          ladderScore: npsLadderScore("Moderate Happy"),

          rowSpan: a === 6 && b === 9 ? 2 : null,
          colSpan: a === 6 && b === 9 ? 2 : null,
          color: "#00ffbf",
          value: check
            ? spliceDecimal(
              (getSumBlock([6, 7], [9, 10], ScoreList) * 100) / total
            )
            : 0,
        };
      } else if (8 <= a && a <= 10 && 9 <= b && b <= 10) {
        //Happy - Promoters
        return {
          text: "Happy - Promoters",

          npsType: "Promoters",
          ladderType: "Happy",

          npsScore: npsLadderScore("Promoters"),
          ladderScore: npsLadderScore("Happy"),

          rowSpan: a === 8 && b === 9 ? 3 : null,
          colSpan: a === 8 && b === 9 ? 2 : null,
          color: "#33cc33",
          value: check
            ? spliceDecimal(
              (getSumBlock([8, 9, 10], [9, 10], ScoreList) * 100) / total
            )
            : 0,
        };
      } else {
        //None
        return {
          text: "",

          npsType: "",
          ladderType: "",

          npsScore: 0,
          ladderScore: 0,

          rowSpan: null,
          colSpan: null,
          color: "#ffffff",
          value: 0,
        };
      }
    }

    let total = 0;
    if (ScoreList && ScoreList.length > 0) {
      ScoreList.forEach((item) => {
        total += getSum(item, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
      });
    }

    console.log("total---->", total);


    let qListNPS = [];
    let qListLadder = [];
    if (questionsList && questionsList.length > 0) {
      qListNPS = questionsList.filter(prev => prev.templateId === 6);
      qListLadder = questionsList.filter(prev => prev.templateId === 16);
    }


    
    function getColor(dmgScore) {
      let score = parseFloat(dmgScore);
      if(score>=0 && score<1){
        return { Color: "linear-gradient(90deg, #cfddfc, #e7eefd)", diffTextColor: "#000" };
      }else if(score>=1 && score<5){
        return { Color: "linear-gradient(90deg, #9fbaf9, #cfddfc)", diffTextColor: "#000" };
      }else if(score>=5 && score<10){
        return { Color: "linear-gradient(90deg, #6f98f6, #9fbaf9)", diffTextColor: "#000" };
      }else if(score>=10 && score<25){
        return { Color: "linear-gradient(90deg, #3f75f3, #6f98f6)", diffTextColor: "#ffffff" };
      }else if(score>=25 && score<=100){
        return { Color: "linear-gradient(90deg, #0f53f0, #3f75f3)", diffTextColor: "#ffffff" };
      }
    }




    return (
      <>
        <div className="flex flex-wrap items-center justify-between gap-4">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}
            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexFilterData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
          />
          <Commentary
            indexType={indexType}
            optionType={optionType}
            getCommentData={getCommentData}
          />
        </div>

        {true ? (
          <>
            {/* {FilterValues && FilterValues.length > 0 ?
          <div className="items-center h-10 px-4 py-3 bg-white border-b xl:flex lg:flex md:flex justify-left">
            {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
              <p className="flex mx-2 my-4 text-sm"><p className="mr-1 text-blue-500">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
            ) : null}</div>
          : null} */}

            <div onClick={() => this.closeFilter(false)} className="">
              <div className="w-full space-y-4 ">
                <div className="p-6 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                  <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap ">
                    Question Selection
                  </h1>

                  <div className="flex flex-col gap-4 md:flex-row">
                    <div
                      onClick={() => this.openQuesDrop(!showQueDrop1, 1)}
                      className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2 relative w-full justify-between"
                    >
                      <h1 className="">
                        {storeQues1 && storeQues1.name
                          ? storeQues1.name
                          : "Select Question 1"}
                      </h1>

                      <span
                        className={
                          showQueDrop
                            ? "material-symbols-outlined rotate-180 "
                            : "material-symbols-outlined rotate-0 "
                        }
                      >
                        arrow_drop_down
                      </span>

                      {showQueDrop1 ? (
                        qListNPS && qListNPS.length > 0 ?
                          <div className="absolute z-10 py-2 overflow-y-auto font-normal bg-white border rounded-lg shadow-lg -left-2 w-fit top-12 h-60 scollar-xs-blue ">
                            {qListNPS && qListNPS.length > 0
                              ? qListNPS.map((question, index) =>
                                storeQues2.id !== question.id ? (
                                  <span
                                    key={index}
                                    onClick={() =>
                                      this.handleSelectQues(question, 1)
                                    }
                                    className={
                                      storeQues1.id === question.id
                                        ? "block px-4 p-2 text-sm  text-[#3D405B]  cursor-pointer hover:bg-gray-100"
                                        : "block px-4 p-2 text-sm  text-[#3D405B]  cursor-pointer hover:bg-gray-100"
                                    }
                                  >
                                    {question.name}
                                  </span>
                                ) : null
                              )
                              : null}
                          </div>
                          : null
                      ) : null}
                    </div>

                    <div
                      onClick={() => this.openQuesDrop(!showQueDrop2, 2)}
                      className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2 relative w-full justify-between"
                    >
                      <h1 className="">
                        {storeQues2 && storeQues2.name
                          ? storeQues2.name
                          : "Select Question 2"}
                      </h1>
                      <span
                        className={
                          showQueDrop
                            ? "material-symbols-outlined rotate-180 "
                            : "material-symbols-outlined rotate-0 "
                        }
                      >
                        arrow_drop_down
                      </span>

                      {showQueDrop2 ? (
                        qListLadder && qListLadder.length > 0 ?
                          <div className="absolute z-10 py-2 overflow-y-auto font-normal bg-white border rounded-lg shadow-lg -left-2 w-fit top-12 h-60 scollar-xs-blue ">
                            {qListLadder && qListLadder.length > 0
                              ? qListLadder.map((question, index) =>
                                storeQues1.id !== question.id ? (
                                  <span
                                    key={index}
                                    onClick={() =>
                                      this.handleSelectQues(question, 2)
                                    }
                                    className={
                                      storeQues2.id === question.id
                                        ? "block px-4 p-2 text-sm  text-[#3D405B]  cursor-pointer hover:bg-gray-100"
                                        : "block px-4 p-2 text-sm  text-[#3D405B]  cursor-pointer hover:bg-gray-100"
                                    }
                                  >
                                    {question.name}
                                  </span>
                                ) : null
                              )
                              : null}
                          </div>
                          : null
                      ) : null}
                    </div>

                    <button
                      onClick={() => this.handleSaveCrossTab()}
                      className=" cursor-pointer text-sm py-2 px-4 font-medium bg-[#2196f3] text-white rounded-md border border-[#2196f3]"
                    >
                      Submit
                    </button>
                  </div>
                </div>

                <div className="p-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                  <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap pb-2 ">
                    NPS VS Happiness Ladder
                  </h1>

                  <div className="py-4 ">
                    {ScoreList && ScoreList.length > 0 ? (
                      <div className="table w-full overflow-x-auto ">
                        <table className="w-full border ">
                          <thead className>
                            <tr className="bg-[#F9FAFB] border-b text-sm  text-[#3D405B]">
                              <th className="p-3 border-r font-bold  w-[20%]  ">
                                <div className="flex items-center justify-center"></div>
                              </th>
                              <th className="p-3 border-r font-bold  w-[10%] ">
                                <div className="flex items-center justify-center"></div>
                              </th>
                              <th
                                colSpan={
                                  optionList2 && optionList2.length > 0
                                    ? optionList2.length
                                    : 0
                                }
                                className="p-3 border-r font-semibold text-left text-base  w-[10%]"
                              >
                                {storeQues2 && storeQues2.name
                                  ? storeQues2.name
                                  : "Question 2"}

                              </th>
                            </tr>

                            <tr className=" border-b text-sm  text-[#3D405B]">
                              <th className="p-3 font-bold border-r">
                                <div className="flex items-center justify-center"></div>
                              </th>

                              <th className="p-3 font-bold border-r">
                                <div className="flex items-center justify-center"></div>
                              </th>

                              {optionList2 && optionList2.length > 0
                                ? optionList2.map((option) => (
                                  <th className="p-3 border-r font-bold w-[10%] text-left">

                                    {option}

                                  </th>
                                ))
                                : null}
                            </tr>
                          </thead>
                          <tbody>
                            {/* one row table */}
                            {ScoreList && ScoreList.length > 0
                              ? ScoreList.map((item, index) => (
                                <tr className=" odd:bg-[#F9FAFB] border-b text-sm  text-[#3D405B]">
                                  {index === 0 ? (
                                    <td
                                      className="p-3 text-base font-semibold text-left border-r"
                                      rowSpan={
                                        ScoreList && ScoreList.length > 0
                                          ? ScoreList.length
                                          : 0
                                      }
                                    >
                                      {storeQues1 && storeQues1.name
                                        ? storeQues1.name
                                        : "Question 1"}
                                    </td>
                                  ) : null}

                                  <td className="text-left border-r">
                                    {" "}
                                    <p className="p-3 font-semibold ">
                                      {item.q1}
                                    </p>
                                  </td>

                                  {optionList2 && optionList2.length > 0
                                    ? optionList2.map((option) => (
                                      <>
                                        <td className="p-3 border-r"
                                        style={{
                                          minWidth:"60px",
                                          minHeight:"50px",
                                          color: getColor(
                                            item && item[option] && item[option].per? item[option].per: "0"
                                          ).diffTextColor,
                                          background: getColor(
                                            item && item[option] && item[option].per? item[option].per: "0"
                                          ).Color,
                                        }}>
                                          <span className="font-bold text-lg">
                                            {item &&
                                              item[option] &&
                                              item[option].count
                                              ? item[option].count
                                              : 0}
                                          </span>
                                          {" "}
                                          <br/>
                                          <span className="text-text-900 text-xs mt-1">
                                            ({item &&
                                              item[option] &&
                                              item[option].per
                                              ? item[option].per.toFixed(
                                                2
                                              ) + "%"
                                              : "0%"})
                                          </span>
                                        </td>
                                      </>
                                    ))
                                    : null}
                                </tr>
                              ))
                              : null}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <>
                        <div className="text-xl text-center text-gray-500">
                          Please select both questions
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {!loading ? (
              <div className="pt-40 text-3xl text-center text-gray-400">
                We have not calculated this section!
              </div>
            ) : null}
          </>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(SummaryData);
