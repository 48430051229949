import React, { Component } from "react";
import { connect } from "react-redux";
import Header from './Header';
import ReactEcharts from 'echarts-for-react';

function ResponsePage(props) {

  let { list, dataList, userName, createdAt } = props;

  console.log("dataList----/////////////////////--->", dataList)


  function getRandomColor() {
    let colorList = ["#42A5F5", "#7E57C2", "#FFC107", "#66BB6A", "#5C6BC0"]
    var color = colorList[Math.floor(Math.random() * colorList.length)];
    return color
  }



  function getIndexColor(index) {
    let colorList = ["#42A5F5", "#7E57C2", "#FFC107", "#66BB6A", "#5C6BC0"]
    index = index % 5;
    var color = colorList[index];
    return color
  }


  const getChartPie = (inner, responsesList, level) => {

    let series_x_data = responsesList && responsesList.length > 0 && responsesList.map((res, indexY) => ({
      value: getIceScores(inner, res),
      name: res ? res : "-",
      itemStyle: {
        color: getIndexColor(indexY),
      },
      label: {
        show: false,
        formatter: '10',
        itemStyle: {
          color: '#fff',
        },
      },
    }));


    const optionPie = {
      title: {
        show: false,
      },
      tooltip: {
        trigger: 'item',
      },
      legend: {
        show: false,
        orient: 'horizontal',
        bottom: 'center',
      },
      series: [
        {
          name: level,
          type: 'pie',
          radius: '100%',
          data: series_x_data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
      grid: {
        left: '6px',
        right: '10px',
        bottom: '0px',
        top: '4px',
        containLabel: true,
      },

    };
    return optionPie
  }

  console.log("list--->responses-->", list)

  const getscorePerc = (res, all) => {
    let scoreX = 0;
    if (all && all.length > 0) {
      let total = 0;
      let sum = res && res.uniqueEmpCount ? res.uniqueEmpCount : 0;
      all.forEach(ele => {
        total += ele.uniqueEmpCount;
      });
      scoreX = total > 0 ? ((parseFloat(sum) * 100) / total).toFixed(0) : 0;
    }
    return scoreX
  }

  const getTotalCount = (ele, dmgValue) => {
    let total = 0;
    if (ele && ele.demographics && ele.demographics.length > 0) {
      ele.demographics.forEach((inner) => {
        if (inner.level === dmgValue) {
          if (inner && inner.responses && inner.responses.length > 0) {
            inner.responses.forEach((res) => {
              total += (res.uniqueEmpCount);
            })

          }
        }

      })
    }
    return total
  }


  const listaddedcolor = (listx) => {
    return listx && listx.length > 0 ? listx.map((ele, index) => ({ name: ele, color: getIndexColor(index) })) : []
  }




  let sizeChunk = 2;
  function chunkArray(array, chunkSize) {
    let result = [];
    if (array && array.length > 0) {
      for (let i = 0; i < array.length; i += chunkSize) {
        let chunk = array.slice(i, i + chunkSize);
        result.push(chunk);
      }
    }

    return result;
  }

  const sortingList = (list) => {
    let sortedList = []
    if (list && list.length > 0) {
      sortedList = list.sort((a, b) => {
        return ('' + a.xAxis).localeCompare(('' + b.xAxis));
      });
    }
    return sortedList
  }


  let finalList = chunkArray(sortingList(dataList), sizeChunk)


  const getIceScores = (inner, dmgValue) => {
    let score = 0;
    if (inner && inner["ICE"] && inner["ICE"].length > 0) {
      inner["ICE"].forEach((ele) => {
        if (ele.xAxis === dmgValue) {
          score = ele && ele.score && ele.score.toFixed(0) ? ele.score.toFixed(0) : "0";
        }
      })
    }
    return score
  }

  const getNPSScores = (inner, dmgValue) => {

    let totalCount = 0;
    let Promoters = 0;
    let Passsives = 0;
    let Distractors = 0;

    if (inner && inner["NPS"] && inner["NPS"].length > 0) {
      inner["NPS"].forEach((ele) => {
        if (ele.xAxis === dmgValue) {

          if (ele && ele.answers && ele.answers.length > 0) {
            ele.answers.forEach(at => {


              let key = parseInt(at.answerText);
              let score = parseInt(at.count);

              totalCount += score;

              if (parseInt(key) >= 9 && parseInt(key) <= 10) {
                Promoters += score;
              }
              if (parseInt(key) >= 7 && parseInt(key) <= 8) {
                Passsives += score;
              }
              if (parseInt(key) >= 0 && parseInt(key) <= 6) {
                Distractors += score;
              }


            });
          }


        }
      })
    }

    let PromotersPer = (Promoters * 100 / totalCount);
    let PasssivesPer = (Passsives * 100 / totalCount);
    let DistractorsPer = (Distractors * 100 / totalCount);

    let npsScore = ((PromotersPer) - (DistractorsPer)).toFixed(2);

    let PromotersColorRange = PromotersPer / 100;
    let PasssivesColorRange = (PasssivesPer / 100) + PromotersColorRange;
    let DistractorsColorRange = (DistractorsPer / 100) + PasssivesColorRange;


    let NPSObj = {
      "sign": parseFloat(npsScore) < 0 ? "-" : "+",
      "npsScore": parseFloat(npsScore).toFixed(0),
      "npsScoreBar": (npsScore).toString(),
      "PromotersPer": PromotersPer.toFixed(0),
      "PasssivesPer": PasssivesPer.toFixed(0),
      "DistractorsPer": DistractorsPer.toFixed(0)
    }

    return NPSObj["npsScore"]
  }


  const getResponseWiseList = (inner) => {
    let responseList = [];
    ["ICE", "NPS"].forEach((field) => {
      if (inner && inner[field] && inner[field].length > 0) {
        inner[field].forEach((ele) => {
          let getIndex = responseList.findIndex(prev => prev === ele.xAxis);
          if (getIndex === -1) {
            responseList.push(ele.xAxis);
          }
        })
      }
    })
    return responseList
  }

  const sortingName = (level, response) => {
    let sortedList = []
    if (level === "Grade") {
      if (response && response.length > 0) {
        sortedList = response.sort((a, b) => {
          return ('' + a).localeCompare(('' + b));
        });
      }
    } else if (level === "Zone") {
      if (response && response.length > 0) {
        sortedList = response.sort((a, b) => {
          return ('' + a).localeCompare(('' + b));
        });
      }
    } else if (level === "Tenure") {

      let newArr = [
        "Less than 6 months",
        "Between 6 months to 3 yrs",
        "Between 3 to 5 yrs",
        "Between 5 to 10 yrs",
        "More than 10 Yrs"
      ];

      if (response && response.length > 0) {
        sortedList = response.sort((a, b) => {
          return newArr.indexOf('' + a) - newArr.indexOf('' + b);
        });

      }
    }

    return sortedList
  }


  return (
    <>
      {finalList && finalList.length > 0 &&
        finalList.map((innerList, index) =>
          <section className='w-full p-6 px-6 flex flex-col justify-between page-break'>
            <Header userName={userName} createdAt={createdAt} />
            <div className="flex flex-col space-y-6 w-full">
              <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">Insight Summary (in %)</h1>

              <div className={innerList && innerList.length > 1 ? "grid grid-cols-2 divide-x " : "grid grid-cols-1 divide-x  "}>

                {innerList && innerList.length > 0 && innerList.map((ele) =>
                  <>
                    <div className="space-y-2 first:pr-4 last:pl-4  ">
                      <div className="text-sm font-bold text-[#212121] bg-[#F1F5F9] p-2 px-2 flex justify-between items-center">
                        <p>{ele && ele.xAxis ? ele.xAxis : ""}</p>
                        <div className="flex items-center justify-between space-x-2">
                        </div>
                      </div>

                      {ele && ele.demographics && ele.demographics.length > 0 && ele.demographics.map((inner) =>
                        <>

                          {inner && inner.level && inner.level === "Tenure" &&
                            <div className=" ">
                              {/* <p className="text-sm font-bold"></p> */}
                              <div className="flex justify-between items-center">
                                <p className="text-sm font-bold">{inner.level}</p>
                                {/* <div className="flex space-x-2 pr-1">
                                  <p className="text-sm font-bold">ICE</p>
                                  <p className="text-sm font-bold">eNPS</p>
                                </div> */}
                              </div>
                              <div className="py-2 border-b w-full">
                                <table className="w-full">
                                  <tbody>
                                    <tr className="">
                                      <td className="text-[#212121] w-44 flex-shrink-0 font-medium text-right text-[10px] p-2 py-0.5"></td>
                                      <td className={innerList && innerList.length > 1 ? "w-[40%] p-2 py-0.5" : "w-[65%] p-2 py-0.5"}>
                                        <div className="bg-[#fff] w-full h-4 overflow-hidden flex items-center space-x-2">
                                          <div className="w-[40%] h-4" />
                                        </div>
                                      </td>
                                      <td className="font-bold w-8 text-left text-xs px-1 py-0.5">ICE</td>
                                      <td className="font-bold w-8 text-left text-xs px-1 py-0.5">eNPS</td>
                                    </tr>

                                    {getResponseWiseList(inner) && getResponseWiseList(inner).length > 0 && sortingName(inner.level, getResponseWiseList(inner)).map((dmgValue) =>
                                      <tr className=" ">
                                        <td className="text-[#212121] w-44 flex-shrink-0 font-medium text-right text-[10px] p-2 py-0.5 whitespace-nowrap">{dmgValue && dmgValue ? dmgValue : "-"}</td>
                                        <td className={innerList && innerList.length > 1 ? "w-[40%] p-2 py-0.5" : "w-[65%] p-2 py-0.5"}>
                                          <div className="bg-[#E8F4FD] w-full h-4 overflow-hidden flex items-center space-x-2">
                                            <div className="w-[40%] h-4" style={{
                                              width: getIceScores(inner, dmgValue) + "%",
                                              background: getRandomColor()
                                            }} />
                                          </div>
                                        </td>
                                        <td className="font-medium w-8 text-left text-xs p-2 py-0.5">{getIceScores(inner, dmgValue)}</td>
                                        <td className="font-medium w-8 text-left text-xs p-2 py-0.5">{getNPSScores(inner, dmgValue)}</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>}


                          {inner && inner.level && inner.level === "Gender" &&
                            <div className="pr-2">
                              <div className="flex justify-between items-center">
                                <p className="text-sm font-bold" >{inner.level}</p>
                                <div className="flex space-x-5">
                                  <p className="text-xs font-bold" >ICE</p>
                                  <p className="text-xs font-bold" >eNPS</p>
                                </div>
                              </div>
                              <div className="grid grid-cols-1 gap-1 py-1 border-b">
                                {getResponseWiseList(inner) && getResponseWiseList(inner).length > 0 && getResponseWiseList(inner).map((dmgValue) =>
                                  <>
                                    {dmgValue && dmgValue === "M" && <>
                                      <div className=" flex items-center space-x-2 ">
                                        <div className="flex-shrink-0 text-[#42A5F5] flex items-center">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#42A5F5" class="icon icon-tabler icons-tabler-filled icon-tabler-man"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M15 8c1.628 0 3.2 .787 4.707 2.293a1 1 0 0 1 -1.414 1.414c-.848 -.848 -1.662 -1.369 -2.444 -1.587l-.849 5.944v4.936a1 1 0 0 1 -2 0v-4h-2v4a1 1 0 0 1 -2 0v-4.929l-.85 -5.951c-.781 .218 -1.595 .739 -2.443 1.587a1 1 0 1 1 -1.414 -1.414c1.506 -1.506 3.08 -2.293 4.707 -2.293z" /><path d="M12 1a3 3 0 1 1 -3 3l.005 -.176a3 3 0 0 1 2.995 -2.824" /></svg>
                                        </div>
                                        <div className="w-full h-4 bg-[#E8F4FD] flex items-start">
                                          <div className="bg-[#42A5F5] w-[20%] h-4" style={{ width: getIceScores(inner, dmgValue) + "%" }}></div>
                                        </div>
                                        <div className="flex flex-shrink-0 items-center justify-between px-2 text-xs space-x-4 font-medium w-20">
                                          <span className="pr-2">{getIceScores(inner, dmgValue)}</span>
                                          <span>{getNPSScores(inner, dmgValue)}</span>
                                        </div>
                                      </div>
                                    </>}

                                    {dmgValue && dmgValue === "F" && <>
                                      <div className=" flex items-center space-x-2 ">
                                        <div className="flex-shrink-0  text-[#7E57C2] flex items-center">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#7E57C2" class="icon icon-tabler icons-tabler-filled icon-tabler-woman"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 8c1.91 0 3.79 .752 5.625 2.219a1 1 0 1 1 -1.25 1.562c-1.019 -.815 -2.016 -1.345 -2.997 -1.6l1.584 5.544a1 1 0 0 1 -.962 1.275h-1v4a1 1 0 0 1 -2 0v-4h-2v4a1 1 0 0 1 -2 0v-4h-1a1 1 0 0 1 -.962 -1.275l1.584 -5.545c-.98 .256 -1.978 .786 -2.997 1.601a1 1 0 1 1 -1.25 -1.562c1.733 -1.386 3.506 -2.133 5.307 -2.212l.335 -.007z" /><path d="M12 1a3 3 0 1 1 -3 3l.005 -.176a3 3 0 0 1 2.995 -2.824" /></svg>
                                        </div>
                                        <div className="w-full h-4 bg-[#E8F4FD] flex items-start">
                                          <div className="bg-[#7E57C2] w-[20%] h-4" style={{ width: getIceScores(inner, dmgValue) + "%" }}></div>
                                        </div>
                                        <div className="flex flex-shrink-0 items-center justify-between px-2 text-xs space-x-4 font-medium w-20">
                                          <span className="pr-2">{getIceScores(inner, dmgValue)}</span>
                                          <span>{getNPSScores(inner, dmgValue)}</span>
                                        </div>
                                      </div>
                                    </>}
                                  </>
                                )}
                              </div>
                            </div>}

                          {inner && inner.level && inner.level === "Zone" &&
                            <div className="">
                              <div className="flex justify-between items-center">
                                <p className="text-sm font-bold" >{inner.level}</p>
                                {/* <div className="flex space-x-6 pr-2">
                                  <p className="text-sm font-bold" >ICE</p>
                                  <p className="text-sm font-bold" >eNPS</p>
                                </div> */}
                              </div>
                              <div className="flex items-center space-x-6 justify-center py-2 border-b">
                                {getResponseWiseList(inner) && getResponseWiseList(inner).length > 0 ?
                                  <ReactEcharts option={getChartPie(inner, getResponseWiseList(inner), inner.level)} style={{ height: '95px', width: '170px' }} className="" />
                                  : null
                                }
                                <table className="w-full">
                                  <tbody>

                                    <tr className="bg-[#F1F5F9]">
                                      <td className="text-[#212121] w-40 flex-shrink-0 font-bold text-left text-xs pl-6 p-1 border-b-2 border-r-2 border-white">type</td>
                                      <td className="font-bold w-10 text-left text-xs p-1 border-b-2 border-r-2 border-white">ICE</td>
                                      <td className="font-bold w-10 text-left text-xs p-1 border-b-2 border-r-2 border-white">eNPS</td>
                                    </tr>
                                    {getResponseWiseList(inner) && getResponseWiseList(inner).length > 0 && sortingName(inner.level, getResponseWiseList(inner)).map((dmgValue, indexX) =>
                                      <tr className="bg-[#F1F5F9]">
                                        <td className="text-[#212121] w-40 flex-shrink-0 font-medium text-left text-xs p-1 border-b-2 border-r-2 border-white">
                                          <div className="flex itmes-center space-x-2">
                                            <div className="w-3 h-3 bg-[#42A5F5] mt-0.5" style={{ background: getIndexColor(indexX) }} />
                                            <span>{dmgValue ? dmgValue : "-"}</span>
                                          </div>
                                        </td>
                                        <td className="font-medium w-10 text-left text-xs p-1 border-b-2 border-r-2 border-white">
                                          {getIceScores(inner, dmgValue)}
                                        </td>
                                        <td className="font-medium w-10 text-left text-xs p-1 border-b-2 border-r-2 border-white">
                                          {getNPSScores(inner, dmgValue)}
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>}

                          {inner && inner.level && inner.level === "Grade" &&
                            <div className=" ">
                              {/* <p className="text-sm font-bold"></p> */}
                              <div className="flex justify-between items-center">
                                <p className="text-sm font-bold" >Grade</p>
                                {/* <div className="flex space-x-2 pr-1">
                                  <p className="text-sm font-bold" >ICE</p>
                                  <p className="text-sm font-bold" >eNPS</p>
                                </div> */}
                              </div>
                              <div className="py-2 border-b w-full">
                                <table className="w-full">
                                  <tbody>
                                    <tr className="">
                                      <td className="text-[#212121] w-40 flex-shrink-0 font-medium text-right text-[10px] p-2 py-1"></td>
                                      <td className={innerList && innerList.length > 1 ? "w-[45%] p-2 py-1" : "w-[65%] p-2 py-1"}>
                                        <div className="bg-[#fff] w-full h-4 overflow-hidden flex items-center space-x-2">
                                          <div className="w-[45%] h-4" />
                                        </div>
                                      </td>
                                      <td className="font-bold w-8 text-left text-xs px-1 py-1">ICE</td>
                                      <td className="font-bold w-8 text-left text-xs px-1 py-1">eNPS</td>
                                    </tr>
                                    {getResponseWiseList(inner) && getResponseWiseList(inner).length > 0 && sortingName(inner.level, getResponseWiseList(inner)).map((dmgValue) =>
                                      <tr className=" ">
                                        <td className="text-[#212121] w-40 flex-shrink-0 font-medium text-right text-[10px] p-2 py-0.5 whitespace-nowrap">{dmgValue ? dmgValue : "-"}</td>
                                        <td className={innerList && innerList.length > 1 ? "w-[38%] p-2 py-0.5" : "w-[65%] p-2 py-0.5"}>
                                          {/* <td className="w-[45%] p-2 py-1">*/}
                                          <div className="bg-[#E8F4FD] w-full h-4 overflow-hidden flex items-center space-x-2">
                                            <div className="w-[38%] h-4" style={{ width: getIceScores(inner, dmgValue) + "%", backgroundColor: "#42A5F5" }} />
                                          </div>
                                        </td>
                                        <td className="font-medium w-8 text-left text-xs p-2 py-0.5">{getIceScores(inner, dmgValue)}</td>
                                        <td className="font-medium w-8 text-left text-xs p-2 py-0.5">{getNPSScores(inner, dmgValue)}</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>}


                          {inner && inner.level && inner.level === "EmployeeType" &&
                            <div className="  ">
                              <p className="text-sm font-bold" >Employee Type</p>
                              <div className="flex items-center space-x-6 justify-center py-4 ">
                                <ReactEcharts option={getChartPie(inner, getResponseWiseList(inner), inner.level)} style={{ height: '90px', width: '170px' }} className="" />
                                <table className="w-full">
                                  <tbody>
                                    <tr className="bg-[#F1F5F9]">
                                      <td className="text-[#212121] w-40 flex-shrink-0 font-bold text-left text-xs p-1 pl-6 border-b-2 border-r-2 border-white">Type</td>
                                      <td className="font-bold w-10 text-left text-xs p-2 border-b-2 border-r-2 border-white">ICE</td>
                                      <td className="font-bold w-10 text-left text-xs p-2 border-b-2 border-r-2 border-white">eNPS</td>
                                    </tr>
                                    {getResponseWiseList(inner) && getResponseWiseList(inner).length > 0 && getResponseWiseList(inner).map((dmgValue, indexX) =>
                                      <tr className="bg-[#F1F5F9]">
                                        <td className="text-[#212121] w-40 flex-shrink-0 font-medium text-left text-xs p-1 border-b-2 border-r-2 border-white"
                                          style={{ width: getIceScores(inner, dmgValue) + "%" }}>
                                          <div className="flex itmes-center space-x-2   ">
                                            <div className="w-3 h-3 bg-[#42A5F5] mt-0.5" style={{ background: getIndexColor(indexX) }} />
                                            <span>{dmgValue ? dmgValue : "-"}</span>
                                          </div>
                                        </td>
                                        <td className="font-medium w-10 text-left text-xs p-1 border-b-2 border-r-2 border-white">
                                          {getIceScores(inner, dmgValue)}
                                        </td>
                                        <td className="font-medium w-10 text-left text-xs p-1 border-b-2 border-r-2 border-white">
                                          {getNPSScores(inner, dmgValue)}
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>}
                        </>
                      )}
                    </div>
                  </>
                )
                }
              </div>
            </div>
          </section>
        )}

    </>

  );
}
export default ResponsePage;
