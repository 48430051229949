import React from "react";
import moment from "moment";

export default function DomainsFive(props) {
  let { userName, createdAt, domainThemeDetails } = props;




  return (
    <>
      <main className="p-10 px-12 space-y-6 bg-white page-break">
        <div className='page-break'>
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-semibold">Leadership Report</h1>
            <p className="text-lg font-semibold">{userName + " | " + createdAt}</p>
          </div>

          <div className="flex flex-col space-y-6 w-full pt-5">

            <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4"> Leadership 5 Domains Definition</h1>

            <div className="space-y-2">

              {domainThemeDetails.map((item, index) => (
                <div key={index} className="flex items-center px-4 py-3 w-full bg-[#FFF5D9] space-x-6" style={{ backgroundColor: item.colorOne }}>
                  <div className="flex items-center space-x-4 w-60 flex-shrink-0">
                    <div className="w-4 h-4 bg-[#FFC107]" style={{ backgroundColor: item.colorTwo }}></div>
                    <p className="text-base font-bold uppercase">{item.attribute}</p>
                  </div>
                  <div className="w-full text-sm">
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}

            </div>
          </div>

        </div>
      </main>
    </>
  );
}
