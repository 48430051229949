import React, { Component } from "react";
import { connect } from "react-redux";
import moment, { max } from "moment";
import IndexKPI from "./Components/IndexKPI";
import Filter from "./Components/Filter";
import { dashboardActions } from "../../../../../_actions";
import { CSVLink } from "react-csv";
import Commentary from "../../../Index/Components/Commentary/Commentary";
import DataNotFound from "../../../Components/DataNotFound";
class Dimension extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: "",
      showTaskDropdown: [],
      showMAPView: true,

      FilterValues: [],
      showFilter: false,
      showValue: "",
      dateValue: "",
      showEditDate: false,
      currEMPData: {},
      showMngrGoalsPage: 0,
      currACTData: {},
      showChangeStatus: "all",
    };
  }
  componentDidMount() { }

  openDropdown = (emp) => {
    if (this.state.showDropdown === emp.employeeId) {
      this.setState({ showDropdown: "" });
    } else {
      this.setState({ showDropdown: emp.employeeId });
    }
  };

  openTaskDropdown = (act) => {
    let { showTaskDropdown } = this.state;

    let getIndex = showTaskDropdown.findIndex((prev) => prev === act.id);
    if (getIndex === -1) {
      showTaskDropdown.push(act.id);
    } else {
      showTaskDropdown.splice(getIndex, 1);
    }
    this.setState({ showTaskDropdown });
  };

  handleMAPView = (val) => {
    this.setState({ showMAPView: val });
  };

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { handleMultiFilterGetQuestion } = this.props;
    this.setState({ FilterValues: finalFilter });

    //console.log('========s===================>',finalFilter)
    // handleMultiFilterGetQuestion(finalFilter);
  };

  handleDateChange = (value) => {
    this.setState({ dateValue: value });
  };

  handleShowEditDate = (check) => {
    this.setState({ showEditDate: check }, () => {
      if (!check) {
        let reqData = {
          date: this.state.dateValue,
        };
        this.props.dispatch(dashboardActions.editIDPLastDate(reqData));
      }
    });
  };

  showGoals = (empData) => {
    //////console.log("empData--->",empData);
    this.setState({
      currEMPData: empData,
      showMngrGoalsPage: 1,
      showChangeStatus: "all",
    });
  };

  showTasks = (actData) => {
    //////console.log("actData--->",actData);
    this.setState({
      currACTData: actData,
      showMngrGoalsPage: 2,
      showChangeStatus: "all",
    });
  };

  handleBackGoalTask = () => {
    let { showMngrGoalsPage } = this.state;
    if (showMngrGoalsPage === 2) {
      this.setState({ showMngrGoalsPage: 1, showChangeStatus: "all" });
    } else if (showMngrGoalsPage === 1) {
      this.setState({ showMngrGoalsPage: 0, showChangeStatus: "all" });
    }
  };

  handleChangeStatus = (check) => {
    this.setState({ showChangeStatus: check });
  };

  render() {
    let {
      getIDPLastDateData,
      getAllManagerActionPlanData,
      getDemographicIndexForManagerData,
      GetParameterLabel,
      EmpFilterData,
      getIndexFilterData,
    } = this.props;

    let {
      showMAPView,
      showDropdown,
      showTaskDropdown,
      FilterValues,
      currEMPData,
      showMngrGoalsPage,
      currACTData,
      showChangeStatus,
    } = this.state;

    //-------------------------------------------------------------------------------------------

    let DmgForMngrData = [];
    //console.log('x FilterValues: ',FilterValues);

    if (
      getDemographicIndexForManagerData &&
      getDemographicIndexForManagerData.length > 0
    ) {
      getDemographicIndexForManagerData.forEach((Emp) => {
        let isCheck = 0;
        if (FilterValues && FilterValues.length > 0) {
          FilterValues.forEach((filter) => {
            if (filter.value !== "All") {
              if (!(Emp[filter.level] === filter.value)) {
                isCheck++;
              }
            }

            //console.log('x 1: ',Emp[filter.level]);
            //console.log('x 2: ',filter.value);
          });
        }
        // //console.log('x Emp: ',Emp);
        // //console.log('x isCheck: ',isCheck);

        if (isCheck === 0) {
          DmgForMngrData.push(Emp);
        }
      });
    }

    //console.log('x DmgForMngrData: ',DmgForMngrData);

    let AllMngrAPlanData = [];

    if (getAllManagerActionPlanData && getAllManagerActionPlanData.length > 0) {
      getAllManagerActionPlanData.forEach((action) => {
        let emp_id =
          action && action.employeeId && action.employeeId.emp_id
            ? action.employeeId.emp_id
            : "";

        let getIndex = DmgForMngrData.findIndex(
          (prev) => prev.emp_id === emp_id
        );
        if (getIndex !== -1 || true) {
          AllMngrAPlanData.push(action);
        }
      });
    }

    //-------------------------------------------------------------------------------

    //console.log('getAllManagerActionPlanData: ',getAllManagerActionPlanData);
    // ////console.log('DmgForMngrData: ',DmgForMngrData);

    let EmployeeList = [];
    let actionTotalCount = 0;
    let actionCompleteCount = 0;
    let totalTasks = 0;
    let completedTasks = 0;
    let StartedTasks = 0;

    if (AllMngrAPlanData && AllMngrAPlanData.length > 0) {
      AllMngrAPlanData.forEach((action) => {
        if (
          action &&
          action.employeeTaskList &&
          action.employeeTaskList.length > 0
        ) {
          // ////console.log('action.employeeTaskList: ',action.employeeTaskList);

          totalTasks += action.employeeTaskList.length;
          completedTasks += action.employeeTaskList.filter(
            (x) => x.isComplete == true
          ).length;

          StartedTasks += action.employeeTaskList.filter(
            (x) => x.isStarted == true
          ).length;
        }

        if (action.isComplete) {
          actionCompleteCount++;
        }
        actionTotalCount++;
        let emp_id =
          action && action.employeeId && action.employeeId.emp_id
            ? action.employeeId.emp_id
            : "";
        let EmpEmail =
          action && action.employeeId && action.employeeId.email
            ? action.employeeId.email
            : "";
        let EmpName =
          action && action.employeeId && action.employeeId.name
            ? action.employeeId.name
            : "";

        let getIndex = EmployeeList.findIndex((prev) => prev.emp_id === emp_id);
        if (getIndex === -1) {
          EmployeeList.push({
            emp_id: emp_id,
            EmpName: EmpName,
            EmpEmail: EmpEmail,
          });
        }
      });
    }

    let EmployeeActionList = [];
    if (EmployeeList && EmployeeList.length > 0) {
      EmployeeList.forEach((emp) => {
        let actionFilteredList = AllMngrAPlanData.filter(
          (x) =>
            (x && x.employeeId && x.employeeId.emp_id
              ? x.employeeId.emp_id
              : "") == emp.emp_id
        );
        let completedActionCount = actionFilteredList.filter(
          (action) => action.isComplete == true
        ).length;
        let totalActionCount =
          actionFilteredList && actionFilteredList.length > 0
            ? actionFilteredList.length
            : 0;
        //////console.log('actionFilteredList: ',actionFilteredList);
        let startedActionCount = actionFilteredList.filter(
          (action) => action.isStarted == true
        ).length;

        let actionFilteredStartDate = AllMngrAPlanData.filter(
          (x) => x.isStarted == true
        );
        let actionFilteredEndDate = AllMngrAPlanData.filter(
          (x) => x.isComplete == true
        );
        ////console.log('actionFilteredStartDate: ',actionFilteredStartDate);
        //////console.log('actionFilteredEndDate: ',actionFilteredEndDate);

        let startDateList = [];
        if (actionFilteredStartDate && actionFilteredStartDate.length > 0) {
          actionFilteredStartDate.forEach((item) => {
            startDateList.push(item.startDate);
          });
        }
        let endDateList = [];
        if (actionFilteredEndDate && actionFilteredEndDate.length > 0) {
          actionFilteredEndDate.forEach((item) => {
            endDateList.push(item.startDate);
          });
        }
        ////console.log('startDateList: emp',emp,startDateList);

        // let min = 0
        // let max = 0
        // if(startDateList && startDateList.length>0){
        //   min = Math.min(...startDateList);
        // }
        // if(endDateList && endDateList.length>0){
        //   max = Math.max(...endDateList);
        // }

        //////console.log('minimum: ',min);
        //////console.log('maximum: ',max);

        let ManagerList = [];
        if (DmgForMngrData && DmgForMngrData.length > 0) {
          ManagerList = DmgForMngrData;
        }
        let getIndex = ManagerList.findIndex(
          (prev) => prev.emp_id === emp.emp_id
        );
        let AllStartDates = [];
        let AllEndDates = [];
        if (actionFilteredList && actionFilteredList.length > 0) {
          actionFilteredList.forEach((item) => {
            if (true) {
              ////console.log('action---item: ',item);

              if (item.isStarted) {
                AllStartDates.push(item.startDate);
              }
              if (item.isComplete) {
                AllEndDates.push(item.endDate);
              }
            }
          });
        }
        ////console.log('AllStartDates: ',AllStartDates);
        ////console.log('AllEndDates: ',AllEndDates);

        let tempList = {
          EmpName: emp.EmpName,
          EmpEmail: emp.EmpEmail,
          emp_id: emp.emp_id,
          emp_details: getIndex !== -1 ? DmgForMngrData[getIndex] : "",
          action_list: actionFilteredList,
          startDate:
            AllStartDates && AllStartDates.length > 0
              ? Math.min(...AllStartDates)
              : 0,
          endDate:
            AllEndDates && AllEndDates.length > 0
              ? Math.max(...AllEndDates)
              : 0,
          isStarted: startedActionCount ? true : false,
          isComplete: completedActionCount === totalActionCount ? true : false,
        };
        EmployeeActionList.push(tempList);
      });
    }
    ////console.log('EmployeeActionList: ',EmployeeActionList);

    // let ActionType2List = [];
    // if(AllMngrAPlanData && AllMngrAPlanData.length>0){
    //   AllMngrAPlanData.forEach((item)=>{
    //     let getIndex = ActionType2List.findIndex(prev=>prev.actionId.id===item.actionId.id);
    //     if(getIndex===-1){
    //       let temp= {
    //         actionId:item.actionId,
    //         empList:[]
    //       }
    //       if(item.emp_id){
    //         temp["empList"].push({
    //           "createdAt":item.createdAt,
    //           "emp_id":item.emp_id
    //         });
    //         ActionType2List.push(temp);
    //       }
    //     }
    //     else{
    //       if(item.emp_id){
    //         ActionType2List[getIndex]["empList"].push({
    //           "createdAt":item.createdAt,
    //           "emp_id":item.emp_id
    //         });
    //       }
    //     }
    //   });
    // }

    //console.log('AllMngrAPlanData: ',AllMngrAPlanData)

    let TaskList = [];

    if (AllMngrAPlanData && AllMngrAPlanData.length > 0) {
      AllMngrAPlanData.forEach((action) => {
        if (
          action &&
          action.employeeTaskList &&
          action.employeeTaskList.length > 0
        ) {
          action.employeeTaskList.forEach((task) => {
            let getIndex = TaskList.findIndex((prev) => prev.id === task.id);
            if (getIndex === -1) {
              TaskList.push({
                ...task,
                managerList: [
                  { managerId: task.emp_id, isComplete: task.isComplete },
                ],
                taskCount: 1,
                actionName: action.actionId.name,
                parameterName: action.actionId.parameterName,
              });
            } else {
              TaskList[getIndex]["managerList"].push({
                managerId: task.emp_id,
                isComplete: task.isComplete,
              });
              TaskList[getIndex]["taskCount"] += 1;
            }

            // let getIndex = TaskList.findIndex(prev=>prev.id===task.id);
            // if(getIndex === -1){
            //   TaskList.push({
            //     ...task,
            //     completeList:[task.isComplete],
            //     taskCount:1,
            //     actionName:action.actionId.name,
            //     parameterName:action.actionId.parameterName
            //   });
            // }
            // else{
            //   TaskList[getIndex]["completeList"].push(task.isComplete);
            //   TaskList[getIndex]["taskCount"] += 1;
            // }
          });
        }
      });
    }

    // let PendingTaskList = [];
    // let CompletedTaskList = [];

    // if(TaskList && TaskList.length>0){
    //   TaskList.forEach((item)=>{

    //     let booleanCount = 0;
    //     if(item && item.completeList && item.completeList.length>0){
    //       booleanCount = item.completeList.filter(x=>x === true).length;
    //     }

    //     if(booleanCount === item.taskCount){
    //       CompletedTaskList.push(item);
    //     }
    //     else{
    //       PendingTaskList.push(item);
    //     }

    //   });
    // }

    // if(TaskList && TaskList.length>0){
    //   TaskList.forEach((item)=>{

    //     if(item.isComplete){
    //       CompletedTaskList.push(item);
    //     }
    //     else{
    //       PendingTaskList.push(item);
    //     }

    //   });
    // }

    // //console.log('PendingTaskList:--------> ',PendingTaskList)
    // //console.log('CompletedTaskList:--------> ',CompletedTaskList)

    let EmpFilterData2 = [];
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== "ManagerPID") {
          EmpFilterData2.push(emp);
        }
      });
    }

    ////console.log('showTaskDropdown :',showTaskDropdown)

    let FilteredAllManagerActionPlanData = [];
    if (getAllManagerActionPlanData && getAllManagerActionPlanData.length > 0) {
      getAllManagerActionPlanData.forEach((Emp) => {
        let empData = Emp && Emp.employeeId ? Emp.employeeId : "";
        let isCheck = 0;
        if (FilterValues && FilterValues.length > 0) {
          FilterValues.forEach((filter) => {
            if (filter.value !== "All") {
              if (!(empData[filter.level] === filter.value)) {
                isCheck++;
              }
            }
          });
        }

        if (isCheck === 0) {
          FilteredAllManagerActionPlanData.push(Emp);
        }
      });
    }

    function ManagerStatus(task) {
      let ResponseAction = {
        assigned:
          task && task.managerList.length > 0 ? task.managerList.length : 0,
        pending:
          task && task.managerList.length > 0
            ? task.managerList.filter((x) => x.isComplete === false).length
            : 0,
        completed:
          task && task.managerList.length > 0
            ? task.managerList.filter((x) => x.isComplete === true).length
            : 0,
      };
      return ResponseAction;
    }

    let EmployeeList2 = [];
    if (
      FilteredAllManagerActionPlanData &&
      FilteredAllManagerActionPlanData.length > 0
    ) {
      FilteredAllManagerActionPlanData.forEach((emp) => {
        let employeeDetails = emp && emp.employeeId ? emp.employeeId : "";
        let employeeId =
          emp && emp.employeeId && emp.employeeId.id ? emp.employeeId.id : "";

        let getIndex = EmployeeList2.findIndex(
          (prev) => prev.employeeId === employeeId
        );

        if (getIndex !== -1) {
          EmployeeList2[getIndex]["actionList"].push(emp);
        } else {
          EmployeeList2.push({
            actionList: [emp],
            employeeDetails: employeeDetails,
            employeeId: employeeId,
          });
        }
      });
    }

    let TaskList2 = [];
    if (
      FilteredAllManagerActionPlanData &&
      FilteredAllManagerActionPlanData.length > 0
    ) {
      FilteredAllManagerActionPlanData.forEach((action) => {
        let taskList =
          action &&
            action.employeeTaskList &&
            action.employeeTaskList.length > 0
            ? action.employeeTaskList
            : [];
        if (taskList && taskList.length > 0) {
          taskList.forEach((task) => {
            let getIndex = TaskList2.findIndex((prev) => prev.id === task.id);
            if (getIndex === -1) {
              TaskList2.push({
                ...task,
                managerList: [
                  { managerId: task.employeeId, isComplete: task.isComplete },
                ],
                taskCount: 1,
                actionName: action.actionId.name,
                parameterName: action.actionId.parameterName,
              });
            } else {
              TaskList2[getIndex]["managerList"].push({
                managerId: task.employeeId,
                isComplete: task.isComplete,
              });
              TaskList2[getIndex]["taskCount"] += 1;
            }
          });
        }
      });
    }

    if (
      FilteredAllManagerActionPlanData &&
      FilteredAllManagerActionPlanData.length > 0
    ) {
      FilteredAllManagerActionPlanData.forEach((action) => {
        if (
          action &&
          action.employeeTaskList &&
          action.employeeTaskList.length > 0
        ) {
          totalTasks += action.employeeTaskList.length;
          completedTasks += action.employeeTaskList.filter(
            (x) => x.isComplete == true
          ).length;
          StartedTasks += action.employeeTaskList.filter(
            (x) => x.isStarted == true
          ).length;
        }
      });
    }

    //console.log('(((((((((((((getAllManagerActionPlanData :',getAllManagerActionPlanData)

    let totalGoals = 0;
    let completedGoals = 0;

    if (
      FilteredAllManagerActionPlanData &&
      FilteredAllManagerActionPlanData.length > 0
    ) {
      totalGoals = FilteredAllManagerActionPlanData.length;
      completedGoals = FilteredAllManagerActionPlanData.filter(
        (x) => x.isComplete == true
      ).length;
    }

    let ResponseAction = {
      assigned: totalGoals,
      pending: totalGoals - completedGoals,
      completed: completedGoals,
    };

    //console.log('(((((((((((((EmployeeList2 :',EmployeeList2);

    //console.log('TaskList:--------> ',TaskList);
    //console.log('TaskList2:--------> ',TaskList2);

    //console.log('(((((((((((((getDemographicIndexForManagerData :',getDemographicIndexForManagerData)

    //console.log('(((((((((((((EmployeeActionList :',EmployeeActionList)

    function getValue(data, type) {
      if (type === "name") {
        let name =
          data && data.employeeDetails && data.employeeDetails.name
            ? data.employeeDetails.name
            : "";
        return name;
      } else if (type === "email") {
        let email =
          data && data.employeeDetails && data.employeeDetails.email
            ? data.employeeDetails.email
            : "";
        return email;
      } else if (type === "emp_id") {
        let emp_id =
          data && data.employeeDetails && data.employeeDetails.emp_id
            ? data.employeeDetails.emp_id
            : "";
        return emp_id;
      } else if (type === "emp_startdate") {
        let ActionList = data && data.actionList ? data.actionList : "";
        let StartedActionList = ActionList.filter((x) => x.isStarted === true);

        let startDate = 0;
        if (StartedActionList && StartedActionList.length > 0) {
          StartedActionList.forEach((item, index) => {
            if (index === 0) {
              startDate = item.startDate;
            } else {
              if (item.startDate < startDate) {
                startDate = item.startDate;
              }
            }
          });
        }

        return startDate ? moment.unix(startDate).format("DD MMM, yyyy") : "";
      } else if (type === "emp_enddate") {
        let ActionList = data && data.actionList ? data.actionList : "";
        let CompleteActionList = ActionList.filter(
          (x) => x.isComplete === true
        );

        let endDate = 0;
        if (CompleteActionList && CompleteActionList.length > 0) {
          CompleteActionList.forEach((item, index) => {
            if (index === 0) {
              endDate = item.endDate;
            } else {
              if (item.endDate > endDate) {
                endDate = item.endDate;
              }
            }
          });
        }

        return endDate ? moment.unix(endDate).format("DD MMM, yyyy") : "";
      } else {
        return "";
      }
    }

    function getMNGRDetails(value) {
      return currEMPData &&
        currEMPData["employeeDetails"] &&
        currEMPData["employeeDetails"][value]
        ? currEMPData["employeeDetails"][value]
        : "";
    }

    function getGoalCalculation(goal, type) {
      let totalTaskCount = 0;
      let completedTaskCount = 0;
      if (goal && goal.actionList && goal.actionList.length > 0) {
        totalTaskCount = goal.actionList.length;
        completedTaskCount = goal.actionList.filter(
          (x) => x.isComplete === true
        ).length;
      }
      if (type === 1) {
        return completedTaskCount.toString() + "/" + totalTaskCount.toString();
      } else if (type === 2) {
        return (completedTaskCount * 100) / totalTaskCount + "%";
      } else if (type === 3) {
        return completedTaskCount === totalTaskCount;
      } else if (type === 4) {
        return completedTaskCount !== totalTaskCount;
      } else {
        return 0;
      }
    }

    function getTaskCalculation(activity, type) {
      //////console.log("activity---------------->",activity);
      let totalTaskCount = 0;
      let completedTaskCount = 0;
      if (
        activity &&
        activity.employeeTaskList &&
        activity.employeeTaskList.length > 0
      ) {
        totalTaskCount = activity.employeeTaskList.length;
        completedTaskCount = activity.employeeTaskList.filter(
          (x) => x.isComplete === true
        ).length;
      }
      if (type === 1) {
        return completedTaskCount.toString() + "/" + totalTaskCount.toString();
      } else if (type === 2) {
        return (completedTaskCount * 100) / totalTaskCount + "%";
      } else if (type === 3) {
        return completedTaskCount === totalTaskCount;
      } else {
        return 0;
      }
    }

    let taskDataList = [];
    let goalDataList = [];
    let empDataList = [];

    if (showMngrGoalsPage === 2) {
      if (
        currACTData &&
        currACTData["employeeTaskList"] &&
        currACTData["employeeTaskList"].length > 0
      ) {
        if (showChangeStatus === "completed") {
          taskDataList = currACTData["employeeTaskList"].filter(
            (x) => x.isComplete === true
          );
        } else if (showChangeStatus === "pending") {
          taskDataList = currACTData["employeeTaskList"].filter(
            (x) => x.isComplete === false
          );
        } else {
          taskDataList = currACTData["employeeTaskList"];
        }
      }
    } else if (showMngrGoalsPage === 1) {
      if (
        currEMPData &&
        currEMPData["actionList"] &&
        currEMPData["actionList"].length > 0
      ) {
        if (showChangeStatus === "completed") {
          goalDataList = currEMPData["actionList"].filter(
            (x) => x.isComplete === true
          );
        } else if (showChangeStatus === "pending") {
          goalDataList = currEMPData["actionList"].filter(
            (x) => x.isComplete === false
          );
        } else {
          goalDataList = currEMPData["actionList"];
        }
      }
    } else {
      if (EmployeeList2 && EmployeeList2.length > 0) {
        if (showChangeStatus === "completed") {
          empDataList = EmployeeList2.filter((x) => getGoalCalculation(x, 3));
        } else if (showChangeStatus === "pending") {
          empDataList = EmployeeList2.filter((x) => getGoalCalculation(x, 4));
        } else {
          empDataList = EmployeeList2;
        }
      }
    }

    //////console.log("empDataList------>",empDataList)

    let exportData = [];

    if (empDataList && empDataList.length > 0) {
      empDataList.forEach((emp) => {
        if (emp && emp.actionList && emp.actionList.length > 0) {
          emp.actionList.forEach((action) => {
            if (
              action &&
              action.employeeTaskList &&
              action.employeeTaskList.length > 0
            ) {
              action.employeeTaskList.forEach((task) => {
                let temp = {
                  emp_name:
                    emp && emp.employeeDetails && emp.employeeDetails.name
                      ? emp.employeeDetails.name
                      : "-",
                  emp_id:
                    emp && emp.employeeDetails && emp.employeeDetails.emp_id
                      ? emp.employeeDetails.emp_id
                      : "-",
                  goal_name:
                    action && action.actionId && action.actionId.name
                      ? action.actionId.name
                      : "-",
                  task_name: task && task.name ? task.name : "-",
                  createdAt:
                    task && task.createdAt
                      ? moment(new Date(parseInt(task.createdAt)))
                        .utcOffset("+05:30")
                        .format("DD MMM YYYY")
                      : "NaN",
                  startDate:
                    task && task.isStarted
                      ? moment(new Date(parseInt(task.startDate)))
                        .utcOffset("+05:30")
                        .format("DD MMM YYYY")
                      : "NaN",
                  completedDate:
                    task && task.isComplete
                      ? moment(new Date(parseInt(task.endDate)))
                        .utcOffset("+05:30")
                        .format("DD MMM YYYY")
                      : "NaN",
                  status: task.isComplete
                    ? "Completed"
                    : task.isStarted
                      ? "Pending"
                      : "No Started",
                };
                exportData.push(temp);
              });
            }
          });
        }
      });
    }

    //////console.log("exportData------>",exportData)

    let { indexType, getCommentData } = this.props;


    function hexToRgbA(hex, opacity) {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
      throw hex;
    }





    return (
      <>
        {showMngrGoalsPage === 0 ? (
          <div className="md:p-6 p-4 space-y-4 w-full flex-1 overflow-y-auto bg-[#f9fafb] ">
            <div className="justify-between md:flex">
              {/* left side */}
              <div className="flex items-center">
                <h1 class=" xl:text-2xl text-xl font-medium text-[#3D405B]  ">
                  Manager goal plan
                </h1>
              </div>
              {/* right side */}
              <div className="flex items-center lg:mt-0 mt-4 md:justify-end capitalize text-sm space-x-4 text-[#3D405B">
                {/* btn */}
                <p className="text-[#3D405B] font-medium flex-shrink-0">last edited</p>

                {/* <div className="flex items-center px-4 py-1 space-x-4 text-sm uppercase bg-white border rounded-full cursor-pointer">
            <span className>23-04-2020</span> <span className="text-base text-gray-500 material-symbols-outlined">calendar_month</span>
            </div> */}

                {getIDPLastDateData && getIDPLastDateData.lastIDPDate ? (
                  <div className="flex items-stretch w-full space-x-2 md:w-auto">
                    {this.state.showEditDate ? (
                      <input
                        type="date"
                        value={this.state.dateValue}
                        onChange={(e) => this.handleDateChange(e.target.value)}
                        className=" bg-white w-full p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2"
                      />
                    ) : (
                      <input
                        type="date"
                        value={getIDPLastDateData.lastIDPDate}
                        onChange={() => { }}
                        className="  bg-white w-full py-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2 "
                        disabled={true}
                        placeholder="dd-mm-yyyy"
                      />
                    )}

                    {!this.state.showEditDate ? (
                      <span
                        onClick={() => this.handleShowEditDate(true)}
                        className="  cursor-pointer flex-shrink-0 text-sm py-2 px-4 font-medium bg-[#2196f3] text-white rounded-md border border-[#2196f3]"
                      >
                        edit
                      </span>
                    ) : (
                      <>
                        <button
                          onClick={() => this.handleShowEditDate(false)}
                          className="cursor-pointer flex-shrink-0 text-sm py-2 px-4 font-medium bg-[#2196f3] text-white rounded-md border border-[#2196f3]"
                        >
                          Submit
                        </button>
                        {/* <span onClick={()=>this.handleShowEditDate(false)} className='px-2 mt-1 font-bold text-green-500 cursor-pointer material-symbols-outlined'>done</span> */}
                      </>
                    )}
                  </div>
                ) : (
                  <div className="flex py-3 text-lg ">
                    {this.state.showEditDate ? (
                      <input
                        type="date"
                        value={this.state.dateValue}
                        onChange={(e) => this.handleDateChange(e.target.value)}
                        className="px-2 py-1 text-sm border rounded-lg "
                      />
                    ) : (
                      <input
                        type="date"
                        value={this.state.dateValue}
                        onChange={() => { }}
                        className="px-2 py-1 text-sm border rounded-lg bg-gray-50 "
                        disabled={true}
                        placeholder="dd-mm-yyyy"
                      />
                    )}

                    {!this.state.showEditDate ? (
                      <span
                        onClick={() => this.handleShowEditDate(true)}
                        className="px-2 py-1 text-gray-500 cursor-pointer material-symbols-outlined "
                      >
                        edit
                      </span>
                    ) : (
                      <>
                        <div
                          onClick={() => this.handleShowEditDate(false)}
                          className="cursor-pointer px-2 py-0.5 ml-2 bg-green-500 text-white rounded-md text-sm pt-1"
                        >
                          Submit
                        </div>
                        {/* <span onClick={()=>this.handleShowEditDate(false)} className='px-2 mt-1 font-bold text-green-500 cursor-pointer material-symbols-outlined'>done</span> */}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* <div className="items-center justify-between space-y-4 md:flex lg:space-y-0">
        <div className="flex flex-wrap gap-2">
          <div className="bg-blue-500 border text-white flex justify-between px-4 items-center relative py-1.5 rounded-full space-x-1.5">
            <span className="material-symbols-outlined">filter_list</span>
            <span class=" text-sm text-[#3D405B]/70 font-medium ">Filters</span>
            <span className=" bg-white  rounded-full text-[#2196F3] text-xs w-6 h-6 flex items-center justify-center font-semibold">3</span>
            <span className="material-symbols-outlined ">arrow_drop_down</span>
          </div>
          <div className="bg-white text-gray-500    border  flex justify-between px-4 items-center relative py-1.5 rounded-full space-x-1.5">
            <span class=" text-sm text-[#3D405B]/70 font-medium ">Dehli</span>
            <span className="material-symbols-outlined ">arrow_drop_down</span>
          </div>
          <div className="bg-white text-gray-500    border  flex justify-between px-4 items-center relative py-1.5 rounded-full space-x-1.5">
            <span class=" text-sm text-[#3D405B]/70 font-medium ">Dehli</span>
            <span className="material-symbols-outlined ">arrow_drop_down</span>
          </div>
          <div className="bg-white text-gray-500    border  flex justify-between px-4 items-center relative py-1.5 rounded-full space-x-1.5">
            <span class=" text-sm text-[#3D405B]/70 font-medium ">Dehli</span>
            <span className="material-symbols-outlined ">arrow_drop_down</span>
          </div>
        </div>
      </div> */}

            <div className="flex flex-col justify-between gap-4 md:items-center md:flex-row">
              <Filter
                showFilter={this.state.showFilter}
                showValue={this.state.showValue}
                closeFilter={this.closeFilter}
                EmpFilterData2={EmpFilterData2}
                getIndexFilterData={getIndexFilterData}
                handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
              />

              <div className="flex justify-between">
                {exportData && exportData.length > 0 ? (
                  <CSVLink data={exportData} filename={"ManagerGoalPlan.csv"}>
                    <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                        <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                        <path d="M10 12l4 4m0 -4l-4 4" />
                      </svg>
                      <span> {"Export CSV"}</span>
                    </button>
                  </CSVLink>
                ) : (
                  ""
                )}

                <Commentary
                  indexType={indexType}
                  optionType={1}
                  getCommentData={getCommentData}
                />
              </div>
            </div>

            <IndexKPI ResponseAction={ResponseAction} />

            {/*  */}
            {/*sub nav bar 1 */}
            <div className="items-center justify-between border-b md:flex">
              <div className="flex space-x-2 overflow-hidden overflow-x-auto text-sm font-medium text-gray-600 capitalize cursor-pointer whitespace-nowrap tab-scroll">
                <span
                  onClick={() => this.handleChangeStatus("all")}
                  className={
                    showChangeStatus === "all"
                      ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                      : "hover:bg-[#F9FAFB] transition-all duration-150   cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "
                  }
                >
                  All{" "}
                </span>
                <span
                  onClick={() => this.handleChangeStatus("pending")}
                  className={
                    showChangeStatus === "pending"
                      ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                      : "hover:bg-[#F9FAFB] transition-all duration-150   cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "
                  }
                >
                  pending
                </span>
                <span
                  onClick={() => this.handleChangeStatus("completed")}
                  className={
                    showChangeStatus === "completed"
                      ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                      : "hover:bg-[#F9FAFB] transition-all duration-150   cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "
                  }
                >
                  Completed
                </span>
              </div>
            </div>
            {/* end */}
            {/* card work */}

            {empDataList && empDataList.length > 0 ? (
              <div className="grid gap-4 lg:grid-cols-3 md:grid-cols-2">
                {empDataList.map((empAct, index) => (
                  <div
                    key={index}
                    className="bg-white hover:shadow-lg duration-150 transition space-y-4 text-[#3D405B]  border p-6 rounded-lg  cursor-pointer"
                  >
                    <div className="flex items-start space-x-4">
                      <div className="p-4 rounded-full bg-slate-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-user"
                          width="44"
                          height="44"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#2c3e50"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <circle cx="12" cy="7" r="4" />
                          <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                        </svg>
                      </div>

                      <div className="space-y-2">
                        {" "}
                        <h1 className="text-lg font-semibold text-[#3D405B]">
                          {getValue(empAct, "name")}
                        </h1>
                        <p className="text-[#3D405B]/70 text-sm capitalize font-medium">
                          Employee ID:{" "}
                          <b className="font-medium">
                            {getValue(empAct, "emp_id")}
                          </b>
                        </p>
                        <p className="text-[#3D405B]/70 text-sm capitalize font-medium">
                          Email:{" "}
                          <b className="font-medium">
                            {getValue(empAct, "email")}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="space-y-1.5">
                      <div className="flex justify-between text-sm font-medium">
                        <p>Goal</p> <p>{getGoalCalculation(empAct, 1)}</p>
                      </div>
                      <div className="h-1 overflow-hidden bg-gray-100 rounded-full">
                        <div
                          className={
                            "w-2/6 h-1 " +
                            (getGoalCalculation(empAct, 3)
                              ? "bg-green-400"
                              : "bg-yellow-400")
                          }
                          style={{ width: getGoalCalculation(empAct, 2) }}
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => this.showGoals(empAct)}
                      className="flex items-center justify-between "
                    >
                      <span className="uppercase font-medium text-sm cursor-pointer text-[#2196f3]">
                        view Goal
                      </span>
                      {getGoalCalculation(empAct, 3) ? (
                        <button className="flex items-center px-4 py-2 text-sm font-medium text-white uppercase bg-green-500 rounded-md cursor-pointer">
                          {" "}
                          completed{" "}
                        </button>
                      ) : (
                        <button className="flex items-center px-4 py-2 text-sm font-medium text-white uppercase bg-yellow-500 rounded-md cursor-pointer">
                          {" "}
                          pending{" "}
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : ( 
              <div className="md:p-6 p-4 bg-white border rounded-lg">
                <DataNotFound />
              </div> 
            )}
          </div>
        ) : null}

        {showMngrGoalsPage === 1 ? (
          <div className=" md:p-6 p-4 space-y-4 w-full flex-1 overflow-y-auto bg-[#f9fafb]">
            <div className="justify-between md:flex">
              {/* left side */}
              <div className="flex items-center space-x-4 lg:space-x-6">
                <button
                  onClick={() => this.handleBackGoalTask()}
                  className="text-blue-500 bg-[#edf6fe] rounded-full cursor-pointer hover:bg-blue-100 trasition duration-150 p-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-arrow-left  "
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#2196f3"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                    <line x1="5" y1="12" x2="11" y2="18"></line>
                    <line x1="5" y1="12" x2="11" y2="6"></line>
                  </svg>
                </button>
                <h1 class=" xl:text-2xl text-xl font-medium text-[#3D405B]">
                  {" "}
                  Goal plans
                </h1>
              </div>

              <div className="md:flex  hidden items-center text-sm text-[#3D405B]">
                <span className="mx-2 material-symbols-rounded">home</span>
                <span>/</span>
                <span className="px-2">My survey</span>
                <span>/</span>
                <span className="px-2">Manager action plan</span>
                <span>/</span>
                <span className="px-2 font-semibold">Action plans</span>
              </div>
            </div>
            {/* end */}
            <div className="bg-white flex space-x-6 items-center hover:shadow-lg duration-150 transition   text-[#3D405B]  border p-6 rounded-lg  cursor-pointer ">
              <div className="p-4 rounded-full bg-slate-100 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-user"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#2c3e50"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx="12" cy="7" r="4" />
                  <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                </svg>
              </div>
              <div className="space-y-2">
                <h1 className="text-xl font-semibold">
                  {getMNGRDetails("name")}
                </h1>
                <p className=" text-sm capitalize text-[#3D405B]/70">
                  Employee ID:{" "}
                  <b className="font-semibold">{getMNGRDetails("emp_id")}</b>
                </p>
                <p className=" text-sm capitalize text-[#3D405B]/70">
                  Email:{" "}
                  <b className="font-semibold">{getMNGRDetails("email")}</b>
                </p>
              </div>
            </div>

            {/*sub nav bar 1 */}
            <div className="items-center justify-between border-b md:flex">
              <div className="flex space-x-2 overflow-hidden overflow-x-auto text-sm font-medium text-gray-600 capitalize cursor-pointer whitespace-nowrap tab-scroll">
                <span
                  onClick={() => this.handleChangeStatus("all")}
                  className={
                    showChangeStatus === "all"
                      ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                      : "hover:bg-[#F9FAFB] transition-all duration-150   cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "
                  }
                >
                  All{" "}
                </span>
                <span
                  onClick={() => this.handleChangeStatus("pending")}
                  className={
                    showChangeStatus === "pending"
                      ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                      : "hover:bg-[#F9FAFB] transition-all duration-150   cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "
                  }
                >
                  pending
                </span>
                <span
                  onClick={() => this.handleChangeStatus("completed")}
                  className={
                    showChangeStatus === "completed"
                      ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                      : "hover:bg-[#F9FAFB] transition-all duration-150   cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "
                  }
                >
                  Completed
                </span>
              </div>
            </div>

            {/* card work */}

            {goalDataList && goalDataList.length > 0 ? (
              <div className="grid gap-4 lg:grid-cols-4 md:grid-cols-2">
                {goalDataList.map((activity) => (
                  <div className="bg-white hover:shadow-lg duration-150 transition space-y-4 text-[#3D405B]  border p-6 rounded-lg  cursor-pointer flex flex-col justify-between h-full">
                    <div>
                      <h1 className="text-lg font-semibold">
                        {activity && activity.actionId && activity.actionId.name
                          ? activity.actionId.name
                          : ""}
                      </h1>
                      <p className="text-[#3D405B]/70 text-sm capitalize">
                        created date{" "}
                        {moment(new Date(parseInt(activity.createdAt)))
                          .utcOffset("+05:30")
                          .format("DD MMM YYYY")}
                      </p>
                    </div>
                    <p className="text-base text-[#3D405B]  line-clamp-3">
                      {activity && activity.actionId && activity.actionId.desc
                        ? activity.actionId.desc
                        : ""}
                    </p>
                    <div className="space-y-1.5">
                      <div className="flex justify-between text-base font-semibold text-[#3D405B] ">
                        <p>Task</p> <p>{getTaskCalculation(activity, 1)}</p>
                      </div>
                      <div className="h-1 overflow-hidden bg-gray-100 rounded-full">
                        <div
                          className="h-1 bg-blue-500 w-0/6"
                          style={{ width: getTaskCalculation(activity, 2) }}
                        />
                      </div>
                      {/* <p className="text-xs font-medium text-red-400 capitalize">expired in 3 days</p> */}
                    </div>
                    <div
                      onClick={() => this.showTasks(activity)}
                      className="flex items-center justify-between text-sky-500"
                    >
                      <h1 className="text-sm font-medium uppercase cursor-pointer">
                        view tasks
                      </h1>
                      {getTaskCalculation(activity, 3) ? (
                        <button className="flex items-center px-4 py-2 text-xs font-semibold text-white uppercase bg-green-500 rounded-full cursor-pointer">
                          {" "}
                          completed{" "}
                        </button>
                      ) : (
                        <button className="flex items-center px-4 py-2 text-xs font-semibold text-white uppercase bg-yellow-500 rounded-full cursor-pointer">
                          {" "}
                          pending{" "}
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <div className="py-4 text-2xl font-medium text-center text-gray-400">
                  Data Not Available
                </div>
              </>
            )}
          </div>
        ) : null}

        {showMngrGoalsPage === 2 ? (
          <div className="md:p-6 p-4 space-y-4 w-full flex-1 overflow-y-auto bg-[#f9fafb]">
            <div className="justify-between md:flex">
              {/* left side */}
              <div className="flex items-center space-x-4 lg:space-x-6">
                <button
                  onClick={() => this.handleBackGoalTask()}
                  className="text-blue-500 bg-[#edf6fe] rounded-full cursor-pointer hover:bg-blue-100 trasition duration-150 p-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-arrow-left  "
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#2196f3"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                    <line x1="5" y1="12" x2="11" y2="18"></line>
                    <line x1="5" y1="12" x2="11" y2="6"></line>
                  </svg>
                </button>
                <h1 class=" xl:text-2xl text-xl font-medium text-[#3D405B]">
                  {" "}
                  Goal plans
                </h1>
              </div>

              <div className="md:flex  hidden items-center text-sm text-[#3D405B]">
                <span className="mx-2 material-symbols-rounded">home</span>
                <span>/</span>
                <span className="px-2">My survey</span>
                <span>/</span>
                <span className="px-2">Manager action plan</span>
                <span>/</span>
                <span className="px-2 font-semibold">Action plans</span>
              </div>
            </div>

            {/* end */}
            <div className="bg-white flex space-x-6 items-center hover:shadow-lg duration-150 transition   text-[#3D405B]  border p-6 rounded-lg  cursor-pointer ">
              <div className="p-4 rounded-full bg-slate-100 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-user"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#2c3e50"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx="12" cy="7" r="4" />
                  <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                </svg>
              </div>
              <div className="space-y-2">
                <h1 className="text-xl font-semibold">
                  {getMNGRDetails("name")}
                </h1>
                <p className=" text-sm capitalize text-[#3D405B]/70">
                  Employee ID:{" "}
                  <b className="font-semibold">{getMNGRDetails("emp_id")}</b>
                </p>
                <p className=" text-sm capitalize text-[#3D405B]/70">
                  Email:{" "}
                  <b className="font-semibold">{getMNGRDetails("email")}</b>
                </p>
              </div>
            </div>

            {/*sub nav bar 1 */}
            <div className="items-center justify-between border-b md:flex">
              <div className="flex space-x-2 overflow-hidden overflow-x-auto text-sm font-medium text-gray-600 capitalize cursor-pointer whitespace-nowrap tab-scroll">
                <span
                  onClick={() => this.handleChangeStatus("all")}
                  className={
                    showChangeStatus === "all"
                      ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                      : "hover:bg-[#F9FAFB] transition-all duration-150   cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "
                  }
                >
                  All{" "}
                </span>
                <span
                  onClick={() => this.handleChangeStatus("pending")}
                  className={
                    showChangeStatus === "pending"
                      ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                      : "hover:bg-[#F9FAFB] transition-all duration-150   cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "
                  }
                >
                  pending
                </span>
                <span
                  onClick={() => this.handleChangeStatus("completed")}
                  className={
                    showChangeStatus === "completed"
                      ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                      : "hover:bg-[#F9FAFB] transition-all duration-150   cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "
                  }
                >
                  Completed
                </span>
              </div>
            </div>
            {/* end */}
            {/* card work */}

            {taskDataList && taskDataList.length > 0 ? (
              <div className="grid grid-cols-1 gap-4">

                {taskDataList.map((task) => (
                  <div
                    className="bg-white flex space-x-6 items-center hover:shadow-lg duration-150 transition   text-[#3D405B]  border p-6 rounded-lg  cursor-pointer"
                    style={{
                      backgroundImage: 'url("img/default_sm.png")',
                      backgroundSize: "cover",
                    }}
                  >
                    <div
                      style={{
                        background: (task.isComplete
                          ? hexToRgbA("#00CD7E", "0.2")
                          : task.isStarted
                            ? hexToRgbA("#EAB308", "0.2")
                            : hexToRgbA("#78909C", "0.2"))
                      }}
                      className={
                        " flex items-center rounded-lg  cursor-pointer p-6 px-4 border "
                        // +
                        // (task.isComplete
                        //   ? "bg-green-50"
                        //   : task.isStarted
                        //   ? "bg-yellow-50"
                        //   : "bg-blue-50")
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          color: (task.isComplete
                            ? "#00CD7E"
                            : task.isStarted
                              ? "#EAB308"
                              : "#78909C")
                        }}
                        className={
                          "icon icon-tabler icon-tabler-clipboard-list"

                        }
                        width="60"
                        height="60"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                        <rect x="9" y="3" width="6" height="4" rx="2" />
                        <line x1="9" y1="12" x2="9.01" y2="12" />
                        <line x1="13" y1="12" x2="15" y2="12" />
                        <line x1="9" y1="16" x2="9.01" y2="16" />
                        <line x1="13" y1="16" x2="15" y2="16" />
                      </svg>
                    </div>
                    <div className="space-y-2 ">
                      <h1 className="text-lg font-medium text-[#3D405B]">
                        {task && task.name ? task.name : ""}
                      </h1>
                      <p className=" text-sm font-medium capitalize text-[#3D405B]/70">
                        created date{" "}
                        {moment(new Date(parseInt(task.createdAt)))
                          .utcOffset("+05:30")
                          .format("DD MMM YYYY")}
                      </p>
                      <p className="text-xs font-medium text-gray-800 capitalize">
                        {task && task.deadline
                          ? "Target in " +
                          ((moment
                            .duration(
                              moment(task.deadline, "YYYY-MM-DD").diff(
                                moment().startOf("day")
                              )
                            )
                            .asDays()).toString()).replace("-", "") +
                          " days"
                          : "No Target Date"}
                      </p>
                      {task.isComplete ? (
                        <button style={{
                          background: (task.isComplete
                            ? "#00CD7E"
                            : task.isStarted
                              ? "#EAB308"
                              : "#78909C")
                        }} className="flex items-center px-4 py-2 text-sm text-white bg-white rounded-md cursor-pointer ">
                          Completed
                        </button>
                      ) :
                        (task.isStarted ?
                          (
                            <button style={{
                              background: (task.isComplete
                                ? "#00CD7E"
                                : task.isStarted
                                  ? "#EAB308"
                                  : "#78909C")
                            }} className="flex items-center px-4 py-2 text-sm text-white bg-white rounded-md cursor-pointer ">
                              Pending
                            </button>
                          ) :
                          (
                            <button style={{
                              background: (task.isComplete
                                ? "#00CD7E"
                                : task.isStarted
                                  ? "#EAB308"
                                  : "#78909C")
                            }} className="flex items-center px-4 py-2 text-sm text-white bg-white rounded-md cursor-pointer ">
                              Not Started
                            </button>
                          ))

                      }
                    </div>


                  </div>
                ))}

              </div>
            ) : (
              <>
                <div className="py-4 text-2xl font-medium text-center text-gray-400">
                  Data Not Available1231
                </div>
              </>
            )}
          </div>
        ) : null}
      </>
    );
  }
}
function mapStateToProps(state) {
  ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(Dimension);
