import React, { Component } from "react";
import { connect } from "react-redux";
import Header from '../Components/Header';

function IndexThemesDistribution(props) {

  let { getSubWiseParaFeedDistData, userName, createdAt, GetParameterLabel } = props;


  // let optionList = ["Extremely Dissatisfied", "Very Dissatisfied", "Somewhat Dissatisfied", "Somewhat Satisfied", "Very Satisfied", "Extremely Satisfied"]
  // let optionColorList = ["#EF5350", "#FF7043", "#FFA726", "#D4E157", "#9CCC65", "#66BB6A"]


  const getDistScore = (inner, opt, check) => {
    let totalSum = 0;
    let sumCount = 0;

    if (inner && inner.answers && inner.answers.length > 0) {
      inner.answers.forEach((element) => {
        let score = element["count"];
        if (element["answerText"] === opt) {
          sumCount += score;
        }
        totalSum += score;
      })
    }


    let score = totalSum > 0 ? parseFloat((sumCount * 100) / totalSum) : 0;
    return check ? (score > 5 ? score.toFixed(0) : "") : score
  }



  const distList = [
    {
      name: 'Strongly Disagree',
      color: '#EF5350'
    },
    {
      name: 'Disagree',
      color: '#FF7043'
    },
    {
      name: 'Slightly Disagree',
      color: '#FFA726'
    },
    {
      name: 'Slightly Agree',
      color: '#D4E157'
    },
    {
      name: 'Agree',
      color: '#9CCC65'
    },
    {
      name: 'Strongly Agree',
      color: '#66BB6A'
    },
  ]


  function chunkArray(array, chunkSize) {
    let result = [];
    let i = 0;
    while (i < array.length) {
      let chunk = array.slice(i, i + chunkSize);
      result.push(chunk);
      i += chunkSize;
      // chunkSize = 9;
    }
    return result;
  }

  const sortingList = (list) => {
    let sortedList = []
    if (list && list.length > 0) {
      sortedList = list.sort((a, b) => {
        return ('' + a.xAxis).localeCompare(('' + b.xAxis));
      });
    } 
    return sortedList
  }

  let size = 2;
  let finalList = getSubWiseParaFeedDistData && getSubWiseParaFeedDistData.length > 0 ? chunkArray(sortingList(getSubWiseParaFeedDistData), size) : [];




  return (
    <>
      {finalList && finalList.length > 0 &&
        finalList.map((innerList, index) =>
          <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
            {/* report header */}
            <Header createdAt={createdAt} userName={userName} />


            <div className="flex flex-col space-y-4 w-full ">
              <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                {"ICE Index Themes Distribution (in %)" + (index > 0 ? " (Continue)" : "")}
              </h1>

              <div className="space-y-4 ">

                {innerList && innerList.length > 0 &&
                  innerList.map((ele, index) => (
                    <div className="space-y-4 ">

                      <table className="w-full">
                        <thead className=" bg-[#F1F5F9]">
                          <td className="text-base font-bold text-[#212121] bg-[#F1F5F9] p-2 border-b-2 border-r-2 border-white">{ele && ele.xAxis ? ele.xAxis : "Any"}</td>
                          <td className="p-2 border-b-2 border-r-2 border-white">
                            <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]   ">
                              {distList && distList.length > 0 && distList.map((ele) =>
                                <div className="flex items-center ">
                                  <div
                                    className="p-1.5 rounded-sm bg-[#EF5350]" style={{ background: ele.color }}
                                  />
                                  <p className="ml-1.5">{ele.name}</p>
                                </div>
                              )}
                            </div>
                          </td>
                        </thead>
                        {ele && ele.indexDist && ele.indexDist.length > 0 &&
                          ele.indexDist.map((para, index) => (
                            <tr className=" bg-[#F1F5F9]">
                              <td className="text-[#212121] w-56  text-left text-sm p-2 border-b-2 border-r-2 border-white">{para && para._id ? GetParameterLabel(para._id) : ""}</td>
                              <td className="text-[#212121]  font-medium text-left text-sm p-2 border-b-2 border-r-2 border-white">
                                <div className="w-full flex items-center text-sm ">
                                  {distList && distList.length > 0 &&
                                    distList.map((ele) =>
                                      <div className="w-[10%] bg-[#EF5350] h-6 flex items-center justify-center font-medium"
                                        style={{ background: ele.color, width: getDistScore(para, ele.name) + "%" }}
                                      >{
                                          getDistScore(para, ele.name, true)
                                        }</div>)}
                                </div>
                              </td>
                            </tr>
                          ))}
                      </table>
                    </div>
                  ))}


              </div>
            </div>

          </section>
        )}
    </>

  );
}
export default IndexThemesDistribution;
