import React, { Component } from "react";
import { connect } from "react-redux";

function Header(props) {

  let { userName, createdAt } = props;

  return (
    <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
      <p className='font-medium text-base'>Pulse Report</p>
      {/* <p className='font-medium text-base'>{userName + " | " + createdAt}</p> */}
      <div className="text-center text-[#212121]/80  font-medium flex items-center space-x-2 justify-center" >
        <span>{userName} </span> <span>|</span> <span>{createdAt}</span>
      </div>
    </div>
  );
}
export default Header;
