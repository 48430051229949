import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dashboardActions } from '../../../_actions';
// import Filter from '../../CommonComponents/Filters/IndexFilter/Filter'
import ReactEcharts from 'echarts-for-react';

class ICEIndexDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currFilterName: 'none',
      showLeaderBoard: false,
      isMultifilter: false,
      optionType: 1,
      showOverview: true,
      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: '',
      ListName: 'Demographic',
      showEDAModal: false,
      crrDMGValue: ""
    }
  }

  componentDidMount() {

  }

  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleNPSFilter } = this.props;
    this.setState({ FilterValues: finalFilter, ListName: name });
    handleNPSFilter(finalFilter, true, name);
  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  onOptionType = (value) => {
    this.setState({ optionType: value });
  };

  render() {

    let { optionType, FilterValues } = this.state;
    let { getIceDmgWiseAnsCountData, getIceNPSRowWiseData, getEMPFilterLabel, getIndexFilterData, EmpFilterData, getIceIndexData } = this.props;

    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }


    function getDMGScore(list, type) {

      let totalCount = 0;

      let Promoters = 0;
      let Passsives = 0;
      let Distractors = 0;

      if (type === "overall") {
        if (list && list.length > 0) {
          list.forEach(eleX => {
            if (eleX && eleX.answerTextGroups && eleX.answerTextGroups.length > 0) {
              eleX.answerTextGroups.forEach(ele => {


                let key = parseInt(ele.answerText);
                let score = parseInt(ele.count);

                totalCount += score;

                if (parseInt(key) >= 9 && parseInt(key) <= 10) {
                  Promoters += score;
                }
                if (parseInt(key) >= 7 && parseInt(key) <= 8) {
                  Passsives += score;
                }
                if (parseInt(key) >= 0 && parseInt(key) <= 6) {
                  Distractors += score;
                }


              });
            }
          });
        }
      } else {
        if (list && list.length > 0) {
          list.forEach(ele => {
            let key = parseInt(ele.answerText);
            let score = parseInt(ele.count);

            totalCount += score;

            if (parseInt(key) >= 9 && parseInt(key) <= 10) {
              Promoters += score;
            }
            if (parseInt(key) >= 7 && parseInt(key) <= 8) {
              Passsives += score;
            }
            if (parseInt(key) >= 0 && parseInt(key) <= 6) {
              Distractors += score;
            }

          });
        }
      }

      let PromotersPer = (Promoters * 100 / totalCount);
      let PasssivesPer = (Passsives * 100 / totalCount);
      let DistractorsPer = (Distractors * 100 / totalCount);

      let npsScore = ((PromotersPer) - (DistractorsPer)).toFixed(2);

      let PromotersColorRange = PromotersPer / 100;
      let PasssivesColorRange = (PasssivesPer / 100) + PromotersColorRange;
      let DistractorsColorRange = (DistractorsPer / 100) + PasssivesColorRange;

      let NPSObj = {
        "sign": parseFloat(npsScore) < 0 ? "-" : "+",
        "npsScore": parseFloat(npsScore).toFixed(0),
        "npsScoreBar": (npsScore).toString(),
        "PromotersPer": PromotersPer.toFixed(0),
        "PasssivesPer": PasssivesPer.toFixed(0),
        "DistractorsPer": DistractorsPer.toFixed(0)
      }

      return NPSObj
    }


    function getDMGWiseNPSScore(data, type) {

      let totalCount = 0;

      let Promoters = 0;
      let Passsives = 0;
      let Distractors = 0;

      let new_list = [];
      if (type === "Overall") {
        new_list = data.feedbacks;
      } else if (type === "Self") {
        new_list = data.feedbacks.filter(item => item.feedback.includes('Self'));
      } else if (type === "Cross") {
        new_list = data.feedbacks.filter(item => !item.feedback.includes('Self'));
      }

      if (new_list && new_list.length > 0) {
        new_list.forEach(eleX => {
          if (eleX && eleX.answers && eleX.answers.length > 0) {
            eleX.answers.forEach(ele => {

              let key = parseInt(ele.answerText);
              let score = parseInt(ele.count);

              totalCount += score;

              if (parseInt(key) >= 9 && parseInt(key) <= 10) {
                Promoters += score;
              }
              if (parseInt(key) >= 7 && parseInt(key) <= 8) {
                Passsives += score;
              }
              if (parseInt(key) >= 0 && parseInt(key) <= 6) {
                Distractors += score;
              }


            });
          }

        });
      }



      let PromotersPer = (Promoters * 100 / totalCount);
      let PasssivesPer = (Passsives * 100 / totalCount);
      let DistractorsPer = (Distractors * 100 / totalCount);

      let npsScore = ((PromotersPer) - (DistractorsPer)).toFixed(2);

      let PromotersColorRange = PromotersPer / 100;
      let PasssivesColorRange = (PasssivesPer / 100) + PromotersColorRange;
      let DistractorsColorRange = (DistractorsPer / 100) + PasssivesColorRange;

      let NPSObj = {
        "sign": parseFloat(npsScore) < 0 ? "-" : "+",
        "npsScore": parseFloat(npsScore).toFixed(0),
        "npsScoreBar": (npsScore).toString(),
        "PromotersPer": PromotersPer.toFixed(0),
        "PasssivesPer": PasssivesPer.toFixed(0),
        "DistractorsPer": DistractorsPer.toFixed(0)
      }

      return NPSObj
    }


    let overall_list = [];
    let self_list = [];
    let cross_list = [];

    if (getIceNPSRowWiseData && getIceNPSRowWiseData.length > 0) {
      overall_list = getIceNPSRowWiseData;
      self_list = getIceNPSRowWiseData.filter(item => item._id.includes('Self'));
      cross_list = getIceNPSRowWiseData.filter(item => !item._id.includes('Self'));
    }



    const MultiPieCircle1 = {
      series: [
        {
          type: 'pie',
          radius: ['65%', '80%'], // Outer circle
          startAngle: 180,
          data: [
            { value: getDMGScore(overall_list, "overall")["PromotersPer"], itemStyle: { color: '#66BB6A', borderRadius: '50%', } },
            { value: 100 - parseInt(getDMGScore(overall_list, "overall")["PromotersPer"]), itemStyle: { color: '#66BB6A29', } },

          ],
          label: {
            show: false, // Hide the label by default 
            position: 'center',
            formatter: getDMGScore(overall_list, "overall")["PromotersPer"],
            fontSize: 16,
            fontWeight: 'bold',
            color: '#212121', // Text color
            fontFamily: 'Poppins, sans-serif', // Title font family
          },
          labelLine: {
            show: false,
          },
          emphasis: {
            label: {
              show: true, // Show the label on hover
            },
          },
        },
        {
          type: 'pie',
          radius: ['50%', '63%'], // Middle circle
          startAngle: 180,
          data: [
            { value: getDMGScore(overall_list, "overall")["PasssivesPer"], itemStyle: { color: '#FFA726', borderRadius: '50%' } },
            { value: 100 - parseInt(getDMGScore(overall_list, "overall")["PasssivesPer"]), itemStyle: { color: '#FFA72629' } },
          ],
          label: {
            show: false, // Hide the label by default
            position: 'center',
            formatter: getDMGScore(overall_list, "overall")["PasssivesPer"],
            fontSize: 16,
            fontWeight: 'bold',
            color: '#212121', // Text color
            fontFamily: 'Poppins, sans-serif', // Title font family
          },
          labelLine: {
            show: false,
          },
          emphasis: {
            label: {
              show: true, // Show the label on hover
            },
          },
        },
        {
          type: 'pie',
          radius: ['35%', '48%'], // Inner circle
          startAngle: 180,
          data: [
            { value: getDMGScore(overall_list, "overall")["DistractorsPer"], itemStyle: { color: '#EF5350', borderRadius: '50%', } },
            { value: 100 - parseInt(getDMGScore(overall_list, "overall")["DistractorsPer"]), itemStyle: { color: '#F4433629' } },
          ],
          label: {
            show: false, // Hide the label by default
            position: 'center',
            formatter: getDMGScore(overall_list, "overall")["DistractorsPer"],
            fontSize: 16,
            fontWeight: 'bold',
            color: '#212121', // Text color
            fontFamily: 'Poppins, sans-serif', // Title font family
          },
          labelLine: {
            show: false,
          },
          emphasis: {
            label: {
              show: true, // Show the label on hover
            },
          },
        },
      ],
    };

    const MultiPieCircle2 = {
      series: [
        {
          type: 'pie',
          radius: ['65%', '80%'], // Outer circle
          startAngle: 180,
          data: [
            { value: getDMGScore(self_list, "overall")["PromotersPer"], itemStyle: { color: '#66BB6A', borderRadius: '50%', } },
            { value: 100 - parseInt(getDMGScore(self_list, "overall")["PromotersPer"]), itemStyle: { color: '#66BB6A29', } },

          ],
          label: {
            show: false, // Hide the label by default 
            position: 'center',
            formatter: getDMGScore(self_list, "overall")["PromotersPer"],
            fontSize: 16,
            fontWeight: 'bold',
            color: '#212121', // Text color
            fontFamily: 'Poppins, sans-serif', // Title font family
          },
          labelLine: {
            show: false,
          },
          emphasis: {
            label: {
              show: true, // Show the label on hover
            },
          },
        },
        {
          type: 'pie',
          radius: ['50%', '63%'], // Middle circle
          startAngle: 180,
          data: [
            { value: getDMGScore(self_list, "overall")["PasssivesPer"], itemStyle: { color: '#FFA726', borderRadius: '50%' } },
            { value: 100 - parseInt(getDMGScore(self_list, "overall")["PasssivesPer"]), itemStyle: { color: '#FFA72629' } },
          ],
          label: {
            show: false, // Hide the label by default
            position: 'center',
            formatter: getDMGScore(self_list, "overall")["PasssivesPer"],
            fontSize: 16,
            fontWeight: 'bold',
            color: '#212121', // Text color
            fontFamily: 'Poppins, sans-serif', // Title font family
          },
          labelLine: {
            show: false,
          },
          emphasis: {
            label: {
              show: true, // Show the label on hover
            },
          },
        },
        {
          type: 'pie',
          radius: ['35%', '48%'], // Inner circle
          startAngle: 180,
          data: [
            { value: getDMGScore(self_list, "overall")["DistractorsPer"], itemStyle: { color: '#EF5350', borderRadius: '50%', } },
            { value: 100 - parseInt(getDMGScore(self_list, "overall")["DistractorsPer"]), itemStyle: { color: '#F4433629' } },
          ],
          label: {
            show: false, // Hide the label by default
            position: 'center',
            formatter: getDMGScore(self_list, "overall")["DistractorsPer"],
            fontSize: 16,
            fontWeight: 'bold',
            color: '#212121', // Text color
            fontFamily: 'Poppins, sans-serif', // Title font family
          },
          labelLine: {
            show: false,
          },
          emphasis: {
            label: {
              show: true, // Show the label on hover
            },
          },
        },
      ],
    };

    const MultiPieCircle3 = {
      series: [
        {
          type: 'pie',
          radius: ['65%', '80%'], // Outer circle
          startAngle: 180,
          data: [
            { value: getDMGScore(cross_list, "overall")["PromotersPer"], itemStyle: { color: '#66BB6A', borderRadius: '50%', } },
            { value: 100 - parseInt(getDMGScore(cross_list, "overall")["PromotersPer"]), itemStyle: { color: '#66BB6A29', } },

          ],
          label: {
            show: false, // Hide the label by default 
            position: 'center',
            formatter: getDMGScore(cross_list, "overall")["PromotersPer"],
            fontSize: 16,
            fontWeight: 'bold',
            color: '#212121', // Text color
            fontFamily: 'Poppins, sans-serif', // Title font family
          },
          labelLine: {
            show: false,
          },
          emphasis: {
            label: {
              show: true, // Show the label on hover
            },
          },
        },
        {
          type: 'pie',
          radius: ['50%', '63%'], // Middle circle
          startAngle: 180,
          data: [
            { value: getDMGScore(cross_list, "overall")["PasssivesPer"], itemStyle: { color: '#FFA726', borderRadius: '50%' } },
            { value: 100 - parseInt(getDMGScore(cross_list, "overall")["PasssivesPer"]), itemStyle: { color: '#FFA72629' } },
          ],
          label: {
            show: false, // Hide the label by default
            position: 'center',
            formatter: getDMGScore(cross_list, "overall")["PasssivesPer"],
            fontSize: 16,
            fontWeight: 'bold',
            color: '#212121', // Text color
            fontFamily: 'Poppins, sans-serif', // Title font family
          },
          labelLine: {
            show: false,
          },
          emphasis: {
            label: {
              show: true, // Show the label on hover
            },
          },
        },
        {
          type: 'pie',
          radius: ['35%', '48%'], // Inner circle
          startAngle: 180,
          data: [
            { value: getDMGScore(cross_list, "overall")["DistractorsPer"], itemStyle: { color: '#EF5350', borderRadius: '50%', } },
            { value: 100 - parseInt(getDMGScore(cross_list, "overall")["DistractorsPer"]), itemStyle: { color: '#F4433629' } },
          ],
          label: {
            show: false, // Hide the label by default
            position: 'center',
            formatter: getDMGScore(cross_list, "overall")["DistractorsPer"],
            fontSize: 16,
            fontWeight: 'bold',
            color: '#212121', // Text color
            fontFamily: 'Poppins, sans-serif', // Title font family
          },
          labelLine: {
            show: false,
          },
          emphasis: {
            label: {
              show: true, // Show the label on hover
            },
          },
        },
      ],
    };



    function getChartOpt(npsScore) {
      if (npsScore) {
         let opt = {
            series: [{
               type: 'gauge',
               min: -100,
               max: 100,
               axisLine: {
                  lineStyle: {
                     width: 15,
                     color: [
                        [0.5, '#e57373'],
                        [0.8, '#FFA726'],
                        [1, '#81c784']
                     ]
                  }
               },
               pointer: {
                  itemStyle: {
                     color: 'auto'
                  }
               },
               axisTick: {
                  distance: -30,
                  length: 8,
                  lineStyle: {
                     color: '#fff',
                     width: 2
                  }
               },
               splitLine: {
                  distance: -30,
                  length: 30,
                  lineStyle: {
                     color: '#fff',
                     width: 4
                  }
               },
               axisLabel: {
                  color: 'auto',
                  distance: 25,
                  fontSize: 10
               },
               detail: {
                  valueAnimation: false,
                  formatter: '{value}',
                  color: '#757575',
                  fontSize: 0
               },
               data: [{
                  value: npsScore // Replace npsScore with your hardcoded value
               }]
            }]
         }
         return opt
      } else {
         let opt = {
            series: [{
               type: 'gauge',
               min: -100,
               max: 100,
               axisLine: {
                  lineStyle: {
                     width: 15,
                     color: [
                        [0.5, '#e57373'],
                        [0.8, '#FFA726'],
                        [1, '#81c784']
                     ]
                  }
               },
               pointer: {
                  itemStyle: {
                     color: 'auto'
                  }
               },
               axisTick: {
                  distance: -30,
                  length: 8,
                  lineStyle: {
                     color: '#fff',
                     width: 2
                  }
               },
               splitLine: {
                  distance: -30,
                  length: 30,
                  lineStyle: {
                     color: '#fff',
                     width: 4
                  }
               },
               axisLabel: {
                  color: 'auto',
                  distance: 25,
                  fontSize: 10
               },
               detail: {
                  valueAnimation: false,
                  formatter: '{value}',
                  color: '#757575',
                  fontSize: 0
               },
               data: [{
                  value: 0
               }]
            }]
         }
         return opt
      }

   }



    return (
      <>
        <main className="flex-1 w-full p-4 overflow-hidden overflow-y-auto bg-[#f9fafb] md:p-6 space-y-6 ">

          {/* <div className=''>
            <Filter
              showFilter={this.state.showFilter}
              showValue={this.state.showValue}
              closeFilter={this.closeFilter}
              EmpFilterData2={EmpFilterData2}
              getIndexFilterData={getIndexFilterData}
              handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
              getEMPFilterLabel={getEMPFilterLabel}
            />
          </div> */}

          {!(FilterValues && FilterValues.length > 0) ?
            <div className="md:p-4 p-4 space-y-8 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
              <h1 className="font-medium xl:text-xl text-lg text-[#212121] pb-4 ">How likely are you to recommend this department to another colleague / department / team? (0-10 pt scale)
              </h1>

              <div className="w-full gap-6 flex md:flex-row flex-col md:items-center justify-center">
                <p className='text-xl font-semibold text-[#212121] '>eNPS = % of Promoters - % of Detractors</p>
                <div className="flex md:flex-row flex-row md:gap-6 gap-4 justify-center md:text-base text-sm">
                  <div className="flex flex-col items-center gap-2">
                    <p className="font-medium">Detractors</p>
                    <div className="text-white flex items-center divide-x divide-white ">
                      <span className="md:w-8 w-6 flex items-center justify-center md:h-8 h-6 bg-[#EF5350]">1</span>
                      <span className="md:w-8 w-6 flex items-center justify-center md:h-8 h-6 bg-[#EF5350]">2</span>
                      <span className="md:w-8 w-6 flex items-center justify-center md:h-8 h-6 bg-[#EF5350]">3</span>
                      <span className="md:w-8 w-6 flex items-center justify-center md:h-8 h-6 bg-[#EF5350]">4</span>
                      <span className="md:w-8 w-6 flex items-center justify-center md:h-8 h-6 bg-[#EF5350]">5</span>
                      <span className="md:w-8 w-6 flex items-center justify-center md:h-8 h-6 bg-[#EF5350]">6</span>
                    </div>
                  </div>
                  <div className="flex flex-col items-center gap-2">
                    <p className="font-medium">Passive</p>
                    <div className="text-white flex items-center divide-x divide-white ">
                      <span className="md:w-8 w-6 flex items-center justify-center md:h-8 h-6 bg-[#FFA726]">7</span>
                      <span className="md:w-8 w-6 flex items-center justify-center md:h-8 h-6 bg-[#FFA726]">8</span>
                    </div>
                  </div>
                  <div className="flex flex-col items-center gap-2">
                    <p className="font-medium">Promoters</p>
                    <div className="text-white flex items-center divide-x divide-white ">
                      <span className="md:w-8 w-6 flex items-center justify-center md:h-8 h-6 bg-[#66BB6A]">9</span>
                      <span className="md:w-8 w-6 flex items-center justify-center md:h-8 h-6 bg-[#66BB6A]">10</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='grid xl:grid-cols-3 grid-cols-1 gap-6'>


                <div className='border rounded-lg p-4 space-y-4'>
                  <p className='text-lg font-semibold text-center'>Overall eNPS Score</p>

                  <div className='w-full mx-auto '>
                    <div className='flex justify-center items-center h-full'>
                      <ReactEcharts option={getChartOpt(getDMGScore(overall_list, "overall")["npsScore"])} style={{ height: '270px', width: '270px' }} />
                    </div>
                  </div>

                  <p className='text-center font-semibold'>NPS Score (Promoters - Detractors): {getDMGScore(overall_list, "overall")["npsScore"] + "%"}</p>

                  <div>
                    <div className='flex items-center space-x-6 py-2 border-b'>
                      <div className='flex items-center space-x-2 w-72'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M18.2441 23.421H5.52761V8.75896L13.4898 0.508789L14.6065 1.39681C14.7286 1.50359 14.8181 1.64268 14.8646 1.79807C14.9266 2.00216 14.9557 2.21483 14.9508 2.42808V2.71383L13.6615 8.75896H22.2245C22.6767 8.76999 23.1074 8.95457 23.4272 9.27445C23.7471 9.59433 23.9317 10.025 23.9427 10.4772V12.824C23.9433 12.9636 23.9576 13.1028 23.9855 13.2395C24.0146 13.3791 23.9997 13.5243 23.9427 13.6551L20.3344 21.9592C20.152 22.3734 19.86 22.73 19.4899 22.9905C19.1325 23.2657 18.6951 23.4168 18.2441 23.421ZM7.2459 21.7027H18.6161L22.2245 13.139V10.4757H11.542L13.06 3.34385L7.2459 9.47297V21.7027ZM5.52761 8.75896V10.4772H1.71829V21.7027H5.52761V23.421H0V8.75896H5.52761Z" fill="#66BB6A" />
                        </svg>
                        <p className='text-sm'>Promoters <b className='font-medium'>({getDMGScore(overall_list, "overall")["PromotersPer"] + "%"})</b></p>
                      </div>
                      <div className="h-8 w-full bg-[#F1F5F9]">
                        <div className="flex items-center h-8 bg-[#66BB6A] w-[50%]" style={{ width: getDMGScore(overall_list, "overall")["PromotersPer"] + "%" }}>
                        </div>
                      </div>
                    </div>

                    <div className='flex items-center space-x-6 py-2 border-b'>
                      <div className='flex items-center space-x-2 w-72'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M18.2441 23.421H5.52761V8.75896L13.4898 0.508789L14.6065 1.39681C14.7286 1.50359 14.8181 1.64268 14.8646 1.79807C14.9266 2.00216 14.9557 2.21483 14.9508 2.42808V2.71383L13.6615 8.75896H22.2245C22.6767 8.76999 23.1074 8.95457 23.4272 9.27445C23.7471 9.59433 23.9317 10.025 23.9427 10.4772V12.824C23.9433 12.9636 23.9576 13.1028 23.9855 13.2395C24.0146 13.3791 23.9997 13.5243 23.9427 13.6551L20.3344 21.9592C20.152 22.3734 19.86 22.73 19.4899 22.9905C19.1325 23.2657 18.6951 23.4168 18.2441 23.421ZM7.2459 21.7027H18.6161L22.2245 13.139V10.4757H11.542L13.06 3.34385L7.2459 9.47297V21.7027ZM5.52761 8.75896V10.4772H1.71829V21.7027H5.52761V23.421H0V8.75896H5.52761Z" fill="#FFA726" />
                        </svg>
                        <p className='text-sm'>Passives<b className='font-medium'>({getDMGScore(overall_list, "overall")["PasssivesPer"] + "%"})</b></p>
                      </div>
                      <div className="h-8 w-full bg-[#F1F5F9]">
                        <div className="flex items-center h-8 bg-[#FFA726] w-[15%]" style={{ width: getDMGScore(overall_list, "overall")["PasssivesPer"] + "%" }}>

                        </div>
                      </div>
                    </div>

                    <div className='flex items-center space-x-6 py-2 '>
                      <div className='flex items-center space-x-2 w-72'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
                          <path d="M1.77277 14.6722C1.54818 14.6725 1.3259 14.627 1.11953 14.5383C0.913159 14.4497 0.727048 14.3199 0.572593 14.1569C0.409549 14.0024 0.279748 13.8163 0.191139 13.61C0.102529 13.4036 0.0569758 13.1813 0.0572684 12.9567V10.6051C0.0567532 10.4655 0.0424315 10.3263 0.0145123 10.1896C-0.0146114 10.05 0.000328345 9.90476 0.0572684 9.774L3.66304 1.4673C3.84558 1.05331 4.1376 0.696959 4.50767 0.436648C4.8652 0.161721 5.3026 0.0108287 5.7536 0.00683594H18.4717V14.6722L10.5085 22.9234L9.39163 22.0353C9.26953 21.9285 9.18009 21.7894 9.13359 21.634C9.07157 21.4299 9.04245 21.2172 9.04733 21.0039V20.7173L10.336 14.6737L1.77277 14.6722ZM16.7532 1.72534H5.38154L1.77277 10.2901V12.9537H12.4566L10.9383 20.0858L16.7532 13.9589V1.72534ZM18.4717 14.6722V12.9537H22.2815V1.72534H18.4717V0.00683594H24V14.6722H18.4717Z" fill="#EF5350" />
                        </svg>
                        <p className='text-sm'>Detractors<b className='font-medium'>({getDMGScore(overall_list, "overall")["DistractorsPer"] + "%"})</b></p>
                      </div>
                      <div className="h-8 w-full bg-[#F1F5F9]">
                        <div className="flex items-center h-8 bg-[#EF5350] w-[75%]" style={{ width: getDMGScore(overall_list, "overall")["DistractorsPer"] + "%" }}>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='border rounded-lg p-4 space-y-4'>
                  <p className='text-lg font-semibold text-center'>Self</p>

                  <div className='w-full mx-auto '>
                    <div className='flex justify-center items-center h-full'>
                      <ReactEcharts option={getChartOpt(getDMGScore(self_list, "overall")["npsScore"])} style={{ height: '270px', width: '270px' }} />
                    </div>
                  </div>

                  <p className='text-center font-semibold'>NPS Score (Promoters - Detractors): {getDMGScore(self_list, "overall")["npsScore"] + "%"}</p>

                  <div>
                    <div className='flex items-center space-x-6 py-2 border-b'>
                      <div className='flex items-center space-x-2 w-72'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M18.2441 23.421H5.52761V8.75896L13.4898 0.508789L14.6065 1.39681C14.7286 1.50359 14.8181 1.64268 14.8646 1.79807C14.9266 2.00216 14.9557 2.21483 14.9508 2.42808V2.71383L13.6615 8.75896H22.2245C22.6767 8.76999 23.1074 8.95457 23.4272 9.27445C23.7471 9.59433 23.9317 10.025 23.9427 10.4772V12.824C23.9433 12.9636 23.9576 13.1028 23.9855 13.2395C24.0146 13.3791 23.9997 13.5243 23.9427 13.6551L20.3344 21.9592C20.152 22.3734 19.86 22.73 19.4899 22.9905C19.1325 23.2657 18.6951 23.4168 18.2441 23.421ZM7.2459 21.7027H18.6161L22.2245 13.139V10.4757H11.542L13.06 3.34385L7.2459 9.47297V21.7027ZM5.52761 8.75896V10.4772H1.71829V21.7027H5.52761V23.421H0V8.75896H5.52761Z" fill="#66BB6A" />
                        </svg>
                        <p className='text-sm'>Promoters <b className='font-medium'>({getDMGScore(self_list, "overall")["PromotersPer"] + "%"})</b></p>
                      </div>
                      <div className="h-8 w-full bg-[#F1F5F9]">
                        <div className="flex items-center h-8 bg-[#66BB6A] w-[50%]" style={{ width: getDMGScore(self_list, "overall")["PromotersPer"] + "%" }}>
                        </div>
                      </div>
                    </div>

                    <div className='flex items-center space-x-6 py-2 border-b'>
                      <div className='flex items-center space-x-2 w-72'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M18.2441 23.421H5.52761V8.75896L13.4898 0.508789L14.6065 1.39681C14.7286 1.50359 14.8181 1.64268 14.8646 1.79807C14.9266 2.00216 14.9557 2.21483 14.9508 2.42808V2.71383L13.6615 8.75896H22.2245C22.6767 8.76999 23.1074 8.95457 23.4272 9.27445C23.7471 9.59433 23.9317 10.025 23.9427 10.4772V12.824C23.9433 12.9636 23.9576 13.1028 23.9855 13.2395C24.0146 13.3791 23.9997 13.5243 23.9427 13.6551L20.3344 21.9592C20.152 22.3734 19.86 22.73 19.4899 22.9905C19.1325 23.2657 18.6951 23.4168 18.2441 23.421ZM7.2459 21.7027H18.6161L22.2245 13.139V10.4757H11.542L13.06 3.34385L7.2459 9.47297V21.7027ZM5.52761 8.75896V10.4772H1.71829V21.7027H5.52761V23.421H0V8.75896H5.52761Z" fill="#FFA726" />
                        </svg>
                        <p className='text-sm'>Passives<b className='font-medium'>({getDMGScore(self_list, "overall")["PasssivesPer"] + "%"})</b></p>
                      </div>
                      <div className="h-8 w-full bg-[#F1F5F9]">
                        <div className="flex items-center h-8 bg-[#FFA726] w-[15%]" style={{ width: getDMGScore(self_list, "overall")["PasssivesPer"] + "%" }}>

                        </div>
                      </div>
                    </div>

                    <div className='flex items-center space-x-6 py-2 '>
                      <div className='flex items-center space-x-2 w-72'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
                          <path d="M1.77277 14.6722C1.54818 14.6725 1.3259 14.627 1.11953 14.5383C0.913159 14.4497 0.727048 14.3199 0.572593 14.1569C0.409549 14.0024 0.279748 13.8163 0.191139 13.61C0.102529 13.4036 0.0569758 13.1813 0.0572684 12.9567V10.6051C0.0567532 10.4655 0.0424315 10.3263 0.0145123 10.1896C-0.0146114 10.05 0.000328345 9.90476 0.0572684 9.774L3.66304 1.4673C3.84558 1.05331 4.1376 0.696959 4.50767 0.436648C4.8652 0.161721 5.3026 0.0108287 5.7536 0.00683594H18.4717V14.6722L10.5085 22.9234L9.39163 22.0353C9.26953 21.9285 9.18009 21.7894 9.13359 21.634C9.07157 21.4299 9.04245 21.2172 9.04733 21.0039V20.7173L10.336 14.6737L1.77277 14.6722ZM16.7532 1.72534H5.38154L1.77277 10.2901V12.9537H12.4566L10.9383 20.0858L16.7532 13.9589V1.72534ZM18.4717 14.6722V12.9537H22.2815V1.72534H18.4717V0.00683594H24V14.6722H18.4717Z" fill="#EF5350" />
                        </svg>
                        <p className='text-sm'>Detractors<b className='font-medium'>({getDMGScore(self_list, "overall")["DistractorsPer"] + "%"})</b></p>
                      </div>
                      <div className="h-8 w-full bg-[#F1F5F9]">
                        <div className="flex items-center h-8 bg-[#EF5350] w-[75%]" style={{ width: getDMGScore(self_list, "overall")["DistractorsPer"] + "%" }}>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className='border rounded-lg p-4 space-y-4'>
                  <p className='text-lg font-semibold text-center'>Cross-functional</p>

                  <div className='w-full mx-auto '>
                    <div className='flex justify-center items-center h-full'>
                      <ReactEcharts option={getChartOpt(getDMGScore(cross_list, "overall")["npsScore"])} style={{ height: '270px', width: '270px' }} />
                    </div>
                  </div>

                  <p className='text-center font-semibold'>NPS Score (Promoters - Detractors): {getDMGScore(cross_list, "overall")["npsScore"] + "%"}</p>

                  <div>
                    <div className='flex items-center space-x-6 py-2 border-b'>
                      <div className='flex items-center space-x-2 w-72'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M18.2441 23.421H5.52761V8.75896L13.4898 0.508789L14.6065 1.39681C14.7286 1.50359 14.8181 1.64268 14.8646 1.79807C14.9266 2.00216 14.9557 2.21483 14.9508 2.42808V2.71383L13.6615 8.75896H22.2245C22.6767 8.76999 23.1074 8.95457 23.4272 9.27445C23.7471 9.59433 23.9317 10.025 23.9427 10.4772V12.824C23.9433 12.9636 23.9576 13.1028 23.9855 13.2395C24.0146 13.3791 23.9997 13.5243 23.9427 13.6551L20.3344 21.9592C20.152 22.3734 19.86 22.73 19.4899 22.9905C19.1325 23.2657 18.6951 23.4168 18.2441 23.421ZM7.2459 21.7027H18.6161L22.2245 13.139V10.4757H11.542L13.06 3.34385L7.2459 9.47297V21.7027ZM5.52761 8.75896V10.4772H1.71829V21.7027H5.52761V23.421H0V8.75896H5.52761Z" fill="#66BB6A" />
                        </svg>
                        <p className='text-sm'>Promoters <b className='font-medium'>({getDMGScore(cross_list, "overall")["PromotersPer"] + "%"})</b></p>
                      </div>
                      <div className="h-8 w-full bg-[#F1F5F9]">
                        <div className="flex items-center h-8 bg-[#66BB6A] w-[50%]" style={{ width: getDMGScore(cross_list, "overall")["PromotersPer"] + "%" }}>
                        </div>
                      </div>
                    </div>

                    <div className='flex items-center space-x-6 py-2 border-b'>
                      <div className='flex items-center space-x-2 w-72'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M18.2441 23.421H5.52761V8.75896L13.4898 0.508789L14.6065 1.39681C14.7286 1.50359 14.8181 1.64268 14.8646 1.79807C14.9266 2.00216 14.9557 2.21483 14.9508 2.42808V2.71383L13.6615 8.75896H22.2245C22.6767 8.76999 23.1074 8.95457 23.4272 9.27445C23.7471 9.59433 23.9317 10.025 23.9427 10.4772V12.824C23.9433 12.9636 23.9576 13.1028 23.9855 13.2395C24.0146 13.3791 23.9997 13.5243 23.9427 13.6551L20.3344 21.9592C20.152 22.3734 19.86 22.73 19.4899 22.9905C19.1325 23.2657 18.6951 23.4168 18.2441 23.421ZM7.2459 21.7027H18.6161L22.2245 13.139V10.4757H11.542L13.06 3.34385L7.2459 9.47297V21.7027ZM5.52761 8.75896V10.4772H1.71829V21.7027H5.52761V23.421H0V8.75896H5.52761Z" fill="#FFA726" />
                        </svg>
                        <p className='text-sm'>Passives<b className='font-medium'>({getDMGScore(cross_list, "overall")["PasssivesPer"] + "%"})</b></p>
                      </div>
                      <div className="h-8 w-full bg-[#F1F5F9]">
                        <div className="flex items-center h-8 bg-[#FFA726] w-[15%]" style={{ width: getDMGScore(cross_list, "overall")["PasssivesPer"] + "%" }}>

                        </div>
                      </div>
                    </div>

                    <div className='flex items-center space-x-6 py-2 '>
                      <div className='flex items-center space-x-2 w-72'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
                          <path d="M1.77277 14.6722C1.54818 14.6725 1.3259 14.627 1.11953 14.5383C0.913159 14.4497 0.727048 14.3199 0.572593 14.1569C0.409549 14.0024 0.279748 13.8163 0.191139 13.61C0.102529 13.4036 0.0569758 13.1813 0.0572684 12.9567V10.6051C0.0567532 10.4655 0.0424315 10.3263 0.0145123 10.1896C-0.0146114 10.05 0.000328345 9.90476 0.0572684 9.774L3.66304 1.4673C3.84558 1.05331 4.1376 0.696959 4.50767 0.436648C4.8652 0.161721 5.3026 0.0108287 5.7536 0.00683594H18.4717V14.6722L10.5085 22.9234L9.39163 22.0353C9.26953 21.9285 9.18009 21.7894 9.13359 21.634C9.07157 21.4299 9.04245 21.2172 9.04733 21.0039V20.7173L10.336 14.6737L1.77277 14.6722ZM16.7532 1.72534H5.38154L1.77277 10.2901V12.9537H12.4566L10.9383 20.0858L16.7532 13.9589V1.72534ZM18.4717 14.6722V12.9537H22.2815V1.72534H18.4717V0.00683594H24V14.6722H18.4717Z" fill="#EF5350" />
                        </svg>
                        <p className='text-sm'>Detractors<b className='font-medium'>({getDMGScore(cross_list, "overall")["DistractorsPer"] + "%"})</b></p>
                      </div>
                      <div className="h-8 w-full bg-[#F1F5F9]">
                        <div className="flex items-center h-8 bg-[#EF5350] w-[75%]" style={{ width: getDMGScore(cross_list, "overall")["DistractorsPer"] + "%" }}>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>


              </div>

              {/* <div className="flex md:flex-row flex-col gap-4 justify-center items-center   text-[#3D405B] text-base font-medium  ">
                <button className="p-4 py-2 text-[#2196F3]    transition flex items-center space-x-2">
                  <span> View In Details</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 6l6 6l-6 6" /></svg>
                </button>
              </div> */}
            </div>
            :
            <div className="md:p-4 p-4 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
              <h1 className="font-medium xl:text-xl text-lg text-[#212121] whitespace-nowrap">How likely are you to recommend this department to another colleague / department / team? (0-10 pt scale)
              </h1>

              <div className="w-full mb-4 overflow-hidden overflow-x-auto customscroll2 ">
                <table className="w-full border whitespace-nowrap ">
                  <thead>
                    <tr className="bg-[#F9FAFB] border-b text-sm  text-[#212121]">
                      <th rowSpan={2} className="py-3 px-2 border-r font-semibold  md:w-[50%] w-40 text-left  ">
                        Department
                      </th>
                      <th colSpan={3} className="py-3 px-2 border-r font-semibold md:w-[50%] w-60">
                        NPS Score (%)
                      </th>
                    </tr>
                    <tr className="bg-[#F9FAFB] border-b text-sm  text-[#212121]">
                      <th className="py-3 px-4 border-r font-semibold md:w-[20%] w-40">
                        Overall Score (%)
                      </th>
                      <th className="py-3 px-4 border-r font-semibold md:w-[20%] w-40">
                        Self (%)
                      </th>
                      <th className="py-3 px-4 border-r font-semibold md:w-[20%] w-40">
                        Cross-Functional (%)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {getIceDmgWiseAnsCountData && getIceDmgWiseAnsCountData.length > 0 &&
                      getIceDmgWiseAnsCountData.sort((a, b) => (a.xAxis > b.xAxis ? 1 : -1)).map((ele) =>
                    <tr className="bg-white text-sm text-[#3D405B] font-medium">
                      <td className="p-3 border border-b  w-60 ">
                        {ele && ele.xAxis}
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        {getDMGWiseNPSScore(ele, "Overall")["npsScore"] + "%"}
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        {getDMGWiseNPSScore(ele, "Self")["npsScore"] + "%"}
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        {getDMGWiseNPSScore(ele, "Cross")["npsScore"] + "%"}
                      </td>
                    </tr>
                      )}



                    {/* <tr className="bg-white text-sm text-[#3D405B] font-medium">
                      <td className="p-3 border border-b  w-60 ">
                        Cash Operation
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                    </tr>
                    <tr className="bg-white text-sm text-[#3D405B] font-medium">
                      <td className="p-3 border border-b  w-60 ">
                        Infosec & Marketing

                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                    </tr>
                    <tr className="bg-white text-sm text-[#3D405B] font-medium">
                      <td className="p-3 border border-b  w-60 ">
                        Digital Sales
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                    </tr>
                    <tr className="bg-white text-sm text-[#3D405B] font-medium">
                      <td className="p-3 border border-b  w-60 ">
                        Financial Services & Inclusion
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                    </tr>
                    <tr className="bg-white text-sm text-[#3D405B] font-medium">
                      <td className="p-3 border border-b  w-60 ">
                        Product & Digital Transformation
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                    </tr>
                    <tr className="bg-white text-sm text-[#3D405B] font-medium">
                      <td className="p-3 border border-b  w-60 ">
                        Digital Operations
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                    </tr>
                    <tr className="bg-white text-sm text-[#3D405B] font-medium">
                      <td className="p-3 border border-b  w-60 ">
                        Human Resource
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                    </tr>
                    <tr className="bg-white text-sm text-[#3D405B] font-medium">
                      <td className="p-3 border border-b  w-60 ">
                        Technology
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                    </tr>
                    <tr className="bg-white text-sm text-[#3D405B] font-medium">
                      <td className="p-3 border border-b  w-60 ">
                        Business Finance, Finance & Accounts and Legal
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                      <td className="p-3 border border-b text-center   ">
                        32%
                      </td>
                    </tr> */}


                  </tbody>
                </table>
              </div>

              {/* <div className="flex md:flex-row flex-col gap-4 justify-center items-center   text-[#3D405B] text-sm font-medium  ">
                <p className="">Pages</p>
                <div className="flex border divide-x rounded-md ">
                  <button className="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                    Previous
                  </button>
                  <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] ">
                    1
                  </button>
                  <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                    2
                  </button>
                  <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                    3
                  </button>
                  <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                    4
                  </button>
                  <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                    5
                  </button>
                  <button className="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                    Next
                  </button>
                </div>
                <p>2 of 10</p>
              </div> */}



            </div>
          }

        </main>
      </>
    );

  }
}




function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(ICEIndexDetails);