import React, { Component } from "react";
import { connect } from "react-redux";

function DevelopmentActionPlan(props) {
  let { userName, createdAt } = props;

  return (
    <section className="p-6 px-6 flex flex-col justify-between page-break">
      {/* report header */}
      <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
        <p className='font-medium italic text-base '>360 Feedback</p>
        <p className='font-medium italic text-base'>{userName} | {createdAt}</p>
      </div>
      <div className="space-y-4 flex flex-col  ">
        <h1 className="text-2xl text-[#212121] font-medium capitalize">
          Development Action Plan
        </h1>

        <div className="text-slate-800 space-y-4 text-sm">
          <p className="italic " >
            This document, when completed, should assist you in analysing your
            360º Assessment feedback along with your strength assessment report,
            and creating a specific action-based development plan. This
            Development Action Plan is a key resource in enabling you to design
            you goals and activities and help you to seamlessly achieve your
            objectives.
          </p>
        </div>

        <span className="text-xl font-semibold text-sky-500 underline  ">Part 1:</span>
        <p className="text-base font-medium ">Goal : Roadmap</p>


        <table className="w-full border">
          <thead>
            <tr className=" ">
              <th className="w-5/12"></th>
              <th className="w-7/12"></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className=" bg-gray-100 border-r">
                <div className="flex   p-2 px-4  ">
                  <p>
                    <b className="font-semibold  "> SUMMARY:</b> Write down the
                    specific and time-bound goal here
                  </p>
                </div>
              </td>
              <td className="p-2 px-4"></td>
            </tr>
          </tbody>
        </table>

        <table className="w-full border">
          <thead>
            <tr className=" ">
              <th className="w-5/12"></th>
              <th className="w-7/12"></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className=" bg-gray-100 border-r">
                <div className="flex   p-2 px-4  ">
                  <p>
                    <b className="font-semibold  "> MOTIVATION:</b> This goal is
                    important for me to achieve because
                  </p>
                </div>
              </td>
              <td className="p-2 px-4"></td>
            </tr>
          </tbody>
        </table>

        <table className="w-full border">
          <thead>
            <tr className=" ">
              <th className="w-5/12"></th>
              <th className="w-7/12"></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className=" bg-gray-100 border-r">
                <div className="flex   p-2 px-4  ">
                  <p>
                    <b className="font-semibold  "> GAP:</b>My self Assessment
                    (my strength and my own perception), how its is
                    similar/different from those of my Subordinates and/or
                    Peers/Customers (as per 360 assessment)?
                  </p>
                </div>
              </td>
              <td className="p-2 px-4"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
}
export default DevelopmentActionPlan;
