import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class DriverRadar extends Component {
  constructor(props) {
    super(props);
    this.state = {


    }
  }

  componentDidMount() {

  }


  render() {
    let { GetParameterLabel, isStrengths, GetParameterDesc, getQuestionData } = this.props;


    function getScoreValueX(relation, questionX) {
      let scoreMean = 0;
      if (relation !== "Self") {
        let otherSum = 0;
        let otherCount = 0;

        if (questionX && questionX.relationList && questionX.relationList.length > 0) {
          questionX.relationList.forEach((relation) => {
            if (relation !== "Self") {
              otherCount += Number(questionX[relation + "_count"])


              otherSum += Number(questionX[relation + "_sum"]);
            }
          })
        }
        scoreMean = Number((otherSum / otherCount).toFixed(2))
      }
      else {
        scoreMean = Number(questionX["Self_sum"] / questionX["Self_count"]);

      }
      return [Number((scoreMean).toFixed(2))]

    }


    let questionTableList = []
    if (getQuestionData && getQuestionData.length > 0) {
      getQuestionData.forEach((element) => {
        let temp = {
          "name": element.name,
          "para": element.parameterDimensionName,
          "score": getScoreValueX("Other", element)
        }
        questionTableList.push(temp)
      })
      if (isStrengths) {
        questionTableList.sort((b, a) => parseFloat(a.score) - parseFloat(b.score))
      } else {
        questionTableList.sort((a, b) => parseFloat(a.score) - parseFloat(b.score))
      }

    }



    function getColorAsc(isStrength, crrPos, lenTh) {
      let colorList = isStrength ? ["#4CAF50", "#4CAF50", "#4CAF50", "#4CAF50"] : ["#F44336", "#F44336", "#F44336", "#F44336"];
      let color = isStrength ? "#388E3C" : "#388E3C";
      let perc = Number((crrPos * 100) / lenTh);
      if (isStrength) {
        if (perc >= 0 && perc < 25) {
          color = colorList[0]
        } else if (perc >= 25 && perc < 50) {
          color = colorList[1]
        } else if (perc >= 50 && perc < 75) {
          color = colorList[2]
        } else if (perc >= 75 && perc <= 100) {
          color = colorList[3]
        }
      } else {
        if (perc >= 0 && perc < 25) {
          color = colorList[0]
        } else if (perc >= 25 && perc < 50) {
          color = colorList[1]
        } else if (perc >= 50 && perc < 75) {
          color = colorList[2]
        } else if (perc >= 75 && perc <= 100) {
          color = colorList[3]
        }
      }


      return color
    }
    let { userName, createdAt } = this.props;


    return (

      <section className='p-6 px-6 flex flex-col justify-between page-break'>

        {/* report header */}
        <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
          <p className='font-medium italic text-base '>360 Feedback</p>
          <p className='font-medium italic text-base'>{userName} | {createdAt}</p>
        </div>
        <div className='space-y-4 flex flex-col '>
          <h1 className='text-2xl text-slate-800 font-medium   capitalize'>{isStrengths ? "Strengths" : "Areas of Development"} </h1>

          <div className='text-[#212121]/80 italic  space-y-4 text-sm'>
            {isStrengths ?
              <span><p className="">{"Below are the statements where you received the highest ratings and are considered your key strengths "}</p><b>{"(Exclude Self)"}</b></span>
              :
              <span><p className="">{"Below are the statements where you received the lowest ratings and are considered your areas of development "}</p><b>{"(Exclude Self)"}</b></span>
            }
          </div>

          <div className='space-y-2  '>

            {questionTableList && questionTableList.length > 0 ?
              questionTableList.map((element, index) =>
                (index < 10) ?
                  <div className='flex items-stretch'>
                    <div className='flex items-center  '>
                      <div style={{ background: getColorAsc(isStrengths, index + 1, questionTableList.length) }} className={(isStrengths ? "bg-[#4CAF50] " : "bg-[#F44336] ") + ' flex items-center text-white h-full w-16 justify-center text-base font-medium'}>
                        {element && element.score > 0 ? (element.score) : 0}</div>
                    </div>
                    <div className='space-y-2 bg-[#4CAF50]/10 w-full p-4 py-2' style={{
                      background: `${getColorAsc(isStrengths, index + 1, questionTableList.length)}1A`, // 7F represents 50% opacity in hexadecimal
                    }}
                    >
                      <p className='text-base text-[#212121] leading-5'>{element.name}</p>
                      <p className='font-bold text-base italic text-[#212121]/80'>-{GetParameterLabel(element.para)}</p>
                    </div>
                  </div>

                  : null
              ) : null}

          </div>

        </div>



      </section>


    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DriverRadar);
