import React from 'react';
import ReactEcharts from "echarts-for-react";


export default function BarCorelation(props) {
    
  let { DriverName, DriverValues } = props;


  let XAxis = DriverName
  let List = DriverValues




  return (
    <>	
      <div className="pt-1 pb-20">
        <ReactEcharts
          option={{
            tooltip:{
              trigger: 'item',
              formatter:function (a) {
                // //console.log('params: ', a)
                return '<b>'+XAxis[a.dataIndex]+'</b></br>'+'Value: '+((a.value)?a.value.toFixed(2):0).toString()
              }
            },
            xAxis: {
                type: 'category',
                data: XAxis,
                axisLabel:{
                  show:true,
                  rotate:40,
                  interval:0,
                  formatter:function(a){
                    //console.log(a)
                    return a?a.length>12?a.slice(0,12)+'..':a:a
                  }
                }
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: List,
                type: 'bar',
                showBackground: true,
                barWidth:'30px',
                color:'#6536d1'
            }]
        }}
                          
        />
    
    <div className="text-center mt-10"><b>{"X-Axis:"}</b>{" Organization Core Drivers,"} <b>{"Y-Axis:"}</b>{" Relative weight"}</div>
    </div>
                 
    </>
  );
}
