import React, { Component } from "react";
import { connect } from "react-redux";
import Header from '../Components/Header';
import ReactEcharts from 'echarts-for-react';

function ICEIndexSummary(props) {

  let { list, crrSubReportType, getIceDistributionData, getLTHeadIceScoreV3Data, getLTHeadIceParaScoreV3Data, userName, createdAt, IndexNumber, getIceIndexData, GetParameterLabel, getLTHeadIceParaScoreData, getLTHeadIceSubWiseIceScoreData, getLTHeadIceScoreData, ParticipationObj } = props;

  const getColor = (scoreX) => {
    let score = parseFloat(scoreX);
    let color = "#7e58c0";
    if (score >= 0 && score < 75) {
      color = "#ef5350";
    } else if (score >= 75 && score < 85) {
      color = "#ffa726";
    } else if (score >= 85 && score <= 100) {
      color = "#66bb6a";
    }
    return color
  }


  const getMainIndexScore = (list) => {
    let score = 0;
    if (list && list.length > 0) {
      score = list[0]["finalScore"]
    }
    return score > 0 ? parseFloat(score).toFixed(0) : 0
  }

  const option = {
    series: [
      {
        type: 'pie',
        radius: ['60%', '90%'], // Adjust thickness of the circular progress bar
        startAngle: 225, // Start angle of the progress (0 degrees is the top)
        endAngle: 45, // End angle to create a full circle effect
        data: [
          {
            value: getMainIndexScore(getLTHeadIceScoreData),
            name: 'Progress',
            itemStyle: {
              color: getColor(getMainIndexScore(getLTHeadIceScoreData)), // Progress color
              borderRadius: '50%', // Simulate border-radius (but this won't directly apply)
            },
          },
          {
            value: 100 - getMainIndexScore(getLTHeadIceScoreData),
            name: 'Remaining',
            itemStyle: {
              color: '#7E57C214', // Background color
            },
          },
        ],
        label: {
          show: true,
          position: 'center',
          formatter: `{d}%`, // Display percentage value in the center
          fontSize: 16,
          fontWeight: 'bold',
          color: '#212121', // Text color
          fontFamily: 'Poppins, sans-serif', // Title font family
        },
        labelLine: {
          show: false, // Hide the line connecting the label
        },
        emphasis: {
          scale: false, // Disable scaling on hover
        },
      },
    ],
  };


  const graphOption = (score) => {
    const optionCircleSmall = {
      series: [
        {
          type: 'pie',
          radius: ['60%', '80%'], // Adjust thickness of the circular progress bar
          startAngle: 225, // Start angle of the progress (0 degrees is the top)
          endAngle: 45, // End angle to create a full circle effect
          data: [
            {
              value: parseFloat(score),
              name: 'Progress',
              itemStyle: {
                color: getColor(parseFloat(score)), // Progress color
                borderRadius: '50%', // Simulate border-radius (but this won't directly apply)
              },
            },
            {
              value: 100 - parseFloat(score),
              name: 'Remaining',
              itemStyle: {
                color: '#7E57C214', // Background color
              },
            },
          ],
          label: {
            show: true,
            position: 'center',
            formatter: `{d}%`, // Display percentage value in the center
            fontSize: 12,
            fontWeight: 'bold',
            color: '#212121', // Text color
            fontFamily: 'Poppins, sans-serif', // Title font family
          },
          labelLine: {
            show: false, // Hide the line connecting the label
          },
          emphasis: {
            scale: false, // Disable scaling on hover
          },
        },
      ],
    };
    return optionCircleSmall
  }

  const getScore = (type, ele, para) => {
    let score = 0;
    if (type === "dimension") {
      if (ele && ele.dimensionScores && ele.dimensionScores.length > 0) {
        let getIndex = ele.dimensionScores.findIndex(prev => prev.parameterDimensionName === para);
        if (getIndex !== -1) {
          score = ele.dimensionScores[getIndex]["score"]
        }
      }
    } else if (type === "index") {
      if (ele && ele.indexScore && ele.indexScore.length > 0) {
        score = ele.indexScore[0]["finalScore"]
      }
    }
    return score > 0 ? parseFloat(score).toFixed(0) : 0
  }


  let paraList = []
  if (getLTHeadIceSubWiseIceScoreData && getLTHeadIceSubWiseIceScoreData.length > 0) {
    getLTHeadIceSubWiseIceScoreData.map((ele) => {
      if (ele && ele.dimensionScores && ele.dimensionScores.length > 0) {
        ele.dimensionScores.map((inner) => {
          let getIndex = paraList.findIndex(prev => prev === inner.parameterDimensionName);
          if (getIndex === -1) {
            paraList.push(inner.parameterDimensionName);
          }
        })
      }
    })
  }




  function spliceDecimal(no) {
    if (no) {
      let str = no.toString();
      let first = str.split('.')[0];
      let second = str.split('.')[1];
      let second2 = second ? second.substring(0, 2) : ""
      let final = first + '.' + second2;
      if (second2) {
        return parseFloat(final).toFixed(2)
      } else {
        return no
      }
    } else {
      return 0
    }
  }


  let indexScore = getIceIndexData && getIceIndexData[IndexNumber] ? spliceDecimal(getIceIndexData[IndexNumber]) : 0;




  const sortingList = (list) => {
    let sortedList = []
    if (list && list.length > 0) {
      sortedList = list.sort((a, b) => {
        return ('' + a.xAxis).localeCompare(('' + b.xAxis));
      });
    }
    return sortedList
  }


  function chunkArray(array, chunkSize) {
    let result = [];
    let i = 0;
    while (i < array.length) {
      let chunk = array.slice(i, i + chunkSize);
      result.push(chunk);
      i += chunkSize;
      chunkSize = crrSubReportType === "Unit" ? 6 : 9;
    }
    return result;
  }
  let size = 3;

  let finalList = getLTHeadIceSubWiseIceScoreData && getLTHeadIceSubWiseIceScoreData.length > 0 ? chunkArray(sortingList(getLTHeadIceSubWiseIceScoreData), size) : [];

  // console.log('finalListfinalListfinalListfinalListfinalListfinalListfinalListfinalListfinalListfinalListfinalListfinalListfinalListfinalList', finalList);

  const getFavourableScore = (item) => {
    let favSum = 0;
    let totalSum = 0;
    if (item && item.answers && item.answers.length > 0) {
      item.answers.forEach((inner) => {
        if (["Agree", "Strongly Agree"].includes(inner.answerText)) {
          favSum += inner.count;
        }
        totalSum += inner.count;
      })
    }
    let score = totalSum > 0 ? ((favSum * 100) / totalSum).toFixed(0) : "0";
    return score
  }

  const scoreRoundOff = (score) => {
    return score > 0 ? parseFloat(score).toFixed(0) : 0
  }

  function overllScore(para, xlist, type) {
    let score = 0;
    if (xlist && xlist.length > 0) {
      let getIndex = xlist.findIndex(prev => prev._id === para);
      if (getIndex !== -1) {
        score = type === "org" ? getFavourableScore(xlist[getIndex]) : scoreRoundOff(xlist[getIndex]["score"])
      }
    }

    return score
  }


  console.log("getLTHeadIceScoreData----121---->", getLTHeadIceScoreData);

  const renamingFn = (type, value, mode) => {
    if (type === "Unit") {
      return mode === 2 ? ("BU Unit" + "    " + "(Overall Dept.)") : "BU Unit"
    } else {
      return value
    }
  }


  return (
    <>
      {finalList && finalList.length > 0 &&
        finalList.map((innerList, index) =>
          <section className='w-full p-6 px-6 flex flex-col justify-between page-break'>
            <Header createdAt={createdAt} userName={userName} />
            <div className="flex flex-col space-y-6 w-full">
              {/* <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">{index ? "Respondent summary (Continue)" : "Respondent summary"}</h1> */}
              <h1 className="text-2xl font-medium capitalize bg-[#212121]/90 text-white p-4">
                {"ICE Index Score" + (index > 0 ? " (Continue)" : "")}
              </h1>

              {index === 0 && <>
                <div className="flex items-center justify-between  w-full">
                  <p className='text-xl font-bold text-[#212121]'>{renamingFn(crrSubReportType, crrSubReportType)} ICE Index Score</p>
                  <div className="flex flex-wrap gap-x-6 gap-y-2 justify-start items-center text-xs text-[#212121]  ">
                    <div className="flex items-center">
                      <div className="px-3 py-1.5 rounded-sm bg-[#ef5350]" />
                      <p className="ml-1.5 text-sm">{"<75%"}</p>
                    </div>
                    <div className="flex items-center">
                      <div className="px-3 py-1.5 rounded-sm bg-[#ffa726]" />
                      <p className="ml-1.5 text-sm">{">=75% & <85%"}</p>
                    </div>
                    <div className="flex items-center ">
                      <div className="px-3 py-1.5 rounded-sm bg-[#66bb6a]" />
                      <p className="ml-1.5 text-sm">{">=85%"}</p>
                    </div>
                  </div>
                </div>
                <div className='space-x-4  flex items-center'>

                  <div className="flex justify-center  space-y-4">
                    <div className=" w-60 flex-shrink-0">
                      <ReactEcharts option={option} style={{ height: '230px', width: '230px' }} />
                      {/* <p className="text-sm text-center font-bold text-[#212121]">Organization Index Score: {indexScore}</p> */}
                      <p className="text-sm text-center font-normal text-[#212121] whitespace-nowrap"> *Note: ICE (Internal Customer Engagement)</p>
                    </div>
                  </div>

                  <div className="w-full space-y-6  pl-4 pb-6">
                    {/* <p className='text-xl font-bold text-[#212121]'>{"ICE Index Themes Score (in %)"}</p> */}

                    {/* <table className="w-full">

                      <tr className="bg-[#F1F5F9]">
                        <td className="text-[#212121] w-40 flex-shrink-0 font-medium text-left text-sm p-2 border-b-2 border-r-2 border-white">{crrSubReportType}</td>
                        <tr className="bg-[#F1F5F9]"  >
                          <td className="text-[#212121] w-40 flex-shrink-0 font-medium text-left text-sm p-2 border-b-2 border-r-2 border-white">Organization</td>
                          <td className="w-[60%] p-2 border-b-2 border-white">
                            <div className="bg-[#7e58c0]/10 w-full h-7 overflow-hidden flex items-center space-x-2">
                              <div
                                className="h-7 w-[50%] bg-red-500"
                                style={{ width: scoreRoundOff(indexScore) + "%", background: getColor(scoreRoundOff(indexScore)) }}
                              />
                            </div>
                          </td>
                          <td className="w-[60%] p-2 border-b-2 border-white">
                            <div className="bg-[#7e58c0]/10 w-full h-7 overflow-hidden flex items-center space-x-2">
                              <div
                                className="h-7 w-[50%] bg-red-500"
                                style={{ width: getMainIndexScore(getLTHeadIceScoreData) + "%", background: getColor(getMainIndexScore(getLTHeadIceScoreData)) }}
                              />
                            </div>
                          </td>
                          <td className="font-medium w-10 text-left text-sm p-2 border-b-2 border-r-2 border-white">{getMainIndexScore(getLTHeadIceScoreData) + "%"}</td>
                        </tr>
                        <td className="font-medium w-10 text-left text-sm p-2 border-b-2 border-r-2 border-white">{scoreRoundOff(indexScore).toString() + "%"}</td>
                      </tr>
                    </table> */}



                    <table className="w-full">
                      <tr className="bg-[#F1F5F9]"  >
                        <td className="text-[#212121] w-40 flex-shrink-0 font-bold text-left text-sm p-2 border-b-2 border-r-2 border-white">Organization</td>
                        <td className="w-[60%] p-2 border-b-2 border-white">
                          <div className="bg-[#7e58c0]/10 w-full h-7 overflow-hidden flex items-center space-x-2">
                            <div
                              className="h-7 w-[50%] bg-red-500"
                              style={{ width: scoreRoundOff(indexScore) + "%", background: getColor(scoreRoundOff(indexScore)) }}
                            />
                          </div>
                        </td>
                        <td className="font-medium w-10 text-left text-sm p-2 border-b-2 border-r-2 border-white">{scoreRoundOff(indexScore).toString() + "%"}</td>
                      </tr>
                      <tr className="bg-[#F1F5F9]">
                        <td className="text-[#212121] w-40 flex-shrink-0 font-bold text-left text-sm p-2 border-b-2 border-r-2 border-white">{renamingFn(crrSubReportType, crrSubReportType)}</td>
                        <td className="w-[60%] p-2 border-b-2 border-white">
                          <div className="bg-[#7e58c0]/10 w-full h-7 overflow-hidden flex items-center space-x-2">
                            <div
                              className="h-7 w-[50%] bg-red-500"
                              style={{ width: getMainIndexScore(getLTHeadIceScoreData) + "%", background: getColor(getMainIndexScore(getLTHeadIceScoreData)) }}
                            />
                          </div>
                        </td>
                        <td className="font-medium w-10 text-left text-sm p-2 border-b-2 border-r-2 border-white">{getMainIndexScore(getLTHeadIceScoreData) + "%"}</td>
                      </tr>
                    </table>



                  </div>
                </div>
              </>}


              <table className="w-full bg-[#F1F5F9] ">
                <thead className="bg-[#EAEFF4] ">
                  <th rowSpan={2} className="p-2 px-4 text-base font-bold text-[#212121] text-left border-r-2 border-b-2 border-white w-36">Department</th>
                  <th rowSpan={2} className="p-2 text-base font-bold text-[#212121] border-r-2 border-b-2 border-white  w-24">ICE Index
                    Score</th>
                  <th rowSpan={1} colSpan={6} className="p-2 py-3 text-base font-bold text-[#212121]  border-b-2 border-white">Themes Score</th>
                  <tr className="border-r-2 border-white ">
                    {paraList && paraList.length > 0 && paraList.map((para) =>
                      <th colSpan={1} className="p-2 py-3 text-xs font-bold text-[#212121] w-20 border-r-2 border-b-2 border-white ">{GetParameterLabel(para)}</th>
                    )}
                  </tr>
                </thead>
                <tbody className="">

                  <tr className="text-sm font-normal   border-white ">
                    <td className="p-2 px-4 font-bold text-left border-r-2 border-b-2 border-white">Organization</td>
                    <td className="p-2 text-center border-r-2 border-b-2 border-white">
                      <div className="flex justify-center">
                        <ReactEcharts option={graphOption(scoreRoundOff(indexScore))} style={{ height: '65px', width: '65px' }} />
                      </div>
                    </td>

                    {paraList && paraList.length > 0 &&
                      paraList.map((para) =>
                        <td className="p-2 text-center border-r-2 border-b-2 border-white"
                          style={{ backgroundColor: getColor(overllScore(para, getIceDistributionData, "org")) }}
                        >{overllScore(para, getIceDistributionData, "org") + "%"}</td>
                      )}
                  </tr>

                  <tr className="text-sm font-normal   border-white ">
                    <td className="p-2 px-4 font-bold text-left border-r-2 border-b-2 border-white">{renamingFn(crrSubReportType, "LT Head" + "    " + "(Overall Dept.)", 2)}</td>
                    <td className="p-2 text-center border-r-2 border-b-2 border-white">
                      <div className="flex justify-center">
                        <ReactEcharts option={graphOption(getMainIndexScore(crrSubReportType === "LT Head" || crrSubReportType === "Unit" ? getLTHeadIceScoreData : getLTHeadIceScoreV3Data))} style={{ height: '65px', width: '65px' }} />
                      </div>
                    </td>

                    {paraList && paraList.length > 0 &&
                      paraList.map((para) =>
                        <td className="p-2 text-center border-r-2 border-b-2 border-white"
                          style={{ backgroundColor: getColor(overllScore(para, crrSubReportType === "LT Head" ? getLTHeadIceParaScoreData : getLTHeadIceParaScoreV3Data, "score")) }}
                        >{overllScore(para, crrSubReportType === "LT Head" ? getLTHeadIceParaScoreData : getLTHeadIceParaScoreV3Data, "score") + "%"}</td>
                      )}
                  </tr>

                  {innerList && innerList.length > 0 && innerList.map((ele) =>
                    <>
                      <tr className="text-sm font-normal border-white ">
                        <td className="p-2 px-4 font-normal text-left border-r-2 border-b-2 border-white">{ele && ele.xAxis}</td>
                        <td className="p-2 text-center border-r-2 border-b-2 border-white">
                          <div className="flex justify-center">
                            <ReactEcharts option={graphOption(getScore("index", ele))} style={{ height: '65px', width: '65px' }} />
                          </div>
                        </td>
                        {paraList && paraList.length > 0 &&
                          paraList.map((para) =>
                            <td className="p-2 text-center border-r-2 border-b-2 border-white"
                              style={{ backgroundColor: getColor(getScore("dimension", ele, para)) }}
                            >{getScore("dimension", ele, para)}</td>
                          )}
                      </tr>
                    </>
                  )}
                </tbody>
              </table>

            </div>
          </section>
        )}

    </>

  );
}

export default ICEIndexSummary;
