import React from 'react';
import ReactEcharts from "echarts-for-react";
import { graphic } from 'echarts';


export default function BarDistribution(props) {
  let { DistributionData } = props;

  let List = ['Unhappy', 'ModeratelyHappy', 'Happy', 'Happiest'];

  let labelObj = {
    'Unhappy': `Unhappy`,

    'ModeratelyHappy': `Moderate`,

    'Happier': `Happier`,

    'Happiest': `Happiest`
  }

  let colorObj = {
    'Unhappy': '#e53935',
    'ModeratelyHappy': '#ffc107',
    'Happier': '#a2e37d',
    'Happiest': '#00cc66'
  }

  // let DistObj = {}

  let ScoreList = []
  let DistObj = {}
  let names = []
  if (DistributionData) {
    if (List && List.length > 0) {
      List.forEach((item) => {
        let name = item === "Happy" ? "Happier" : item;
        ScoreList.push({
          "name": name,
          "score": DistributionData && DistributionData[item] && parseFloat(DistributionData[item]) ? parseFloat(DistributionData[item]) : 0
        })
        DistObj[name] = DistributionData[item] ? DistributionData[item].toFixed(2) : 0;
        names.push(labelObj[name])
      });
    }
  }

  // ScoreList.sort((a,b)=>a.score - b.score);

  // let data = [];
  // let names = []
  // if(ScoreList && ScoreList.length>0){
  //   ScoreList.forEach((item)=>{
  //     data.push({
  //       value:item.score && item.score.toFixed(2)?item.score.toFixed(2):0,
  //       itemStyle:{
  //         color:colorObj && colorObj[item.name]?colorObj[item.name]:'#757575'
  //       }
  //     })
  //     names.push(item.name === "Happy"?"Happier":labelObj[item.name])
  //   });
  // }


  //console.log('x---names: ',names)


  // //console.log('DistObj: ',DistObj)



  let data = [
    {
      value: DistObj['Unhappy'],
      itemStyle: {
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#f1c0c0'
          },
          {
            offset: 1,
            color: '#e53935'
          }])
      }
    },
    {
      value: DistObj['ModeratelyHappy'],
      itemStyle: {
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#fbe2b6'
          },
          {
            offset: 1,
            color: '#ffc107'
          }])
      }
    },
    {
      value: DistObj['Happier'],
      itemStyle: {
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#d2f2c0'
          },
          {
            offset: 1,
            color: '#a2e37d'
          }])
      }
    },
    {
      value: DistObj['Happiest'],
      itemStyle: {
        // color:'#00cc66',
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#b3ffd9'
          },
          {
            offset: 1,
            color: '#00cc66'
          }])
      }
    },
  ]




  return (
 
    <ReactEcharts
      className='!w-full'
      option={{
        tooltip: {
          trigger: 'item',
          formatter: function (a) {
            //console.log(a)
            return a.name + ': <span>' + a.value + '%</span>'
          }
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: "#000",
            textStyle: {
              fontSize: 12,
              fontWeight: 'normal'
            },
          },
          axisLine: {
            lineStyle: {
              color: '#e6e6e6'
            }
          },
          data: names
        },
        yAxis: {
          type: 'value',
          max: 100,
          splitLine: {
            lineStyle: {
              color: '#f2f2f2'
            }
          },
          axisLabel: {
            color: "#000",
          }
        },
        series: [{
          data: data,
          type: 'bar',
          barWidth: '50px',
          showBackground: true,
          label: {
            show: true,
            position: 'top',
            color: "#212121",
            textStyle: {
              fontSize: 12,
              fontWeight: '600'
            },
            formatter: function (obj) {
              console.log("value---1->", obj.value)
              return obj.value > 0 ? obj.value + '%' : "0";
            }
          },

        }],
        grid: {
          left: '1%',
          right: '1%',
          bottom: '0%',
          top: '10px',
          containLabel: true
        },
      }}
    />


  );
}
