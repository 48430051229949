import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../Components/Header';


class FeedbackResponseTopicDesc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',

      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: '',
      crrSelectedEmp: {}
    }
  }

  componentDidMount() {

  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { get360Overview } = this.props;
    this.setState({ FilterValues: finalFilter });
    get360Overview(finalFilter, "", "", false);

  }

  handleSelectEmployee = (value) => {
    this.setState({ crrSelectedEmp: value }, () => {
      let { get360Overview } = this.props;

      let emp_id = "";
      if (value && value.emp_id) {
        emp_id = value.emp_id;
      } else {
        emp_id = value.emp_id;
      }
      let { FilterValues } = this.state;
      get360Overview(FilterValues, "", "", false, emp_id)

    })
  }


  render() {


    let { getSubWiseParaScoreData, userName, createdAt, title, type } = this.props;


    let colorCode = "#ECEFF1";
    if (type === "Strengths") {
      colorCode = "#4CAF50"
    } else if (type === "Improvement") {
      colorCode = "#FFA000"
    }

    let sizeChunk = 6;
    function chunkArray(array, chunkSize) {
      let result = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        let chunk = array.slice(i, i + chunkSize);
        result.push(chunk);
      }
      return result;
    }


    const sortingList = (list) => {
      let sortedList = []
      if (list && list.length > 0) {
        sortedList = list.sort((a, b) => {
          return ('' + a.xAxis).localeCompare(('' + b.xAxis));
        });
      }
      return sortedList
    }


    return (
      <>
        {getSubWiseParaScoreData && getSubWiseParaScoreData.length > 0 &&
          sortingList(getSubWiseParaScoreData).map((dataX) =>
            <>
              {dataX && dataX.feedAnswers && dataX.feedAnswers.length > 0 &&
                chunkArray(dataX.feedAnswers, sizeChunk).map((listxy, index) =>
                  <>
                    <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>

                      <Header createdAt={createdAt} userName={userName} />

                      <div className="flex flex-col space-y-4 w-full ">
                        <h1 className="text-2xl font-medium bg-[#212121]/90 text-white p-4">
                          {(index ? title + " - " + (dataX && dataX.xAxis ? "Department: " + dataX.xAxis : "") + " (Continue)" : title + " - " + (dataX && dataX.xAxis ? "Department: " + dataX.xAxis : ""))}

                        </h1>


                        <div className='text-[#212121] space-y-4 text-sm'>
                          {/* <p className='text-[#212121] italic'> This section provides verbatim comments from all respondents. You will gain the most value if you pay attention to the frequently occurring topics and suggestions. Try to view the information objectively and reconcile it with the information in the previous rating sections.</p> */}

                          <div>
                            {/* <h1 className='font-medium text-[#212121] text-lg'>{"Q." + (index + 1).toString() + " " + (ele && ele.question)}</h1> */}

                            {listxy && listxy.length > 0 ?
                              listxy.map((text, index2) =>
                                <div class="space-x-4 flex items-end py-2">
                                  {/* <div>
                                    <div className='bg-green-500 flex items-center justify-center h-8 w-8 rounded-full p-1' style={{ background: getRandomColor() }}>
                                      <img src='/img/profile.svg' />
                                    </div>
                                  </div> */}
                                  <div className=' rounded-tl-xl rounded-r-xl p-4 w-full '
                                    style={{
                                      background: `${colorCode}1A`,
                                    }}
                                  >
                                    <div className=' space-y-2 px-2'>
                                      <p class="text-sm text-slate-600 font-bold">{text && text.topic}   </p>

                                      <p class="text-sm text-slate-600">{text && text.detail}</p>
                                    </div>
                                    <p class="text-sx text-gray-400">{text && text.feedback}</p>
                                  </div>
                                </div>
                              ) : null}

                          </div>



                        </div>


                      </div>
                    </section>
                  </>
                )}
            </>
          )}
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(FeedbackResponseTopicDesc);
