import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class QuestionStack extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { colorTheme, question, getQuestionFilterData, currFilterValue, currFilterName } = this.props;


    //console.log('question:--sss-x> ',question)


    let filterQuestion = {}
    if (question && getQuestionFilterData && getQuestionFilterData.length > 0) {
      getQuestionFilterData.forEach((quesFilter) => {
        if (quesFilter.name === question.name) {
          filterQuestion = quesFilter;
        }
      })
    }


    let total = 0
    let names = []
    let weightage1 = [];
    let weightage2 = [];
    let weightage3 = [];
    let weightage4 = [];
    let weightage5 = [];
    let weightage6 = [];

    // let ColorList = ["#757575","#e60000","#ff3333","#ff8080","#99e699","#70db70","#33cc33"];
    let ColorList = ["#757575", "#ff6600", "#ff944d", "#ffb380", "#9fbfdf", "#6699cc", "#336699"];

    if (colorTheme === 2) {
      ColorList = ["#757575", "#ff6600", "#ff944d", "#ffb380", "#9fbfdf", "#6699cc", "#336699"];
    }
    else if (colorTheme === 1) {
      ColorList = ["#757575", "#e60000", "#ff3333", "#ff8080", "#99e699", "#70db70", "#33cc33"];
    }
    let weightage1All = []



    function getColor2 (name){
      let color = ""
      if(name === "Strongly Disagree"){
        color = ColorList[1]
      }
      else if(name === "Disagree"){
        color = ColorList[2]
      }
      else if(name === "Slightly Disagree"){
        color = ColorList[3]
      }
      else if(name === "Slightly Agree"){
        color = ColorList[4]
      }
      else if(name === "Agree"){
        color = ColorList[5]
      }
      else if(name === "Strongly Agree"){
        color = ColorList[5]
      }
      return color
    }

    if (question) {
      // total = question["1"] + question["2"] + question["3"] + question["4"] + question["5"] + question["6"];
      
      total = question["count"];

      let answerKeys = question && question.answers?Object.keys(question.answers):{};
      if(answerKeys && answerKeys.length>0){
        answerKeys.forEach((key,index)=>{
          let valueData = {
            value: (parseFloat(question["answers"][key]) * 100 / total).toFixed(2),
            itemStyle: { color: ColorList[index+1] }
          }

          if(getColor2(key)){
            valueData["itemStyle"] = { color: getColor2(key) }
          }


          weightage1All.push({
            name: key,
            type: 'bar',
            stack: '总量',
            barWidth: '30px',
            label: {
              show: true,
              position: 'insideRight',
              formatter: function (d) {
                if (parseInt(d.value) < 7) {
                  return '';
                }
                else {
                  return d.value;
                }
              }
            },
            data: [valueData],
            inverse: true
          });
        })
      }

      
      names.push({
        value: "Overall",
        textStyle: { fontWeight: 'bold' }
      })

    }


    // //console.log('overallQuestion:---> ',question)
    // //console.log('filterQuestion:---> ',filterQuestion)

    // if(currFilterValue !=='none'){
    //     if(currFilterName !=='none'){
    //         total = filterQuestion["1"] + filterQuestion["2"] + filterQuestion["3"] + filterQuestion["4"] + filterQuestion["5"] + filterQuestion["6"];
    //         names.push({
    //             value:currFilterValue,
    //             textStyle:{fontWeight:'bold'}
    //         })
    //         weightage1.push({
    //             value:(parseFloat(filterQuestion["1"])*100/total).toFixed(2),
    //             itemStyle: {color: '#e60000'}
    //         });
    //         weightage2.push({
    //             value:(parseFloat(filterQuestion["2"])*100/total).toFixed(2),
    //             itemStyle: {color: '#ff3333'}
    //         });
    //         weightage3.push({
    //             value:(parseFloat(filterQuestion["3"])*100/total).toFixed(2),
    //             itemStyle: {color: '#ff8080'}
    //         });
    //         weightage4.push({
    //             value:(parseFloat(filterQuestion["4"])*100/total).toFixed(2),
    //             itemStyle: {color: '#6ad4fb'}

    //         });
    //         weightage5.push({
    //             value:(parseFloat(filterQuestion["5"])*100/total).toFixed(2),
    //             itemStyle: {color: '#1fbff9'}
    //         });
    //         weightage6.push({
    //             value:(parseFloat(filterQuestion["6"])*100/total).toFixed(2),
    //             itemStyle: {color: '#06a6e0'}
    //         });
    //     }
    // }






    return (
      <>
        <div style={{ zIndex: '' }}>

          {true ?
            <ReactEcharts
              style={{ height: '37px', marginTop: '6px', width: '100%' }}
              option={{
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            
                        type: 'shadow',        
                    }
                },
                // grid: {
                //     left: '3%',
                //     right: '4%',
                //     bottom: '3%',
                //     containLabel: true
                // },
                xAxis: {
                  type: 'value',
                  axisLine: 'left',
                  splitLine: {
                    lineStyle: {
                      color: '#fff'
                    }
                  },
                  axisLabel: {
                    show: false
                  },
                  max: 100

                },
                yAxis: {
                  type: 'category',
                  data: names,
                  position: 'left',
                  inverse: true,
                  axisLine: {
                    lineStyle: {
                      color: '#fff'
                    }
                  },
                  axisLabel: {
                    show: false
                  }
                },
                axisLabel: {
                  color: "#000",
                  interval: 0,
                  formatter: function (value) {
                    if (value.length > 20) {
                      return value.substring(0, 20) + ".. :";
                    } else {
                      return value + ":";
                    }
                  }
                },
                series: weightage1All
              }}
            />
            : null}

        </div>

        {/* {/* <div
          className="tooltip-response2"
        >
          <div style={{
            paddingLeft: '10px',
            paddingTop: '10px',

          }}>{(currFilterValue !== 'none') ? currFilterValue : "Overall"}</div>
          <div style={{
            paddingLeft: '15px',
            paddingTop: '7px'
          }}>
            <div style={{
              width: '10px',
              height: '10px',
              background: ColorList[1],
              borderRadius: '50%',
              float: 'left',
              marginTop: '3px'
            }}></div>
            <div style={{
              marginLeft: '12px',
              marginRight: '20px',
              float: 'left',
              width: '100px'
            }}>{"Strongly Disagree"}</div>
            <div style={{
              marginLeft: '25px',
              fontWeight: 'bold',
              width: '20px'
            }}>{weightage1[0].value + ' %'}</div>

          </div>
          <div style={{
            paddingLeft: '15px',
            paddingTop: '7px'
          }}>
            <div style={{
              width: '10px',
              height: '10px',
              background: ColorList[2],
              borderRadius: '50%',
              float: 'left',
              marginTop: '3px'
            }}></div>
            <div style={{
              marginLeft: '12px',
              marginRight: '20px',
              float: 'left',
              width: '100px'
            }}>{"Disagree"}</div>
            <div style={{
              marginLeft: '25px',
              fontWeight: 'bold'
            }}>{weightage2[0].value + ' %'}</div>

          </div>
          <div style={{
            paddingLeft: '15px',
            paddingTop: '7px'
          }}>
            <div style={{
              width: '10px',
              height: '10px',
              background: ColorList[3],
              borderRadius: '50%',
              float: 'left',
              marginTop: '3px'
            }}></div>
            <div style={{
              marginLeft: '12px',
              marginRight: '20px',
              float: 'left',
              width: '100px'
            }}>{"Slightly Disagree"}</div>
            <div style={{
              marginLeft: '25px',
              fontWeight: 'bold'
            }}>{weightage3[0].value + ' %'}</div>

          </div>
          <div style={{
            paddingLeft: '15px',
            paddingTop: '7px'
          }}>
            <div style={{
              width: '10px',
              height: '10px',
              background: ColorList[4],
              borderRadius: '50%',
              float: 'left',
              marginTop: '3px'
            }}></div>
            <div style={{
              marginLeft: '12px',
              marginRight: '20px',
              float: 'left',
              width: '100px'
            }}>{"Slightly Agree"}</div>
            <div style={{
              marginLeft: '25px',
              fontWeight: 'bold'
            }}>{weightage4[0].value + ' %'}</div>

          </div>
          <div style={{
            paddingLeft: '15px',
            paddingTop: '7px'
          }}>
            <div style={{
              width: '10px',
              height: '10px',
              background: ColorList[5],
              borderRadius: '50%',
              float: 'left',
              marginTop: '3px'
            }}></div>
            <div style={{
              marginLeft: '12px',
              marginRight: '20px',
              float: 'left',
              width: '100px'
            }}>{"Agree"}</div>
            <div style={{
              marginLeft: '25px',
              fontWeight: 'bold'
            }}>{weightage5[0].value + ' %'}</div>

          </div>
          <div style={{
            paddingLeft: '15px',
            paddingTop: '7px'
          }}>
            <div style={{
              width: '10px',
              height: '10px',
              background: ColorList[6],
              borderRadius: '50%',
              float: 'left',
              marginTop: '3px'
            }}></div>
            <div style={{
              marginLeft: '12px',
              marginRight: '20px',
              float: 'left',
              width: '100px'
            }}>{"Strongly Agree"}</div>
            <div style={{
              marginLeft: '25px',
              fontWeight: 'bold'
            }}>{weightage6[0].value + ' %'}</div> */}

          {/* </div> */}

        {/* </div> */}



      </>
    );
  }
}
function mapStateToProps(state) {
  //   //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionStack);
