import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class DriverRadar extends Component {
  constructor(props) {
    super(props);
    this.state = {


    }
  }

  componentDidMount() {

  }


  render() {
    let { get360DimensionRelationScoreData, getQuestionsData } = this.props;



    // let colorList = ["#E57373","#BA68C8","#BA68C8","#9575CD","#7986CB","#64B5F6","#4FC3F7","#4DD0E1","#4DB6AC","#81C784","#DCE775","#FFB74D","#FF8A65","#90A4AE","#F06292"]
    let colorList = ["#FFD54F", "#E57373", "#81C784", "#64B5F6", "F06292",
      "#BA68C8", "#9575CD", "#7986CB", "#4FC3F7", "4DD0E1",
      "#4DB6AC", "#DCE775", "#FFB74D", "#FF8A65", "90A4AE"
    ]


    let colorListA = []
    if (getQuestionsData && getQuestionsData.length > 0) {
      let relation1List = getQuestionsData && getQuestionsData[0] && getQuestionsData[0]["relationList"] ? getQuestionsData[0]["relationList"] : [];
      if (relation1List && relation1List.length > 0) {
        relation1List.forEach((element, index) => {
          colorListA.push({
            "relation": element,
            "color": colorList[index] ? colorList[index] : "#FFD54F"
          })
        })
      }
    }


    console.log("{{{{{{{colorListAgetQuestionsDatagetQuestionsDatagetQuestionsDatagetQuestionsDatagetQuestionsData", getQuestionsData)
    console.log("{{{{{{{colorListAgetQuestionsDatagetQuestionsDatagetQuestionsDatagetQuestionsDatagetQuestionsData", colorListA)

    function getColorByRelation(name) {
      let defaultColor = "#FFD54F";

      let getIndex = colorListA && colorListA.length > 0 ? colorListA.findIndex(prev => prev.relation === name) : -1;
      if (getIndex !== -1) {
        console.log("colorListA[getIndex]---->", colorListA[getIndex])
        defaultColor = colorListA && colorListA[getIndex] && colorListA[getIndex]["color"] ? colorListA[getIndex]["color"] : "#FFD54F"
      }
      console.log("colorListA---->", colorListA)
      console.log("name---->", name)

      return defaultColor
    }






    let ValueList = [];
    let DimensionList = [];
    let RelationList = [];
    let RelationListMain = [];



    if (get360DimensionRelationScoreData && get360DimensionRelationScoreData.length > 0) {
      let listRelationScore = get360DimensionRelationScoreData;

      try {
        listRelationScore = get360DimensionRelationScoreData.sort((b, a) => (a._id.length - b._id.length));
      } catch (e) {
        listRelationScore = get360DimensionRelationScoreData;
      }

      listRelationScore.forEach((item) => {

        let dimension1 = item && item._id ? item._id : "";

        let dimension = dimension1;


        let temp = {
          "dimen_xyz": dimension
        }
        if (item && item.total && item.total.length > 0) {
          item.total.forEach((inner) => {
            if (inner && inner._id && inner._id.relation) {

              let scoreMean1 = inner && inner.mean ? inner.mean : 0;

              let scoreMean = scoreMean1.toFixed(2);

              temp[inner._id.relation] = scoreMean;

              let getIndex2 = RelationList.findIndex(prev => prev.name === inner._id.relation);
              if (getIndex2 === -1) {
                let tempx = {
                  name: inner._id.relation,
                  value: [scoreMean]
                }
                RelationList.push(tempx);
                RelationListMain.push(tempx)

              } else {
                RelationList[getIndex2]["value"].push(scoreMean)
              }



            }
          })
        }


        DimensionList.push({ name: dimension, max: 6 })



        ValueList.push(temp)
      })
    }


    console.log('-------->>>>get360DimensionRelationScoreData; ', get360DimensionRelationScoreData)



    let colorDataList = []
    if (RelationListMain && RelationListMain.length > 0) {
      RelationListMain.forEach((ele) => {
        colorDataList.push(getColorByRelation(ele.name));
      })
    }

    // console.log("{{{{{{{colorListAgetQuestionsDatagetQuestionsDatagetQuestionsDatagetQuestionsDatagetQuestionsData", colorDataList)


    let data = [

      {
        name: 'Analytical\nThinking',
        itemStyle: {
          color: '#40679E'
        },
        children: [
          {
            name: 'Self',
            itemStyle: {
              color: '#40679E'
            },
            children: [
              {
                name: 'self',
                value: 4.2,
                itemStyle: {
                  color: '#40679E'
                }
              }
            ]
          },
          {
            name: 'Peers',
            itemStyle: {
              color: '#40679E'
            },
            children: [
              {
                name: 'Peers',
                value: 4.1,
                itemStyle: {
                  color: '#40679E'
                }
              }
            ]
          },
          {
            name: 'Manager',
            itemStyle: {
              color: '#40679E'
            },
            children: [
              {
                name: 'Manager',
                value: 4,
                itemStyle: {
                  color: '#40679E'
                }
              }
            ]
          },
          {
            name: 'Reportee',
            itemStyle: {
              color: '#40679E'
            },
            children: [
              {
                name: 'Reportee',
                value: 3.5,
                itemStyle: {
                  color: '#40679E'
                }
              }
            ]
          },

        ]
      },

      {
        name: 'Analytical\nThinking1',
        itemStyle: {
          color: '#FAA300'
        },
        children: [
          {
            name: 'Self',
            itemStyle: {
              color: '#FAA300'
            },
            children: [
              {
                name: 'self',
                value: 4.2,
                itemStyle: {
                  color: '#FAA300'
                }
              }
            ]
          },
          {
            name: 'Peers',
            itemStyle: {
              color: '#FAA300'
            },
            children: [
              {
                name: 'Peers',
                value: 4.1,
                itemStyle: {
                  color: '#FAA300'
                }
              }
            ]
          },
          {
            name: 'Manager',
            itemStyle: {
              color: '#FAA300'
            },
            children: [
              {
                name: 'Manager',
                value: 4,
                itemStyle: {
                  color: '#FAA300'
                }
              }
            ]
          },
          {
            name: 'Reportee',
            itemStyle: {
              color: '#FAA300'
            },
            children: [
              {
                name: 'Reportee',
                value: 3.5,
                itemStyle: {
                  color: '#FAA300'
                }
              }
            ]
          },

        ]
      },
      {
        name: 'Customer\nFocus',
        itemStyle: {
          color: '#EEEEEEE'
        },
        children: [
          {
            name: 'Self',
            itemStyle: {
              color: '#EEEEEEE'
            },
            children: [
              {
                name: 'self',
                value: 4.2,
                itemStyle: {
                  color: '#EEEEEEE'
                }
              }
            ]
          },
          {
            name: 'Peers',
            itemStyle: {
              color: '#EEEEEEE'
            },
            children: [
              {
                name: 'Peers',
                value: 4.1,
                itemStyle: {
                  color: '#EEEEEEE'
                }
              }
            ]
          },
          {
            name: 'Manager',
            itemStyle: {
              color: '#EEEEEEE'
            },
            children: [
              {
                name: 'Manager',
                value: 4,
                itemStyle: {
                  color: '#EEEEEEE'
                }
              }
            ]
          },
          {
            name: 'Reportee',
            itemStyle: {
              color: '#EEEEEEE'
            },
            children: [
              {
                name: 'Reportee',
                value: 3.5,
                itemStyle: {
                  color: '#EEEEEEE'
                }
              }
            ]
          },

        ]
      },

      {
        name: 'Customer\nFocus1',
        itemStyle: {
          color: '#294B29'
        },
        children: [
          {
            name: 'Self',
            itemStyle: {
              color: '#294B29'
            },
            children: [
              {
                name: 'self',
                value: 4.2,
                itemStyle: {
                  color: '#294B29'
                }
              }
            ]
          },
          {
            name: 'Peers',
            itemStyle: {
              color: '#294B29'
            },
            children: [
              {
                name: 'Peers',
                value: 4.1,
                itemStyle: {
                  color: '#294B29'
                }
              }
            ]
          },
          {
            name: 'Manager',
            itemStyle: {
              color: '#294B29'
            },
            children: [
              {
                name: 'Manager',
                value: 4,
                itemStyle: {
                  color: '#294B29'
                }
              }
            ]
          },
          {
            name: 'Reportee',
            itemStyle: {
              color: '#294B29'
            },
            children: [
              {
                name: 'Reportee',
                value: 3.5,
                itemStyle: {
                  color: '#294B29'
                }
              }
            ]
          },

        ]
      },

      {
        name: 'Customer\nFocus2',
        itemStyle: {
          color: '#A94438'
        },
        children: [
          {
            name: 'Self',
            itemStyle: {
              color: '#A94438'
            },
            children: [
              {
                name: 'self',
                value: 4.2,
                itemStyle: {
                  color: '#A94438'
                }
              }
            ]
          },
          {
            name: 'Peers',
            itemStyle: {
              color: '#A94438'
            },
            children: [
              {
                name: 'Peers',
                value: 4.1,
                itemStyle: {
                  color: '#A94438'
                }
              }
            ]
          },
          {
            name: 'Manager',
            itemStyle: {
              color: '#A94438'
            },
            children: [
              {
                name: 'Manager',
                value: 4,
                itemStyle: {
                  color: '#A94438'
                }
              }
            ]
          },
          {
            name: 'Reportee',
            itemStyle: {
              color: '#A94438'
            },
            children: [
              {
                name: 'Reportee',
                value: 3.5,
                itemStyle: {
                  color: '#A94438'
                }
              }
            ]
          },

        ]
      },

      {
        name: 'Champion\nInnovation',
        itemStyle: {
          color: '#FF9843'
        },
        children: [
          {
            name: 'Self',
            itemStyle: {
              color: '#FF9843'
            },
            children: [
              {
                name: 'self',
                value: 4.2,
                itemStyle: {
                  color: '#FF9843'
                }
              }
            ]
          },
          {
            name: 'Peers',
            itemStyle: {
              color: '#FF9843'
            },
            children: [
              {
                name: 'Peers',
                value: 4.1,
                itemStyle: {
                  color: '#FF9843'
                }
              }
            ]
          },
          {
            name: 'Manager',
            itemStyle: {
              color: '#FF9843'
            },
            children: [
              {
                name: 'Manager',
                value: 4,
                itemStyle: {
                  color: '#FF9843'
                }
              }
            ]
          },
          {
            name: 'Reportee',
            itemStyle: {
              color: '#FF9843'
            },
            children: [
              {
                name: 'Reportee',
                value: 3.5,
                itemStyle: {
                  color: '#FF9843'
                }
              }
            ]
          },

        ]
      },
      {
        name: 'Champion\nInnovation1',
        itemStyle: {
          color: '#3468C0'
        },
        children: [
          {
            name: 'Self',
            itemStyle: {
              color: '#3468C0'
            },
            children: [
              {
                name: 'self',
                value: 4.2,
                itemStyle: {
                  color: '#3468C0'
                }
              }
            ]
          },
          {
            name: 'Peers',
            itemStyle: {
              color: '#3468C0'
            },
            children: [
              {
                name: 'Peers',
                value: 4.1,
                itemStyle: {
                  color: '#3468C0'
                }
              }
            ]
          },
          {
            name: 'Manager',
            itemStyle: {
              color: '#3468C0'
            },
            children: [
              {
                name: 'Manager',
                value: 4,
                itemStyle: {
                  color: '#3468C0'
                }
              }
            ]
          },
          {
            name: 'Reportee',
            itemStyle: {
              color: '#3468C0'
            },
            children: [
              {
                name: 'Reportee',
                value: 3.5,
                itemStyle: {
                  color: '#3468C0'
                }
              }
            ]
          },

        ]
      },
      {
        name: 'Champion\nInnovation2',
        itemStyle: {
          color: '#424769'
        },
        children: [
          {
            name: 'Self',
            itemStyle: {
              color: '#424769'
            },
            children: [
              {
                name: 'self',
                value: 4.2,
                itemStyle: {
                  color: '#424769'
                }
              }
            ]
          },
          {
            name: 'Peers',
            itemStyle: {
              color: '#424769'
            },
            children: [
              {
                name: 'Peers',
                value: 4.1,
                itemStyle: {
                  color: '#424769'
                }
              }
            ]
          },
          {
            name: 'Manager',
            itemStyle: {
              color: '#dd4c51'
            },
            children: [
              {
                name: 'Manager',
                value: 4,
                itemStyle: {
                  color: '#424769'
                }
              }
            ]
          },
          {
            name: 'Reportee',
            itemStyle: {
              color: '#424769'
            },
            children: [
              {
                name: 'Reportee',
                value: 3.5,
                itemStyle: {
                  color: '#424769'
                }
              }
            ]
          },

        ]
      },


    ];


    return (
      <>
        <div className="p-4 bg-white border rounded-md cursor-pointer">
          <div className='space-y-6' >
            <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">Spiral Chart</h1>





{/* 
            <ReactEcharts
              className='w-full mx-auto'
              style={{ height: '600px' }}
              option={{
                tooltip: {
                  trigger: 'item',
                  formatter: '{b}: {c}'
                },
                series: {
                  type: 'sunburst',
                  data: data,
                  radius: [0, '95%'],
                  sort: undefined,
                  emphasis: {
                    focus: 'ancestor'
                  },
                  levels: [
                    {},
                    {
                      r0: '10%',
                      r: '65%',
                      itemStyle: {
                        borderWidth: 2
                      },
                      label: {
                        rotate: 'tangential'
                      }
                    },
                    {
                      r0: '45%',
                      r: '70%',
                      label: {
                        align: 'right'
                      }
                    },
                    {
                      r0: '72%',
                      r: '70%',
                      label: {
                        position: 'outside',
                        padding: 3,
                        silent: false
                      },
                      itemStyle: {
                        borderWidth: 3
                      }
                    }
                  ]
                }
              }}
            /> */}










            {/* <ReactEcharts className='w-full mx-auto'
              style={{ height: '500px' }}
              option={{
                tooltip: {
                  trigger: 'item', // This specifies that the tooltip should be triggered by hovering over an item in the chart.
                  formatter: '{b}: {c}' // This is a simple formatter that displays the name of the item and its value.
                },
                // title: {
                //   text: 'WORLD COFFEE RESEARCH SENSORY LEXICON',
                //   subtext: 'Source: https://worldcoffeeresearch.org/work/sensory-lexicon/',
                //   textStyle: {
                //     fontSize: 14,
                //     align: 'center'
                //   },
                //   subtextStyle: {
                //     align: 'center'
                //   },
                //   sublink: 'https://worldcoffeeresearch.org/work/sensory-lexicon/'
                // },
                series: {
                  type: 'sunburst',
                  data: data,
                  radius: [0, '95%'],
                  sort: undefined,
                  emphasis: {
                    focus: 'ancestor'
                  },
                  levels: [
                    {},
                    {
                      r0: '15%',
                      r: '35%',
                      itemStyle: {
                        borderWidth: 2
                      },
                      label: {
                        rotate: 'tangential'
                      }
                    },
                    {
                      r0: '35%',
                      r: '70%',
                      label: {
                        align: 'right'
                      }
                    },
                    {
                      r0: '70%',
                      r: '72%',
                      label: {
                        position: 'outside',
                        padding: 3,
                        silent: false
                      },
                      itemStyle: {
                        borderWidth: 3
                      }
                    }
                  ]
                }
              }}
            />
 */}

            {get360DimensionRelationScoreData && get360DimensionRelationScoreData.length > 0 ?
              <div className="flex justify-center w-full mb-4 ">
                <ReactEcharts className='w-full mx-auto'
                  style={{ height: '500px' }}
                  option={
                    {
                      tooltip: {
                        show: true,

                        trigger: 'item'
                      },
                      color: colorDataList,

                      legend: {
                        data: RelationListMain
                      },
                      radar: {
                        indicator: DimensionList
                      },
                      textStyle: {
                        color: '#000',
                        fontSize: 14,
                        fontStyle: 'bold'
                      },
                      series: [
                        {

                          name: 'Relation',
                          type: 'radar',
                          data: RelationList
                        }
                      ]
                    }}
                />

              </div>
              :
              <>
                <div className="pt-40 text-3xl text-center text-gray-400">Data Not Available!</div>
              </>}


          </div>
        </div>


      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DriverRadar);
