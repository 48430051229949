import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {

    let { IndexLabels, getIndexData,showMultiple, IndexForManagerData,ManagerListDasboardDataObj, DynaObj, EmpFilterData }= this.props;

    //console.log('IndexLabels: ',IndexLabels)
    // //console.log('getIndexData: ',getIndexData)
    // //console.log('IndexForManagerData: ',IndexForManagerData)
    //console.log('ManagerListDasboardDataObj: ',ManagerListDasboardDataObj)





    let DataValues = []
    let list = []
    let overallData = {}
    let managerTeam = {}
    let VariableData = {}

    
    if(true){
      //  list = getDistributionIndexData

        // overallData = {
        //   AchivementAvg:  getIndexDriverData.AchivementCount,
        //   BeingAvg:  getIndexDriverData.BeingCount,
        //   MeeningfullAvg:  getIndexDriverData.MeeningfullCount,
        //   RelationshipAvg:  getIndexDriverData.RelationshipCount,
        //   TimeAvg:  getIndexDriverData.TimeCount,
        //   WorkLifeAvg:  getIndexDriverData.WorkLifeCount,
        //   name: "OVERALL"
        // }

        if(IndexLabels && IndexLabels.length>0){
          IndexLabels.forEach((item)=>{

            if(getIndexData[item.indexType]){
              overallData[item.indexType]=getIndexData[item.indexType].toFixed(2)
              overallData["name"]="Overall Score";
            }

            if(IndexForManagerData[item.indexType]){
              managerTeam[item.indexType]=IndexForManagerData[item.indexType].toFixed(2)
              managerTeam["name"]="Team Score";
            }


            // VariableData[item.indexType]=getManagerListDomainFilterData[item.indexType]
            // VariableData["name"]="Department Score";

          })
        }



        // list.push(VariableData)
        if(overallData && overallData["name"]){
          list.push(overallData)
        }
        if(managerTeam && managerTeam["name"]){
          list.push(managerTeam)
        }



        let NewData = []
        if(EmpFilterData && EmpFilterData.length>0){
          EmpFilterData.forEach((emp)=>{
            if(DynaObj && DynaObj.length>0){
              DynaObj.forEach((item)=>{
                if(emp.name===item.name[0]){
                  NewData.push(item);
                }
              })
            }
          })
        }

        //console.log('NewData: ',NewData);

        if(NewData && NewData.length>0){
          NewData.forEach((item)=>{
            if(item.name[0] !== "ManagerPID"){

              let Data = {}

              if(IndexLabels && IndexLabels.length>0){
                IndexLabels.forEach((label)=>{
                  Data[label.indexType] = item && item[label.indexType] ? item[label.indexType].toFixed(2):'NaN'
                })
              }

              Data["name"] = item.name;
              
              if(Data && Data["name"]){
                list.push(Data);
              }
              //console.log('NewData Data: ',Data);

            }
          })
        }

        // //console.log('NewData: ',NewData);


    }


    
    let XAxis= []
    let XAxisName= []

    if(IndexLabels && IndexLabels.length>0){
      IndexLabels.forEach((item)=>{
        XAxis.push(item.indexName);
        XAxisName.push(item.indexType);
      })
    }

    let YAxis= []

      if(list && list.length>0){
        list.forEach((driver)=>{
          YAxis.push(driver.name)
        })
      }

      // //console.log('XAxis: ',XAxis)
      // //console.log('YAxis: ',YAxis)
      // //console.log('DataValues: ',DataValues)
      // //console.log('list: ',list)





      // let XAxis = []


      // if(EmpFilterData && EmpFilterData.length>0){
      //   EmpFilterData.forEach((emp)=>{
      //     XAxis.push(emp.label)
      //   })
      // }

      //console.log('IndexLabels: ',IndexLabels)
      //console.log('--->>_---------->list: ',list)



      let blackColor = '#000000';
      function getColor(data, index) {
        if(data.name==="Overall Score"){
          return {Color:'#ffffff',diffTextColor:'#ffffff'}

        }
        else{
          let diffNo = parseInt(Math.abs(parseFloat(overallData[index]) - parseFloat(data[index])));
          //////console.log("diffNo:",diffNo)
          if(parseFloat(overallData[index]) <= parseFloat(data[index])){ //greater (blue)
            if(diffNo>=0 && diffNo<=2){
              return {Color:'#2a74d5',diffTextColor:'#ffffff'}

            }
            else if(diffNo>=2 && diffNo<=12){
              return {Color:'#4b9ee7',diffTextColor:'#00000'}

            }
            else{
              return {Color:'#b8d7f2',diffTextColor:'#00000'}
            }

          }
          else{ //lesse (red)
            if(diffNo>=0 && diffNo<=2){
              return {Color:'#f5babd',diffTextColor:'#000000'}

            }
            else if(diffNo>=2 && diffNo<=12){
              return {Color:'#ea95a1',diffTextColor:'#000000'}

            }
            else{
              return {Color:'#d03e4d',diffTextColor:'#ffffff'}

            }
          }
        }

      }


      let List2x = []

      let NewManagers = ManagerListDasboardDataObj;

      if(NewManagers && NewManagers.length>0){
        List2x.push(overallData);
        NewManagers.forEach((item)=>{
          let data2x = {}
          if(IndexLabels && IndexLabels.length>0){
            IndexLabels.forEach((valueIndex)=>{
              data2x[valueIndex.indexType]=item.ManagerData[valueIndex.indexType].toFixed(2)
            });
          }
          data2x["name"]=item.ManagerName;
          List2x.push(data2x);
        })
      }

      let ListFinal = [];
      if(showMultiple){
        ListFinal = List2x;
      }
      else{
        ListFinal = list;
      }
      //console.log('NewManagers-->',NewManagers)
      //console.log('ListFinal-->',ListFinal)
                    
    return (
      <>  
      {ListFinal && ListFinal.length>0?
      <div className="overflow-x-auto whitespace-nowrap w-full overflow-hidden  mb-4 pb-4">
      <table className="w-full border ">
        <thead>
          <tr className="bg-gray-100 border-b text-xs   text-gray-800">
            <th className="py-2 px-4 border-r font-bold">{""}</th>
            {IndexLabels && IndexLabels.length>0?
              IndexLabels.map((label, index)=>
                <th key={index} className="p-3 font-bold text-gray-800 ">{label.indexName}
                {" ("+((ListFinal[0][label.indexType])?ListFinal[0][label.indexType]:'NaN')+")%"}
                </th>
              )
            :null}
          </tr>
        </thead>
        <tbody>
          {ListFinal && ListFinal.length>0?
            ListFinal.map((item)=>
              <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                {item.name!=="Overall Score"? 
                <td className="p-3  font-bold" style={{fontWeight:(item.name==="Overall Score")?'bold':null}}>    
                  {(item.name)?item.name:'Unavailable'}
                </td>
                :null}

                {IndexLabels && IndexLabels.length>0?
                  IndexLabels.map((label)=>
                  item.name!=="Overall Score"?
                  <td className="p-3 border border-b" style={{background:getColor(item,label.indexType).Color,color:(item.name==="Overall Score")?blackColor: getColor(item,label.indexType).diffTextColor,
                  fontWeight:getColor(item,label.indexType).diffTextColor==="#000000"?"500":null}}>    
                    {(item[label.indexType])?item[label.indexType]:'NaN'}
                  </td>                  
                  :null
                  )
                :null}


              </tr>
            ):null}




        </tbody>
      </table>
    </div>
      :null}
                           



      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HeatMap);
