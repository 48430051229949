import React, { Component } from 'react';
import { connect } from "react-redux";
import { dashboardActions, growActions } from "../../_actions";
import Sidebar from "./Components/Sidebar";
import Header from "./Components/Header";
import Content from './TaskModules/Content';
import Activity from './TaskModules/Activity';
import Feedback from './TaskModules/Feedback';
import MultiFeedback from './TaskModules/MultiFeedback';


class GrowWeekPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType1: true,
      showMusic: true,
      viewType2: false,
      width: 0, height: 0,
      newid: '',
      crrBucketId: this.props.match.params.id,
      crrMonthId: "",
      crrDayId: "",
      crrFeedbackList: []
    };
    // this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }

    let reqData = {
      "bucketId": this.state.crrBucketId
    }

    this.props.dispatch(growActions.getGrowPlusMonth_User(reqData));
    this.props.dispatch(dashboardActions.getGrowProfile());
    this.props.dispatch(growActions.getGrowPlusBucket_User());
    this.props.dispatch(growActions.getGrowPlusTaskStatus_User());
    // this.props.dispatch(growActions.forceGrowPlusTaskComplete_User());

  }


  leadingInner = (item) => {
    if (item.isStart === 0) {
      // this.setState({showtrue:true})
      this.setState({ newid: item.id })
      this.setState({ newids: item.monthId })
      let alldata = { item }
      this.props.navigation.navigate('LeadingSelfInner/' + item.id)
      let data = { weekId: item.id, monthId: item.monthId }
      this.props.dispatch(dashboardActions.startGrowWeek(data, this.props))
    }
    else {
      let alldata = { item }
      this.props.navigation.navigate('LeadingSelfInner/' + item.id)
    }
  }

  getDaysAPI = (id) => {
    let reqData = {
      "monthId": id
    }
    this.setState({ crrMonthId: id });
    this.props.dispatch(growActions.getGrowPlusDay_User(reqData));
  }

  selectDayAPI = (id) => {
    let reqData = {
      "dayId": id
    }
    this.setState({ crrDayId: id });
    this.props.dispatch(growActions.getGrowPlusTask_User(reqData));
  }

  handleTextFieldInput = (taskId, text) => {
    let { grow } = this.props;
    let { getGrowPlusTask_UserData } = grow;
    if (text) {
      if (text && text.length < 500) {
        this.props.dispatch(growActions.changeGrowPlusTask_Input(getGrowPlusTask_UserData, taskId, text));
      }
    } else {
      this.props.dispatch(growActions.changeGrowPlusTask_Input(getGrowPlusTask_UserData, taskId, text));
    }
  }

  handleInnerTextInput = (taskId, name, text) => {
    let { grow } = this.props;
    let { getGrowPlusTask_UserData } = grow;
    if (text) {
      if (text && text.length < 500) {
        this.props.dispatch(growActions.changeGrowPlusResponse_Input(getGrowPlusTask_UserData, taskId, name, text));
      }
    } else {
      this.props.dispatch(growActions.changeGrowPlusResponse_Input(getGrowPlusTask_UserData, taskId, name, text));
    }
  }


  handleRedirect = () => {
    this.props.history.push(`/app/manageprofile`)
  };


  saveFeedbackText = (taskId, check, type) => {

    let { grow } = this.props;
    let { getGrowPlusTask_UserData } = grow;
    let responseList = getGrowPlusTask_UserData && getGrowPlusTask_UserData.responseList && getGrowPlusTask_UserData.responseList.length > 0 ? getGrowPlusTask_UserData.responseList : []

    const getTextValue = (list, taskId) => {
      let textValue = "";
      if (list && list.length > 0) {
        let getIndex = list.findIndex(prev => prev.taskId.toString() === taskId.toString());
        if (getIndex !== -1) {
          textValue = list[getIndex]["feedback"];
        }
      }
      return textValue
    }
    const getTextListValue = (list, taskId) => {
      let feedSaveList = "";
      if (list && list.length > 0) {
        let getIndex = list.findIndex(prev => prev.taskId.toString() === taskId.toString());
        if (getIndex !== -1) {
          feedSaveList = list && list[getIndex] && list[getIndex]["feedList"] && list[getIndex]["feedList"].length > 0 ? list[getIndex]["feedList"] : [];
        }
      }
      return feedSaveList && feedSaveList.length > 0 ? feedSaveList.map((ele) => ({ "name": ele.name, "text": ele.text })) : []
    }

    let text = getTextValue(responseList, taskId);
    let textFeedList = getTextListValue(responseList, taskId);

    let reqData = {
      "taskId": taskId,
      "feedback": type === 2 ? "" : text,
      "feedList": type === 2 ? textFeedList : [],
      "isComplete": check ? true : (text ? true : false)
    }

    let temp = {
      "dayId": this.state.crrDayId
    }
    this.props.dispatch(growActions.saveGrowPlusTask_User(reqData, temp));
  }


  handleContinueButton = () => {
    const { crrDayId } = this.state;
    let { grow } = this.props;
    let { getGrowPlusDay_UserData } = grow;
    if (getGrowPlusDay_UserData && getGrowPlusDay_UserData.length > 0) {
      let getIndex = getGrowPlusDay_UserData.findIndex(x => x.id === crrDayId)
      if (getIndex !== -1 && getIndex < getGrowPlusDay_UserData.length - 1) {
        let newDayId = getGrowPlusDay_UserData[getIndex + 1]["id"]
        this.setState({ crrDayId: newDayId })
        this.selectDayAPI(newDayId)
      }
    }
  }

  handleCheckSubmitForce = (dayId) => {
    let temp = {
      "dayId": dayId,
      "isComplete": true
    }
    this.props.dispatch(growActions.forceGrowPlusTaskComplete_User(temp));

  }


  render() {
    let { dashboard, grow } = this.props;
    let { clientProfile, getGrowDayListbyWeekId } = dashboard;
    let { getGrowPlusMonth_UserData, getGrowPlusDay_UserData, getGrowPlusTask_UserData, getGrowPlusBucket_UserData, getGrowPlusTaskStatus_UserData, forceGrowPlusTaskComplete_User } = grow;
    let { crrFeedbackList, crrBucketId, crrMonthId, crrDayId } = this.state;

    let taskList = getGrowPlusTask_UserData && getGrowPlusTask_UserData.taskList && getGrowPlusTask_UserData.taskList.length > 0 ? getGrowPlusTask_UserData.taskList : []
    let responseList = getGrowPlusTask_UserData && getGrowPlusTask_UserData.responseList && getGrowPlusTask_UserData.responseList.length > 0 ? getGrowPlusTask_UserData.responseList : []


    const getLabels = (list, id, field) => {
      let value = "";
      if (list && list.length > 0) {
        let getIndex = list.findIndex(prev => prev.id === id);
        if (getIndex !== -1) {
          value = list && list[getIndex] && list[getIndex][field] ? list[getIndex][field] : ""
        }
      }
      return value
    }

    let contentList = [];
    let activityList = [];
    let feedbackList = [];
    let multiFeedbackList = [];
    if (taskList && taskList.length > 0) {
      contentList = taskList.filter(prev => prev.type === "Content");
      activityList = taskList.filter(prev => prev.type === "Activity");
      feedbackList = taskList.filter(prev => prev.type === "Feedback");
      multiFeedbackList = taskList.filter(prev => prev.type === "MultiFeedback");
    }



    // console.log("getGrowPlusTaskStatus_UserData----------------->", getGrowPlusTaskStatus_UserData);



    return (

      <div className="overflow-hidden h-screen ">
        <Header
          clientProfile={clientProfile}
          handleRedirect={this.handleRedirect}
        />
        <div className="flex">
          <Sidebar
            getGrowPlusMonth_UserData={getGrowPlusMonth_UserData}

            crrBucketId={crrBucketId}
            crrMonthId={crrMonthId}
            crrDayId={crrDayId}
            getGrowPlusTaskStatus_UserData={getGrowPlusTaskStatus_UserData}
            handleCheckSubmitForce={this.handleCheckSubmitForce}

            getDaysAPI={this.getDaysAPI}
            selectDayAPI={this.selectDayAPI}
            getGrowPlusDay_UserData={getGrowPlusDay_UserData}
            getGrowPlusBucket_UserData={getGrowPlusBucket_UserData}
          />
          <main className="flex-1 overflow-y-auto h-[calc(100vh-3.7rem)] bg-white flex flex-col justify-between  relative">

            {/* Introduction */}
            <div >
              <div className='border-b w-full p-4'>
                <h2 className="text-lg font-medium">{getLabels(getGrowPlusDay_UserData, crrDayId, "label")}</h2>
              </div>
              {/* Introduction */}

              {/* Quotes */}
              {getLabels(getGrowPlusDay_UserData, crrDayId, "quotes") && (
                <div className='mt-10 md:w-4/5 w-full mx-auto md:px-0 px-4'>
                  <div className='bg-[#009688] rounded-xl text-center flex flex-col items-center py-6 md:bg-cover bg-top bg-no-repeat w-full bg-contain '
                    style={{ backgroundImage: `url('/img/grow/bg-quotes.svg')` }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width={42} height={33} viewBox="0 0 42 33" fill="none">
                      <path d="M3.69367 29.4157C1.29033 26.863 0 24 0 19.359C0 11.1923 5.733 3.87266 14.07 0.253662L16.1537 3.469C8.372 7.67833 6.85067 13.1407 6.244 16.5847C7.497 15.936 9.13733 15.7097 10.745 15.859C14.9543 16.2487 18.2723 19.7043 18.2723 24C18.2723 26.1659 17.4119 28.2432 15.8804 29.7747C14.3488 31.3062 12.2716 32.1667 10.1057 32.1667C8.90785 32.1563 7.72405 31.9078 6.62323 31.4355C5.52241 30.9632 4.52656 30.2766 3.69367 29.4157ZM27.027 29.4157C24.6237 26.863 23.3333 24 23.3333 19.359C23.3333 11.1923 29.0663 3.87266 37.4033 0.253662L39.487 3.469C31.7053 7.67833 30.184 13.1407 29.5773 16.5847C30.8303 15.936 32.4707 15.7097 34.0783 15.859C38.2877 16.2487 41.6057 19.7043 41.6057 24C41.6057 26.1659 40.7452 28.2432 39.2137 29.7747C37.6822 31.3062 35.6049 32.1667 33.439 32.1667C32.2412 32.1563 31.0574 31.9078 29.9566 31.4355C28.8557 30.9632 27.8599 30.2766 27.027 29.4157Z" fill="white" />
                    </svg>
                    <p className=' py-3 text-white text-lg font-semibold '>
                      {getLabels(getGrowPlusDay_UserData, crrDayId, "quotes")}
                    </p>
                    {/* <p className='text-white italic'>~ Lao Tzu</p> */}
                  </div>
                </div>
              )}


              {contentList && contentList.length > 0 && contentList.map((task) =>
                <div className='md:px-0 px-4'>
                  {task && task.type && task.type === "Content" &&
                    <Content task={task} />
                  }
                </div>
              )}

              <div className='mt-10 md:w-4/5 w-full mx-auto md:px-0 px-4'>

                {multiFeedbackList && multiFeedbackList.length > 0 &&
                  <>
                    <MultiFeedback handleInnerTextInput={this.handleInnerTextInput} multiFeedbackList={multiFeedbackList} responseList={responseList} handleTextFieldInput={this.handleTextFieldInput} saveFeedbackText={this.saveFeedbackText} />
                  </>
                }

                {activityList && activityList.length > 0 &&
                  <div className=' '>
                    <Activity activityList={activityList} saveFeedbackText={this.saveFeedbackText} />
                  </div>
                }

                {feedbackList && feedbackList.length > 0 && feedbackList.map((task) =>
                  <div className=' '>
                    {task && task.type && task.type === "Feedback" &&
                      <Feedback saveFeedbackText={this.saveFeedbackText} responseList={responseList} task={task} crrFeedbackList={crrFeedbackList} handleTextFieldInput={this.handleTextFieldInput} />
                    }
                  </div>
                )}
              </div>


              {/* week day-1 */}
              <div className='mt-10 md:w-4/5 w-full mx-auto md:px-0 px-4'>

                {/* <div className='border rounded-lg overflow-hidden'>
                  <div className='flex items-center justify-between w-full p-4 bg-[#E6F5F3] border-b'>
                    <h2 className='text-lg font-semibold'>Activity</h2>
                    <button className='text-[#009688]'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" v
                      iewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                    </button>
                  </div>

                  <div className='px-4 space-y-4 py-4 '>
                    <div className='divide-y'>
                      <div className='py-4'>
                        <p className='font-semibold'>Watch this Video</p>
                        <li className='list-disc '>
                          <span className='font-semibold'> Plan is not Strategy:</span> https://www.youtube.com/watch?v=iuYlGRnC7J8
                        </li>
                      </div>
                      <div className='py-4'>
                        <p className='font-semibold'>Read and Introspect</p>
                        <li className='list-disc '>
                          <span className='font-semibold'>  A Way to plan if you’re bad at planning:</span> https://hbr.org/2017/07/a-way-to-plan-if-youre-bad-at-planning
                        </li>
                      </div>
                    </div>
                    <p className='text-[#212121]/70 font-medium text-right'>500 Character Max.</p>
                    <button className='flex items-center space-x-2 bg-[#009688] px-6 py-2 rounded-md text-white w-fit ml-auto'>
                      <span>Save & Continue</span>
                    </button>
                  </div>

                </div> */}

                {/* <div className='border rounded-lg overflow-hidden'>
                  <div className='flex items-center justify-between w-full p-4 bg-[#E6F5F3] border-b'>
                    <h2 className='text-lg font-semibold'>Write down your key takeaway</h2>
                    <button className='text-[#009688]'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                    </button>
                  </div>

                  <div className='px-4 space-y-4 py-4 '>
                    <textarea rows={4} className='border p-4 w-full rounded-lg' placeholder='Write here...'></textarea>
                    <p className='text-[#212121]/70 font-medium text-right'>500 Character Max.</p>
                    <button className='flex items-center space-x-2 bg-[#009688] px-6 py-2 rounded-md text-white w-fit ml-auto'>
                      <span>Save & Continue</span>
                    </button>
                  </div>

                </div> */}

                {/* <div className='border rounded-lg overflow-hidden'>
                  <div className='flex items-center justify-between w-full p-4 bg-[#E6F5F3] border-b'>
                    <h2 className='text-lg font-semibold'>Practice tracker</h2>
                    <button className='text-[#009688]'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                    </button>
                  </div>

                  <div className='px-4 space-y-4 py-4 '>

                    <div className='space-y-2 '>
                      <div className='flex items-center space-x-6'>
                        <p className='text-[#212121] font-semibold text-left flex-shrink-0'>Day 1</p>
                        <input className='border rounded-lg p-4 py-3 w-full' placeholder='Write here...' />
                      </div>
                      <p className='text-[#212121]/70 font-medium text-right text-sm'>250 Character Max.</p>
                    </div>
                    <div className='space-y-2 '>
                      <div className='flex items-center space-x-6'>
                        <p className='text-[#212121] font-semibold text-left flex-shrink-0'>Day 2</p>
                        <input className='border rounded-lg p-4 py-3 w-full' placeholder='Write here...' />
                      </div>
                      <p className='text-[#212121]/70 font-medium text-right text-sm'>250 Character Max.</p>
                    </div>
                    <div className='space-y-2 '>
                      <div className='flex items-center space-x-6'>
                        <p className='text-[#212121] font-semibold text-left flex-shrink-0'>Day 3</p>
                        <input className='border rounded-lg p-4 py-3 w-full' placeholder='Write here...' />
                      </div>
                      <p className='text-[#212121]/70 font-medium text-right text-sm'>250 Character Max.</p>
                    </div>
                    <div className='space-y-2 '>
                      <div className='flex items-center space-x-6'>
                        <p className='text-[#212121] font-semibold text-left flex-shrink-0'>Day 4</p>
                        <input className='border rounded-lg p-4 py-3 w-full' placeholder='Write here...' />
                      </div>
                      <p className='text-[#212121]/70 font-medium text-right text-sm'>250 Character Max.</p>
                    </div>
                    <div className='space-y-2 '>
                      <div className='flex items-center space-x-6'>
                        <p className='text-[#212121] font-semibold text-left flex-shrink-0'>Day 5</p>
                        <input className='border rounded-lg p-4 py-3 w-full' placeholder='Write here...' />
                      </div>
                      <p className='text-[#212121]/70 font-medium text-right text-sm'>250 Character Max.</p>
                    </div>
                    <button className='flex items-center space-x-2 bg-[#009688] px-6 py-2 rounded-md text-white w-fit ml-auto'>
                      <span>Save & Continue</span>
                    </button>
                  </div>

                </div> */}

              </div>
            </div>
            {/* Day One */}

            <div className='border-t flex items-center justify-center py-4 bottom-0 right-0 sticky bg-white absolute w-full    '>
              <button onClick={this.handleContinueButton} className='flex items-center space-x-2 bg-[#009688] px-8 py-3 rounded-md text-white'>
                <span>Continue</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-narrow-right"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l14 0" /><path d="M15 16l4 -4" /><path d="M15 8l4 4" /></svg>
              </button>
            </div>
          </main>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, users, grow, dashboard } = state;
  return {
    loggingIn,
    questions,
    users,
    dashboard,
    grow,
  };
}
export default connect(mapStateToProps)(GrowWeekPlan)