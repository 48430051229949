import React from 'react';
import GaugeScore from './Charts/GaugeScore';


export default function ScoreBenchmark(props) {

  let {

    item,

    DistributionData,
    Benchmark,
    cronBachAlpha,
    indexOverallValue,
    isCrole,
    name

  } = props;


  //  let Benchmark ={
  //    score:0
  //  }


  //console.log('Benchmark: ',Benchmark)

  return (
    <div>

      <div className="flex justify-center item-center">
        <GaugeScore item={item} Benchmark={Benchmark} />
      </div>


      {isCrole ?
        <div className="flex justify-center  ">
          <div className="text-sm items-center text-center mx-2"><p className="text-gray-500 font-semibold">
            {indexOverallValue && indexOverallValue.toString() ? "Organization " + name + " Score: " + indexOverallValue.toString() : "NaN"}</p></div>
        </div>
        : <div />}


      <div className="flex items-center justify-center 2xl:pb-6">
        {/* 1 */}
        <div className="flex flex-wrap justify-center gap-4 text-sm text-gray-500   "> 
          <div className="flex items-center "><div style={{ background: "#e53935" }} className="p-2 rounded-sm" /><p className="ml-2">{"Well Managed Stess (=6)"}</p></div>
          <div className="flex items-center "><div style={{ background: "#ffc107" }} className="p-2 rounded-sm" /><p className="ml-2">{"Moderately Well (4.5-6)"}</p></div>
          <div className="flex items-center "><div style={{ background: "#a2e37d" }} className="p-2 rounded-sm" /><p className="ml-2">{"Often Stressed (3-4.5)"}</p></div>
          <div className="flex items-center "><div style={{ background: "#00cc66" }} className="p-2 rounded-sm" /><p className="ml-2">{"Need Immediate Attention (<3)"}</p></div>
        </div>
         
      </div>


    </div>
  );
}
