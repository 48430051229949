import React, { Component } from 'react';
import { connect } from 'react-redux';
import ClusterGraph from './Charts/ClusterGraph';
// import Filter from './Filter';
import Commentary from '../../../Index/Components/Commentary/Commentary';
import Filter from './Filter/Filter';
import DemographicFilter from './Filter/DemographicFilter';
import Histogram from './Charts/Histogram';

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: 'none',
      viewScore: true,
      showDropDown1: false,
      showDropDown2: false,

      showFilter: false,
      showValue: '',

      showFilter2: false,
      showValue2: '',

      selectedClusterInfo: {},

      clusterSecondFilter: "",

      DimensionList: [
        {
          index: 1,
          name: 'Happiness',
          cutOff: 59,
          range: 72,
          type: ""
        },
        {
          index: 2,
          name: 'Engagement',
          cutOff: 78,
          range: 100,
          type: "%"

        },
        {
          index: 4,
          name: 'Stress',
          cutOff: 28,
          range: 60,
          type: "%"

        },
        {
          index: 5,
          name: 'Manager',
          cutOff: 59,
          range: 100,
          type: "%"

        },
        {
          index: 6,
          name: 'Leader',
          cutOff: 50,
          range: 100,
          type: "%"

        },
        // {
        //   index:8,
        //   name:'OrgCore',
        //   cutOff:59
        // },
      ],

      selectedItem1: '',
      selectedItem2: '',


      currentSelectValue: '',
      FilterValues: []



    }
  }
  componentDidMount() {

  }

  handleViewScore = (data) => {
    this.setState({ viewScore: data })
  }




  handleDropdown1 = (check) => {
    this.setState({ showDropDown1: !check, showDropDown2: false })
  }
  handleDropdown2 = (check) => {
    this.setState({ showDropDown2: !check, showDropDown1: false })
  }

  changeItem1 = (item) => {
    this.setState({ selectedItem1: item })
    this.setState({ showDropDown1: false, showDropDown2: false })

  }

  changeItem2 = (item) => {
    this.setState({ selectedItem2: item })
    this.setState({ showDropDown1: false, showDropDown2: false })

  }



  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { onClusterAnalysis } = this.props;
    this.setState({ FilterValues: finalFilter });
    onClusterAnalysis(finalFilter);

  }


  handleMultifilterIndexScore3 = (finalFilter, name) => {
    this.setState({ clusterSecondFilter: finalFilter });
  }



  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });

  }

  closeFilter2 = (check1, check2) => {
    this.setState({ showFilter2: check1, showValue2: check2 });
  }


  handleSelectCluster = (params) => {
    let { clusterAnalysisData, getClusterEmpData, EmpFilterData } = this.props;
    function getLabel(valueArray) {
      let label1 = "";
      let EmpList = [];
      if (clusterAnalysisData && clusterAnalysisData.length > 0) {
        // let getIndex1 = clusterAnalysisData.findIndex(prev=>prev[1] === valueArray[0]);
        let getIndex2 = clusterAnalysisData.findIndex(prev => prev[0] === valueArray[2]);

        // //////console.log("getIndex1========>",getIndex1)
        // //////console.log("getIndex2========>",getIndex2)
        // //////console.log("valueArray========>",valueArray)
        console.log("clusterAnalysisData========>", clusterAnalysisData)
        if (getIndex2 !== -2) {
          label1 = clusterAnalysisData[getIndex2][0];
          EmpList = clusterAnalysisData[getIndex2][3];
        }
      }
      //////console.log("label1--->",label1)
      return {
        "clusterName": label1,
        "clusterSize": valueArray[0],
        "clusterCenter": valueArray[1],
        "clusterEmployees": EmpList
      }
    }


    console.log("params=============>", params)

    let Data = getLabel(params.data);
    let empList = Data.clusterEmployees;
    console.log("empList=============>", empList)

    let initFileter = {
      "level": EmpFilterData && EmpFilterData.length > 0 ? EmpFilterData[0]["name"] : ""
    }
    console.log("initFileter=============>", initFileter)

    this.setState({ selectedClusterInfo: Data, clusterSecondFilter: [initFileter] })
    getClusterEmpData(empList)

  }

  render() {
    let { ErrorOtherObj, clusterAnalysisError, clusterAnalysisMSG, getEMPFilterLabel, getClusterEmpInfoListData, clusterAnalysisData, clusterAnalysisCount, getIndexByFilterDMGData, CountRestrict, validateList, indexType, optionType, loading, getCommentData, getIndexData, getDemographicIndexData, EmpFilterData, getIndexFilterData } = this.props;


    let { clusterSecondFilter, selectedClusterInfo, showDropDown1, showDropDown2, DimensionList, selectedItem1, selectedItem2, currentSelectValue, FilterValues } = this.state;

    // let valueList=[]
    // if(getIndexData){
    //   valueList = Object.keys(getIndexData).map((key) => [ key, getIndexData[key] ]);
    //   //console.log('valueList: ',valueList)
    // }


    console.log('getClusterEmpInfoListData:----------> ', getClusterEmpInfoListData)






    //console.log('clusterAnalysisData: ',clusterAnalysisData)
    //console.log('clusterAnalysisCount: ',clusterAnalysisCount);




    // let { DimensionList} = this.state;

    // let { validateIndex } = this.props;

    // let validateList = [];
    // let validObj = Object.keys(validateIndex).map((key)=>[key,validateIndex[key]])
    // //console.log('validObj: ',validObj);
    // if(validObj && validObj.length>0){
    //   validObj.forEach((item)=>{
    //     if(item[1]){
    //       let getIndex = DimensionList.findIndex(prev=>prev.index===parseInt(item[0]));
    //       if(getIndex!==-1?DimensionList[getIndex].name:""){
    //         validateList.push({
    //           "name":getIndex!==-1?DimensionList[getIndex].name:"",
    //           "template":parseInt(item[0])
    //         });
    //       }

    //     }
    //   })
    // }
    // //console.log('validateList: ',validateList);




    let DimensionIndex = []
    if (validateList && validateList.length > 0) {
      validateList.forEach((valid) => {
        if (DimensionList && DimensionList.length > 0) {
          DimensionList.forEach((dimen) => {
            if (valid.template === dimen.index) {
              DimensionIndex.push(dimen);
            }
          })
        }
      })
    }


    //console.log('validateList: ',validateList)

    let Item1 = '';
    let Item2 = '';
    if (selectedItem1) {
      Item1 = selectedItem1
    }
    else {
      let getIndex1 = DimensionIndex.findIndex(prev => prev.template === selectedItem1.index)
      if (getIndex1 === -1) {
        Item1 = DimensionIndex[0];
      }
      else {
        Item1 = DimensionIndex[getIndex1];
      }
    }


    if (selectedItem2) {
      Item2 = selectedItem2
    }
    else {
      let getIndex2 = DimensionIndex.findIndex(prev => prev.template === selectedItem2.index)
      if (getIndex2 === -1) {
        Item2 = DimensionIndex[0];
      }
      else {
        Item2 = DimensionIndex[getIndex2];
      }

      if (Item1 === Item2) {

        if (getIndex2 === -1) {
          Item2 = DimensionIndex[1];
        }
        else {
          Item2 = DimensionIndex[getIndex2 + 1];
        }

      }
    }



    // selectedItem1:{
    //   index:1,
    //   name:'Happiness',
    //   cutOff:59,
    //   range:72,
    //   type:""
    // },
    // selectedItem2:{
    //   index:2,
    //   name:'Engagement',
    //   cutOff:78,
    //   range:100,
    //   type:"%"
    // },


    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;
    // let DistributionIndexData = [];
    //  let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }


      //  DistributionIndexDataScore=getIndexDriverData;
      //  DemographicDataDistribution=getIndexDistributionData;
      //  DistributionIndexData = getDistributionIndexData

      // getDistributionIndexData && getIndexDriverData

    }

    //////console.log("clusterAnalysisMSG--->",clusterAnalysisMSG)

    let errorMsg = "";
    if (clusterAnalysisMSG) {
      errorMsg = "* We are not showing data where employee count is less than 5"
    }

    return (
      <>

        <div className="flex items-center justify-between gap-4 md:flex-row">
          <div className="flex items-center justify-between text-gray-800">
            <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">People To Meet</h1>
          </div>
          <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />
        </div>


        {true ?
          <>
            <div onClick={() => this.closeFilter(false)} className="" >

              <div className="w-full" >
                {Item2 && Item2.name ?

                  <div className="grid grid-cols-1 gap-4 xl:grid-cols-1 ">

                    <div className="md:p-6 p-4 transition duration-150 bg-white border rounded-lg hover:shadow-lg space-y-4">

                      <h1 className=" font-medium xl:text-xl text-lg text-[#3D405B]  ">Risk Categories</h1> 

                      {ErrorOtherObj && ErrorOtherObj["clusterAnalysisError"] ?
                        <>
                          <div className='my-24 text-2xl font-medium text-center text-gray-400'>{ErrorOtherObj["clusterAnalysisError"]}</div>
                        </>
                        :
                        <>

                          {Item2 && Item2.name ?
                            <div className="h-auto" >
                              <ClusterGraph
                                clusterAnalysisData={clusterAnalysisData}
                                clusterAnalysisCount={clusterAnalysisCount}
                                CountRestrict={CountRestrict} getDemographicIndexData={getDemographicIndexData} selectedItem1={Item1} selectedItem2={Item2}
                                handleSelectCluster={this.handleSelectCluster}
                              />
                              <div className="mt-4 text-center flex justify-center md:flex-row flex-col gap-2 "><p><b>X-Axis:</b> {' Cluster Size'}</p> <p><b>Y-Axis:</b> {'Cluster Centroid'} </p></div>

                            </div>
                            : null}

                        </>}


                    </div>

                    <div className="md:p-6 p-4 transition duration-150 bg-white border rounded-lg hover:shadow-lg space-y-4">

                      <div className="flex flex-col items-center justify-center gap-2   bg-white md:justify-between md:flex-row">
                        <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] md:text-left text-center">Employee Distribution across Demographic Wise</h1> 
                        <div className='flex justify-end'>
                          <DemographicFilter
                            showFilter={this.state.showFilter2}
                            showValue={this.state.showValue2}
                            closeFilter={this.closeFilter2}

                            EmpFilterData2={EmpFilterData2}
                            handleMultifilterIndexScore={this.handleMultifilterIndexScore3}
                            getEMPFilterLabel={getEMPFilterLabel}
                          />
                        </div>

                      </div>


                      {ErrorOtherObj && ErrorOtherObj["getClusterEmpInfoError"] ?
                        <>
                          <div className='my-24 text-2xl font-medium text-center text-gray-400'>{ErrorOtherObj["getClusterEmpInfoError"]}</div>
                        </>
                        :
                        <>

                          <Histogram clusterSecondFilter={clusterSecondFilter} getClusterEmpInfoListData={getClusterEmpInfoListData} loading={loading} handleKeywordCall={this.handleKeywordCall} />
                        </>}

                    </div>

                  </div>
                  :
                  <>
                    <div className="w-full px-4 m-4 bg-white rounded shadow-lg">
                      <div className="py-4 text-lg font-semibold">Risk Categories</div>

                      <div className='py-40 text-3xl text-center text-gray-400'>
                        {clusterAnalysisError}
                      </div>

                    </div>

                  </>
                }





              </div>
            </div>
          </>
          :
          <>
            {!loading ?
              <div className="pt-40 text-3xl text-center text-gray-400">{clusterAnalysisError}</div> : null}
          </>
        }
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
