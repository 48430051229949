import React from 'react';
import GaugeScore from './Charts/GaugeScore';


export default function ScoreBenchmark(props) {
    
    let { 

        item,

        DistributionData,
        Benchmark,
        cronBachAlpha,
        indexOverallValue,
        isCrole,
        name

     } = props;


    //  let Benchmark ={
    //    score:0
    //  }


     //console.log('Benchmark: ',Benchmark)

    return (
      <>	

      
                  {/* <div className="h-60" /> */}
                  <div className="flex justify-center item-center">
                    <GaugeScore item={item} Benchmark={Benchmark}/>
                  </div>


                  {isCrole?
                    <div className="flex justify-center pb-2">
                    <div className="text-sm items-center text-center mx-2 mt-12"><p className="text-gray-500 font-semibold">
                      {indexOverallValue && indexOverallValue.toString()?"Organization "+name+" Score: " +indexOverallValue.toString():"NaN"}</p></div>
                  </div>
                  :<div />}

{/* 
                  <div className="flex items-center justify-center">
                    <div className=" grid 2xl:grid-cols-4 grid-cols-2 gap-4 text-xs text-gray-500  ">
                      <div className="flex items-center "><div className="p-1 bg-red-500 rounded-sm" /><p className="ml-2">Unhappy (0-48)</p></div>
                      <div className="flex items-center "><div className="p-1 bg-yellow-400 rounded-sm" /><p className="ml-2">Moderate (49-60)</p></div>
                      <div className="flex items-center "><div className="p-1 bg-green-400 rounded-sm" /><p className="ml-2">Happier (61-66)</p></div>
                      <div className="flex items-center "><div className="p-1 bg-pink-400 rounded-sm" /><p className="ml-2">Happiest (67-72)
                        </p></div>
                    </div>
                  </div> */}



      </>
    );
  }
