import React, { Component } from "react";
import { connect } from "react-redux";
import Header from '../Components/Header';

function OrgThemesSelfCross(props) {

  let { getSubWiseFeedAnsCountData, userName, createdAt, GetParameterLabel, mode } = props;

  const elementsPie = Array.from({ length: 3 });

  let optionList = ["Extremely Dissatisfied", "Very Dissatisfied", "Somewhat Dissatisfied", "Somewhat Satisfied", "Very Satisfied", "Extremely Satisfied"]
  let optionColorList = ["#EF5350", "#FF7043", "#FFA726", "#D4E157", "#9CCC65", "#66BB6A"]


  const getCrossScore = (ele, type) => {

    let finalListEmpty = optionList && optionList.length > 0 ? optionList.map((ele) => ({ "name": ele, "count": 0, "score": 0 })) : []


    let rowList = ele && ele.feedbacks && ele.feedbacks.length > 0 ? ele.feedbacks : [];
    if (rowList && rowList.length > 0) {

      let list = [];

      if (type === "Overall") {
        list = rowList;
      } else if (type === "Self") {
        list = rowList.filter(item => item.feedback.includes('Self'));
      } else if (type === "Cross") {
        list = rowList.filter(item => !item.feedback.includes('Self'));
      }


      let optCountList = [];
      let totalSum = 0;

      if (optionList && optionList.length > 0) {
        optionList.forEach((opt) => {

          let sumCount = 0
          if (list && list.length > 0) {
            list.forEach((inner) => {
              if (inner && inner.answers && inner.answers.length > 0) {
                let index1 = inner.answers.findIndex(prev => prev.answerText === opt);
                if (index1 !== -1) {
                  let score = inner.answers[index1]["count"];
                  if (score) {

                    sumCount += score;

                    totalSum += score;
                  }
                }


              }


            })
          }

          optCountList.push({
            "name": opt,
            "count": sumCount,
            "score": 0
          })


        })
      }

      let finalList = totalSum > 0 && optCountList && optCountList.length > 0 ? optCountList.map((ele) => ({ "name": ele.name, "count": ele.count, "score": parseInt((ele.count * 100) / totalSum) })) : finalListEmpty;

      return finalList


    }

    return finalListEmpty
  }


  function chunkArray(array, chunkSize) {
    let result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      let chunk = array.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  }


  let size = 5;

  // console.log("getSubWiseFeedAnsCountData-------------->", getSubWiseFeedAnsCountData);

  // const getSorted = () => {

  // }


  const scoreCalc = (ele) => {
    let total = 0;
    let relevantCount = 0;
    if (ele && ele.feedbacks && ele.feedbacks.length > 0) {
      for (const item of ele.feedbacks) {
        for (const answer of item.answers) {
          total += answer.count;
          if (["Strongly Agree", "Agree", "Extremely Satisfied", "Very Satisfied"].includes(answer.answerText)) {
            relevantCount += answer.count;
          }
        }
      }
    }
    let score = total > 0 ? ((relevantCount * 100) / total) : 0;
    return score
  }

  const sortedScoreList = (list, dept) => {
    let list_new = []
    if (list && list.length > 0) {
      for (var i = 0; i < list.length; i++) {
        list_new.push({
          ...list[i],
          score: scoreCalc(list[i])
        })
      }
      if (mode === "Top") {
        list_new.sort((b, a) => (a.score - b.score));
      }
      if (mode === "Bottom") {
        list_new.sort((a, b) => (a.score - b.score));
      }
    }

    console.log("mode>>>>>>>>", mode);
    console.log("dept>>>>>>>>", dept);
    console.log("list_new>>>>>>>>>>", list_new);

    return list_new
  }


  
  const sortingList = (list) => {
    let sortedList = []
    if (list && list.length > 0) {
      sortedList = list.sort((a, b) => {
        return ('' + a.xAxis).localeCompare(('' + b.xAxis));
      });
    }
    return sortedList
  }


  return (
    <>
      {getSubWiseFeedAnsCountData && getSubWiseFeedAnsCountData.length > 0 &&
        sortingList(getSubWiseFeedAnsCountData).map((dataX) =>
          <>
            <section className='w-full p-6 px-6 flex flex-col justify-between page-break'>
              <Header createdAt={createdAt} userName={userName} />

              <div className="flex flex-col space-y-4 w-full ">
                <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                  {mode + " 3 Themes - " + (dataX && dataX.xAxis ? "Department: " + dataX.xAxis : "")}
                </h1>
                <div className="space-y-6">
                  <div className=" items-center  justify-center bg-[#F1F5F9] p-2 px-4 ">
                    <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]   ">
                      <div className="flex items-center ">
                        <div
                          className="p-1.5 rounded-sm bg-[#EF5350]"

                        />
                        <p className="ml-1.5">Extremely Dissatisfied</p>
                      </div>
                      <div className="flex items-center ">
                        <div
                          className="p-1.5 rounded-sm bg-[#FF7043]"
                        />
                        <p className="ml-1.5">Very Dissatisfied</p>
                      </div>
                      <div className="flex items-center ">
                        <div
                          className="p-1.5 rounded-sm bg-[#FFA726]"
                        />
                        <p className="ml-1.5">Somewhat Dissatisfied</p>
                      </div>
                      <div className="flex items-center ">
                        <div
                          className="p-1.5 rounded-sm bg-[#D4E157]"
                        />
                        <p className="ml-1.5">Somewhat Satisfied</p>
                      </div>
                      <div className="flex items-center ">
                        <div
                          className="p-1.5 rounded-sm bg-[#9CCC65]"
                        />
                        <p className="ml-1.5">Very Satisfied</p>
                      </div>
                      <div className="flex items-center ">
                        <div
                          className="p-1.5 rounded-sm bg-[#66BB6A]"
                        />
                        <p className="ml-1.5">Extremely Satisfied</p>
                      </div>
                    </div>
                    <p className="text-red-600 text-xs py-2">{"*The ranking is based on 'Strongly Agree' and 'Agree' responses."}</p>
                  </div>
                  {sortedScoreList(dataX.dimensionDist, dataX.xAxis) && sortedScoreList(dataX.dimensionDist, dataX.xAxis).length > 0 &&
                    sortedScoreList(dataX.dimensionDist, dataX.xAxis).map((ele, index2) => (
                      (index2 < 3) ?
                        <div className="space-y-2 ">
                          <p className="text-base font-bold text-[#212121] bg-[#F1F5F9] p-2 px-4">{index2 + 1}.  {ele && ele._id ? GetParameterLabel(ele._id) : ""}</p>
                          <div className="grid grid-cols-2 gap-6">
                            {/* <p className="font-bold text-base">Department</p> */}
                            <div className="space-y-2">
                              <>
                                <span className="text-sm">Self</span>
                                <div className="w-full flex items-center text-sm space-x-2">
                                  <div className='flex items-end h-16 overflow-hidden space-x-1'>
                                    {getCrossScore(ele, "Self") && getCrossScore(ele, "Self").length > 0
                                      ? getCrossScore(ele, "Self").map((inner, index) => (
                                        <div className="bg-gray-100 flex items-end h-16">
                                          <div
                                            key={index}
                                            style={{
                                              background: inner && inner.color ? inner.color : optionColorList[index],
                                              height: (inner && inner.score ? inner.score : 0) + "%",
                                            }}
                                            className='flex items-end justify-center text-[#212121] font-medium w-16 px-2  h-16'>
                                            <span className='text-sm'>
                                              {true ? inner.score + "%" : ""}
                                            </span>
                                          </div>
                                        </div>
                                      ))
                                      : null}
                                  </div>
                                </div>
                              </>
                            </div>
                            <div className="space-y-2">
                              <span className="text-sm">Cross-Functional</span>
                              <div className="w-full flex items-center text-sm space-x-2">

                                <div className='flex items-end h-16 overflow-hidden space-x-1'>
                                  {getCrossScore(ele, "Cross") && getCrossScore(ele, "Cross").length > 0
                                    ? getCrossScore(ele, "Cross").map((inner, index) => (
                                      <div className="bg-gray-100 flex items-end h-16">
                                        <div
                                          key={index}
                                          style={{
                                            background: inner && inner.color ? inner.color : optionColorList[index],
                                            height: (inner && inner.score ? inner.score : 0) + "%",
                                          }}
                                          className='flex items-end justify-center text-[#212121] font-medium w-16 px-2'
                                        >
                                          <span className='text-sm'>
                                            {true ? inner.score + "%" : ""}
                                          </span>
                                        </div>
                                      </div>
                                    ))
                                    : null}
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                        : null
                    ))}


                </div>
              </div >

            </section>
          </>
        )}


    </>

  );
}
export default OrgThemesSelfCross;
