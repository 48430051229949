import React, { Component } from 'react';
import { connect } from 'react-redux';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
        // showFilter:false,
        // showValue:'',
        currFilterList:[],
        finalFilter:[],
        searchName:''

    }
  }
  componentDidMount() {

  }


  handleParameterQuestion=()=>{

  }

  handleSelectFilter=(name)=>{
    // this.setState({showFilter:false});
    this.props.closeFilter(false);

    let {currFilterList,finalFilter}= this.state;
    let { handleMultifilterIndexScore } = this.props;
    let getIndex = currFilterList.findIndex(prev=>prev===name);

    if(getIndex===-1){
        currFilterList = [name];
        finalFilter = [{
            "level":name,
            "value":"All"
        }];  

        this.setState({currFilterList,finalFilter},()=>{
            handleMultifilterIndexScore(finalFilter,name); 
        });
    }
    else{
        currFilterList.splice(getIndex,1);
        let getIndex2 = finalFilter.findIndex(prev=>prev.level===name);
        finalFilter.splice(getIndex2,1);

        this.setState({currFilterList,finalFilter},()=>{
            if(finalFilter && finalFilter.length>0){
                handleMultifilterIndexScore(finalFilter,finalFilter[finalFilter.length-1].level); 
            }
            else{
                handleMultifilterIndexScore([]); 
                // this.setState({showFilter:false})
                this.props.closeFilter(false);

            }
        });
    }

    }

  handleOpenFilter=(check)=>{
    this.setState({showFilter:!check,showValue:''});
    this.props.closeFilter(!check,'');

    }


    handleOpenValue=(name)=>{
        if(this.props.showValue===name){
            // this.setState({showValue:'',showFilter:false});
            this.props.closeFilter(false,'');


        }
        else{
            // this.setState({showValue:name,showFilter:false})
            this.props.closeFilter(false,name);

        }
    }


    handleSelectValue=(value,name)=>{
        let { finalFilter } = this.state;

        let getIndex =  finalFilter.findIndex(prev=>prev.level===name);

        if(getIndex===-1){

            finalFilter.push({
                "level":name,
                "value":value
            });      

        }
        else{

            let getIndex2 =  finalFilter.findIndex(prev=>prev.value===value);
            if(getIndex2===-1){
                finalFilter[getIndex].value=value;
            }
            else if(value==="All"){
                finalFilter[getIndex].value="All";
            }

        }
        this.props.closeFilter(false,'');
        let { handleMultifilterIndexScore } = this.props;
        this.setState({finalFilter},()=>{
            handleMultifilterIndexScore(finalFilter,name);
        })
    }



    handleFilterClear=()=>{
    let { handleMultifilterIndexScore } = this.props;
    this.setState({finalFilter:[],currFilterList:[]},()=>{
        handleMultifilterIndexScore([]);
    })
    }

    handleSelectAllFilter=()=>{

    }

    handleSearch=(e)=>{
      this.setState({searchName:e.target.value});
    }

  render() {

    const ref = React.createRef();
    let { EmpFilterData2, getIndexFilterData, showValue,  showFilter } = this.props;
    let { searchName, currFilterList,  finalFilter } = this.state;

    let DemographicValueList = {};
    if(getIndexFilterData){
        DemographicValueList = getIndexFilterData;
        // DemographicValueList.sort();
    }



    ////console.log('EmpFilterData2: ',EmpFilterData2)
    ////console.log('currFilterList: ',currFilterList)
    ////console.log('DemographicValueList: ',DemographicValueList)
    ////console.log('finalFilter: ',finalFilter)



    function selectedValue (item){
        let Value = '';
        let getIndex = finalFilter.findIndex(prev=>prev.level===item);
        if(getIndex!==-1){
            if(finalFilter && finalFilter[getIndex] && finalFilter[getIndex].value){
                Value = finalFilter[getIndex].value;
            }
        }
        return Value;
    }


    function SortList(list){
      let temp = [];
      if(list && list.length>0){
        temp= list.sort()
      }
      return temp;
    }


    return (
      <>
         <div className="flex justify-between items-center space-x-4">
       
                   
          {/* 1 */}
          <div className="text-sm border bg-blue-500 px-4 py-1.5 rounded-full flex items-center capitalize whitespace-nowrap mb-4 cursor-pointer transition  duration-150 relative">
            <span className="material-symbols-outlined   mr-2" style={{fontSize: '18px'}}  onClick={()=>this.handleOpenFilter(showFilter)}>sort</span>
            <p  onClick={()=>this.handleOpenFilter(showFilter)} className=" ">{
              currFilterList && currFilterList.length>0?currFilterList[0]:"Select Demographic"

            }</p> 
            <span  onClick={()=>this.handleOpenFilter(showFilter)} className=" material-symbols-outlined text-blue-500">arrow_drop_down</span>
            {showFilter?
            <div className="z-10 absolute right-0 top-10 bg-white shadow-lg font-normal font-xs w-48 overflow-hidden overflow-y-auto space-y-2 h-60" id="chatbot" >
              {EmpFilterData2 && EmpFilterData2.length>0?
                EmpFilterData2.map((dmg,index)=>
                (dmg.name !== "ManagerPID")?
               <div key={index}  >
                    <label  
                    onClick={()=>this.handleSelectFilter(dmg.name)} className="p-2 flex itmes-center hover:bg-blue-100 cursor-pointer w-full text-xs">
                  <input type="checkbox" checked={currFilterList.some(prev=>prev===dmg.name)?true:false} 
                    className="mr-2" onClick={()=>this.handleSelectFilter(dmg.name)} onChange={()=>{}}/>{dmg.label}</label> 
                </div>
                :null
                ):null}
              </div>
             :null}
          </div>
          {/* 2 */}




        </div>
        
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Filter);
