import React, { Component } from "react";
import { connect } from "react-redux";
import Header from '../Components/Header';

function ResponsePage(props) {

  let { getIceCrossDistData, createdAt, GetParameterLabel } = props;

  const elementsPie = Array.from({ length: 3 });

  let optionList = ["Extremely Dissatisfied", "Very Dissatisfied", "Somewhat Dissatisfied", "Somewhat Satisfied", "Very Satisfied", "Extremely Satisfied"]
  let optionColorList = ["#EF5350", "#FF7043", "#FFA726", "#D4E157", "#9CCC65", "#66BB6A"]


  const getCrossScore = (ele, type) => {

    let finalListEmpty = optionList && optionList.length > 0 ? optionList.map((ele) => ({ "name": ele, "count": 0, "score": 0 })) : []


    let rowList = ele && ele.feedbacks && ele.feedbacks.length > 0 ? ele.feedbacks : [];
    if (rowList && rowList.length > 0) {

      let list = [];

      if (type === "Self") {
        list = rowList.filter(item => item.feedback.includes('Self'));
      } else if (type === "Cross") {
        list = rowList.filter(item => !item.feedback.includes('Self'));
      }


      let optCountList = [];
      let totalSum = 0;

      if (optionList && optionList.length > 0) {
        optionList.forEach((opt) => {

          let sumCount = 0
          if (list && list.length > 0) {
            list.forEach((inner) => {
              if (inner && inner.answers && inner.answers.length > 0) {
                let index1 = inner.answers.findIndex(prev => prev.answerText === opt);
                if (index1 !== -1) {
                  let score = inner.answers[index1]["count"];
                  if (score) {

                    sumCount += score;

                    totalSum += score;
                  }
                }


              }


            })
          }

          optCountList.push({
            "name": opt,
            "count": sumCount,
            "score": 0
          })


        })
      }

      let finalList = totalSum > 0 && optCountList && optCountList.length > 0 ? optCountList.map((ele) => ({ "name": ele.name, "count": ele.count, "score": parseInt((ele.count * 100) / totalSum) })) : finalListEmpty
      console.log("finalList----->", finalList)
      console.log("totalSum----->", totalSum)

      return finalList


    }


    return finalListEmpty
  }

  console.log("getIceCrossDistData----->", getIceCrossDistData)


  function chunkArray(array, chunkSize) {
    let result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      let chunk = array.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  }

  let size = 5;
  let finalList = getIceCrossDistData && getIceCrossDistData.length > 0 ? chunkArray(getIceCrossDistData, size) : [];

  return (
    <>
      {finalList && finalList.length > 0 &&
        finalList.map((innerList, index) =>
          <section className='w-full p-6 px-6 flex flex-col justify-between page-break'>
            <Header createdAt={createdAt} />

            <div className="flex flex-col space-y-4 w-full ">
              <h1 className="text-2xl font-bold capitalize  bg-[#212121]/90 text-white p-4">
                {index ? "Themes (Continue)" : "Themes"}
              </h1>
              <div className="space-y-4">
                <div className="flex items-center  justify-center bg-[#F1F5F9] p-2 px-4 ">
                  <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]/70   ">
                    <div className="flex items-center ">
                      <div
                        className="p-1.5 rounded-sm bg-[#EF5350]"

                      />
                      <p className="ml-1.5">Extremely Dissatisfied</p>
                    </div>
                    <div className="flex items-center ">
                      <div
                        className="p-1.5 rounded-sm bg-[#FF7043]"
                      />
                      <p className="ml-1.5">Very Dissatisfied</p>
                    </div>
                    <div className="flex items-center ">
                      <div
                        className="p-1.5 rounded-sm bg-[#FFA726]"
                      />
                      <p className="ml-1.5">Somewhat Dissatisfied</p>
                    </div>
                    <div className="flex items-center ">
                      <div
                        className="p-1.5 rounded-sm bg-[#D4E157]"
                      />
                      <p className="ml-1.5">Somewhat Satisfied</p>
                    </div>
                    <div className="flex items-center ">
                      <div
                        className="p-1.5 rounded-sm bg-[#9CCC65]"
                      />
                      <p className="ml-1.5">Very Satisfied</p>
                    </div>
                    <div className="flex items-center ">
                      <div
                        className="p-1.5 rounded-sm bg-[#66BB6A]"
                      />
                      <p className="ml-1.5">Extremely Satisfied</p>
                    </div>
                  </div>
                  <div className="hidden flex flex-wrap justify-start gap-2 text-xs text-[#212121]/70 border-l-2 pl-4  ">
                    <div className="flex items-center ">
                      <div
                        className="p-1.5 rounded-sm bg-[#42A5F5]"
                      />
                      <p className="ml-1.5">Less than 6 months</p>
                    </div>
                    <div className="flex items-center ">
                      <div
                        className="p-1.5 rounded-sm bg-[#9575CD]"
                      />
                      <p className="ml-1.5">6 months to 1 year</p>
                    </div>
                    <div className="flex items-center ">
                      <div
                        className="p-1.5 rounded-sm bg-[#FFA726]"
                      />
                      <p className="ml-1.5">1 to 3 years</p>
                    </div>
                    <div className="flex items-center ">
                      <div
                        className="p-1.5 rounded-sm bg-[#66BB6A]"
                      />
                      <p className="ml-1.5">3 to 5 years</p>
                    </div>
                    <div className="flex items-center ">
                      <div
                        className="p-1.5 rounded-sm bg-[#EF5350]"
                      />
                      <p className="ml-1.5">6 to 10 years</p>
                    </div>
                  </div>
                </div>
                {innerList && innerList.length > 0 && innerList.map((ele, index2) => (
                  <div className="space-y-4 ">
                    <p className="text-base font-bold text-[#212121] bg-[#F1F5F9] p-2 px-4">{(index * size) + (index2 + 1)}.  {ele && ele.parameterDimensionName?GetParameterLabel(ele.parameterDimensionName):""}</p>
                    <div className="grid grid-cols-2 gap-6">
                      {/* <p className="font-bold text-base">Department</p> */}
                      <div className="space-y-2">
                        <>
                          <span className="text-sm">Self</span>
                          <div className="w-full flex items-center text-sm space-x-2">
                            <div className='flex items-end h-16 overflow-hidden space-x-1'>
                              {getCrossScore(ele, "Self") && getCrossScore(ele, "Self").length > 0
                                ? getCrossScore(ele, "Self").map((inner, index) => (
                                  <div className="bg-gray-100 flex items-end h-16">
                                    <div
                                      key={index}
                                      style={{
                                        background: inner && inner.color ? inner.color : optionColorList[index],
                                        height: (inner && inner.score ? inner.score : 0) + "%",
                                      }}
                                      className='flex items-end justify-center text-[#212121] font-medium w-16 px-2  h-16'>
                                      <span className='text-sm'>
                                        {true ? inner.score + "%" : ""}
                                      </span>
                                    </div>
                                  </div>
                                ))
                                : null}
                            </div>
                          </div>
                        </>
                      </div>
                      <div className="space-y-2">
                        <span className="text-sm">Cross-Functional</span>
                        <div className="w-full flex items-center text-sm space-x-2">

                          <div className='flex items-end h-16 overflow-hidden space-x-1'>
                            {getCrossScore(ele, "Cross") && getCrossScore(ele, "Cross").length > 0
                              ? getCrossScore(ele, "Cross").map((inner, index) => (
                                <div className="bg-gray-100 flex items-end h-16">
                                  <div
                                    key={index}
                                    style={{
                                      background: inner && inner.color ? inner.color : optionColorList[index],
                                      height: (inner && inner.score ? inner.score : 0) + "%",
                                    }}
                                    className='flex items-end justify-center text-[#212121] font-medium w-16 px-2'
                                  >
                                    <span className='text-sm'>
                                      {true ? inner.score + "%" : ""}
                                    </span>
                                  </div>
                                </div>
                              ))
                              : null}
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                ))}


              </div>
            </div >

          </section>
        )}

    </>

  );
}
export default ResponsePage;
