import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class DriverRadar extends Component {
    constructor(props) {
        super(props);
        this.state = {
    
      
        }
      }
    
      componentDidMount() {
    
      }
    
    
      render() {
        let { get360DimensionRelationScoreData, GetParameterLabel } = this.props;
        
        function getListSorted(list){
          if(list && list.length>0){
            let List1 = list.filter(prev=>prev.relation ==="Self")
            let List2 = list.filter(prev=>prev.relation !=="Self")
            let RelationListMainA = List1.concat(List2)
            return RelationListMainA
          }else{
            return []
          }
        }

        let get360DimensionRelationScoreList = []
        if(get360DimensionRelationScoreData && get360DimensionRelationScoreData.length>0){
          get360DimensionRelationScoreList = get360DimensionRelationScoreData.map((element)=>(element))
          get360DimensionRelationScoreList.sort((a,b)=>((a && a.item && a.item._id?a.item._id:"").charCodeAt(0)-(b && b.item && b.item._id?b.item._id:"").charCodeAt(0)))
        }

          let ValueList = [];
          let DimensionList = [];
          let RelationList = [];
          let RelationListMain = [];

          if(get360DimensionRelationScoreList && get360DimensionRelationScoreList.length>0){
            get360DimensionRelationScoreList.forEach((item)=>{
              let dimension1 = item && item._id?item._id:"";
  
              let dimension = dimension1;

  
              let temp = {
                "dimen_xyz":dimension
              }

              let miniList = []
              if(item && item.total && item.total.length>0){
                item.total.forEach((inner)=>{
                  if(inner && inner._id && inner._id.relation){
                    miniList.push({
                      "relation":inner._id.relation,
                      "mean":inner && inner.mean?inner.mean:0
                    })
                  }
                })
              }
              let miniListSorted = getListSorted(miniList)





              if(miniListSorted && miniListSorted.length>0){
                miniListSorted.forEach((inner)=>{
                  if(inner && inner.relation){

                    let scoreMean1 = inner && inner.mean?inner.mean:0;

                    let scoreMean = scoreMean1.toFixed(2);

                    temp[inner.relation] = scoreMean;
  
                    let getIndex2 = RelationList.findIndex(prev=>prev.name === inner.relation);
                    if(getIndex2 ===-1){
                      let tempx = { 
                        name: inner.relation, 
                        value: [scoreMean]
                      }
                      RelationList.push(tempx);
                      RelationListMain.push(tempx)

                    }else{
                      RelationList[getIndex2]["value"].push(scoreMean)
                    }
        
  
  
                  }
                })
              }


              DimensionList.push({ name: dimension, max: 6 })

  
  
              ValueList.push(temp)
            })
          }
  
          //console.log('-------->>>>DimensionList; ',DimensionList)
          //console.log('-------->>>>RelationList; ',RelationList)
          //console.log('-------->>>>ValueList; ',ValueList)


          

          let sourceList = [
            ['Dimensions']
          ]
          if(RelationList && RelationList.length>0){
            RelationList.forEach((item)=>{
              sourceList[0].push(item.name)
            })
          }
          if(ValueList && ValueList.length>0){
            ValueList.forEach((item1)=>{
              let eachDimen = [GetParameterLabel(item1.dimen_xyz)]
              if(RelationList && RelationList.length>0){
                RelationList.forEach((item2)=>{
                  eachDimen.push(item1[item2.name])
                })
              }
              sourceList.push(eachDimen)
            })
          }
          //console.log('-------->>>>sourceList; ',sourceList)


          // //console.log('-------->>>>sourceList; ',sourceList)
          // //console.log('-------->>>>sample; ',[
          //   ['product', '2015', '2016', '2017'],
          //   ['Matcha Latte', 43.3, 85.8, 93.7],
          //   ['Milk Tea', 83.1, 73.4, 55.1],
          //   ['Cheese Cocoa', 86.4, 65.2, 82.5],
          //   ['Walnut Brownie', 72.4, 53.9, 39.1]
          // ])
          // var first = "Self";
          // sourceList.sort(function(x,y){ return x == first ? -1 : y == first ? 1 : 0; });

          // function getListSorted(list){
          //   if(list && list.length>0){
          //     let List1 = list.filter(prev=>prev ==="Self")
          //     let List2 = list.filter(prev=>prev !=="Self")
          //     let RelationListMainA = List1.concat(List2)
          //     return RelationListMainA
          //   }else{
          //     return []
          //   }
          // }
          // let sourceListA = getListSorted(sourceList)

    
        return (
          <>     
    <div className="bg-white border  p-4 rounded-md   cursor-pointer">
        <div className='space-y-6' >
          <h1 className="font-medium text-lg ">Relationship Score Summary Across Dimension</h1>

          {get360DimensionRelationScoreList && get360DimensionRelationScoreList.length>0?
          <div className="  w-full  mb-4 flex  justify-center">
          <ReactEcharts className='w-full'
            style={{ height: '500px' }}

            option={
              {
                legend: {},
                tooltip: {},
                dataset: {
                  source: sourceList
                },
                xAxis: { type: 'category'},
                yAxis: {},
                // Declare several bar series, each will be mapped
                // to a column of dataset.source by default.
                series: [{ type: 'bar',label:{show:true,position: 'top'} }, { type: 'bar',label:{show:true,position: 'top'} }, { type: 'bar',label:{show:true,position: 'top'} }, { type: 'bar',label:{show:true,position: 'top'} }]
              }}
                            
          />

          </div>
          :
            <>
            <div className="text-center text-3xl text-gray-400 pt-40">Data Not Available!</div>
            </>}


        </div>
      </div>


          </>
        );
      }
    }
    function mapStateToProps(state) {
      // //////console.log("state  ", state);
      const { loggingIn } = state.authentication;
      const { users, dashboard } = state;
      return {
        loggingIn,
        users,
        dashboard
      };
    }
export default connect(mapStateToProps)(DriverRadar);
