import React, { Component } from "react";
import { connect } from "react-redux";
import BarCorelation from "../../Charts/BarCorelation";
import Commentary from "../../../../../Index/Components/Commentary/Commentary";
import Filter from "./Filter";
import Parameter from "./Parameter";

class DimensionIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFilterName: "none",
      currentFilterValue: "none",
      currentSelectValue: "",
      FilterValues: [],
      showFilter: false,
      showValue: "",
      showParaFilter: false,
      showParaValue: "",

      showTargetDropdown: false,
      selectedTargetValue: "",
      showCompareDropdown: false,
      compareDimenList: [],
      isSelectAll: false,
    };
  }
  componentDidMount() { }

  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleMultiFilterCorrelation, OptType } = this.props;
    this.setState({ FilterValues: finalFilter });
    // handleMultiFilterCorrelation(OptType,finalFilter,true, name);
    this.submitTargetCompareCorrelation(true, finalFilter);
  };

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };
  closeParaFilter = (check1, check2) => {
    this.setState({ showParaFilter: check1, showParaValue: check2 });
  };

  handleTargetDropdown = (check1, initialValue) => {
    this.setState({ showTargetDropdown: check1, showCompareDropdown: false });
    this.setState({ selectedTargetValue: initialValue });
  };

  selectTargetDimension = (value) => {
    let { compareDimenList } = this.state;

    let getIndex = compareDimenList.findIndex((prev) => prev === value);
    if (getIndex !== -1) {
      compareDimenList.splice(getIndex, 1);
    }
    this.setState({ compareDimenList });

    this.setState({
      selectedTargetValue: value,
      showTargetDropdown: false,
      showCompareDropdown: true,
    });
  };

  handleCompareDropdown = (check1, initialValue) => {
    this.setState({ showCompareDropdown: check1, showTargetDropdown: false });
    this.setState({ selectedTargetValue: initialValue });
  };

  submitTargetCompareCorrelation = (isfilter, FiltersNew) => {
    let { FilterValues, selectedTargetValue, compareDimenList } = this.state;
    let { handleMultiFilterCorrelation } = this.props;
    if (
      selectedTargetValue &&
      compareDimenList &&
      compareDimenList.length > 0
    ) {
      this.setState({ showTargetDropdown: false, showCompareDropdown: false });
      handleMultiFilterCorrelation(
        isfilter ? FiltersNew : FilterValues,
        selectedTargetValue,
        compareDimenList
      );
    }
  };

  selectMultiDimension = (value, validParameterList) => {
    let { compareDimenList, selectedTargetValue, isSelectAll } = this.state;
    if (selectedTargetValue) {
      if (value === "all") {
        if (isSelectAll) {
          this.setState({ isSelectAll: false, compareDimenList: [] });
        } else {
          this.setState({ isSelectAll: true });
          let tempList = [];
          if (validParameterList && validParameterList.length > 0) {
            validParameterList.forEach((item) => {
              if (item !== selectedTargetValue) {
                ////console.log("selectedTargetValue--->",selectedTargetValue)
                tempList.push(item);
              }
            });
          }
          ////console.log("tempList--->",tempList)
          this.setState({ compareDimenList: tempList });
        }
      } else {
        this.setState({ isSelectAll: false });
        let getIndex = compareDimenList.findIndex((prev) => prev === value);
        if (getIndex === -1) {
          if (value !== selectedTargetValue) {
            compareDimenList.push(value);
          }
        } else {
          compareDimenList.splice(getIndex, 1);
        }
        this.setState({ compareDimenList });
      }
    }
  };

  render() {
    let {
      ErrorOtherObj,
      getCorrelationDimenionVsDimensionError,
      getEMPFilterLabel,
      getCorrelationData,
      getCorrelationFilterData,
      validateList,
      IndexDimension,
      CountRestrict,
      GetParameterLabel,
      XAxis,
      XAxisName,
      Name,
      loading,
      indexType,
      optionType,
      getCommentData,
      dimensionTypeNo,
      EmpFilterData,
      getIndexFilterData,
      handleCorrelationFilter,
      getSurveyData,
      getIndexByFilterDMGData,
    } = this.props;

    let { isSelectAll } = this.state;
    let validParameterList = [];

    //Index dimension names
    if (validateList && validateList.length > 0) {
      validateList.forEach((indexObj) => {
        if (
          IndexDimension &&
          IndexDimension[indexObj.name] &&
          IndexDimension[indexObj.name].length > 0
        ) {
          IndexDimension[indexObj.name].forEach((elem) => {
            validParameterList.push(elem);
          });
        }
      });
    }

    //Organization parameter names
    if (
      getSurveyData &&
      getSurveyData.orgList &&
      getSurveyData.orgList.length > 0
    ) {
      getSurveyData.orgList.forEach((org) => {
        validParameterList.push(org.name);
      });
    }

    let { currentFilterName, currentFilterValue } = this.state;

    let filterNames = [];
    let filterValues = [];
    let filterVal1 = "none";

    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((item) => {
        filterNames.push(item.name);
      });
    }

    let CurrentFilterName = "";
    if (currentFilterName !== "none") {
      CurrentFilterName = currentFilterName;
    } else if (filterNames && filterNames.length > 0) {
      CurrentFilterName = filterNames[0];
    }
    if (getIndexFilterData) {
      filterValues = getIndexFilterData[CurrentFilterName];
    }

    if (currentFilterValue !== "none") {
      filterVal1 = currentFilterValue;
    } else if (filterValues && filterValues.length > 0) {
      filterVal1 = filterValues[0];
    }

    console.log(
      "==============================================> validParameterList",
      validParameterList
    );

    let { currentSelectValue, FilterValues } = this.state;

    let EmpFilterData2 = [];
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== "ManagerPID") {
          EmpFilterData2.push(emp);
        }
      });
    }

    let isDisable = true;
    //  let DemographicDataScore = [];
    //  let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {
      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === "All") {
            isDisable = false;
          }
        });
      }

      //  DemographicDataScore=getDemographicIndexMultiFilterData;
      //  DemographicDataDistribution=getIndexDistributionData;
    }

    let apiData = [];
    if (FilterValues && FilterValues.length > 0) {
      let isAvail = 0;
      FilterValues.forEach((item) => {
        if (item.value !== "All") {
          isAvail++;
        }
      });

      if (isAvail) {
        apiData = getCorrelationFilterData;
      } else {
        apiData = getCorrelationData;
      }
    } else {
      apiData = getCorrelationData;
    }

    console.log("apiData; ", apiData);

    console.log("FilterValues===================", this.state.FilterValues);
    console.log(
      "selectedTargetValue===================",
      this.state.selectedTargetValue
    );
    console.log(
      "compareDimenList===================",
      this.state.compareDimenList
    );

    let itemListX = [];

    let count =
      getCorrelationData && getCorrelationData[1] && getCorrelationData[1].count
        ? getCorrelationData[1].count
        : "NaN";

    if (getCorrelationData && getCorrelationData.length > 0) {
      itemListX = Object.keys(getCorrelationData[0]);
    }

    let itemList = CountRestrict(count) ? [] : itemListX;

    let CorrelationOverall = [];
    if (itemList && itemList.length > 0) {
      itemList.forEach((element) => {
        if (element !== "name") {
          CorrelationOverall.push({
            label: GetParameterLabel(element.slice(0, -3)),
            score:
              getCorrelationData &&
                getCorrelationData[0] &&
                getCorrelationData[0][element]
                ? getCorrelationData[0][element]
                : 0,
          });
        }
      });
    }

    CorrelationOverall.sort((a, b) => b.score - a.score);

    let XAxis1 = [];
    let List = [];

    if (CorrelationOverall && CorrelationOverall.length > 0) {
      CorrelationOverall.forEach((item) => {
        XAxis1.push(GetParameterLabel(item.label));
        List.push(item.score ? item.score : 0);
      });
    }

    return (
      <>
        {/* <div className="items-center justify-between h-10 px-4 py-3 bg-white border-b xl:flex lg:flex md:flex">
        <Filter
          showFilter={this.state.showFilter}
          showValue={this.state.showValue}
          closeFilter={this.closeFilter}

          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexByFilterDMGData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        />
        <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

      </div> */}
        <div className="flex flex-col justify-between gap-4 md:items-center md:flex-row">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}
            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />


          <Parameter
            validParameterList={validParameterList}
            showParaFilter={this.state.showParaFilter}
            showParaValue={this.state.showParaValue}
            closeParaFilter={this.closeParaFilter}
            showTargetDropdown={this.state.showTargetDropdown}
            handleTargetDropdown={this.handleTargetDropdown}
            selectedTargetValue={this.state.selectedTargetValue}
            selectTargetDimension={this.selectTargetDimension}
            showCompareDropdown={this.state.showCompareDropdown}
            handleCompareDropdown={this.handleCompareDropdown}
            selectMultiDimension={this.selectMultiDimension}
            compareDimenList={this.state.compareDimenList}
            submitTargetCompareCorrelation={this.submitTargetCompareCorrelation}
            GetParameterLabel={GetParameterLabel}
            List={List}
            isSelectAll={isSelectAll}
          />


          <Commentary
            indexType={indexType}
            optionType={optionType}
            getCommentData={getCommentData}
          />
        </div>

        {true ? (
          <>
            {/* <div className="items-center justify-start xl:flex lg:flex md:flex"> */}

            {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} /> */}

            {/* <Filter 
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        /> */}

            {/* </div> */}

            {/* {FilterValues && FilterValues.length > 0 ?
          <div className="items-center h-10 px-4 py-3 bg-white border-b xl:flex lg:flex md:flex justify-left">
            {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
              <p className="flex mx-2 my-4 text-sm"><p className="mr-1 text-blue-500">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
            ) : null}</div>
          : null} */}

            <div onClick={() => this.closeFilter(false)} className="">
              {/* <div className="flex w-full" >
                
              
                <div className="justify-center w-full p-4 m-4 mr-2 bg-white shadow-lg" > */}

              <div className="p-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap ">
                  Correlation
                </h1>

                {ErrorOtherObj && ErrorOtherObj["getDemographicIndexError"] ? (
                  <div className="my-24 text-2xl font-medium text-center text-gray-400">
                    {ErrorOtherObj["getDemographicIndexError"]}
                  </div>
                ) : (
                  <>
                    {apiData ? (
                      <div className="py-4 ">
                        <BarCorelation
                          XAxis1={XAxis1}
                          List={List}
                          getCorrelationData={apiData}
                          CountRestrict={CountRestrict}
                          XAxisName={XAxisName}
                          XAxis={XAxis}
                          GetParameterLabel={GetParameterLabel}
                        />
                      </div>
                    ) : (
                      <div className="p-2 text-lg text-center text-gray-500">
                        {getCorrelationDimenionVsDimensionError}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            {!loading ? (
              <div className="pt-40 text-3xl text-center text-gray-400">
                We have not calculated this section!
              </div>
            ) : null}
          </>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(DimensionIndex);
