import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TbMailCheck } from "react-icons/tb";
class IndexKPI extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { getRespondantDetailsData } = this.props;


    let ParticipationObj = {
      total: 0,
      completed: 0,
      mailSent: 0,
      clicked: 0,
      participated: 0,
      performance: 0,
      perfPlusSign: true
    }

    if (getRespondantDetailsData && getRespondantDetailsData[0]) {
      let apiData = getRespondantDetailsData[0]
      ParticipationObj['total'] = apiData['total'] ? apiData['total'] : 0;
      ParticipationObj['completed'] = apiData['completed'] ? apiData['completed'] : 0;
      ParticipationObj['mailSent'] = apiData['mailSent'] ? apiData['mailSent'] : 0;
      ParticipationObj['clicked'] = apiData['clicked'] ? apiData['clicked'] : 0;
      ParticipationObj['participated'] = apiData['completed'] && apiData['total'] ? (apiData['completed'] * 100 / apiData['total']).toFixed(2) : 0;



    }



    // indexType={indexType}
    // getIndexData={getIndexData}
    // getIndexScore={getIndexScore}
    // getAllBenchMarkData={getAllBenchMarkData}
    // getRespondantDetailsData={getRespondantDetailsData}

    return (
      <>
        <div className="flex items-center h-32 p-6 border rounded-lg cursor-pointer  bg-white ">
          <div className="flex items-center w-full ">
            <div className="space-y-2">
              <p className="block text-base text-[#212112]/80 w-full capitalize ">
                Sent</p>
              <h1 className="text-3xl font-medium w-full text-[#212121]">
                {ParticipationObj['total']}
              </h1>
            </div>
          </div>
          <div className="p-4 bg-[#2196F3]/10 text-[#2196F3] rounded-md">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail-check" width="50" height="50" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M11 19h-6a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v6" /><path d="M3 7l9 6l9 -6" /><path d="M15 19l2 2l4 -4" /></svg>
          </div> 
        </div>

        <div className="flex items-center h-32 p-6 border rounded-lg cursor-pointer  bg-white ">
          <div className="flex items-center w-full">
            <div className="space-y-2">
              <p className="block text-base text-[#212112]/80 w-full capitalize ">Responded</p>
              <h1 className="text-3xl font-medium w-full text-[#212121]">
                {ParticipationObj['completed']}
              </h1>

            </div>
          </div>

          <div className="p-4 bg-[#2196F3]/10 text-[#2196F3] rounded-md">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-hand-click"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M8 13v-8.5a1.5 1.5 0 0 1 3 0v7.5" /><path d="M11 11.5v-2a1.5 1.5 0 0 1 3 0v2.5" /><path d="M14 10.5a1.5 1.5 0 0 1 3 0v1.5" /><path d="M17 11.5a1.5 1.5 0 0 1 3 0v4.5a6 6 0 0 1 -6 6h-2h.208a6 6 0 0 1 -5.012 -2.7l-.196 -.3c-.312 -.479 -1.407 -2.388 -3.286 -5.728a1.5 1.5 0 0 1 .536 -2.022a1.867 1.867 0 0 1 2.28 .28l1.47 1.47" /><path d="M5 3l-1 -1" /><path d="M4 7h-1" /><path d="M14 3l1 -1" /><path d="M15 6h1" /></svg>
          </div>
        </div>

        <div className="flex items-center h-32 p-6 border rounded-lg cursor-pointer  bg-white ">
          <div className="flex items-center w-full ">
            <div className="space-y-2">
              <p className="block text-base text-[#212112]/80 w-full capitalize ">Response Rate</p>
              <h1 className="text-3xl font-medium w-full text-[#212121]">
                {ParticipationObj['participated'] + '%'}
              </h1> 
            </div>
          </div>
          <div className="p-4 bg-[#2196F3]/10 text-[#2196F3] rounded-md">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-circle-percentage"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M9 15.075l6 -6" /><path d="M9 9.105v.015" /><path d="M15 15.12v.015" /></svg>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(IndexKPI);
