
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const surveyService = {
    // logout,
    getSurveyList,
    getQuestionTypeList,
    getSurveyById,
    onSurveyQueRandomize,

    getParameterList,

    updateSurveyWelcome,

    getParameterListbyParentId,
    getParaLibraryByParentNames,

    getCustomerResponseAnalyticsPagination,
    setExpireSurvey,

    createSurvey,
    deleteSurvey,
    updateSurvey,

    responseAnalysisBySurveyId,
    response360AnalysisBySurveyId,
    getCampaignEmployeesByIDSurveyWise,

    resendCampain,
    resendAssessmentCampain,
    resend360Campain,
    createSurveyFromLib,
    getSurveyFromLib,

    getEmployeeList,
    getEmployeesByEmpIdList,
    getEmployeeSearch,
    getEmployeeBatchRange,
    getCustomerBatchRange,

    saveEmployeeList,
    saveCustomerList,


    updateEmployeeStatus,
    updateCustomFilterStatus,

    getLanguageLabel,
    getLanguage,

    getEmployeeColumnName,
    getEmployeeFilterListByCompanyId,
    getEmployeeFilterListBySurveyId,
    createEmployeeFilter,
    createSurveyFilter,

    updateEmployeeFilterStatus,
    updateEmployeeShowReportStatus,
    deleteEmployeeFilter,
    assignRole,
    updateEmployee,
    getQuestionLibraryList,
    getAllFilterValues,

    getAssignedRoles,
    updateEmployeeFilterRank,
    getIndexByFilterDMG,
    getResponseAnalyticsPagination,
    getResponse360AnalyticsPagination,
    getResponseAnalyticsKeyWord,
    removeRole,
    updateSurveyDesign,
    getAllResponseAnalyticsBySurveyId,

    customerResponsePaginationByCampaignId,
    updateEmployeeEngagementComparisonStatus,
    updateEmployeeEDAStatus,

    updateEmployeeFilterLabel,

    getParameterLibraryListbyParameterType,
    copyParaLibToParameter,
    autoUpdateParameters,
    getEmployeeLimitedList,
    createEmployeeRecord,
    getLifecycleStageList,
    allTooltipList,
    getDefaultRiskSurvey,
    updateSurveyClusterSurveyId,
    getEmployeeFields,
    getCustomerFields,

    loadDefaultRecDaysSurvey,
    loadDefaultOnboardingSurvey,
    updateActiveStatusRecCampaign,
    allLifecycleListenList,
    generateCompanyBotLink,

    getSurveyOneTemplate,

    getSurveyListByCategory,
    changeLifecycleDashStatus,
    save360RelationList,
    savePMSRelationList,

    saveCustEmpRelationList,

    get360RelationList,
    getPMSRelationList,

    getCustEmpRelationList,
    handle360FeedbackDisable,
    setSurveyCampaignType,
    copy360Parameters,
    getSurveyListV3,
    getSurveyListV4,
    getParameterLabelByName,
    saveEmpDateField,

    getSurveyListByRole,
    autoChangeDashboardType,
    getAllEmployeeList,
    copyPackageParameters,
    getTranslateElementAll,
    getAssessmentByTopBottom,
    getResponseAssessmentShortCode,
    copySurvey,
    updateEmpFilterDMGStatus,
    getSurveyDMGLaunchFilter,
    getSurveyLaunchByDMGValue,
    responseIceDownloadBySurveyId,
    getQuestionNameIDOnly
};
function logout() {
    localStorage.removeItem('adminuser');
    window.location.href = "#/login";
}

function handleResponse(response) {
    // //////console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logoutSession();
                //location.reload(true);
            }
            // //////console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        ////////console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            ////////console.log("datadatadatadatadata ---------------------->  ", data);
            if (data.code === 3) {
                logoutSession();
            }
            if (data && data.message && (data.message === "UnauthorizedError")) {
                logout()

            }
            //              else if (data.code===1) {
            //                 logoutSession();
            //             }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}



function handleResponse2(response) {
    // //////console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            // //////console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        ////////console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            // //////console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}







function logoutSession(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/userlogout`, requestOptions)
        .then(handleResponse2)
        .then(user => {
            let userObj = {
                userLogout: user
            }
            localStorage.removeItem('adminuser');
            window.location.href = "#/login";
            return userObj;
        });
}




async function getSurveyList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getSurveyListV2`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let surveyObj = {
                getSurveyListData: data.data
            }
            //console.log();

            return surveyObj;
        });
}


async function getQuestionNameIDOnly(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getQuestionNameIDOnly`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let surveyObj = {
                getQuestionNameIDOnlyData: data.data
            }
            //console.log();

            return surveyObj;
        });
}


async function getQuestionTypeList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getQuestionTypeList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getQuestionTypeListData: data.data
            }
            //console.log();
            return surveyObj;
        });
}


async function getSurveyLaunchByDMGValue(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getSurveyLaunchByDMGValue`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getSurveyLaunchByDMGValueData: data.data
            }
            //console.log();
            return surveyObj;
        });
}



async function getSurveyById(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getSurveyById`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                getSurveyData: data.data
            }
            //console.log();

            return companyObj;
        });
}

async function onSurveyQueRandomize(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/onSurveyQueRandomize`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                onSurveyQueRandomizeData: data.data
            }

            return companyObj;
        });
}

async function getParameterList(data) {
    //////console.log("Data Ready For API: ",data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getParameterList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let parameterObj = {
                getParameterListData: data.data
            }

            return parameterObj;
        });
}



async function getSurveyDMGLaunchFilter(data) {
    //////console.log("Data Ready For API: ",data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getSurveyDMGLaunchFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let parameterObj = {
                getSurveyDMGLaunchFilterData: data.data
            }

            return parameterObj;
        });
}

async function updateSurveyWelcome(data) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/updateSurveyWelcomeV1`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                updateSurvey: data.data
            }
            //console.log();

            return companyObj;
        });
}



async function getParameterListbyParentId(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getParameterListbyParentId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let parameterObj = {
                getParameterListbyParentData: data.data
            }

            return parameterObj;
        });
}

async function getParaLibraryByParentNames(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getParaLibraryByParentNames`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let parameterObj = {
                getParaLibraryByParentNamesData: data.data
            }

            return parameterObj;
        });
}


async function setExpireSurvey(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/setExpireSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let surveyObj = {
                setExpireSurveyData: data.data
            }

            return surveyObj;
        });
}


async function createSurvey(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/createSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let surveyObj = {
                createSurveyData: data.data
            }

            return surveyObj;
        });
}


async function deleteSurvey(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/deleteSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let surveyObj = {
                deleteSurveyData: data.data
            }

            return surveyObj;
        });
}


async function updateSurvey(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/updateSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let surveyObj = {
                updateSurveyData: data.data
            }

            return surveyObj;
        });
}

async function responseAnalysisBySurveyId(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/responseAnalysisBySurveyId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            // //console.log('x responseAnalysisBySurveyIdData',data.data)
            let surveyObj = {
                responseAnalysisBySurveyIdData: data.data
            }

            return surveyObj;
        });
}


async function response360AnalysisBySurveyId(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/response360AnalysisBySurveyId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            // //console.log('x responseAnalysisBySurveyIdData',data.data)
            let surveyObj = {
                responseAnalysisBySurveyIdData: data.data
            }

            return surveyObj;
        });
}


async function getCampaignEmployeesByIDSurveyWise(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getCampaignEmployeesByIDSurveyWise`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getCampaignEmployeesByIDSurveyWiseData: data.data
            }
            //console.log('x getCampaignEmployeesByIDSurveyWise',surveyObj)

            return surveyObj;
        });
}


async function resendCampain(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/resendCampain`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                resendCampainData: data.data
            }
            return surveyObj;
        });
}


async function resendAssessmentCampain(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/resendAssessmentCampain`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                resendAssessmentCampainData: data.data
            }
            return surveyObj;
        });
}

async function resend360Campain(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/resend360Campain`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                resend360CampainData: data.data
            }
            return surveyObj;
        });
}



async function createSurveyFromLib(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/createSurveyFromLib`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                createSurveyFromLibData: data.data
            }
            return surveyObj;
        });
}


async function getSurveyFromLib(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getSurveyFromLib`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getSurveyFromLibData: data.data
            }
            return surveyObj;
        });
}



async function getEmployeeList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getEmployeeList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getEmployeeListData: data.data
            }
            return surveyObj;
        });
}


async function getEmployeesByEmpIdList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getEmployeesByEmpIdList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getEmployeesByEmpIdListData: data.data
            }
            return surveyObj;
        });
}


async function getEmployeeSearch(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getEmployeeList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("data-----------------------------------------------------------------------------.",data.data)
            let surveyObj = {
                getEmployeeSearchData: data.data
            }
            return surveyObj;
        });
}




async function getEmployeeBatchRange(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getEmployeeBatchByFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getEmployeeBatchRangeData: data.data
            }
            return surveyObj;
        });
}


async function getCustomerBatchRange(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getCustomerBatchRange`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getCustomerBatchRangeData: data.data
            }
            return surveyObj;
        });
}



async function saveEmployeeList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/saveEmployeeList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                saveEmployeeListData: data.data
            }
            return surveyObj;
        });
}

async function saveCustomerList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/saveCustomerList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                saveCustomerListData: data.data
            }
            return surveyObj;
        });
}



async function save360RelationList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/save360RelationList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                save360RelationListData: data.data
            }
            return surveyObj;
        });
}

async function savePMSRelationList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/savePMSRelationList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                savePMSRelationListData: data.data
            }
            return surveyObj;
        });
}



async function saveCustEmpRelationList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/saveCustEmpRelationList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                saveCustEmpRelationListData: data.data
            }
            return surveyObj;
        });
}





async function updateEmployeeStatus(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/updateEmployeeStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                updateEmployeeStatusData: data.data
            }
            return surveyObj;
        });
}


async function updateCustomFilterStatus(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/updateCustomFilterStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                updateCustomFilterStatusData: data.data
            }
            return surveyObj;
        });
}




async function getLanguageLabel(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getLanguageLabel`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getLanguageLabelData: data.data
            }
            return surveyObj;
        });
}

async function getLanguage(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getLanguage`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getLanguageData: data.data
            }
            return surveyObj;
        });
}

async function getEmployeeColumnName(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getEmployeeColumnName`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getEmployeeColumnNameData: data.data
            }
            return surveyObj;
        });
}


async function getEmployeeFilterListByCompanyId(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getEmployeeFilterListByCompanyId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getEmployeeFilterListByCompanyIdData: data.data
            }
            return surveyObj;
        });
}



async function getEmployeeFilterListBySurveyId(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getEmployeeFilterListBySurveyId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getEmployeeFilterListByCompanyIdData: data.data
            }
            return surveyObj;
        });
}




async function createEmployeeFilter(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/createEmployeeFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                createEmployeeFilterData: data.data
            }
            return surveyObj;
        });
}

async function createSurveyFilter(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/createSurveyFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                createEmployeeFilterData: data.data
            }
            return surveyObj;
        });
}



async function updateEmployeeFilterStatus(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/updateEmployeeFilterStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                updateEmployeeFilterStatusData: data.data
            }
            return surveyObj;
        });
}

async function updateEmployeeShowReportStatus(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/updateEmployeeShowReportStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                updateEmployeeShowReportStatusData: data.data
            }
            return surveyObj;
        });
}

async function deleteEmployeeFilter(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/deleteEmployeeFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                deleteEmployeeFilterData: data.data
            }
            return surveyObj;
        });
}


async function assignRole(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/assignRole`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                assignRoleData: data.data
            }
            return surveyObj;
        });
}


async function updateEmployee(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/updateEmployee`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                updateEmployeeData: data.data
            }
            return surveyObj;
        });
}

async function getQuestionLibraryList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getQuestionLibraryList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getQuestionLibraryListData: data.data
            }
            return surveyObj;
        });
}



async function getAllFilterValues(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getAllFilterValues`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getAllFilterValuesData: data.data
            }
            return surveyObj;
        });
}

async function updateEmployeeFilterRank(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/updateEmployeeFilterRank`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getAllFilterValuesData: data.data
            }
            return surveyObj;
        });
}

async function getAssignedRoles(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getAssignedRolesV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getAssignedRolesData: data.data
            }
            return surveyObj;
        });
}


async function getIndexByFilterDMG(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getIndexByFilterDMG`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getIndexByFilterDMGData: data.data
            }
            return surveyObj;
        });
}


async function getResponseAnalyticsPagination(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getResponseAnalyticsPagination`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getResponseAnalyticsPaginationData: data.data
            }
            return surveyObj;
        });
}


async function getCustomerResponseAnalyticsPagination(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getCustomerResponseAnalyticsPagination`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getResponseAnalyticsPaginationData: data.data
            }
            return surveyObj;
        });
}




async function getResponse360AnalyticsPagination(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getResponse360AnalyticsPagination`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getResponseAnalyticsPaginationData: data.data
            }
            return surveyObj;
        });
}



async function getResponseAnalyticsKeyWord(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getResponseAnalyticsKeyWord`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getResponseAnalyticsPaginationData: data.data
            }
            return surveyObj;
        });
}





async function removeRole(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/removeRole`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                removeRoleData: data.data
            }
            return surveyObj;
        });
}

async function updateSurveyDesign(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/updateSurveyDesign`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                updateSurveyDesignData: data.data
            }
            return surveyObj;
        });
}



async function getAllResponseAnalyticsBySurveyId(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getAllResponseAnalyticsBySurveyId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getAllResponseAnalyticsBySurveyIdData: data.data
            }
            return surveyObj;
        });
}



async function customerResponsePaginationByCampaignId(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/customerResponsePaginationByCampaignId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                customerResponsePaginationByCampaignIdData: data.data
            }
            return surveyObj;
        });
}



async function updateEmployeeEngagementComparisonStatus(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/updateEmployeeEngagementComparisonStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                updateEmployeeEngagementComparisonStatusData: data.data
            }
            return surveyObj;
        });
}



async function updateEmpFilterDMGStatus(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/updateEmpFilterDMGStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                updateEmpFilterDMGStatusData: data.data
            }
            return surveyObj;
        });
}



async function updateEmployeeEDAStatus(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/updateEmployeeEDAStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                updateEmployeeEDAStatusData: data.data
            }
            return surveyObj;
        });
}


async function updateEmployeeFilterLabel(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/updateEmployeeFilterLabel`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                updateEmployeeFilterLabelData: data.data
            }
            return surveyObj;
        });
}



async function getParameterLibraryListbyParameterType(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getParameterLibraryListbyParameterType`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getParameterLibraryListbyParameterTypeData: data.data
            }
            return surveyObj;
        });
}



async function copyParaLibToParameter(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/copyParaLibToParameter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                copyParaLibToParameterData: data.data
            }
            return surveyObj;
        });
}



async function autoUpdateParameters(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/autoUpdateParameters`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                autoUpdateParametersData: data.data
            }
            return surveyObj;
        });
}



async function getEmployeeLimitedList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getEmployeeLimitedList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getEmployeeLimitedListData: data.data
            }
            return surveyObj;
        });
}


async function createEmployeeRecord(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/createEmployeeRecord`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                createEmployeeRecordData: data.data
            }
            return surveyObj;
        });
}


async function getLifecycleStageList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getLifecycleStageList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getLifecycleStageListData: data.data
            }
            return surveyObj;
        });
}


async function allTooltipList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/allTooltipList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                allTooltipListData: data.data
            }
            return surveyObj;
        });
}



async function getDefaultRiskSurvey(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getDefaultRiskSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getDefaultRiskSurveyData: data.data
            }
            return surveyObj;
        });
}





async function updateSurveyClusterSurveyId(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/updateSurveyClusterSurveyId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                updateSurveyClusterSurveyIdData: data.data
            }
            return surveyObj;
        });
}






async function getEmployeeFields(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getEmployeeFields`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getEmployeeFieldsData: data.data
            }
            return surveyObj;
        });
}

async function getCustomerFields(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getCustomerFields`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getCustomerFieldsData: data.data
            }
            return surveyObj;
        });
}




async function loadDefaultRecDaysSurvey(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/loadDefaultRecDaysSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                loadDefaultRecDaysSurveyData: data.data
            }
            return surveyObj;
        });
}


async function loadDefaultOnboardingSurvey(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/loadDefaultOnboardingSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                loadDefaultOnboardingSurveyData: data.data
            }
            return surveyObj;
        });
}



async function autoChangeDashboardType(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/autoChangeDashboardType`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                autoChangeDashboardTypeData: data.data
            }
            return surveyObj;
        });
}



async function updateActiveStatusRecCampaign(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/updateActiveStatusRecCampaign`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                updateActiveStatusRecCampaignData: data.data
            }
            return surveyObj;
        });
}

async function allLifecycleListenList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/allLifecycleListenList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                allLifecycleListenListData: data.data
            }
            return surveyObj;
        });
}


async function generateCompanyBotLink(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/generateCompanyBotLink`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                generateCompanyBotLinkData: data.data
            }
            return surveyObj;
        });
}



async function getSurveyOneTemplate(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getSurveyOneTemplate`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getSurveyOneTemplateData: data.data
            }
            return surveyObj;
        });
}





async function getSurveyListByCategory(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getSurveyListByCategory`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getSurveyListByCategoryData: data.data
            }
            return surveyObj;
        });
}



async function changeLifecycleDashStatus(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/changeLifecycleDashStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                changeLifecycleDashStatusData: data.data
            }
            return surveyObj;
        });
}

async function get360RelationList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/get360RelationList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                get360RelationListData: data.data
            }
            return surveyObj;
        });
}
async function getPMSRelationList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getPMSRelationList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getPMSRelationListData: data.data
            }
            return surveyObj;
        });
}


async function getCustEmpRelationList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getCustEmpRelationList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getCustEmpRelationListData: data.data
            }
            return surveyObj;
        });
}

async function handle360FeedbackDisable(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/handle360FeedbackDisable`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                handle360FeedbackDisableData: data.data
            }
            return surveyObj;
        });
}


async function setSurveyCampaignType(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/setSurveyCampaignType`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                setSurveyCampaignTypeData: data.data
            }
            return surveyObj;
        });
}


async function copy360Parameters(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/copy360Parameters`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                copy360ParametersData: data.data
            }
            return surveyObj;
        });
}


async function copyPackageParameters(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/copyPackageParameters`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                copyPackageParametersData: data.data
            }
            return surveyObj;
        });
}



async function getSurveyListV3(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getSurveyListV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getSurveyListV3Data: data.data
            }
            return surveyObj;
        });
}

async function getSurveyListV4(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getSurveyListV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getSurveyListV4Data: data.data
            }
            return surveyObj;
        });
}


async function getParameterLabelByName(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getParameterLabelByName`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getParameterLabelByNameData: data.data
            }
            return surveyObj;
        });
}

async function saveEmpDateField(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/saveEmpDateField`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                saveEmpDateFieldData: data.data
            }
            return surveyObj;
        });
}

async function getSurveyListByRole(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getSurveyListByRole`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getSurveyListByRoleData: data.data
            }
            return surveyObj;
        });
}



async function getAllEmployeeList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getAllEmployeeList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getAllEmployeeListData: data.data
            }
            return surveyObj;
        });
}





async function getTranslateElementAll(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getTranslateElementAll`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getTranslateElementAllData: data.data
            }
            return surveyObj;
        });
}



async function getAssessmentByTopBottom(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getAssessmentByTopBottom`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getAssessmentByTopBottomData: data.data
            }
            return surveyObj;
        });
}

async function getResponseAssessmentShortCode(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getResponseAssessmentShortCode`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getResponseAssessmentShortCodeData: data.data
            }
            return surveyObj;
        });
}

async function responseIceDownloadBySurveyId(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/responseIceDownloadBySurveyId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                responseIceDownloadBySurveyIdData: data.data
            }
            return surveyObj;
        });
}


async function copySurvey(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/copySurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                copySurveyData: data.data
            }
            return surveyObj;
        });
}


