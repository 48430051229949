import React, { Component } from 'react';
import { connect } from 'react-redux';
import IndexKPI from '../Components/IndexKPI';

import RadarChartPage1 from './Charts/RadarChartPage1';
import BarDistribution1 from './Charts/BarDistribution1';

import EmployeeDropdown from '../Filter/EmployeeDropdown';
import Filter from './Filter/Filter';

import QuestionTable from './Charts/QuestionTable';



class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',

      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: '',
      crrSelectedEmp: {}
    }
  }

  componentDidMount() {

  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { get360Overview, get360DimensionItemDetails } = this.props;
    this.setState({ FilterValues: finalFilter });
    get360Overview(finalFilter, "", "", false);
    get360DimensionItemDetails(finalFilter, "", "", false);

  }

  handleSelectEmployee = (value) => {
    this.setState({ crrSelectedEmp: value }, () => {
      let { get360Overview, get360DimensionItemDetails } = this.props;

      let emp_id = "";
      if (value && value.emp_id) {
        emp_id = value.emp_id;
      } else {
        emp_id = value.emp_id;
      }
      let { FilterValues } = this.state;
      get360Overview(FilterValues, "", "", false, emp_id)
      get360DimensionItemDetails(FilterValues, "", "", false, emp_id)

    })
  }


  render() {


    let { getQuestionsData, GetParameterLabel, getEMPFilterLabel, getAnsweredEmployeesData, get360DimensionRelationScoreData, EmpFilterData, getIndexByFilterDMGData } = this.props;

    let { FilterValues } = this.state;

    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }
    }


    console.log("|||||||||||---get360DimensionRelationScoreData----|||||||||||----->", get360DimensionRelationScoreData)

    return (
      <>

        <div>
          <div className="flex-wrap items-center justify-between w-full gap-4 space-y-4 md:flex md:space-y-0">
            <Filter
              showFilter={this.state.showFilter}
              showValue={this.state.showValue}
              closeFilter={this.closeFilter}

              EmpFilterData2={EmpFilterData2}
              getIndexFilterData={getIndexByFilterDMGData}
              handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
              getEMPFilterLabel={getEMPFilterLabel}
            />


            <div>
              <EmployeeDropdown
                getAnsweredEmployeesData={getAnsweredEmployeesData}
                crrSelectedEmp={this.state.crrSelectedEmp}
                handleSelectEmployee={this.handleSelectEmployee}
              />
            </div>




            {/* <span className="hidden text-xs font-medium text-blue-500">Need Help?</span> */}
            {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} /> */}

            {/* <div className='mr-4'>
            {ScoreExcel ?
            <CSVLink data={ScoreExcel} filename={"EnagagementFavorability.csv"}>
            <p className="text-sm font-normal text-purple-700 cursor-pointer hover:text-purple-500">
              {"Download Excel"}
            </p>                
            </CSVLink>
            : '...'}
            </div> */}



          </div>

          {/*             
        <EmployeeDropdown
        getAnsweredEmployeesData={getAnsweredEmployeesData}
        crrSelectedEmp={this.state.crrSelectedEmp}
        handleSelectEmployee={this.handleSelectEmployee}
        /> */}
        </div>

        <div className="grid w-full grid-cols-1 gap-6 lg:grid-cols-2">


          {/* Spiral Chart Left Side  */}
          <RadarChartPage1
            get360DimensionRelationScoreData={get360DimensionRelationScoreData}
            GetParameterLabel={GetParameterLabel}
            getQuestionsData={getQuestionsData}
          />

          {/* Dimension Summary Right Side  */}
          <BarDistribution1
            get360DimensionRelationScoreData={get360DimensionRelationScoreData}
            GetParameterLabel={GetParameterLabel}
            getQuestionsData={getQuestionsData}
          />
        </div>


        {/* Strength Table */}
        <div className="grid w-full grid-cols-1 gap-6 lg:grid-cols-1">
          <QuestionTable
            name={"Strength"}
            type={1}
            GetParameterLabel={GetParameterLabel}
            getQuestionsData={getQuestionsData}
          />
        </div>

        {/* Weakness Table */}
        <div className="grid w-full grid-cols-1 gap-6 lg:grid-cols-1">
          <QuestionTable
            name={"Weakness"}
            type={2}
            GetParameterLabel={GetParameterLabel}
            getQuestionsData={getQuestionsData}
          />
        </div>




      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Overview);
