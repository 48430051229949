import React from "react";
import ReactEcharts from 'echarts-for-react';

function ICEIndexScoreDist(props) {

  let { getIceIndexData, IndexNumber, getIceDistributionData, GetParameterLabel } = props;


  const getColor = (scoreX) => {
    let score = parseFloat(scoreX);
    let color = "#7e58c0";
    if (score >= 0 && score < 75) {
      color = "#ef5350";
    } else if (score >= 75 && score < 85) {
      color = "#ffa726";
    } else if (score >= 85 && score <= 100) {
      color = "#66bb6a";
    }
    return color
  }




  function spliceDecimal(no) {
    if (no) {
      let str = no.toString();
      let first = str.split('.')[0];
      let second = str.split('.')[1];
      let second2 = second ? second.substring(0, 2) : ""
      let final = first + '.' + second2;
      if (second2) {
        return parseFloat(final).toFixed(2)
      } else {
        return no
      }
    } else {
      return 0
    }
  }

  let indexScore = getIceIndexData && getIceIndexData[IndexNumber] ? spliceDecimal(getIceIndexData[IndexNumber]) : 0;

  const option = {
    series: [
      {
        type: 'pie',
        radius: ['60%', '90%'], // Adjust thickness of the circular progress bar
        startAngle: 225, // Start angle of the progress (0 degrees is the top)
        endAngle: 45, // End angle to create a full circle effect
        data: [
          {
            value: indexScore,
            name: 'Progress',
            itemStyle: {
              color: getColor(indexScore), // Progress color
              borderRadius: '50%', // Simulate border-radius (but this won't directly apply)
            },
          },
          {
            value: 100 - indexScore,
            name: 'Remaining',
            itemStyle: {
              color: '#7E57C214', // Background color
            },
          },
        ],
        label: {
          show: true,
          position: 'center',
          formatter: function (data) {
            return ((data && data.value > 0 ? data.value : 0).toString() + "%")
          },
          fontSize: 16,
          fontWeight: 'bold',
          color: '#212121', // Text color
          fontFamily: 'Poppins, sans-serif', // Title font family
        },
        labelLine: {
          show: false, // Hide the line connecting the label
        },
        emphasis: {
          scale: false, // Disable scaling on hover
        },
      },
    ],
  };

  const tableSix = Array.from({ length: 6 });


  // const getDistScore = (list, type, name, para) => {
  //   if (list && list.length > 0) {
  //     if (type === "parameters") {
  //       return list.map((ele) => (ele._id))
  //     } else if (type === "options") {
  //       let scoreList = []

  //       list.filter((ele) => (ele._id === para)).forEach((ele) => {
  //         let totalSum = 0;
  //         let scoreSum = 0;

  //         if (ele && ele.answers && ele.answers.length > 0) {
  //           ele.answers.forEach((inner) => {
  //             let count = inner && inner["count"] ? inner["count"] : 0;

  //             if (inner["answerText"] === name) {
  //               scoreSum += count;
  //             }
  //             totalSum += count;
  //           })
  //         }
  //         let perc = totalSum > 0 ? ((scoreSum * 100) / totalSum).toFixed(2) : "0";
  //         scoreList.push(perc);
  //       })

  //       let finalScore = 0;
  //       if (scoreList && scoreList.length > 0) {
  //         finalScore = parseFloat(scoreList[0]) < 5 ? "" : scoreList[0]
  //       }
  //       return finalScore
  //     }
  //   }
  //   return []
  // }

  const getDistScore = (list, type, name, para, check) => {
    if (!list || !list.length) return [];
    if (type === "parameters") {
      return list.map(ele => ele._id);
    }
    if (type === "options") {
      const ele = list.find(ele => ele._id === para);
      if (!ele || !ele.answers) return 0;
      const totalSum = ele.answers.reduce((sum, inner) => sum + (inner.count || 0), 0);
      const scoreSum = ele.answers.reduce((sum, inner) =>
        sum + (inner.answerText === name ? (inner.count || 0) : 0), 0);
      const perc = totalSum ? ((scoreSum * 100) / totalSum).toFixed(0) : "0";
      return parseFloat(perc) < 5 && !check ? "" : perc;
    }
    return [];
  }


  const getFavourableScore = (item) => {
    let favSum = 0;
    let totalSum = 0;
    if (item && item.answers && item.answers.length > 0) {
      item.answers.forEach((inner) => {
        if (["Agree", "Strongly Agree"].includes(inner.answerText)) {
          favSum += inner.count;
        }
        totalSum += inner.count;
      })
    }
    let score = totalSum > 0 ? ((favSum * 100) / totalSum).toFixed(0) : "0";

    let color = "";
    if (score >= 0 && score < 75) {
      color = "#ef5350";
    } else if (score >= 75 && score < 85) {
      color = "#ffa726";
    } else if (score >= 85 && score <= 100) {
      color = "#66bb6a";
    }
    // else if (score > 74 && score <= 75) {
    //   color = "#D4E157";
    // } else if (score > 75 && score <= 80) {
    //   color = "#9CCC65";
    // } else if (score > 80 && score <= 100) {
    //   color = "#66BB6A";
    // }
    return { score, color }
  }


  return (


    <div className="flex flex-col space-y-4 w-full ">
      <h1 className="text-2xl font-bold capitalize  bg-[#212121]/90 text-white p-4">
        ICE Index Summary
      </h1>

      <div className='space-x-4  flex items-start'>
        <div className=" w-60 flex-shrink-0 space-y-6">
          <p className='text-xl font-bold text-[#212121] '>ICE Index Score</p>
          <ReactEcharts option={option} style={{ height: '230px', width: '230px' }} />
        </div>

        <div className="w-full space-y-6 border-l pl-4">
          <p className='text-xl font-bold text-[#212121] '>{"ICE Index Themes Score (in %)"}</p>
          <div className="flex flex-wrap gap-x-6 gap-y-2 justify-center items-center text-xs text-[#212121] w-full ">
            <div className="flex items-center ">
              <div className="px-3 py-1.5 rounded-sm bg-[#66bb6a]" />
              <p className="ml-1.5 text-sm">{">=85%"}</p>
            </div>
            <div className="flex items-center">
              <div className="px-3 py-1.5 rounded-sm bg-[#ffa726]" />
              <p className="ml-1.5 text-sm">{">=75% & <85%"}</p>
            </div>
            <div className="flex items-center">
              <div className="px-3 py-1.5 rounded-sm bg-[#ef5350]" />
              <p className="ml-1.5 text-sm">{"<75%"}</p>
            </div>
          </div>
          <table className="w-full">
            {getIceDistributionData &&
              getIceDistributionData.length > 0 &&
              getIceDistributionData.map((ele, index) => {
                const { score, color } = getFavourableScore(ele); // Get score and color
                return (
                  <tr className="bg-[#F1F5F9]" key={index}>
                    <td className="text-[#212121] w-40 flex-shrink-0 font-medium text-left text-sm p-2 border-b-2 border-r-2 border-white">
                      {ele && ele._id ? GetParameterLabel(ele._id) : ""}
                    </td>
                    <td className="w-[60%] p-2 border-b-2  border-white">
                      <div className="bg-[#7e58c0]/10 w-full h-7 overflow-hidden flex items-center space-x-2">
                        <div
                          className="w-3/6 h-7"
                          style={{ width: score + "%", backgroundColor: color }} // Use color
                        />
                      </div>
                    </td>
                    <td className="font-medium w-10 text-left text-sm p-2 border-b-2 border-r-2 border-white">
                      {score}% {/* Display score */}
                    </td>
                  </tr>
                );
              })}
          </table>

        </div>
      </div>

      <div className="space-y-6 pt-6">

        <p className='text-xl font-bold text-[#212121]'>{"ICE Index Themes Distribution (in %)"}</p>

        <table>
          <thead className="  bg-[#eaeff4]">
            <th className="w-[30%] px-2 border-b-2 border-r-2 border-white p-2 text-sm font-bold text-[#212121] text-left">ICE  Dimensions</th>
            <th className=" w-[70%] p-2 border-b-2 border-r-2 border-white font-medium  ">
              <div className="flex flex-wrap gap-x-6 gap-y-2   text-xs text-[#212121] w-full ">
                <div className="flex items-center ">
                  <div className="p-1.5 rounded-sm bg-[#EF5350]" />
                  <p className="ml-1.5">Slightly Disagree</p>
                </div>
                <div className="flex items-center ">
                  <div className="p-1.5 rounded-sm bg-[#FF7043]" />
                  <p className="ml-1.5">Disagree</p>
                </div>
                <div className="flex items-center ">
                  <div className="p-1.5 rounded-sm bg-[#FFA726]" />
                  <p className="ml-1.5">Slightly Disagree</p>
                </div>
                <div className="flex items-center ">
                  <div className="p-1.5 rounded-sm bg-[#D4E157]" />
                  <p className="ml-1.5">Slightly Agree</p>
                </div>
                <div className="flex items-center ">
                  <div className="p-1.5 rounded-sm bg-[#9CCC65]" />
                  <p className="ml-1.5">Agree</p>
                </div>
                <div className="flex items-center ">
                  <div className="p-1.5 rounded-sm bg-[#66BB6A]" />
                  <p className="ml-1.5">Strongly Agree</p>
                </div>
              </div>

            </th>

          </thead>
          <tbody>
            {getDistScore(getIceDistributionData, "parameters") && getDistScore(getIceDistributionData, "parameters").length > 0 &&
              getDistScore(getIceDistributionData, "parameters").map((ele, index) => (
                <tr className="bg-[#F1F5F9] ">
                  <td className="text-sm text-left p-2 border-b-2 border-r-2 border-white">{GetParameterLabel(ele)}</td>
                  <td className="border-b-2 border-r-2 border-white p-2">
                    <div className="w-full flex items-center text-sm">
                      <div className="bg-[#EF5350] h-8 flex items-center justify-center font-medium" style={{ width: getDistScore(getIceDistributionData, 'options', 'Extremely Dissatisfied', ele, true) + "%" }}>{getDistScore(getIceDistributionData, 'options', 'Extremely Dissatisfied', ele)}</div>
                      <div className="bg-[#FF7043] h-8 flex items-center justify-center font-medium" style={{ width: getDistScore(getIceDistributionData, 'options', 'Disagree', ele, true) + "%" }}>{getDistScore(getIceDistributionData, 'options', 'Disagree', ele)}</div>
                      <div className="bg-[#FFA726] h-8 flex items-center justify-center font-medium" style={{ width: getDistScore(getIceDistributionData, 'options', 'Slightly Disagree', ele, true) + "%" }}>{getDistScore(getIceDistributionData, 'options', 'Slightly Disagree', ele)}</div>
                      <div className="bg-[#D4E157] h-8 flex items-center justify-center font-medium" style={{ width: getDistScore(getIceDistributionData, 'options', 'Slightly Agree', ele, true) + "%" }}>{getDistScore(getIceDistributionData, 'options', 'Slightly Agree', ele)}</div>
                      <div className="bg-[#9CCC65] h-8 flex items-center justify-center font-medium" style={{ width: getDistScore(getIceDistributionData, 'options', 'Agree', ele, true) + "%" }}>{getDistScore(getIceDistributionData, 'options', 'Agree', ele)}</div>
                      <div className="bg-[#66BB6A] h-8 flex items-center justify-center font-medium" style={{ width: getDistScore(getIceDistributionData, 'options', 'Strongly Agree', ele, true) + "%" }}>{getDistScore(getIceDistributionData, 'options', 'Strongly Agree', ele)}</div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

      </div>
    </div>
  );
}
export default ICEIndexScoreDist;
