import React from "react";
export default function Help(props) {
  let { desc } = props;
  return (
    <p>

      {desc}

    </p>
  );
}
