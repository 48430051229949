import React, { Component } from "react";
import { connect } from "react-redux";
import Header from '../Components/Header';

function ResponsePage(props) {

  let { getIceMixHeatMapData, createdAt, title, size, isSelf, GetParameterLabel } = props;



  let uniqueParaList = []
  if (getIceMixHeatMapData && getIceMixHeatMapData.length > 0) {
    const allDimensionNames = getIceMixHeatMapData.flatMap(item => item.parameters.map(param => param.parameterDimensionName));
    uniqueParaList = [...new Set(allDimensionNames)];
  }

  function getScorePercentage(feedback, parameterDimensionName, list) {
    let total = 0;
    let relevantCount = 0;

    for (const item of list) {
      if (item.feedback === feedback) {
        for (const parameter of item.parameters) {
          if (parameter.parameterDimensionName === parameterDimensionName) {
            for (const answer of parameter.answers) {
              total += answer.count;
              if (["Strongly Agree", "Agree", "Extremely Satisfied", "Very Satisfied"].includes(answer.answerText)) {
                relevantCount += answer.count;
              }
            }
          }
        }
      }
    }

    return total > 0 ? ((relevantCount * 100) / total).toFixed(0) : "0";
  }




  // function getColorForScore(score) {

  //   if (parseFloat(score) === 0) {
  //     return "#F1F5F9"
  //   } else {
  //     // Ensure score is within 0 to 100
  //     score = Math.max(0, Math.min(100, score));

  //     let color = { r: 255, g: 0, b: 0 };

  //     if (score >= 0 && score < 30) {
  //       color = { r: 255, g: 0, b: 0 }; // Red
  //     } else if (score <= 70) {
  //       color = { r: 255, g: 255, b: 0 }; // Yellow
  //     } else {
  //       color = { r: 0, g: 128, b: 0 }; // Green
  //     }

  //     // Calculate opacity (0.0 - 1.0 range)
  //     const opacity = score / 100;

  //     // Convert RGB to Hex format with opacity
  //     return rgbToHexWithOpacity(color.r, color.g, color.b, opacity);
  //   }

  // }

  // function rgbToHexWithOpacity(r, g, b, opacity) {

  //   // Convert RGB to hex
  //   const toHex = (c) => {
  //     const hex = c.toString(16);
  //     return hex.length === 1 ? '0' + hex : hex;
  //   };

  //   // Convert opacity to hex
  //   const opacityHex = Math.round(opacity * 255).toString(16).padStart(2, '0');

  //   return `#${toHex(r)}${toHex(g)}${toHex(b)}${opacityHex}`;
  // }


  function SortList(list) {
    let temp = [];
    if (list && list.length > 0) {
      temp = list.sort((a, b) => a.feedback.toLowerCase().localeCompare(b.feedback.toLowerCase()));
    }
    return temp;
  }


  function chunkArray(array, chunkSize) {
    let result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      let chunk = array.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  }

  let finalList = getIceMixHeatMapData && getIceMixHeatMapData.length > 0 ? chunkArray(SortList(getIceMixHeatMapData), size) : [];

  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
    throw hex;
  }


  // Old code for Krishna
  // function getColor(dmgScore) {
  //   let score = parseFloat(dmgScore);
  //   // if (score >= 0 && score < 1) {
  //   //   return { Color: "linear-gradient(90deg, #cfddfc, #e7eefd)", diffTextColor: "#000" };
  //   // } else if (score >= 1 && score < 50) {
  //   //   return { Color: "linear-gradient(90deg, #9fbaf9, #cfddfc)", diffTextColor: "#000" };
  //   // } else if (score >= 50 && score < 75) {
  //   //   return { Color: "linear-gradient(90deg, #6f98f6, #9fbaf9)", diffTextColor: "#000" };
  //   // } else if (score >= 75 && score < 90) {
  //   //   return { Color: "linear-gradient(90deg, #3f75f3, #6f98f6)", diffTextColor: "#ffffff" };
  //   // } else if (score >= 90 && score <= 100) {
  //   //   return { Color: "linear-gradient(90deg, #0f53f0, #3f75f3)", diffTextColor: "#ffffff" };
  //   // }

  //   if (isSelf) {
  //     return { Color: score === 0 ? "#e3f2fd" : hexToRgbA("#42A5F5", (score / 100).toString()), diffTextColor: score > 40 ? "#ffffff" : "#000000" };
  //   } else {
  //     return { Color: score === 0 ? "#ede7f6" : hexToRgbA("#7E57C2", (score / 100).toString()), diffTextColor: score > 40 ? "#ffffff" : "#000000" };
  //   }
  // }


  // New code keshri with opacity
  // function getColor(dmgScore) {
  //   let score = parseFloat(dmgScore);
  //   let color = "";

  //   // Check the score ranges and assign color accordingly
  //   if (score >= 0 && score <= 50) {
  //     color = "#78909c";
  //   } else if (score > 50 && score <= 75) {
  //     color = "#ffecb3";
  //   } else if (score > 75 && score <= 90) {
  //     color = "#ffcdd2";
  //   } else if (score > 90 && score <= 100) {
  //     color = "#c8e6c9";
  //   }

  //   // Handle color and diffTextColor based on whether it's self or not
  //   if (isSelf) {
  //     return {
  //       Color: score === 0 ? "#e3f2fd" : hexToRgbA(color, (score / 100).toString()),
  //       diffTextColor: score > 40 ? "#ffffff" : "#000000"
  //     };
  //   } else {
  //     return {
  //       Color: score === 0 ? "#ede7f6" : hexToRgbA(color, (score / 100).toString()),
  //       diffTextColor: score > 40 ? "#ffffff" : "#000000"
  //     };
  //   }
  // }


  // Latest code opacity remove it.
  function getColor(dmgScore) {
    let score = parseFloat(dmgScore);
    let color = "";

    // Check the score ranges and assign color accordingly

    // if (score >= 0 && score <= 20) {
    //   color = "#d32f2f"; // drak red 
    // } else if (score > 20 && score <= 30) {
    //   color = "#f44336"; // light red
    // } else if (score > 30 && score <= 50) {
    //   color = "#ffc107"; // light yellow 
    // } else if (score > 50 && score <= 60) {
    //   color = "#ffa000"; // drak Yellow
    // } else if (score > 60 && score <= 80) {
    //   color = "#4caf50"; // light green 
    // } else if (score > 80 && score <= 100) {
    //   color = "#388e3c"; // drak green
    // }

    if (score >= 0 && score < 75) {
      color = "#ef5350";
    } else if (score >= 75 && score < 85) {
      color = "#ffa726";
    } else if (score >= 85 && score <= 100) {
      color = "#66bb6a";
    }

    // Handle color and diffTextColor based on whether it's self or not
    if (isSelf) {
      return {
        Color: score === 0 ? "#e3f2fd" : color,  // No opacity calculation
        diffTextColor: score > 40 ? "#ffffff" : "#000000"
      };
    } else {
      return {
        Color: score === 0 ? "#ede7f6" : color,  // No opacity calculation
        diffTextColor: score > 40 ? "#ffffff" : "#000000"
      };
    }
  }




  const replaceFn = (word) => {
    if (isSelf && word) {
      return word.replace("(Self Department)", "")
    } else {
      return word
    }
  }



  return (
    <>
      {finalList && finalList.length > 0 &&
        finalList.map((innerList, index) =>
          <section className='w-full p-6 px-6 flex flex-col justify-between page-break'>
            <Header createdAt={createdAt} />

            <div className="flex flex-col space-y-4 w-full ">
              <h1 className="text-2xl bg-[#212121]/90 text-white p-4 font-bold"
                style={{ fontFamily: 'Poppins, sans-serif' }}>
                {index ? title + " (Continue)" : title}
              </h1>

              <div className="grid grid-cols-4 gap-2">
                {uniqueParaList && uniqueParaList.length > 0 &&
                  uniqueParaList.map((para, index) =>
                    <div className="flex items-center flex-nowrap space-x-2">
                      <span className="text-xs flex items-center justify-center font-bold text-center bg-[#E5E7EB] px-2 py-1 w-10 h-8">{"T" + (index + 1).toString() + ""}</span>
                      <span className="text-[11px] font-bold text-left">{GetParameterLabel(para)}</span>
                    </div>
                  )}
              </div>

              <div className="pt-6">
                <table className="w-full">
                  <thead>
                    <tr className="">
                      <th rowSpan={2} className="w-[25%] bg-[#EAEFF4] px-2 selection: border-r-2 border-white p-2 text-sm font-bold text-[#212121] text-left">  </th>
                      <th
                        colSpan={uniqueParaList.length}  // dynamically set colSpan value
                        className="text-center text-sm font-medium bg-[#F1F5F9] p-2 border-r-2 border-b-2 border-white">
                        Score in percentage (%)
                      </th>
                    </tr>
                  </thead>
                  <thead className="text-sm">
                    <tr className="  ">
                      <th rowSpan={2} className="w-[25%] bg-[#EAEFF4] px-2 border-b-2 border-r-2 border-white p-2 text-sm font-bold text-[#212121] text-left">Department </th>
                      {uniqueParaList && uniqueParaList.length > 0 &&
                        uniqueParaList.map((para, index) =>
                          <th className="text-xs font-bold p-2 text-center w-14 bg-[#EAEFF4] border-r-2 border-b-2 border-white">{"T" + (index + 1).toString()}</th>
                        )}
                    </tr>
                  </thead>

                  <tbody className="">
                    {innerList && innerList.length > 0 && innerList.map((ele, index2) => (
                      <tr key={index2} className="text-center text-xs font-medium text-[#212121] border-white">
                        <td className="text-sm text-left p-2 font-normal border-r-2 border-white  border-b-2 bg-[#F1F5F9]">
                          {ele && ele.feedback ? replaceFn(ele.feedback) : ""}
                        </td>
                        {uniqueParaList && uniqueParaList.length > 0 &&
                          uniqueParaList.map((para) =>
                            <td className="bg-[#EF5350] py-2 h-12 text-xs border-r-2 border-white border-b-2"
                              style={{
                                color: getColor(
                                  getScorePercentage(ele.feedback, para, getIceMixHeatMapData)
                                ).diffTextColor,
                                background: getColor(
                                  getScorePercentage(ele.feedback, para, getIceMixHeatMapData)
                                ).Color,

                              }}>
                              {getScorePercentage(ele.feedback, para, getIceMixHeatMapData)}
                            </td>
                          )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

          </section>
        )}

    </>
  );
}
export default ResponsePage;
