import React, { Component } from 'react';
import { connect } from 'react-redux';


// import Engagement from './DimensionIndex/Engagement';
// import Happiness from './DimensionIndex/Happiness';
// import Stress from './DimensionIndex/Stress';

// import Happiness from './DimensionIndex/Happiness/Happiness';
// import Engagement from './DimensionIndex/Engagement/Engagement';
// import Stress from './DimensionIndex/Stress/Stress';
// import Manager from './DimensionIndex/Manager/Manager';
// import Leader from './DimensionIndex/Leader/Leader';


import CorrelationModule from './DimensionIndex/CorrelationModule/Module';

// import Filter from './Filter';
import Commentary from '../../../Index/Components/Commentary/Commentary';

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: 'none',
      viewScore: true,
      showDropDown1: false,
      showDropDown2: false,

      dimensionTypeNo: 0,

      DimensionList: [
        {
          index: 1,
          name: 'Happiness',
          cutOff: 59
        },
        {
          index: 2,
          name: 'Engagement',
          cutOff: 78

        },
        {
          index: 4,
          name: 'Stress',
          cutOff: 28

        },
        {
          index: 5,
          name: 'Manager',
          cutOff: 59
        },
        {
          index: 6,
          name: 'Leader',
          cutOff: 59
        },
        // {
        //   index:8,
        //   name:'OrgCore',
        //   cutOff:59
        // },
      ],

      selectedItem1: {
        index: 1,
        name: 'Happiness',
        cutOff: 59
      },
      selectedItem2: {
        index: 2,
        name: 'Engagement',
        cutOff: 78
      },
    }
  }
  componentDidMount() {

  }

  handleViewScore = (data) => {
    this.setState({ viewScore: data })
  }




  handleDropdown1 = (check) => {
    this.setState({ showDropDown1: !check, showDropDown2: false })
  }
  handleDropdown2 = (check) => {
    this.setState({ showDropDown2: !check, showDropDown1: false })
  }

  changeItem1 = (item) => {
    this.setState({ selectedItem1: item })
    this.setState({ showDropDown1: false, showDropDown2: false })

  }

  changeItem2 = (item) => {
    this.setState({ selectedItem2: item })
    this.setState({ showDropDown1: false, showDropDown2: false })

  }


  handleSummaryCard = (val) => {
    let { handleQuadrantFilter } = this.props;
    this.setState({ currFilterName: val })
    handleQuadrantFilter(val)
  }


  // handleFilternameSave=(name)=>{
  //   this.setState({currFilterName:name})
  // }

  onSwitchOptionScreen = (validateList) => {
    let { getCorrelationDimenionVsDimensionError, getEMPFilterLabel, loading, GetParameterLabel,
      indexType, optionType, getCommentData, EmpFilterData, getIndexFilterData,

      getAllBenchMarkData, getIndexScore, handleSummaryFilter, handleQuadrantFilter, handleSummaryCard, getDemographicIndexData, getIndexData, getIndexDistributionData,
      getQuestionData, getQuestionFilterData, handleQuestionFilter, handleCorrelationFilter,
      getDistributionIndexData, handleDistributionFilter, getIndexDriverData,

      getHappinessCorrelationData,
      getEngagementCorrelationData,
      getStressCorrelationData,
      getManagerCorrelationData,
      getLeaderCorrelationData,

      handleMultiFilterCorrelation,

      IndexDimension,
      getCorrelationData,
      getCorrelationFilterData,


      getSurveyData,

      CountRestrict,
      getIndexByFilterDMGData,

      ErrorOtherObj

    } = this.props;

    let { dimensionTypeNo } = this.state;

    let NewOption = parseInt((optionType).toString() + (dimensionTypeNo).toString())
    //console.log('000 indexType',indexType);

    //console.log('000 getEngagementCorrelationData',getEngagementCorrelationData);


    // let XAxisHappiness = ['Time','Being','Relationship','Worklife','Achievement','Meaning']
    // let XAxisNameHappiness = ['TimeAvg','BeingAvg','RelationshipAvg','WorkLifeAvg','AchivementAvg','MeeningfullAvg']

    // let XAxisEngagement= ['Pride','Presence','Performance']
    // let XAxisNameEngagement= ['PrideAvg','PresenceAvg','PerformanceAvg']

    // let XAxisStress= ['Collaboration','Empowerment','Inclusion','Recognition','WorkSatisfaction']
    // let XAxisNameStress= ['CollaborationAvg','EmpowermentAvg','InclusionAvg','RecognitionAvg','WorkSatisfactionAvg']

    // let XAxisStress= ['Collaboration','Empowerment','Inclusion','Recognition','WorkSatisfaction']
    // let XAxisNameStress= ['CollaborationAvg','EmpowermentAvg','InclusionAvg','RecognitionAvg','WorkSatisfactionAvg']

    // let XAxisStress= ['Collaboration','Empowerment','Inclusion','Recognition','WorkSatisfaction']
    // let XAxisNameStress= ['CollaborationAvg','EmpowermentAvg','InclusionAvg','RecognitionAvg','WorkSatisfactionAvg']



    let OptType = 0;
    if (dimensionTypeNo) {
      OptType = dimensionTypeNo;
    }
    else {
      OptType = validateList && validateList.length > 0 ? validateList[0].template : 0;
    }

    //console.log('OptType: ',OptType);

    let Name = "";
    let XAxisDimen = [];
    let XAxisDimenAvg = [];
    // let getCorrelationData = [];

    // if(OptType===1){
    //   Name="Happiness";
    //   getCorrelationData = getHappinessCorrelationData;
    // }
    // else if(OptType===2){
    //   Name="Engagement";
    //   getCorrelationData = getEngagementCorrelationData;
    // }
    // else if(OptType===4){
    //   Name="Stress";
    //   getCorrelationData = getStressCorrelationData;
    // }
    // else if(OptType===5){
    //   Name="Manager";
    //   getCorrelationData = getManagerCorrelationData;
    // }
    // else if(OptType===6){
    //   Name="Leader";
    //   getCorrelationData = getLeaderCorrelationData;
    // }

    //console.log('getLeaderCorrelationData: ',getLeaderCorrelationData)

    XAxisDimen = IndexDimension[Name];
    if (XAxisDimen && XAxisDimen.length > 0) {
      XAxisDimen.forEach((dimen) => {
        XAxisDimenAvg.push(dimen + 'Avg');
      });
    }

    let staticOption = 1
    switch (staticOption) {

      case 1: return <CorrelationModule
        getIndexByFilterDMGData={getIndexByFilterDMGData}
        IndexDimension={IndexDimension}
        getCorrelationData={getCorrelationData}
        getCorrelationFilterData={getCorrelationFilterData}
        validateList={validateList}
        getCorrelationDimenionVsDimensionError={getCorrelationDimenionVsDimensionError}
        getSurveyData={getSurveyData}

        CountRestrict={CountRestrict}

        GetParameterLabel={GetParameterLabel} OptType={OptType} XAxis={XAxisDimen} XAxisName={XAxisDimenAvg} Name={Name} loading={loading} handleMultiFilterCorrelation={handleMultiFilterCorrelation}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        handleCorrelationFilter={handleCorrelationFilter}
        getDemographicIndexData={getDemographicIndexData} handleQuadrantFilter={handleQuadrantFilter}
        dimensionTypeNo={dimensionTypeNo} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getAllBenchMarkData={getAllBenchMarkData} getIndexScore={getIndexScore} getIndexDistributionData={getIndexDistributionData}
        handleSummaryFilter={handleSummaryFilter} handleSummaryCard={handleSummaryCard} getIndexData={getIndexData} getEMPFilterLabel={getEMPFilterLabel}

        ErrorOtherObj={ErrorOtherObj}

      />



      default: return <div>Default Option</div>
    }
  }


  onDimensionType = (val) => {
    let { handleCorelation } = this.props;
    handleCorelation(val);
    this.setState({ dimensionTypeNo: val })
  }

  render() {
    let { validateList, getIndexData, getDemographicIndexData, EmpFilterData, getIndexFilterData } = this.props;
    let { DimensionList } = this.state;

    let ValidateListNew = []
    if (DimensionList && DimensionList.length > 0) {
      DimensionList.forEach((dimen) => {
        if (validateList && validateList.length > 0) {
          validateList.forEach((valid) => {
            if (valid.template === dimen.index) {
              ValidateListNew.push(valid);
            }
          })
        }
      })
    }

    let { dimensionTypeNo } = this.state;

    let OptType = 0;
    if (dimensionTypeNo) {
      OptType = dimensionTypeNo;
    }
    else {
      OptType = ValidateListNew && ValidateListNew.length > 0 ? ValidateListNew[0].template : 0;
    }

    //console.log('OptType: ',OptType);
    //console.log('ValidateListNew: ',ValidateListNew);


    // let valueList=[]
    // if(getIndexData){
    //   valueList = Object.keys(getIndexData).map((key) => [ key, getIndexData[key] ]);
    //   //console.log('valueList: ',valueList)
    // }

    return (
      <>

        {this.onSwitchOptionScreen(ValidateListNew)}

      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
