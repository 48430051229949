import React, { Component } from "react";
import { connect } from "react-redux";

function Header(props) {

  let { userName, createdAt } = props;

  return (
    <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
      <p className='font-medium text-base'>Pulse Report</p>
      <p className='font-medium text-base'><span>Overall </span> <span>|</span> <span>{createdAt}</span></p>
    </div>
  );
}
export default Header;
