import React, { Component } from "react";
import { connect } from "react-redux";

import HappinessNudge from "../SummaryChart/HappinessNudge";
import EngagementNudge from "../SummaryChart/EngagementNudge";
import StressNudge from "../SummaryChart/StressNudge";
import { spliceDecimal } from "../../../../FunctionLibrary/FuntionList";
import DataNotFound from "../../../Components/DataNotFound";
import NudgesOrgOverview from "../../../../CommonComponents/Nudges/NudgesOrgOverview";
class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() { }

  render() {
    let {
      getIndexOverallScoreData,
      getIndexOverallDistributionData,
      getDemographicWiseRankedScoreIndexTopData,
      getDemographicWiseRankedScoreIndexBottomData,
      optionType,
      DimensionList
    } = this.props;
    let loading = "";
    let inner = "";



    // console.log('getIndexOverallDistributionDatagetIndexOverallDistributionDatagetIndexOverallDistributionDatagetIndexOverallDistributionData', getIndexOverallDistributionData);


    // console.log(
    //   "getDemographicWiseRankedScoreIndexTopData:----------> ",
    //   getDemographicWiseRankedScoreIndexTopData
    // );
    // console.log(
    //   "getDemographicWiseRankedScoreIndexBottomData:----------> ",
    //   getDemographicWiseRankedScoreIndexBottomData
    // );

    function groupingFn(inputData, field) {
      let list = [];
      if (inputData && inputData.length > 0) {
        inputData.forEach((element, index) => {
          if (Number(element.score) > 0) {
            let getIndex = list.findIndex(
              (prev) => prev["name"] === element[field]
            );
            if (getIndex === -1) {
              let temp = {
                name: element[field],
                data: [element],
              };
              list.push(temp);
            } else {
              list[getIndex]["data"].push(element);
            }
          }
        });
      }
      return list;
    }

    let IndexList = groupingFn(
      getDemographicWiseRankedScoreIndexTopData,
      "indexName"
    );
    // console.log("IndexList--->", IndexList);

    let finalList = [];

    if (IndexList && IndexList.length > 0) {
      IndexList.forEach((item) => {
        let name = item.name;
        let innerList = groupingFn(item.data, "level");
        finalList.push({
          name: name,
          data: innerList,
        });
      });
    }

    // console.log("finalList--->", finalList);

    function sortFn(list) {
      if (list && list.length > 0) {
        list.sort((a, b) => a.rank - b.rank);
        return list;
      } else {
        return [];
      }
    }

    function getSecondList(element, item) {
      let indexName = item.name;
      let dmgLevel = element.name;
      let finalList2 = [];
      let list2 = getDemographicWiseRankedScoreIndexBottomData;
      if (list2 && list2.length > 0) {
        let finalList1 = list2.filter((prev) => prev.indexName === indexName);
        if (finalList1 && finalList1.length > 0) {
          finalList2 = finalList1.filter((prev) => prev.level === dmgLevel);
        }
      }
      return finalList2;
    }

    function getIndexColor(field, templateNo) {
      if (templateNo === 1) {
        let colorObj = {
          Unhappy: "#e53935",
          ModeratelyHappy: "#ffc107",
          Happy: "#a2e37d",
          Happiest: "#00cc66",
        };
        return colorObj && colorObj[field] ? colorObj[field] : "";
      } else if (templateNo === 2) {
        let colorObj = {
          ActivelyDisengaged: "#d12e2e",
          Passive: "#f4ae34",
          ModeratelyEngaged: "#a2e37d",
          HighlyEngaged: "#00cc66",
        };
        return colorObj && colorObj[field] ? colorObj[field] : "";
      }
    }

    function getIndexLabel(field, templateNo) {
      if (templateNo === 1) {
        let labelObj = {
          Unhappy: `Unhappy`,
          ModeratelyHappy: `Moderate`,
          Happy: `Happy`,
          Happiest: `Happiest`,
        };
        return labelObj && labelObj[field] ? labelObj[field] : "";
      } else if (templateNo === 2) {
        let labelObj = {
          ActivelyDisengaged: `Disengaged`,
          Passive: `Passive`,
          ModeratelyEngaged: `Moderately Engaged`,
          HighlyEngaged: `Highly Engaged`,
        };
        return labelObj && labelObj[field] ? labelObj[field] : "";
      }
    }



    function resolveCheck(a, b, c) {
      let value = 0;
      if (a && a[b] && a[b] !== "NaN") {
        value = spliceDecimal(a[b], c)
      }
      return value
    }

    let objData = {}
    if (getIndexOverallDistributionData && getIndexOverallDistributionData.length > 0) {
      getIndexOverallDistributionData.map((element) => {
        objData[element.dimensionName] = resolveCheck(element, "score", 2);
      })
    }

    console.log("objData--123---->", objData);


    function getName(index, DimensionList) {
      let name = '';
      if (DimensionList && DimensionList.length > 0) {
        let getINDEX = DimensionList.findIndex(prev => prev.index === index);
        if (getINDEX !== -1) {
          name = DimensionList[getINDEX]["name"]
        }
      }
      return name
    }

    return (
      <>
        {true ? (
          <>
            <div className="space-y-6 ">
              <div className="md:p-4 p-4 space-y-6 bg-white border rounded-lg">
                <div className="flex flex-col items-center gap-2 md:flex-row">
                  <h1 className=" font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap ">
                    Overall   {getName(optionType, DimensionList)}
                  </h1>

                  {getIndexOverallScoreData &&
                    getIndexOverallScoreData.length > 0
                    ? getIndexOverallScoreData.map((element) => (
                      <div className="flex items-center p-1 px-4 space-x-2 text-xl rounded-md bg-gray-100 w-fit">
                        <div
                          className=" text-base font-semibold  rounded-full text-[#3D405B]  flex justify-center items-center  "
                          style={{
                            background: getIndexColor(
                              element.dimensionName,
                              element.indexNo
                            ),
                          }}
                        >
                          <p> {resolveCheck(element, "score", 2) + "%"}</p>
                        </div>
                        <h1 className=" text-base text-[#3D405B]  font-medium">
                          {element.indexName}
                        </h1>
                        <h1 className="text-base text-[#3D405B]  ">
                          {element.text ? "=>" + inner.text : ""}
                        </h1>
                      </div>
                    ))
                    : null}
                </div>







                {/* <div className="grid grid-cols-1 gap-4 md:grid-cols-2 ">
                  <div className="p-6 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                    <div className="flex items-center justify-between">
                      <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap ">
                        {name} Score
                      </h1>
                      <h1 className="font-medium xl:text-base text-sm text-[#3D405B]/50 whitespace-nowrap  file: ">
                        Cron Batch Alpha: {cronBachAlpha.score}
                      </h1>
                    </div>

                    {ErrorIndexObj && ErrorIndexObj["getIndexV2Error"] ? (
                      <>
                        <div className="my-24 text-2xl font-medium text-center text-gray-400">
                          {ErrorIndexObj["getIndexV2Error"]}
                        </div>
                      </>
                    ) : (
                      <>
                        {FilterValues && FilterValues.length > 0 ? (
                          <>
                            {ErrorIndexObj &&
                              ErrorIndexObj["getDemographicIndexMultiFilterError"] ? (
                              <>
                                <div className="my-24 text-2xl font-medium text-center text-gray-400">
                                  {
                                    ErrorIndexObj[
                                    "getDemographicIndexMultiFilterError"
                                    ]
                                  }
                                </div>
                              </>
                            ) : (

                              <StackChart
                                getIndexScore={getIndexScore}
                                tempSortData={tempSortData}
                                currFilterName={currFilterName}
                                indexType={indexType}
                                CountRestrict={CountRestrict}
                                handleSelectEDA={this.handleSelectEDA}
                                ErrorIndexObj={ErrorIndexObj}
                              />


                            )}
                          </>
                        ) : (
                          <>
                            <SummaryScore
                              name={name}
                              isCrole={isCrole}
                              indexOverallValue={indexOverallValue}
                              cronBachAlpha={cronBachAlpha}
                              item={Item}
                              DistributionData={getIndexData}
                              Benchmark={Benchmark}
                            />
                          </>
                        )}{" "}
                      </>
                    )}
                    <p
                      className="text-sm text-[#3d405B]/70"

                    >
                      * We are not showing data where employee count is
                      less than 5
                    </p>

                  </div>

                  <div className="p-6 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                    <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap ">
                      {name} Distribution
                    </h1>

                    {ErrorIndexObj && ErrorIndexObj["getIndexV2Error"] ? (
                      <>
                        <div className="my-24 text-2xl font-medium text-center text-gray-400">
                          {ErrorIndexObj["getIndexV2Error"]}
                        </div>
                      </>
                    ) : (
                      <>
                        {FilterValues && FilterValues.length > 0 ? (
                          <>
                            {ErrorIndexObj &&
                              ErrorIndexObj["getIndexV2DemographicError"] ? (
                              <>
                                <div className="my-24 text-2xl font-medium text-center text-gray-400">
                                  {ErrorIndexObj["getIndexV2DemographicError"]}
                                </div>
                              </>
                            ) : (

                              <StackChartDistribution
                                getIndexData={getIndexData}
                                tempSortDistribution={tempSortDistribution}
                                currFilterName={currFilterName}
                                indexType={indexType}
                                CountRestrict={CountRestrict}
                                ErrorIndexObj={ErrorIndexObj}
                              />


                            )}
                          </>
                        ) : (

                          <SummaryDistribution
                            cronBachAlpha={cronBachAlpha}
                            item={Item}
                            DistributionData={getIndexData}
                            Benchmark={Benchmark}
                          />

                        )}
                      </>
                    )}

                    <p
                      className="text-sm text-[#3d405B]/70"
                    >
                      * We are not showing data where employee count is
                      less than 5
                    </p>
                  </div>
                </div> */}


                <div className="w-full h-full ">

                  {optionType === 1 ?
                    <>
                      <HappinessNudge
                        DistributionData={objData}
                      />
                    </>
                    : null}

                  {optionType === 2 ?
                    <>
                      <EngagementNudge
                        DistributionData={objData}
                      />
                    </>
                    : null}

                  {optionType === 4 ?
                    <>
                      <StressNudge
                        DistributionData={objData}
                      />
                    </>
                    : null}


                </div>

                {/* Distribution Score */}
                <div className="md:flex grid grid-cols-2 justify-center items-center flex-row w-full md:gap-6 gap-4">
                  {getIndexOverallDistributionData &&
                    getIndexOverallDistributionData.length > 0
                    ? getIndexOverallDistributionData.map((element) => (
                      <>
                        {element.score ? (
                          <div className="flex items-center space-x-2">
                            <div
                              className="flex-shrink-0 md:w-4 w-3 md:h-4 h-3  "
                              style={{
                                background: getIndexColor(
                                  element.dimensionName,
                                  element.indexNo
                                ),
                              }}
                            >

                            </div>
                            <div className="flex items-center space-x-2">
                              <h1 className="md:text-base text-xs text-[#3D405B] ">
                                {getIndexLabel(
                                  element.dimensionName,
                                  element.indexNo
                                )}
                              </h1>
                              <p className="font-semibold  md:text-base text-sm"> {resolveCheck(element, "score", 2) + "%"}</p>

                              <h1 className="md:text-base text-xs text-[#33B2FF] ">
                                {element.text ? "=>" + inner.text : ""}
                              </h1>
                            </div>
                          </div>
                        ) : null}
                      </>
                    ))
                    : null}

                </div>


              </div>



              <div className="">
                {finalList && finalList.length > 0
                  ? finalList.map((item) => (
                    <div className="space-y-6">
                      {item && item.data && item.data.length > 0
                        ? item.data.map((element) => (
                          <div className="md:p-4 p-4 space-y-4 bg-white border rounded-lg ">
                            <div className="flex flex-col items-center justify-between gap-4 md:flex-row ">
                              <div className="flex flex-col items-center gap-4 md:flex-row">
                                <p className=" font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap ">
                                  {"Demographic Wise" + " (" + element.name + ")"}
                                </p>
                                {/* <div className="flex items-center space-x-2 cursor-pointer">
                                  <h1 className="text-lg text-[#3D405B] font-medium">
                                    ({element.name})
                                  </h1>
                                </div> */}
                              </div>
                              <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>
                                  <path d="M10 12l4 4m0 -4l-4 4"></path>
                                </svg>
                                <span>Download Excel</span>
                              </button>
                            </div>
                            <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 ">
                              <div className="my-2 border border-white">
                                <h1 className="p-4 py-2 font-semibold capitalize bg-green-100 border-b border-white">
                                  top 5
                                </h1>

                                <table className="w-full ">
                                  <thead>
                                    <tr className="text-left text-[#212121] capitalize  border-b-2 border-white text-sm">
                                      {/* <th></th>
                                      <th></th> */}
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y-2 divide-white ">
                                    {element &&
                                      element.data &&
                                      element.data.length > 0
                                      ? sortFn(element.data).map((inner, index) => (
                                        <tr
                                          style={{ background: (index % 2 === 0 ? '#F5F5F5' : '#ECECEC') }}
                                          className={`border-b text-[#212121] text-sm border-l border-r border-white`}>
                                          <th className="p-2  font-medium capitalize border-r-2 border-white text-left px-4">{inner.value}</th>
                                          <td className="px-2 w-[40%] py-2">
                                            <div className="h-6 w-full bg-[#CFD8DC]">
                                              <div className="w-full flex items-center h-6 bg-[#1E88E5]"
                                                style={{ background: "#4CAF50", width: inner && inner.score ? (parseInt(inner.score * 100).toFixed(0).slice(0, 2)).toString() + "%" : "0%" }}>
                                                <span className="px-2 text-xs font-medium text-white"> {(inner.score * 100).toFixed(0).slice(0, 2)}%
                                                </span>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      ))
                                      : null}
                                  </tbody>

                                </table>

                              </div>

                              <div className="my-2 border border-white"> 
                                <h1 className="p-4 py-2 font-semibold capitalize bg-red-100 border-b border-white">
                                  Bottom 5
                                </h1> 
                                <table className="w-full ">
                                  <thead>
                                    <tr className="text-left text-[#212121] capitalize  border-b-2 border-white text-sm">
                                      {/* <th></th>
                                      <th></th> */}
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y-2 divide-white ">
                                    {getSecondList(element, item) &&
                                      getSecondList(element, item).length > 0
                                      ? sortFn(
                                        getSecondList(element, item)
                                      ).map((inner, index) => (
                                        <tr
                                          style={{ background: (index % 2 === 0 ? '#F5F5F5' : '#ECECEC') }}
                                          className={`border-b text-[#212121] text-sm border-l border-r border-white `}>
                                          <th className="p-2  font-medium capitalize border-r-2 border-white text-left px-4">{inner.value}</th>
                                          <td className="px-2 w-[40%] py-2">
                                            <div className="h-6 w-full bg-[#CFD8DC]">
                                              <div className="w-full flex items-center h-6 bg-[#1E88E5]"
                                                style={{ background: "#F44336", width: inner && inner.score ? (parseInt(inner.score * 100).toFixed(0).slice(0, 2)).toString() + "%" : "0%" }}>
                                                <span className="px-2 text-xs font-medium text-white">
                                                  {(inner.score * 100).toFixed(0).slice(0, 2)}%
                                                </span>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      ))
                                      :
                                      <div className="flex items-center justify-center text-xl text-center text-gray-400 bg-white h-28">
                                        <div class="text-center"><h2 class="text-[#212121] md:text-2xl text-xl font-semibold">   Data Not Found</h2><p class="md:text-lg text-base text-[#212121]/70">Opps! Looks like there isn't any data yet. </p></div>
                                      </div>
                                    }
                                  </tbody>

                                </table>
 
                              </div>
                            </div>
                          </div>
                        ))
                        : null}
                    </div>
                  ))
                  : null}
              </div>
            </div>
          </>
        ) : (
          <>
            {!loading ? (
              <div className="pt-40 text-3xl text-center text-gray-400"></div>
            ) : null}
          </>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(SummaryData);
