import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";


export default function GaugeScore(props) {

  let { score, Benchmark } = props;

  // let Benchmark={
  //   score:4.5
  // }



  return (
    <div>
      <div className='2xl:!w-[450px] 2xl:!h-[270px] md:!w-[400px] md:!h-[250px] !w-[300px] !h-[200px] -mt-4'>
        {score ?
          // <ReactSpeedometer
          //   fluidWidth={true}
          //   value={score}
          //   minValue={0}
          //   maxValue={100}
          //   needleColor="#4db8ff"
          //   segments={1}
          //   segmentColors={[
          //     "#5C6BC0",
          //   ]}
          //   ringWidth={50}
          //   customSegmentStops={[0, 100]}
          //   needleTransitionDuration={3333}
          //   needleTransition="easeElastic"
          // />
          <ReactSpeedometer
            fluidWidth={true}
            value={score} // Ensure this is your desired value, e.g., 70
            minValue={0}
            maxValue={100}
            needleColor="#4db8ff"
            segments={2} // Two segments: filled and unfilled
            segmentColors={["#5C6BC0", "#E0E0E0"]} // First color fills up to the value, second color for the rest
            ringWidth={50}
            customSegmentStops={[0, score, 100]} // Fill up to the current value, rest is unfilled
            needleTransitionDuration={3333}
            needleTransition="easeElastic"
          />

          :
          <h1 className='text-gray-400 text-center pt-12  '>Data not available!</h1>}











        {/* <ReactSpeedometer
          needleHeightRatio={0.7}
          maxSegmentLabels={5}
          segments={3}
          customSegmentStops={[0, 500, 750, 900, 1000]}
          segmentColors={['firebrick', 'tomato', 'gold', 'limegreen']}
          value={333}
          textColor={textColor}
        /> */}
        {/* <ReactSpeedometer
          forceRender={true}
          maxSegmentLabels={1}
          customSegmentStops={[500, 777, 1000]}
          segmentColors={['#5959ac', '#AAA']}
          needleColor={'#5959ac'}
          currentValueText={'Current Value: ${value}'}
          minValue={500}
          maxValue={1000}
          value={777}
          textColor={textColor}
        />
        <ReactSpeedometer
          forceRender={true}
          maxSegmentLabels={1}
          segmentColors={['tomato', 'gold']}
          needleColor={'#5959ac'}
          currentValueText={'Current Value: ${value}'}
          minValue={-120}
          maxValue={0}
          value={-100}
          customSegmentStops={[-120, -100, 0]}
          textColor={textColor}
        /> */}





      </div>
      {Benchmark && Benchmark.score || true ?
        <div className="gauge-outer">
          <div className="gauge-inner" style={{ transform: 'rotate(calc(1deg * (-45 + ' + 0 + ' * 29.95)))' }}></div>
        </div>
        : null}

    </div>
  );
}
