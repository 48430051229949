import React, { Component } from 'react';
import { connect } from 'react-redux';
import DemographicFilter from './Filters/DemographicFilter';
import QuestionFilter from './Filters/QuestionFilter';
import WordChart from './Content/WordChart';
import WordDetails from './Content/WordDetails';
import Comments from './Content/Comments';
import Commentary from '../../../Index/Components/Commentary/Commentary';
import WordsBarChart from './Content/Charts/WordsBarChart';

class TextAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuesId: '',
      DemographicFilterName: '',
      DemographicFilterValue: '',
      FilterList: [],
      showFilter: false,
      showValue: '',

      showViewBy: false
    }
  }



  componentDidMount() {
  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  closeFilterQues = (check1) => {
    this.setState({ showViewBy: check1 });
  }

  handleChangeQuesIdSave = (Id) => {
    this.setState({ currentQuesId: Id })
  }

  FilterSave = (name, value) => {
    this.setState({ DemographicFilterName: name, DemographicFilterValue: value })
  }

  handleKeywordCall = (keyWord, check) => {
    let { currentQuesId, FilterList } = this.state;
    let { getTextQuestionData, callWordCloudText, handleWordCloudDemographicFilter } = this.props;

    let initQuesId = '';
    if (currentQuesId) {
      initQuesId = currentQuesId;
    }
    else {
      initQuesId = getTextQuestionData && getTextQuestionData.length > 0 ? getTextQuestionData[0].id : '';
    }


    if (check) {

      // let wordList = []
      // if(wordCloudData && wordCloudData.list && wordCloudData.list.length>0){
      //   wordCloudData.list.forEach((item)=>{
      //     wordList.push(item[0])
      //   })

      // }

      handleWordCloudDemographicFilter(initQuesId, FilterList, 1, "topicModellingCustom", keyWord);
      // callWordCloudText(initQuesId, keyWord&&keyWord[0]?keyWord[0]:{}, FilterList,"","","manyKeywords")
    }
    else {
      callWordCloudText(initQuesId, keyWord, FilterList)

    }

  }


  handleKeywordCallOnly = (keyWord, check) => {
    let { currentQuesId, FilterList } = this.state;
    let { getTextQuestionData, callWordCloudText } = this.props;

    let initQuesId = '';
    if (currentQuesId) {
      initQuesId = currentQuesId;
    }
    else {
      initQuesId = getTextQuestionData && getTextQuestionData.length > 0 ? getTextQuestionData[0].id : '';
    }


    if (check) {

      // let wordList = []
      // if(wordCloudData && wordCloudData.list && wordCloudData.list.length>0){
      //   wordCloudData.list.forEach((item)=>{
      //     wordList.push(item[0])
      //   })

      // }

      // handleWordCloudDemographicFilter(initQuesId,FilterList, 1, "topicModellingCustom",keyWord);
      callWordCloudText(initQuesId, keyWord && keyWord[0] ? keyWord[0] : {}, FilterList, "", "", "manyKeywords")
    }
    else {
      callWordCloudText(initQuesId, keyWord, FilterList)

    }

  }





  // handleKeywordCall=(keyWord, check)=>{
  //   let { currentQuesId, DemographicFilterName, DemographicFilterValue, FilterList } = this.state;
  //   let { getTextQuestionData, callWordCloudText, wordCloudData , handleWordCloudDemographicFilter} = this.props;

  //   let initQuesId = '';
  //   if(currentQuesId){
  //     initQuesId = currentQuesId;
  //   }
  //   else{
  //     initQuesId = getTextQuestionData && getTextQuestionData.length>0?getTextQuestionData[0].id:'';
  //   }


  //   if(check){

  //     // let wordList = []
  //     // if(wordCloudData && wordCloudData.list && wordCloudData.list.length>0){
  //     //   wordCloudData.list.forEach((item)=>{
  //     //     wordList.push(item[0])
  //     //   })

  //     // }

  //     handleWordCloudDemographicFilter(initQuesId,FilterList, 1, "topicModellingCustom",keyWord);
  //     callWordCloudText(initQuesId, keyWord&&keyWord[0]?keyWord[0]:{}, FilterList,"","","manyKeywords")
  //   }
  //   else{
  //     callWordCloudText(initQuesId, keyWord, FilterList)

  //   }

  // }



  handleMultifilterIndexScore2 = (finalFilter) => {
    let { getTextQuestionData, wordCloudData } = this.props;
    let { currentQuesId, graphType } = this.state;
    let initQuesId = '';
    if (currentQuesId) {
      initQuesId = currentQuesId;
    }
    else {
      initQuesId = getTextQuestionData && getTextQuestionData.length > 0 ? getTextQuestionData[0].id : '';
    }

    let { handleWordCloudDemographicFilter } = this.props;

    this.setState({ FilterList: finalFilter });

    let wordList = []
    if (wordCloudData && wordCloudData.list && wordCloudData.list.length > 0) {
      wordCloudData.list.forEach((item) => {
        wordList.push(item[0])
      })

    }

    handleWordCloudDemographicFilter(initQuesId, finalFilter, graphType, "topicModelling", wordList);

  }

  render() {

    let { ErrorOtherObj, topicModellingError, onCreateTopicSubmit, getTopicListData, topicModellingData, loading, callQuestionWordCloud, wordTextKeyWord, wordCloudTextData, getTextQuestionData, handleChangeQuestion, wordCloudData, EmpFilterData,
      getIndexByFilterDMGData } = this.props;

    // let { currentQuesId} = this.state;

    ////console.log('getTextQuestionData: ',getTextQuestionData)

    // let initQuesId = '';


    // if(currentQuesId){
    //   initQuesId = currentQuesId;
    // }
    // else{
    //   initQuesId = getTextQuestionData && getTextQuestionData.length>0?getTextQuestionData[0].id:'';
    // }


    let { CountRestrict } = this.props;
    let count = wordCloudData && wordCloudData.count ? wordCloudData.count : "NaN";
    let CloudData = CountRestrict(count) ? [] : wordCloudData;
    let CloudTextData = CountRestrict(count) ? [] : wordCloudTextData;
    let wordTextKeyWordData = CountRestrict(count) ? "" : wordTextKeyWord;
    let newWordList = CountRestrict(count) ? [] : (wordCloudData && wordCloudData.list && wordCloudData.list.length > 0 ? wordCloudData.list : []);



    let maxCount = 0;
    if (newWordList && newWordList.length > 0) {
      newWordList.forEach((item) => {
        if (item[1] > maxCount) {
          maxCount = item[1];
        }
      });
    }





    let topicData = []
    if (topicModellingData && topicModellingData.list && topicModellingData.list.length > 0) {
      topicModellingData.list.forEach((item) => {
        let temp = {
          "keyword": item.word,
          "sentimentPerc": {
            "Positive": 0,
            "Negative": 0,
            "Neutral": 0
          }
        }

        if (item && item.sentiments.length > 0) {
          item.sentiments.forEach((item2) => {
            let sentiment = item2 && item2[1] && item2[1][0] ? item2[1][0] : "Neutral";
            //////console.log("sentiment",sentiment)
            if (sentiment === "Neutral") {
              temp["sentimentPerc"]['Neutral'] += 1
            }
            else if (sentiment === "POSITIVE") {
              temp["sentimentPerc"]['Positive'] += 1
            }
            else if (sentiment === "NEGATIVE") {
              temp["sentimentPerc"]['Negative'] += 1
            }

          })
        }


        topicData.push(temp)




      });
    }


    //console.log('topicData---->',topicData)
    let { indexType, optionType, getCommentData } = this.props;

    return (
      <>
        <div onClick={() => this.closeFilter(false)} >
          <div className='flex flex-wrap items-start justify-between w-full gap-4' >

            <QuestionFilter
              showViewBy={this.state.showViewBy}
              closeFilterQues={this.closeFilterQues}
              wordCloudData={wordCloudData}
              callQuestionWordCloud={callQuestionWordCloud} getTextQuestionData={getTextQuestionData} handleChangeQuestion={handleChangeQuestion} handleChangeQuesIdSave={this.handleChangeQuesIdSave}
            />


          </div>
        </div>



        {/*sub nav bar 2 */}
        <div className="items-center justify-between space-y-4 md:flex lg:space-y-0">
          <DemographicFilter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
          />

          <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        </div>

        <div className="flex items-center space-x-3 capitalize">
          <p className="text-sm text-gray-500 capitalize ">total comments :<b className="font-semibold text-gray-600">{wordCloudTextData && wordCloudTextData.length > 0 ? wordCloudTextData.length : 0}</b></p>
        </div>
        {/* end */}
        {/* main work */}
        {/* no 1 */}
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 ">



          {ErrorOtherObj && ErrorOtherObj["topicModellingError"] ?
            <>
              <div className='py-6 my-24 text-2xl font-medium text-center text-gray-400 bg-white'>{ErrorOtherObj["topicModellingError"]}</div>
            </>
            :
            <WordChart topicModellingError={topicModellingError} onCreateTopicSubmit={onCreateTopicSubmit} getTopicListData={getTopicListData} topicData={topicData} loading={loading} wordCloudData={CloudData} handleKeywordCallOnly={this.handleKeywordCallOnly} handleKeywordCall={this.handleKeywordCall} />
          }

          <WordDetails EmpFilterData2={EmpFilterData} loading={loading} wordCloudTextData={CloudTextData} wordTextKeyWord={wordTextKeyWordData} />



        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(TextAnalysis);
