import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import ReactTooltip from "react-tooltip";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSurveyName: "",
      currentSurveyId: "",
      showDropdown: false,
      gameType: "",

      // showSidebar:false
    };
  }
  componentDidMount() { }

  onDropdown = () => {
    if (this.state.showDropdown) {
      this.setState({ showDropdown: false });
    } else {
      this.setState({ showDropdown: true });
    }
  };

  logout = () => {
    this.props.dispatch(userActions.logout());
  };

  IndexHoverOptions = (surveyId, indexType) => {
    let { onOptionType } = this.props;

    return (
      <span>
        <i
          className="fa fa-scribd"
          onClick={() => onOptionType(surveyId, indexType, 1)}
        />
        <i
          className="fa fa-quora"
          onClick={() => onOptionType(surveyId, indexType, 2)}
        />
        <i
          className="fa fa-align-left"
          onClick={() => onOptionType(surveyId, indexType, 3)}
        />
        {/* <i className="fa fa-facebook" onClick={()=>onOptionType(surveyId,indexType,4)}/>  */}
      </span>
    );
  };

  handleIndexType = (surveyId, indexType, isDisable, event) => {
    this.setState({ gameType: event });
    let { onOptionType, callOverviewApi } = this.props;

    if (isDisable === "Overview") {
      callOverviewApi();
    } else {
      onOptionType(surveyId, indexType, 1);
    }

    // if (!isDisable) {
    //   onOptionType(surveyId, indexType, 1);
    // } else {
    //   // callOverviewApi();
    // }
  };

  sidebarToggle = () => {
    let { handleSidebar, showSidebar } = this.props;
    handleSidebar(!showSidebar);
  };

  render() {
    let {
      SurveyList,
      getIndexData,
      indexType,
      CurrentSurveyId,
      showMobileSidebar,
      isLifecycle,
    } = this.props;
    let { showSidebar } = this.props;

    let surveyId = "";
    if (SurveyList && SurveyList.length > 0) {
      if (CurrentSurveyId) {
        surveyId = CurrentSurveyId;
      } else {
        surveyId = SurveyList[0].id;
      }
    }

    let indexLabelsList = [];
    let IndexList = [];

    function getIndexName(key) {
      let name = "";
      if (key === 1) {
        name = "Happiness";
      } else if (key === 2) {
        name = "Engagement";
      } else if (key === 3) {
        name = "Hope";
      } else if (key === 4) {
        name = "Stress";
      } else if (key === 5) {
        name = "Manager";
      } else if (key === 6) {
        name = "Leadership";
      } else if (key === 7) {
        name = "Safety";
      } else if (key === 9) {
        name = "Diversity";
      } else {
        name = "NaN";
      }
      return name;
    }

    if (getIndexData) {
      IndexList = Object.keys(getIndexData).map((key) => [
        Number(key),
        getIndexData[key],
      ]);

      IndexList.forEach((data) => {
        if (getIndexName(data[0]) !== "NaN" && data && data[0] && data[1]) {
          indexLabelsList.push({
            template: data[0],
            score: data[1].toFixed(2),
            name: getIndexName(data[0]),
          });
        }
      });
    }

    function getIconsByTemplateNo(template) {
      return template === 1 ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-list"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.8"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="9" />
          <line x1="9" y1="10" x2="9.01" y2="10" />
          <line x1="15" y1="10" x2="15.01" y2="10" />
          <path d="M9.5 15a3.5 3.5 0 0 0 5 0" />
        </svg>
      ) : template === 2 ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-users"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.8"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="9" cy="7" r="4" />
          <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
          <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
        </svg>
      ) : template === 4 ? (
        "sentiment_dissatisfied"
      ) : template === 5 ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-user-circle"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.8"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="9" />
          <circle cx="12" cy="10" r="3" />
          <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" />
        </svg>
      ) : template === 6 ? (
        "leaderboard"
      ) : template === 8 ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-building-community"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.8"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M8 9l5 5v7h-5v-4m0 4h-5v-7l5 -5m1 1v-6a1 1 0 0 1 1 -1h10a1 1 0 0 1 1 1v17h-8" />
          <line x1="13" y1="7" x2="13" y2="7.01" />
          <line x1="17" y1="7" x2="17" y2="7.01" />
          <line x1="17" y1="11" x2="17" y2="11.01" />
          <line x1="17" y1="15" x2="17" y2="15.01" />
        </svg>
      ) : template === 102 ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-gauge"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.8"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="9" />
          <circle cx="12" cy="12" r="1" />
          <line x1="13.41" y1="10.59" x2="16" y2="8" />
          <path d="M7 12a5 5 0 0 1 5 -5" />
        </svg>
      ) : template === 103 ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-chart-pie-2"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.8"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 3v9h9" />
          <circle cx="12" cy="12" r="9" />
        </svg>
      ) : template === 104 ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-graph"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.8"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M4 18v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
          <path d="M7 14l3 -3l2 2l3 -3l2 2"></path>
        </svg>
      ) : template === 105 ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-recharging"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.8"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M7.038 4.5a9 9 0 0 0 -2.495 2.47" />
          <path d="M3.186 10.209a9 9 0 0 0 0 3.508" />
          <path d="M4.5 16.962a9 9 0 0 0 2.47 2.495" />
          <path d="M10.209 20.814a9 9 0 0 0 3.5 0" />
          <path d="M16.962 19.5a9 9 0 0 0 2.495 -2.47" />
          <path d="M20.814 13.791a9 9 0 0 0 0 -3.508" />
          <path d="M19.5 7.038a9 9 0 0 0 -2.47 -2.495" />
          <path d="M13.791 3.186a9 9 0 0 0 -3.508 -.02" />
          <path d="M12 8l-2 4h4l-2 4" />
          <path d="M12 21a9 9 0 0 0 0 -18" />
        </svg>
      ) : template === 106 ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-file-invoice"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.8"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M14 3v4a1 1 0 0 0 1 1h4" />
          <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
          <line x1="9" y1="7" x2="10" y2="7" />
          <line x1="9" y1="13" x2="15" y2="13" />
          <line x1="13" y1="17" x2="15" y2="17" />
        </svg>
      ) : template === 107 ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-report"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.8"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
          <path d="M18 14v4h4" />
          <path d="M18 11v-4a2 2 0 0 0 -2 -2h-2" />
          <rect x="8" y="3" width="6" height="4" rx="2" />
          <circle cx="18" cy="18" r="4" />
          <path d="M8 11h4" />
          <path d="M8 15h3" />
        </svg>
      ) : template === 108 ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-target"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.8"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="1" />
          <circle cx="12" cy="12" r="5" />
          <circle cx="12" cy="12" r="9" />
        </svg>
      ) : template === 109 ? (
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-library" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M7 3m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
          <path d="M4.012 7.26a2.005 2.005 0 0 0 -1.012 1.737v10c0 1.1 .9 2 2 2h10c.75 0 1.158 -.385 1.5 -1" />
          <path d="M11 7h5" />
          <path d="M11 10h6" />
          <path d="M11 13h3" />
        </svg>
      ) : template === 121 ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-user-exclamation"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.8"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="9" cy="7" r="4" />
          <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
          <line x1="19" y1="7" x2="19" y2="10" />
          <line x1="19" y1="14" x2="19" y2="14.01" />
        </svg>
      ) : (
        "dashboard"
      );
    }

    let otherLabelList = [
      {
        name: "Organization Core",
        template: 8,
        show: true,
      },
      {
        name: "Manager Score Card",
        template: 102,
        show: (CurrentSurveyId === "652651170849bbd94cd5d66a" ? false : !isLifecycle),
      },
      {
        name: "Advance Insights",
        template: 103,
        show: !isLifecycle,
      },
      {
        name: "Text Analysis",
        template: 104,
        show: true,
      },
      {
        name: "Strength & Weakness",
        template: 105,
        show: !isLifecycle,
      },
      {
        name: "Manager Action Plan",
        template: 106,
        show: (CurrentSurveyId === "652651170849bbd94cd5d66a" ? false : !isLifecycle),
      },
      {
        name: "Goal Plan",
        template: 108,
        show: !isLifecycle,
      },
      {
        name: "Report",
        template: 107,
        show: !isLifecycle,
      },
      {
        name: "People to Meet",
        template: 121,
        show: true,
      },
      {
        name: "Excel Download",
        template: 122,
        show: true,
      },
    ];


    let preLabelList = [
      {
        name: "Snapshot",
        template: 109,
        show: true,
      }
    ]





    return (
      <>
        <div
          className={
            showSidebar
              ? "cursor-pointer transition-width w-64 lg:block scollar-xs  lg:relative fixed top-0 left-0  h-screen lg:z-20 z-20 overflow-y-auto overflow-hidden  duration-200 transform bg-white border-r customscroll4 " +
              (showMobileSidebar ? "" : "hidden")
              : "cursor-pointer transition-width  w-16 lg:block scollar-xs lg:relative fixed top-0 left-0  h-screen lg:z-20 z-20 overflow-y-auto overflow-hidden  duration-200 transform bg-white border-r customscroll4 " +
              (showMobileSidebar ? "" : "hidden")
          }
          style={{ height: "calc(100% - 0rem)" }}
        >
          {!showMobileSidebar ? (
            <div className="sticky top-0 flex items-center bg-white ">
              <div className="flex items-center justify-center w-16 px-4 py-3 text-gray-500">
                <span
                  onClick={() => this.sidebarToggle()}
                  className="p-1 bg-gray-100 rounded material-symbols-outlined"
                >
                  {showSidebar ? "chevron_left" : "chevron_right"}
                </span>
              </div>
              <span className="text-sm text-gray-500 ">Insight</span>
            </div>
          ) : (
            <div className="py-1" />
          )}

          <div className="mb-4 whitespace-nowrap ">
            {/* 1 */}
            <div
              data-tip="Overview"
              onClick={() => this.handleIndexType(surveyId, 0, "Overview")}
              className={
                indexType === 0
                  ? "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer border-l-4 border-[#2196F3] bg-[#2196F3]/10"
                  : "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer border-l-4 border-[#fff]"
              }
            >
              <div
                className={
                  indexType === 0
                    ? "flex items-center px-4 py-4 w-16  text-[#2196F3] h-12 justify-center"
                    : "flex items-center px-4 py-4 w-16 text-[#3D405B]  h-12 justify-center"
                }
              >
                <span className="p-2 material-symbols-outlined">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-layout-dashboard"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.8"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M4 4h6v8h-6z"></path>
                    <path d="M4 16h6v4h-6z"></path>
                    <path d="M14 12h6v8h-6z"></path>
                    <path d="M14 4h6v4h-6z"></path>
                  </svg>
                </span>
              </div>
              <span
                className={
                  indexType === 0 ? " text-sm text-[#2196F3]" : " text-sm  "
                }
              >
                Overview
              </span>
            </div>
            <ReactTooltip
              place="right"
              className="extraClass1"
              type="dark"
              effect="solid"
            />
            {/* 2 */}

            {preLabelList && preLabelList.length > 0
              ? preLabelList.map((item, index) =>
                item.show ? (
                  <div
                    title={item && item.name ? item.name : ""}
                    key={index}
                    onClick={() =>
                      this.handleIndexType(
                        surveyId,
                        item.template,
                        indexType === item.template
                      )
                    }
                    className={
                      indexType === item.template
                        ? "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer   border-l-4 border-[#2196F3] bg-[#2196F3]/10"
                        : "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer  border-l-4 border-[#fff]"
                    }
                  >
                    <div
                      className={
                        indexType === item.template
                          ? "flex items-center px-4 py-4 w-16  text-[#2196F3] h-12 justify-center"
                          : "flex items-center px-4 py-4 w-16  text-[#3D405B]  h-12 justify-center"
                      }
                    >
                      <span className="p-2 material-symbols-outlined">
                        {getIconsByTemplateNo(item.template)}
                      </span>
                    </div>
                    <span
                      className={
                        indexType === item.template
                          ? " text-sm text-[#2196F3]"
                          : " text-sm text-[#3D405B] "
                      }
                    >
                      {item && item.name ? item.name : ""}
                    </span>
                  </div>
                ) : null
              )
              : null}

            {indexLabelsList && indexLabelsList.length > 0
              ? indexLabelsList.map((item, index) => (
                <div
                  title={(item && item.name ? item.name : "") + " Insight"}
                  key={index}
                  onClick={() =>
                    this.handleIndexType(
                      surveyId,
                      item.template,
                      indexType === item.template
                    )
                  }
                  className={
                    indexType === item.template
                      ? "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer border-l-4 border-[#2196F3] bg-[#2196F3]/10"
                      : "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer border-l-4 border-[#fff]"
                  }
                >
                  <div
                    className={
                      indexType === item.template
                        ? "flex items-center px-4 py-4 w-16  text-[#2196F3] h-12 justify-center"
                        : "flex items-center px-4 py-4 w-16 text-[#3D405B]  h-12 justify-center"
                    }
                  >
                    <span className="p-2 material-symbols-outlined">
                      {getIconsByTemplateNo(item.template)}
                    </span>
                  </div>
                  <span
                    className={
                      indexType === item.template
                        ? " text-sm text-[#2196F3]"
                        : " text-sm "
                    }
                  >
                    {(item && item.name ? item.name : "") + " Insight"}
                  </span>
                </div>
              ))
              : null}

            {otherLabelList && otherLabelList.length > 0
              ? otherLabelList.map((item, index) =>
                item.show ? (
                  <div
                    title={item && item.name ? item.name : ""}
                    key={index}
                    onClick={() =>
                      this.handleIndexType(
                        surveyId,
                        item.template,
                        indexType === item.template
                      )
                    }
                    className={
                      indexType === item.template
                        ? "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer   border-l-4 border-[#2196F3] bg-[#2196F3]/10"
                        : "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer border-l-4 border-[#fff]"
                    }
                  >
                    <div
                      className={
                        indexType === item.template
                          ? "flex items-center px-4 py-4 w-16  text-[#2196F3] h-12 justify-center"
                          : "flex items-center px-4 py-4 w-16  text-[#3D405B]  h-12 justify-center"
                      }
                    >
                      <span className="p-2 material-symbols-outlined">
                        {getIconsByTemplateNo(item.template)}
                      </span>
                    </div>
                    <span
                      className={
                        indexType === item.template
                          ? " text-sm text-[#2196F3]"
                          : " text-sm text-[#3D405B] "
                      }
                    >
                      {item && item.name ? item.name : ""}
                    </span>
                  </div>
                ) : null
              )
              : null}
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn,
  };
}
export default connect(mapStateToProps)(Sidebar);
