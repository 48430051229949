// ProgressBar.js
import React from 'react';

const ProgressBar = ({ label, percentage, color }) => {
    return (
        <>
            <div className="flex items-center flex-shrink-0 space-x-2 text-[#212121]">
                <div className='w-full space-y-10'>
                    <div className='flex items-center space-x-6 '>
                        <p className='w-48 text-right'>{label}</p>
                        <div className='h-8 bg-[#F1F5F9] w-full'>
                            <div className='h-8 flex justify-end items-center' style={{ width: `${percentage}%`, backgroundColor: color, }} >
                                <span className='text-white text-sm px-2 font-medium'>{percentage}%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProgressBar;
