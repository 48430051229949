import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {

    let {DemographicValues, DemographicValuesObj,XAxis,AnswerObjOverall,
        
        
        currFilterValue, currFilterName, getDistributionIndexData,getIndexDriverData, GetParameterLabel }= this.props;

    //console.log('xxxxx--------nps -----------------AnswerObjOverall: ',AnswerObjOverall)     
    
    
    function GetNPSScore(ValuesObj, item){
      if(item==="OVERALL"){

        let totalCount = 0;
        let Promoters = 0;
        let Passsives = 0;
        let Distractors = 0;

        if(ValuesObj){
          Object.keys(ValuesObj).forEach(key => {
            let score = parseInt(ValuesObj[key]);
    
            totalCount+=score;
    
            if(parseInt(key) >= 9 && parseInt(key) <= 10){
              Promoters+=score;
            }
            if(parseInt(key) >= 7 && parseInt(key) <= 8){
              Passsives+=score;
            }
            if(parseInt(key) >= 1 && parseInt(key) <= 6){
              Distractors+=score;
            }
    
          });
        }

        let total = Promoters + Passsives + Distractors;
        let NPScore = ((Promoters*100/total)-(Distractors*100/total)).toFixed(2);
        return NPScore==="NaN"?0:NPScore
      }
      else{
        let Promoters =  ValuesObj[item].Promoters;
        let Passsives = ValuesObj[item].Passsives;
        let Distractors = ValuesObj[item].Distractors;
        let total = Promoters + Passsives + Distractors;
        let NPScore = ((Promoters*100/total)-(Distractors*100/total)).toFixed(2);
        // return NPScore==="NaN"?0:NPScore

        let count = ValuesObj[item]["count"];
        return count>5?  (NPScore==="NaN"?0:NPScore)  : "NaN"


      }

    }   

    function GetPerc(ValuesObj,item,type){

      if(item==="OVERALL"){

        let totalCount = 0;
        let Promoters = 0;
        let Passsives = 0;
        let Distractors = 0;
  
        if(ValuesObj){
          Object.keys(ValuesObj).forEach(key => {
            let score = parseInt(ValuesObj[key]);
    
            totalCount+=score;
    
            if(parseInt(key) >= 9 && parseInt(key) <= 10){
              Promoters+=score;
            }
            if(parseInt(key) >= 7 && parseInt(key) <= 8){
              Passsives+=score;
            }
            if(parseInt(key) >= 1 && parseInt(key) <= 6){
              Distractors+=score;
            }
    
          });
        }
        //console.log('xxxxx--------nps -----------------Promoters + Passsives + Distractors: ',Promoters , Passsives , Distractors)     
        let typeValue = 0;
        if(type==="Promoters"){typeValue=Promoters}else if(type==="Passsives"){typeValue=Passsives}else if(type==="Distractors"){typeValue=Distractors}
        let total = Promoters + Passsives + Distractors;
        let score = ((typeValue*100)/total);
        return score?score.toFixed(2):0
      }
      else{
        let Promoters =  ValuesObj[item]["Promoters"];
        let Passsives = ValuesObj[item]["Passsives"];
        let Distractors = ValuesObj[item]["Distractors"];
        let total = Promoters + Passsives + Distractors;
        let score = (((ValuesObj[item][type])*100)/total)
        // return score?score.toFixed(2):0

        let count = ValuesObj[item]["count"];
        return count>5?  (score?score.toFixed(2):0)   : "NaN"
      }

    }





    DemographicValues.sort();

    return (
      <>

      <div className="py-1  whitespace-nowrap overflow-x-auto pb-6 ">
      <table className="border-collapse w-full">
        <thead>

          <tr className="text-xs">
            <th className="p-3 font-bold bg-gray-50 text-gray-800 border border-gray-300 ">{XAxis}</th>
            <th className="p-3 font-bold bg-gray-50 text-gray-800 border border-gray-300 px-10">{"NPS Score (%)"}</th>
            <th className="p-3 font-bold bg-gray-50 text-gray-800 border border-gray-300 px-10">{"Promoters (%)"}</th>
            <th className="p-3 font-bold bg-gray-50 text-gray-800 border border-gray-300">{"Passsives (%)"}</th>
            <th className="p-3 font-bold bg-gray-50 text-gray-800 border border-gray-300">{"Detractors (%)"}</th>
          </tr>

        </thead>
        <tbody>

            {/* <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                <td className="p-3 border border-b text-left" >    
                  {"Overall"}
                </td>
                <td className="p-3 border border-b px-6" >    
                  {GetNPSScore(DemographicValuesObj,item)}
                </td>
                <td className="p-3 border border-b px-6" >      
                    {DemographicValuesObj[item].Promoters}
                </td>
                <td className="p-3 border border-b px-6" >      
                    {DemographicValuesObj[item].Passsives}
                </td>
                <td className="p-3 border border-b px-6" >      
                    {DemographicValuesObj[item].Distractors}
                </td>
              </tr> */}

            <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                <td className="p-3 border border-b text-left" >    
                  {"OVERALL"}
                </td>
                <td className="p-3 border border-b px-6 font-bold" >    
                  {GetNPSScore(AnswerObjOverall,"OVERALL")}
                </td>
                <td className="p-3 border border-b px-6 bg-green-300" >      
                    {GetPerc(AnswerObjOverall,"OVERALL","Promoters")}
                </td>
                <td className="p-3 border border-b px-6 bg-yellow-300" >      
                    {GetPerc(AnswerObjOverall,"OVERALL","Passsives")}
                </td>
                <td className="p-3 border border-b px-6 bg-red-300" >      
                    {GetPerc(AnswerObjOverall,"OVERALL","Distractors")}
                </td>
              </tr>

          {DemographicValues && DemographicValues.length>0?
            DemographicValues.map((item,index)=>
            (item)?
              <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                <td className="p-3 border border-b text-left" >    
                  {item}
                </td>
                <td className="p-3 border border-b px-6 font-bold" >    
                  {GetNPSScore(DemographicValuesObj,item)}
                </td>
                <td className="p-3 border border-b px-6 bg-green-300" >      
                    {GetPerc(DemographicValuesObj,item,"Promoters")}
                </td>
                <td className="p-3 border border-b px-6 bg-yellow-300" >      
                    {GetPerc(DemographicValuesObj,item,"Passsives")}
                </td>
                <td className="p-3 border border-b px-6 bg-red-300" >      
                    {GetPerc(DemographicValuesObj,item,"Distractors")}
                </td>
              </tr>
            :null
            ):null}



        </tbody>
      </table>
    </div>

</>
    
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HeatMap);
