import React, { Component } from "react";
import { connect } from "react-redux";
import NudgeModal from "./NudgeModal";

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddNudgeModal: false
    };
  }
  componentDidMount() { }

  handleAddNudgeModal = (ques) => {
    this.setState({ openAddNudgeModal: ques });
  }

  addNudgeToQues = (ques) => {
    let { openAddNudgeModal } = this.state;
    let { handleMapNudgeToQuestion } = this.props;

    if (openAddNudgeModal && openAddNudgeModal.id) {
      let data = {
        "questionId": openAddNudgeModal.id,
        "nudgeLibraryId": ques.id,
        "rangeList": ques.rangeList
      }
      console.log("data---->", data)
      handleMapNudgeToQuestion(data)
    }
  }

  render() {
    let {
      loading,
      getNudgeLibListData,
      questionsList,
      GetParameterLabel,
      getNudgeTextQuesList

    } = this.props;

    let { openAddNudgeModal } = this.state;

    // console.log("getNudgeLibListData---->", getNudgeLibListData)
    // console.log("questionsList---->", questionsList)
    // console.log("getNudgeTextQuesList---->", getNudgeTextQuesList)


    function getFormattedParameter(element, field1, field2) {
      return element && element[field1] && element[field1][field2] ? element[field1][field2] : ""
    }

    let crrRangeList = []
    if (openAddNudgeModal && openAddNudgeModal.id) {
      if (getNudgeTextQuesList && getNudgeTextQuesList.length > 0) {
        let getIndex = getNudgeTextQuesList.findIndex(prev => prev.questionId.toString() === openAddNudgeModal.id.toString());
        if (getIndex !== -1) {
          crrRangeList = getNudgeTextQuesList[getIndex]["rangeList"]
        }
      }
    }
    // console.log("openAddNudgeModal.id---->", openAddNudgeModal.id)
    // console.log("crrRangeList---->", crrRangeList)



    const getNudgeRangeByQuesId=(list, questionId)=>{
      let crrRangeList = []
      if (questionId) {
        if (list && list.length > 0) {
          let getIndex = list.findIndex(prev => prev.questionId.toString() === questionId.toString());
          if (getIndex !== -1) {
            crrRangeList = list[getIndex]["rangeList"]
          }
        }
      }
      return crrRangeList
    }


    return (
      <>
        {true ? (
          <>
            <div className="space-y-6 ">
              <div className="p-6 space-y-6 bg-white border rounded-lg">
                <div className="flex flex-col items-center gap-2 md:flex-row">
                  <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B]">
                    {" "}
                    Nudge Mapping
                  </h1>



                </div>



                <div
                  className="lg:p-6 p-4  h-[40rem]  overflow-y-auto space-y-6" >
                  <div className="space-y-4 " >
                    {questionsList && questionsList.length > 0 ?
                      questionsList.map((ques, index) =>
                        <div className="flex justify-between p-4 border rounded-lg bg-slate-50">
                          {/* <h1 className="text-lg font-medium">{GetParameterLabel(ele.para)}</h1> */}
                          <div>
                            <div>
                              <ul className="flex ">
                                <span className="p-2 px-1 text-sm leading-7 ">{(index + 1).toString() + '. '}</span>
                                <li className="p-2 text-sm leading-7 ">
                                  {ques.name}

                                </li>
                              </ul>

                              <ul className="flex ">
                                <li className="p-2 text-sm leading-7 ">
                                  {GetParameterLabel(getFormattedParameter(ques, "parameterBucketId", "name"))}
                                </li>
                                <li className="p-2 text-sm leading-7 ">
                                  {GetParameterLabel(getFormattedParameter(ques, "parameterDimensionId", "name"))}
                                </li>
                              </ul>

                              <ul className="">
                                {getNudgeRangeByQuesId(getNudgeTextQuesList,ques.id) && getNudgeRangeByQuesId(getNudgeTextQuesList,ques.id).length > 0 ?
                                  getNudgeRangeByQuesId(getNudgeTextQuesList,ques.id).map((inner) =>
                                    <>
                                      <li className="p-2 text-sm leading-7 ">
                                        <div className="p-2 font-bold">{inner.label}</div>
                                        <div className="px-2">{'"' + ques.name + '" ' + inner.text}</div>
                                      </li>
                                    </>
                                  ) : null}

                              </ul>

                            </div>
                          </div>

                          <div>
                            <button onClick={() => this.handleAddNudgeModal(ques)} className="cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] ">{"Add Nudge"}</button>

                          </div>
                        </div>
                      ) : null}

                  </div>
                </div>



                {/* Distribution Score */}
                <div className="grid w-4/6 grid-cols-2 gap-4 mx-auto my-2 lg:grid-cols-4 lg:gap-6">


                </div>


              </div>


            </div>
          </>
        ) : (
          <>
            {!loading ? (
              <div className="pt-40 text-3xl text-center text-gray-400"></div>
            ) : null}
          </>
        )}



        {openAddNudgeModal && (<>

            <NudgeModal
              handleAddNudgeModal={this.handleAddNudgeModal}
              question={openAddNudgeModal}
              getNudgeLibListData={getNudgeLibListData}
              addNudgeToQues={this.addNudgeToQues}
              getFormattedParameter={getFormattedParameter}
              GetParameterLabel={GetParameterLabel}
              crrRangeList={crrRangeList}
            />

        </>)}
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(SummaryData);
