import {
  surveyConstants
} from '../_constants';

export function survey(state = {}, action) {

  switch (action.type) {

    case surveyConstants.SURVEY_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.SURVEY_GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        getSurveyListSuccess: true,
        getSurveyListData: action.survey.getSurveyListData.list,
        getSurveyListTotal: action.survey.getSurveyListData.total,

      };
    case surveyConstants.SURVEY_GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.UPDATE_SHOW_REPORT_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.UPDATE_SHOW_REPORT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateEmployeeShowReportStatusSuccess: true,

      };
    case surveyConstants.UPDATE_SHOW_REPORT_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };






    case surveyConstants.UPDATE_EMPLOYEE_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.UPDATE_EMPLOYEE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateCustomFilterStatusSuccess: true,

      };
    case surveyConstants.UPDATE_EMPLOYEE_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };






    case surveyConstants.QUESTIONTYPE_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.QUESTIONTYPE_GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionTypeListSuccess: true,
        getQuestionTypeListData: action.survey.getQuestionTypeListData.list,
        getQuestionTypeListDataTotal: action.survey.getQuestionTypeListData.total,

      };
    case surveyConstants.QUESTIONTYPE_GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case surveyConstants.GET_SURVEY_DATA_REQUEST:
      return {
        ...state
      };
    case surveyConstants.GET_SURVEY_DATA_SUCCESS:
      return {
        ...state,
        expireSurveySuccess: false,
        loading: false,
        getSurveySuccess: true,
        getSurveyData: action.survey.getSurveyData

      };
    case surveyConstants.GET_SURVEY_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case surveyConstants.GET_SURVEY_QUE_RANDOMIZE_REQUEST:
      return {
        ...state
      };
    case surveyConstants.GET_SURVEY_QUE_RANDOMIZE_SUCCESS:
      return {
        ...state,
        expireSurveySuccess: false,
        loading: false,
        onSurveyQueRandomizeSuccess: true,
        onSurveyQueRandomizeData: action.survey.onSurveyQueRandomizeData

      };
    case surveyConstants.GET_SURVEY_QUE_RANDOMIZE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case surveyConstants.PARAMETERLIST_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.PARAMETERLIST_GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        addParameterSuccess: false,
        updateParameterSuccess: false,

        parameterList: action.survey.getParameterListData.list,
        parameterTotal: action.survey.getParameterListData.total,
        total: action.survey.getParameterListData.total
      };
    case surveyConstants.PARAMETERLIST_GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.WELCOME_SURVEY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.WELCOME_SURVEY_SUCCESS:
      return {
        ...state,
        expireSurveySuccess: false,
        loading: false,

        welcomeSurveySuccess: true
      };
    case surveyConstants.WELCOME_SURVEY_FAILURE:
      return {
        ...state,
        loading: false,

        error: action.error
      };


    case surveyConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_SUCCESS:
      return {
        ...state,
        addParameterSuccess: false,
        updateParameterSuccess: false,
        loading: false,

        getParameterListbyParentData: action.survey.getParameterListbyParentData
      };
    case surveyConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_FAILURE:
      return {
        ...state,
        loading: false,

        error: action.error
      };



      case surveyConstants.PARA_LIB_BY_PARENTS_NAMES_REQUEST:
        return {
          ...state,
          loading: true
        };
      case surveyConstants.PARA_LIB_BY_PARENTS_NAMES_SUCCESS:
        return {
          ...state,
          addParameterSuccess: false,
          updateParameterSuccess: false,
          loading: false,
  
          getParaLibraryByParentNamesData: action.survey.getParaLibraryByParentNamesData
        };
      case surveyConstants.PARA_LIB_BY_PARENTS_NAMES_FAILURE:
        return {
          ...state,
          loading: false,
  
          error: action.error
        };




    case surveyConstants.SET_EXPIRY_SURVEY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.SET_EXPIRY_SURVEY_SUCCESS:
      return {
        ...state,
        setExpireSurveySuccess: true,
        loading: false,
        setExpireSurveyData: action.survey.setExpireSurveyData
      };
    case surveyConstants.SET_EXPIRY_SURVEY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.CREATE_NEW_SURVEY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.CREATE_NEW_SURVEY_SUCCESS:
      return {
        ...state,
        createSurveySuccess: true,
        loading: false,
        createSurveyData: action.survey.createSurveyData
      };
    case surveyConstants.CREATE_NEW_SURVEY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.DELETE_SURVEY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.DELETE_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteSurveySuccess: true,
      };
    case surveyConstants.DELETE_SURVEY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case surveyConstants.UPDATE_SURVEY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.UPDATE_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        updateSurveySuccess: true,
      };
    case surveyConstants.UPDATE_SURVEY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case surveyConstants.RESPONSE_ANALYTICS_SURVEY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.RESPONSE_ANALYTICS_SURVEY_SUCCESS:
      return {
        ...state,
        responseAnalysisBySurveyIdSuccess: true,
        loading: false,
        responseAnalysisBySurveyIdData: action.survey.responseAnalysisBySurveyIdData

      };
    case surveyConstants.RESPONSE_ANALYTICS_SURVEY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };






    case surveyConstants.RESPONSE_360_ANALYTICS_SURVEY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.RESPONSE_360_ANALYTICS_SURVEY_SUCCESS:
      return {
        ...state,
        responseAnalysisBySurveyIdSuccess: true,
        loading: false,
        responseAnalysisBySurveyIdData: action.survey.responseAnalysisBySurveyIdData

      };
    case surveyConstants.RESPONSE_360_ANALYTICS_SURVEY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.CAMPAIGN_EMPLOYEES_SURVEY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.CAMPAIGN_EMPLOYEES_SURVEY_SUCCESS:
      return {
        ...state,
        getCampaignEmployeesByIDSurveyWiseSuccess: true,
        loading: false,
        getCampaignEmployeesByIDSurveyWiseData: action.survey.getCampaignEmployeesByIDSurveyWiseData

      };
    case surveyConstants.CAMPAIGN_EMPLOYEES_SURVEY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.RESEND_CAMPAIGN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.RESEND_CAMPAIGN_SUCCESS:
      return {
        ...state,
        resendCampainSuccess: true,
        loading: false,
        resendCampainData: action.survey.resendCampainData

      };
    case surveyConstants.RESEND_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.RESEND_ASSESSMENT_CAMPAIGN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.RESEND_ASSESSMENT_CAMPAIGN_SUCCESS:
      return {
        ...state,
        resendAssessmentCampainSuccess: true,
        loading: false,
        resendAssessmentCampainData: action.survey.resendAssessmentCampainData

      };
    case surveyConstants.RESEND_ASSESSMENT_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case surveyConstants.RESEND_360_CAMPAIGN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.RESEND_360_CAMPAIGN_SUCCESS:
      return {
        ...state,
        resend360CampainSuccess: true,
        loading: false,
        resend360CampainData: action.survey.resend360CampainData

      };
    case surveyConstants.RESEND_360_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.CREATE_SURVEY_FROM_LIB_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.CREATE_SURVEY_FROM_LIB_SUCCESS:
      return {
        ...state,
        createSurveyFromLibSuccess: true,
        loading: false,
        createSurveyFromLibData: action.survey.createSurveyFromLibData

      };
    case surveyConstants.CREATE_SURVEY_FROM_LIB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };







    case surveyConstants.GET_SURVEY_FROM_LIB_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_SURVEY_FROM_LIB_SUCCESS:
      return {
        ...state,
        getSurveyFromLibSuccess: true,
        loading: false,
        getSurveyFromLibData: action.survey.getSurveyFromLibData.list

      };
    case surveyConstants.GET_SURVEY_FROM_LIB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case surveyConstants.GET_EMPLOYEE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        getEmployeeListSuccess: true,
        loading: false,
        getEmployeeListData: action.survey.getEmployeeListData.list,
        getEmployeeListTotal: action.survey.getEmployeeListData.total

      };
    case surveyConstants.GET_EMPLOYEE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case surveyConstants.GET_EMPLOYEE_BY_ID_LIST_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_EMPLOYEE_BY_ID_LIST_LIST_SUCCESS:
      return {
        ...state,
        getEmployeesByEmpIdListSuccess: true,
        getEmployeesByEmpIdListData: action.survey.getEmployeesByEmpIdListData.list,
        getEmployeesByEmpIdListTotal: action.survey.getEmployeesByEmpIdListData.total,
        loading: false

      };
    case surveyConstants.GET_EMPLOYEE_BY_ID_LIST_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };






    case surveyConstants.GET_EMPLOYEE_SEARCH_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_EMPLOYEE_SEARCH_SUCCESS:
      return {
        ...state,
        getEmployeeSearchSuccess: true,
        loading: false,
        getEmployeeSearchData: action.survey.getEmployeeSearchData

      };
    case surveyConstants.GET_EMPLOYEE_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case surveyConstants.SAVE_EMPLOYEE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.SAVE_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        saveEmployeeListSuccess: true,
        saveEmployeeListData: action.survey.saveEmployeeListData,
        loading: false

      };
    case surveyConstants.SAVE_EMPLOYEE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case surveyConstants.SAVE_CUSTOMER_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.SAVE_CUSTOMER_LIST_SUCCESS:
      return {
        ...state,
        saveCustomerListSuccess: true,
        saveCustomerListData: action.survey.saveCustomerListData,
        loading: false

      };
    case surveyConstants.SAVE_CUSTOMER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case surveyConstants.SAVE_360_RELATION_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.SAVE_360_RELATION_LIST_SUCCESS:
      return {
        ...state,
        save360RelationListSuccess: true,
        save360RelationListData: action.survey.save360RelationListData,
        loading: false

      };
    case surveyConstants.SAVE_360_RELATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.SAVE_PMS_RELATION_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.SAVE_PMS_RELATION_LIST_SUCCESS:
      return {
        ...state,
        savePMSRelationListSuccess: true,
        savePMSRelationListData: action.survey.savePMSRelationListData,
        loading: false

      };
    case surveyConstants.SAVE_PMS_RELATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.SAVE_CUST_EMP_RELATION_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.SAVE_CUST_EMP_RELATION_LIST_SUCCESS:
      return {
        ...state,
        saveCustEmpRelationListSuccess: true,
        saveCustEmpRelationListData: action.survey.saveCustEmpRelationListData,
        loading: false

      };
    case surveyConstants.SAVE_CUST_EMP_RELATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.GET_CUST_EMP_RELATION_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_CUST_EMP_RELATION_LIST_SUCCESS:
      return {
        ...state,
        getCustEmpRelationListSuccess: true,
        getCustEmpRelationListData: action.survey.getCustEmpRelationListData.list,
        getCustEmpRelationListTotal: action.survey.getCustEmpRelationListData.total,
        loading: false

      };
    case surveyConstants.GET_CUST_EMP_RELATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case surveyConstants.HANDLE_360_FEEDBACK_DISABLE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.HANDLE_360_FEEDBACK_DISABLE_SUCCESS:
      return {
        ...state,
        handle360FeedbackDisableSuccess: true,
        handle360FeedbackDisableData: action.survey.handle360FeedbackDisableData,
        loading: false

      };
    case surveyConstants.HANDLE_360_FEEDBACK_DISABLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.SET_SURVEY_CAMPAIGN_TYPE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.SET_SURVEY_CAMPAIGN_TYPE_SUCCESS:
      return {
        ...state,
        setSurveyCampaignTypeSuccess: true,
        setSurveyCampaignTypeData: action.survey.setSurveyCampaignTypeData,
        loading: false

      };
    case surveyConstants.SET_SURVEY_CAMPAIGN_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.GET_ALL_LANGUGAGE_LABEL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_ALL_LANGUGAGE_LABEL_SUCCESS:
      return {
        ...state,
        saveEmployeeListSuccess: true,
        loading: false,
        getLanguageLabelData: action.survey.getLanguageLabelData

      };
    case surveyConstants.GET_ALL_LANGUGAGE_LABEL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case surveyConstants.GET_ALL_LANGUGAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_ALL_LANGUGAGE_SUCCESS:
      return {
        ...state,
        saveEmployeeListSuccess: true,
        loading: false,
        getLanguageData: action.survey.getLanguageData

      };
    case surveyConstants.GET_ALL_LANGUGAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case surveyConstants.GET_EMPLOYEE_COLUMN_NAME_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_EMPLOYEE_COLUMN_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        getEmployeeColumnNameSuccess: true,
        getEmployeeColumnNameData: action.survey.getEmployeeColumnNameData

      };
    case surveyConstants.GET_EMPLOYEE_COLUMN_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.GET_EMPLOYEE_FILTER_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_EMPLOYEE_FILTER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getEmployeeFilterListByCompanyIdSuccess: true,
        getEmployeeFilterListByCompanyIdData: action.survey.getEmployeeFilterListByCompanyIdData

      };
    case surveyConstants.GET_EMPLOYEE_FILTER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.GET_EMPLOYEE_BATCH_RANGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_EMPLOYEE_BATCH_RANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        getEmployeeBatchRangeSuccess: true,
        getEmployeeBatchRangeData: action.survey.getEmployeeBatchRangeData

      };
    case surveyConstants.GET_EMPLOYEE_BATCH_RANGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.GET_CUSTOMER_BATCH_RANGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_CUSTOMER_BATCH_RANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        getCustomerBatchRangeSuccess: true,
        getCustomerBatchRangeData: action.survey.getCustomerBatchRangeData

      };
    case surveyConstants.GET_CUSTOMER_BATCH_RANGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.CREATE_EMPLOYEE_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.CREATE_EMPLOYEE_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        createEmployeeFilterSuccess: true,

      };
    case surveyConstants.CREATE_EMPLOYEE_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case surveyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateEmployeeFilterStatusSuccess: true,

      };
    case surveyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.DELETE_EMPLOYEE_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.DELETE_EMPLOYEE_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteEmployeeFilterSuccess: true,

      };
    case surveyConstants.DELETE_EMPLOYEE_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case surveyConstants.USER_ASSIGN_ROLE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.USER_ASSIGN_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        assignRoleSuccess: true,

      };
    case surveyConstants.USER_ASSIGN_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case surveyConstants.UPDATE_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateEmployeeSuccess: true

      };
    case surveyConstants.UPDATE_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.REMOVE_USER_ROLE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.REMOVE_USER_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        removeRoleSuccess: true

      };
    case surveyConstants.REMOVE_USER_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case surveyConstants.UPDATE_SURVEY_DESIGN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.UPDATE_SURVEY_DESIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        updateSurveyDesignSuccess: true

      };
    case surveyConstants.UPDATE_SURVEY_DESIGN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.QUESTION_LIBRARY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.QUESTION_LIBRARY_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionLibraryListSuccess: true,
        getQuestionLibraryListData: action.survey.getQuestionLibraryListData.list

      };
    case surveyConstants.QUESTION_LIBRARY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };







    case surveyConstants.ALL_FILTER_VALUES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.ALL_FILTER_VALUES_SUCCESS:
      return {
        ...state,
        getAllFilterValuesSuccess: true,
        loading: false,
        getAllFilterValuesData: action.survey.getAllFilterValuesData
      };
    case surveyConstants.ALL_FILTER_VALUES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.UPDATE_EMPLOYEE_FILTER_RANK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.UPDATE_EMPLOYEE_FILTER_RANK_SUCCESS:
      return {
        ...state,
        updateEmployeeFilterRankSuccess: true,
        loading: false,
        // getAllFilterValuesData: action.survey.getAllFilterValuesData
      };
    case surveyConstants.UPDATE_EMPLOYEE_FILTER_RANK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.GET_ASSIGNED_ROLES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_ASSIGNED_ROLES_SUCCESS:
      return {
        ...state,
        getAssignedRolesSuccess: true,
        loading: false,
        getAssignedRolesData: action.survey.getAssignedRolesData.list,
        getAssignedRolesTotal: action.survey.getAssignedRolesData.total
      };
    case surveyConstants.GET_ASSIGNED_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.GET_INDEX_BY_FILTER_DMG_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_INDEX_BY_FILTER_DMG_SUCCESS:
      return {
        ...state,
        getIndexByFilterDMGSuccess: true,
        loading: false,
        getIndexByFilterDMGData: action.survey.getIndexByFilterDMGData

      };
    case surveyConstants.GET_INDEX_BY_FILTER_DMG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.GET_SURVEY_LAUNCH_BY_DMG_VALUE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_SURVEY_LAUNCH_BY_DMG_VALUE_SUCCESS:
      return {
        ...state,
        getSurveyLaunchByDMGValueSuccess: true,
        loading: false,
        getSurveyLaunchByDMGValueData: action.survey.getSurveyLaunchByDMGValueData

      };
    case surveyConstants.GET_SURVEY_LAUNCH_BY_DMG_VALUE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case surveyConstants.GET_RESPONSE_ANALYTICS_PAGINATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_RESPONSE_ANALYTICS_PAGINATION_SUCCESS:
      return {
        ...state,
        getResponseAnalyticsPaginationSuccess: true,
        loading: false,
        getResponseAnalyticsPaginationData: action.survey.getResponseAnalyticsPaginationData.list,
        getResponseAnalyticsPaginationTotal: action.survey.getResponseAnalyticsPaginationData.total

      };
    case surveyConstants.GET_RESPONSE_ANALYTICS_PAGINATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.GET_CUSTOMER_V1_RESPONSE_ANALYTICS_PAGINATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_CUSTOMER_V1_RESPONSE_ANALYTICS_PAGINATION_SUCCESS:
      return {
        ...state,
        getResponseAnalyticsPaginationSuccess: true,
        loading: false,
        getResponseAnalyticsPaginationData: action.survey.getResponseAnalyticsPaginationData.list,
        getResponseAnalyticsPaginationTotal: action.survey.getResponseAnalyticsPaginationData.total

      };
    case surveyConstants.GET_CUSTOMER_V1_RESPONSE_ANALYTICS_PAGINATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.GET_RESPONSE_ANALYTICS_KEYWORD_REQUEST:
      return {
        ...state,
        loading: false
      };
    case surveyConstants.GET_RESPONSE_ANALYTICS_KEYWORD_SUCCESS:
      return {
        ...state,
        getResponseAnalyticsPaginationSuccess: true,
        loading: false,
        getResponseAnalyticsPaginationData: action.survey.getResponseAnalyticsPaginationData.list,
        getResponseAnalyticsPaginationTotal: action.survey.getResponseAnalyticsPaginationData.total

      };
    case surveyConstants.GET_RESPONSE_ANALYTICS_KEYWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllResponseAnalyticsBySurveyIdSuccess: true,
        getAllResponseAnalyticsBySurveyIdData: action.survey.getAllResponseAnalyticsBySurveyIdData

      };
    case surveyConstants.GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_REQUEST:
      return {
        ...state,
        loading: true,
        resCustomerAnalyticsLoading: true
      };
    case surveyConstants.GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_SUCCESS:
      return {
        ...state,
        loading: false,
        customerResponsePaginationByCampaignIdSuccess: true,
        customerResponsePaginationByCampaignIdData: action.survey.customerResponsePaginationByCampaignIdData,
        resCustomerAnalyticsLoading: false

      };
    case surveyConstants.GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_FAILURE:
      return {
        ...state,
        loading: false,
        resCustomerAnalyticsLoading: false,
        error: action.error
      };



    case surveyConstants.UPDATE_ENGAGEMENT_COMPARISON_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.UPDATE_ENGAGEMENT_COMPARISON_STATUS_SUCCESS:
      return {
        ...state,
        updateEmployeeEngagementComparisonStatusSuccess: true,
        loading: false,
      };
    case surveyConstants.UPDATE_ENGAGEMENT_COMPARISON_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case surveyConstants.UPDATE_EDA_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.UPDATE_EDA_STATUS_SUCCESS:
      return {
        ...state,
        updateEmployeeEDAStatusSuccess: true,
        loading: false,
      };
    case surveyConstants.UPDATE_EDA_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case surveyConstants.UPDATE_EMP_FILTER_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.UPDATE_EMP_FILTER_STATUS_SUCCESS:
      return {
        ...state,
        updateEmpFilterDMGStatusSuccess: true,
        loading: false,
      };
    case surveyConstants.UPDATE_EMP_FILTER_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.UPDATE_EMPLOYEE_FILTER_LABEL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.UPDATE_EMPLOYEE_FILTER_LABEL_SUCCESS:
      return {
        ...state,
        loading: false,
        updateEmployeeFilterLabelSuccess: true,
        updateEmployeeFilterLabelData: action.survey.updateEmployeeFilterLabelData

      };
    case surveyConstants.UPDATE_EMPLOYEE_FILTER_LABEL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case surveyConstants.GET_PARA_LIB_BY_PARA_TYPE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_PARA_LIB_BY_PARA_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        getParameterLibraryListbyParameterTypeSuccess: true,
        getParameterLibraryListbyParameterTypeData: action.survey.getParameterLibraryListbyParameterTypeData
      };
    case surveyConstants.GET_PARA_LIB_BY_PARA_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.COPY_PARA_LIB_TO_PARAMETER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.COPY_PARA_LIB_TO_PARAMETER_SUCCESS:
      return {
        ...state,
        loading: false,
        copyParaLibToParameterSuccess: true
      };
    case surveyConstants.COPY_PARA_LIB_TO_PARAMETER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case surveyConstants.AUTO_UPDATE_PARAMETERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.AUTO_UPDATE_PARAMETERS_SUCCESS:
      return {
        ...state,
        loading: false,
        autoUpdateParametersSuccess: true
      };
    case surveyConstants.AUTO_UPDATE_PARAMETERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.GET_EMPLOYEE_LIMITED_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_EMPLOYEE_LIMITED_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getEmployeeLimitedListSuccess: true,
        getEmployeeLimitedListData: action.survey.getEmployeeLimitedListData.list,
        getEmployeeLimitedListTotal: action.survey.getEmployeeLimitedListData.total
      };
    case surveyConstants.GET_EMPLOYEE_LIMITED_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.CREATE_EMPLOYEE_RECORD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.CREATE_EMPLOYEE_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        createEmployeeRecordSuccess: true
      };
    case surveyConstants.CREATE_EMPLOYEE_RECORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.GET_LIFECYCLESTAGE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_LIFECYCLESTAGE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getLifecycleStageListSuccess: true,
        getLifecycleStageListData: action.survey.getLifecycleStageListData.list,
        getLifecycleStageListTotal: action.survey.getLifecycleStageListData.total
      };
    case surveyConstants.GET_LIFECYCLESTAGE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.ALL_TOOLTIP_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.ALL_TOOLTIP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        allTooltipListSuccess: true,
        allTooltipListData: action.survey.allTooltipListData
      };
    case surveyConstants.ALL_TOOLTIP_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case surveyConstants.GET_DEFAULT_RISK_SURVEY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_DEFAULT_RISK_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        getDefaultRiskSurveySuccess: true,
        getDefaultRiskSurveyData: action.survey.getDefaultRiskSurveyData
      };
    case surveyConstants.GET_DEFAULT_RISK_SURVEY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case surveyConstants.UPDATE_SURVEY_CLUSTER_SURVEY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.UPDATE_SURVEY_CLUSTER_SURVEY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        updateSurveyClusterSurveyIdSuccess: true,
        updateSurveyClusterSurveyIdData: action.survey.updateSurveyClusterSurveyIdData
      };
    case surveyConstants.UPDATE_SURVEY_CLUSTER_SURVEY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };






    case surveyConstants.GET_EMP_FIELDS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_EMP_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        getEmployeeFieldsSuccess: true,
        getEmployeeFieldsData: action.survey.getEmployeeFieldsData
      };
    case surveyConstants.GET_EMP_FIELDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.GET_CMP_FIELDS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_CMP_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        getCustomerFieldsSuccess: true,
        getCustomerFieldsData: action.survey.getCustomerFieldsData
      };
    case surveyConstants.GET_CMP_FIELDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.LOAD_DEF_REC_DAYS_SURVEYS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.LOAD_DEF_REC_DAYS_SURVEYS_SUCCESS:
      return {
        ...state,
        loading: false,
        loadDefaultRecDaysSurveySuccess: true,
        loadDefaultRecDaysSurveyData: action.survey.loadDefaultRecDaysSurveyData
      };
    case surveyConstants.LOAD_DEF_REC_DAYS_SURVEYS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.LOAD_DEF_ONBOARDING_SURVEYS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.LOAD_DEF_ONBOARDING_SURVEYS_SUCCESS:
      return {
        ...state,
        loading: false,
        loadDefaultOnboardingSurveySuccess: true,
        loadDefaultOnboardingSurveyData: action.survey.loadDefaultOnboardingSurveyData
      };
    case surveyConstants.LOAD_DEF_ONBOARDING_SURVEYS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.AUTO_CHANGE_DASHBOARD_TYPE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.AUTO_CHANGE_DASHBOARD_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        autoChangeDashboardTypeSuccess: true,
      };
    case surveyConstants.AUTO_CHANGE_DASHBOARD_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };







    case surveyConstants.UPDATE_ACTIVE_STATUS_REC_CAMP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.UPDATE_ACTIVE_STATUS_REC_CAMP_SUCCESS:
      return {
        ...state,
        loading: false,
        updateActiveStatusRecCampaignSuccess: true,
        updateActiveStatusRecCampaignData: action.survey.updateActiveStatusRecCampaignData
      };
    case surveyConstants.UPDATE_ACTIVE_STATUS_REC_CAMP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case surveyConstants.GENERATE_COMPANY_BOT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GENERATE_COMPANY_BOT_SUCCESS:
      return {
        ...state,
        loading: false,
        generateCompanyBotLinkSuccess: true,
        generateCompanyBotLinkData: action.survey.generateCompanyBotLinkData
      };
    case surveyConstants.GENERATE_COMPANY_BOT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.ALL_LIFECYCLE_LISTEN_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.ALL_LIFECYCLE_LISTEN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        allLifecycleListenListSuccess: true,
        allLifecycleListenListData: action.survey.allLifecycleListenListData
      };
    case surveyConstants.ALL_LIFECYCLE_LISTEN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case surveyConstants.GET_SURVEY_ONE_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_SURVEY_ONE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        getSurveyOneTemplateSuccess: true,
        getSurveyOneTemplateData: action.survey.getSurveyOneTemplateData
      };
    case surveyConstants.GET_SURVEY_ONE_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case surveyConstants.GET_SURVEY_LIST_BY_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_SURVEY_LIST_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        getSurveyListByCategorySuccess: true,
        getSurveyListByCategoryData: action.survey.getSurveyListByCategoryData
      };
    case surveyConstants.GET_SURVEY_LIST_BY_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.CHANGE_LIFECYCLE_DASH_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.CHANGE_LIFECYCLE_DASH_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        changeLifecycleDashStatusSuccess: true,
        changeLifecycleDashStatusData: action.survey.changeLifecycleDashStatusData
      };
    case surveyConstants.CHANGE_LIFECYCLE_DASH_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case surveyConstants.GET_360_RELATION_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_360_RELATION_LIST_SUCCESS:
      return {
        ...state,
        get360RelationListSuccess: true,
        get360RelationListData: action.survey.get360RelationListData.list,
        get360RelationListTotal: action.survey.get360RelationListData.total,
        loading: false

      };
    case surveyConstants.GET_360_RELATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case surveyConstants.GET_PMS_RELATION_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_PMS_RELATION_LIST_SUCCESS:
      return {
        ...state,
        getPMSRelationListSuccess: true,
        getPMSRelationListData: action.survey.getPMSRelationListData.list,
        getPMSRelationListTotal: action.survey.getPMSRelationListData.total,
        loading: false

      };
    case surveyConstants.GET_PMS_RELATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };






    case surveyConstants.COPY_360_PARAMETERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.COPY_360_PARAMETERS_SUCCESS:
      return {
        ...state,
        copy360ParametersSuccess: true,
        loading: false

      };
    case surveyConstants.COPY_360_PARAMETERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.COPY_PACKAGE_PARAMETERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.COPY_PACKAGE_PARAMETERS_SUCCESS:
      return {
        ...state,
        copyPackageParametersSuccess: true,
        loading: false

      };
    case surveyConstants.COPY_PACKAGE_PARAMETERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.GET_SURVEY_LIST_V3_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_SURVEY_LIST_V3_SUCCESS:
      return {
        ...state,
        getSurveyListV3Success: true,
        getSurveyListV3Data: action.survey.getSurveyListV3Data.list,
        getSurveyListV3Total: action.survey.getSurveyListV3Data.total,
        loading: false

      };
    case surveyConstants.GET_SURVEY_LIST_V3_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.GET_SURVEY_LIST_V4_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_SURVEY_LIST_V4_SUCCESS:
      return {
        ...state,
        getSurveyListV4Success: true,
        getSurveyListV4Data: action.survey.getSurveyListV4Data.list,
        getSurveyListV4Total: action.survey.getSurveyListV4Data.total,
        loading: false

      };
    case surveyConstants.GET_SURVEY_LIST_V4_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case surveyConstants.GET_PARAMETER_LABEL_NAME_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_PARAMETER_LABEL_NAME_SUCCESS:
      return {
        ...state,
        getParameterLabelByNameDataSuccess: true,
        getParameterLabelByNameData: action.survey.getParameterLabelByNameData.childList,
        loading: false

      };
    case surveyConstants.GET_PARAMETER_LABEL_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case surveyConstants.SAVE_EMP_DATE_FIELD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.SAVE_EMP_DATE_FIELD_SUCCESS:
      return {
        ...state,
        saveEmpDateFieldSuccess: true,
        saveEmpDateFieldDate: action.survey.saveEmpDateFieldDate,
        loading: false

      };
    case surveyConstants.SAVE_EMP_DATE_FIELD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case surveyConstants.GET_CROLE_SURVEY_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_CROLE_SURVEY_LIST_SUCCESS:
      return {
        ...state,
        getSurveyListByRoleSuccess: true,
        getSurveyListByRoleData: action.survey.getSurveyListByRoleData,
        loading: false

      };
    case surveyConstants.GET_CROLE_SURVEY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.GET_RESPONSE_360_ANALYTICS_PAGINATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_RESPONSE_360_ANALYTICS_PAGINATION_SUCCESS:
      return {
        ...state,
        getResponseAnalyticsPaginationSuccess: true,
        loading: false,
        getResponse360AnalyticsPaginationData: action.survey.getResponseAnalyticsPaginationData.list,
        getResponseAnalyticsPaginationTotal: action.survey.getResponseAnalyticsPaginationData.total

      };
    case surveyConstants.GET_RESPONSE_360_ANALYTICS_PAGINATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case surveyConstants.GET_ALL_EMPLOYEE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_ALL_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllEmployeeListSuccess: true,
        getAllEmployeeListData: action.survey.getAllEmployeeListData,

      };
    case surveyConstants.GET_ALL_EMPLOYEE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

      case surveyConstants.REPONSE_ICEDOWNLOAD_BY_SURVEY_REQUEST:
        return {
          ...state,
          iceloading: true
        };
      case surveyConstants.REPONSE_ICEDOWNLOAD_BY_SURVEY_SUCCESS:
        return {
          ...state,
          iceloading: false,
          responseIceDownloadBySurveyIdSuccess: true,
          responseIceDownloadBySurveyIdData: action.survey.responseIceDownloadBySurveyIdData,
  
        };
      case surveyConstants.REPONSE_ICEDOWNLOAD_BY_SURVEY_FAILURE:
        return {
          ...state,
          iceloading: false,
          error: action.error
        };

    case surveyConstants.GET_TRANSLATE_ELEMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_TRANSLATE_ELEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        getTranslateElementAllSuccess: true,
        getTranslateElementAllData: action.survey.getTranslateElementAllData,

      };
    case surveyConstants.GET_TRANSLATE_ELEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case surveyConstants.GET_ASSESSMENT_BY_TOP_BOTTOM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_ASSESSMENT_BY_TOP_BOTTOM_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentByTopBottomSuccess: true,
        getAssessmentByTopBottomData: action.survey.getAssessmentByTopBottomData,

      };
    case surveyConstants.GET_ASSESSMENT_BY_TOP_BOTTOM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case surveyConstants.GET_RESPONSE_ASSESSMENT_SHORT_CODE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_RESPONSE_ASSESSMENT_SHORT_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        getResponseAssessmentShortCodeSuccess: true,
        getResponseAssessmentShortCodeData: action.survey.getResponseAssessmentShortCodeData,

      };
    case surveyConstants.GET_RESPONSE_ASSESSMENT_SHORT_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

      
      case surveyConstants.GET_QUES_NAME_ID_ONLY_REQUEST:
        return {
          ...state,
          loading: true
        };
      case surveyConstants.GET_QUES_NAME_ID_ONLY_SUCCESS:
        return {
          ...state,
          loading: false,
          getQuestionNameIDOnlySuccess: true,
          getQuestionNameIDOnlyData: action.survey.getQuestionNameIDOnlyData,
  
        };
      case surveyConstants.GET_QUES_NAME_ID_ONLY_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };


    case surveyConstants.SURVEY_COPY_SURVEY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.SURVEY_COPY_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        copySurveySuccess: true,
        copySurveyData: action.survey.copySurveyData,

      };
    case surveyConstants.SURVEY_COPY_SURVEY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };







    case surveyConstants.GET_SURVEY_DMG_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.GET_SURVEY_DMG_FILTER_SUCCESS:
      return {
        ...state,
        getSurveyDMGLaunchFilterSuccess: true,
        getSurveyDMGLaunchFilterData: action.survey.getSurveyDMGLaunchFilterData,
        loading: false

      };
    case surveyConstants.GET_SURVEY_DMG_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    default:
      return state
  }
}