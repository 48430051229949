export const growConstants = {

    START_GROW_GETALL_REQUEST: 'START_GROW_GETALL_REQUEST',
    START_GROW_GETALL_SUCCESS: 'START_GROW_GETALL_SUCCESS',
    START_GROW_GETALL_FAILURE: 'START_GROW_GETALL_FAILURE',

    GET_GROWDAY_REQUEST: 'GET_GROWDAY_REQUEST',
    GET_GROWDAY_SUCCESS: 'GET_GROWDAY_SUCCESS',
    GET_GROWDAY_FAILURE: 'GET_GROWDAY_FAILURE',


    GET_GROW_PLUS_BUCKET_REQUEST: 'GET_GROW_PLUS_BUCKET_REQUEST',
    GET_GROW_PLUS_BUCKET_SUCCESS: 'GET_GROW_PLUS_BUCKET_SUCCESS',
    GET_GROW_PLUS_BUCKET_FAILURE: 'GET_GROW_PLUS_BUCKET_FAILURE',

    GET_GROW_PLUS_MONTHS_REQUEST: 'GET_GROW_PLUS_MONTHS_REQUEST',
    GET_GROW_PLUS_MONTHS_SUCCESS: 'GET_GROW_PLUS_MONTHS_SUCCESS',
    GET_GROW_PLUS_MONTHS_FAILURE: 'GET_GROW_PLUS_MONTHS_FAILURE',

    GET_GROW_PLUS_DAY_USER_REQUEST: 'GET_GROW_PLUS_DAY_USER_REQUEST',
    GET_GROW_PLUS_DAY_USER_SUCCESS: 'GET_GROW_PLUS_DAY_USER_SUCCESS',
    GET_GROW_PLUS_DAY_USER_FAILURE: 'GET_GROW_PLUS_DAY_USER_FAILURE',

    GET_GROW_PLUS_DAY_TASK_USER_REQUEST: 'GET_GROW_PLUS_DAY_TASK_USER_REQUEST',
    GET_GROW_PLUS_DAY_TASK_USER_SUCCESS: 'GET_GROW_PLUS_DAY_TASK_USER_SUCCESS',
    GET_GROW_PLUS_DAY_TASK_USER_FAILURE: 'GET_GROW_PLUS_DAY_TASK_USER_FAILURE',
    
    GET_GROW_PLUS_DAY_TASK_STATUS_USER_REQUEST: 'GET_GROW_PLUS_DAY_TASK_STATUS_USER_REQUEST',
    GET_GROW_PLUS_DAY_TASK_STATUS_USER_SUCCESS: 'GET_GROW_PLUS_DAY_TASK_STATUS_USER_SUCCESS',
    GET_GROW_PLUS_DAY_TASK_STATUS_USER_FAILURE: 'GET_GROW_PLUS_DAY_TASK_STATUS_USER_FAILURE',

    GET_GROW_PLUS_DAY_TASK_COMPLETE_USER_REQUEST: 'GET_GROW_PLUS_DAY_TASK_COMPLETE_USER_REQUEST',
    GET_GROW_PLUS_DAY_TASK_COMPLETE_USER_SUCCESS: 'GET_GROW_PLUS_DAY_TASK_COMPLETE_USER_SUCCESS',
    GET_GROW_PLUS_DAY_TASK_COMPLETE_USER_FAILURE: 'GET_GROW_PLUS_DAY_TASK_COMPLETE_USER_FAILURE',

    SAVE_GROW_PLUS_DAY_TASK_USER_REQUEST: 'SAVE_GROW_PLUS_DAY_TASK_USER_REQUEST',
    SAVE_GROW_PLUS_DAY_TASK_USER_SUCCESS: 'SAVE_GROW_PLUS_DAY_TASK_USER_SUCCESS',
    SAVE_GROW_PLUS_DAY_TASK_USER_FAILURE: 'SAVE_GROW_PLUS_DAY_TASK_USER_FAILURE',


    GET_GROWDAYWEEK_REQUEST: 'GET_GROWDAYWEEK_REQUEST',
    GET_GROWDAYWEEK_SUCCESS: 'GET_GROWDAYWEEK_SUCCESS',
    GET_GROWDAYWEEK_FAILURE: 'GET_GROWDAYWEEK_FAILURE',

    GET_GROWDAY_DAY_REQUEST: 'GET_GROWDAY_DAY_REQUEST',
    GET_GROWDAY_DAY_SUCCESS: 'GET_GROWDAY_DAY_SUCCESS',
    GET_GROWDAY_DAY_FAILURE: 'GET_GROWDAY_DAY_FAILURE',

    GET_REFLECTION_DAY_REQUEST: 'GET_REFLECTION_DAY_REQUEST',
    GET_REFLECTION_DAY_SUCCESS: 'GET_REFLECTION_DAY_SUCCESS',
    GET_REFLECTION_DAY_FAILURE: 'GET_REFLECTION_DAY_FAILURE',

    GET_GROW_DAY_REQUEST: 'GET_GROW_DAY_REQUEST',
    GET_GROW_DAY_SUCCESS: 'GET_GROW_DAY_SUCCESS',
    GET_GROW_DAY_FAILURE: 'GET_GROW_DAY_FAILURE',


    GET_GROWWEEK_REQUEST: 'GET_GROWWEEK_REQUEST',
    GET_GROWWEEK_SUCCESS: 'GET_GROWWEEK_SUCCESS',
    GET_GROWWEEK_FAILURE: 'GET_GROWWEEK_FAILURE',

    GET_WEEK_MONTH_REQUEST: 'GET_WEEK_MONTH_REQUEST',
    GET_WEEK_MONTH_SUCCESS: 'GET_WEEK_MONTH_SUCCESS',
    GET_WEEK_MONTH_FAILURE: 'GET_WEEK_MONTH_FAILURE',

    START_MONTH_REQUEST: 'START_MONTH_REQUEST',
    START_MONTH_SUCCESS: 'START_MONTH_SUCCESS',
    START_MONTH_FAILURE: 'START_MONTH_FAILURE',

    GET_MONTH_REQUEST: 'GET_MONTH_REQUEST',
    GET_MONTH_SUCCESS: 'GET_MONTH_SUCCESS',
    GET_MONTH_FAILURE: 'GET_MONTH_FAILURE',

    DASHBOARD_GROWNOTE_REQUEST: 'DASHBOARD_GROWNOTE_REQUEST',
    DASHBOARD_GROWNOTE_SUCCESS: 'DASHBOARD_GROWNOTE_SUCCESS',
    DASHBOARD_GROWNOTE_FAILURE: 'DASHBOARD_GROWNOTE_FAILURE',

    DASHBOARD_SUBMITGROW_REQUEST: 'DASHBOARD_SUBMITGROW_REQUEST',
    DASHBOARD_SUBMITGROW_SUCCESS: 'DASHBOARD_SUBMITGROW_SUCCESS',
    DASHBOARD_SUBMITGROW_FAILURE: 'DASHBOARD_SUBMITGROW_FAILURE',

    GET_KNOWLEDGE_REQUEST: 'GET_KNOWLEDGE_REQUEST',
    GET_KNOWLEDGE_SUCCESS: 'GET_KNOWLEDGE_SUCCESS',
    GET_KNOWLEDGE_FAILURE: 'GET_KNOWLEDGE_FAILURE',

    UPDATE_EMPLOYEE_TRACKER_REQUEST: 'UPDATE_EMPLOYEE_TRACKER_REQUEST',
    UPDATE_EMPLOYEE_TRACKER_SUCCESS: 'UPDATE_EMPLOYEE_TRACKER_SUCCESS',
    UPDATE_EMPLOYEE_TRACKER_FAILURE: 'UPDATE_EMPLOYEE_TRACKER_FAILURE',

    SUB_ACT_REQUEST: 'SUB_ACT_REQUEST',
    SUB_ACT_SUCCESS: 'SUB_ACT_SUCCESS',
    SUB_ACT_FAILURE: 'SUB_ACT_FAILURE',

    UPDATE_COMMENT_REQUEST: 'UPDATE_COMMENT_REQUEST',
    UPDATE_COMMENT_SUCCESS: 'UPDATE_COMMENT_SUCCESS',
    UPDATE_COMMENT_FAILURE: 'UPDATE_COMMENT_FAILURE',

    GROWPARAMETERLIST_BYTYPE_GETALL_REQUEST: 'GROWPARAMETERLIST_BYTYPE_GETALL_REQUEST',
    GROWPARAMETERLIST_GETALL_SUCCESS: 'GROWPARAMETERLIST_GETALL_SUCCESS',
    GROWPARAMETERLIST_GETALL_FAILURE: 'GROWPARAMETERLIST_GETALL_FAILURE',

    FORCE_GROW_PLUS_DAY_TASK_COMPLETE_USER_REQUEST: 'FORCE_GROW_PLUS_DAY_TASK_COMPLETE_USER_REQUEST',
    FORCE_GROW_PLUS_DAY_TASK_COMPLETE_USER_SUCCESS: 'FORCE_GROW_PLUS_DAY_TASK_COMPLETE_USER_SUCCESS',
    FORCE_GROW_PLUS_DAY_TASK_COMPLETE_USER_FAILURE: 'FORCE_GROW_PLUS_DAY_TASK_COMPLETE_USER_FAILURE',
};
