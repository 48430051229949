import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { dashboardActions } from "../../../_actions";
import { FaRegCircle } from "react-icons/fa6";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";


function Sidebar(props) {

  let { getGrowPlusMonth_UserData, getGrowPlusDay_UserData, getDaysAPI, selectDayAPI,
    crrBucketId, crrMonthId, crrDayId, getGrowPlusBucket_UserData, getGrowPlusTaskStatus_UserData, handleCheckSubmitForce
  } = props;

  const history = useHistory();
  const [expanded, setExpanded] = useState(0);

  const checkStatusPerc = (list, bucketId, field) => {
    let perc = 0;
    if (list && list.length > 0) {
      let getIndex = list.findIndex(prev => prev.bucketId.toString() === bucketId.toString());
      if (getIndex !== -1) {
        perc = list && list[getIndex] && list[getIndex][field] ? list[getIndex][field] : 0
      }
    }
    return perc > 0 ? parseFloat(perc) : 0
  }

  const checkMonthStatus = (list, bucketId, monthId, field) => {
    if (list && list.length > 0) {
      let getIndex = list.findIndex(prev => prev.bucketId.toString() === bucketId.toString());
      if (getIndex !== -1) {
        let monthList = list && list[getIndex] && list[getIndex][field] && list[getIndex][field].length > 0 ? list[getIndex][field] : [];
        if (monthList && monthList.length > 0) {
          let getIndeY = monthList.findIndex(prev => prev.monthId.toString() === monthId.toString());
          if (getIndeY !== -1) {
            return monthList[getIndeY]["completedDays"].toString() + "/" + monthList[getIndeY]["totalDays"].toString()
          }
        }
      }
    }
    return "0/0"
  }

  const checkDayComplete = (list, bucketId, monthId, dayId) => {
    if (list && list.length > 0) {
      let getIndex = list.findIndex(prev => prev.bucketId.toString() === bucketId.toString());
      if (getIndex !== -1) {

        let monthlist = list && list[getIndex] && list[getIndex]["monthlist"] && list[getIndex]["monthlist"].length > 0 ? list[getIndex]["monthlist"] : [];
        console.log("monthlist---xxxx->", monthlist)

        if (monthlist && monthlist.length > 0) {
          let getIndeY = monthlist.findIndex(prev => prev.monthId.toString() === monthId.toString());
          if (getIndeY !== -1) {

            let daylist = monthlist && monthlist[getIndeY] && monthlist[getIndeY]["daylist"] && monthlist[getIndeY]["daylist"].length > 0 ? monthlist[getIndeY]["daylist"] : [];
            console.log("daylist---xxxx->", daylist)

            if (daylist && daylist.length > 0) {
              let getIndeZ = daylist.findIndex(prev => prev.dayId.toString() === dayId.toString());
              if (getIndeZ !== -1) {
                return daylist[getIndeZ]["isComplete"]
              }
            }

          }
        }
      }
    }
    return false
  }

  const checkMonthComplete = (list, bucketId, monthId, field) => {
    if (list && list.length > 0) {
      let getIndex = list.findIndex(prev => prev.bucketId.toString() === bucketId.toString());
      if (getIndex !== -1) {
        let monthList = list && list[getIndex] && list[getIndex][field] && list[getIndex][field].length > 0 ? list[getIndex][field] : [];
        if (monthList && monthList.length > 0) {
          let getIndeY = monthList.findIndex(prev => prev.monthId.toString() === monthId.toString());
          if (getIndeY !== -1) {
            return monthList[getIndeY]["isComplete"]
          }
        }
      }
    }
    return false
  }

  // Function to toggle expansion
  const handleToggleExpand = (index, id) => {
    console.log('ididididididididididididid', id);
    setExpanded(expanded === index ? null : index);
    getDaysAPI(id)

  };

  const handleBackToHome = () => {
    history.push("/app/growactionplan");
  };

  const getLabels = (list, bucketId, field) => {
    let value = ""
    if (list && list.length > 0) {
      let getIndex = list.findIndex(prev => prev.id.toString() === bucketId.toString());
      if (getIndex !== -1) {
        value = list && list[getIndex] && list[getIndex][field] && list[getIndex][field] ? list[getIndex][field] : ""
      }
    }
    return value
  }
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    setIsVisible(!isVisible); // Toggle between true and false
  };


  return (

    <div className={isVisible ? 'xl:relative absolute transition top-0 -left-96 duration-300 transform  z-10' : "xl:relative absolute transition top-0 -left-0 duration-300 transform  z-10"}>
      {isVisible ?
        <button
          onClick={toggleVisibility}
          className="bg-[#009688] text-white rounded-md p-2 absolute -right-14 top-2 lg:hidden block ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="icon icon-tabler icons-tabler-outline icon-tabler-menu-2"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 6l16 0" />
            <path d="M4 12l16 0" />
            <path d="M4 18l16 0" />
          </svg>
        </button>
        :
        <button
          onClick={toggleVisibility}
          className="bg-[#009688] text-white rounded-r-md p-2 absolute -right-10 top-2 lg:hidden block ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="icon icon-tabler icons-tabler-outline icon-tabler-x"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M18 6l-12 12" />
            <path d="M6 6l12 12" />
          </svg>
        </button>
      }



      <div className=" bg-white w-96  overflow-y-auto shadow side-menu flex-shrink-0 p-6 space-y-4 lg:h-[calc(100vh-3.7rem)] h-screen ">
        <div className="bg-[#E6F5F3] rounded-lg p-4 space-y-4">
          <div className="flex items-center space-x-4">
            <button onClick={handleBackToHome} className="bg-[#CCEAE7] text-[#009688] p-2 rounded-md cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-left"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M15 6l-6 6l6 6" /></svg>
            </button>
            <span className="font-medium">Back to Home</span>
          </div>

          <div className="space-y-2 ">
            <h2 className="text-xl font-semibold">{getLabels(getGrowPlusBucket_UserData, crrBucketId, "label")}</h2>
            <div className="flex items-center justify-start font-medium space-x-2 text-[#212121]/70">
              <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 25 25" fill="none">  <path d="M6.5 2.5H18.5V8.5L14.5 12.5L18.5 16.5V22.5H6.5V16.5L10.5 12.5L6.5 8.5V2.5ZM16.5 17L12.5 13L8.5 17V20.5H16.5V17ZM12.5 12L16.5 8V4.5H8.5V8L12.5 12ZM10.5 6.5H14.5V7.25L12.5 9.25L10.5 7.25V6.5Z" fill="#212121" fillOpacity="0.54" /></svg>
              <span>{checkStatusPerc(getGrowPlusTaskStatus_UserData, crrBucketId, "totalMonths")} Months</span>
            </div>
            <div className="w-full h-2 rounded-full bg-white">
              <div className="h-2 rounded-full bg-[#009688] w-[40%]" style={{ width: `${checkStatusPerc(getGrowPlusTaskStatus_UserData, crrBucketId, "percent")}%` }} />
            </div>
          </div>
        </div>

        {
          getGrowPlusMonth_UserData && getGrowPlusMonth_UserData.length > 0 ?
            getGrowPlusMonth_UserData.map((item, index) => (

              <div className="border rounded-lg overflow-hidden">

                <div onClick={() => handleToggleExpand(index, item.id)} className="bg-[#009688] flex justify-between items-center text-white py-4 px-4 cursor-pointer">
                  <div className="flex items-center space-x-4">
                    <div className="w-6 h-6 rounded-full text-[#009688] flex items-center justify-center">
                      {
                        true || checkMonthComplete(getGrowPlusTaskStatus_UserData, crrBucketId, item.id, "monthlist") ?
                          <IoMdCheckmarkCircle className="w-7 h-7 text-white" />
                          :
                          <FaRegCircle className="w-5 h-5 text-white" />
                      }
                    </div>
                    <p className="font-medium text-base">{item.label}</p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <p>{checkMonthStatus(getGrowPlusTaskStatus_UserData, crrBucketId, item.id, "monthlist")}</p>
                    {/* <p>{getGrowPlusDay_UserData.length}</p> */}
                    {expanded === index ?
                      <MdKeyboardArrowUp className="w-6 h-6 text-white" />
                      :
                      <MdKeyboardArrowDown className="w-6 h-6 text-white" />
                    }
                  </div>
                </div>

                {expanded === index && (
                  <ol className="relative text-[#212121]/70 border-s-[3px] border-[#CFD8DC] ">

                    {
                      getGrowPlusDay_UserData && getGrowPlusDay_UserData.length > 0 ?
                        getGrowPlusDay_UserData.map((day, index) => (
                          <>
                            <div className={"flex justify-between py-3 px-4 h-20 items-center " + (day.id === crrDayId ? "bg-[#009688]/10 text-[#212121]" : "text-[#212121]/70")}>
                              <div className="flex items-center space-x-4">
                                <div className="w-5 flex justify-center flex-shrink-0">
                                  <div className="h-20 w-0.5 bg-[#CFD8DC] flex flex-col items-center justify-center">
                                    {checkDayComplete(getGrowPlusTaskStatus_UserData, crrBucketId, item.id, day.id) ?
                                      <div className="flex items-center justify-center w-5 h-5 rounded-full  bg-[#009688] text-white ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-check">  <path stroke="none" d="M0 0h24v24H0z" fill="none" />  <path d="M5 12l5 5l10 -10" /></svg>
                                      </div>
                                      :
                                      <div className="flex items-center justify-center w-5 h-5 rounded-full border-2 border-[#CFD8DC] bg-white ">
                                      </div>
                                    }
                                  </div>
                                </div>
                                <div onClick={() => selectDayAPI(day.id)} className="space-y-1 cursor-pointer">
                                  {checkDayComplete(getGrowPlusTaskStatus_UserData, crrBucketId, item.id, day.id) ?
                                    <div>
                                      <h3 className="font-semibold text-[#212121]">{day.label}</h3>
                                      <div className="flex items-center justify-start font-medium space-x-2 text-[#212121]">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 25 25" fill="none">  <path d="M6.5 2.5H18.5V8.5L14.5 12.5L18.5 16.5V22.5H6.5V16.5L10.5 12.5L6.5 8.5V2.5ZM16.5 17L12.5 13L8.5 17V20.5H16.5V17ZM12.5 12L16.5 8V4.5H8.5V8L12.5 12ZM10.5 6.5H14.5V7.25L12.5 9.25L10.5 7.25V6.5Z" fill="#212121" fillOpacity="" /></svg>
                                        <span>30 Min</span>
                                      </div>
                                    </div>
                                    :
                                    <div>
                                      <h3 className="font-semibold text-[#212121]/70">{day.label}</h3>
                                      <div className="flex items-center justify-start font-medium space-x-2 text-[#212121]/70">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 25 25" fill="none">  <path d="M6.5 2.5H18.5V8.5L14.5 12.5L18.5 16.5V22.5H6.5V16.5L10.5 12.5L6.5 8.5V2.5ZM16.5 17L12.5 13L8.5 17V20.5H16.5V17ZM12.5 12L16.5 8V4.5H8.5V8L12.5 12ZM10.5 6.5H14.5V7.25L12.5 9.25L10.5 7.25V6.5Z" fill="#212121" fillOpacity="0.54" /></svg>
                                        <span>30 Min</span>
                                      </div>
                                    </div>
                                  }
                                </div>
                              </div>

                              <div onClick={() => handleCheckSubmitForce(day.id)}>
                                {checkDayComplete(getGrowPlusTaskStatus_UserData, crrBucketId, item.id, day.id) ?
                                  <MdCheckBox className="w-5 h-5 text-[#009688] cursor-pointer" />
                                  :
                                  <MdCheckBoxOutlineBlank className="w-5 h-5 " />
                                }
                              </div>

                            </div>

                          </>
                        )) : null
                    }
                  </ol>
                )}
              </div>

            )) : null
        }
      </div>
    </div>
  );

}
export default Sidebar;