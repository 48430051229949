import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class QuestionScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CrrMainKey: ""
    }
  }
  componentDidMount() {

  }


  handleWords = (params) => {
    let { keyWordsFn, keyWordsMultiFn } = this.props;
    let { CrrMainKey } = this.state;

    let keyword = params && params.name ? params.name : "";

    let MultiType = false
    if (params && params.data && params.data.value) {
      MultiType = true;
    }


    ////console.log('CrrMainKey:---> ',CrrMainKey)
    ////console.log('keyword:---> ',keyword)
    ////console.log('params:---> ',params)


    if (MultiType) {
      if ((CrrMainKey !== "") && (CrrMainKey !== keyword)) {
        ////console.log('<----multi:---> ')
        keyWordsMultiFn(CrrMainKey, keyword);

      }
    }
    else {
      this.setState({ CrrMainKey: keyword })
      keyWordsFn(keyword);
    }



  }


  render() {
    let { keyList, DataObj } = this.props;

    let { CrrMainKey } = this.state;






    // 0:
    // children: (3) [{…}, {…}, {…}]
    // collapsed: true
    // name: "analytics"
    // [[Prototype]]: Object
    // 1: {name: 'animate', children: Array(12)}
    // 2: {name: 'data', children: Array(7), collapsed: true}
    // 3: {name: 'display', children: Array(4)}
    // 4: {name: 'flex', children: Array(1), collapsed: true}
    // 5: {name: 'physics', children: Array(8)}
    // 6: {name: 'query', children: Array(29), collapsed: true}
    // 7: {name: 'scale', children: Array(10)}
    // 8: {name: 'util', children: Array(19), collapsed: true}
    // 9: {name: 'vis', children: Array(7)}
    // length: 10
    // [[Prototype]]: Array(0)
    // name: "flare"

    // console.log('keyList:---> ', keyList)
    // console.log('DataObj:---> ', DataObj)


    let data = {
      "name": "Keywords",
      "children": []
    }


    if (keyList && keyList.length > 0) {
      keyList.forEach((key) => {
        let temp1 = {
          children: [],
          collapsed: CrrMainKey === key ? false : true,
          name: key
        }

        if (DataObj && DataObj[key] && DataObj[key].length > 0) {
          DataObj[key].forEach((item) => {
            temp1["children"].push({
              "name": item[0],
              "value": item[1]
            })
          });
        }

        data["children"].push(temp1)

      })
    }





    return (
      <>
        <div>

          {true ?
            <ReactEcharts
            className='!h-[1400px]'
              style={{ width: '100%' }}
              // ref={(e) => { this.echarts_react = e; }}
              onEvents={{
                'click': (params) => this.handleWords(params)
              }}
              option={{
                tooltip: {
                  trigger: 'item',
                  triggerOn: 'mousemove'
                },
                grid: {
                  left: '10%',
                  right: '10%',
                  bottom: '5%',
                  containLabel: true
                },
                series: [
                  {
                    type: 'tree',
                    data: [data],
                    top: '1%',
                    left: '5%',
                    bottom: '1%',
                    right: '20%',
                    symbolSize: 7,
                    label: {
                      position: 'left',
                      verticalAlign: 'middle',
                      align: 'left',
                      fontSize: 14
                    },
                    leaves: {
                      label: {
                        position: 'right',
                        verticalAlign: 'middle',
                        align: 'right'
                      }
                    },
                    emphasis: {
                      focus: 'descendant'
                    },
                    expandAndCollapse: true,
                    animationDuration: 550,
                    animationDurationUpdate: 750
                  }
                ]
              }}
            />
            : <div className="p-2 px-4 font-semibold text-left text-purple-500">NaN</div>}

        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  //   //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionScore);
