import React, { Component } from "react";
import { connect } from "react-redux";
import ReactEcharts from "echarts-for-react";

class StackChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    let { score, color } = this.props;

    let responseRate = score ? score : 0;

    let ScoreLabel = parseFloat(responseRate).toFixed(2) + "%";

    return (
      <>
        {/* <div className="stack-title">
            {currFilterName} 
          </div> */}

     
        <div
          className="rounded-full w-28 h-28 bg-green-500 flex justify-center items-center "
          style={{ background: color }}
        >
          <div className="rounded-full w-24 h-24  bg-white  flex justify-center items-center">
            <div
              className="font-medium text-center text-sm "
              style={{ color: color }}
            >
              {ScoreLabel}
            </div>
          </div>
        </div>
{/* 
        <div>
              {true?
              <>
                            <ReactEcharts
                            style={{height:'200px',width:'200px',
                            marginTop:'-70px', marginRight:'-50px',
                            marginBottom:'-50px'
                          }}
                            option={{
                              // tooltip: {
                              //   trigger: 'item'
                              // },
                              series: [
                                {
                                  name: 'Access From',
                                  type: 'pie',
                                  radius: ['40%', '50%'],
                                  avoidLabelOverlap: true,
                                  label: {
                                    show: true,
                                    position: 'center',
                                    fontSize: '20',
                                    fontWeight: 'bold'
                                  },
                                  emphasis: {
                                    label: {
                                      show: true,
                                      fontSize: '20',
                                      fontWeight: 'bold'
                                    }
                                  },
                                  labelLine: {
                                    show: true
                                  },
                                  data: DataNew
                                }
                              ]
                            }}
                          />
                </>
              :null}
          </div> */}
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(StackChart);
