import React, { Component } from "react";
import { connect } from "react-redux";

function IntroPage(props) {

  let { userName, createdAt } = props;

  return (
    <>
      <div className="flex flex-col space-y-10 w-full ">
        <img className="w-48 mx-auto" src="./img/hitachi-logo-1-1.svg" />
        <div className="space-y-3">
          <h1 className="text-3xl font-bold capitalize text-[#E50026] text-center">{userName}</h1>
          <div className="text-center text-[#3C3C3C] italic text-lg font-medium flex items-center space-x-2 justify-center" >
            <span>{createdAt}</span>
          </div>
        </div>
        <img className="w-full" src='./img/pulse-your-opinion-count.webp' />
        <p className="text-center text-[#3C3C3C]/80 font-medium italic">*This report is confidential and should not be distributed without permission.</p>
        {/* <p className="text-sm text-[#212121]/80 text-center">Copyright 2024 HappyPlus, Inc. All rights reserved.</p> */}
      </div>
    </>
  );
}
export default IntroPage;
