import React, { Component } from "react";
import { connect } from "react-redux";
import ReactEcharts from 'echarts-for-react';
import Header from '../Components/Header';

function NPSDistribution(props) {
   let { getIceNPSRowWiseData, createdAt, title, isSelf, isHideDetails } = props;

   function getDMGScore(list, type) {

      let totalCount = 0;

      let Promoters = 0;
      let Passsives = 0;
      let Distractors = 0;

      if (type === "overall") {
         if (list && list.length > 0) {
            list.forEach(eleX => {
               if (eleX && eleX.answerTextGroups && eleX.answerTextGroups.length > 0) {
                  eleX.answerTextGroups.forEach(ele => {


                     let key = parseInt(ele.answerText);
                     let score = parseInt(ele.count);

                     totalCount += score;

                     if (parseInt(key) >= 9 && parseInt(key) <= 10) {
                        Promoters += score;
                     }
                     if (parseInt(key) >= 7 && parseInt(key) <= 8) {
                        Passsives += score;
                     }
                     if (parseInt(key) >= 0 && parseInt(key) <= 6) {
                        Distractors += score;
                     }


                  });
               }
            });
         }
      } else {
         if (list && list.length > 0) {
            list.forEach(ele => {
               let key = parseInt(ele.answerText);
               let score = parseInt(ele.count);

               totalCount += score;

               if (parseInt(key) >= 9 && parseInt(key) <= 10) {
                  Promoters += score;
               }
               if (parseInt(key) >= 7 && parseInt(key) <= 8) {
                  Passsives += score;
               }
               if (parseInt(key) >= 0 && parseInt(key) <= 6) {
                  Distractors += score;
               }

            });
         }
      }


      let PromotersPer = (Promoters * 100 / totalCount);
      let PasssivesPer = (Passsives * 100 / totalCount);
      let DistractorsPer = (Distractors * 100 / totalCount);

      let npsScore = ((PromotersPer) - (DistractorsPer)).toFixed(2);

      let PromotersColorRange = PromotersPer / 100;
      let PasssivesColorRange = (PasssivesPer / 100) + PromotersColorRange;
      let DistractorsColorRange = (DistractorsPer / 100) + PasssivesColorRange;


      let NPSObj = {
         "sign": parseFloat(npsScore) < 0 ? "-" : "+",
         "npsScore": parseFloat(npsScore).toFixed(0),
         "npsScoreBar": (npsScore).toString(),
         "PromotersPer": PromotersPer.toFixed(0),
         "PasssivesPer": PasssivesPer.toFixed(0),
         "DistractorsPer": DistractorsPer.toFixed(0)
      }

      return NPSObj
   }


   const MultiPieCircle = {
      series: [
         {
            type: 'pie',
            radius: ['65%', '80%'], // Outer circle
            startAngle: 180, // Start angle of the progress (0 degrees is the top)
            // endAngle: 45, // End angle to create a full circle effect
            data: [
               { value: getDMGScore(getIceNPSRowWiseData, "overall")["PromotersPer"], itemStyle: { color: '#66BB6A', borderRadius: '50%', } },
               { value: 100 - parseInt(getDMGScore(getIceNPSRowWiseData, "overall")["PromotersPer"]), itemStyle: { color: '#66BB6A29', } },

            ],
            label: {
               show: false, // Hide the label by default 
               position: 'center',
               formatter: getDMGScore(getIceNPSRowWiseData, "overall")["PromotersPer"],
               fontSize: 16,
               fontWeight: 'bold',
               color: '#212121', // Text color
               fontFamily: 'Poppins, sans-serif', // Title font family
            },
            labelLine: {
               show: false,
            },
            emphasis: {
               label: {
                  show: true, // Show the label on hover
               },
            },
         },
         {
            type: 'pie',
            radius: ['50%', '63%'], // Middle circle
            startAngle: 180, // Start angle of the progress (0 degrees is the top)
            data: [
               { value: getDMGScore(getIceNPSRowWiseData, "overall")["PasssivesPer"], itemStyle: { color: '#FFA726', borderRadius: '50%' } },
               { value: 100 - parseInt(getDMGScore(getIceNPSRowWiseData, "overall")["PasssivesPer"]), itemStyle: { color: '#FFA72629' } },
            ],
            label: {
               show: false, // Hide the label by default
               position: 'center',
               formatter: getDMGScore(getIceNPSRowWiseData, "overall")["PasssivesPer"],
               fontSize: 16,
               fontWeight: 'bold',
               color: '#212121', // Text color
               fontFamily: 'Poppins, sans-serif', // Title font family
            },
            labelLine: {
               show: false,
            },
            emphasis: {
               label: {
                  show: true, // Show the label on hover
               },
            },
         },
         {
            type: 'pie',
            radius: ['35%', '48%'], // Inner circle
            startAngle: 180, // Start angle of the progress (0 degrees is the top)
            data: [
               { value: getDMGScore(getIceNPSRowWiseData, "overall")["DistractorsPer"], itemStyle: { color: '#EF5350', borderRadius: '50%', } },
               { value: 100 - parseInt(getDMGScore(getIceNPSRowWiseData, "overall")["DistractorsPer"]), itemStyle: { color: '#F4433629' } },
            ],
            label: {
               show: false, // Hide the label by default
               position: 'center',
               formatter: getDMGScore(getIceNPSRowWiseData, "overall")["DistractorsPer"],
               fontSize: 16,
               fontWeight: 'bold',
               color: '#212121', // Text color
               fontFamily: 'Poppins, sans-serif', // Title font family
            },
            labelLine: {
               show: false,
            },
            emphasis: {
               label: {
                  show: true, // Show the label on hover
               },
            },
         },
      ],
   };


   function SortList(list) {
      let temp = [];
      if (list && list.length > 0) {
         temp = list.sort((a, b) => a._id.toLowerCase().localeCompare(b._id.toLowerCase()));
      }
      return temp;
   }


   // function chunkArray(array, chunkSize) {
   //    let result = [];
   //    for (let i = 0; i < array.length; i += chunkSize) {
   //       let chunk = array.slice(i, i + chunkSize);
   //       result.push(chunk);
   //       chunkSize = 20
   //    }
   //    return result;
   // }


   function chunkArray(array, chunkSize) {
      let result = [];
      // for (let i = 0; i < array.length; i += chunkSize) {
      //    let chunk = array.slice(i, i + chunkSize);
      //    result.push(chunk);
      // }

      let i = 0;
      while (i < array.length) {
         let chunk = array.slice(i, i + chunkSize);
         result.push(chunk);
         i += chunkSize;
         chunkSize = 17;
      }
      return result;
   }

   let size = 9;
   let finalList = getIceNPSRowWiseData && getIceNPSRowWiseData.length > 0 ? chunkArray(SortList(getIceNPSRowWiseData), size) : [];


   const replaceFn = (word) => {
      if (isSelf && word) {
         return word.replace("(Self Department)", "")
      } else {
         return word
      }
   }

   if (isHideDetails) {
      finalList = [[]]
   }


   function getChartOpt(npsScore) {
      if (npsScore) {
         let opt = {
            series: [{
               type: 'gauge',
               min: -100,
               max: 100,
               axisLine: {
                  lineStyle: {
                     width: 15,
                     color: [
                        [0.5, '#e57373'],
                        [0.8, '#FFA726'],
                        [1, '#81c784']
                     ]
                  }
               },
               pointer: {
                  itemStyle: {
                     color: 'auto'
                  }
               },
               axisTick: {
                  distance: -30,
                  length: 8,
                  lineStyle: {
                     color: '#fff',
                     width: 2
                  }
               },
               splitLine: {
                  distance: -30,
                  length: 30,
                  lineStyle: {
                     color: '#fff',
                     width: 4
                  }
               },
               axisLabel: {
                  color: 'auto',
                  distance: 25,
                  fontSize: 10
               },
               detail: {
                  valueAnimation: false,
                  formatter: '{value}',
                  color: '#757575',
                  fontSize: 0
               },
               data: [{
                  value: npsScore // Replace npsScore with your hardcoded value
               }]
            }]
         }
         return opt
      } else {
         let opt = {
            series: [{
               type: 'gauge',
               min: -100,
               max: 100,
               axisLine: {
                  lineStyle: {
                     width: 15,
                     color: [
                        [0.5, '#e57373'],
                        [0.8, '#FFA726'],
                        [1, '#81c784']
                     ]
                  }
               },
               pointer: {
                  itemStyle: {
                     color: 'auto'
                  }
               },
               axisTick: {
                  distance: -30,
                  length: 8,
                  lineStyle: {
                     color: '#fff',
                     width: 2
                  }
               },
               splitLine: {
                  distance: -30,
                  length: 30,
                  lineStyle: {
                     color: '#fff',
                     width: 4
                  }
               },
               axisLabel: {
                  color: 'auto',
                  distance: 25,
                  fontSize: 10
               },
               detail: {
                  valueAnimation: false,
                  formatter: '{value}',
                  color: '#757575',
                  fontSize: 0
               },
               data: [{
                  value: 0
               }]
            }]
         }
         return opt
      }

   }

   return (
      <>
         {finalList && finalList.length > 0 &&
            finalList.map((innerList, index) =>
               <section className='w-full p-6 px-6 flex flex-col justify-between page-break'>
                  <Header createdAt={createdAt} />

                  <div className="flex flex-col space-y-0 w-full ">
                     <h1 className="text-2xl font-medium    bg-[#212121]/90 text-white p-4">
                        {index ? title + " (Continue)" : title}
                     </h1>
                     {!index &&
                        <>
                           {/* <div className='space-x-10  flex items-center'>
                              <div className="w-60 flex-shrink-0 ">
                                 <ReactEcharts option={getChartOpt(getDMGScore(getIceNPSRowWiseData, "overall")["npsScore"])} style={{ height: '270px', width: '270px' }} />
                              </div>

                              <div className="w-full space-y-4">
                                 <p className='text-xl font-bold text-[#212121] '>eNPS Score: {getDMGScore(getIceNPSRowWiseData, "overall")["npsScore"] + "%"}</p>
                                 <p className='text-xl font-bold text-[#212121] '>eNPS = {getDMGScore(getIceNPSRowWiseData, "overall")["PromotersPer"] + "%"} Promoters - {getDMGScore(getIceNPSRowWiseData, "overall")["DistractorsPer"] + "%"} Detractors</p>
                                 <div className="flex justify-start space-x-6 ">
                                    <div className="flex flex-col items-center gap-2">
                                       <p className="font-medium">Detractors</p>
                                       <div className="text-white flex items-center divide-x divide-white ">
                                          <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">1</span>
                                          <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">2</span>
                                          <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">3</span>
                                          <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">4</span>
                                          <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">5</span>
                                          <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">6</span>
                                       </div>
                                    </div>
                                    <div className="flex flex-col items-center gap-2">
                                       <p className="font-medium">Passive</p>
                                       <div className="text-white flex items-center divide-x divide-white ">
                                          <span className="w-8 flex items-center justify-center h-8 bg-[#FFA726]">7</span>
                                          <span className="w-8 flex items-center justify-center h-8 bg-[#FFA726]">8</span>
                                       </div>
                                    </div>
                                    <div className="flex flex-col items-center gap-2">
                                       <p className="font-medium">Promoters</p>
                                       <div className="text-white flex items-center divide-x divide-white ">
                                          <span className="w-8 flex items-center justify-center h-8 bg-[#66BB6A]">9</span>
                                          <span className="w-8 flex items-center justify-center h-8 bg-[#66BB6A]">10</span>
                                       </div>
                                    </div>
                                 </div>

                                 <div>
                                    <div className="flex items-center space-x-6 py-2 border-b">
                                       <div className="flex items-center space-x-2 w-72">
                                          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">   <path d="M18.2441 23.421H5.52761V8.75896L13.4898 0.508789L14.6065 1.39681C14.7286 1.50359 14.8181 1.64268 14.8646 1.79807C14.9266 2.00216 14.9557 2.21483 14.9508 2.42808V2.71383L13.6615 8.75896H22.2245C22.6767 8.76999 23.1074 8.95457 23.4272 9.27445C23.7471 9.59433 23.9317 10.025 23.9427 10.4772V12.824C23.9433 12.9636 23.9576 13.1028 23.9855 13.2395C24.0146 13.3791 23.9997 13.5243 23.9427 13.6551L20.3344 21.9592C20.152 22.3734 19.86 22.73 19.4899 22.9905C19.1325 23.2657 18.6951 23.4168 18.2441 23.421ZM7.2459 21.7027H18.6161L22.2245 13.139V10.4757H11.542L13.06 3.34385L7.2459 9.47297V21.7027ZM5.52761 8.75896V10.4772H1.71829V21.7027H5.52761V23.421H0V8.75896H5.52761Z" fill="#66BB6A" /></svg>
                                          <p className="text-sm">
                                             Promoters <b className="font-medium">{getDMGScore(getIceNPSRowWiseData, "overall")["PromotersPer"] + "%"}</b>
                                          </p>
                                       </div>
                                       <div className="h-8 w-full bg-[#F1F5F9]">
                                          <div className="flex items-center h-8 bg-[#66BB6A]" style={{ width: `${getDMGScore(getIceNPSRowWiseData, "overall")["PromotersPer"]}%` }} />
                                       </div>
                                    </div>
                                    <div className="flex items-center space-x-6 py-2 border-b">
                                       <div className="flex items-center space-x-2 w-72">
                                          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">   <path d="M18.2441 23.421H5.52761V8.75896L13.4898 0.508789L14.6065 1.39681C14.7286 1.50359 14.8181 1.64268 14.8646 1.79807C14.9266 2.00216 14.9557 2.21483 14.9508 2.42808V2.71383L13.6615 8.75896H22.2245C22.6767 8.76999 23.1074 8.95457 23.4272 9.27445C23.7471 9.59433 23.9317 10.025 23.9427 10.4772V12.824C23.9433 12.9636 23.9576 13.1028 23.9855 13.2395C24.0146 13.3791 23.9997 13.5243 23.9427 13.6551L20.3344 21.9592C20.152 22.3734 19.86 22.73 19.4899 22.9905C19.1325 23.2657 18.6951 23.4168 18.2441 23.421ZM7.2459 21.7027H18.6161L22.2245 13.139V10.4757H11.542L13.06 3.34385L7.2459 9.47297V21.7027ZM5.52761 8.75896V10.4772H1.71829V21.7027H5.52761V23.421H0V8.75896H5.52761Z" fill="#FFA726" /></svg>
                                          <p className="text-sm">
                                             Passives <b className="font-medium">{getDMGScore(getIceNPSRowWiseData, "overall")["PasssivesPer"] + "%"}</b>
                                          </p>
                                       </div>
                                       <div className="h-8 w-full bg-[#F1F5F9]">
                                          <div className="flex items-center h-8 bg-[#FFA726]" style={{ width: `${getDMGScore(getIceNPSRowWiseData, "overall")["PasssivesPer"]}%` }} />
                                       </div>
                                    </div>
                                    <div className="flex items-center space-x-6 py-2 ">
                                       <div className="flex items-center space-x-2 w-72">
                                          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={23} viewBox="0 0 24 23" fill="none">   <path d="M1.77277 14.6722C1.54818 14.6725 1.3259 14.627 1.11953 14.5383C0.913159 14.4497 0.727048 14.3199 0.572593 14.1569C0.409549 14.0024 0.279748 13.8163 0.191139 13.61C0.102529 13.4036 0.0569758 13.1813 0.0572684 12.9567V10.6051C0.0567532 10.4655 0.0424315 10.3263 0.0145123 10.1896C-0.0146114 10.05 0.000328345 9.90476 0.0572684 9.774L3.66304 1.4673C3.84558 1.05331 4.1376 0.696959 4.50767 0.436648C4.8652 0.161721 5.3026 0.0108287 5.7536 0.00683594H18.4717V14.6722L10.5085 22.9234L9.39163 22.0353C9.26953 21.9285 9.18009 21.7894 9.13359 21.634C9.07157 21.4299 9.04245 21.2172 9.04733 21.0039V20.7173L10.336 14.6737L1.77277 14.6722ZM16.7532 1.72534H5.38154L1.77277 10.2901V12.9537H12.4566L10.9383 20.0858L16.7532 13.9589V1.72534ZM18.4717 14.6722V12.9537H22.2815V1.72534H18.4717V0.00683594H24V14.6722H18.4717Z" fill="#EF5350" />
                                          </svg>
                                          <p className="text-sm">
                                             Detractors <b className="font-medium">{getDMGScore(getIceNPSRowWiseData, "overall")["DistractorsPer"] + "%"}</b>
                                          </p>
                                       </div>
                                       <div className="h-8 w-full bg-[#F1F5F9]">
                                          <div className="flex items-center h-8 bg-[#EF5350]" style={{ width: `${getDMGScore(getIceNPSRowWiseData, "overall")["DistractorsPer"]}%` }} />
                                       </div>
                                    </div>
                                 </div>

                              </div>
                           </div> */}

                           <div className="flex flex-col space-y-6 w-full pt-4">
                              <div className="flex justify-center space-x-6">
                                 <div className="flex flex-col items-center gap-2">
                                    <p className="font-medium">Detractors</p>
                                    <div className="text-white flex items-center divide-x divide-white">
                                       <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">1</span>
                                       <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">2</span>
                                       <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">3</span>
                                       <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">4</span>
                                       <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">5</span>
                                       <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">6</span>
                                    </div>
                                 </div>
                                 <div className="flex flex-col items-center gap-2">
                                    <p className="font-medium">Passive</p>
                                    <div className="text-white flex items-center divide-x divide-white">
                                       <span className="w-8 flex items-center justify-center h-8 bg-[#FFA726]">7</span>
                                       <span className="w-8 flex items-center justify-center h-8 bg-[#FFA726]">8</span>
                                    </div>
                                 </div>
                                 <div className="flex flex-col items-center gap-2">
                                    <p className="font-medium">Promoters</p>
                                    <div className="text-white flex items-center divide-x divide-white">
                                       <span className="w-8 flex items-center justify-center h-8 bg-[#66BB6A]">9</span>
                                       <span className="w-8 flex items-center justify-center h-8 bg-[#66BB6A]">10</span>
                                    </div>
                                 </div>
                              </div>

                              <div className='gap-6  flex flex-row border rounded-lg '>
                                 <div className=" flex-shrink-0 ">
                                    <ReactEcharts option={getChartOpt(getDMGScore(getIceNPSRowWiseData, "overall")["npsScore"])} style={{ height: '250px', width: '250px' }} />
                                 </div>
                                 <div className="w-full space-y-3 p-4 text-left">
                                    <div className="space-y-2 ">
                                       <p className='text-lg font-bold text-[#212121] '>{title} Score: {getDMGScore(getIceNPSRowWiseData, "overall")["npsScore"] + "%"}</p>
                                       <div className='text-base font-semibold text-[#212121] flex space-x-2'>
                                          <span>eNPS =</span>
                                          <span>{getDMGScore(getIceNPSRowWiseData, "overall")["PromotersPer"] + "%"}</span>
                                          <span>Promoters</span>
                                          <span>-</span>
                                          <span>{getDMGScore(getIceNPSRowWiseData, "overall")["DistractorsPer"] + "%"}</span>
                                          <span>Detractors</span>
                                       </div>
                                    </div>

                                    <div>
                                       <div className="flex items-center space-x-6 py-2 border-b">
                                          <div className="flex items-center space-x-2 w-72">
                                             <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                             >
                                                <path
                                                   d="M18.2441 23.421H5.52761V8.75896L13.4898 0.508789L14.6065 1.39681C14.7286 1.50359 14.8181 1.64268 14.8646 1.79807C14.9266 2.00216 14.9557 2.21483 14.9508 2.42808V2.71383L13.6615 8.75896H22.2245C22.6767 8.76999 23.1074 8.95457 23.4272 9.27445C23.7471 9.59433 23.9317 10.025 23.9427 10.4772V12.824C23.9433 12.9636 23.9576 13.1028 23.9855 13.2395C24.0146 13.3791 23.9997 13.5243 23.9427 13.6551L20.3344 21.9592C20.152 22.3734 19.86 22.73 19.4899 22.9905C19.1325 23.2657 18.6951 23.4168 18.2441 23.421ZM7.2459 21.7027H18.6161L22.2245 13.139V10.4757H11.542L13.06 3.34385L7.2459 9.47297V21.7027ZM5.52761 8.75896V10.4772H1.71829V21.7027H5.52761V23.421H0V8.75896H5.52761Z"
                                                   fill="#66BB6A"
                                                />
                                             </svg>
                                             <p className="text-sm">
                                                Promoters <b className="font-medium">{getDMGScore(getIceNPSRowWiseData, "overall")["PromotersPer"] + "%"}</b>
                                             </p>
                                          </div>
                                          <div className="h-8 w-full bg-[#F1F5F9]">
                                             <div
                                                className="flex items-center h-8 bg-[#66BB6A]"
                                                style={{ width: `${getDMGScore(getIceNPSRowWiseData, "overall")["PromotersPer"]}%` }}
                                             />
                                          </div>
                                       </div>
                                       <div className="flex items-center space-x-6 py-2 border-b">
                                          <div className="flex items-center space-x-2 w-72">
                                             <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                             >
                                                <path
                                                   d="M18.2441 23.421H5.52761V8.75896L13.4898 0.508789L14.6065 1.39681C14.7286 1.50359 14.8181 1.64268 14.8646 1.79807C14.9266 2.00216 14.9557 2.21483 14.9508 2.42808V2.71383L13.6615 8.75896H22.2245C22.6767 8.76999 23.1074 8.95457 23.4272 9.27445C23.7471 9.59433 23.9317 10.025 23.9427 10.4772V12.824C23.9433 12.9636 23.9576 13.1028 23.9855 13.2395C24.0146 13.3791 23.9997 13.5243 23.9427 13.6551L20.3344 21.9592C20.152 22.3734 19.86 22.73 19.4899 22.9905C19.1325 23.2657 18.6951 23.4168 18.2441 23.421ZM7.2459 21.7027H18.6161L22.2245 13.139V10.4757H11.542L13.06 3.34385L7.2459 9.47297V21.7027ZM5.52761 8.75896V10.4772H1.71829V21.7027H5.52761V23.421H0V8.75896H5.52761Z"
                                                   fill="#FFA726"
                                                />
                                             </svg>
                                             <p className="text-sm">
                                                Passives <b className="font-medium">{getDMGScore(getIceNPSRowWiseData, "overall")["PasssivesPer"] + "%"}</b>
                                             </p>
                                          </div>
                                          <div className="h-8 w-full bg-[#F1F5F9]">
                                             <div
                                                className="flex items-center h-8 bg-[#FFA726]"
                                                style={{ width: `${getDMGScore(getIceNPSRowWiseData, "overall")["PasssivesPer"]}%` }}
                                             />
                                          </div>
                                       </div>
                                       <div className="flex items-center space-x-6 py-2">
                                          <div className="flex items-center space-x-2 w-72">
                                             <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={24}
                                                height={23}
                                                viewBox="0 0 24 23"
                                                fill="none"
                                             >
                                                <path
                                                   d="M1.77277 14.6722C1.54818 14.6725 1.3259 14.627 1.11953 14.5383C0.913159 14.4497 0.727048 14.3199 0.572593 14.1569C0.409549 14.0024 0.279748 13.8163 0.191139 13.61C0.102529 13.4036 0.0569758 13.1813 0.0572684 12.9567V10.6051C0.0567532 10.4655 0.0424315 10.3263 0.0145123 10.1896C-0.0146114 10.05 0.000328345 9.90476 0.0572684 9.774L3.66304 1.4673C3.84558 1.05331 4.1376 0.696959 4.50767 0.436648C4.8652 0.161721 5.3026 0.0108287 5.7536 0.00683594H18.4717V14.6722L10.5085 22.9234L9.39163 22.0353C9.26953 21.9285 9.18009 21.7894 9.13359 21.634C9.07157 21.4299 9.04245 21.2172 9.04733 21.0039V20.7173L10.336 14.6737L1.77277 14.6722ZM16.7532 1.72534H5.38154L1.77277 10.2901V12.9537H12.4566L10.9383 20.0858L16.7532 13.9589V1.72534ZM18.4717 14.6722V12.9537H22.2815V1.72534H18.4717V0.00683594H24V14.6722H18.4717Z"
                                                   fill="#EF5350"
                                                />
                                             </svg>
                                             <p className="text-sm">
                                                Detractors <b className="font-medium">{getDMGScore(getIceNPSRowWiseData, "overall")["DistractorsPer"] + "%"}</b>
                                             </p>
                                          </div>
                                          <div className="h-8 w-full bg-[#F1F5F9]">
                                             <div
                                                className="flex items-center h-8 bg-[#EF5350]"
                                                style={{ width: `${getDMGScore(getIceNPSRowWiseData, "overall")["DistractorsPer"]}%` }}
                                             />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <p><span className="font-bold">Note -:</span> The Employee Net Promoter Score (eNPS) scale spans from -100 to 100. Scores falling within the 10 to 50 range are regarded as 'average,' while those between 50 and 75 are considered excellent. An eNPS score exceeding 75 is likely to be among the top and considered as world-class.</p>

                           </div>

                        </>

                     }

                     {!isHideDetails &&
                        <div className="pt-6">
                           <table className="w-full">
                              <thead className="  bg-[#eaeff4]">
                                 <th className="w-[30%] px-2 border-b-2 border-r-2 border-white p-2 text-sm font-bold text-[#212121] text-left   "> Department</th>
                                 <th className=" w-[70%]  p-2 border-b-2 border-r-2 border-white  " colSpan={2}>
                                    <div className="text-sm font-bold flex justify-between ">
                                       <span>-100%</span>
                                       <span>0</span>
                                       <span>100%</span>
                                    </div>
                                 </th>
                              </thead>
                              <tbody>
                                 {innerList && innerList.length > 0 && innerList.map((ele, index2) => (
                                    <tr className="bg-[#F1F5F9] ">
                                       <td className="text-sm text-left p-2 border-b-2 border-r-2 border-white">
                                          <div className="flex  !text-left  items-center">
                                             <div> {ele && ele._id ? replaceFn(ele._id) : ""}</div>
                                          </div>
                                       </td>
                                       {getDMGScore(ele.answerTextGroups)["sign"] === "+" ?
                                          <>
                                             <td className="p-2 w-[35.5%] border-b-2 border-r-2 border-white">
                                                <div className="flex items-center">
                                                   <span className="text-xs font-medium pl-2 w-12"></span>
                                                   {/* <span className="text-xs font-medium pl-2 w-12">{getDMGScore(ele.answerTextGroups)["npsScore"] + "%"}</span> */}
                                                   <div className="bg-[#EF5350]/10 w-full h-7 overflow-hidden flex items-center space-x-2 justify-end">
                                                      <span className="text-xs font-medium"></span>
                                                      <div className="bg-[#EF5350] w-0/6 h-7"></div>
                                                   </div>
                                                </div>
                                             </td>
                                             <td className="p-2 w-full border-b-2 border-r-2 border-white">
                                                <div className="flex items-center">
                                                   <div className="bg-[#66BB6A]/10 w-full h-7 overflow-hidden flex items-center space-x-2">
                                                      <div className="bg-[#66BB6A] w-full h-7" style={{ width: getDMGScore(ele.answerTextGroups)["npsScoreBar"] + "%" }}></div>
                                                   </div>
                                                   <span className="text-xs font-medium pl-2 w-12">{getDMGScore(ele.answerTextGroups)["npsScore"] + "%"}</span>
                                                </div>
                                             </td>
                                          </>
                                          :
                                          <>
                                             <td className="p-2 w-full border-b-2 border-r-2 border-white">
                                                <div className="flex items-center">
                                                   <div className="bg-[#EF5350]/10 w-full h-7 overflow-hidden flex items-center space-x-2 justify-end">
                                                      <span className="text-xs font-medium pr-2 w-12">{getDMGScore(ele.answerTextGroups)["npsScore"] + "%"}</span>
                                                   </div>
                                                   <div className="bg-[#EF5350] w-full h-7" style={{ width: getDMGScore(ele.answerTextGroups)["npsScoreBar"] + "%" }}></div>
                                                </div>
                                             </td>
                                             <td className="p-2 border-b-2 border-r-2 border-white">
                                                <div className="bg-[#66BB6A]/10 w-full h-7 overflow-hidden flex items-center space-x-2">
                                                   <div className="bg-[#66BB6A] w-0/6 h-7"></div>
                                                   <span className="text-xs font-medium"></span>
                                                </div>
                                             </td>
                                          </>

                                       }

                                    </tr>
                                 ))}
                              </tbody>

                           </table>

                        </div>}



                  </div>
               </section>
            )}
      </>
   );
}
export default NPSDistribution;
