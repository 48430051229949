import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class StackChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  
  render() {
    let { score, color } = this.props;


    let responseRate = score? score:0;


    let DataNew = [
      { value: (100-parseFloat(responseRate)).toFixed(2), 
        itemStyle:{
          color:"#ededed"
        },
        name: 
          `${parseFloat(responseRate)}%`, },
      { value: parseFloat(responseRate), 
        itemStyle:{
          color:"#3399ff"
        },
        name: 
           `${parseFloat(responseRate)}%` }
    ]


    let ScoreLabel = parseFloat(responseRate).toFixed(2)+"%"

    return (
      <>
         {/* <div className="stack-title">
            {currFilterName} 
          </div> */}

          
        <div className="rounded-full w-24 h-24 bg-green-500 pt-2 pl-2" style={{background:color}}>
          <div className="rounded-full w-20 h-20  bg-white pt-2 pl-1 justify-center items-center">
            <div className="font-bold text-center text-base pt-5" style={{color:color}}>{ScoreLabel}</div>
          </div>
        </div>


          {/* <div>
              {true?
              <>
                            <ReactEcharts
                            style={{height:'200px',width:'200px',
                            marginTop:'-70px', marginRight:'-50px',
                            marginBottom:'-50px'
                          }}
                            option={{
                              // tooltip: {
                              //   trigger: 'item'
                              // },
                              series: [
                                {
                                  name: 'Access From',
                                  type: 'pie',
                                  radius: ['40%', '50%'],
                                  avoidLabelOverlap: true,
                                  label: {
                                    show: true,
                                    position: 'center',
                                    fontSize: '20',
                                    fontWeight: 'bold'
                                  },
                                  emphasis: {
                                    label: {
                                      show: true,
                                      fontSize: '20',
                                      fontWeight: 'bold'
                                    }
                                  },
                                  labelLine: {
                                    show: true
                                  },
                                  data: DataNew
                                }
                              ]
                            }}
                          />
                </>
              :null}
          </div> */}
     

      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(StackChart);
