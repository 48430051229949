import React from "react";
import { GoDotFill } from "react-icons/go";

function Content(props) {

  let { activityList, saveFeedbackText } = props;


  return (
    <>

      <div className='border rounded-lg overflow-hidden'>
        <div className='flex items-center justify-between w-full p-4 bg-[#E6F5F3] border-b'>
          <h2 className='text-lg font-semibold'>Activity</h2>
          <button className='text-[#009688]'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
          </button>
        </div>

        <div className='px-4 space-y-4 py-4 '>
          <div className='divide-y'>
            {activityList && activityList.length > 0 && activityList.map((task) =>
              <>
                <div className='py-4'>
                  <p className='font-semibold'>{task && task.desc ? task.desc : ""}</p>
                  {task && task.list && task.list.length > 0 &&
                    task.list.map((ele, index) =>
                      <li className='list-disc' key={index}>
                        <span className='font-semibold'>{ele.title}: {" "}</span>
                        <a href={ele.desc} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">{" "}{ele.desc}</a>
                      </li>
                    )}
                </div>
                {/* <button
                  onClick={() => saveFeedbackText(task.id, true)}
                  className='flex items-center space-x-2 bg-[#009688] mb-2 px-6 py-2 rounded-md text-white w-fit ml-auto lg:text-base text-sm'>
                  <span>Save & Continue</span>
                </button> */}
              </>
            )}
          </div>

        </div>

      </div>

    </>
  );

}
export default Content;