import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class DriverRadar extends Component {
  constructor(props) {
    super(props);
    this.state = {


    }
  }

  componentDidMount() {

  }


  render() {
    let { GetParameterLabel, isStrengths, getQuestionData } = this.props;


    function getScoreValueX(relation, questionX) {
      let scoreMean = 0;
      if (relation !== "Self") {
        let otherSum = 0;
        let otherCount = 0;

        if (questionX && questionX.relationList && questionX.relationList.length > 0) {
          questionX.relationList.forEach((relation) => {
            if (relation !== "Self") {
              otherCount += Number(questionX[relation + "_count"])


              otherSum += Number(questionX[relation + "_sum"]);
            }
          })
        }
        scoreMean = Number((otherSum / otherCount).toFixed(2))
      }
      else {
        scoreMean = Number(questionX["Self_sum"] / questionX["Self_count"]);
      }
      return [Number((scoreMean).toFixed(2))]

    }


    let questionTableList = []
    if (getQuestionData && getQuestionData.length > 0) {
      getQuestionData.forEach((element) => {
        let temp = {
          "name": element.name,
          "dimension": element.parameterDimensionName,
          "Self": getScoreValueX("Self", element),
          "Others": getScoreValueX("Others", element)
        }
        questionTableList.push(temp)
      })

    }


    let list = []
    if (questionTableList && questionTableList.length > 0) {
      questionTableList.forEach((element) => {
        if (isStrengths) {
          if (element.Self < element.Others) {
            list.push(element)
          }
        } else {
          if (element.Self > element.Others) {
            list.push(element)
          }
        }


      })
    }


    let color1 = "#757575";
    let color2 = "#757575";
    if (isStrengths) {
      color1 = "#FF8F00";
      color2 = "#43A047";

    } else {
      color1 = "#43A047";
      color2 = "#FF8F00";
    }


    const chunkSize = 8;
    let ListMain = []
    for (let i = 0; i < list.length; i += chunkSize) {
      const chunk = list.slice(i, i + chunkSize);
      ListMain.push(chunk)
      // do whatever
    }

    let { userName, createdAt } = this.props;

    return (
      <>
        {ListMain && ListMain.length > 0
          ? ListMain.map((listInner, indx) => (
            <section className="p-6 px-6 flex flex-col justify-between page-break">
              {/* report header */}
              <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                <p className='font-medium italic text-base '>360 Feedback</p>
                <p className='font-medium italic text-base'>{userName} | {createdAt}</p>
              </div>
              <div className="space-y-4 flex flex-col  ">
                <h1 className="text-2xl text-[#212121] font-medium capitalize">
                  {(isStrengths ? "Hidden Strengths " : "Blind Spots ") +
                    (indx > 0 ? "(Continue)" : "")}{" "}
                </h1>

                <div className="text-[#212121]/80  space-y-4 text-sm">
                  {isStrengths ? (
                    <p className='italic'>
                      Hidden Strengths are statements where you rated yourself
                      lower compared to the average rating of other
                      respondents.
                    </p>
                  ) : (
                    <p className='italic'>
                      Blind Spots are statements where you rated yourself
                      higher compared to the average rating of other
                      respondents. These may be your potential areas of
                      improvement.
                    </p>
                  )}

                  <div className='space-y-4'>

                    {listInner && listInner.length > 0
                      ? listInner.map((element) => (
                        <table className="w-full  "> 
                          <tr className="bg-[#F5F5F5] ">
                            <td className="p-2 px-4 border-r-2 border-white w-6/12 " rowSpan={2}>
                              <div className="space-y-2 ">
                                <p className="  text-base text-[#212121]/80 font-normal leading-5">
                                  {element.name}
                                </p>
                                <p className="italic text-base font-bold text-[#212121]/80">
                                  {"- " +
                                    GetParameterLabel(
                                      element.dimension
                                    )}
                                </p>
                              </div> 
                            </td>
                            <td className='w-2/12 divide-y-2 divide-white border-r-2 border-white whitespace-nowrap  text-[#212121]/80 font-medium' rowSpan={2}>
                              <p className="py-2 h-10 p-2 text-right">
                                Your Rating
                              </p>
                              <p className="py-2 h-10 p-2 text-right  ">
                                Others
                              </p>
                            </td>

                            <td className="w-4/12 divide-y-2 divide-white" rowSpan={2}>
                              <div className=" h-10 p-2 w-full">
                                <div className='w-full h-6 bg-gray-200'>
                                  <div
                                    style={{
                                      background: color1,
                                      width:
                                        (element && element.Self > 0
                                          ? (element.Self * 100) / 6
                                          : 0) + "%",
                                    }}
                                    className="bg-yellow-500 w-3/6 h-6 flex justify-start items-center"
                                  >
                                    <span className="text-sm text-white px-1">
                                      {element && element.Self > 0
                                        ? element.Self
                                        : 0}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="h-10 p-2 ">
                                <div className='w-full h-6 bg-gray-200'>
                                  <div
                                    style={{
                                      background: color2,
                                      width:
                                        (element && element.Others > 0
                                          ? (element.Others * 100) / 6
                                          : 0) + "%",
                                    }}
                                    className="bg-green-500 w-3/6 h-6 flex justify-start items-center"
                                  >
                                    <span className="text-sm text-white px-1">
                                      {element && element.Others > 0
                                        ? element.Others
                                        : 0}
                                    </span>
                                  </div>
                                </div>
                              </div>

                            </td>
                          </tr>  
                        </table>
                      ))
                      : <div className="text-left text-gray-500 text-md py-4">
                        {isStrengths
                          ? "There are no Hidden Strengths"
                          : "There are no Blind Spots"}
                      </div>}

                  </div>
                </div>
              </div>

              {/* <div className='py-4 flex justify-between items-center'>
          <img src="/img/logo/happypluslogo.png" class=" w-24 object-cover" alt="default_survey_logo" />
          <p className='text-slate-600'>Page 1</p>
          <div/>
        </div> */}

            </section>
          ))
          : null}
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DriverRadar);
