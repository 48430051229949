import React from "react";

export default function Item(props) {
  let {
    getSurveyData,
    IndexEngagementDimensionList,
    getEngagementQuestionData,
    IndexManagerDimensionList,
    getManagerQuestionData,
    getIndexData,
    getAllBenchMarkStaticData
  } = props;
  let { manageName, createdAt } = props;

  let questionEngList = [];
  let questionMngrList = [];
  if (getIndexData && getIndexData["2"] && getIndexData && getIndexData["5"]) {
    if (
      IndexEngagementDimensionList &&
      IndexEngagementDimensionList.length > 0
    ) {
      IndexEngagementDimensionList.forEach((para) => {
        if (getEngagementQuestionData && getEngagementQuestionData.length > 0) {
          getEngagementQuestionData.forEach((ques) => {
            if (para === ques.parameterDimensionName) {
              questionEngList.push(ques);
            }
          });
        }
      });
    }

    if (IndexManagerDimensionList && IndexManagerDimensionList.length > 0) {
      IndexManagerDimensionList.forEach((para) => {
        if (getManagerQuestionData && getManagerQuestionData.length > 0) {
          getManagerQuestionData.forEach((ques) => {
            if (para === ques.parameterDimensionName) {
              questionMngrList.push(ques);
            }
          });
        }
      });
    }
  }



  function getSelectiveScores(list, item, type) {
    let scoreObj = {
      "HWScore": 0,
      "OverallScore": 0
    };
    if (list && list.length > 0) {

      if (type === "dimension") {
        let newList = list.filter(prev => prev.type === "dimension");
        let getIndex = newList.findIndex(prev => prev.name === item);
        if (getIndex !== -1) {
          scoreObj["HWScore"] = newList[getIndex]["HWScore"];
          scoreObj["OverallScore"] = newList[getIndex]["OverallScore"];
        }
      } else if (type === "index") {
        let newList = list.filter(prev => prev.type === "index");
        console.log("newList------x------>", newList)
        console.log("item------x------>", item)

        let getIndex = newList.findIndex(prev => prev.name === item);
        if (getIndex !== -1) {
          scoreObj["HWScore"] = Number(newList[getIndex]["HWScore"]);
          scoreObj["OverallScore"] = Number(newList[getIndex]["OverallScore"]);
        }
      }

    }
    return {
      "HWScore": scoreObj["HWScore"] > 0 ? scoreObj["HWScore"] : "-",
      "OverallScore": scoreObj["OverallScore"] > 0 ? scoreObj["OverallScore"] : "-"
    }
  }



  return (
    <>
      <section className=" space-y-2   px-10">
        <main className=" w-full pb-4 space-y-6 text-left  ">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/70 italic">
            <h1 className="text-base font-semibold">Manager Score Card</h1>
            <p className="text-base font-semibold">{manageName +" | "+createdAt}</p>
          </div>

          <h1 className="px-6 text-4xl text-left text-[#fff] uppercase bg-[#2364AD] p-4 py-4  ">
            First Impression Of Your Manager Report
          </h1>

          <div className="w-full gap-6 space-y-4">
            <div className="bg-[#2364AD] p-4 ">
              <p className="text-xl font-medium text-white">Review Your Results:</p>
            </div>
            <p className="font-bold">Carefully read through your Manager Scorecard and Manager Index. Note your scores, strengths, and areas for improvement.</p>
          </div>
          <div className="w-full gap-6 space-y-3">
            <div className="bg-[#2364AD] p-4 ">
              <p className="text-xl font-medium text-white">Guiding Questions</p>
            </div>


            <div className="space-y-1 ">

              <div class=" py-4 bg-[#ffffff] space-y-4 ">
                <p className="text-xl font-bold">1. What was your first reaction to your Report?</p>
                {/* <div className="list-disc space-y-4 pl-6 ">
                  <div className="flex">
                    <div className="mt-2 mr-2">
                      <svg width="6" height="6" viewBox="0 0 3 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="1.25" cy="1.25" r="1.25" fill="#212121" />
                      </svg>
                    </div>
                    <p className="tracking-tighter">
                      -------------------------------------------------------------------------------------------
                    </p>
                  </div>

                </div> */}


              </div>
              <div class=" py-4 bg-[#ffffff] space-y-4 ">
                <p className="text-xl font-bold">2. Which areas did you score the highest?</p>
                <div className="list-disc space-y-4 pl-6 ">
                  <div className="flex">
                    <div className="mt-2 mr-2">
                      <svg width="6" height="6" viewBox="0 0 3 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="1.25" cy="1.25" r="1.25" fill="#212121" />
                      </svg>
                    </div>
                    <p>
                      What specific behaviors, action contributed to these high scores?
                    </p>
                  </div>

                </div>


              </div>
              <div class=" py-4 bg-[#ffffff] space-y-4 ">
                <p className="text-xl font-bold">3. Which areas did you score the lowest?</p>
                <div className="list-disc space-y-4 pl-6 ">

                  <div className="flex">
                    <div className="mt-2 mr-2">
                      <svg width="6" height="6" viewBox="0 0 3 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="1.25" cy="1.25" r="1.25" fill="#212121" />
                      </svg>
                    </div>
                    <p>
                      What specific challenges or obstacles might have contributed to these lower scores?
                    </p>
                  </div>
                 

                </div>
              </div>
              <div class=" py-4 bg-[#ffffff] space-y-4 ">
                <p className="text-xl font-bold">4. What areas you would like to improve?</p>
              </div>
              <div class=" py-4 bg-[#ffffff] space-y-4 ">
                <p className="text-xl font-bold">5. What areas you would like to continue and leverage on?</p>
              </div>
              <div class=" py-4 bg-[#ffffff] space-y-4 ">
                <p className="text-xl font-bold">6. How do you think these changes will help you better manage your team?</p>
              </div>
            </div>


          </div>
        </main>
      </section>
    </>
  );
}
