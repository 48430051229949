import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";
import { spliceDecimal } from "../../../../../../../../FunctionLibrary/FuntionList"

export default function GaugeScore(props) {

  let { item, Benchmark } = props;



  return (
    <>
      <div className="pt-1 pl-4" style={{ height: Benchmark && Benchmark.score ? null : "210px" }}>
        <div>
          <ReactSpeedometer className="meter"
            height={210}
            value={spliceDecimal(item.score, 2)}
            minValue={0}
            maxValue={100}
            needleColor="#4db8ff"
            labelFontSize="0px"
            segments={1}
            segmentColors={[
              "#f5b342",
              "#e3e6e3"
            ]}
            ringWidth={35}
            customSegmentStops={[0, Number(spliceDecimal(item.score, 2)), 100]}
            // fluidWidth={true}
            needleTransitionDuration={3333}
            needleTransition="easeElastic"
          />

          <div style={{marginTop:'-50px'}} className='w-84 flex justify-between items-center'>
            <div className='font-semibold'>0</div>
            <div className='font-semibold'>100</div>
          </div>

        </div>

        {Benchmark && Benchmark.score || false ?
          <div className="gauge-outer">
            <div className="gauge-inner" style={{ transform: 'rotate(calc(1deg * (-45 + ' + 0 + ' * 1.8)))' }}></div>
          </div>
          : null}





      </div>

    </>
  );
}
