import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";
import { graphic } from 'echarts';
import BarDistribution from './BarDistribution';
import DataNotFound from '../../../../../Components/DataNotFound';
class BoxPlot extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { pairScore, list } = this.props;



    let DistributionData = {
      "ActivelyDisengaged": 0,
      "HighlyEngaged": 0,
      "ModeratelyEngaged": 0,
      "Passive": 0
    }
    let max = 0;
    if (list && list.length > 0) {
      list.forEach(element => {
        let score = parseFloat(element[2]);
        if (score < 3.5) {
          DistributionData["ActivelyDisengaged"] += 1;
          console.log("score1-------------------------11111--->", score)

          if (max < DistributionData["ActivelyDisengaged"]) {
            max = DistributionData["ActivelyDisengaged"]
          }

        } else if (score >= 3.5 && score < 4.5) {
          DistributionData["Passive"] += 1;
          console.log("score2-------------------------11111--->", score)

          if (max < DistributionData["Passive"]) {
            max = DistributionData["Passive"]
          }


        } else if (score >= 4.5 && score < 5.5) {
          DistributionData["ModeratelyEngaged"] += 1;

          if (max < DistributionData["ModeratelyEngaged"]) {
            max = DistributionData["ModeratelyEngaged"]
          }

        } else if (score >= 5.5) {
          DistributionData["HighlyEngaged"] += 1;

          if (max < DistributionData["HighlyEngaged"]) {
            max = DistributionData["HighlyEngaged"]
          }

        }
      });
    }

    max = max + 100




    return (
      <>
        {pairScore && pairScore.length > 0 ?
          <>
            <BarDistribution DistributionData={DistributionData} max={max} />
            <div className="text-center mt-10">
              <b>{"X-Axis:"}</b>{" Engagement Distribution, "}
              <b>{"Y-Axis:"}</b>{" No of Employees"}
            </div>
          </>
          :
          <DataNotFound />
        }

        {/* {pairScore && pairScore.length>0?
      <>
        <ReactEcharts
          option={{
            tooltip: {
              trigger: 'axis',
              axisPointer: {     
                  type: 'shadow'        
              },
              formatter: function(a){
                let nameLabel = a && a[0] && a[0].name?a[0].name:"";
                let valueLabel = a && a[0] && a[0].value?a[0].value:"NA";
                let htmlElement = '<b>'+nameLabel+'</b><br/>'+
                '<p>Number of Employees: <b>'+valueLabel+'</b></p>'

                return htmlElement
              }

            },
            xAxis: {
              type: 'category',
              data: pairScore.map((element)=>(element.label)),
              label: {
                show: true,
                fontStyle:'bold'
              }
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                data: pairScore.map((element)=>(element.count)),
                type: 'bar',
                showBackground: true,
                label: {
                  show: true,
                  position: 'top',
                  formatter: function(value) {
                      return value.data>0?value.data:"0";
                  }
              },
                barWidth:'30px',
                color:new graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#62cff4'
                  },
                  {
                    offset: 1,
                    color: '#2c67f2'
                  }])
              }
            ]
          }}
        />
        <div className="text-center mt-10">
          <b>{"X-Axis:"}</b>{" Engagement Score Range,"} 
          <b>{"Y-Axis:"}</b>{" No of Employees"}
        </div>

        </>
      :
      <div className="text-center mt-10">Data Not Available</div>}
       */}
      </>
    );
  }
}
function mapStateToProps(state) {
  //   //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(BoxPlot);
