import React, { Component } from 'react';
import { connect } from 'react-redux';

import MultiBarChart from './Charts/MultiBarChart';
// import RadarChartPage1 from './Charts/RadarChartPage1';
import BarDistribution1 from './Charts/BarDistribution1';



// import EmployeeDropdown from '../Filter/EmployeeDropdown';
// import Filter from './Filter/Filter';


class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
        currFilterValue: 'none',
        currFilterName: 'none',
  
        currentSelectValue: '',
        FilterValues: [],
        showFilter: false,
        showValue: '',
        crrSelectedEmp:{}
    }
  }

  componentDidMount() {

  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { get360Overview } = this.props;
    this.setState({ FilterValues: finalFilter });
    get360Overview(finalFilter,"","",false);

  }

  handleSelectEmployee=(value)=>{
    this.setState({ crrSelectedEmp: value },()=>{
      let { get360Overview } = this.props;

      let emp_id = "";
      if(value && value.emp_id){
        emp_id = value.emp_id;
      }else{
        emp_id = value.emp_id;
      }
      let { FilterValues } = this.state;
      get360Overview(FilterValues,"","",false,emp_id)

    })
  }


  render() {
   

    let { GetParameterLabel, get360DimensionRelationScoreData, EmpFilterData, GetParameterDesc, getColorByRelation, sequence_list, sequence_rel } = this.props;

    let { FilterValues } = this.state;
    
    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }
    }

    let { userName, createdAt } = this.props;


    return ( 
          
      <MultiBarChart
          get360DimensionRelationScoreData={get360DimensionRelationScoreData}
          GetParameterLabel={GetParameterLabel}
          GetParameterDesc={GetParameterDesc}
          getColorByRelation={getColorByRelation}
          userName={userName}
          createdAt={createdAt}
          sequence_list={sequence_list}
          sequence_rel={sequence_rel}
      />
      
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Overview);
