import React from "react";
import moment from "moment";

export default function IntroductionPage(props) {
  let { userName, createdAt } = props;


  return (
    <>
      <main className="p-10 px-12 space-y-6 bg-white page-break">
        <div className='page-break'>
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-semibold">Leadership Report</h1>
            <p className="text-lg font-semibold">{userName + " | " + createdAt}</p>
          </div>

          <div className="flex flex-col w-full ">
            {/* <h1 className="text-2xl font-medium capitalize text-white p-4">Introduction</h1> */}
            <div className="space-y-4 pt-4 w-full">
              <p className="text-[#212121] text-sm w-full">
                This leadership assessment report provides an in-depth analysis of leadership capabilities across five essential domains: Leading Self, Leading Others, Leading Team, Leading Results, and Leading Community. Each domain offers insights into the strengths and areas for growth that define effective leadership, fostering a well-rounded approach to personal and organizational impact.</p>
              <p className="text-[#212121] text-sm w-full">This report offers insights into your unique leadership strengths within an organizational context,
                empowering you to leverage these attributes effectively. By reflecting on your leadership qualities, you gain a clearer understanding of how your thoughts, actions, and relationships impact your role and influence within the organization. This enhanced awareness enables you to proactively develop your skills and align your goals with organizational objectives. </p>
              <p className="text-[#212121] text-sm w-full">Additionally, the report provides valuable insights into your decision-making processes, team dynamics, behaviors, and overall leadership style, equipping you to drive meaningful results and contribute to organizational success.</p>

              <div className="w-full space-y-1 page-break pt-4">
                <h1 className="text-xl font-bold text-[#212121] uppercase">How to go through this report?</h1>
              </div>

              <div className="space-y-0.5 text-sm w-full">
                <div className="w-full flex px-3 py-2 bg-[#F5F5F5]">
                  <img src="/img/icon/dot.png" className="w-1.5 h-1.5 mt-2 bg-gray-900 rounded-full" />
                  <p className="mx-2 w-full">
                    Fully engage with the report, reflecting on every word to grasp your unique strengths. Recall instances where your talents were in play and note their impact on your performance.
                  </p>
                </div>
                <div className="w-full flex px-3 py-2 bg-[#E2E2E2]">
                  <img src="/img/icon/dot.png" className="w-1.5 h-1.5 mt-2 bg-gray-900 rounded-full" />
                  <p className="mx-2 w-full">
                    Prioritize your Dominant themes, as they heavily influence your behavior and personal brand. Understanding their origin helps you cultivate an authentic perception of yourself.
                  </p>
                </div>
                <div className="w-full flex px-3 py-2 bg-[#F5F5F5]">
                  <img src="/img/icon/dot.png" className="w-1.5 h-1.5 mt-2 bg-gray-900 rounded-full" />
                  <p className="mx-2 w-full">
                    {" "}
                    Pay attention to your non-dominant talent themes to identify areas where partnering with others may be beneficial and recognize your areas of lesser natural aptitude.
                  </p>
                </div>
                <div className="w-full flex px-3 py-2 bg-[#E2E2E2]">
                  <img src="/img/icon/dot.png" className="w-1.5 h-1.5 mt-2 bg-gray-900 rounded-full" />
                  <p className="mx-2 w-full">
                    Proactively plan to leverage your inherent talents in your next task to observe how they enhance your performance.
                  </p>
                </div>
                <div className="w-full flex px-3 py-2 bg-[#F5F5F5]">
                  <img src="/img/icon/dot.png" className="w-1.5 h-1.5 mt-2 bg-gray-900 rounded-full" />
                  <p className="mx-2 w-full">
                    For further insights and clarity, feel free to reach out to us at contact@happyplus.in. We can schedule a coaching call to address any questions or concerns you may have.
                  </p>
                </div>
              </div>

              <main className="page-break pt-4">
                <div className="w-full space-y-1 page-break">
                  <h1 className="text-xl font-bold text-[#212121] uppercase">How Do I Use the Leadership Assessment Report?</h1>
                </div>
              </main>
              <div className="space-y-0.5 text-sm w-full">
                <div className="flex w-full px-3 py-2 bg-[#F5F5F5]">
                  <img src="/img/icon/dot.png" className="w-1.5 h-1.5 mt-2 bg-gray-900 rounded-full" />
                  <p className="mx-2 w-full">This self-assessment report is designed to help you reflect on the diverse skills and attributes essential to effective leadership—both those you already possess and those you aim to develop. </p>
                </div>
                <div className="flex w-full px-3 py-2 bg-[#E2E2E2]">
                  <img src="/img/icon/dot.png" className="w-1.5 h-1.5 mt-2 bg-gray-900 rounded-full" />
                  <p className="mx-2 w-full">While mastering every leadership skill is a lifelong journey, this tool, as part of the Leadership Development Program, will guide you in evaluating your strengths and areas for growth. </p>
                </div>
                <div className="flex w-full px-3 py-2 bg-[#F5F5F5]">
                  <img src="/img/icon/dot.png" className="w-1.5 h-1.5 mt-2 bg-gray-900 rounded-full" />
                  <p className="mx-2 w-full">By examining why certain attributes feel like strengths or weaknesses, you can identify targeted areas for improvement. This assessment serves as a foundation for setting meaningful goals in your Personal Development Plan, supporting your continuous growth as a leader.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </main>
    </>
  );
}
