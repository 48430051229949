import React, { Component } from "react";
import { connect } from "react-redux";
import ReactEcharts from "echarts-for-react";

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    let {
      crrManagersList,
      getIndexScoreManagerPIDWiseData,
      singleManagerData,
      validateList,
      DemographicRestriction,
      IndexLabels,
      getIndexData,
      showMultiple,
      IndexForManagerData,
      ManagerListDasboardDataObj,
      DynaObj,
      EmpFilterData,
    } = this.props;

    ////console.log('IndexLabels: ',IndexLabels)
    // ////console.log('getIndexData: ',getIndexData)
    // ////console.log('IndexForManagerData: ',IndexForManagerData)
    ////console.log('ManagerListDasboardDataObj: ',ManagerListDasboardDataObj)

    function getManagerName(mngrId) {
      let name = "";
      if (crrManagersList && crrManagersList.length > 0) {
        let getIndex = crrManagersList.findIndex(
          (prev) => prev.emp_id.toString() === mngrId.toString()
        );
        if (getIndex !== -1) {
          name = crrManagersList[getIndex]["name"];
        }
      }
      return name ? name : mngrId;
    }

    let DataValues = [];
    let list = [];
    let overallData = {};
    let managerTeam = {};
    let VariableData = {};

    if (true) {
      //  list = getDistributionIndexData

      // overallData = {
      //   AchivementAvg:  getIndexDriverData.AchivementCount,
      //   BeingAvg:  getIndexDriverData.BeingCount,
      //   MeeningfullAvg:  getIndexDriverData.MeeningfullCount,
      //   RelationshipAvg:  getIndexDriverData.RelationshipCount,
      //   TimeAvg:  getIndexDriverData.TimeCount,
      //   WorkLifeAvg:  getIndexDriverData.WorkLifeCount,
      //   name: "OVERALL"
      // }

      if (IndexLabels && IndexLabels.length > 0) {
        IndexLabels.forEach((item) => {
          if (getIndexData[item.indexType]) {
            overallData[item.indexType] =
              getIndexData[item.indexType].toFixed(2);
            overallData["name"] = "Overall Score";
          }

          if (IndexForManagerData[item.indexType]) {
            managerTeam[item.indexType] =
              IndexForManagerData[item.indexType].toFixed(2);
            managerTeam["name"] = "Team Score";
          }

          // VariableData[item.indexType]=getManagerListDomainFilterData[item.indexType]
          // VariableData["name"]="Department Score";
        });
      }

      // list.push(VariableData)
      if (overallData && overallData["name"]) {
        list.push(overallData);
      }
      if (managerTeam && managerTeam["name"]) {
        list.push(managerTeam);
      }

      let NewData = [];
      if (EmpFilterData && EmpFilterData.length > 0) {
        EmpFilterData.forEach((emp) => {
          if (
            DemographicRestriction.findIndex((prev) => prev === emp.name) !== -1
          ) {
            if (DynaObj && DynaObj.length > 0) {
              DynaObj.forEach((item) => {
                if (emp.name === item.name[0]) {
                  NewData.push(item);
                }
              });
            }
          }
        });
      }

      ////console.log('NewData: ',NewData);

      if (NewData && NewData.length > 0) {
        NewData.forEach((item) => {
          if (item.name[0] === "ManagerPID") {
            let Data = {};

            if (IndexLabels && IndexLabels.length > 0) {
              IndexLabels.forEach((label) => {
                Data[label.indexType] =
                  item && item[label.indexType]
                    ? item[label.indexType].toFixed(2)
                    : "NaN";
              });
            }

            Data["name"] = "Team Score";

            if (Data && Data["name"]) {
              list.push(Data);
            }
            ////console.log('NewData Data: ',Data);
          }
        });
      }

      if (NewData && NewData.length > 0) {
        NewData.forEach((item) => {
          if (item.name[0] !== "ManagerPID") {
            let Data = {};

            if (IndexLabels && IndexLabels.length > 0) {
              IndexLabels.forEach((label) => {
                Data[label.indexType] =
                  item && item[label.indexType]
                    ? item[label.indexType].toFixed(2)
                    : "0";
              });
            }

            // Data["name"] = item.name;

            let newLevel =
              item && item.name && item.name[0] ? item.name[0] : "";
            Data["name"] =
              singleManagerData && singleManagerData[newLevel]
                ? newLevel + " (" + singleManagerData[newLevel] + ") "
                : newLevel;

            if (Data && Data["name"]) {
              list.push(Data);
            }
            ////console.log('NewData Data: ',Data);
          }
        });
      }

      // ////console.log('NewData: ',NewData);
    }

    let XAxis = [];
    let XAxisName = [];

    if (IndexLabels && IndexLabels.length > 0) {
      IndexLabels.forEach((item) => {
        XAxis.push(item.indexName);
        XAxisName.push(item.indexType);
      });
    }

    let YAxis = [];

    if (list && list.length > 0) {
      list.forEach((driver) => {
        YAxis.push(driver.name);
      });
    }

    // ////console.log('XAxis: ',XAxis)
    // ////console.log('YAxis: ',YAxis)
    // ////console.log('DataValues: ',DataValues)
    // ////console.log('list: ',list)

    // let XAxis = []

    // if(EmpFilterData && EmpFilterData.length>0){
    //   EmpFilterData.forEach((emp)=>{
    //     XAxis.push(emp.label)
    //   })
    // }

    ////console.log('IndexLabels: ',IndexLabels)
    ////console.log('--->>_---------->list: ',list)

    let blackColor = "#000000";
    function getColor(data, index) {
      if (data.name === "Overall Score") {
        return { Color: "#ffffff", diffTextColor: "#ffffff" };
      } else {
        let diffNo = parseInt(
          Math.abs(parseFloat(overallData[index]) - parseFloat(data[index]))
        );
        ////////console.log("diffNo:",diffNo)
        if (parseFloat(overallData[index]) <= parseFloat(data[index])) {
          //greater (blue)
          if (diffNo >= 0 && diffNo <= 2) {
            return { Color: "#2196F3", diffTextColor: "#ffffff" };
          } else if (diffNo >= 2 && diffNo <= 12) {
            return { Color: "#90CAF9", diffTextColor: "#00000" };
          } else {
            return { Color: "#BBDEFB", diffTextColor: "#00000" };
          }
        } else {
          //lesse (red)
          if (diffNo >= 0 && diffNo <= 2) {
            return { Color: "#FFCDD2", diffTextColor: "#000000" };
          } else if (diffNo >= 2 && diffNo <= 12) {
            return { Color: "#ea95a1", diffTextColor: "#000000" };
          } else {
            return { Color: "#ef5350", diffTextColor: "#ffffff" };
          }
        }
      }
    }

    let List2x = [];
    List2x.push(overallData);

    //console.log('list: ',list)

    if (
      getIndexScoreManagerPIDWiseData &&
      getIndexScoreManagerPIDWiseData.length > 0
    ) {
      getIndexScoreManagerPIDWiseData.forEach((manager) => {
        let temp = {
          name: getManagerName(manager.mngrId),
        };
        if (IndexLabels && IndexLabels.length > 0) {
          IndexLabels.forEach((item) => {
            if (
              manager &&
              manager["finalData"] &&
              manager["finalData"][item.indexType]
            ) {
              temp[item.indexType] =
                manager["finalData"][item.indexType].toFixed(2);
            }
          });
        }
        List2x.push(temp);
      });
    }

    console.log(
      "----s--------.getIndexScoreManagerPIDWiseData: ",
      getIndexScoreManagerPIDWiseData
    );
    console.log("----s--------.showMultiple: ", showMultiple);

    let ListFinal = [];
    if (showMultiple) {
      ListFinal = List2x;
    } else {
      ListFinal = list;
    }
    ////console.log('NewManagers-->',NewManagers)
    ////console.log('ListFinal-->',ListFinal)

    let IndexNameList = [
      {
        index: 1,
        name: "Happiness",
        color: "#FF4081",
      },
      {
        index: 2,
        name: "Engagement",
        color: "#2196F3",
      },
      {
        index: 4,
        name: "Stress",
        color: "#EF5350",
      },
      {
        index: 5,
        name: "Manager",
        color: "#9C27B0",
      },
    ];
    let IndexNameData = [];
    if (validateList && validateList.length > 0) {
      validateList.forEach((valid) => {
        if (IndexNameList && IndexNameList.length > 0) {
          IndexNameList.forEach((item) => {
            if (item.index === valid.template)
              IndexNameData.push({
                indexType: item.index,
                indexName: item.name,
              });
          });
        }
      });
    }

    console.log("ListFinal-->", ListFinal);

    return (
      <div className=" bg-white hover:shadow-lg duration-150 transition  border p-6 rounded-lg space-y-6   cursor-pointer">
        <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap ">
        Table View
        </h1>
        {ListFinal && ListFinal.length > 0 ? (
          <div className="overflow-x-auto whitespace-nowrap w-full overflow-hidden  mb-4 pb-4">
            <table className="w-full border ">
              <thead>
                <tr className=" border-b text-sm  text-[#3D405B]">
                  <th className="py-2 px-4 border-r font-bold text-left">{"Overall Score"}</th>
                  {IndexNameData && IndexNameData.length > 0
                    ? IndexNameData.map((label, index) => (
                        <th
                          key={index}
                          className="p-3 font-bold border-r text-[#3D405B] text-left "
                        >
                          {label.indexName}
                          <div className="px-12" />
                        </th>
                      ))
                    : null}
                </tr>
              </thead>
              <tbody>
                {/* <tr className="bg-white border-b text-xs   text-gray-800">
                  <th className="py-2 px-4 border-r font-bold">
                    {"Overall Score"}
                  </th>
                  {IndexNameData && IndexNameData.length > 0
                    ? IndexNameData.map((label, index) => (
                        <th
                          key={index}
                          className="p-3 font-bold text-gray-800 "
                        >
                          {ListFinal[0][label.indexType]
                            ? ListFinal[0][label.indexType]
                            : "0"}
                        </th>
                      ))
                    : null}
                </tr> */}

                {ListFinal && ListFinal.length > 0
                  ? ListFinal.map((item) => (
                      <tr className="bg-white border-b   text-sm text-center text-[#3D405B]">
                        {item.name !== "Overall Score" ? (
                          <td
                            className="p-3 text-left"
                            style={{
                              fontWeight:
                                item.name === "Overall Score" ? "bold" : null,
                            }}
                          >
                            {item.name ? item.name : "Unavailable"}
                          </td>
                        ) : null}

                        {IndexNameData && IndexNameData.length > 0
                          ? IndexNameData.map((label) =>
                              item.name !== "Overall Score" ? (
                                <td
                                  className="p-3 border border-b text-left"
                                  style={{
                                    background: getColor(item, label.indexType)
                                      .Color,
                                    color:
                                      item.name === "Overall Score"
                                        ? blackColor
                                        : getColor(item, label.indexType)
                                            .diffTextColor,
                                    fontWeight:
                                      getColor(item, label.indexType)
                                        .diffTextColor === "#000000"
                                        ? "500"
                                        : null,
                                  }}
                                >
                                  {item[label.indexType]
                                    ? item[label.indexType]
                                    : "0"}
                                </td>
                              ) : null
                            )
                          : null}
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(HeatMap);
