import React from 'react';
import GaugeScore from './Charts/GaugeScore';
import BarDistribution from './Charts/BarDistribution';


export default function Engagement(props) {

  let {

    item,
    DistributionData,
    Benchmark,
    cronBachAlpha

  } = props;


  ////console.log('DistributionData:====> ',DistributionData)



  return (
    <>
      <div className="w-full">

        <div className="" >
          <BarDistribution DistributionData={DistributionData} />
        </div>


      </div>
    </>
  );
}
