import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";


class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList:[],
      EmpIndexA:0,
      EmpIndexB:4
    }
  }
  componentDidMount() {

  }

  handleMatrix=(question)=>{
    let { stage } = this.props;
    if(stage==2){
      let { handleMatrixDetails } = this.props;
      handleMatrixDetails(question,stage)
    }
    
  }


  render() {
  let { stage , renderType,currentQuestionId,  getQuestionMatrixResultData, questionsList } = this.props;
          


  function FormatMatriXOptionHeading(question, isOption){
    let paraObjShow = {};
    if(question && question.optionsList && question.optionsList.length>0){
      question.optionsList.forEach((item)=>{
        if(paraObjShow && paraObjShow[item.heading]){
          paraObjShow[item.heading] += 1
        }
        else{
          paraObjShow[item.heading] = 1
        }
      });
    }

    let headingList = []
    let tempKey = Object.keys(paraObjShow);
    if(tempKey && tempKey.length>0){
      tempKey.forEach((item)=>{
      headingList.push({
        name:item,
        span:paraObjShow[item]
      })
    });
    }

    if(isOption){
      let OptionList = []
      if(headingList && headingList.length>0){
        headingList.forEach((heading)=>{
          if(question && question.optionsList && question.optionsList.length>0){
            question.optionsList.forEach((option)=>{
              if(heading.name === option.heading){
                OptionList.push(option)
              }
            });
          }
        });
      }
      return OptionList
    }
    else{
      return headingList
    }

  }





function getCountPerc(SubQues,option,heading){
  //////console.log("getCountPerc===>",SubQues,option,heading)
  let total = 0;
  let count = 0;
  if(SubQues && SubQues.optionsList && SubQues.optionsList.length>0){
    SubQues.optionsList.forEach((item)=>{
      if(item.heading === heading){
        total += parseInt(item.count);
        if(item.answerText===option){
          count += parseInt(item.count);
        }
      }
    });
  }
  return (parseFloat((count*100)/total).toFixed(2)).toString()+"%";
}



  let MainQuesList = []
  if(questionsList && questionsList.length>0){
    questionsList.forEach((ques)=>{
      if((ques.templateId===13) && !ques.parentId){
          MainQuesList.push({
            questionId:ques.id,
            name:ques.name,
            optionsList:ques.optionsList,
            SubQuestionList:[]
          });
      }
    });
  }

  
  if(getQuestionMatrixResultData && getQuestionMatrixResultData.length>0){
    getQuestionMatrixResultData.forEach((item)=>{

      let newIndex = MainQuesList.findIndex(prev=>prev.questionId===(item && item.total && item.total[0] && item.total[0]._id.qparentId?item.total[0]._id.qparentId:""));
      if(newIndex!==-1){
        let temp = {
          "name":item && item.question && item.question[0] && item.question[0].name?item.question[0].name:"",
          "optionsList":[],
          "qtemplate":item && item.total && item.total[0] && item.total[0]._id.qtemplate?item.total[0]._id.qtemplate:"",
          "qparentId":item && item.total && item.total[0] && item.total[0]._id.qparentId?item.total[0]._id.qparentId:"",
          "questionId":item && item._id?item._id:"",
        }
        if(item && item.total && item.total.length>0){
          item.total.forEach((otp)=>{
            if(otp && otp._id){
              temp["optionsList"].push({
                "answer":otp && otp._id && otp._id.answer?otp._id.answer:"",
                "answerText":otp && otp._id && otp._id.answerText?otp._id.answerText:"",
                "heading":otp && otp._id && otp._id.heading?otp._id.heading:"",
                "count":otp && otp.count?otp.count:"",
  
              });
            }
          });
        }

        MainQuesList[newIndex]["SubQuestionList"].push(temp)
      }

    });
  }







  // let questionName = ALLMatrixList && ALLMatrixList[0] && ALLMatrixList[0].name?ALLMatrixList[0].name:"";
  // let question = ALLMatrixList && ALLMatrixList[0]?ALLMatrixList[0]:{};

    let questionName = "";
    let question = {};


    let ALLMatrixList = [];

    if(stage==2){
      ALLMatrixList = MainQuesList;
    }
    if(stage==3){
      ALLMatrixList = MainQuesList;
      //console.log('X==========================================================>MainQuesList: ',MainQuesList);

      let getINdex3 = MainQuesList.findIndex(prev=>prev.questionId === currentQuestionId);
      if(getINdex3!==-1){
        questionName = MainQuesList && MainQuesList[getINdex3] && MainQuesList[getINdex3].name? MainQuesList[getINdex3].name : "";
        question = MainQuesList && MainQuesList[getINdex3]?MainQuesList[getINdex3]:{};
      }

    }
    else{
      ALLMatrixList = MainQuesList && MainQuesList.length>0?[ MainQuesList[0] ]:[];
      
    }


    //console.log('X==========================================================>currentQuestionId: ',currentQuestionId);
    //console.log('X==========================================================>questionName: ',questionName);
    //console.log('X==========================================================>question: ',question);






    function SingleScore (SubQues, option){
      let item = "";
      let total = 0;
      let count = 0;
      if(SubQues && SubQues.optionsList && SubQues.optionsList.length>0){
        SubQues.optionsList.forEach((item)=>{

          if((item.heading === option.heading)){
            total += item.count;
            if((item.answerText === option.name)){
              count += item.count;
            }
          }


        });
      }




      //console.log('X==========================================================>count: ',count);

      
      return (((count*100)/total).toFixed(2).toString()+"%"); 
    }



    return (
      <>
    
  <div className=" w-full   p-2">
  <div className="text-gray-700 text-lg font-semibold ">{questionName}</div>
  <table className="w-full border mt-4 " >
    <thead className="">
      
      

      <tr className=" border-b">
        
        
        <th className="p-2 border-r cursor-pointer text-sm font-medium">
          <div className="flex items-center justify-center">
          </div>
        </th>
        
        
        {FormatMatriXOptionHeading(question, true) && FormatMatriXOptionHeading(question, false).length>0?
         FormatMatriXOptionHeading(question, false).map((heading, optionindex)=>
            <th className="w-2/6 border-r cursor-pointer text-sm  bg-green-400 text-white">
              <p className="w-full border-b  p-2 font-semibold">{heading.name.split("-")[0]}</p>
              <div className="flex font-normal ">
                
              {FormatMatriXOptionHeading(question, true) && FormatMatriXOptionHeading(question, true).length>0?
              FormatMatriXOptionHeading(question, true).map((option, optionindex)=>(option.heading === heading.name)?
                <p className="w-full border-r p-2 font-semibold">{option.name}</p>
              
              :null ):null}
              </div>
            </th>
         ):null}


      </tr>


    </thead>
    <tbody>
      {question && question.SubQuestionList && question.SubQuestionList.length>0?
        question.SubQuestionList.map((SubQues)=>
            <tr className="bg-white  border-b text-sm"> 
                <td className=" border-r p-2 font-normal">{SubQues.name}</td>
                


                        
              {FormatMatriXOptionHeading(question, true) && FormatMatriXOptionHeading(question, false).length>0?
              FormatMatriXOptionHeading(question, false).map((heading, optionindex)=>
              <td className="border-r "> 
                <div className="flex">
                  {FormatMatriXOptionHeading(question, true) && FormatMatriXOptionHeading(question, true).length>0?
                  FormatMatriXOptionHeading(question, true).map((option, optionindex)=>(option.heading === heading.name)?
                  <div className="w-full flex justify-center font-semibold">{
                    SingleScore(SubQues, option)
                  }</div> 
                  :null ):null}
                </div>
              </td>
              ):null}



                 
          
          </tr>

      ):null}


    </tbody>
  </table>
</div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(PieChart);
