import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import "../index.css";
import Layout from "./Layout/Layout";
import PlusLogin from "../pages/Login/PlusLogin";
import Login from "../pages/Login/Login";
import GrowLogin from "../pages/Login/GrowLogin";
export default function App() {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/survey" />} />
        {/* <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} /> */}

        <Route exact path="/app" render={() => <Redirect to="/app/survey" />} />
        {/* <Route exact path="/app" render={() => <Redirect to="/app/dashboard" />} /> */}

        <PublicRoute path="/app" component={withRouter(Layout)} />
        <PublicRoute path="/login" component={withRouter(PlusLogin)} />
        <PublicRoute path="/pmslogin" component={withRouter(Login)} />
        <PublicRoute path="/growlogin" component={withRouter(GrowLogin)} />
      </Switch>
    </HashRouter>
  );

  function PublicRoute({ component, ...rest }) {
    let value = 'login'
    try {
      value = window.location.href.split('#/')[1];
    } catch {
      value = 'login'
    }
    console.log("value--->", value)
    return (
      <Route
        {...rest}
        render={(props) =>
          false ? (
            <Redirect
              to={{
                pathname: (value[1] === 'pmslogin') ? "/pmslogin" : ((value[1] === 'login') ? "/login" : "/login"),
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
