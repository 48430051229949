import React, { Component } from 'react';
import { connect } from 'react-redux';

import Filter from './Filter';
import HeatMap from './Components/HeatMap';
import HorizontalBar from '../../../../Overview/Components/QuestionTypeCards/Charts/HorizontalBar';

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: 'none',
      viewScore: true,
      showLeaderBoard: false,
      showFilter: false,
      showValue: '',
      XAxis: ""
    }
  }
  componentDidMount() {

  }
  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleFilterSave = (name) => {
    this.setState({ currFilterName: name })
  }



  handleMultifilterIndexScore2 = (finalFilter, XAxis) => {
    //console.log(':----------------------------------------------------------------------------------------------------------------XAxis:-------------------->',finalFilter, XAxis)

    let { handleOthersFilterCall } = this.props;
    this.setState({ FilterValues: finalFilter, XAxis: XAxis });
    handleOthersFilterCall(finalFilter, XAxis, 11);

  }

  render() {
    let {
      getQuestionOthersResultData, currentQuestionId, EmpFilterData, getQuestionOthersResultFilterData } = this.props;

    let { currFilterName, FilterValues, XAxis } = this.state;

    //-------------------------------------------------------------------------------------------------------------------------
    let nEwAxis = "";
    if (XAxis) {
      nEwAxis = XAxis;
    }
    else if (EmpFilterData && EmpFilterData.length > 0) {
      nEwAxis = EmpFilterData[0].name;
    }


    //////console.log("==========>EmpFilterData",EmpFilterData)




    let QuestionName = "";

    function FormattFun(inputData) {
      let LIST = []
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {


          if (currentQuestionId === ques.total[0]._id.questionId) {


            let temp = {
              name: ques && ques.question[0] && ques.question[0].name ? ques.question[0].name : "",
              answerList: []
            }
            QuestionName = temp["name"];

            if (ques && ques.total && ques.total.length > 0) {
              ques.total.forEach((item) => {
                //console.log('item: -------->nEwAxis',item)
                let ans = {
                  count: item.count,
                  answerText: item["_id"]["answerText"],
                  template: item["_id"]["qtemplate"],
                  dmgValue: item["_id"][nEwAxis]
                }
                temp["answerList"].push(ans);
              });
            }
            LIST.push(temp);
          }
        });
      }
      return LIST
    }

    let NPSQuesList = FormattFun(getQuestionOthersResultData);
    let NPSQuesListFilter = FormattFun(getQuestionOthersResultFilterData);


    //console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxgetQuestionOthersResultData: ',getQuestionOthersResultData)


    let totalCount = 0;

    let Promoters = 0;
    let Passsives = 0;
    let Distractors = 0;

    let DemographicValues = [];
    let DemographicValuesObj = {};

    let item = NPSQuesList[0];
    let itemOthers = NPSQuesListFilter[0];



    let AnswerObjOverall = {};
    let AllAnsText = [];
    if (item && item.answerList && item.answerList.length > 0) {
      item.answerList.forEach((ans) => {
        ////console.log('ans (NPS)',ans)

        let getIndex3 = AllAnsText.findIndex(prev => prev === ans.answerText);

        if (getIndex3 === -1) {
          AllAnsText.push(ans.answerText);
        }


        if (AnswerObjOverall && AnswerObjOverall[ans.answerText]) {
          AnswerObjOverall[ans.answerText] += (ans.count);
        }
        else {
          AnswerObjOverall[ans.answerText] = (ans.count)
        }

      });
    }




    let AnswerObj = {};
    let AnswerObjList = [];

    if (itemOthers && itemOthers.answerList && itemOthers.answerList.length > 0) {
      itemOthers.answerList.forEach((ans) => {

        let getIndex = DemographicValues.findIndex(prev => prev === ans.dmgValue);
        if (getIndex === -1) {
          DemographicValues.push(ans.dmgValue);
          DemographicValuesObj[ans.dmgValue] = {
            "count": 0
          }
          if (AllAnsText && AllAnsText.length > 0) {
            AllAnsText.forEach((alltext) => {
              DemographicValuesObj[ans.dmgValue][alltext] = 0;
            });
          }
          // DemographicValuesObj[ans.dmgValue]={
          //   "Star 1":0,
          //   "Star 2":0
          // }
        }
        //console.log('ans (NPSQuesList)',NPSQuesList)
        //console.log('dmgValue (NPS)',ans.dmgValue)

        let code = ans.answerText + ans.dmgValue;

        let getIndex1 = AnswerObjList.findIndex(prev => prev.code === code);
        if (getIndex1 === -1) {
          AnswerObjList.push({
            "text": ans.answerText,
            "name": ans.dmgValue,
            "value": ans.count,
            "code": ans.answerText + ans.dmgValue
          });
        }
        else {
          AnswerObjList[getIndex1].value += ans.count;
        }


        //  if(AnswerObj && AnswerObj[ans.answerText] && AnswerObj[ans.answerText].name){
        //    AnswerObj[ans.answerText]["value"] += (ans.count);
        //  }
        //  else{
        //    AnswerObj[ans.answerText]={
        //      "name":ans.dmgValue,
        //      "value":(ans.count)
        //    }
        //  }

      });
    }


    ////console.log('AnswerObj:',AnswerObj)

    if (AnswerObjList && AnswerObjList.length > 0) {


      // //console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxAnswerObjList: ',AnswerObjList)

      // //console.log('--->DemographicValues:',DemographicValues)



      ////console.log('test : AnswerObj',AnswerObj)
      AnswerObjList.forEach(item1 => {
        //  let score = parseInt(AnswerObj[key]["value"]);
        //  let name = AnswerObj[key]["name"];
        ////console.log('test : score',score)
        ////console.log('test : name',name)
        ////console.log('test : key',parseInt(key))

        let key = item1.text;
        let name = item1.name;
        let score = item1.value;

        totalCount++;
        DemographicValuesObj[name]["count"] += score;

        if (key) {

          DemographicValuesObj[name][key] += score;

        }
        //  if((key) === "No"){

        //   DemographicValuesObj[name]["No"]+=score;
        //  }

      });
    }

    ////console.log('DemographicValues: ',DemographicValues)
    ////console.log('FINAL: DemographicValuesObj: ',DemographicValuesObj)

    let PromotersPer = (Promoters * 100 / totalCount);
    let PasssivesPer = (Promoters * 100 / totalCount);
    let DistractorsPer = (Distractors * 100 / totalCount);

    let npsScore = ((PromotersPer) - (DistractorsPer)).toFixed(2);

    let PromotersColorRange = PromotersPer / 100;
    let PasssivesColorRange = (PasssivesPer / 100) + PromotersColorRange;
    let DistractorsColorRange = (DistractorsPer / 100) + PasssivesColorRange;




    ////console.log('Promoters: ',Promoters);
    ////console.log('Passsives: ',Passsives);
    ////console.log('Distractors: ',Distractors);

    ////console.log('npsScore: ',npsScore);

    ////console.log('PromotersColorRange: ',PromotersColorRange);
    ////console.log('PasssivesColorRange: ',PasssivesColorRange);
    ////console.log('DistractorsColorRange: ',DistractorsColorRange);






    //console.log('----------->AnswerObjOverall:',AnswerObjOverall)
    ////console.log('handleDemographicFilter:',handleDemographicFilter)


    let { getIndexFilterData } = this.props;


    return (
      <>
        <div className="items-center justify-between h-10 xl:flex lg:flex md:flex">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}
            EmpFilterData2={EmpFilterData}
            getIndexFilterData={getIndexFilterData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
          />
        </div>

        <div onClick={() => this.closeFilter(false)} className="">
          <div className="flex w-full pt-6" >

            {!(FilterValues && FilterValues.length > 0) && false ?
              <>
                <div className="w-full px-4 pb-10 m-4 bg-white rounded shadow-lg">
                  <h1 className="py-4 text-lg font-semibold">{QuestionName}
                    {/* <p className="text-sm font-semibold text-gray-500">RATING SCALE QUESTION</p> */}
                  </h1>
                  {item && item.answerList[0] && item.answerList[0].template === 5 ?
                    <HorizontalBar AnswerObj={AnswerObjOverall} name={"Overall"} templateId={5} stage={3} />
                    : null}
                </div>
              </>
              :
              <div className="w-full p-5 bg-white rounded shadow-lg mb-60">
                <div className="flex items-center justify-between px-1 text-base font-semibold text-gray-700">
                  <h1 className="text-lg font-semibold">{QuestionName}
                    {/* <p className="text-sm font-semibold text-gray-500">RATING SCALE QUESTION</p> */}
                    <p className="text-base font-semibold text-gray-700"><span className="text-blue-500">{(currFilterName !== 'none') ? "( " + currFilterName + " wise )" : ""}</span></p>
                  </h1>
                </div>

                <HeatMap
                  AnswerObjOverall={AnswerObjOverall}
                  DemographicValues={DemographicValues}
                  DemographicValuesObj={DemographicValuesObj}
                  AllAnsText={AllAnsText}
                  XAxis={XAxis}
                />
              </div>
            }

          </div>
        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
