import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import ReactTooltip from "react-tooltip";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSurveyName: "",
      currentSurveyId: "",
      showDropdown: false,
      gameType: "",
      showElement: false,

      // showSidebar:false
    };
  }
  componentDidMount() { }

  onDropdown = () => {
    if (this.state.showDropdown) {
      this.setState({ showDropdown: false });
    } else {
      this.setState({ showDropdown: true });
    }
  };

  logout = () => {
    this.props.dispatch(userActions.logout());
  };

  IndexHoverOptions = (surveyId, indexType) => {
    let { onOptionType } = this.props;

    return (
      <span>
        <i
          className="fa fa-scribd"
          onClick={() => onOptionType(surveyId, indexType, 1)}
        />
        <i
          className="fa fa-quora"
          onClick={() => onOptionType(surveyId, indexType, 2)}
        />
        <i
          className="fa fa-align-left"
          onClick={() => onOptionType(surveyId, indexType, 3)}
        />
        {/* <i className="fa fa-facebook" onClick={()=>onOptionType(surveyId,indexType,4)}/>  */}
      </span>
    );
  };

  handleIndexType = (surveyId, indexType, isDisable, event) => {
    this.setState({ gameType: event });
    let { onOptionType, callOverviewApi } = this.props;
    if (!isDisable) {
      onOptionType(surveyId, indexType, 1);
    } else {
      callOverviewApi();
    }
    // this.setState({showSidebar:false});

    let { handleSidebar } = this.props;
    handleSidebar(false);

    // setTimeout(()=>{
    //   this.setState({showSidebar:false});
    // },5000)
  };

  sidebarToggle = () => {
    let { handleSidebar, showSidebar } = this.props;
    handleSidebar(!showSidebar);
  };

  toggleElement = () => {
    this.setState((prevState) => ({
      showElement: !prevState.showElement,
    }));
  };

  render() {
    let {
      SurveyList,
      getIndexData,
      // onHappinessIndex, onEngagementIndex, onHopeIndex, onStressIndex, onOptionType,
      indexType,
      CurrentSurveyId,
      showMobileSidebar,
      isLifecycle,
      accessAllow,
      routeLink,
    } = this.props;
    let {
      // currentSurveyName, showDropdown,
      showSidebar,
    } = this.props;

    let surveyId = "";
    if (SurveyList && SurveyList.length > 0) {
      if (CurrentSurveyId) {
        surveyId = CurrentSurveyId;
      } else {
        surveyId = SurveyList[0].id;
      }
    }

    let indexLabelsList = [];
    let IndexList = [];

    function getIndexName(key) {
      let name = "";
      if (key === 1) {
        name = "Happiness";
      } else if (key === 2) {
        name = "Engagement";
      } else if (key === 3) {
        name = "Hope";
      } else if (key === 4) {
        name = "Stress";
      } else if (key === 5) {
        name = "Manager";
      } else if (key === 6) {
        name = "Leadership";
      } else if (key === 7) {
        name = "Safety";
      } else if (key === 9) {
        name = "Diversity";
      } else {
        name = "NaN";
      }
      return name;
    }

    if (getIndexData) {
      IndexList = Object.keys(getIndexData).map((key) => [
        Number(key),
        getIndexData[key],
      ]);

      IndexList.forEach((data) => {
        if (getIndexName(data[0]) !== "NaN" && data && data[0] && data[1]) {
          indexLabelsList.push({
            template: data[0],
            score: data[1].toFixed(2),
            name: getIndexName(data[0]),
          });
        }
      });
    }

    let otherLabelList = [
      {
        name: "Overview",
        route: "overview",
        template: 1,
        show: accessAllow("overview"),
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-layout-dashboard"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.8"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M4 4h6v8h-6z"></path>
            <path d="M4 16h6v4h-6z"></path>
            <path d="M14 12h6v8h-6z"></path>
            <path d="M14 4h6v4h-6z"></path>
          </svg>
        ),
      },
      {
        name: "Employees",
        route: "employees",
        template: 2,
        show: accessAllow("uploadEmp"),
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-user-circle"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.8"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="12" cy="12" r="9" />
            <circle cx="12" cy="10" r="3" />
            <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" />
          </svg>
        ),
      },
      {
        name: "Customers",
        route: "customers",
        template: 3,
        show: accessAllow("uploadCust"),
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-user-check"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.8"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="9" cy="7" r="4" />
            <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
            <path d="M16 11l2 2l4 -4" />
          </svg>
        ),
      },
      {
        name: "Users",
        route: "users",
        template: 4,
        show: accessAllow("uploadUser"),
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-users"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.8"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="9" cy="7" r="4" />
            <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
          </svg>
        ),
      },
      {
        name: "Index & Dimension",
        route: "parameterSetting",
        template: 5,
        show: accessAllow("parameterSetting"),
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-list"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.8"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="9" y1="6" x2="20" y2="6" />
            <line x1="9" y1="12" x2="20" y2="12" />
            <line x1="9" y1="18" x2="20" y2="18" />
            <line x1="5" y1="6" x2="5" y2="6.01" />
            <line x1="5" y1="12" x2="5" y2="12.01" />
            <line x1="5" y1="18" x2="5" y2="18.01" />
          </svg>
        ),
      },
      {
        name: "Filters Settings",
        route: "filterSetting",
        template: 6,
        show: accessAllow("filterSetting"),
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-settings"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.8"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" />
            <circle cx="12" cy="12" r="3" />
          </svg>
        ),
      },
    ];

    let CardItemList = [
      {
        id: 1,
        name: "Exployee Experience",
        desc: "Establish an insightful end-to-end employee listening strategy for better understanding the people in your organization and improving the employee experience.",
        value: 0,
        color: "#FF4081",
        image: "/img/modules/employee_engagement.svg",
        shortName: "survey",
      },

      {
        id: 8,
        name: "Performance",
        desc: "Establish an insightful end-to-end assessment for better understanding the people in your organization.",
        value: 8,
        image: "/img/modules/assessment.svg",
        shortName: "performance",
      },
      {
        id: 4,
        name: "360 Feeback",
        desc: "The Plus 360 assessment platform for easier, faster and more affordable employee reviews and performance assessments.",
        value: 4,
        image: "/img/modules/360_feedback_survey.svg",
        shortName: "feedbacksurvey",
      },
      {
        id: 6,
        name: "Customer Insight",
        desc: "Establish an insightful end-to-end customer listening strategy for better understanding the people in your organization and improving the customer experience.",
        value: 6,
        image: "/img/modules/customer_insight.svg",
        shortName: "customersurvey",
      },
      {
        id: 7,
        name: "Assessment",
        desc: "Establish an insightful end-to-end assessment for better understanding the people in your organization.",
        value: 7,
        image: "/img/modules/assessment.svg",
        shortName: "assessment",
      },
      {
        id: 72,
        name: "Leadership Assessment",
        desc: "Establish an insightful end-to-end assessment for better understanding the people in your organization.",
        value: 72,
        image: "/img/modules/happiness_insights.svg",
        shortName: "leadershipassessment",
      },
      {
        id: 2,
        name: "Sense Solution",
        desc: "SENSE Solution helps to sense different behaviors, individuals changing need and unearthed the dissatisfaction trigger point.",
        value: 2,
        image: "/img/modules/sense_solution.svg",
        shortName: "risksurvey",
      },
      {
        id: 3,
        name: "Happiness Insights",
        desc: "Prioritize well-being within your organization culture through science, techniques and researched based solutions",
        value: 3,
        image: "/img/modules/happiness_insights.svg",
        shortName: "happinessdashboard",
      },
    ];

    let LisXt = [];
    if (CardItemList && CardItemList.length > 0) {
      CardItemList.forEach((item) => {
        if (accessAllow(item.shortName)) {
          LisXt.push(item);
        }
      });
    }

    function routePathName() {
      let str = window.location.href;
      let word = str.split("app/")[1];
      return word ? word.replace("/", "") : "";
    }

    return (
      <>
        <div
          className={
            showSidebar
              ? "cursor-pointer transition-width w-64  lg:block lang lg:relative fixed top-0 left-0 z-20  h-screen     duration-200 transform bg-white border-r " +
              (showMobileSidebar ? "" : "hidden")
              : "cursor-pointer transition-width  w-16  lg:block lang lg:relative fixed top-0 left-0 z-20 h-screen     duration-200 transform bg-white border-r  " +
              (showMobileSidebar ? "" : "hidden")
          }
          style={{ height: "calc(100% - 0rem)" }}
        >
          <div className="my-4 whitespace-nowrap ">
            {otherLabelList && otherLabelList.length > 0
              ? otherLabelList.map((item, index) =>
                item.show ? (
                  <>
                    {index === 1 ? ( //Survey Sub menu
                      <div className="relative group ">
                        <div
                          onClick={this.toggleElement}
                          className="flex items-center justify-between hover:bg-blue-50 text-[#3D405B] select-none cursor-pointer   "
                        >
                          <div className="flex items-center">
                            <div className="flex items-center justify-center w-16 h-12 px-4 py-4 ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-clipboard-list"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="1.8"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                                <rect
                                  x="9"
                                  y="3"
                                  width="6"
                                  height="4"
                                  rx="2"
                                />
                                <line x1="9" y1="12" x2="9.01" y2="12" />
                                <line x1="13" y1="12" x2="15" y2="12" />
                                <line x1="9" y1="16" x2="9.01" y2="16" />
                                <line x1="13" y1="16" x2="15" y2="16" />
                              </svg>
                            </div>
                            {showSidebar ? (
                              <span className="text-sm  font-normal text-[#3D405B]">
                                Surveys
                              </span>
                            ) : null}
                          </div>
                          {showSidebar ? (
                            <span
                              className={
                                this.state.showElement
                                  ? "material-symbols-outlined mx-4 text-[#3D405B] rotate-180 transition-all duration-200"
                                  : "rotate-0 transition-all duration-200 material-symbols-outlined mx-4 text-[#3D405B]"
                              }
                            >
                              expand_more
                            </span>
                          ) : null}
                        </div>
                        {showSidebar ? null : (
                          <div className="absolute top-0 z-40 hidden p-4 space-y-2 bg-white border rounded-md shadow-md left-16 group-hover:block">
                            {LisXt && LisXt.length > 0
                              ? LisXt.map((card) => (
                                <p
                                  onClick={() => routeLink(card.shortName)}
                                  className={
                                    ((
                                      routePathName() === "risksurvey"
                                        ? "risksurvey" === card.shortName
                                        : routePathName() === card.shortName
                                    )
                                      ? "text-[#2196F3] hover:text-[#2196F3] py-1"
                                      : "text-[#3D405B] hover:text-[#2196F3] py-1") +
                                    " text-sm block font-normal"
                                  }
                                >
                                  {card.name}
                                </p>
                              ))
                              : null}
                          </div>
                        )}

                        <div
                          className={
                            this.state.showElement && showSidebar
                              ? "space-y-4 pl-16 pr-4 py-4 transition-height transform duration-200 h-72 overflow-hidden "
                              : "space-y-4 pl-16 pr-4 transition-height transform duration-200 h-0 overflow-hidden "
                          }
                        >
                          {LisXt && LisXt.length > 0
                            ? LisXt.map((card) => (
                              <p
                                onClick={() => routeLink(card.shortName)}
                                className={
                                  ((
                                    routePathName() === "risksurvey"
                                      ? "risksurvey" === card.shortName
                                      : routePathName() === card.shortName
                                  )
                                    ? "text-[#2196F3] hover:text-[#2196F3]"
                                    : "text-[#3D405B] hover:text-[#2196F3]") +
                                  " text-sm block  font-normal "
                                }
                              >
                                {card.name}
                              </p>
                            ))
                            : null}
                        </div>
                      </div>
                    ) : null}

                    <div
                      title={item && item.name ? item.name : ""}
                      key={index}
                      onClick={() => routeLink(item.route)}
                      className={
                        routePathName() === item.route
                          ? "flex items-center hover:bg-[#2196F3]/10 text-gray-500 cursor-pointer   border-l-4 border-[#2196F3] bg-[#2196F3]/10"
                          : "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer"
                      }
                    >
                      <div
                        className={
                          routePathName() === item.route
                            ? "flex items-center px-4 py-4 w-16  text-[#2196F3] h-12 justify-center"
                            : "flex items-center px-4 py-4 w-16 text-[#3D405B]   h-12 justify-center"
                        }
                      >
                        <span className=" material-symbols-outlined">
                          {item.icon}
                        </span>
                      </div>

                      {showSidebar ? (
                        <span
                          className={
                            routePathName() === item.route
                              ? " text-sm text-[#2196F3]"
                              : " text-sm text-[#3D405B] "
                          }
                        >
                          {item && item.name ? item.name : ""}
                        </span>
                      ) : null}
                    </div>
                  </>
                ) : null
              )
              : null}
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn,
  };
}
export default connect(mapStateToProps)(Sidebar);
